import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { DashboardDCAContext } from '../../../context/DasboardDCA/DashboardDCAContext';
import { DashboardContext } from '../../../context/Dashboard/DashboardContentx';

// Componente de tooltip personalizado
function CustomTooltip({ active, payload, label }) {

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const titleColor = "#ce0f41"; // Color del título dependiendo de Gas o Oil
    const valueColor = "black"; // Color del valor
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: titleColor }}>{`Date: `}<span style={{ color: valueColor }}>{data.Dates}</span></p>
        {/* <p style={{ color: titleColor }}>{`Cumulative Days: `}<span style={{ color: valueColor }}>{data.CumulativeDate}</span></p> */}
        <p style={{ color: 'blue' }}>{`Wellhead Pressure: `}<span style={{ color: valueColor }}>{data['Static Pressure']}{` (psig)`}</span></p>
        <p style={{ color: 'red' }}>{`Flowing Temperature: `}<span style={{ color: valueColor }}>{data['Flowing Temperature']}{` (deg F)`}</span></p>
      </div>
    );
  }

  return null;
}

export const ChartConditions = (props) => {
  const enableModal = props.enableModal
  const chartRef = useRef();
  const { dataFilterUserDCA } = useContext(DashboardDCAContext);
  const { deviceRealTime } = useContext(DashboardContext);
  let [valueOffset, setValueOffset] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chartNode = chartRef.current;
        const offsetTop = chartNode.offsetTop;
        // Obtener el tamaño de la pantalla
        const screenHeight = window.innerHeight;

        setValueOffset(enableModal ? offsetTop + 460  : (screenHeight/2) - offsetTop);
        // setValueOffset(300);

      }
    };

    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);

    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();

    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [enableModal]);

  const data = props.data.filter(item => 
    item['LSD'] === deviceRealTime.device_dca.name &&
    item['Static Pressure'] !== -999.25 && item['Flowing Temperature'] !== -999.25 
  );
  const bottom = props.bottom
  const dataHistory = props.dataHistory;

  const formattedData = data.map((item, index) => ({
    ...item,
    CumulativeDate: index + 1,
    'Static Pressure': parseFloat(item[deviceRealTime?.device_dca?.discretized ? dataFilterUserDCA.well_name+' Well Head Pressure' : 'Static Pressure' ]),
    'Flowing Temperature': parseFloat(item['Flowing Temperature']),
    Date: String(item['Date (MM/DD/YYYY)']).split(" ")[0],
    Dates: String(item['Date (MM/DD/YYYY)'])
  }));

  // let dataFinalHistory = [];

  // if(dataHistory != []){
  // const dataHistoryMod = dataHistory?.map((item, index) => ({
  //     ...item,
  //     "Date": String(item["date"])?.split(" ")[0],
  //     "Corrected Flow Rate": item["oil_rate"],
  //     "Corrected Accumulated Volume": item["comulative_oil"],
  // }));
  
  // dataFinalHistory = dataHistoryMod.concat(formattedData);
  
  // }else{
  // dataFinalHistory = formattedData;
  // }

  // Encontrar el máximo valor de Gas-Oil ratio u Oil rate y redondearlo al siguiente número de mil más cercano
  const maxDataValue1 = Math.max(
    ...formattedData.map(item => parseFloat(item['Static Pressure']))
  );

  const roundedMaxValue = Math.ceil(maxDataValue1 / 100) * 100;

  // Encontrar el máximo valor de Gas-Oil ratio u Oil rate y redondearlo al siguiente número de mil más cercano
  const maxDataValue2 = Math.max(
    ...formattedData.map(item => parseFloat(item['Flowing Temperature']))
  );

  const roundedMaxValue2 = Math.ceil(maxDataValue2 / 100) * 100;
  const yAxisDomain = roundedMaxValue;
  const yAxisDomain1 = roundedMaxValue2;
  // Ancho fijo del eje Y
  const yAxisWidth = 85;

  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5000
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };

  const dataLength = formattedData.length;
  const tickInterval = Math.ceil(dataLength / 6);

  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={1000}
          height={300}
          data={formattedData} // dataFinalHistory
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: bottom,
          }}
        >
          <XAxis
            dataKey="Date"
            // domain={['auto', 'auto']}
            allowDecimals={false}
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
            }}
            tick={{ fill: "black" }}
            tickCount={tickInterval}
          />

          <YAxis
            yAxisId={0}
            dataKey={'Static Pressure'}
            label={{
              value: 'Wellhead Pressure (psig)',
              angle: -90,
              position: 'insideLeft',
              fill: 'black',
              style: { textAnchor: "middle" }, // Mover el label hacia abajo
            }}
            width={yAxisWidth}
            domain={yAxisDomain} // Establecer el dominio basado en el máximo valor
            tickFormatter={(value) => Math.round(value)}
            padding={{bottom:10}}
          />
          <Brush dataKey="Date" height={10} stroke="#8884d8" y={valueOffset} />
          <Tooltip content={<CustomTooltip />} />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={'Flowing Temperature'}
            label={{
              value:
                'Flowing Temperature (deg F)',
              angle: -90,
              position: 'insideRight',
              fill: 'black',
              style: { textAnchor: "middle" },
            }}
            width={yAxisWidth}
            domain={yAxisDomain1} // Establecer el dominio redondeado en el eje Y
            tickFormatter={(value) => Math.round(value)}
            padding={{bottom:10}}
            tick={{ fill: "black" }}
          />
          {/* <ReferenceLine x={3} stroke="red" label="Max PV PAGE" /> */}
          {/* <ReferenceLine y={9800} label="Max" stroke="red" /> */}
          <Line type="monotone" dataKey="Static Pressure" fill='blue' stroke="blue"
            dot={<CustomDot color='blue' />} activeDot={{ r: 4 }} yAxisId={0}
          />
          <Line type="monotone" dataKey="Flowing Temperature" fill='red' stroke="#ce0f41"
            dot={<CustomDot color='red' />} activeDot={{ r: 4 }} yAxisId={1} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
