import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  CopyOutlined
} from "@ant-design/icons";
import { Checkbox, message, Modal, Tooltip, Select, Switch, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorLoadingData } from "../../components/ErrorPage";
import { ImportFileHistory } from "../../components/GasLift/Complements/ImportFileHistory";
import { Loading } from "../../components/Loading";
import { DATA_ALERTS } from "../../components/elements/types/type_dashboard";
import { REQUIRED_PROPIERTIES, DATA_CREATE_WELL_GAS_LIFT } from "../../components/elements/types/type_gas_lift";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabTuning } from "../../hooks/useTabTuning";
import { useFilters } from "../../hooks/useFilters";
import { useTabIOT } from "../../hooks/useTabIOT";
import { useCrudUsers } from "../../hooks/useCrudUsers";
import { NAME_PAGES } from "../../components/elements/types/type_pages";

const emptyDataFluid= {
  res_pressure: "",
  res_temperature: "",
  res_initial_gor: "",
  res_oil_gravity: "",
  res_gas_gravity: "",
  res_H2S: "",
  res_CO2: "",
  res_N2: "",
  res_water_salinity: "",
  sep_pressure: "",
  sep_temperature: "",
  sep_oil_gravity: "",
  sep_gas_gravity: "",
  sep_solution_gor: "",
  sep_oil_volume_factor: "",
  fluidLab:false,
  temperature_lab: "",
  solution_gor_pb: "",
  bubble_point_pressure_lab: "",
  oil_fvf: "",
  table_fluid_lab:[],
  option_data_point: "",
  calculate_correlation: "",
  calculate_correlation_oil: "",
  bubble_point_pressure:"",
  data_point_table:[],
  fluidLab:false,
};

const dataWellCopyUser = {
  well_id: "",
  well_name: "",
  user: []
}

const emptyDataReservoir = {
  layer_pressure: "",
  layer_temperature: "",
  md_perf_depth: "",
  water_cut_fraction: "",
  state_reservoir: "",
  ipr_model: "",
  test_pressure_1_s: "",
  test_flow_rate_1_s: "",
  test_pressure_2_s: "",
  test_flow_rate_2_s: "",
  productivity_index_j_s: "",
  test_pressure_1_us: "",
  test_flow_rate_1_us: "",
  test_pressure_2_us: "",
  test_flow_rate_2_us: "",
  productivity_index_j_us: "",
  check_phases: false,
  pwf_ipr: false,
  straight_line: false
};

const emptyDataFlow = {
  oil_flow_rate_min : "",
  oil_flow_rate_max : "",
  number_rates_flow : "",
  check_import_model_ipr : false,
  select_parameters: "",
  min_gor_flow : "",
  max_gor_flow : "",
  number_cases_gor_flow : "",
  min_wt_flow : "",
  max_wt_flow : "",
  number_cases_wt_flow : "",
  min_inj_flow : "",
  max_inj_flow : "",
  number_cases_inj_flow : "",
  table_inflow_outflow: [],
  table_performance_flow : []
};

export const AddWellGasLift = () => {
  const { confirm } = Modal;
  const { Option } = Select;
  const { dataFilter, mutationUpdateFilter } = useFilters();
  const Navigate = useNavigate();
  const { selectedWell, getSelectedWell, getDataPage, getOpenFirstModal, getDataAlertGasLift, someAlert, getSomeAlert, getDeviceRealTime, deviceRealTime} = useContext(DashboardContext);
  const {
    User,
    userDataInfo,
    dataFilterUserGasLift,
    dataCreated,
    getDataCreated,
    getDataFilterUserGaslift,
    viewLoading,
    setViewLoading,
    listWellsGasLift,
    listDataTuning,
    dataWellGasLift,
    queryDataHistory,
    queryListDataHistory,
    mutationDeleteDataGasLift,
    mutationUpdateDataGasLift,
    mutationCreateDataHistory,
    mutationDeleteDataHistory,
    mutationUpdateDataHistory,
    mutationSaveDataGasLift,
    errorListDataHistory,
    refetchDataWellGasLift,
    refetchListWellsGasLift,
    refetchDataTuning,
    refetchListDataHistory,
    refetchDataHistory,
    generateRadomId,
    setUserMultiselect,
    refetchWellsUserGasLift,
    wellsUserGasLift
  } = useTabGaslift();
  const { dataUsersExternal, dataUsersInternal, setEnabledUserExt, setEnabledUserInt} = useCrudUsers();
  const { refetchDataListPlatformsWells, setTypeProcessPlatform, refetchDataListPlatforms, dataListPlatforms, dataListPlatformsWells} = useTabIOT();
  const { mutationDeleteDataTuning, refetchTuning, queryDataTuning, mutationCreateDataTuning, mutationCreateCalTuningVLP } = useTabTuning();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [dataInformationEndFluid, setDataInformationEndFluid] = useState();
  const [dataInformationEndReservoir, setDataInformationEndReservoir] = useState();
  const [dataInformationEndFlow, setDataInformationEndFlow] = useState();
  const [inputWellInformation, setInputWellInformation] = useState(DATA_CREATE_WELL_GAS_LIFT);
  const [openModalCopy, setOpenModalCopy] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [listWells, setListWells] = useState(null);
  const [listTuning, setListTuning] = useState([]);
  const [listDataHistory, setListDataHistory] = useState([]);
  const [arrayDataHistory, setArrayDataHistory] = useState(null);
  const [valuesDataHistory, setValuesDataHistory] = useState();
  const [enableMultiUser, setEnableMultiUser] = useState(false);
  const [validationWell, setValidationWell] = useState(false);
  const [validationWellEnd, setValidationWellEnd] = useState(false);
  const [enableValidationWells, setEnableValidationWells] = useState(false);
  const [loadCopy, setLoadCopy] = useState(false);
  const [validationWells, setValidationWells] = useState([]);
  const [multiUserCopy, setmultiUserCopy] = useState(dataWellCopyUser);
  const userOption = dataUsersInternal?.filter(user => user.user_name.split('@')[0] !== User.user_name).map(user => ({value: user.user_name.split('@')[0]}));
  
  useEffect(() => {
    getDataPage("AddWellGasLift");
    refetchDataTuning();
    refetchListWellsGasLift();
    refetchListDataHistory();
    //Esta variable se emplea para inicializar los check seleccionados
    if (selectedWell !== null) {
      setSelectedItemId(selectedWell);
      // En esta seccion de debe llamar el api de listado de pozos y buscar el id que se toma de la variable de estado para extraer el resto de datos y setearlo de esta forma se valida que exista
      const dataWellFind = data.find((item) => item.well_id === selectedWell);
      const dataFilterNew = {
        county: dataWellFind?.county,
        field: dataWellFind?.field,
        well_id: dataWellFind?.well_id,
        well_platforms: dataWellFind?.well_platforms,
        well_name: dataWellFind?.well_name,
      };
      if (data.length > 0) {
        getDataFilterUserGaslift(dataFilterNew);
      }
    }

    if (listWellsGasLift) {
      setListWells(listWellsGasLift?.data);
    }
  }, []);

  useEffect(() => {

      if (queryListDataHistory?.statusType === "SUCCESS") {
        setListDataHistory(queryListDataHistory?.data);
      } 
      if (listDataTuning?.statusCode === 200075) {
        setListTuning(listDataTuning?.data)
      }
  }, [listDataTuning, queryListDataHistory])
  
  useEffect(() => {
    if (selectedWell !== null) {
      refetchDataWellGasLift();
      refetchDataHistory();
      refetchTuning();
    }
  }, [selectedWell])

  useEffect(() => {
    refetchListWellsGasLift();
    if (selectedWell !== null && data.length > 0) {
      setSelectedItemId(Number(selectedWell));
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (dataWellGasLift) {
        if (dataWellGasLift.statusCode === 200075) {
          const filterDataCreate = dataWellGasLift.data;
          const filterDataEndFluid = Object.assign({}, emptyDataFluid);
          const filterDataEndReservoir = Object.assign({},emptyDataReservoir);
          const filterDataEndFlow = Object.assign({},emptyDataFlow);
          const filteredJson = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEndFluid))
          );
          const filteredJson2 = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEndReservoir))
          );
          const filteredJson3 = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEndFlow))
          );
          setDataInformationEndFluid(filteredJson);
          setDataInformationEndReservoir(filteredJson2);
          setDataInformationEndFlow(filteredJson3);
        }

        if(queryDataHistory?.statusType === "SUCCESS"){
          setArrayDataHistory(queryDataHistory?.data?.data)
          const dataHistory = arrayDataHistory?.map(obj => obj['id']);
          setValuesDataHistory(dataHistory)
        }
      }
    }, 1000);
  }, [dataWellGasLift])

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchDataTuning();
      refetchListWellsGasLift();
      refetchListDataHistory();
    }, 10000); // 30 segundos
    // Limpieza del intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, [refetchListWellsGasLift]); // Dependencia del efecto

  useEffect(() => {
    setTimeout(() => {
      if (listWellsGasLift) {
        if (listWellsGasLift.statusType != "ERROR") {
          setData(listWellsGasLift.data)
        }
      }
    }, 500);
  }, [listWellsGasLift]);

  useEffect(() => {

    if(wellsUserGasLift?.statusCode === 200075 && wellsUserGasLift?.data && validationWell.state){
      
      const validationResults  = wellsUserGasLift?.data?.some(well => 
        well.well_id === multiUserCopy.well_id || 
        well.well_name === multiUserCopy.well_name
      );
            
      setValidationWells(prevState => ({
        ...prevState,
        [validationWell.user]: validationResults
      }));
      if (validationResults) {
        message.error("The "+validationWell.user+" already has a well with the NAME or ID");
      }
      setValidationWell({...validationWell, state: false})
    }else if (wellsUserGasLift?.statusCode === 400077 && !wellsUserGasLift?.data && validationWell.state){
      setValidationWells(prevState => ({
        ...prevState,
        [validationWell.user]: false
      }));
      setValidationWell({...validationWell, state: false});
    }
 
  }, [wellsUserGasLift, validationWell]);

  useEffect(() => {
    if (enableValidationWells) {
      const valUserDuplicate = Object.values(validationWells).some(value => !!value);
      setEnableValidationWells(false);
      setValidationWellEnd(!valUserDuplicate);
      setLoadCopy(false);
      if(!valUserDuplicate){
        message.success("No problem for validation duplicate ID or NAME in others wells");
      }
    }
  }, [enableValidationWells]);

  if (viewLoading) {
    return (<Loading />)
  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleDelete = (id) => {
    if (selectedWell === id) {
      showDeleteConfirm(id);

    } else {
      message.warning('Please, select the proper well')
    }
  };
  const handleClickButtonCreate = (idButton) => {
    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWell
    );
    const tuningValidatedExists = listTuning?.some((tuningItem) => tuningItem.well_id === selectedWell);
    if (
      idButton === 0 &&
      !!userDataInfo &&
      !!userDataInfo.operator &&
      !!userDataInfo.lease &&
      !!userDataInfo.region
    ) {
      Navigate(`./${NAME_PAGES.gas_lift.create_well}`);
    } else if (idButton === 1) {
        if (data[selectedWellIndex]?.wellConfig?.fluidConfig === true) {
          message.warning(`Sorry! A fluid is already exist to this well, please edit this`)
        }
        else if (!!selectedWell) {
          Navigate(`./${NAME_PAGES.gas_lift.create_fluid}`);
        }
    } else if (idButton === 2) {
        if (data[selectedWellIndex]?.wellConfig?.reservoir === true) {
          message.warning(`Sorry! A reservoir is already exist to this well, please edit this`)
        } else if (!data[selectedWellIndex]?.wellConfig?.fluidConfig) {
          message.warning('Please select a well with a fluid')
        } else if (
          !!selectedWell &&
          data[selectedWellIndex]?.wellConfig?.fluidConfig === true) {
          Navigate(`./${NAME_PAGES.gas_lift.create_reservoir}`);
        }
    } else if (idButton === 3 && !!selectedWell) {
      Navigate(`./${NAME_PAGES.gas_lift.create_edit_tuning}`);
    } else if (idButton === 4 && !!selectedWell) {
      if (data[selectedWellIndex]?.wellConfig?.flowConfig === true){
        message.warning(`Sorry! A inflow/outflow is already exist to this well, please edit this`)
      } else if (!tuningValidatedExists){
        message.warning('Please select a well with a VLP tuning')
      } else if (tuningValidatedExists){
        Navigate(`./${NAME_PAGES.gas_lift.create_inoutflow}`);
      }
    } else {
      info();
    }
  };

  const handleUploadFile = (dataFile) => {
    const historyExists = Array.isArray(listDataHistory) && listDataHistory.some(
      (itemHistory) => itemHistory.wellid === selectedWell
    );
    const maxValueHistory = valuesDataHistory != undefined ? Math.max(...valuesDataHistory) + 1 : 0;
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + (historyExists ? maxValueHistory : 1), ...obj };
    });
    const dataFileEnd = dataFilePre.map((item)=>({
      ...item, 
      ['date']: convertDate(item.date) 
    }))

    if (dataFileEnd?.length > 0 && !historyExists) {
      setTimeout(() => {
        mutationCreateDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
    }else if(dataFileEnd?.length > 0 && historyExists){
      setTimeout(() => {
        mutationUpdateDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
    }
  };

  const handleClickButtonEdit = (typeConfig, id) => {
    if (typeConfig === "wellConfig") {
      if (selectedWell === id) {
        Navigate(`./${NAME_PAGES.gas_lift.edit_well}`);
      } else {
        message.warning('Please, select the proper well')
      }
    }
    if (typeConfig === "fluidConfig") {
      if (selectedWell === id) {
        Navigate(`./${NAME_PAGES.gas_lift.edit_fluid}`);
      } else {
        message.warning('Please select the well corresponding to this fluid')
      }
    }
    if (typeConfig === "reservoir") {
      if (selectedWell === id) {
        Navigate(`./${NAME_PAGES.gas_lift.edit_reservoir}`);
      } else {
        message.warning('Please select the well corresponding to this reservoir')
      }
    }
    if (typeConfig === "tuning") {
      if (selectedWell === id) {
        Navigate(`./${NAME_PAGES.gas_lift.create_edit_tuning}`);
      } else {
        message.warning('Please select the well corresponding to this tuning')
      }
    }
    if (typeConfig === "flowConfig") {
      if (selectedWell === id) {
        Navigate("./StepMenu-EditInOutFlow");
      } else {
        message.warning('Please select the well corresponding to this Flow')
      }
    }
  }

  const handleClickButtonDelete = (typeConfig, well_id) => {
    if (well_id === selectedWell) {
      if (selectedWell ? selectedWell != null && selectedWell != 'null' && selectedWell != undefined : well_id === selectedItemId) {
        showDeleteCardConfirm(typeConfig, well_id);
      } else {
        infoDelete(typeConfig, well_id)
      }
    } else {
      message.warning(`Please select the well corresponding to this ${typeConfig === 'fluidConfig' ? 'fluid' : typeConfig === 'reservoir' ? 'reservoir' : typeConfig === 'tuning' ? 'tuning' : typeConfig === 'history' ? 'data history' : 'well'}`)
    }
  }

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const showDeleteCardConfirm = (type, id) => {
    confirm({
      title: `Do you want to delete this ${type === 'fluidConfig' ? 'fluid' : type === 'reservoir' ? 'reservoir' : type === 'tuning' ? 'tuning' : type === 'history' ? 'data history' : 'well'}?`,
      icon: <ExclamationCircleFilled />,
      content: 'Deleted information can´t be recovered',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {

        const selectedWellIndex = data.findIndex(well => well.well_id === id);
        if (type === 'fluidConfig') {
          if (data[selectedWellIndex]?.wellConfig?.reservoir) {
            infoDelete(type, id)
          } else {
            const dataCreateFuild = Object.assign({}, emptyDataFluid, dataInformationEndFluid);
            // Verificar si todas las propiedades requeridas existen en dataDeleteReservoir
            const allPropertiesExist = REQUIRED_PROPIERTIES.every(prop => prop in dataCreateFuild);
            if (allPropertiesExist) {
              setTimeout(() => {
                mutationUpdateDataGasLift.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: dataCreateFuild })
                message.success(`Fluid delete Successfull`)
              }, 100);
              setTimeout(() => {
                refetchDataWellGasLift();
              }, 1000);
            } else {
              // Si falta alguna propiedad, mostrar alerta
              message.warning('Sorry, something was wrong, please try again');
            }

          }
        }
        else if (type === 'reservoir') {
          const dataDeleteReservoir = Object.assign({}, emptyDataReservoir, dataInformationEndReservoir);
          // Verificar si todas las propiedades requeridas existen en dataDeleteReservoir
          const allPropertiesExist = REQUIRED_PROPIERTIES.every(prop => prop in dataDeleteReservoir);
          if (allPropertiesExist) {
            setTimeout(() => {
              mutationUpdateDataGasLift.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: dataDeleteReservoir })
              message.success(`Reservoir delete Successfull`)
            }, 100);
            setTimeout(() => {
              refetchDataWellGasLift();
            }, 1000);
          } else {
            // Si falta alguna propiedad, mostrar alerta
            message.warning('Sorry, something was wrong, please try again');
          }
        }
        else if (type === "tuning") {
          setTimeout(() => {
            mutationDeleteDataTuning.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserGasLift.county,
              field: dataFilterUserGasLift.field,
              wellid: dataFilterUserGasLift.well_id,
              username: User.user_name
            });
          }, 1000);
          setTimeout(() => {
            refetchDataTuning();
          }, 2000);
        }
        else if (type === 'flowConfig') {
          const dataDeleteFlow = Object.assign({}, emptyDataFlow, dataInformationEndFlow);
          // Verificar si todas las propiedades requeridas existen en dataDeleteReservoir
          const allPropertiesExist = REQUIRED_PROPIERTIES.every(prop => prop in dataDeleteFlow);
          if (allPropertiesExist) {
            setTimeout(() => {
              mutationUpdateDataGasLift.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: dataDeleteFlow })
              message.success(`Flow delete Successfull`)
            }, 100);
            setTimeout(() => {
              refetchDataWellGasLift();
            }, 1000);
          } else {
            // Si falta alguna propiedad, mostrar alerta
            message.warning('Sorry, something was wrong, please try again');
          }
        }
        else if (type === "history") {
          setTimeout(() => {
            mutationDeleteDataHistory.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserGasLift.county,
              field: dataFilterUserGasLift.field,
              well: dataFilterUserGasLift.well_id,
              username: User.user_name,
            });
          }, 1000);
          setTimeout(() => {
            refetchListDataHistory();
          }, 2000);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Do you want to delete this well?",
      icon: <ExclamationCircleFilled />,
      content:
        "Deleted information can´t be recovered",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        const newData = data.filter((item) => item.id !== id);
        setData(newData);
        const newDataCreated = dataCreated.filter(
          (item) =>
            item.id !== id && item.well_id !== dataFilterUserGasLift.well_id
        );
        getDataCreated(newDataCreated);
        const tuningExistsValidated = listTuning?.some((tuningItem) => tuningItem.well_id === id);
        if (tuningExistsValidated) {
          setTimeout(() => {
            mutationDeleteDataTuning.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserGasLift.county,
              field: dataFilterUserGasLift.field,
              wellid: dataFilterUserGasLift.well_id,
              username: User.user_name,
            });
          }, 300);
        }

        const historyExists = Array.isArray(listDataHistory) && listDataHistory.some((itemHistory) => itemHistory.wellid === id);
        if(historyExists){
          setTimeout(() => {
            mutationDeleteDataHistory.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserGasLift.county,
              field: dataFilterUserGasLift.field,
              well: dataFilterUserGasLift.well_id,
              username: User.user_name,
            });
          }, 1000);
        }

        setTimeout(() => {
          mutationDeleteDataGasLift.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: dataFilterUserGasLift.county,
            field: dataFilterUserGasLift.field,
            well: dataFilterUserGasLift.well_id,
            username: User.user_name,
          });
        }, 1000);
        getSelectedWell(null)
        getDataFilterUserGaslift(null)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const info = () => {
    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWell
    );
    Modal.info({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      okText: "OK",
      okType: "danger",
      content: (
        <div>
          {(userDataInfo.operator === undefined || userDataInfo.lease === undefined) ? (
            <>
              <p>Please select or fill-out the information of operator, lease, and region</p>
            </>
          ) : (selectedWell === undefined || selectedWell === null) &&
            (!!userDataInfo) ? (
              <p>Please select a well first</p>
            ) : !data[selectedWellIndex]?.wellConfig?.fluidConfig && (
            <p>Please select a well with a fluid</p>
          )}
        </div>
      ),
      onOk() {userDataInfo.operator === undefined || userDataInfo.lease === undefined ? getOpenFirstModal(true) : getOpenFirstModal(false)},
    });
  };

  const infoDelete = (typeConfig, id) => {
    Modal.info({
      title: "Warning",
      content: (
        <div>
          {(typeConfig === 'fluidConfig' && (selectedWell != null || selectedWell != 'null' || selectedWell == undefined)) &&
            <>
              <p>Please delete the reservor first</p>
            </>
          }
          {(typeConfig === 'reservoir' && (selectedWell == null || selectedWell == 'null' || selectedWell == undefined)) &&
            <>
              <p>Please select a well to continue</p>
            </>
          }
          {(typeConfig === 'tuning' && selectedWell === null) &&
            <>
              <p>Please select the well or same to edit or delete tuning</p>
            </>
          }
          {(selectedWell != id) &&
            <>
              <p>Please select the well corresponding to this {typeConfig === 'reservoir' ? 'reservoir' : typeConfig === 'tuning' ? 'tuning' : 'fluid'}</p>
            </>
          }
        </div>
      ),
      onOk() { },
    });
  };
  
  const handleChangeCheckWell = (valueCheck, idCheck) => {
    if (valueCheck) {
      setSelectedItemId(idCheck);
    } else {
      setSelectedItemId(null);
    }

    if (valueCheck === true) {
      // En esta seccion de debe llamar el api de listado de pozos y buscar el id que se toma de la variable de estado para extraer el resto de datos y setearlo de esta forma se valida que exista
      const dataWellFind = data.find((item) => item.well_id === idCheck);
      const dataFilterNew = {
        county: dataWellFind.county,
        field: dataWellFind.field,
        well_id: dataWellFind.well_id,
        well_platforms:  dataWellFind.well_platforms,
        well_name: dataWellFind.well_name,
      };
      getDataFilterUserGaslift(dataFilterNew);
      getSelectedWell(idCheck);
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: {...dataFilter?.data,checkedWellGaslift:idCheck}
        });
      }, 1000);
    } else {
      getSelectedWell(null);
      getDataFilterUserGaslift(null);
      getDataAlertGasLift([]);
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: {...dataFilter?.data,checkedWellGaslift:""}
         
        });
      }, 1000);
      getSomeAlert({
        ...someAlert,
        gas_lift: DATA_ALERTS.gas_lift
      })
    }
  }

  const handleModalCopyWell = (idCheck, state) => {
    
    if (selectedWell !== null && (selectedWell === idCheck) && state && dataWellGasLift?.data) {
      setOpenModalCopy(state);
      refetchDataListPlatforms();
      setInputWellInformation({
          ...dataWellGasLift?.data,
          well_id:"", 
          well_platforms:"", 
          well_name:"",
          discretized_well: false,
        })
    }else if(!state){
      setInputWellInformation(DATA_CREATE_WELL_GAS_LIFT);
      setOpenModalCopy(state)
      setInputEmpty(false)
      setmultiUserCopy(dataWellCopyUser);
      setValidationWellEnd(false);
      setValidationWell(false);
      setEnableMultiUser(false);
      setValidationWells([])
    }else{
      message.info("Please select check with correct well")
    }
  };

  const handleClickGenerateId = () => {

    const idGenerated = generateRadomId()
    setInputWellInformation({
      ...inputWellInformation,
      well_id: idGenerated
    })
  };

  const handleChangeInformation = (e) => {
    const { name, value } = e.target;
    let exists = false;
    
    switch (name) {
      case "well_platforms":
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            platform: value,
            selected_platform_graph:{
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartProdHistory:value,
              chartWellCond:value,
              chartHole:value
            }
          }
        });
        setTypeProcessPlatform(value);
        setTimeout(() => {
          refetchDataListPlatformsWells();
        }, 500);
        setInputWellInformation((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
  
      case "discretized_well":
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            discretized: value
          }
        });
        setInputWellInformation((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
      case "well_id":
        exists = enableMultiUser ? false : wellIdExists(name, value, data);
        if (exists) {
          setInputWellInformation((prevState) => ({
            ...prevState,
            [name]: null,
          }));
          message.error(
            "There is already a well with the same ID, please generate a new ID"
          );
        } else {
          setInputWellInformation((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
        break;
  
      case "well_name":
        exists = enableMultiUser ? false : wellIdExists(name, value, data);
        if (exists) {
          setInputWellInformation((prevState) => ({
            ...prevState,
            [name]: null,
          }));
          message.error(
            "There is already a well with the same Name, please select a new name"
          );
        } else {
          setInputWellInformation((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
        break;
      case "option_select":
        setmultiUserCopy((prevState) => ({
          ...prevState,
          ["user"]: value,
        }));
        break;
      default:
        setInputWellInformation((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;
    }
    setValidationWellEnd(false);
  };
  
  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInformation(customEvent);
  };

  const handleSwitch = (enabled) => {
    const customEvent = {
      target: {
        name: "discretized_well",
        value: enabled,
      },
    };
    handleChangeInformation(customEvent);
  }

  const handleSwitchMultiUser = (enabled) => {
    setEnableMultiUser(enabled);
    setEnabledUserInt(enabled);
    setValidationWellEnd(false);
    if (!enabled) {
      
      const existsWellName = wellIdExists("well_name", inputWellInformation.well_name, data);
      const existsWellId = wellIdExists("well_id", inputWellInformation.well_id, data);
      
      setInputWellInformation((prevState) => ({
        ...prevState,
        ["well_name"]: existsWellName ? null : inputWellInformation.well_name,
        ["well_id"]: existsWellId ? null : inputWellInformation.well_id,
      }));

   }else if (enabled) {
    setInputWellInformation((prevState) => ({
      ...prevState,
      ["well_name"]: inputWellInformation.well_name === null ? "" : inputWellInformation.well_name,
      ["well_id"]: inputWellInformation.well_id === null ? "" : inputWellInformation.well_id,
    }));
   }
  }
  
  const handleCopyWell = (type) => {
    if (!!inputWellInformation.well_id && !!inputWellInformation.well_name && !!inputWellInformation.well_platforms && dataWellGasLift?.data) {
      if (type === "save") {
        if(enableMultiUser){
          async function executeFunction() {
            await sequentialMutationCopyWell(multiUserCopy.user);
          }
          executeFunction();
        }else{
          getDataFilterUserGaslift({
            ...dataFilterUserGasLift,
            county: inputWellInformation.county,
            field: inputWellInformation.field,
            well_id: inputWellInformation.well_id,
            well_platforms: inputWellInformation.well_platforms,
            well_name: inputWellInformation.well_name,
          });
    
          getSelectedWell(inputWellInformation.well_id);
          
          async function executeFunction() {
            await sequentialMutationCopyWell([User?.user_name]);
          }
          executeFunction();
        }
      }else if (type === "validate_multi_user") {
        setLoadCopy(true);
        setmultiUserCopy((prevState) => ({
          ...prevState,
          ["well_id"]: inputWellInformation.well_id,
          ["well_name"]: inputWellInformation.well_name
        }));
        
        async function executeFunction() {

          setValidationWells([]);
          for (const user of multiUserCopy.user) {
            setUserMultiselect(user);
            await new Promise(resolve => setTimeout(resolve, 500));

            await refetchWellsUserGasLift();
            setValidationWells(prevState => ({
                ...prevState,
                [user]: null
            }));
            setValidationWell({state: true, user: user});
          }
          await new Promise(resolve => setTimeout(resolve, 500));
          setEnableValidationWells(true)
        }
        executeFunction()
      }
    }else{
      message.error("Please complete information")
      setInputEmpty(true)
    }
  }
  
  const sequentialMutationCopyWell = async (users) => {
    setLoadCopy(true);

    for (const user of users) {
      await mutationSaveDataGasLift.mutateAsync({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: inputWellInformation.county,
        field: inputWellInformation.field,
        well: inputWellInformation.well_id,
        username: user,
        data: {...inputWellInformation, data_alert: [], data_check: []}
      });
      
      if (queryDataTuning?.statusCode === 200075 && queryDataTuning?.data) {
  
        const arrayGaugePressure = queryDataTuning?.data?.gauge_data.map(item=> item.gauge_tvd);
        const arrayDataDeviation = inputWellInformation.dataDeviation.map(item=> item.measured_depth);
        const arrayTVD = inputWellInformation.dataDeviation.map(item=> item.tvd);
        const stateCorrelation = queryDataTuning?.data?.active_checks[0] === 0 ? "BB" : queryDataTuning?.data?.active_checks[0] === 1 ? "DR" : queryDataTuning?.data?.active_checks[0] === 2 ? "FB" : queryDataTuning?.data?.active_checks[0] === 3 ? "HB" : "W";
        const stateWellType = inputWellInformation.well_type === "producer" ? true : false
  
        const dataCalculatelVLP = {
          res_pressg: Number(queryDataTuning?.data?.reservoir_pressure),
          res_templ: Number(queryDataTuning?.data?.reservoir_temperature),
          mid_perf_tvd: Number(queryDataTuning?.data?.mid_perf_tvd_depth),
          qo_sc: Number(queryDataTuning?.data?.oil_rate),
          qg_sc: Number(queryDataTuning?.data?.gas_rate) === 0 ? null : Number(queryDataTuning?.data?.gas_rate),
          gor: Number(queryDataTuning?.data?.gor_pp) === 0 ? null : Number(queryDataTuning?.data?.gor_pp),
          w_cut: Number(queryDataTuning?.data?.water_cut),
          qg_inj_sc: Number(queryDataTuning?.data?.injection_gas_rate),
          inj_pressg: Number(queryDataTuning?.data?.injection_pressure),
          wh_pressg: Number(queryDataTuning?.data?.wellhead_pressure),
          wh_temp: Number(queryDataTuning?.data?.wellhead_temperature),
          inj_depth1: Number(queryDataTuning?.data?.gas_lift_valve_depth_1),
          inj_depth2: Number(queryDataTuning?.data?.gas_lift_valve_depth_2),
          inj_depth3: Number(queryDataTuning?.data?.gas_lift_valve_depth_3),
          API: Number(inputWellInformation.res_oil_gravity),
          pb: Number(inputWellInformation.bubble_point_pressure),
          rsb: Number(inputWellInformation.res_initial_gor),
          sgg: Number(inputWellInformation.res_gas_gravity),
          yco2: Number(inputWellInformation.res_CO2),
          yh2s: Number(inputWellInformation.res_H2S),
          yn2: Number(inputWellInformation.res_N2),
          ppm: Number(inputWellInformation.res_water_salinity),
          num_interval: Number(queryDataTuning?.data?.number_of_intervals),
          parameter1: Number(queryDataTuning?.data?.paramter1_vlp),
          parameter2: Number(queryDataTuning?.data?.paramter2_vlp),
          depth_press_test_values: arrayGaugePressure,
          type_correlation: stateCorrelation,
          is_producer: stateWellType,
          measured_depth_table: arrayDataDeviation,
          true_vertical_depth_table: arrayTVD,
          switch_lab: inputWellInformation.fluidLab,
          correlation: inputWellInformation.calculate_correlation,
          correlation_2: inputWellInformation.calculate_correlation_oil,
        };
  
        const dataUnstructuredTubing = queryDataTuning?.data?.tubing_data.reduce((resultado, objeto, index) => {
          resultado[`MD${index + 1}`] = objeto.measured_depth;
          resultado[`ID${index + 1}`] = objeto.internal_diameter;
          resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
          return resultado;
        }, {});
    
        const dataCalculateVLP = Object.assign({}, dataCalculatelVLP,dataUnstructuredTubing);
        
        await mutationCreateCalTuningVLP.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputWellInformation.county,
          field: inputWellInformation.field,
          wellid: inputWellInformation.well_id,
          username: user,
          data: dataCalculateVLP,
        });
  
        await new Promise(resolve => setTimeout(resolve, 1000));
        const dataTuningEnd = {
          ...queryDataTuning?.data,
          well_id: inputWellInformation.well_id,
          well_name: inputWellInformation.well_name,
          well_platforms: inputWellInformation.well_platforms
        }
  
        await mutationCreateDataTuning.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputWellInformation.county,
          field: inputWellInformation.field,
          wellid: inputWellInformation.well_id,
          username: user,
          data: dataTuningEnd,
        });
      };
      
      await new Promise(resolve => setTimeout(resolve, 1000));
  
      if(arrayDataHistory){
        
        await mutationCreateDataHistory.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputWellInformation.county,
          field: inputWellInformation.field,
          wellid: inputWellInformation.well_id,
          username: user,
          data: arrayDataHistory,
        });
      }
  
      await new Promise(resolve => setTimeout(resolve, 100));
      await Promise.all([
        refetchDataTuning(),
        refetchListWellsGasLift(),
        refetchListDataHistory(),
        refetchDataWellGasLift(),
        refetchDataHistory(),
      ]);
    }
    await new Promise(resolve => setTimeout(resolve, 500));
    setLoadCopy(false)
    setOpenModalCopy(false);
    setInputEmpty(false);
    setValidationWells([]);
    setmultiUserCopy(dataWellCopyUser);
    setInputWellInformation(DATA_CREATE_WELL_GAS_LIFT);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        className={`text-white rounded-md ${validationWells[label] ? "bg-[#991b1b]" : "bg-[#662D91]"}`}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  function wellIdExists(name, value, data) {
    
    const val = data?.some((obj) => obj[name] === value);
    return val
  }

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <Modal
        title="Copy Well Gas Lift"
        centered
        open={openModalCopy}
        onOk={() => {
          if (!loadCopy) {
            handleCopyWell(enableMultiUser && !validationWellEnd ? "validate_multi_user" : "save");
          }
        }}
        onCancel={() => {
          if (!loadCopy) {
            handleModalCopyWell(false);
          }
        }}
        width={700}
        className="rounded-md"
        okButtonProps={{
          className: enableMultiUser && !validationWellEnd ? "bg-[#2c7233]" : "bg-[#662D91]",
          disabled: loadCopy
        }}
        cancelButtonProps={{disabled: loadCopy}}
        okText={enableMultiUser && !validationWellEnd ? "validate" : "OK"}
      >
        {loadCopy ? 
          <div 
            className="flex flex-col w-full text-center justify-center mt-8"
            style={{ height: enableMultiUser ? '28em' : '23em' }}
          >
            <Loading/>
          </div>
         : 
          <div className="flex flex-col w-full h-full text-center justify-center mt-8">
            <div 
              className={`flex w-full justify-center text-center mb-[0]`}
              style={{ height: enableMultiUser ? '28em' : '23em' }}
              >
              <div className="grid grid-cols-2 text-right w-[90%] justify-start items-center h-[10em]">
                {User?.role !== "Simple User" &&
                  <>
                    <label
                        htmlFor=""
                        className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                      Copy Multi Users
                    </label>
                    <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                      <Tooltip title={"Multi User"} color={"#662D91"}>
                        <Switch
                          style={{
                            backgroundColor: enableMultiUser ? "#662D91" : "#707070",
                            borderColor: enableMultiUser ? "#662D91" : "#707070",
                          }}
                          checked={enableMultiUser}
                          onChange={handleSwitchMultiUser}
                        ></Switch>
                      </Tooltip>
                    </div>
                  </>
                }
                <label
                  htmlFor=""
                  className="flex max-w-[50px] text-start font bold text-[15px] mb-[7px]"
                >
                  Well ID
                </label>
                <div className="flex flex-row w-full h-[47px] gap-x-2 text-right justify-start items-center mb-[17px]">
                  <input
                    type="text"
                    name="well_id"
                    value={inputWellInformation.well_id}
                    onChange={handleChangeInformation}
                    className={`flex w-2/3 h-[47px] rounded-[4px] px-3  border border-solid  ${
                      (inputEmpty && inputWellInformation.well_id === "") ||
                      inputWellInformation.well_id === null
                        ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                    } `}
                  />
                  <button
                    onClick={handleClickGenerateId}
                    className="flex w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                  >
                    Generate ID
                  </button>
                </div>
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Platforms
                </label>
                <Select
                  style={{
                    backgroundColor: inputEmpty && inputWellInformation.well_platforms === "" ? "#B91C1C" : "",
                    border: inputEmpty && inputWellInformation.well_platforms === "" ? "1px solid #B91C1C" : "",
                    borderRadius: "8px",
                    textAlignalign: "left"
                  }}
                  name="well_platforms"
                  value={inputWellInformation.well_platforms || "Select option"}
                  className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                  onChange={(value) => handleChangeSelect("well_platforms", value)}
                >
                  {dataListPlatforms?.data?.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Discretized well
                </label>
                <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                  <Switch
                    style={{
                      backgroundColor: inputWellInformation.discretized_well ? "#662D91" : "#707070",
                      borderColor: inputWellInformation.discretized_well ? "#662D91" : "#707070",
                    }}
                    checked={inputWellInformation.discretized_well}
                    onChange={handleSwitch}
                  ></Switch>
                </div>
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Well name
                </label>
                {inputWellInformation.discretized_well ?
                  <Select
                    style={{
                      backgroundColor: inputEmpty && inputWellInformation.well_name === "" ? "#B91C1C" : "",
                      border: inputEmpty && inputWellInformation.well_name === "" ? "1px solid #B91C1C" : "",
                      borderRadius: "8px",
                      textAlignalign: "left"
                    }}
                    name="fluid_type"
                    value={inputWellInformation.well_name || ""}
                    className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                    onChange={(value) => handleChangeSelect("well_name", value)}
                  >
                  <Option value="">Selected option</Option>
                  {dataListPlatformsWells?.data?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                  ))}
                  </Select>
                :
                  <input
                    type="text"
                    name="well_name"
                    value={inputWellInformation.well_name}
                    onChange={handleChangeInformation}
                    className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid px-3 ${
                      (inputEmpty && inputWellInformation.well_name === "") || inputWellInformation.well_name === null
                        ? " border-red-700 bg-yellow-100"
                        : "border border-solid border-[#BCBCCC]"
                    } `}
                  />
                }
                {(enableMultiUser && User?.role !== "Simple User") && (
                  <>
                    <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                      <label
                          htmlFor=""
                          className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                        >
                        Users
                      </label>
                    </div>
                    <Select
                      style={{
                        backgroundColor: inputEmpty && inputWellInformation.well_platforms === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputWellInformation.well_platforms === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="users"
                      value={multiUserCopy.user}
                      className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("option_select", value)}
                      mode="multiple"
                      tagRender={tagRender}
                      options={userOption}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        }
      </Modal>
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full  gap-4 pr-2 pl-[90px] p-5">
        <div className="flex flex-row gap-5 pl-[76px] pr-[76pc]">
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(0)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Well Confing
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(1)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add PVT data
              {/* Add Fluid data */}
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(2)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Reservoir
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(3)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add VLP Tuning
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(4)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Inflow/Outflow
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <ImportFileHistory uploadfile={handleUploadFile}></ImportFileHistory>
          </div>
        </div>
        {(errorListDataHistory  && listWells === null  ) ? <ErrorLoadingData /> :

        <ul className="flex flex-col gap-y-[17px]">
          {data?.map((item, index) => {
            const tuningExists = listTuning?.some(
              (tuningItem) => tuningItem.well_id === item.well_id
            );
            const historyExists = Array.isArray(listDataHistory) && listDataHistory.some(
              (itemHistory) => itemHistory.wellid === item.well_id
            );
            return (
              <li
                key={index}
                className="flex flex-row w-full  border-solid  p-5 rounded-md"
              >
                <div className="flex flex-row w-full gap-5">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium text-[15px] border border-[#BCBCCC] bg-[#FAFAFA] ">
                      <tr style={{ width: '100%' }}>
                        <th scope="col" className="px-6 py-4" style={{ width: '15%' }}>
                          Select well
                        </th>
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Well Name
                        </th>
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Well ID
                        </th> 
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Description
                        </th>
                        <th scope="col" className="px-6 py-4 justify-center text-center items-center" style={{ width: '20%' }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-b font-medium text-[15px]  border border-[#BCBCCC]">
                      <tr>
                        <td className="whitespace-nowrap px-6 py-4" rowSpan={ historyExists || item.wellConfig.flowConfig || tuningExists || item.wellConfig.fluidConfig || item.wellConfig.reservoir ? 6  : 1}>
                          <Checkbox
                            className={"custom-checkbox"}
                            onChange={(e) => {
                              handleChangeCheckWell(e.target.checked, item.well_id);
                              getSelectedWell(e.target.checked ? item.well_id : null);
                            }
                            }
                            checked={selectedWell ? item.well_id === dataFilterUserGasLift?.well_id : false}
                          ></Checkbox>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                          {item.well_name}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4" rowSpan={ historyExists || item.wellConfig.flowConfig || tuningExists || item.wellConfig.fluidConfig || item.wellConfig.reservoir ? 6 : 1} >
                          {item.well_id}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4" >
                          well's data 
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                          <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined 
                              key="edit" 
                              onClick={() => handleClickButtonEdit("wellConfig", item.well_id)} 
                              style={{ color: '#707070' }}/>
                          </Tooltip>
                          <span className="mx-2"></span>
                          <Tooltip title={"Copy well"} color={"#662D91"}>
                            <CopyOutlined 
                              key="copyWell" 
                              onClick={() => handleModalCopyWell(item.well_id, true)} 
                              style={{ color: '#707070' }}/>
                          </Tooltip>
                          <span className="mx-2"></span>
                          <Tooltip title={"Delete the well"} color={"#662D91"}>
                            <DeleteOutlined key="deleteWell" onClick={() => handleDelete(item.well_id)} style={{ color: '#707070' }}/>
                          </Tooltip>
                        </td>
                      </tr>

                      {item.wellConfig.fluidConfig && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Fluid config
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's fluid config
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("fluidConfig", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete" onClick={() => handleClickButtonDelete("fluidConfig", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {item.wellConfig.reservoir && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Reservoir
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's reservoir data  
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("reservoir", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete"  onClick={() => handleClickButtonDelete("reservoir", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {tuningExists && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            VLP Tuning
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            VLP Tuning of the well 
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("tuning", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete" onClick={() => handleClickButtonDelete("tuning", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {item.wellConfig.flowConfig && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Inflow Outflow
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            Inflow Outflow of the well
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                            <EditOutlined key="edit" onClick={() => handleClickButtonEdit("flowConfig", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-6"></span>  
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined key="delete" onClick={() => handleClickButtonDelete("flowConfig", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                      {historyExists && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Data history
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's production history data 
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined key="delete" onClick={() => handleClickButtonDelete("history", item.well_id)} style={{ color: '#707070' }}/>
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
            );
          })}
        </ul>
        }
      </div>
    </div>
  );
};