export const validateParams = (...params) => {
	if (
		params.some((param) => param === undefined || param === null || param === "")
	) {
		return false;
	} else {
		return true;
	}
};

export const parseCSV = (csvText) => {
	const lines = csvText.trim().split("\n");
	const headers = lines[0].split(",");

	return lines.slice(1).map((line) => {
		const values = line.split(",");
		return headers.reduce((acc, header, index) => {
			acc[header.trim()] = isNaN(values[index])
				? values[index].trim()
				: parseFloat(values[index]);
			return acc;
		}, {});
	});
};
