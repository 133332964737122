export const config = {
	API: {
		PORT_DB: process.env.REACT_APP_PORT_DB,
		DCA: {
			BASE_URL_DCA: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
			BASE_URL_DATA_HISTORY: process.env.REACT_APP_BASE_URL_DATA_HISTORY_DCA,
			BASE_URL_VOLUMETRACKER: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
			BASE_URL_VOLUMETRACKER_RT: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
			BASE_URL_DEVICE: process.env.REACT_APP_BASE_URL_PLOTTING,
		},
		FLOW: {
			BASE_URL_FLOW: process.env.REACT_APP_BASE_URL_PROCESS,
		},
		FORECAST: {
			FILE_FORECAST: process.env.REACT_APP_FILE_FORECAST,
			RUN_FORECAST: process.env.REACT_APP_RUN_FORECAST,
			OUTPUT_FORECAST: process.env.REACT_APP_OUTPUT_FORECAST,
		},
		INJECTOR: {
			BASE_URL_INJECTOR: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
			BASE_URL_DATA_HISTORY: process.env.REACT_APP_BASE_URL_DATA_HISTORY_INJECTOR,
			BASE_URL_VOLUMETRACKER: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
			BASE_URL_VOLUMETRACKER_RT: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
		},
		GASLIFT: {
			BASE_URL_WELL: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
			BASE_URL_DATA_HISTORY: process.env.REACT_APP_BASE_URL_DATA_HISTORY_GASLIFT,
			BASE_URL_VOLUMETRACKER: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
			BASE_URL_VOLUMETRACKER_RT: process.env.REACT_APP_BASE_URL_VOLUMETRACKER,
			BASE_URL_DEVICE: process.env.REACT_APP_BASE_URL_PLOTTING,
			BASE_URL_3D: process.env.REACT_APP_BASE_URL_PROCESS,

		},
		IOT: {
			BASE_URL_IOT: process.env.REACT_APP_BASE_URL_IOT,
		},
		IPR: {
			BASE_URL_IPR: process.env.REACT_APP_BASE_URL_PROCESS,
		},
		MAP: {
			DB_URL_LIST_MAP: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
			DB_URL_ALERT_MAP: process.env.REACT_APP_BASE_URL_ALERTS_MAP,
		},
		PVT: {
			BASE_URL_PVT: process.env.REACT_APP_BASE_URL_PROCESS,
		},
		TUNING: {
			BASE_URL_TUNING: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
			BASE_URL_TUNING_VLP: process.env.REACT_APP_BASE_URL_PROCESS,
		},
		USERS: {
			URL_FILTER: process.env.REACT_APP_BASE_URL_PARAMETERS_WELL,
		},
		SCREENING: {
			URL_SCREENING: process.env.REACT_APP_BASE_ULR_SCREENING_TOOL,
		},
		PLOTTING: {
			URL_PLOTTING: process.env.REACT_APP_BASE_URL_PLOTTING,
			URL_PARAMETERS_PLOTTING: process.env.REACT_APP_BASE_URL_PARAMETERS_PLOTTING,
			URL_PRODUCTION_TEST: process.env.REACT_APP_BASE_URL_PRODUCTION_TEST,
		},
		PLUNGER_LIFT: {
			URL_PLUNGER_LIFT: process.env.REACT_APP_BASE_URL_CASING_PRESSURE,
			BASE_URL_DATA_HISTORY:
				process.env.REACT_APP_BASE_URL_DATA_HISTORY_PLUNGER_LIFT,
		},
		MULTI_WELL: {
			URL_MULTI_WELL: process.env.REACT_APP_BASE_URL_MULTI_WELL,
		},
	},
	AUTH: {
		TOKEN_HISTORIC: process.env.REACT_APP_TOKEN_HISTORIC,
		TOKEN_VOLUMETRACKER: process.env.REACT_APP_TOKEN_VOLUMETRACKER,
		TOKEN_DCA: process.env.REACT_APP_TOKEN_PARAMETERS,
		TOKEN_GAS_LIFT: process.env.REACT_APP_TOKEN_PARAMETERS,
		TOKEN_PLUNGER_LIFT: process.env.REACT_APP_TOKEN_PARAMETERS,
		TOKEN_TUNING: process.env.REACT_APP_TOKEN_PARAMETERS,
		TOKEN_INJECTOR: process.env.REACT_APP_TOKEN_PARAMETERS,
		TOKEN_FILTER: process.env.REACT_APP_TOKEN_PARAMETERS,
		TOKEN_PARAMETERS_PLOTTING: process.env.REACT_APP_TOKEN_PARAMETERS_PLOTTING,
		TOKEN_SCREENING: process.env.REACT_APP_TOKEN_SCREENING,
		TOKEN_IOT: process.env.REACT_APP_TOKEN_IOT,
		TOKEN_IPR_GAS_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_PVT_GAS_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_VLP_GAS_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_FLOW_GAS_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_SCENARIOS_IPR_GAS_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_SCENARIOS_VLP_GAS_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_FORECAST_UPLOAD: process.env.REACT_APP_TOKEN_FORECAST_UPLOAD,
		TOKEN_FORECAST_RUN: process.env.REACT_APP_TOKEN_FORECAST_RUN,
		TOKEN_FORECAST_OUTPUT: process.env.REACT_APP_TOKEN_FORECAST_OUTPUT,
		TOKEN_PLOTTING: process.env.REACT_APP_TOKEN_PLOTTING,
		TOKEN_ALERTS: process.env.REACT_APP_TOKEN_ALERTS,
		TOKEN_MULTI_WELL: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_PROCESS_PLUNGER_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_IPR_PLUNGER_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_PVT_PLUNGER_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_SCENARIOS_IPR_PLUNGER_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_GRAPH_EXCEL_PLUNGER_LIFT: process.env.REACT_APP_TOKEN_PROCESS,
		TOKEN_PRODUCTION_TEST: process.env.REACT_APP_TOKEN_PRODUCTION_TEST,
	},
};

export const keyIntegration = {
	keyEncryptPass: process.env.REACT_APP_KEYENCRYPT_PASS,
	keyEncrypt: process.env.REACT_APP_KEYENCRYPT,
};
