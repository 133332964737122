import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { getUsersData } from '../services/api/usersAPI'
import { AuthContext } from '../auth/context/AuthContext'

const initialDataUser = {
    user_name: "",
    user_password: "",
    clients: "",
    proyects: "",
    platforms: "",
}

export const useCrudUsers = () => {
    const { User } = useContext(AuthContext);
    const [enabledUserExt, setEnabledUserExt] = useState(false);
    const [enabledUserInt, setEnabledUserInt] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [modalEditar, setModalEditar] = useState(false);
    const [modalEliminar, setModalEliminar] = useState(false);
    const [usuarioSeleccionado, setUserSelect] = useState(initialDataUser);
    const [error, setError] = useState();


    const { data: dataUsersExternal, isLoading: loadUserExternal, isError: ErrorUsersExternal } = useQuery({
        queryKey: ["UsersData"],
        retry: 2,
        //refetchInterval: 10000, // Refresca cada 10 minutos (en milisegundos)
        enabled: enabledUserExt,
        queryFn: () => getUsersData(
            User.token,
            'users_externals'
        ),
        onError: () => {
            setError("No exist");
            setEnabledUserExt(false);
        },
        onSuccess: () => {
            setEnabledUserExt(false);
        }
    })

    const { data: dataUsersInternal, isLoading: loadUserInternal, isError: ErrorUsersInternal } = useQuery({
        queryKey: ["UsersData"],
        retry: 2,
        enabled: enabledUserInt,
        queryFn: () => getUsersData(
            User.token,
            'users_enovates'
        ),
        onError: () => {
            setEnabledUserInt(false);
        },
        onSuccess: () => {
            setEnabledUserInt(false);
        }
    })
    const filterInternal = (searchTermn) => {
        if (searchTermn === "") {
            getUsersData();
            setCurrentPage(1);
        } else {
            var searchResult = tasks.filter((elemento) => {
                if (
                    elemento.user_name?.toString().toLowerCase().includes(searchTermn.toLowerCase()) ||
                    elemento.clients?.toString().toLowerCase().includes(searchTermn.toLowerCase()) ||
                    elemento.proyects?.toString().toLowerCase().includes(searchTermn.toLowerCase()) ||
                    elemento.iduser?.toString().toLowerCase().includes(searchTermn.toLowerCase())
                ) {

                    setCurrentPage(1);
                    return elemento;
                } else {
                    return null
                }
            });
            if (searchResult) {
                setUsers(searchResult);
                setCurrentPage(1);
            } else {
                setUsers(tasks);
                setCurrentPage(1);
            }
        }
    };
    const updateTasks = (newTasks) => {
        setTasks(newTasks);
    };

    const searchUserInternal = (e) => {
        setSearch(e.target.value);
        filterInternal(e.target.value);
    };

    const cerrarModalEditar = (dataType) => {
        setModalEditar(false);
        if(dataType === "users_enovates"){
            setTimeout(() => {
                setEnabledUserInt(true);
            }, 100);

        }else if(dataType === "users_externals"){
            setTimeout(() => {
                setEnabledUserExt(true);
            }, 100);
        }


    };
    const cerrarModalEliminar = (dataType) => {
        setModalEliminar(false);
        if(dataType === "users_enovates"){
            setTimeout(() => {
                setEnabledUserInt(true);
            }, 100);

        }else if(dataType === "users_externals"){
            setTimeout(() => {
                setEnabledUserExt(true);
            }, 100);
        }
    };

    return {
        users,
        usuarioSeleccionado,
        error,
        dataUsersExternal,
        dataUsersInternal,
        search,
        modalEditar,
        modalEliminar,
        currentPage,
        searchUserInternal,
        setCurrentPage,
        setEnabledUserExt,
        setEnabledUserInt,
        updateTasks,
        cerrarModalEditar,
        cerrarModalEliminar,
        setUserSelect,
        tasks,
        setTasks,
        setUsers,
        setError,
        loadUserExternal,
        ErrorUsersExternal,
        loadUserInternal,
        ErrorUsersInternal,
        setModalEditar,
        setModalEliminar,
    }
}
