import { useEffect, useState } from "react";
import { INITIAL_DATA_CARDS_DCA } from "../components/elements/types/type_dca";
import { WELL_FILTER } from "../components/elements/types/type_dashboard";

const initialViewLoadingDCA = {
	loadSaveParameters: false,
};

const initialValidateAlertDCA = {
	alertCard: false,
	alertDomain: false,
};

export const useDashboardDCA = () => {
	const [dataFilterUserDCA, setDataFilterUserDCA] = useState(WELL_FILTER);
	const [checkedValuesFirstCol, setCheckedValuesFirstCol] = useState([]);
	const [checkedValuesLastCol, setCheckedValuesLastCol] = useState([]);
	const [dataCardsDCA, setDataCardsDCA] = useState(INITIAL_DATA_CARDS_DCA);
	const [viewLoadingDCA, setViewLoadingDCA] = useState(initialViewLoadingDCA);
	const [validateAlertDCA, setValidateAlertDCA] = useState(
		initialValidateAlertDCA,
	);

	useEffect(() => {
		const getDataFilterUserDCA = JSON.parse(
			sessionStorage.getItem("dataFilterUserDCA"),
		);
		const getCheckedValuesFirstCol = JSON.parse(
			sessionStorage.getItem("checkedValuesFirstCol"),
		);
		const getCheckedValuesLastCol = JSON.parse(
			sessionStorage.getItem("checkedValuesLastCol"),
		);

		if (getDataFilterUserDCA) {
			setDataFilterUserDCA(getDataFilterUserDCA);
		}
		if (getCheckedValuesFirstCol) {
			setCheckedValuesFirstCol(getCheckedValuesFirstCol);
		}
		if (getCheckedValuesLastCol) {
			setCheckedValuesLastCol(getCheckedValuesLastCol);
		}
	}, []);

	const getDataFilterUserDCA = (options) => {
		setDataFilterUserDCA(options);
		sessionStorage.setItem("dataFilterUserDCA", JSON.stringify(options));
	};

	const getCheckedValuesFirstCol = (options) => {
		setCheckedValuesFirstCol(options);
		sessionStorage.setItem("checkedValuesFirstCol", JSON.stringify(options));
	};

	const getCheckedValuesLastCol = (options) => {
		setCheckedValuesLastCol(options);
		sessionStorage.setItem("checkedValuesLastCol", JSON.stringify(options));
	};

	const getDataCardsDCA = (options) => {
		setDataCardsDCA(options);
	};

	const getViewLoadingDCA = (options) => {
		setViewLoadingDCA(options);
	};

	const getValidateAlertDCA = (options) => {
		setValidateAlertDCA(options);
	};

	return {
		dataFilterUserDCA,
		checkedValuesFirstCol,
		checkedValuesLastCol,
		dataCardsDCA,
		validateAlertDCA,
		viewLoadingDCA,
		getDataFilterUserDCA,
		getCheckedValuesFirstCol,
		getCheckedValuesLastCol,
		getDataCardsDCA,
		getViewLoadingDCA,
		getValidateAlertDCA,
	};
};
