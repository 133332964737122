import React, { useState } from "react";
import { ReactComponent as IconDownload } from "../../../../assets/img/icons/downloadTemplate.svg";
import classes from "./DownloadTemplate.module.css";
import * as XLSX from "xlsx";
import { Modal } from "antd";

const { containerBtn, downloadTemplate } = classes;

const DownloadTemplate = ({ template, fileName }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDownload = () => {
    if (!template) {
      console.error("No valid template received.");
      return;
    }

    const workbook = XLSX.utils.book_new();
    const headers = template.columns.map((col) => col.header);
    const rows = template.rows;
    const sheetData = [headers, ...rows];
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    worksheet["!cols"] = generateColumnWidths(sheetData, template.columns);
    worksheet["!rows"] = generateRowHeights(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    const excelData = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });
    const blob = new Blob([s2ab(excelData)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName || `${template.name || "template"}.xlsx`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  };

  const generateColumnWidths = (data, columns) => {
    const columnWidths = [];
    data.forEach((row) => {
      row.forEach((cell, colIndex) => {
        const cellLength = cell ? cell.toString().length : 0;
        columnWidths[colIndex] = Math.max(
          columnWidths[colIndex] || 0,
          cellLength
        );
      });
    });
    return columnWidths.map((width, index) => ({
      wch: Math.max(width + 2, 10),
      hidden: columns[index]?.key === "item",
    }));
  };

  const generateRowHeights = (data) => {
    return data.map((row) => ({
      hpx:
        Math.max(...row.map((cell) => (cell ? cell.toString().length : 0))) > 30
          ? 25
          : 15,
    }));
  };

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleDownload();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button
        onClick={showModal}
        className={containerBtn}
        aria-label="Download Template"
      >
        <IconDownload className={downloadTemplate} />
      </button>

      <Modal
        centered
        title="Alert"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
      >
        <p>
          Please do not modify the column names (headers) in the downloaded
          file. This may cause errors when loading the file.
        </p>
      </Modal>
    </>
  );
};

export default DownloadTemplate;
