export const OPTIONS_PLOTTING = [
    {
        value: 'Static Pressure',
        label: 'Pressure',
        domain: [0,0]
    },
    {
        value: 'Differential Pressure',
        label: 'Differential Pressure',
        domain: [0,0]
    },
    {
        value: 'Flowing Temperature',
        label: 'Flowing Temperature',
        domain: [0,0]
    },
    {
        value: 'Liquid Flow Rate',
        label: 'Liquid Flow Rate',
        domain: [0,0]
    },
    {
        value: 'Liquid Previous Day Total',
        label: 'Liquid Yesterday Volume',
        domain: [0,0]
    },
    {
        value: 'Gas Flow Rate',
        label: 'Gas Flow Rate',
        domain: [0,0]
    },
    {
        value: 'Corrected Current Day Volume',
        label: 'Gas Daily Volume',
        domain: [0,0]
    },
    {
        value: 'Corrected Previous Day Volume',
        label: 'Gas Yesterday Volume',
        domain: [0,0]
    },
    {
        value: 'Corrected Accumulated Volume',
        label: 'Gas Current Month Volume',
        domain: [0,0]
    },
    {
        value: 'Meter 1 Current Day Volume',
        label: 'Meter 1 Current Day Volume',
        domain: [0,0]
    },
    {
        value: 'Meter 1 Flow Rate',
        label: 'Meter 1 Flow Rate',
        domain: [0,0]
    },
    {
        value: 'Meter 1 Net Accumulated Volume',
        label: 'Meter 1 Net Accumulated Volume',
        domain: [0,0]
    },
    {
        value: 'Meter 1 Previous Day Volume',
        label: 'Meter 1 Previous Day Volume',
        domain: [0,0]
    },
    {
        value: 'Meter 2 Current Day Volume',
        label: 'Meter 2 Current Day Volumee',
        domain: [0,0]
    },
    {
        value: 'Meter 2 Flow Rate',
        label: 'Meter 2 Flow Rate',
        domain: [0,0]
    },
    {
        value: 'Meter 2 Net Accumulated Volume',
        label: 'Meter 2 Net Accumulated Volume',
        domain: [0,0]
    },
    {
        value: 'Meter 2 Previous Day Volume',
        label: 'Meter 2 Previous Day Volume',
        domain: [0,0]
    },
    {
        value: 'C6 Well Head Pressure',
        label: 'C6 Well Head Pressure',
        domain: [0,0]
    },
    {
        value: 'C9 Well Head Pressure',
        label: 'C9 Well Head Pressure',
        domain: [0,0]
    }
]

export const INITIAL_DATA_PLOTTING = {
    selected_platform:"",
    selected_device:[],
    selected_parameter:[],
    optionsParmsPlotting:[],
    dataPlotting: []
}

export const COLORS_LINES = ["#662D91", "#C03650", "#A393D6", "#3b50b2", "#60C7EB", "#FCA200", "#92D051", "#4d3a43", "#155263", "#f70776"]
export const LINE_TYPES = ["monotone", "linear", "step", "stepBefore", "stepAfter", "basis", "cardinal", "natural"];
export const LINE_STYLES = ["0", "5 5", "2 2", "10 5", "5 5 2 5", "10 5 2 5"];
