import React from 'react'
import { DashboardDCAContext } from './DashboardDCAContext'
import { useDashboardDCA } from '../../hooks/useDashboardDCA'

export const DashboardDCAProvider = ({children}) => {
    const {
        wellDataInfo,
        dataCreatedDCA,
        dataFilterUserDCA,
        dataHistoryDCA,
        checkedValuesFirstCol,
        checkedValuesLastCol,
        dataCardsDCA,
        getWellDataInfo,
        getDataCreatedDCA,
        getDataCreated,
        getDataFilterUserDCA,
        handleSelectWellDCA,
        getDataHistoryDCA,
        getCheckedValuesFirstCol,
        getCheckedValuesLastCol,
        getDataCardsDCA,
    } = useDashboardDCA();
      return (
        <DashboardDCAContext.Provider value={{   
            wellDataInfo, 
            dataCreatedDCA,
            dataFilterUserDCA,
            dataHistoryDCA,
            checkedValuesFirstCol,
            checkedValuesLastCol,
            dataCardsDCA,
            getWellDataInfo,
            getDataCreatedDCA,
            getDataCreated,
            getDataFilterUserDCA,
            handleSelectWellDCA,
            getDataHistoryDCA,
            getCheckedValuesFirstCol,
            getCheckedValuesLastCol,
            getDataCardsDCA
        }}>
            {children}
        </DashboardDCAContext.Provider>
      )
}
