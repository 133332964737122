import React from "react";
import Logo from "../assets/img/Logo-Load.png";
import { Alert } from "antd";

export const Loading = ({ messageLoading }) => {
	return (
		<div className={`bg-transparent`}>
			<div
				className={`h-screen text-center justify-center items-center flex flex-col`}
			>
				<div className="flex text-center justify-center items-center">
					<img
						src={Logo}
						className="w-20 h-20 m-2 animate-spin"
						alt=""
						loading="loading"
					/>
				</div>
				{messageLoading && (
					<p className="mt-4 text-sm text-gray-600">{messageLoading}</p>
				)}
			</div>
		</div>
	);
};

export const LoadingForecast = () => {
	return (
		<div className={`bg-transparent`}>
			<div
				className={`h-screen text-center justify-center items-center flex flex-col`}
			>
				<div className="flex text-center justify-center items-center">
					<img
						src={Logo}
						className="w-20 h-20 m-2 animate-spin"
						alt=""
						loading="loading"
					/>
				</div>
				<div>
					<p className="mt-4 text-lg text-gray-700">
						Please wait, this process will take 1 to 3 minutes
					</p>
				</div>
			</div>
		</div>
	);
};

export const LoadingGrapchicForecast = () => {
	return (
		<div className={`bg-transparent`}>
			<div
				className={`h-[800] text-center justify-center items-center flex flex-col mt-[10px]`}
			>
				<div className="flex text-center justify-center items-center">
					<img
						src={Logo}
						className="w-20 h-20 m-2 animate-spin"
						alt=""
						loading="loading"
					/>
				</div>
			</div>
		</div>
	);
};

export const LoadingMap = () => {
	return (
		<div className="h-screen flex justify-center items-center bg-white fixed top-0 left-0 right-0 bottom-0">
			<div className="flex flex-col items-center">
				<img
					src={Logo}
					className="w-20 h-20 animate-spin"
					alt="Loading Icon"
					loading="loading"
				/>
			</div>
		</div>
	);
};

export const LoadingResponse = () => {
	return (
		<div className={`bg-transparent`}>
			<div
				className={`h-screen text-center justify-center items-center flex flex-col`}
			>
				<div className="flex text-center justify-center items-center">
					<img
						src={Logo}
						className="w-20 h-20 m-2 animate-spin"
						alt=""
						loading="loading"
					/>
				</div>
			</div>
		</div>
	);
};

export const LoadingDashboard = () => {
	return (
		<div className="w-full h-full flex justify-center items-center bg-white top-0 left-0 right-0 bottom-0">
			<div className="flex flex-col items-center">
				<img
					src={Logo}
					className="w-20 h-20 animate-spin"
					alt="Loading Icon"
					loading="loading"
				/>
			</div>
		</div>
	);
};
