import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { Navbar } from '../../components/layout/Navbar'
import  ArrowdownIcon  from '../../assets/icon/arrow-down-purple.png';
import  CircleIcon  from '../../assets/icon/circle.png';
import  PolygonIcon  from '../../assets/icon/polygon.png';
import  MoveIcon  from '../../assets/icon/move.png';
import  DeleteIcon  from '../../assets/icon/delete.png';
import  BubblesIcon  from '../../assets/icon/bubbles.png';
import  HeatIcon  from '../../assets/icon/heat.png';
import { TableWell } from '../../components/Map/TableWell';
import { initTE, Offcanvas, Ripple} from "tw-elements";
import { MapView } from '../../components/Map/MapView';
import { Sidebar } from '../../components/layout/Sidebar';
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { NAME_PAGES } from '../../components/elements/types/type_pages';
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand1.svg'
import { ReactComponent as ContracrIcon } from '../../assets/icon/contract.svg'
import { Modal } from 'antd';


export const MapWells = () => {
    const { getDataPage } = useContext(DashboardContext);
    const [openModal, setOpenModal] = useState(false);
    const [heightClass, setHeightClass] = useState('h-1/3');

    useEffect(() => {
        initTE({ Offcanvas, Ripple });
        getDataPage(NAME_PAGES.map);
    }, []);

    const [wellboreOptionState, setWellboreOptionState] = useState(false);

    const wellboreOption = () => {
      setWellboreOptionState(!wellboreOptionState);
    };

    const handleExpand = () => {
        setHeightClass('h-3/3')
    };

    const handleColapse = () => {
        setHeightClass('h-1/3')
    };

  return (
    <div className=' w-full h-screen max-h-[840px]'>
        <div className='flex'>
            <Navbar 
                wellboreOption={wellboreOption}
            ></Navbar>
            <Sidebar className="flex abosolute h-full"></Sidebar>
        </div>
        <div className='flex flex-col w-full'>
            <div className="bg-slate-200 flex flex-col w-full h-[868px] gap-2" id="map2" >
                <div className='buttonMap w-full flex flex-row pl-[80px]'>
                    <div className="flex w-full h-full  ml-14 mt-2 gap-2">
                        <button className="w-[40px] h-[40px] rounded-full bg-slate-300 p-2 opacity-50 cursor-not-allowed" disabled><img src={CircleIcon} className=' w-full h-full'/></button>
                        <button className="w-[40px] h-[40px] rounded-full bg-slate-300 p-2 opacity-50 cursor-not-allowed" disabled ><img src={PolygonIcon} className=' w-full h-full'/></button>
                        <button className="w-[40px] h-[40px] rounded-full bg-slate-300 p-2 opacity-50 cursor-not-allowed" disabled ><img src={DeleteIcon} className=' w-full h-full'/></button>
                        <button className="w-[40px] h-[40px] rounded-full bg-slate-300 p-2 opacity-50 cursor-not-allowed" disabled ><img src={MoveIcon} className=' w-full h-full'/></button>
                    </div>
                    <div className="flex w-full h-full text-right items-end justify-end mr-2 mt-2 gap-2">
                        <button className="w-[40px] h-[40px] rounded-full bg-slate-300 p-2 opacity-50 cursor-not-allowed" disabled ><img src={BubblesIcon} className=' w-full h-full'/></button>
                        <button className="w-[40px] h-[40px] rounded-full bg-slate-300 p-2 opacity-50 cursor-not-allowed" disabled ><img src={HeatIcon} className=' w-full h-full'/></button>
                    </div>
                </div>
                <div className='flex w-full h-[100%] bg-black pl-[75px]'>
                    <MapView></MapView>
                </div>
            </div>
            
            <div className='tagTable bottom-0 w-full h-[28px] text-center bg-transparent'>
                <button
                    className="text-center items-center justify-center mr-1.5 inline-block rounded-t-md px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal bg-white text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-pc-purple hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-pc-purple focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    type="button"
                    data-te-offcanvas-toggle
                    data-te-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom"
                    data-te-ripple-init
                    data-te-ripple-color="light">
                    <img src={ArrowdownIcon} alt="" className=" items-center rotate-180"></img>
                </button>
            </div>
        </div>
        <div
                className={`invisible ml-[80px] fixed bottom-0 left-0 right-0 z-[1045] flex ${heightClass} max-h-full max-w-full translate-y-full flex-col border-none bg-neutral-700 bg-clip-padding text-white shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none`}
                tabIndex="-1"
                id="offcanvasBottom"
                aria-labelledby="offcanvasBottomLabel"
                data-te-offcanvas-init>
                <div className="flex items-center justify-between p-4 ">
                    <h5
                        className="flex items-center justify-center w-full mb-0 font-semibold leading-normal text-center "
                        id="offcanvasBottomLabel">
                        LIST WELLS
                    </h5>
                    {heightClass === 'h-1/3' ? (
                        <ExpandIcon
                            className="w-5 h-5 hover:bg-pc-grey3 mr-[8px]"
                            onClick={handleExpand}
                        />
                    ) : (
                        <ContracrIcon
                            className="w-5 h-5 hover:bg-pc-grey3 mr-[8px]"
                            onClick={handleColapse}
                        />
                    )}
                    <button
                        type="button"
                        className="box-content border-none rounded-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                        data-te-offcanvas-dismiss>
                        <span
                            className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="flex-grow p-4 overflow-y-auto small">
                    <TableWell openModal={openModal}></TableWell>
                </div>
            </div>
    </div>
)}
