import React from "react";
import { DashboardInjectorContext } from "./DashboardInjectorContext";
import { useDashboardInjector } from "../../hooks/useDashboardInjector";

export const DashboardInjectorProvider = ({ children }) => {
	const {
		dataFilterUserInjector,
		dataCardsInjector,
		validateAlertInjector,
		viewLoadingInjector,
		getDataFilterUserInjector,
		getDataCardsInjector,
		getViewLoadingInjector,
		getValidateAlertInjector
	} = useDashboardInjector();

	return (
		<DashboardInjectorContext.Provider value={{
			dataFilterUserInjector,
			dataCardsInjector,
			validateAlertInjector,
			viewLoadingInjector,
			getDataFilterUserInjector,
			getDataCardsInjector,
			getViewLoadingInjector,
			getValidateAlertInjector
		}}>
			{children}
		</DashboardInjectorContext.Provider>
	)
}
