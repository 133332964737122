import React, { useEffect, useState } from "react";

const initialDataPlotting = {
	selected_platform: "",
	selected_device: [],
	selected_parameter: [],
	dataConfigGraph: [],
};

const initialViewLoadingGasLift = {
	loadSaveParameters: false,
};

const isEmpty = (value) => {
  return (
    value == null ||
    (typeof value === "string" && value.trim() === "") ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (Array.isArray(value) && value.length === 0)
  );
};

const cleanDataPlotting = (data) => {
  if (!data || !data.dataPlotting) return data;
  return {
    ...data,
    dataPlotting: data.dataPlotting.filter((item) => !isEmpty(item)),
  };
};

export const useDashboardPlotting = () => {

  const [dataPlotting, setDataPlotting] = useState(initialDataPlotting);
  const [viewLoadingPlotting, setViewLoadingPlotting] = useState(initialViewLoadingGasLift);
		const [productionTest, setProductionTest] = useState();

	useEffect(() => {
		const getDomainPlotting = JSON.parse(sessionStorage.getItem("dataPlotting"));
		const getProductiontest = JSON.parse(sessionStorage.getItem("selectedTestProduction"));

		if (getDomainPlotting) {
			const cleanedData = cleanDataPlotting(getDomainPlotting);
			setDataPlotting(cleanedData);
		}
		if (getProductiontest) {
			setProductionTest(getProductiontest);
		}
	}, []);

	const getDataPlotting = (options) => {
		const cleanedData = cleanDataPlotting(options);
		setDataPlotting(cleanedData);
		sessionStorage.setItem("dataPlotting", JSON.stringify(cleanedData));
	};

	const getViewLoadingPlotting = (options) => {
		setViewLoadingPlotting(options);
	};

	const getProductiontest = (options) => {
		setProductionTest(options);
		sessionStorage.setItem("selectedTestProduction", JSON.stringify(options));
	}

	return {
		dataPlotting,
		viewLoadingPlotting,
		productionTest,
		getViewLoadingPlotting,
		getDataPlotting,
		getProductiontest
	};
};
