import { useContext, useState } from "react";
import { AuthContext } from "../auth/context/AuthContext";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { DATA_RESERVOIR } from "../components/elements/types/type_gas_lift";
import { DashboardPLContext } from "../context/DashboardPlungerLift/DashboardPLContext";
import {
	STATUS_TYPE_SCENERY_PLUNGER_LIFT,
	STATUS_TYPE_PLUNGER_LIFT,
	STATUS_CODE_PLUNGER_LIFT,
} from "../components/elements/types/type_response_API";
import {
	calculateQIPRPL,
	uploadJsonIPRPL,
	uploadJsonCasing,
	calculateCasing,
	dataWellPlungerLift,
	uploadJsonWellPL,
	deleteWellPL,
	updateJsonWellPL,
	calculatePressure,
	uploadJsonPressure,
	uploadJsonVolumeTracker,
	listWellPlungerLift,
	calculateInterpolation,
	calculatePVT,
	uploadJsonPVT,
	uploadDataGraphics,
	dataGraphic,
	listDataGraphic,
	deleteScenarioPL,
	deleteListDataGraphic,
	uploadDataHistory,
	getListDataHistory,
	updateDataHistory,
	deleteDataHistory,
	getDataHistory,
	getJsonVolumeTracker,
	getDataAllocation,
} from "../services/api/PlungerLiftAPI";
import { NAME_PAGES } from "../components/elements/types/type_pages";
import { useFilters } from "./useFilters";
import { useNavigate } from "react-router-dom";
import { INITIAL_STATE_PROCESS_PL } from "../components/elements/types/type_plunger_Lift";

const initialDataDeviationApi = {
	md: [],
	tvd: [],
	inc: [],
	t: null,
	p: null,
};

const initiailInputPropiertiesOut = {
	Pb: "",
	Bo: "",
	Uo: "",
	Deno: "",
	SigmaO: "",
	Rs: "",
	Bg: "",
	Ug: "",
	Deng: "",
	Bw: "",
	Uw: "",
	Denw: "",
	SigmaW: "",
};

const initiailInputCalculatedLab = {
	BoUntuned: null,
	PbUntuned: null,
	RsUntuned: null,
	VisoUntuned: null,
	CoUntuned: null,
	BoTuned: null,
	PbTuned: null,
	RsTuned: null,
	VisoTuned: null,
	CoTuned: null,
};

const initialDataTableLab = [
	{
		pressureLab: null,
		BoExp: null,
		RsExp: null,
		VisoExp: null,
		CoExp: null,
		BoUntuned: null,
		RsUntuned: null,
		VisoUntuned: null,
		CoUntuned: null,
		BoTuned: null,
		RsTuned: null,
		VisoTuned: null,
		CoTuned: null,
	},
];

export const useTabPlungerLift = () => {
	const { User } = useContext(AuthContext);
	const navigate = useNavigate();
	const {
		getUserDataInfo,
		userDataInfo,
		selectedWellPL,
		getSelectedWellPL,
		dataPage,
	} = useContext(DashboardContext);
	const {
		getDataFilterPL,
		dataFilterUserPL,
		lastSelectDataIPRPL,
		getSelectDataIPRPL,
		getViewLoadingPlungerLift,
		viewLoadingPlungerLift,
		stateMessageApisPL,
		getStateMessageApisPL,
	} = useContext(DashboardPLContext);
	const [dataDeviationApi, setDataDeviationApi] = useState(
		initialDataDeviationApi,
	);
	const { dataFilter, mutationUpdateFilter } = useFilters();
	const [dataEquipmentApi, setDataEquipmentApi] = useState([]);
	const [bodyAddWell, setBodyAddWell] = useState([]);
	const [inputPropertiesOut, setInputPropertiesOut] = useState(
		initiailInputPropiertiesOut,
	);
	const [viewLoading, setViewLoading] = useState(true);
	const [viewFluidLab, setViewFluidLab] = useState(false);
	const [dataTableLab, setDataTableLab] = useState(initialDataTableLab);
	const [executeTuned, setExecuteTuned] = useState(false);
	const [executeTunedCorrelation, setExecuteTunedCorrelation] = useState(false);
	const [indexTableLab, setIndexTableLab] = useState(0);
	const [dataTableIPRPL, setDataTableIPRPL] = useState();
	const [inputReservoir, setInputReservoir] = useState(
		DATA_RESERVOIR.initialInputReservoir,
	);
	const [showAlert, setShowAlert] = useState(false);
	const [messageAlert, setMessageAlert] = useState(false);
	const [typeAlert, setTypeAlert] = useState(false);

	const handleShowModal = (message, type = "Message") => {
		setMessageAlert(message);
		setTypeAlert(type);
		setShowAlert(true);
	};

	const handleCloseModal = () => {
		setShowAlert(false);
	};
	//FUNCIONES
	const transformData = (dataTableIPRPL) => {
		if (!inputReservoir || !inputReservoir.ipr_model || !dataTableIPRPL)
			return [];

		const { ipr_model } = inputReservoir;
		const { qo, steps } = dataTableIPRPL;

		const transformModelData = (qoModel) => {
			if (!qoModel || !Array.isArray(qoModel) || qoModel.length === 0) {
				return [];
			}

			return qoModel.map((qoValue, index) => ({
				qo: qoValue,
				steps: steps[index].toFixed(2),
			}));
		};

		const transformCompositeData = () => {
			if (
				!qo.qliq_pet ||
				!Array.isArray(qo.qliq_pet) ||
				(qo.qliq_pet.length === 0) | !Array.isArray(qo.qo_pet) ||
				(qo.qo_pet.length === 0) | !Array.isArray(qo.qw_pet) ||
				qo.qw_pet.length === 0
			) {
				return [];
			}

			return qo.qliq_pet.map((_, index) => ({
				qliq_pet: qo.qliq_pet[index],
				qo_pet: qo.qo_pet[index],
				qw_pet: qo.qw_pet[index],
				steps: steps[index].toFixed(2),
			}));
		};

		switch (ipr_model) {
			case "vogel":
				return transformModelData(qo.vogel);
			case "fetko":
				return transformModelData(qo.fetko);
			case "straight":
				return transformModelData(qo.straight);
			case "composite":
				return transformCompositeData();
			default:
				return qo
					? Object.keys(qo).flatMap((model) =>
							transformModelData(qo[model]).map((data) => ({ ...data, model })),
						)
					: [];
		}
	};

	function generateRadomId() {
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const lowercaseLettersAndNumbers = "abcdefghijklmnopqrstuvwxyz0123456789";
		let id = "";

		for (let i = 0; i < 3; i++) {
			const randomUppercaseLetter = uppercaseLetters.charAt(
				Math.floor(Math.random() * uppercaseLetters.length),
			);
			id += randomUppercaseLetter;
		}

		const remainingLength = 10 - id.length;
		for (let i = 0; i < remainingLength; i++) {
			const randomChar = lowercaseLettersAndNumbers.charAt(
				Math.floor(Math.random() * lowercaseLettersAndNumbers.length),
			);
			id += randomChar;
		}

		return id;
	}

	//LLAMADOS API

	const mutationSaveDataIPR = useMutation({
		mutationFn: uploadJsonIPRPL,
		onSuccess: () => {
			message.success(` IPR DataSaved Successfull`);
			setTimeout(() => {
				refetchListqIPR();
			}, 500);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the IPR Data`);
		},
	});

	const {
		data: queryListDataGraphic,
		isLoading: loadListDataGraphic,
		isError: errorListDataGraphic,
		refetch: refetchListDataGraphic,
	} = useQuery({
		queryKey: ["ListDataGraphicsIPRPL"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			listDataGraphic(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			if (data.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
				message.success(data.statusMessage || "Data query successful");
			} else {
				console.error(data.statusMessage || "An error occurred during the query");
			}
		},
		onError: (error) => {},
	});

	const {
		data: queryDataGraphic,
		isLoading: loadDataGraphic,
		isError: errorDataGraphic,
		refetch: refetchDataGraphic,
	} = useQuery({
		queryKey: ["dataGraphicsIPRPL"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			dataGraphic(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
				lastSelectDataIPRPL,
			),
		onSuccess: (data) => {
			if (data.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
				message.success(data.statusMessage || "Data query successful");
			} else {
				console.error(data.statusMessage || "An error occurred during the query");
			}
		},
		onError: (error) => {},
	});

	const mutationUploadJsonDataGraph = useMutation({
		mutationFn: uploadDataGraphics,
		onSuccess: (data) => {
			if (data.statusType === STATUS_TYPE_SCENERY_PLUNGER_LIFT?.SUCCESS) {
				message.success(data.statusMessage || "Uploaded Data Successfully");
				setTimeout(() => {
					refetchListDataGraphic();
				}, 500);
			} else {
				message.error(data.statusMessage || "An error occurred during the upload");
			}
		},
		onError: (error) => {
			message.error("An error has occurred when loading the data of the graphic");
		},
	});

	const mutationDeleteDataGraphicIPR = useMutation({
		mutationFn: deleteScenarioPL,
		onSuccess: (data) => {
			if (data?.statusType === STATUS_TYPE_SCENERY_PLUNGER_LIFT?.SUCCESS) {
				message.success(data.statusMessage || "Deleted Data Successfully");
				setTimeout(() => {
					refetchListDataGraphic();
				}, 200);
			} else {
				message.error(
					data?.statusMessage || "An error occurred during the deleted",
				);
			}
		},
		onError: (error) => {
			message.error(`An error has occurred when delete`);
		},
	});

	const mutationDeleteScenariosReservoir = useMutation({
		mutationFn: deleteListDataGraphic,
		onSuccess: (data) => {
			if (data.statusType === STATUS_TYPE_SCENERY_PLUNGER_LIFT?.SUCCESS) {
				message.success(data.statusMessage || "Deleted Data Successfully");
				setTimeout(() => {
					refetchListDataGraphic();
				}, 200);
			} else {
			}
		},
		onError: (error) => {
			message.error(`An error has occurred when delete`);
		},
	});

	const mutationUploadJsonVolumeTracker = useMutation({
		mutationFn: uploadJsonVolumeTracker,
		onSuccess: () => {
			message.success(`Uploaded Data Successfull`);
			setTimeout(() => {
				refetchDataAllocation();
			}, 500);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the PVT data`);
		},
	});

	const {
		data: dataAllocation,
		isLoading: loadDataAllocation,
		isError: ErrorDataAllocation,
		refetch: refetchDataAllocation,
	} = useQuery({
		queryKey: ["get_data_allocation"],
		queryFn: () =>
			getDataAllocation(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataVolumeTracker,
		isLoading: loadVolumeTracker,
		isError: ErrorloadVolumeTracker,
		refetch: refetchVolumeTracker,
	} = useQuery({
		queryKey: ["get_VolumTracker"],
		queryFn: () =>
			getJsonVolumeTracker(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: listqIPR,
		isLoading: loadListqIPR,
		isError: ErrorListqIPR,
		refetch: refetchListqIPR,
	} = useQuery({
		queryKey: ["get_QIPR"],
		queryFn: () =>
			calculateQIPRPL(
				userDataInfo?.operator,
				userDataInfo?.lease,
				userDataInfo?.region,
				dataFilterUserPL?.county,
				dataFilterUserPL?.field,
				dataFilterUserPL?.well_id,
				User?.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (
				data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR &&
				dataPage !== NAME_PAGES.plunger_lift.dashboard
			) {
				message.info(
					"The calculation carried out was not processed correctly, validates the data",
				);
			} else if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
				const dataReservoior = data.data;
				setDataTableIPRPL(transformData(dataReservoior));
			}

			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const mutationCalcCasing = useMutation({
		mutationFn: uploadJsonCasing,
		onSuccess: (data) => {
			if (dataPage !== NAME_PAGES.plunger_lift.dashboard) {
				if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
					message.success(`Data Done Successfull`);
				} else if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR) {
					message.error("An error has occurred when calculated data");
				}
			}
		},
		onError: (error) => {
			message.error(`An error has occurred upload data`);
		},
	});

	const {
		data: dataCalcCasing,
		isLoading: loadCalcCasing,
		isError: ErrorCalcCasing,
		refetch: refetchCalcDataCasing,
	} = useQuery({
		queryKey: ["get_data_casing_pressure"],
		queryFn: () =>
			calculateCasing(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (
				data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR &&
				dataPage !== NAME_PAGES.plunger_lift.dashboard
			) {
				message.info(
					"The calculation carried out was not processed correctly, validates the data",
				);
			}
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const mutationCalcPressure = useMutation({
		mutationFn: uploadJsonPressure,
		onSuccess: (data) => {
			if (dataPage !== NAME_PAGES.plunger_lift.dashboard) {
				if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
					message.success(`Data Done Successfull`);
				} else if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR) {
					message.error("An error has occurred when calculated data");
				}
			}
		},
		onError: (error) => {
			message.error(`An error has occurred upload data`);
		},
	});

	const {
		data: dataCalcPressure,
		isLoading: loadDataCalcPressure,
		isError: ErrorDataCalcPressure,
		refetch: refetchCalcDataPressure,
	} = useQuery({
		queryKey: ["get_data_pressure"],
		queryFn: () =>
			calculatePressure(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (
				data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR &&
				dataPage !== NAME_PAGES.plunger_lift.dashboard
			) {
				message.info(
					"The calculation carried out was not processed correctly, validates the data",
				);
			}
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const {
		data: dataCalcInterpolation,
		isLoading: loadDataCalcInterpolation,
		isError: ErrorDataCalcInterpolation,
		refetch: refetchCalcDataInterpolation,
	} = useQuery({
		queryKey: ["get_data_Interpolation"],
		queryFn: () =>
			calculateInterpolation(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (
				data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR &&
				dataPage !== NAME_PAGES.plunger_lift.dashboard
			) {
				handleShowModal(
					"The calculation carried out was not processed correctly, validates the data",
					"Message",
				);
			}
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const {
		data: dataWellPL,
		isLoading: loadDataWellPL,
		isError: ErrorDataWellPL,
		refetch: refetchDataWellPL,
	} = useQuery({
		queryKey: ["data_well_plunger_lift"],
		queryFn: () =>
			dataWellPlungerLift(
				userDataInfo?.operator,
				userDataInfo?.lease,
				userDataInfo?.region,
				dataFilterUserPL?.county,
				dataFilterUserPL?.field,
				dataFilterUserPL?.well_id,
				User?.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR) {
				message.info("Problem for data");
			}
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});
	const {
		data: listWellPL,
		isLoading: loadListWellPL,
		isError: ErrorListWellPL,
		refetch: refetchListWellPL,
	} = useQuery({
		queryKey: ["list_well_plunger_lift"],
		queryFn: () =>
			listWellPlungerLift(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (
				stateMessageApisPL?.well ||
				stateMessageApisPL?.reservoir ||
				stateMessageApisPL?.plunger_lift ||
				stateMessageApisPL?.pvt
			) {
				navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
				getStateMessageApisPL(INITIAL_STATE_PROCESS_PL);
			}
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
		},
		onError: (error) => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
			if (
				stateMessageApisPL?.well ||
				stateMessageApisPL?.reservoir ||
				stateMessageApisPL?.plunger_lift ||
				stateMessageApisPL?.pvt
			) {
				navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
				getStateMessageApisPL(INITIAL_STATE_PROCESS_PL);
			}
		},
	});

	const mutationWellPlungerLift = useMutation({
		mutationFn: uploadJsonWellPL,
		onMutate: () => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: true,
			});
		},
		onSuccess: async (data, dataMutation) => {
			if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
				await mutationUpdateFilter.mutateAsync({
					user: User.user_name,
					data: {
						...dataFilter?.data,
						checkedWellPlungerLift: dataMutation.well,
					},
				});

				getSelectedWellPL(dataMutation.well ?? null);

				getDataFilterPL({
					...dataFilterUserPL,
					county: dataMutation.county,
					field: dataMutation.field,
					well_id: dataMutation.well_id,
					well_name: dataMutation.well,
					well_platforms: "",
				});

				await refetchListWellPL();
				message.success(`Data Done Successfull`);
			} else {
				message.error(
					`An error has occurred when loading the data Well Plunger lift`,
				);
			}
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
		},
		onError: (error) => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred upload data`);
		},
	});

	const mutationUpdateWellPlungerLift = useMutation({
		mutationFn: updateJsonWellPL,
		onMutate: () => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: true,
			});
		},
		onSuccess: async (data) => {
			if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
				await refetchListWellPL();
			}
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
			if (dataPage !== NAME_PAGES.plunger_lift.dashboard) {
				if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
					message.success(`Data Done Successfull`);
				} else if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR) {
					message.error("An error has occurred when calculated data");
				}
			}
		},
		onError: (error) => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred upload data`);
		},
	});

	const mutationDeleteWellPlungerLift = useMutation({
		mutationFn: deleteWellPL,
		onMutate: () => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: true,
			});
		},
		onSuccess: async (data) => {
			if (data?.statusType === STATUS_TYPE_PLUNGER_LIFT.ERROR) {
				message.error(data?.statusMessage);
			} else {
				await refetchListWellPL();
				message.success(`Data Well Delete Successfull`);
			}
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
		},
		onError: (error) => {
			getViewLoadingPlungerLift({
				...viewLoadingPlungerLift,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred when delete`);
		},
	});

	// PVT

	const mutationDataPVT = useMutation({
		mutationFn: uploadJsonPVT,
		onSuccess: () => {
			message.success(`Data PVT upload Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when upload data`);
		},
	});

	const {
		data: calcDataPVT,
		isLoading: loadCalcDataPVT,
		isError: ErrorCalcDataPVT,
		refetch: refetchCalcDataPVT,
	} = useQuery({
		queryKey: ["calculate_pvt"],
		queryFn: () =>
			calculatePVT(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => {
			if (data?.statusType === "ERROR") {
				message.info("Problem calculate data");
			}
		},
		onError: (error) => {},
	});

	const mutationUploadDataHistory = useMutation({
		mutationFn: uploadDataHistory,
		onSuccess: () => {
			refetchListDataHistory();
			message.success(`The charge of data history was successful`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when loading the information of the data history`,
			);
		},
	});

	const mutationUpdateDataHistory = useMutation({
		mutationFn: updateDataHistory,
		onSuccess: () => {
			refetchListDataHistory();
			message.success(`The charge of data history was successful`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when loading the information of the data history`,
			);
		},
	});

	const mutationDeleteDataHistory = useMutation({
		mutationFn: deleteDataHistory,
		onSuccess: () => {
			refetchListDataHistory();
			message.success(`The elimination of the Data history was successful`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when delete the information of the data history`,
			);
		},
	});
	const {
		data: dataHistoryPL,
		isLoading: loadDataHistoryPL,
		isError: ErrorDataHistoryPL,
		refetch: refetchDataHistoryPL,
	} = useQuery({
		queryKey: ["dataHistoryPL"],
		queryFn: () =>
			getDataHistory(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserPL.county,
				dataFilterUserPL.field,
				dataFilterUserPL.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
		},
		onError: (error) => {},
	});
	const {
		data: dataListDataHistory,
		isLoading: loadListDataHistory,
		isError: ErrorListDataHistory,
		refetch: refetchListDataHistory,
	} = useQuery({
		queryKey: ["dataHistoryList"],
		queryFn: () =>
			getListDataHistory(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	return {
		User,
		bodyAddWell,
		userDataInfo,
		selectedWellPL,
		getSelectedWellPL,
		dataDeviationApi,
		dataEquipmentApi,
		getUserDataInfo,
		initiailInputPropiertiesOut,
		setDataDeviationApi,
		setDataEquipmentApi,
		setBodyAddWell,
		inputPropertiesOut,
		setInputPropertiesOut,
		viewLoading,
		generateRadomId,
		getDataFilterPL,
		dataFilterUserPL,
		setViewLoading,
		viewFluidLab,
		setViewFluidLab,
		dataTableLab,
		setDataTableLab,
		setExecuteTuned,
		executeTuned,
		setExecuteTunedCorrelation,
		executeTunedCorrelation,
		setIndexTableLab,
		indexTableLab,
		dataTableIPRPL,
		setDataTableIPRPL,
		inputReservoir,
		setInputReservoir,
		mutationSaveDataIPR,
		listqIPR,
		mutationCalcCasing,
		dataCalcCasing,
		refetchCalcDataCasing,
		dataWellPL,
		loadDataWellPL,
		refetchDataWellPL,
		mutationWellPlungerLift,
		mutationDeleteWellPlungerLift,
		mutationUpdateWellPlungerLift,
		refetchCalcDataPressure,
		refetchCalcDataInterpolation,
		dataCalcInterpolation,
		dataCalcPressure,
		mutationCalcPressure,
		refetchListWellPL,
		listWellPL,
		loadListWellPL,
		refetchListqIPR,
		mutationDataPVT,
		refetchCalcDataPVT,
		calcDataPVT,
		mutationUploadJsonDataGraph,
		mutationDeleteDataGraphicIPR,
		mutationDeleteScenariosReservoir,
		queryListDataGraphic,
		refetchListDataGraphic,
		queryDataGraphic,
		refetchDataGraphic,
		lastSelectDataIPRPL,
		getSelectDataIPRPL,
		showAlert,
		handleCloseModal,
		messageAlert,
		typeAlert,
		mutationUploadDataHistory,
		mutationUploadJsonVolumeTracker,
		refetchListDataHistory,
		dataListDataHistory,
		mutationUpdateDataHistory,
		mutationDeleteDataHistory,
		dataHistoryPL,
		ErrorDataHistoryPL,
		refetchDataHistoryPL,
		dataAllocation,
		loadDataAllocation,
		ErrorDataAllocation,
		refetchDataAllocation,
	};
};
