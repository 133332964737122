import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
	const [Auth, setAuth] = useState(false);
	const [User, setUser] = useState({});
	const Navigate = useNavigate();

	useEffect(() => {
		// Recuperar el estado de autenticación de localStorage al cargar la página
		const storedUser = JSON.parse(sessionStorage.getItem("userData"));
		const auth = storedUser?.auth;

		if (auth && storedUser) {
			setAuth(auth);
			setUser(storedUser);
		}
	}, []);

	const signin = (userData) => {
		sessionStorage.setItem("userData", JSON.stringify(userData));
		setUser(userData);
		setAuth(true);
		sessionStorage.setItem("dataPage", "Map");
	};

	const signout = (e) => {
		sessionStorage.clear();
		setAuth(false);
		window.location.href = "/Login";
	};
	return {
		Auth,
		User,
		signin,
		signout,
	};
};
