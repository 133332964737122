import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const FILE_FORECAST = config.API.FORECAST.FILE_FORECAST;
const RUN_FORECAST = config.API.FORECAST.RUN_FORECAST;
const OUTPUT_FORECAST = config.API.FORECAST.OUTPUT_FORECAST;
const TOKEN_FORECAST_UPLOAD = config.AUTH.TOKEN_FORECAST_UPLOAD;
const TOKEN_FORECAST_RUN = config.AUTH.TOKEN_FORECAST_RUN;
const TOKEN_FORECAST_OUTPUT = config.AUTH.TOKEN_FORECAST_OUTPUT;

export const uploadForecast = async (dataJson) => {
	const { operator, lease, region, wellid, userName, data } = dataJson;
	if (!validateParams(operator, lease, region, wellid, userName)) return;

	try {
		const response = await fetch(
			`${FILE_FORECAST}/uploadhistoricfordca/${operator}/${lease}/${region}/${wellid}/${userName}`,
			{
				method: "POST",
				headers: {
					"x-api-key": TOKEN_FORECAST_UPLOAD,
				},
				body: data,
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data history");
	}
};

export const postForecast = async (dataJson) => {
	const { operator, lease, region, wellid, userName, forcastHorizont } =
		dataJson;
	if (
		!validateParams(operator, lease, region, wellid, userName, forcastHorizont)
	)
		return;

	try {
		const response = await fetch(
			`${RUN_FORECAST}/dcaforecastmain1/${operator}/${lease}/${region}/${wellid}/${userName}/${forcastHorizont}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_FORECAST_RUN,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data history");
	}
};

export const getForecast = async (
	operator,
	lease,
	region,
	wellid,
	userName,
	table,
) => {
	if (!validateParams(operator, lease, region, wellid, userName, table)) return;

	try {
		const response = await fetch(
			`${OUTPUT_FORECAST}/outputtable/${operator}/${lease}/${region}/${wellid}/${userName}?table=${table}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_FORECAST_OUTPUT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the data");
	}
};

export const getConfirmUploadFile = async (
	operator,
	lease,
	region,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, wellid, userName)) return;

	try {
		const response = await fetch(
			`${OUTPUT_FORECAST}/uploadedhistoricflag/${operator}/${lease}/${region}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_FORECAST_OUTPUT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the data");
	}
};

export const postForecastSemi = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		wellid,
		forcastHorizont,
		startForecast,
		qi_custom,
		sensitivity,
		period,
		userName,
	} = dataJson;
	if (
		!validateParams(
			operator,
			lease,
			region,
			wellid,
			forcastHorizont,
			startForecast,
			qi_custom,
			sensitivity,
			period,
			userName,
		)
	)
		return;

	try {
		const response = await fetch(
			`${RUN_FORECAST}/dcaforecastmain2semi/${operator}/${lease}/${region}/${wellid}/${userName}/${forcastHorizont}?sensitivity_select=${sensitivity}&period_select=${period}&start_forecast=${startForecast}&qi_custom=${qi_custom}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_FORECAST_RUN,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data history");
	}
};

export const postForecastFull = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		wellid,
		forcastHorizont,
		startForecast,
		qi_custom,
		start_select,
		end_select,
		userName,
	} = dataJson;
	if (
		!validateParams(
			operator,
			lease,
			region,
			wellid,
			forcastHorizont,
			startForecast,
			qi_custom,
			start_select,
			end_select,
			userName,
		)
	)
		return;

	try {
		const response = await fetch(
			`${RUN_FORECAST}/dcaforecastmain2full/${operator}/${lease}/${region}/${wellid}/${userName}/${forcastHorizont}?start_select=${start_select}&end_select=${end_select}&start_forecast=${startForecast}&qi_custom=${qi_custom}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_FORECAST_RUN,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data history");
	}
};
