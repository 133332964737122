import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as GasLiftSidebar } from "../../assets/img/icons/gas_lift.svg";
import { ReactComponent as MapSidebar } from "../../assets/img/icons/map.svg";
import { ReactComponent as GraphSidebar } from "../../assets/img/icons/graph.svg";
import { ReactComponent as AddUserSidebar } from "../../assets/img/icons/addUsers.svg";
import { ReactComponent as UsersExtSidebar } from "../../assets/img/icons/userExternal.svg";
import { ReactComponent as UsersIntSidebar } from "../../assets/img/icons/userInternal.svg";
import { ReactComponent as ScreeningToolSidebar } from "../../assets/img/icons/display.svg";
import { ReactComponent as PlungerLift } from "../../assets/img/icons/plunger_lift.svg";
import { ReactComponent as Injector } from "../../assets/img/icons/injector.svg";
import { ReactComponent as PlottingSidebar } from "../../assets/img/icons/plotting.svg";
import { ReactComponent as ControlSidebar } from "../../assets/img/icons/control_filter.svg";
import { Sidenav, Collapse, initTE, Dropdown } from "tw-elements";
import { Tooltip, Modal } from "antd";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { AuthContext } from "../../auth/context/AuthContext";
import { useFilters } from "../../hooks/useFilters";
import Img_Logo from "../../assets/img/Logo-Enovate.png";
import Emerson from "../../assets/img/Emerson.png";
import LogoB4 from "../../assets/img/B4e_Logo.svg";
import LogoB4E from "../../assets/img/logoB4E.svg";
import LogoKuva from "../../assets/img/Logo_Kuva.png";
import LogoKuv from "../../assets/img/logoKuva.png";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabDCA } from "../../hooks/useTabDCA";
import {
	STATUS_TYPE_WELL_GASLIFT,
	STATUS_TYPE_WELL_DCA,
} from "../elements/types/type_response_API";
import { NAME_PAGES } from "../elements/types/type_pages";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import { useTapMap } from "../../hooks/useTabMap";
import { ROL_NAME } from "../elements/types/type_dashboard";
import {
	MODULE_SIDEBAR,
	PATH_PRODUCT,
	styleIconSideBar,
} from "../elements/types/type_sidebar";

const initialhasAlerts = {
	DCA: false,
	GasLift: false,
	PlungerLift: false,
	VLP: false,
};

const initialCountAlert = {
	gas_lift: 0,
	plunger_lift: 0,
	dca: 0,
};

const SidebarItem = ({ link, tooltip, icon, onClick, active }) => {
	const isDisabled = link === "#";
	const titleTooltip = isDisabled ? "Please select filters first" : tooltip;
	return (
		<Tooltip placement="right" title={titleTooltip} color={"#662D91"}>
			<NavLink
				to={link}
				onClick={onClick}
				className={`flex h-10  ${isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"} items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1 outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
					active ? "bg-white/10" : ""
				}`}
				data-te-sidenav-link-ref
			>
				<span className="mr-4 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
					{icon}
				</span>
				<span
					className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
					data-te-sidenav-slim="false"
				>
					{tooltip}
				</span>
			</NavLink>
		</Tooltip>
	);
};

const ProductItem = ({
	path,
	tooltipContent,
	imgSrc,
	alt,
	containerClassName,
	imageClassName,
	widthSidebar,
}) => {
	return (
		<Tooltip placement="right" title={tooltipContent} color={"#662D91"}>
			<li>
				<NavLink
					to={path}
					target="_blank"
					className="hover:text-white focus:text-pc-purple flex items-center h-12 cursor-pointer truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 border-none transition duration-300 ease-linear hover:bg-slate-50 hover:border-none focus:bg-slate-50 focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none hover:bg-white/10 focus:bg-[white]/10 active:bg-[white]/10"
					data-te-sidenav-link-ref
				>
					<div className={containerClassName}>
						<img src={imgSrc} alt={alt} className={imageClassName} loading="lazy" />
						{!widthSidebar && tooltipContent && tooltipContent !== "B4ECarbon" && (
							<label className="text-[16px] text-white font-sans">
								{tooltipContent}
							</label>
						)}
					</div>
				</NavLink>
			</li>
		</Tooltip>
	);
};

export const Sidebar = () => {
	const { confirm } = Modal;
	const Navigate = useNavigate();
	const {
		dataPage,
		someAlert,
		getSomeAlert,
		selectedWell,
		selectedWellDCA,
		selectedWellPL,
		selectedWellInjector,
		dataAlertGasLift,
		alertDCAData,
		dataAlertPlungerLift,
		userDataInfo,
	} = useContext(DashboardContext);
	const { User } = useContext(AuthContext);
	const { dataFilter } = useFilters();
	const { alertGasLift, alertDCA, alertVLP, alertPlungerLift } = useTapMap();
	const { dataWellDCA, mutationUpdateJsonWellDCA } = useTabDCA();
	const { dataWellGasLift, mutationUpdateDataGasLift } = useTabGaslift();
	const { dataWellPL, mutationUpdateWellPlungerLift } = useTabPlungerLift();
	const [widthSidebar, setWidthSidebar] = useState(true);
	const [countAlert, setCountAlert] = useState(initialCountAlert);
	const [dataWellGL, setDataWellGL] = useState();
	const [dataGetWellDCA, setdataGetWellDCA] = useState();
	const [hasAlerts, setHasAlerts] = useState(initialhasAlerts);

	const validateSomeAlert = !someAlert?.messageChange;

	useEffect(() => {
		initTE({ Collapse, Dropdown, Sidenav });
	}, []);

	useEffect(() => {
		const valuesTrueDCA = someAlert?.forecasting?.countAlert;
		const valuesTrue = someAlert?.gas_lift?.countAlert;
		const valuesTruePlunger = someAlert?.plunger_lift?.countAlert;

		setCountAlert({
			gas_lift: valuesTrue,
			plunger_lift: valuesTrueDCA,
			dca: valuesTruePlunger,
		});
	}, [someAlert]);

	useEffect(() => {
		if (dataFilter) {
			if (dataWellGasLift?.statusType === STATUS_TYPE_WELL_GASLIFT.SUCCESS) {
				setDataWellGL(dataWellGasLift.data);
			}
		}
	}, [dataWellGasLift]);

	useEffect(() => {
		if (dataFilter) {
			if (dataWellDCA?.statusType === STATUS_TYPE_WELL_DCA.SUCCESS) {
				setdataGetWellDCA(dataWellDCA.data);
			}
		}
	}, [dataWellDCA]);

	useEffect(() => {
		setHasAlerts({
			DCA: Object.values(alertDCA?.data || {}).some(
				(item) => item.checks_alerts > 0,
			),
			GasLift: Object.values(alertGasLift?.data || {}).some(
				(item) => item.checks_alerts > 0,
			),
			VLP: Object.values(alertVLP?.data || {}).some(
				(item) => item.checks_alerts > 0,
			),
			PlungerLift: Object.values(alertPlungerLift?.data || {}).some(
				(item) => item.checks_alerts > 0,
			),
		});
	}, [alertGasLift, alertVLP, alertDCA, alertPlungerLift]);

	const changeSlim = () => {
		setWidthSidebar(!widthSidebar);

		const instance = Sidenav.getInstance(document.getElementById("sidenav-4"));
		if (instance) {
			instance.toggleSlim();
		}
	};

	const handleConfirm = (link) => {
		const selectedWells = {
			[NAME_PAGES.gas_lift.dashboard]: selectedWell,
			[NAME_PAGES.DCA.dashboard]: selectedWellDCA,
			[NAME_PAGES.plunger_lift.dashboard]: selectedWellPL,
			[NAME_PAGES.well_injector.dashboard]: selectedWellInjector,
		};
		const validationWell = !!selectedWells[dataPage];

		if (validationWell) {
			confirm({
				title: `Do you want to save the changes of the alarm's thresholds?`,
				icon: <ExclamationCircleFilled />,
				okText: "Save Changes",
				okButtonProps: { className: "bg-[#662D91]" },
				cancelText: "Exit",
				onOk() {
					const dataUserFilter = {
						operator: userDataInfo?.operator,
						lease: userDataInfo?.lease,
						region: userDataInfo?.region,
						username: User?.user_name,
					};

					let data_alert = {};
					let data_check = {};
					let dataFinalWell = {};

					switch (dataPage) {
						case "DashboardGasLift":
							data_alert = dataAlertGasLift;
							data_check = someAlert.gas_lift;
							dataFinalWell = {
								...dataWellGL,
								data_alert: data_alert,
								data_check: data_check,
							};

							mutationUpdateDataGasLift.mutate({
								...dataUserFilter,
								county: dataWellGL?.county,
								field: dataWellGL?.field,
								well: dataWellGL?.well_id,
								data: dataFinalWell,
							});
							break;
						case "DashboardDCA":
							data_alert = alertDCAData;
							data_check = someAlert.forecasting;
							dataFinalWell = {
								...dataGetWellDCA,
								data_alert: data_alert,
								check_Alert: data_check,
							};

							mutationUpdateJsonWellDCA.mutate({
								...dataUserFilter,
								county: dataGetWellDCA?.county,
								field: dataGetWellDCA?.field,
								wellid: dataGetWellDCA?.well_id,
								data: dataFinalWell,
							});
							break;
						case "DashboardPlungerLift":
							data_alert = dataAlertPlungerLift;
							data_check = someAlert.plunger_lift;
							dataFinalWell = {
								...dataWellPL?.data,
								data_alert: data_alert,
								data_check: data_check,
							};

							mutationUpdateWellPlungerLift.mutate({
								...dataUserFilter,
								county: dataWellPL?.data?.county,
								field: dataWellPL?.data?.field,
								well: dataWellPL?.data?.well_id,
								data: dataFinalWell,
							});
							break;
						default:
							break;
					}
					getSomeAlert({ ...someAlert, messageChange: false });
					Navigate(link);
				},
				onCancel() {
					getSomeAlert({ ...someAlert, messageChange: false });
					Navigate(link);
				},
			});
		} else {
			confirm({
				title: `Please select the proper well`,
				icon: <ExclamationCircleFilled />,
				okText: "Ok",
				okButtonProps: { className: "bg-[#662D91]" },
				okCancel: false,
				onOk() {
					getSomeAlert({ ...someAlert, messageChange: false });
					Navigate(link);
				},
			});
		}
	};

	let hasFilters = false;
	if (userDataInfo) {
		const { operator, region, lease } = userDataInfo;
		hasFilters = lease !== "" && region !== "" && operator !== "";
	}

	const modulesEnabled = hasFilters;
	const sidebarItems = [
		{
			link: validateSomeAlert ? `/${NAME_PAGES.map}` : "#",
			tooltip: MODULE_SIDEBAR.map,
			icon: <MapSidebar className={styleIconSideBar} />,
			active: dataPage === NAME_PAGES.map,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.map}`)
				: undefined,
		},
		{
			link: modulesEnabled ? `/${NAME_PAGES.DCA.dashboard}` : "#",
			tooltip: MODULE_SIDEBAR.dca,
			icon: <GraphSidebar className={styleIconSideBar} />,
			active:
				dataPage === NAME_PAGES.DCA.dashboard ||
				dataPage === NAME_PAGES.DCA.list_well,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.DCA.dashboard}`)
				: undefined,
			alert: hasAlerts?.DCA,
		},
		{
			link: modulesEnabled ? `/${NAME_PAGES.gas_lift.dashboard}` : "#",
			tooltip: MODULE_SIDEBAR.gas_lift,
			icon: <GasLiftSidebar className={styleIconSideBar} />,
			active:
				dataPage === NAME_PAGES.gas_lift.dashboard ||
				dataPage === NAME_PAGES.gas_lift.list_well,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.gas_lift.dashboard}`)
				: undefined,
			alert: hasAlerts?.GasLift || hasAlerts?.VLP,
		},
		{
			link: modulesEnabled ? `/${NAME_PAGES.plotting.dashboard}` : "#",
			tooltip: MODULE_SIDEBAR.plotting,
			icon: <PlottingSidebar className={styleIconSideBar} />,
			active: dataPage === NAME_PAGES.plotting.dashboard,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.plotting.dashboard}`)
				: undefined,
		},
		{
			link: modulesEnabled ? `/${NAME_PAGES.screening_tool.dashboard}` : "#",
			tooltip: MODULE_SIDEBAR.screening_tool,
			icon: <ScreeningToolSidebar className={styleIconSideBar} />,
			active:
				dataPage === NAME_PAGES.screening_tool.dashboard ||
				dataPage === NAME_PAGES.screening_tool.list_well,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.screening_tool.dashboard}`)
				: undefined,
		},
		{
			link: modulesEnabled ? `/${NAME_PAGES.plunger_lift.dashboard}` : "#",
			tooltip: MODULE_SIDEBAR.plunger_lift,
			icon: <PlungerLift className={styleIconSideBar} />,
			active:
				dataPage === NAME_PAGES.plunger_lift.dashboard ||
				dataPage === NAME_PAGES.plunger_lift.list_well,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.plunger_lift.dashboard}`)
				: undefined,
			alert: hasAlerts?.PlungerLift,
		},
		{
			link: modulesEnabled ? `/${NAME_PAGES.well_injector.dashboard}` : "#",
			tooltip: MODULE_SIDEBAR.well_injector,
			icon: <Injector className={styleIconSideBar} />,
			active:
				dataPage === NAME_PAGES.well_injector.dashboard ||
				dataPage === NAME_PAGES.well_injector.list_well,
			onClick: someAlert?.messageChange
				? () => handleConfirm(`/${NAME_PAGES.well_injector.dashboard}`)
				: undefined,
		},
		...(User?.role !== ROL_NAME.simple_user && User.user_email !== "rebecca.nye@enovate.ai"
			? [
					{
						link: modulesEnabled ? `/${NAME_PAGES.user.register.create}` : "#",
						tooltip: MODULE_SIDEBAR.register,
						icon: <AddUserSidebar className={styleIconSideBar} />,
						active: dataPage === NAME_PAGES.user.register.create,
						onClick: someAlert?.messageChange
							? () => handleConfirm(`/${NAME_PAGES.user.register.create}`)
							: undefined,
					},
					{
						link: `/${NAME_PAGES.user.external.list_users}`,
						tooltip: MODULE_SIDEBAR.external,
						icon: <UsersExtSidebar className={styleIconSideBar} />,
						active: dataPage === NAME_PAGES.user.external.list_users,
						onClick: someAlert?.messageChange
							? () => handleConfirm(`/${NAME_PAGES.user.external.list_users}`)
							: undefined,
					},
					{
						link: `/${NAME_PAGES.user.internal.list_users}`,
						tooltip: MODULE_SIDEBAR.internal,
						icon: <UsersIntSidebar className={styleIconSideBar} />,
						active: dataPage === NAME_PAGES.user.internal.list_users,
						onClick: someAlert?.messageChange
							? () => handleConfirm(`/${NAME_PAGES.user.internal.list_users}`)
							: undefined,
					},
					{
						link:`/${NAME_PAGES.user.control.create}`,
						tooltip: MODULE_SIDEBAR.control,
						icon: <ControlSidebar className={styleIconSideBar} />,
						active: dataPage === NAME_PAGES.user.control.create,
						onClick: someAlert?.messageChange
							? () => handleConfirm(`/${NAME_PAGES.user.control.create}`)
							: undefined,
					},
				]
			: []),
	];

	const productElements = [
		{
			path: PATH_PRODUCT.emerson.nav,
			tooltipContent: PATH_PRODUCT.emerson.name,
			imgSrc: Emerson,
			alt: PATH_PRODUCT.emerson.name,
			containerClassName: `flex flex-row w-full items-center mt-4 mb-4 cursor-pointer gap-1 justify-${widthSidebar ? "center" : "start ml-[-5px]"}`,
			imageClassName: `transition-all duration-300 ease-in-out ${widthSidebar ? "w-auto h-[60%]" : "w-[23%]"}`,
		},
		{
			path: PATH_PRODUCT.ada_bi.nav,
			tooltipContent: PATH_PRODUCT.ada_bi.name,
			imgSrc: Img_Logo,
			alt: PATH_PRODUCT.ada_bi.name,
			containerClassName: `flex flex-row w-full items-center mt-4 mb-4 cursor-pointer gap-3 justify-${widthSidebar ? "center" : "start"}`,
			imageClassName: `transition-all duration-300 ease-in-out ${widthSidebar ? "w-auto h-[50%]" : "w-[17%]"}`,
		},
		{
			path: PATH_PRODUCT.ada_do.nav,
			tooltipContent: PATH_PRODUCT.ada_do.name,
			imgSrc: Img_Logo,
			alt: PATH_PRODUCT.ada_do.name,
			containerClassName: `flex flex-row w-full items-center mt-4 mb-4 cursor-pointer gap-3 justify-${widthSidebar ? "center" : "start"}`,
			imageClassName: `transition-all duration-300 ease-in-out ${widthSidebar ? "w-auto h-[50%]" : "w-[17%]"}`,
		},
		{
			path: PATH_PRODUCT.b4carbon.nav,
			tooltipContent: PATH_PRODUCT.b4carbon.name,
			imgSrc: widthSidebar ? LogoB4E : LogoB4,
			alt: "",
			containerClassName: `flex flex-row w-full items-center mt-4 mb-4 cursor-pointer justify-${widthSidebar ? "center" : "start ml-[-5px]"}`,
			imageClassName: `transition-all duration-300 ease-in-out ${widthSidebar ? "w-auto h-auto" : "w-full mr-15"}`,
		},
		...(userDataInfo.operator === "lastmile_production"
			? [
					{
						path: PATH_PRODUCT.Kuva.nav,
						tooltipContent: PATH_PRODUCT.Kuva.name,
						imgSrc: widthSidebar ? LogoKuva : LogoKuv,
						alt: PATH_PRODUCT.Kuva.name,
						containerClassName: `flex flex-row w-full items-center cursor-pointer gap-3 justify-${widthSidebar ? "center" : "start"}`,
						imageClassName: `transition-all duration-300 ease-in-out pt-2 pb-2 ${
							widthSidebar ? "w-auto h-auto" : "w-[60%] h-auto"
						}`,
					},
				]
			: []),
	];

	return (
		<>
			<nav
				id="sidenav-4"
				className="group fixed left-0 top-0 z-[1035] h-full w-[245px] -translate-x-full overflow-hidden bg-pa-background shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-slim='true']:hidden data-[te-sidenav-slim-collapsed='true']:w-[80px] data-[te-sidenav-slim='true']:w-[80px] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800 [&[data-te-sidenav-slim-collapsed='true'][data-te-sidenav-slim='false']]:hidden [&[data-te-sidenav-slim-collapsed='true'][data-te-sidenav-slim='true']]:[display:unset]"
				data-te-sidenav-init
				data-te-sidenav-hidden={false}
				data-te-sidenav-mode="side"
				data-te-sidenav-slim={widthSidebar}
				data-te-sidenav-content="#slim-content"
				data-te-sidenav-slim-collapsed={widthSidebar}
				data-te-sidenav-slim-width={80}
			>
				<div>
					<ul
						className="relative m-0 list-none px-[0.2rem]"
						data-te-sidenav-menu-ref
					>
						<li className="relative">
							<div
								onClick={changeSlim}
								className="flex h-[90px] cursor-pointer items-start  truncate rounded-[5px] px-5 py-4 text-[0.875rem] outline-none transition duration-300 ease-linear  hover:text-inherit hover:outline-none  focus:text-inherit focus:outline-none active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
								data-te-sidenav-link-ref
							>
								<span className="mr-[10px] [&>svg]:h-4 [&>svg]:w-4 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300 ">
									<img
										src={Img_Logo}
										className="w-6 lg:w-9 lg:absolute"
										alt=""
										loading="lazy"
									/>
								</span>
								<span
									className="pl-9 group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden text-2xl text-white focus:text-pa-purple hover:text-pa-purple"
									data-te-sidenav-slim="false"
								>
									Enovate Ai
								</span>
							</div>
						</li>
						{sidebarItems.map((item, index) => (
							<li key={index}>
								<SidebarItem {...item} slimLabel={!widthSidebar} />
							</li>
						))}
					</ul>
				</div>
				<div className="flex flex-col items-start absolute bottom-[1em] mb-auto w-full">
					<ul className="mt-auto px-[0.2rem]">
						{productElements.map((element, index) => (
							<ProductItem key={index} {...element} widthSidebar={widthSidebar} />
						))}
					</ul>
				</div>
			</nav>
		</>
	);
};
