import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Rectangle,
  ReferenceArea,
} from "recharts";
import { DashboardPlottingContext } from "../../../context/DashboardPlotting/DashboardPlottingContext";
import {
  LINE_STYLES,
  LINE_TYPES,
} from "../../elements/types/type_plotting";
import styleClasses from "./ChartPlotting.module.css";
import { Tooltip as AntdTooltip } from "antd";
const { principalContainer, containerTool, btn, containerZoom } = styleClasses;
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dateTime = label;

    // Divide el array `payload` en dos columnas
    const half = Math.ceil(payload.length / 2);
    const firstColumn = payload.slice(0, half);
    const secondColumn = payload.slice(half);

    return (
      <div
        style={{
          // // marginLeft:"250px"
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          borderWidth: 1,
          borderRadius: 5,
          fontSize: "11px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            marginBottom: "5px",
            marginTop: "5px",
            fontSize: "11px",
            color: "#333",
          }}
        >
          Date: {moment(dateTime).format("MM/DD/YYYY HH:mm:ss")}
        </p>

        <table style={{ width: "100%", border: "1px solid #662D91" }}>
          <tbody>
            <tr>
              <td
                style={{
                  verticalAlign: "top",
                  borderRight: "1px solid #662D91",
                  padding: "5px",
                }}
              >
                <table style={{ width: "100%" }}>
                  <tbody>
                    {firstColumn.map((data, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #662D91" }}
                      >
                        <td
                          style={{
                            fontWeight: "bold",
                            color: data.color,
                            paddingRight: "5px",
                          }}
                        >
                          {data.name}:
                        </td>
                        <td style={{ color: data.color }}>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td style={{ verticalAlign: "top", padding: "5px" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    {secondColumn.map((data, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #662D91" }}
                      >
                        <td
                          style={{
                            fontWeight: "bold",
                            color: data.color,
                            paddingRight: "5px",
                          }}
                        >
                          {data.name}:
                        </td>
                        <td style={{ color: data.color }}>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export const ChartPlotting = ({
  data,
  linesData,
  colorMapping,
  startDate,
  endDate,
  updateStartDate,
  updateEndDate,
}) => {
  const { dataPlotting } = useContext(DashboardPlottingContext);
  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");
  const [zoomRange, setZoomRange] = useState({
    start: startDate2,
    end: endDate2,
  });
  const [selectionStart, setSelectionStart] = useState(null);
  const [tempHighlightArea, setTempHighlightArea] = useState(null);
  const [zoomHistory, setZoomHistory] = useState([]);
  const filteredDataPlotting = dataPlotting.dataPlotting?.filter((item) =>
    linesData.includes(item.device)
  );

  useEffect(() => {
    setZoomRange({ start: startDate2, end: endDate2 });
  }, [startDate2, endDate2]);

  const formattedData = data
    ?.map((item) => ({
      ...item,
      Date: moment(item["Date (MM/DD/YYYY)"], "MM/DD/YYYY HH:mm:ss").toDate(),
    }))
    .filter((item) => {
      if (zoomRange.start && zoomRange.end) {
        const itemDate = moment(item.Date);
        return itemDate.isBetween(zoomRange.start, zoomRange.end, null, "[]");
      }
      return true;
    });

  const filterAndTransformData = (data, params) => {
    return data
      .map((item) => {
        const newItem = {};
        const timestamp = moment(item["Date (MM/DD/YYYY)"], "MM/DD/YYYY HH:mm:ss").valueOf();
        if (isNaN(timestamp)) {
          // Manejo de fechas inválidas
          console.error(`Fecha inválida: ${item["Date (MM/DD/YYYY)"]}`);
          return null;
        }

        newItem.date = timestamp;
        params.forEach((param, index) => {
          if (item[param] !== null) {
            newItem[param] = item[param];
          }
        });
        // Filtrar objetos vacíos sin datos válidos
        return Object.keys(newItem).length > 0 ? newItem : null;
      })
      .filter((item) => item !== null);
  };

  const transformedDataEnd = filterAndTransformData(formattedData, linesData);

  const handleMouseMove = (e) => {
    if (selectionStart) {
      const tempEnd = e.activeLabel;
      const tempStart = moment
        .min(
          moment(selectionStart),
          moment(tempEnd)
        )
        .valueOf();
      const tempStop = moment
        .max(
          moment(selectionStart),
          moment(tempEnd)
        )
        .valueOf();
      setTempHighlightArea({ start: tempStart, end: tempStop });
    }
  };
  const handleClick = (e) => {
    if (!selectionStart) {
      setSelectionStart(e.activeLabel);
    } else {
      const selectionEnd = e.activeLabel;
      const newStart = moment
        .min(
          moment(selectionStart),
          moment(selectionEnd)
        )
        .valueOf();
      const newEnd = moment
        .max(
          moment(selectionStart),
          moment(selectionEnd)
        )
        .valueOf();
      setZoomHistory((prevHistory) => [...prevHistory, zoomRange]);
      setZoomRange({ start: newStart, end: newEnd });
      setStartDate2(newStart);
      setEndDate2(newEnd);
      setSelectionStart(null);
      setTempHighlightArea({ start: "", end: "" });
    }
  };

const handleDateChange = (e, setDate, updateDate) => {
  const newValue = e.target.value;
  if (newValue === "") {
    setDate("");
  } else {
    setDate(moment(newValue).format("MM/DD/YYYY HH:mm:ss"));
  }
};
  
  // Resetea el zoom al rango original
  const resetZoom = () => {
    setStartDate2("");
    setEndDate2("");
    setZoomRange({ start: "", end: "" });
    setSelectionStart(null);
  };

  const revertZoom = () => {
    if (zoomHistory.length > 0) {
      const lastZoom = zoomHistory.pop();
      setZoomHistory([...zoomHistory]);
      setZoomRange(lastZoom);
      updateStartDate(lastZoom.start);
      updateEndDate(lastZoom.end);
      setStartDate2(lastZoom.start);
      setEndDate2(lastZoom.end);
    }
  };

  const minDate = Math.min(...transformedDataEnd.map((item) => item.date));
  const maxDate = Math.max(...transformedDataEnd.map((item) => item.date));

  const colorUsageCount = {};
  return (
    <div className={principalContainer}>
      <div className={containerTool}>
        <div className={containerZoom}>
          {zoomHistory.length > 0 && (
            <AntdTooltip
              placement="bottom"
              title="PREVIOUS ZOOM"
              color="#662D91"
            >
              <button type="button" className={btn} onClick={revertZoom}>
                <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-counterclockwise"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 3.5A.5.5 0 0 1 1 4v3.248l6.267-3.636c.52-.302 1.233.043 1.233.696v2.94l6.267-3.636c.52-.302 1.233.043 1.233.696v7.384c0 .653-.713.998-1.233.696L8.5 8.752v2.94c0 .653-.713.998-1.233.696L1 8.752V12a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m7 1.133L1.696 8 7.5 11.367zm7.5 0L9.196 8 15 11.367z" />
                  </svg>
                </span>
              </button>
            </AntdTooltip>
          )}
          <AntdTooltip placement="bottom" title="RESET ZOOM" color="#662D91">
            <button type="button" className={btn} onClick={resetZoom}>
              <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-counterclockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
                </svg>
              </span>
            </button>
          </AntdTooltip>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <AntdTooltip placement="bottom" title="START DATE" color="#662D91">
            <div className="flex items-center">
              <input
                type="datetime-local"
                value={
                  startDate2
                    ? moment(startDate2).format(
                        "YYYY-MM-DDTHH:mm"
                      )
                    : ""
                }
                onChange={(e) => handleDateChange(e, setStartDate2, updateStartDate)}
                className={`w-full max-w-[140px] h-[30px] px-2 rounded-[4px] border text-[12px]`}
              />
            </div>
          </AntdTooltip>
          <AntdTooltip placement="bottom" title="END DATE" color="#662D91">
            <div className="flex items-center">
              <input
                type="datetime-local"
                value={
                  endDate2
                    ? moment(endDate2).format(
                        "YYYY-MM-DDTHH:mm"
                      )
                    : ""
                }
                onChange={(e) => handleDateChange(e, setEndDate2, updateEndDate)}
                className={`w-full max-w-[140px] h-[30px] px-2 rounded-[4px] border text-[12px]`}
              />
            </div>
          </AntdTooltip>
        </div>
        
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={350}
          data={transformedDataEnd}
          margin={{
            top: 35,
            right: 5,
            left: 15,
            bottom: 60,
          }}
          onClick={handleClick}
          onMouseMove={handleMouseMove}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            key={`xAxis-1`}
            dataKey={"date"}
            scale="time" // Escala temporal para el eje X
            type="number" // Usa valores numéricos para timestamps
            domain={['auto', 'auto']} // Ajusta automáticamente el rango
            tickFormatter={(value) => moment(value).format("MM/DD/YYYY HH:mm:ss")} // Formateador para las etiquetas
            allowDecimals={false}
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
              fontSize: 14,
              dy: 60, // Adjusts the value to move the label down
            }}
            interval={300}
            tickCount={30}
            tick={{
              fill: "black",
              fontSize: 12,
              angle: -45,
              textAnchor: "end",
            }} // Tilt the ticks
          />
          {tempHighlightArea && (
            <ReferenceArea
              x1={tempHighlightArea.start}
              x2={tempHighlightArea.end}
              strokeOpacity={0.2}
              fill="rgba(231, 230, 247, 1)"
            />
            
          )} 
          {dataPlotting.optionsParmsPlotting?.length &&
            filteredDataPlotting?.map((lineKey, index) => {
              const color =
                colorMapping[lineKey.device]?.color || lineKey.color;
              return (
                <YAxis
                  key={`yAxis-${lineKey.device}`}
                  label={{
                    value: lineKey.device,
                    angle: -90,
                    position: index % 2 === 0 ? "outsideLeft" : "outsideRight",
                    fill: color,
                    fontSize: 14,
                    dx: index % 2 === 0 ? -10 : 10,
                  }}
                  tick={{ fill: color, fontSize: 12 }}
                  yAxisId={index}
                  orientation={index % 2 === 0 ? "left" : "right"}
                  stroke={color}
                  interval={0}
                  width={70}
                  tickCount={10}
                  domain={
                    dataPlotting.dataPlotting[
                      dataPlotting.dataPlotting?.findIndex(
                        (item) => item.device === lineKey.device
                      )
                    ].domain
                  }
                  allowDataOverflow={true}
                />
              );
            })}
          <Tooltip
            content={<CustomTooltip />}
            /* position={{x:0,y:-120}} */ position={{ x: "auto", y: "auto" }}
          />
          {filteredDataPlotting?.map((lineKey, index) => {
            const { color } = colorMapping[lineKey.device] || {};

            // Configurar el tipo de línea basado en el uso del color
            if (!colorUsageCount[color]) {
              colorUsageCount[color] = 0;
            }
            const lineTypeIndex = colorUsageCount[color] % LINE_TYPES.length;
            const lineType = LINE_TYPES[lineTypeIndex];
            const strokeDasharray = LINE_STYLES[lineTypeIndex];

            // Incrementa después de asignar el tipo de línea
            colorUsageCount[color] += 1;

            return (
              <Line
                key={lineKey.device}
                type={lineType} // Aplica el tipo de interpolación
                dataKey={lineKey.device}
                stroke={color || "#000"}
                yAxisId={index}
                activeDot={{ r: 6 }}
                //dot={{ r: 2, fill: color }}
                dot={false}
                strokeDasharray={strokeDasharray} // Aplica el estilo de línea
                connectNulls={true}
              />
            );
          })}
          <Brush
            dataKey="Date (MM/DD/YYYY)"
            height={20}
            stroke="#8884d8"
            y={0}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
