import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { DashboardContext } from '../context/Dashboard/DashboardContentx';
import { createParametersPlotting, deleteParametersPlotting, getJsonPlottingIOT, getListDevicesPlotting, getListPlatformsPlotting, getParametersPlotting, updateParametersPlotting } from '../services/api/plotting';
import { DashboardPlottingContext } from '../context/DashboardPlotting/DashboardPlottingContext';
import { INITIAL_DATA_PLOTTING } from '../components/elements/types/type_plotting';
import { AuthContext } from '../auth/context/AuthContext';
import { message } from 'antd';

export const useTabPlotting = () => {
    const { userDataInfo } = useContext(DashboardContext);
    const { dataPlotting, getOptionsParmsPlotting, getDataPlotting} = useContext(DashboardPlottingContext);
    const { User } = useContext(AuthContext);
    const [concatenatedData, setConcatenatedData] = useState([]);
    const [inputDataPlot, setInputDataPlot] = useState(INITIAL_DATA_PLOTTING);
    const [optionsAllIOT, setOptionsAllIOT] = useState([]);
    const [mutationCompleted, setMutationCompleted] = useState(false);

    const { data: dataListPlatforms, isLoading: loadDataListPlatforms, isError: ErrorDataListPlatforms, refetch: refetchDataListPlatforms,} = useQuery({
        queryKey: ['dataListPlatforms'],
        queryFn: () => getListPlatformsPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region),
        retry: 1,
        enabled: false,
        onSuccess: (data) => {
        },
        onError: (error) => {
        },
    });
    
    const { data: dataListDevices, isLoading: loadDataListDevices, isError: ErrorDataListDevices, refetch: refetchDataListDevices, isSuccess } = useQuery({
        queryKey: ['dataListDevices',inputDataPlot.selected_platform],
        queryFn: () => getListDevicesPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, inputDataPlot.selected_platform),
        retry: 2,
        enabled: false,
        onSuccess: (data) => {
        },
        onError: (error) => {
        },
    });

    const { data: dataPlottingIOT, isLoading: loadDataPlottingIOT, isError: ErrorDataPlottingIOT, refetch: refetchDataPlottingIOT} = useQuery({
      queryKey: ['dataIOTDCA'],
      queryFn: () => getJsonPlottingIOT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, 'all', 'all'),
      retry: 1,
      enabled: false,
      onSuccess: (data) => {
        if(data?.data){
          const responseData = data?.data;
          const optionsSelect = Array.isArray(responseData) && responseData.length > 0 ? Object.keys(responseData[0]).filter(key => !key.includes("Date")).map((item, index)=> ({
            label: item,
            value: item,
          })) : [];
          setOptionsAllIOT(optionsSelect?.sort((a, b) => a.label.localeCompare(b.label)));
        }
      },
      onError: (error) => {
      },
    })

    const { data: dataParamsPlotting, isLoading: loadDataParamsPlotting, isError: ErrorDataParamsPlotting, refetch: refetchParamsPlotting } = useQuery({
      queryKey: ['dataParamsPlotting'],
      queryFn: () => getParametersPlotting(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name),
      retry: 1,
      enabled: false,
      onSuccess: (data) => {
        // Verifica si data?.data[0] es null
        if (data?.data[0] === null) {
          if (!mutationCompleted) {
            addParameters.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              user: User?.user_name,
              data: INITIAL_DATA_PLOTTING
            });
            setMutationCompleted(true);
          } else {
            addParameters.mutate({
              operator: userDataInfo.clients,
              lease: userDataInfo.proyects,
              region: userDataInfo.platforms,
              user: User?.user_name,
              data: INITIAL_DATA_PLOTTING
            });
          }
          getDataPlotting(INITIAL_DATA_PLOTTING)
        } else {
          // Solo ejecuta getDataPlotting si data?.data[0] es diferente de null
          getDataPlotting({
            selected_platform: data?.data[0].data.selected_platform,
            selected_device: data?.data[0].data.selected_device,
            selected_parameter: data?.data[0].data.selected_parameter,
            optionsParmsPlotting: data?.data[0].data.optionsParmsPlotting,
            dataPlotting: data?.data[0].data.dataPlotting
          });
        }
      },
      onError: (error) => {
        // Manejo de errores si es necesario
      },
    })
  
    const addParameters = useMutation({
      mutationFn: createParametersPlotting,
      retry: 1,
      onSuccess: () => {
        message.success("Data save successfull");
        setMutationCompleted(true)
      },
      onError: () => {
      }
    })

    const cleanParameters = useMutation({
      mutationFn: deleteParametersPlotting,
      onSuccess: () => {
      },
      onError: () => {
      }
    })

    const modifyParameters = useMutation({
      mutationFn: updateParametersPlotting,
      onSuccess: () => {
      },
      onError: () => {
      }
    })

  // Función para obtener el color específico desde el archivo de configuración
  const getSpecificColor = (parameter) => {
    // Mapeo de colores basado en el archivo cargado, ejemplo:
    const colorMap = {
      "SMI-27 LACT Meter 1 Previous Day Volume": "#006400", // Dark Green
      "SMI-27 Fuel Gas Meter Flowing Temperature": "#f5dc00", //Yellow
      "SMI-27 LP Separator Flowing Temperature": "#f5dc00", //Yellow
      "V-191 Test Separator Flowing Temperature": "#f5dc00", //Yellow
      "SMI-27 Sales Gas Meter Flowing Temperature": "#f5dc00", //Yellow
      "SMI-27 LP Separator Differential Pressure": "#A349A4", // Pink
      "SMI-27 Sales Gas Meter Differential Pressure": "#A349A4", // Pink
      "SMI-27 Sales Gas Meter Static Pressure": "#8a8980", //Gray
      "SMI-27 LACT Meter 1 Flow Rate": "#90EE90", // Light Green
      "V-191 Well Head Pressure C6": "#000000", // Black
      "SMI-27 IP Separator Corrected Previous Day Volume": "#8B0000", // Dark Red
      "SMI-27 Fuel Gas Meter Corrected Flow Rate": "#FF0202", // Light Red
      "SMI-27 IP Separator Corrected Flow Rate": "#FF0202", // Light Red
      "SMI-27 Sales Gas Meter Corrected Current Day Volume": "#8B0000", // Dark Red
      "SMI-27 IP Separator Static Pressure": "#800080", // Purple
      "SMI-27 LACT Meter 2 Flow Rate": "#90EE90", // Light Green
      "SMI-27 IP Separator Corrected Accumulated Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Corrected Current Day Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Corrected Flow Rate": "#FF0202", // Light Red
      "SMI-27 LP Separator Liquid Flow Rate": "#BF6B21", // Light Brown
      "SMI-27 IP Separator Liquid Flow Rate": "#BF6B21", // Light Brown
      "SMI-27 Fuel Gas Meter Corrected Accumulated Volume": "#8B0000", // Dark Red
      "SMI-27 Fuel Gas Meter Corrected Current Day Volume": "#8B0000", // Dark Red
      "SMI-27 Sales Gas Meter Corrected Flow Rate": "#FF0202", // Light Red
      "V-191 Test Separator Static Pressure": "#800080", // Purple
      "SMI-27 LACT Meter 2 Previous Day Volume": "#006400", // Dark Green
      "SMI-27 LP Separator Static Pressure": "#800080", // Purple
      "SMI-27 LACT Meter 1 Current Day Volume": "#006400", // Dark Green
      "SMI-27 LACT Meter 2 Net Accumulated Volume": "#006400", // Dark Green
      "SMI-27 Sales Gas Meter Gas Flow Rate": "#FF0202", // Light Red
      "V-191 Test Separator Liquid Flow Rate": "#BF6B21", // Light Brown
      "SMI-27 LACT Meter 2 Current Day Volume": "#006400", // Dark Green
      "V-191 Test Separator Corrected Previous Day Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Liquid Previous Day Total": "#8B4513", // Dark Brown
      "SMI-27 LP Separator Liquid Previous Day Total": "#8B4513", // Dark Brown
      "SMI-27 IP Separator Liquid Previous Day Total": "#8B4513", // Dark Brown
      "SMI-27 LACT Meter 1 Net Accumulated Volume": "#006400", // Dark Green
      "SMI-27 IP Separator Separator Pressure": "#800080", // Purple
      "V-191 Well Head Pressure C9": "#000000", // Black
      "SMI-27 Sales Gas Meter Corrected Previous Day Volume": "#8B0000", // Dark Red
      "SMI-27 LP Separator Corrected Flow Rate": "#FF0202", // Light Red
      "V-191 Test Separator Corrected Accumulated Volume": "#8B0000", // Dark Red
      "V-191 Test Separator Separator Pressure": "#800080", // Purple
      "Water rate": "#ADD8E6", // Light Blue
      "Water volume": "#00008B", // Dark Blue
    };
  
    return colorMap[parameter] || "#e8b7e4"; //light pink
  };

  return {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListDevices,
    refetchDataListDevices,
    dataPlottingIOT,
    refetchDataPlottingIOT,
    concatenatedData,
    setConcatenatedData,
    inputDataPlot,
    setInputDataPlot,
    optionsAllIOT,
    dataParamsPlotting,
    loadDataParamsPlotting,
    ErrorDataParamsPlotting,
    refetchParamsPlotting,
    addParameters,
    cleanParameters,
    modifyParameters,
    loadDataPlottingIOT,
    getSpecificColor
  }
}