export const DATA_END_PARAMETERS = {
	// Info PVT
	initial_data_pvt: {
			res_pressure: "",
			res_temperature: "",
			res_initial_gor: "",
			res_oil_gravity: "",
			res_gas_gravity: "",
			res_H2S: "",
			res_CO2: "",
			res_N2: "",
			option_data_point: "",
			calculate_correlation: "brill_and_beggs",
			data_point_table: [],
	},
	// Info Reservoir
	initialInputReservoir: {
			layer_pressure: "",
			layer_temperature: "",
			md_perf_depth: "",
			water_cut_fraction: "",
			state_reservoir: "",
			ipr_model: "",
			test_pressure_1_s: "",
			test_flow_rate_1_s: "",
			test_pressure_2_s: "",
			test_flow_rate_2_s: "",
			liquid_flow_rate_s: "",
			productivity_index_j_s: "",
			test_pressure_1_us: "",
			test_flow_rate_1_us: "",
			test_pressure_2_us: "",
			test_flow_rate_2_us: "",
			liquid_flow_rate_us: "",
			productivity_index_j_us: "",
			check_phases: false,
			pwf_ipr: false,
			straight_line: false,
			bubblePointPressure: "",
			nameDataGraphic: "",
			dateDataGraphic: new Date().toISOString().split("T")[
					0
			],
			selectDataIPR: "",
	},
	// Info Plunger Lift
	initialInputPlungerLift: {
			reservoir_mid_perf_tvd_depth: "",
			reservoir_temperature: "",
			reservoir_pressure: "",
			production_test_date_pp: "",
			production_liquid_rate: "",
			production_gas_rate: "",
			production_gor: "",
			production_water_cut: "",
			production_supplemental_gas_rate: "",
			twp_test_date: "",
			twp_wellhead_temperature: "",
			twp_wellhead_pressure: "",
			twp_real_time: false,
			cwp_test_date: "",
			cwp_wellhead_pressure: "",
			cwp_wellhead_temperature: "",
			cwp_real_time: false,
			plunger_depth: "",
			plunger_weight: "",
			plunger_fvg: "",
			plunger_fvl: "",
			plunger_rising_velocity: 1000,
			tubing_data: [],
	},

	initialMandatoryData: {
			// Data Alerts
			data_alert: {
					alm_casing_pressure_available_min: null,
					alm_casing_pressure_available_max: null,
					alm_GasVolumePer_min: null,
					alm_GasVolumePer_max: null,
					alm_cycle_day_min: null,
					alm_cycle_day_max: null,
					alm_cycle_day_Rt_max: null,
					alm_casing_pressure_required_min: null,
					alm_casing_pressure_required_max: null,
					alm_GLRproduced_min: null,
					alm_GLRproduced_max: null,
					alm_GLRrequired_min: null,
					alm_GLRrequired_max: null,
					twc_Pressure_min: null,
					twc_Pressure_max: null,
					twc_temperature_min: null,
					twc_temperature_max: null,
					em_npv_min: null,
					em_npv_max: null,
					em_roi_min: null,
					em_roi_max: null,
					cwc_Pressure_min: null,
					cwc_Pressure_max: null,
					cwc_temperature_min: null,
					cwc_temperature_max: null,
					pic_Pressure_min: null,
					pic_Pressure_max: null,
					pic_temperature_min: null,
					pic_temperature_max: null,
					prc_GasRate_min: null,
					prc_GasRate_max: null,
					prc_LiquidRate_min: null,
					prc_LiquidRate_max: null,
					prc_Glr_min: null,
					prc_Glr_max: null,
					prc_GasVolumePer_min: null,
					prc_GasVolumePer_max: null,
					prc_LiquidVolumPer_min: null,
					prc_LiquidVolumPer_max: null,
			},
			// Data Check
			data_check: {
					valuesAlert: {
							alm_GLRrequired: false,
							alm_GLRproduced: false,
							alm_casing_pressure_required: false,
							alm_casing_pressure_available: false,
							alm_cycle_day: false,
							twc_Pressure: false,
							twc_temperature: false,
							em_npv: false,
							em_roi: false,
							cwc_Pressure: false,
							cwc_temperature: false,
							pic_Pressure: false,
							pic_temperature: false,
							prc_GasRate: false,
							prc_LiquidRate: false,
							prc_Glr: false,
							prc_GasVolumePer: false,
							prc_LiquidVolumPer: false,
					},
					valuesCheck: {
							alm_GLRrequired: false,
							alm_GLRproduced: false,
							alm_casing_pressure_required: false,
							alm_casing_pressure_available: false,
							alm_cycle_day: false,
							twc_Pressure: false,
							twc_temperature: false,
							em_npv: false,
							em_roi: false,
							cwc_Pressure: false,
							cwc_temperature: false,
							pic_Pressure: false,
							pic_temperature: false,
							prc_GasRate: false,
							prc_LiquidRate: false,
							prc_Glr: false,
							prc_GasVolumePer: false,
							prc_LiquidVolumPer: false,
					},
					countAlert: 0,
					countCheck: 0,
			},
			// Data Domain Graph
			data_domain_graph: {
					gas_rate_min_wpc: null,
					gas_rate_max_wpc: null,
					press_max_wpc: null,
					press_min_wpc: null,
					gas_rate_min_ph: null,
					gas_rate_max_ph: null,
					liquid_rate_min_ph: null,
					liquid_rate_max_ph: null,
			},
			// Dashboard Graph Settings
			type_graph_dashboard: [{
					wellhead_production_conditions: {
							gas_rate: null,
							casing_pressure: null,
							tubing_pressure: null,
							pipeline_pressure: null,
							type_graph: false,
							time_plunger_lift: null,
					},
					production_history: {
							type_graph: false,
							device_sensor: null,
					},
			}, ],
			// Slide Graph
			slide_graph_plungerlift: 1,
			// Real-Time Checks
			checksRealTime: {
					dashboard: false,
			},
	},
};

export const DATA_CALCULATE_PL = {
	id_tubing: "",
	od_tubing_in: "",
	wh_temp_casing: "",
	res_temp: "",
	plunger_weight: "",
	plunger_rising_vel: "",
	plunger_fall_vel_liq: "",
	plunger_fall_vel_gas: "",
	wh_press_tubing: "",
	plunger_depth_md: "",
	id1_casing: "",
	id2_casing: "",
	id3_casing: "",
	length1: "",
	length2: "",
	length3: "",
	sgg_mix: "",
	yn2: "",
	yco2: "",
	yh2s: "",
	qo_max: [],
	qg_sc: "",
	qg_supp_sc: "",
	ql_sc: "",
	wh_press_casing: "",
};

export const DATA_PLUNGER_LIFT = {
	initialInputProcessPlungerLift: {
		comments: "",
		liquid_rate: "",
		gas_rate: "",
		supplemental_gas_rate: "",
		required_casing_pressure: "",
		current_cycles_per_day: "",
		required_gas_volume_per_cycle: "",
		change_gas_liquid_ratio: "",
		cycles_per_day: "",
		key_glr_available: "",
		key_glr_minimum: "",
		required_gas_liquid_ratio: "",
		change_casing_pressure: "",
		change_gas_rate: "",
		change_liquid_rate: "",
		current_casing_pressure: "",
		current_gas_liquid_ratio: "",
		current_gas_rate: "",
		current_gas_volume_per_cycle: "",
		current_liquid_rate: "",
		estimated_gas_volume_per_cycle: "",
		key_casing_pressure_available: "",
		key_casing_pressure_minimum: "",
		key_max_cycles_per_day: "",
		optimum_cycles_per_day: "",
		optimum_gas_liquid_ratio: "",
		optimum_gas_rate: "",
		optimum_gas_volume_per_cycle: "",
		optimum_liquid_rate: "",
		optimum_surface_casing_pressure: "",
	},
	initialDataTubing: [
		{
			id: 0,
			nameTubing: "",
			measured_depth: "",
			internal_diameter: "",
			external_diameter: "",
		},
		{
			id: 1,
			nameTubing: "",
			measured_depth: "",
			internal_diameter: "",
			external_diameter: "",
		},
		{
			id: 2,
			nameTubing: "",
			measured_depth: "",
			internal_diameter: "",
			external_diameter: "",
		},
	],
};

export const DATA_WELL_PLUNGER_LIFT = {
	InitialDataWell: {
		well_id: "",
		region: "",
		field: "",
		latitude: "",
		company: "",
		well_type: "",
		artificial_method: "",
		flow_type: "",
		well_name: "",
		well_platforms: "",
		country: "",
		county: "",
		longitude: "",
		objective: "",
		well_orientation: "",
		well_reference: "",
		fluid_type: "",
	},
	InitialWellDeviation: [
		{
			id: 0,
			measured_depth: "",
			tvd: "",
			inc: "",
		},
		{
			id: 1,
			measured_depth: "",
			tvd: "",
			inc: "",
		},
		{
			id: 2,
			measured_depth: "",
			tvd: "",
			inc: "",
		},
	],
	InitialWelEquipment: [
		{
			id: 0,
			name: "Intermediate casing",
			type: "Casing",
			measured_depth: "",
			internal_diameter: "",
			external_diameter: "",
			absolute_roughness: "",
		},
		{
			id: 1,
			name: "Production Casing",
			type: "Casing",
			measured_depth: "",
			internal_diameter: "",
			external_diameter: "",
			absolute_roughness: "",
		},
		{
			id: 2,
			name: "Tubing",
			type: "Tubing",
			measured_depth: "",
			internal_diameter: "",
			external_diameter: "",
			absolute_roughness: "",
		},
	],
	initialCoordinates: {
		latitudeDegrees: "",
		latitudeMinutes: "",
		latitudeSeconds: "",
		latitudeDirection: "",
		longitudeDegrees: "",
		longitudeMinutes: "",
		longitudeSeconds: "",
		longitudeDirection: "",
	},
};

export const DATA_PVT_PLUNGER_LIFT = {
	initialAutomaticData: [
		{
			id: 0,
			name: "From",
			from_temperature: "",
			from_pressure: "",
		},
		{
			id: 1,
			name: "To",
			to_temperature: "",
			to_pressure: "",
		},
		{
			id: 2,
			name: "Values",
			values_temperature: 1,
			values_pressure: "",
		},
	],
	initialDataCalculation: {
		option_data_point: "",
		calculate_correlation: "brill_and_beggs",
	},
	initialUserSelectionData: [
		{
			id: 0,
			temperature: "",
			pressure: "",
		},
		{
			id: 1,
			temperature: "",
			pressure: "",
		},
		{
			id: 2,
			temperature: "",
			pressure: "",
		},
	],
	stateChecks: [
		{
			gas_z_factor: true,
			gas_density: true,
		},
	],
	initialDataTablePVT: [
		{
			rs: [],
			press: [],
			bo: [],
			viso: [],
			co: [],
		},
	],
};

export const REQUIRED_PROPIERTIES_PL = [
	"well_id",
	"well_platforms",
	"well_name",
	"fluid_type",
	"region",
	"country",
	"field",
	"latitude",
	"longitude",
	"res_pressure",
	"res_temperature",
	"res_initial_gor",
	"res_oil_gravity",
	"res_gas_gravity",
	"res_H2S",
	"res_CO2",
	"res_N2",
	"layer_pressure",
	"layer_temperature",
	"md_perf_depth",
	"water_cut_fraction",
	"reservoir_mid_perf_tvd_depth",
	"reservoir_temperature",
	"reservoir_pressure",
	"production_water_cut",
];

export const INITIAL_POST_DATA_PVT = {
	API_gravity_of_oil: null,
	specific_gravity_of_gas: null,
	temp_res: null,
	rsb_init: null,
	rsb_sep: null,
	bob_sep: null,
	sGg_sep: null,
	p_sep: null,
	t_sep: null,
	API_sep: null,
	lab_temp: null,
	pb_lab: null,
	rsb_lab: null,
	rsdb_lab: null,
	bodb_lab: null,
	rsw_calc: null,
	pb_calc: null,
	specific_gravity_of_oil: null,
};

export const INITIAL_POST_PVT_CALC = {
	p_lab: null,
	rs_lab: null,
	rs_init: null,
	co_lab: null,
	bo_lab: null,
	visc_oil_lab: null,
	press_calc: null,
	numbers_press_rows: null,
};

export const DATA_RESERVOIR = {
	initialInputReservoir: {
		layer_pressure: "",
		layer_temperature: "",
		md_perf_depth: "",
		water_cut_fraction: "",
		state_reservoir: "saturated",
		ipr_model: "",
		test_pressure_1_s: "",
		test_flow_rate_1_s: "",
		test_pressure_2_s: "",
		test_flow_rate_2_s: "",
		productivity_index_j_s: "",
		liquid_flow_rate_s: "",
		test_pressure_1_us: "",
		test_flow_rate_1_us: "",
		test_pressure_2_us: "",
		test_flow_rate_2_us: "",
		productivity_index_j_us: "",
		liquid_flow_rate_us: "",
		check_phases: "",
		pwf_ipr: false,
		straight_line: false,
		bubblePointPressure: 0,
	},
};



export const STATE_ALERTS = {
	alert_alm_GLRrequired: "alm_GLRrequired",
	alert_alm_GasVolumePer: "alm_GasVolumePer",
	alert_alm_GLRproduced: "alm_GLRproduced",
	alert_alm_casing_pressure_required: "alm_casing_pressure_required",
	alert_alm_casing_pressure_available: "alm_casing_pressure_available",
	alert_alm_cycle_day: "alm_cycle_day",
	alert_alm_cycle_day_Rt: "alm_cycle_day_Rt",
	alert_twc_Pressure: "twc_Pressure",
	alert_twc_temperature: "twc_temperature",
	alert_em_npv: "em_npv",
	alert_em_roi: "em_roi",
	alert_cwc_Pressure: "cwc_Pressure",
	alert_cwc_temperature: "cwc_temperature",
	alert_pic_Pressure: "pic_Pressure",
	alert_pic_temperature: "pic_temperature",
	alert_prc_GasRate: "prc_GasRate",
	alert_prc_LiquidRate: "prc_LiquidRate",
	alert_prc_Glr: "prc_Glr",
	alert_prc_GasVolumePer: "prc_GasVolumePer",
	alert_prc_LiquidVolumPer: "prc_LiquidVolumPer",
};

export const dataCheckPVT = [
	{
		name: "Bubble point pressure",
		check: false,
	},
	{
		name: "Solution Gas-Oil ratio",
		check: false,
	},
	{
		name: "Oil formation volume factor",
		check: false,
	},
	{
		name: "Oil viscosity",
		check: false,
	},
];

export const EMPTY_DATA_PVT = {
	res_pressure: "",
	res_temperature: "",
	res_initial_gor: "",
	res_oil_gravity: "",
	res_gas_gravity: "",
	res_H2S: "",
	res_CO2: "",
	res_N2: "",
	option_data_point: "",
	calculate_correlation: "",
	data_point_table: [],
};

export const EMPTY_DATA_RESERVOIR = {
	layer_pressure: "",
	layer_temperature: "",
	md_perf_depth: "",
	water_cut_fraction: "",
	state_reservoir: "",
	ipr_model: "",
	test_pressure_1_s: "",
	test_flow_rate_1_s: "",
	test_pressure_2_s: "",
	test_flow_rate_2_s: "",
	productivity_index_j_s: "",
	test_pressure_1_us: "",
	test_flow_rate_1_us: "",
	test_pressure_2_us: "",
	test_flow_rate_2_us: "",
	productivity_index_j_us: "",
	check_phases: false,
	pwf_ipr: false,
	straight_line: false,
};

export const TYPE_GRAPH_FLUID_CONFIG = [
	{
		name: "Gas Z Factor",
		value: "gas_z_factor",
	},
	{
		name: "Gas Density",
		value: "gas_density",
	},
];

export const NUMBER_FORMAT_MAP = {
	cycles_per_day: "1-decimal",
	estimated_cycles_per_day: "1-decimal",
	current_cycles_per_day: "1-decimal",
	change_casing_pressure: "2-decimals",
	change_gas_liquid_ratio: "2-decimals",
	change_gas_rate: "2-decimals",
	change_liquid_rate: "2-decimals",

	required_casing_pressure: "2-decimals",
	liquid_rate: "2-decimals",
	estimated_liquid_rate: "2-decimals",
	current_liquid_rate: "2-decimals",
	gas_rate: "2-decimals",
	estimated_gas_rate: "2-decimals",
	current_gas_rate: "2-decimals",

	supplemental_gas_rate: "4-decimals",
	required_gas_liquid_ratio: "4-decimals",
	required_gas_volume_per_cycle: "4-decimals",
	plunger_depth: "4-decimals",
	plunger_fvl: "4-decimals",
	plunger_fvg: "4-decimals",

	cwp_wellhead_pressure: "cwp_wellhead_pressure",
	twp_wellhead_pressure: "twp_wellhead_pressure",

	//CassingPressure
	optimum_liquid_rate: "2-decimals",
	optimum_gas_rate: "2-decimals",
	optimum_surface_casing_pressure: "2-decimals",
	optimum_gas_volume_per_cycle: "4-decimals",
	optimum_gas_liquid_ratio: "4-decimals",
	optimum_cycles_per_day: "1-decimal",

	key_glr_available: "4-decimals",
	key_glr_minimum: "4-decimals",
	key_casing_pressure_available: "2-decimals",
	key_casing_pressure_minimum: "2-decimals",
	key_max_cycles_per_day: "1-decimal",
};

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
	"date",
	"gas_rate",
	"liquid_rate",
	"casing_pressure",
	"tubing_pressure",
	"pipeline_pressure",
];

export const REQUIRED_COLUMNS_TABLE_DEVIATION = [
	"measured_depth",
	"tvd",
	"inc",
];

export const REQUIRED_COLUMNS_TABLE_EQUIPMENT = [
	"name",
	"type",
	"measured_depth",
	"internal_diameter",
	"external_diameter",
	"absolute_roughness",
];

export const INITIAL_INPUT_VOLUME_TRACKER = {
	production_history: {
		fcat: null,
		fcat_gas: null,
		water_cut: null,
		separator_type: "",
	},
};

export const OPTIONS_CALCS_PLUNGER = {
	glr: "const_by_glr",
	pc: "const_by_casing_pressure",
};

export const INITIAL_STATE_PROCESS_PL = {
	well: false,
	pvt: false,
	dashboard: false,
	reservoir: false,
	plunger_lift: false,
};
