import React, { useContext } from "react";
import { DashboardPlottingContext } from "../../../context/DashboardPlotting/DashboardPlottingContext";
import { LINE_TYPES, LINE_STYLES } from "../../elements/types/type_plotting";

// Mapa de estilos de línea para representar visualmente cada tipo de línea
const LINE_STYLE_MAP = LINE_TYPES.reduce((acc, type, index) => {
  acc[type] = LINE_STYLES[index];
  return acc;
}, {});

export const TablePlotting = ({
  platform,
  selectedData,
  colorMapping,
  setColorMapping,
}) => {
  const { dataPlotting, getDataPlotting } = useContext(
    DashboardPlottingContext
  );
  const filteredInputDomain = dataPlotting?.dataPlotting?.filter((item) =>
    selectedData.includes(item.device)
  );

  const handleInputDomain = (e, selectedValue, index, position) => {
    const updatedDomain = [...dataPlotting.dataPlotting];
    const newDomainValue = Number(e.target.value) || 0;
    const indexData = updatedDomain?.findIndex(
      (item) => item.device === selectedValue
    );
    updatedDomain[indexData].domain[position] = newDomainValue;
    getDataPlotting({ ...dataPlotting, dataPlotting: updatedDomain });
  };

  const handleColorChange = (e, label) => {
    const newColor = e.target.value;
    const updatedColor = [...dataPlotting.dataPlotting];
    const indexData = updatedColor?.findIndex((item) => item.device === label);
    updatedColor[indexData].color = newColor;
    getDataPlotting({ ...dataPlotting, dataPlotting: updatedColor });
    setColorMapping((prevMapping) => ({
      ...prevMapping,
      [label]: { ...prevMapping[label], color: newColor },
    }));
  };

  // Componente para el icono de tipo de línea
  const LineTypeIcon = ({ type, color }) => (
    <svg width="60" height="10" style={{ margin: "0 auto" }}>
      <line
        x1="0"
        y1="5"
        x2="60"
        y2="5"
        stroke={color}
        strokeWidth="2"
        strokeDasharray={LINE_STYLE_MAP[type]}
      />
    </svg>
  );

  // Mapa para contar las veces que se usa cada color y alternar tipos de línea
  const colorCountMap = {};

  return (
    <table className="min-w-full text-sm font-light text-left text-surface">
      <thead className="font-medium text-black bg-white border-b ">
        <tr>
          <th scope="col" className="px-6 py-2 text-center border border-gray-400">
            Color
          </th>
          <th scope="col" className="px-6 py-2 text-center border border-gray-400">
            Line Type
          </th>
          <th scope="col" className="px-6 py-2 text-center border border-gray-400">
            Platform
          </th>
          <th scope="col" className="px-6 py-2 text-center border border-gray-400">
            Parameter
          </th>
          <th scope="col" className="px-6 py-2 text-center border border-gray-400">
            Scale Min
          </th>
          <th scope="col" className="px-6 py-2 text-center border border-gray-400">
            Scale Max
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredInputDomain?.map((item, index) => {
          const color = colorMapping[item.device]?.color || item.color;

          // Incrementa el contador de este color en el mapa
          if (!colorCountMap[color]) {
            colorCountMap[color] = 0;
          }
          const lineTypeIndex = colorCountMap[color] % LINE_TYPES.length;
          const lineType = LINE_TYPES[lineTypeIndex];

          // Incrementa después de asignar el tipo de línea para la próxima ocurrencia
          colorCountMap[color] += 1;

          return (
            <tr key={index} className="border-b border-neutral-200" style={{ height: "30px" }}>
              <td className="text-center border border-gray-400">
                <input
                  type="color"
                  className="items-center justify-center w-full h-[30px] text-center"
                  value={color}
                  onChange={(e) => handleColorChange(e, item.device)}
                />
              </td>
              <td className="text-center border border-gray-400">
                <LineTypeIcon type={lineType} color={color} />
              </td>
              <td className="px-6 text-center border border-gray-400">
                {platform}
              </td>
              <td className="px-6 text-center border border-gray-400">
                {item.device}
              </td>
              <td className="text-center border border-gray-400">
                <input
                  type="text"
                  className="box-border items-center justify-center w-full h-full text-center"
                  value={item.domain[0]}
                  onChange={(e) => handleInputDomain(e, item.device, index, 0)}
                />
              </td>
              <td className="text-center border border-gray-400">
                <input
                  type="text"
                  className="box-border items-center justify-center w-full h-full text-center"
                  value={item.domain[1]}
                  onChange={(e) => handleInputDomain(e, item.device, index, 1)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
