import React, { useEffect, useState, useContext } from "react";
import { KpiGraph } from "./KpiGraph";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import styleCard from "./CardGraph.module.css";

const {
  containerCard,
  containerColumn1,
  containerColumn2,
  tittle,
  units,
  values,
  containerKpi,
  blinking
} = styleCard;
const initialData = [
  {
    id: "",
    ranges: [50, 100],
    measures: [50],
    markers: [10],
  },
];
export const CardGraph = ({ title, value, unit, color, name }) => {
  
  const { dataAlertPlungerLift, someAlert } = useContext(DashboardContext);
  const [data, setData] = useState(initialData);
  const [shouldBlink, setShouldBlink] = useState(false);
  const [valueAlertMin, setValueAlertMin] = useState(0);
  const [valueAlertMax, setValueAlertMax] = useState(0);
  const [realValue, setRealValue] = useState();

  useEffect(() => {
    const valueMin = !dataAlertPlungerLift[`${name}_min`]
      ? 0
      : dataAlertPlungerLift[`${name}_min`];
    const valueMax = !dataAlertPlungerLift[`${name}_max`]
      ? 0
      : dataAlertPlungerLift[`${name}_max`];
    const valueData = value === "--" ? null : value;
    setValueAlertMin(valueMin);
    setValueAlertMax(valueMax);
    setRealValue(valueData);
  }, [dataAlertPlungerLift, value]);

  useEffect(() => {
    if (
      (realValue > valueAlertMax || realValue < valueAlertMin) &&
      realValue != null
    ) {
      setShouldBlink(someAlert.plunger_lift.valuesCheck[name] ? true : false);
    } else {
      setShouldBlink(false);
    }
  }, [realValue, valueAlertMax, valueAlertMin, someAlert]);
  
  const blinkingStyle = {
    animation: shouldBlink ? "blinking 0.8s infinite alternate" : "none",
  };

  return (
    <div className={containerCard} style={blinkingStyle}>
      <div className={containerColumn1}>
        <h3 className={tittle}>
          {title}
          <label className={units}>{unit}</label>
        </h3>
        <span
          className={`${values} ${color ? "text-green-500" : "text-pc-grey3"}`}
        >
          {value === null ? "--" : value}
        </span>
      </div>
      <div className={containerColumn2}>
        <div>
          <KpiGraph
            dataKpi={data}
            nameValue={name}
            val_max={valueAlertMax}
            val_min={valueAlertMin}
            stateCheck={realValue}
            stateColor={shouldBlink}
          ></KpiGraph>
        </div>
        <div className={containerKpi}>
          <label htmlFor="" className="h-full mr-2 text-xs">
            {" "}
            KPI{" "}
          </label>
          <input
            type="text"
            className="flex w-full h-full text-xs text-white bg-pc-grey2"
          />
        </div>
      </div>
    </div>
  );
};
