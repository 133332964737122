import React, { useEffect, useState } from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const CustomTooltip = ({ active, payload, nameSelect}) => {

  const data = payload[0]?.payload;
  if (active && payload && payload?.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Pwf = ${Math.round(payload[0].payload?.steps)} psia`}</p>
        {nameSelect?.length > 0 &&(
            <p>{`Base Case`}</p>
        )}
        {data?.qliq_pet && (
          <>
            <p className="label text-[#873d01]">{`Liquid Rate = ${Math.round(payload[0].payload.qliq_pet)} STB/D`}</p>
            <p className="label text-[#008000]">{`Oil Rate = ${Math.round(payload[0].payload.qo_pet)} STB/D`}</p>
            <p className="label text-[#0000ff]">{`Water Rate = ${Math.round(payload[0].payload.qw_pet)} STB/D`}</p>
          </>
        )}
        {nameSelect?.length > 0 &&(
          <hr className="bg-[#1f2937]" />
        )}
        {data?.qliq_pet1 && (
          <>
            <p>{nameSelect[0]}</p>
            <p className="label text-[#873d01]">{`Liquid Rate = ${Math.round(payload[0].payload.qliq_pet1)} STB/D`}</p>
            <p className="label text-[#008000]">{`Oil Rate = ${Math.round(payload[0].payload.qo_pet1)} STB/D`}</p>
            <p className="label text-[#0000ff]">{`Water Rate = ${Math.round(payload[0].payload.qw_pet1)} STB/D`}</p>
          </>
        )}
        {nameSelect?.length > 0 &&(
          <hr className="bg-[#1f2937]" />
        )}
        {data?.qliq_pet2 && (
          <>
            <p>{nameSelect[1]}</p>
            <p className="label text-[#873d01]">{`Liquid Rate = ${Math.round(payload[0].payload.qliq_pet2)} STB/D`}</p>
            <p className="label text-[#008000]">{`Oil Rate = ${Math.round(payload[0].payload.qo_pet2)} STB/D`}</p>
            <p className="label text-[#0000ff]">{`Water Rate = ${Math.round(payload[0].payload.qw_pet2)} STB/D`}</p>
          </>
        )}
      </div>
    );
  }

  return null;
};

const CustomLegend = ({ payload, stateSelect }) => {

  if ( stateSelect?.length != 0 && stateSelect?.length != undefined) {
    const uniqueGroups = new Set();
    const typeLine = ['3 3', '7 2', '4 1', '5 5', '4 3'];
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {payload.map((entry, index) => {
          
          // Verifica los grupos de interés
          const isMainGroup = entry.value === "qw_pet" || entry.value === "qo_pet" || entry.value === "qliq_pet";
          const isSecondaryGroup = entry.value === "qw_pet1" || entry.value === "qo_pet1" || entry.value === "qliq_pet1";
          const isTertiaryGroup = entry.value === "qw_pet2" || entry.value === "qo_pet2" || entry.value === "qliq_pet2";
  
          // Si es un grupo principal y aún no se ha mostrado
          if (isMainGroup && !uniqueGroups.has('main')) {
            uniqueGroups.add('main'); // Marcar este grupo como mostrado
            return (
              <div key={`item-main`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                <svg width="12" height="12">
                  <line x1="0" y1="6" x2="12" y2="6" stroke={"black"} fill="black" strokeWidth={3} /> {/* Círculo vacío */}
                </svg>
                <span style={{ marginLeft: '5px' }}>Base case</span>
              </div>
            );
          }
  
          // Si es un grupo secundario y aún no se ha mostrado
          if (isSecondaryGroup && !uniqueGroups.has('secondary')) {
            uniqueGroups.add('secondary'); // Marcar este grupo como mostrado
            return (
              <div key={`item-secondary`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                <svg width="12" height="12">
                <polygon points="6,1 1,11 11,11" stroke={"black"} fill="black" />
                </svg>
                <svg width="20" height="10" style={{ marginRight: 4 }}>
                  <line 
                    x1="0" 
                    y1="5" 
                    x2="20" 
                    y2="5" 
                    stroke={"black"}
                    strokeWidth={3}
                    strokeDasharray={typeLine[index % typeLine?.length]} 
                  />
                </svg>
                <span style={{ marginLeft: '5px' }}>{stateSelect[0]}</span>
              </div>
            );
          }
  
          // Si es un grupo terciario y aún no se ha mostrado
          if (isTertiaryGroup && !uniqueGroups.has('tertiary')) {
            uniqueGroups.add('tertiary'); // Marcar este grupo como mostrado
            return (
              <div key={`item-tertiary`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                <svg width="12" height="12">
                  <circle cx="6" cy="6" r="5" stroke={"black"} fill={"black"} /> {/* Círculo vacío */}
                </svg>
                <svg width="20" height="10" style={{ marginRight: 4 }}>
                  <line 
                    x1="0" 
                    y1="5" 
                    x2="20" 
                    y2="5" 
                    stroke={"black"}
                    strokeWidth={3}
                    strokeDasharray={typeLine[index % typeLine?.length]} 
                  />
                </svg>
                <span style={{ marginLeft: '5px' }}>{stateSelect[1]}</span>
              </div>
            );
          }
  
          return null; // No renderizar nada si no es un grupo de interés
        })}
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div key={`item-liquid`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
          <svg width="12" height="12">
            <line x1="0" y1="6" x2="12" y2="6" stroke={"black"} strokeWidth={3} />
          </svg>
          <span style={{ marginLeft: '5px', color: "#873d01" }}>Liquid Rate</span>
        </div>
        <div key={`item-oil`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
          <svg width="12" height="12">
            <line x1="0" y1="6" x2="12" y2="6" stroke={"#008000"} strokeWidth={3} />
          </svg>
          <span style={{ marginLeft: '5px', color: "#008000" }}>Oil Rate</span>
        </div>
        <div key={`item-water`} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
          <svg width="12" height="12">
            <line x1="0" y1="6" x2="12" y2="6" stroke={"#0000ff"} strokeWidth={3} />
          </svg>
          <span style={{ marginLeft: '5px', color: "#0000ff" }}>Water Rate</span>
        </div> 
      </div>
    );
  }

};

const TriangleDot = ({ cx, cy, fill }) => (
  <polygon
    points={`${cx - 6},${cy + 6} ${cx + 6},${cy + 6} ${cx},${cy - 6}`} // Triángulo hacia arriba
    fill={fill}
  />
);

const ChartComposite = ({ dataResults, optionDataIPR, dataIPR }) => {
  const [data, setData] = useState();
  const [dataGraphic, setDataGraphic] = useState([]);

  useEffect(() => {
    if (!!dataIPR) {
      setDataGraphic(prevData => {
        // Verifica si ya existe un elemento con el mismo 'date' y 'name'
        const alreadyExists = prevData.some(data => data?.date === dataIPR?.date && data?.name === dataIPR?.name);
        // Si no existe la data, agrega el nuevo dato, si existe la mantiene igual
        return alreadyExists ? prevData : [...prevData, dataIPR];
      });
    }
  }, [dataIPR]);

  const transformData = (dataGraphicIPR) => {
    // Verifica que dataResults esté presente y sea un array
    if (!Array.isArray(dataResults)) return [];
    // Recorrer dataResults y agrupar por pasos (steps)
    const filteredData = dataResults.map((result, index) => {
      const { qliq_pet, qo_pet, qw_pet, steps } = result;
      const indexvalue = index;
      // Inicializar el objeto mergedResult
      let mergedResult = {};
      // Verifica que dataGraphicIPR sea un array
      if (Array.isArray(dataGraphicIPR)) {
        // Recorrer cada elemento de dataGraphicIPR
        dataGraphicIPR.forEach((item, index) => {
                const position = optionDataIPR.indexOf(item.name);
                if (optionDataIPR.includes(item.name)) {
                    // Compara los steps considerando precisión
                    const matchingGraphic = item.steps.find((step) => {
                      const stepvalue = Number(step)?.toFixed(2) === Number(steps)?.toFixed(2)
                      return stepvalue;
                    }) || Number(steps);
                    // Si se encuentra un matchingGraphic, fusiona los datos
                    if (matchingGraphic || matchingGraphic === 0) {
                        if (!mergedResult.steps) {
                            mergedResult.steps = matchingGraphic?.toFixed(2);
                        }
                        // Agregar los valores dinámicamente al objeto mergedResult
                        mergedResult["qo_pet"] = qo_pet;
                        mergedResult["qliq_pet"] = qliq_pet;
                        mergedResult["qw_pet"] = qw_pet;
                        mergedResult[`qo_pet${position + 1}`] = item.qo.qo_pet?.[indexvalue] || null;
                        mergedResult[`qliq_pet${position + 1}`] = item.qo.qliq_pet?.[indexvalue] || null;
                        mergedResult[`qw_pet${position + 1}`] = item.qo.qw_pet?.[indexvalue] || null;
                    }
                }
              });
            }
        // Solo retorna el objeto si tiene contenido, de lo contrario lo omite
        return Object.keys(mergedResult)?.length > 0 ? mergedResult : null;
    }).filter(result => result !== null); // Filtra los resultados vacíos
    return filteredData;
  };
  
  useEffect(() => {
    if(!!dataResults && !!dataGraphic && optionDataIPR?.length > 0){
      const newData = transformData(dataGraphic)
      setData(newData)
    } else if (!!dataResults) {
      setData(dataResults)
    } else if (!!dataGraphic && optionDataIPR?.length > 0){
      const newData = transformData(dataGraphic)
      setData(newData)
    } else {
      setData(null)
    }
  }, [dataResults, dataGraphic, optionDataIPR])

  const isValidData = Array.isArray(data) && data?.length > 0;

  // Inicializa maxValueY de forma segura
  const maxValueY = isValidData ? Math.max(...data.map(d => d.steps)) : 0;

  // Lógica para determinar tickStepY basada en maxValueY
  let tickStepY = 100; // Valor predeterminado
  if (maxValueY > 100000) {
    tickStepY = 10000;
  } else if (maxValueY > 10000) {
    tickStepY = 1000;
  } else if (maxValueY > 1000) {
    tickStepY = 500;
  } else if (maxValueY > 100) {
    tickStepY = 100;
  } else if (maxValueY > 10) {
    tickStepY = 10;
  } else if (maxValueY > 0) {
    tickStepY = 1;
  }

  // Calcula roundedMaxY y genera ticksY de forma segura
  const roundedMaxY = isValidData ? Math.ceil(maxValueY / tickStepY) * tickStepY : 0;

  const getColor = (key) => {
    
    if (key === "qliq_pet" || key === "qliq_pet1" || key === "qliq_pet2" ) {
        return '#873d01';
      } else if ( key === "qo_pet" || key === "qo_pet1" || key === "qo_pet2" ) {
        return '#008000';
      } else if (key === "qw_pet" || key === "qw_pet1" || key === "qw_pet2" ) {
        return '#0000ff';
      }
      /* const dynamicColors = [ '#008000', '#873d01', '#0000ff', '#873d01', '#008000', '#0000ff', '#873d01', '#008000', '#0000ff']; // Colores dinámicos
       return dynamicColors[index % dynamicColors.length]; // Asigna un color basado en el índice */
    
  };

  const getDashArrayForIndex = (index) => {
      const dashArrays = ['3 3', '3 3', '3 3', '7 5', '7 5', '7 5' ]; 
      return dashArrays[index % dashArrays.length]; 
  };

  const getPhaseKeys = (data) => {
    const keys = new Set();
  
    data?.forEach(item => {
      
      Object.keys(item).forEach(key => {
        
        // Verificamos si la clave empieza con 'qliq_pet', 'qo_pet', o 'qw_pet'
        if (key.startsWith('qliq_pet') || key.startsWith('qo_pet') || key.startsWith('qw_pet')) {
          keys.add(key); // Añadimos las claves que cumplan con la condición
        }
      });
    });
  
    // Convertimos el Set a un array y lo ordenamos
    return Array.from(keys).sort((a, b) => {
      const extractNum = (str) => parseInt(str.replace(/\D/g, ''), 10); // Extraemos los números de las claves
      const numA = extractNum(a); 
      const numB = extractNum(b);
      return numA - numB; // Ordenamos de menor a mayor
    });
  };

  const getStrokeWidth = (key) => {
    if (key === 'qliq_pet1' || key === 'qo_pet1' || key === 'qw_pet1') {
      return 2; // Línea más gruesa
    } else if (key === 'qliq_pet2' || key === 'qo_pet2' || key === 'qw_pet2') {
      return 2; // Línea intermedia
    } 
    return 2; // Valor por defecto
  };

  const phaseKeys = getPhaseKeys(data);

  const CustomDot = ({ cx, cy, fill }) => (
    <circle cx={cx} cy={cy} r={4} fill={fill} />
  );
  
  return (
    <div className="flex w-[1170px] h-[710px]"> 
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={500}
          data={data}
          margin={{
            left: 5,
            bottom: 30,
            top: 30
          }}
          layout="vertical"
        >
          <XAxis
            tickCount={10}
            label={{
              value: `Liquid Rate (STB/D)`,
              position: 'insideBottom ',
              style: { textAnchor: "middle" },
              dy: 20,
            }}
            allowDataOverflow={true} // Permite que los datos sobrepasen los límites establecidos por domain
            type='number'
          />
          <YAxis
            dataKey="steps"
            domain={[0, roundedMaxY]}
            tickCount={10} // Especifica manualmente los ticks
            label={{
              value: `Bottomhole flowing pressure (psia)`,
              angle: -90,
              position: 'insideLeft',
              offset: 1,
              dy: 110,
            }}
            type="number"
            margin={{ left: 10 }}
            allowDataOverflow={true}
            reversed
          />
          <Tooltip content={<CustomTooltip nameSelect={optionDataIPR} />} />
          <Legend
            layout="horizontal"     // Opciones: "vertical", "horizontal"
            align="center"         // Opciones: "left", "center", "right"
            wrapperStyle={{ bottom: '5px', right: 0, lineHeight: 0 }}
            content={<CustomLegend stateSelect={optionDataIPR} />}
          />
          {phaseKeys?.map((key, index) => {
            
            return (
            <Line 
              key={key} 
              type="monotone" 
              dataKey={key} 
              stroke={getColor(key, index)}
              strokeWidth={getStrokeWidth(key)}
              strokeDasharray={key != "qliq_pet" && key != "qo_pet" && key != "qw_pet" ? getDashArrayForIndex(index) : false} 
              activeDot={{ r: 8}}
              dot={
                key === "qliq_pet" || key === "qo_pet" || key === "qw_pet" ? false : 
                key === "qliq_pet1" || key === "qo_pet1" || key === "qw_pet1" ? 
                <TriangleDot fill={getColor(key, index)} /> : 
                <CustomDot fill={getColor(key, index)} />
              }
            />
          )})}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default ChartComposite;
