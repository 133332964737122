import { config } from "../../config/config";

const BASE_URL = config.API.PORT_DB;
const URL_FILTER = config.API.USERS.URL_FILTER;
const TOKEN_FILTER = config.AUTH.TOKEN_FILTER;

export const getUsersData = async (token, table) => {
	try {
		const response = await fetch(`${BASE_URL}/Users/${table}`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with user obtaining");
	}
};

export const getUserNameInfo = async (token, table, email) => {
	try {
		const response = await fetch(`${BASE_URL}/Username/${table}/${email}`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with user obtaining");
	}
};

export const getUserDataForName = async (token, Username, table) => {
	try {
		const response = await fetch(`${BASE_URL}/Username/${table}/${Username}`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with user information");
	}
};

export const getUsersCLients = async (token) => {
	try {
		const response = await fetch(`${BASE_URL}/Clients`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with customer");
	}
};

export const getUsersClient = async (token, idClient) => {
	try {
		const response = await fetch(`${BASE_URL}/Clients/${idClient}`, {
			method: "Get",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with customer");
	}
};

export const getUsersProjects = async (token) => {
	try {
		const response = await fetch(`${BASE_URL}/Proyects`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Project obtaining problem");
	}
};

export const getUsersPlatforms = async (token) => {
	try {
		const response = await fetch(`${BASE_URL}/Platforms`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Platform obtaining problem");
	}
};

export const getUsersDataClient = async (token, options) => {
	try {
		const response = await fetch(`${BASE_URL}/DataClients/Clients/${options}`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with customer data");
	}
};

export const getUsersDataClientProjects = async (token, options) => {
	try {
		const response = await fetch(`${BASE_URL}/DataClients/proyects/${options}`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem data obtaining problem");
	}
};

export const getUsersDataClientPlatforms = async (token, options) => {
	try {
		const response = await fetch(`${BASE_URL}/DataClients/platforms/${options}`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with the obtaining platform data");
	}
};

export const getUsersClientsProjects = async (token, idclient) => {
	try {
		const response = await fetch(`${BASE_URL}/Clients/${idclient}/Proyects`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Project problem in customers");
	}
};

export const getUsersClientsPlatforms = async (token, idclient) => {
	try {
		const response = await fetch(`${BASE_URL}/Clients/${idclient}/Platforms`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with the obtaining of platforms in customers");
	}
};

export const getUsersRoles = async (token) => {
	try {
		const response = await fetch(`${BASE_URL}/Roles`, {
			method: "GET",
			headers: { "x-access-token": token },
		});
		return response.json();
	} catch (error) {
		console.error("Problem with the obtaining of roles");
	}
};

export const postUsersAdd = async (body, table, token) => {
	try {
		const response = await fetch(`${BASE_URL}/signup/${table}`, {
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		});
		return response.json();
	} catch (error) {
		console.error("User registration problem");
	}
};

export const putUsersUpdate = async (infoUser, idUser, tableData, token) => {
	try {
		const response = await fetch(`${BASE_URL}/Users/${tableData}/${idUser}`, {
			method: "PUT",
			body: JSON.stringify(infoUser),
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		});
		return response.json();
	} catch (error) {
		console.error("User registration problem");
	}
};

export const getInformation = async (token, table) => {
	const res = await fetch(`${BASE_URL}/Users/${table}`, {
		method: "GET",
		headers: { "x-access-token": token },
	});
	return await res.json();
};

export const postClients = async (body, token) => {
	try {
		const response = await fetch(`${BASE_URL}/Clients`, {
			method: "POST",
			body: JSON.stringify(body),
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		});
		return response.json();
	} catch (error) {
		console.error("Customer creation problem");
	}
};

export const postProjects = async (data, token) => {
	try {
		const response = await fetch(`${BASE_URL}/Proyects`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		});
		return response.json();
	} catch (error) {
		console.error("Project creation problems");
	}
};

export const postPlatforms = async (data, token) => {
	try {
		const response = await fetch(`${BASE_URL}/Platforms`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		});
		return response.json();
	} catch (error) {
		console.error("Problem with the creation of the platforms");
	}
};

export const postRoles = async (data, token) => {
	try {
		const response = await fetch(`${BASE_URL}/Roles`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		});
		return response.json();
	} catch (error) {
		console.error("Problem with the creation of the platforms");
	}
};

export const getFilterUser = async (user) => {
	try {
		const reponse = await fetch(`${URL_FILTER}/userregistry/${user}`, {
			method: "GET",
			headers: {
				"x-api-key": TOKEN_FILTER,
			},
		});
		return reponse.json();
	} catch (error) {
		console.error("Problem in data tuning");
	}
};

export const postFilterUser = async (dataJson) => {
	try {
		const user = dataJson.user;
		const data = dataJson.data;

		const response = await fetch(`${URL_FILTER}/userregistry/${user}`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				"x-api-key": TOKEN_FILTER,
			},
		});
		return response.json();
	} catch (error) {
		console.error("Problem with the creation of the platforms");
	}
};

export const putFilterUpdate = async (dataJson) => {
	try {
		const user = dataJson.user;
		const data = dataJson.data;

		const reponse = await fetch(`${URL_FILTER}/userregistry/${user}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": TOKEN_FILTER,
			},
			body: JSON.stringify(data),
		});
		return reponse.json();
	} catch (error) {
		console.error("Problem update data filter");
	}
};
