import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Modal, Select, Steps, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Sidebar } from "../../../components/layout/Sidebar";
import { Navbar } from "../../../components/layout/Navbar";
import { AuthContext } from "../../../auth/context/AuthContext";
import { utils, writeFile } from 'xlsx';
import { ChartInflowOutflow } from '../../../components/GasLift/GraphicsFlow/ChartInflowOutflow'
import { TableInflowOutFlow } from "../../../components/GasLift/TableFlow/TableInflowOutFlow";
import { TablePerformanceAnalysis } from "../../../components/GasLift/TableFlow/TablePerformanceAnalysis";
import { useNavigate } from "react-router-dom";
import { useTabFlow } from "../../../hooks/useTabFlow";
import { useTabGaslift } from "../../../hooks/useTabGaslift";
import { AlertModal } from "../../../components/AlertModal";
import { ReactComponent as TableIcon } from "../../../assets/icon/table.svg";
import { NAME_PAGES } from "../../../components/elements/types/type_pages";
import { STATUS_CODE_FLOW, STATUS_CODE_FLOW_GRAPH, STATUS_CODE_WELL_GASLIFT, STATUS_TYPE_CALCULATE_IPR } from "../../../components/elements/types/type_response_API";
import { INITIAL_DATA_INPUT, INITIAL_DATA_TABLE_INOUTFLOW, INITIAL_DATA_TABLE_PERFLOW, VALUE_SELECT_PARAMETERS } from "../../../components/elements/types/type_flow";

const steps = [
  {
    title: "Well",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
];

export const StepMenuEditInOutFlow = () => {
  const navigate = useNavigate();
  const { User } = useContext(AuthContext);
  const {
    dataWellGasLift,
    mutationUpdateDataGasLift,
    dataFilterUserGasLift,
    userDataInfo,
    refetchDataWellGasLift,
    calculateDomain,
    listqIPR,
    refetchListqIPR
  } = useTabGaslift();

  const {
    queryDataFlow,
    refetchDataFlow,
    queryDataFlowGraph,
    refetchDataFlowGraph,
    mutationCreateFlow,
    flagGraphDomain,
    setFlagGraphDomain
  } = useTabFlow();

  const { Option } = Select;
  const [status, setStatus] = useState(false);
  const [current, setCurrent] = useState(0);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [inputDataFlow, setInputDataFlow] = useState(INITIAL_DATA_INPUT);
  const [openTable, setOpenTable] = useState([false, false]);
  const [dataInformationEnd, setDataInformationEnd] = useState();
  const [dataTableInOutFlow, setDataTableInOutFlow] = useState([]);
  const [dataTablePerFlow, setDataTablePerFlow] = useState(INITIAL_DATA_TABLE_PERFLOW);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [dataTableFlow, setDataTableFlow] = useState([]);
  const [limitsIPR, setLimitIPR] = useState([0, 0]);
  const [uniqueInjection, setUniqueInjection] = useState();

  useEffect(() => {
    refetchDataWellGasLift();
    refetchListqIPR();
  }, []);

  useEffect(() => {
    if (listqIPR) {
      if (listqIPR.statusType === STATUS_TYPE_CALCULATE_IPR.SUCCESS) {
        let globalMax = -Infinity;
        let globalMin = Infinity;

        const qoKeys = Object.keys(listqIPR.data.qo);
        qoKeys.forEach(key => {
          const values = listqIPR.data.qo[key];
          const max = Math.max(...values);
          const min = Math.min(...values);
          if (max > globalMax) globalMax = max;
          if (min < globalMin) globalMin = min;
        });

        const arrayLimits = [...limitsIPR]
        arrayLimits[0] = globalMin
        arrayLimits[1] = globalMax
        setLimitIPR(arrayLimits);
      }
    }
  }, [listqIPR]);

  useEffect(() => {
    if (queryDataFlow) {
      if (queryDataFlow.statusCode === STATUS_CODE_FLOW.SUCCESS) {
        const dataFlowProcess = queryDataFlow?.data?.inflow_outflow;
        setDataTableInOutFlow(dataFlowProcess);

        const qgValues = dataFlowProcess?.map(item => item.qg_inj_sc);
        const uniqueQgValues = [...new Set(qgValues)];
        const roundedValues = uniqueQgValues.map(value => Math.round(value))
        setUniqueInjection(roundedValues);
      }
    }
  }, [queryDataFlow]);

  useEffect(() => {
    if (queryDataFlowGraph) {
      if (queryDataFlowGraph.statusCode === STATUS_CODE_FLOW_GRAPH.SUCCESS) {
        const dataFlowProcess = queryDataFlowGraph?.data?.formatted_data;
        setDataTableFlow(dataFlowProcess);

        const filteredDataDomain = dataFlowProcess.map(obj => {
          const filteredObj = {};
          Object.entries(obj).forEach(([key, value]) => {
            if (key.includes("press") || key === "ipr_interpolate") {
              filteredObj[key] = value;
            }
          });
          return filteredObj;
        });

        const dataMaxChart = calculateDomain(filteredDataDomain, 'max');
        const dataMinChart = calculateDomain(filteredDataDomain, 'min');
        setInputDataFlow(prevState => ({
          ...prevState,
          minChartFlow: dataMinChart,
          maxChartFlow: dataMaxChart,
        }));
        setFlagGraphDomain(false);
      }
    }
  }, [queryDataFlowGraph, flagGraphDomain]);

  useEffect(() => {
    if (dataWellGasLift) {
      if (dataWellGasLift.statusCode === STATUS_CODE_WELL_GASLIFT.SUCCESS) {
        const filterDataCreated = dataWellGasLift.data;
        const filterDataEnd = Object.assign({}, inputDataFlow, { table_inflow_outflow: [] }, { table_performance_flow: [] });
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreated).filter(([key]) => !(key in filterDataEnd))
        );
        setDataInformationEnd(filteredJson);
        setInputDataFlow({
          oil_flow_rate_min: filterDataCreated.oil_flow_rate_min,
          oil_flow_rate_max: filterDataCreated.oil_flow_rate_max,
          number_rates_flow: filterDataCreated.number_rates_flow,
          check_import_model_ipr: filterDataCreated.check_import_model_ipr,
          select_parameters: filterDataCreated.select_parameters,
          min_gor_flow: filterDataCreated.min_gor_flow,
          max_gor_flow: filterDataCreated.max_gor_flow,
          number_cases_gor_flow: filterDataCreated.number_cases_gor_flow,
          min_wt_flow: filterDataCreated.min_wt_flow,
          max_wt_flow: filterDataCreated.max_wt_flow,
          number_cases_wt_flow: filterDataCreated.number_cases_wt_flow,
          min_inj_flow: filterDataCreated.min_inj_flow,
          max_inj_flow: filterDataCreated.max_inj_flow,
          number_cases_inj_flow: filterDataCreated.number_cases_inj_flow,
          minChartFlow: filterDataCreated.minChartFlow,
          maxChartFlow: filterDataCreated.maxChartFlow
        });
      }
    }
  }, [dataWellGasLift]);

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleClickNext = () => {
    if (current === 0) {
      if (inputDataFlow.oil_flow_rate_max === "" || inputDataFlow.oil_flow_rate_min === "" || inputDataFlow.number_rates_flow === "" || inputDataFlow.check_import_model_ipr === false || inputDataFlow.number_rates_flow < 3) {
        const messageAlert = inputDataFlow.number_rates_flow < 3 ? 'The value of Number Rate is not within the permitted range' : 'There are empty fields please validate that the information is complete';
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(messageAlert);
        setInputEmpty(true);
        handleStatusChange();
      } else {
        setCurrent(current + 1);
      }
    }
    if (current === 1) {
      switch (inputDataFlow.select_parameters) {
        case "lift_gas_injection_rate":
          if (inputDataFlow.max_inj_flow === "" || inputDataFlow.min_inj_flow === "" || inputDataFlow.number_cases_inj_flow === "" || inputDataFlow.number_cases_inj_flow < 2) {
            const messageAlert = inputDataFlow.number_rates_flow < 3 ? 'The value of Number Cases is not within the permitted range' : 'There are empty fields please validate that the information is complete';
            handleOpenModal();
            setTypeAlert("Warning");
            setMessageAlert(messageAlert);
            setInputEmpty(true);
            handleStatusChange();
          } else {
            setCurrent(current + 1);
          }
          break;

        case VALUE_SELECT_PARAMETERS.water_cut:
          if (inputDataFlow.max_wt_flow === "" || inputDataFlow.min_wt_flow === "" || inputDataFlow.number_cases_wt_flow === "") {
            handleOpenModal();
            setTypeAlert("Warning");
            setMessageAlert('There are empty fields please validate that the information is complete')
            setInputEmpty(true);
            handleStatusChange();
          } else {
            setCurrent(current + 1);
          }
          break;

        case "formation_gor":
          if (inputDataFlow.max_gor_flow === "" || inputDataFlow.min_gor_flow === "" || inputDataFlow.number_cases_gor_flow === "") {
            handleOpenModal();
            setTypeAlert("Warning");
            setMessageAlert('There are empty fields please validate that the information is complete')
            setInputEmpty(true);
            handleStatusChange();
          } else {
            setCurrent(current + 1);
          }
          break;

        default:
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert('There are empty fields please validate that the information is complete')
          setInputEmpty(true);
          handleStatusChange();
          break;
      }
    }
  };

  const handleClickDone = () => {
    const dataCreateFlow = Object.assign({}, inputDataFlow, { table_inflow_outflow: dataTableInOutFlow }, { table_performance_flow: dataTablePerFlow }, dataInformationEnd);
    setTimeout(() => {
      mutationUpdateDataGasLift.mutate({ 
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field, 
        well: dataFilterUserGasLift.well_id, 
        username: User.user_name, 
        data: dataCreateFlow 
      })
    }, 100);
    navigate(`/${NAME_PAGES.gas_lift.list_well}`);
  }

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };

  const handleChangeCheck = (valueCheck, type) => {
    const customEvent = {
      target: {
        name: type,
        value: valueCheck,
      },
    };
    handleChangeInputData(customEvent);
  }

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInputData(customEvent);
  }

  const handleChangeInputData = (e) => {
    const { name, value } = e.target;
    let dataValue = value;

    if (name !== "select_parameters" && name !== "check_import_model_ipr") {
      dataValue = Number(value);
    }

    if (value === "") {
      setInputDataFlow(prevState => ({
        ...prevState,
        [name]: ""
      }));
    } else {
      if ((name === 'oil_flow_rate_max' || name === 'oil_flow_rate_min') && (dataValue < limitsIPR[0] || dataValue > limitsIPR[1])) {
        message.error("The value entered is not within the IPR ranges");
      }
      else if ((name === 'number_rates_flow') && (dataValue < 0 || dataValue > 12)) {
        message.error("The value cannot be less than 3 or more than 12");
      }
      else if ((name === 'min_inj_flow' || name === 'max_inj_flow') && (dataValue < 0 || dataValue > 90000)) {
        message.error("The value cannot be less than 0 or more than 90000");
      }
      else if ((name === 'number_cases_inj_flow') && (dataValue < 0 || dataValue > 9)) {
        message.error("The value cannot be less than 2 or more than 9");
      }
      else if ((name === 'min_wt_flow' || name === 'max_wt_flow') && (dataValue < 0 || dataValue > 100)) {
        message.error("The value cannot be less than 0 or more than 100");
      }
      else if ((name === 'number_cases_wt_flow') && (dataValue < 2 || dataValue > 5)) {
        message.error("The value cannot be less than 2 or more than 5");
      }
      else if ((name === 'min_gor_flow' || name === 'max_gor_flow') && (dataValue < 0 || dataValue > 60000)) {
        message.error("The value cannot be less than 0 or more than 60000");
      }
      else if ((name === 'number_cases_gor_flow') && (dataValue < 2 || dataValue > 5)) {
        message.error("The value cannot be less than 2 or more than 5");
      }
      else {
        setInputDataFlow(prevState => ({
          ...prevState,
          [name]: dataValue
        }));
      }
    }
  }

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleClickRun = () => {
    const dataFlowEnd = {
      especific_qo: dataInformationEnd.ipr_model === "composite" ? "qo_pet" : dataInformationEnd.ipr_model,
      sensibility: inputDataFlow.select_parameters,
      qo_min: inputDataFlow.oil_flow_rate_min,
      qo_max: inputDataFlow.oil_flow_rate_max,
      qo_num_rates: inputDataFlow.number_rates_flow,
      var_min: inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate ? inputDataFlow.min_inj_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut ? inputDataFlow.min_wt_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor ? inputDataFlow.min_gor_flow : "",
      var_max: inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate ? inputDataFlow.max_inj_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut ? inputDataFlow.max_wt_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor ? inputDataFlow.max_gor_flow : "",
      var_num_points: inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate ? inputDataFlow.number_cases_inj_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut ? inputDataFlow.number_cases_wt_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor ? inputDataFlow.number_cases_gor_flow : ""
    }

    setFlagGraphDomain(true);

    async function executeFunction() {

      await mutationCreateFlow.mutateAsync({
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field, 
        well: dataFilterUserGasLift.well_id, 
        username: User.user_name, 
        data: dataFlowEnd 
      });
      
      await new Promise(resolve => setTimeout(resolve, 500));

      const promises = [refetchDataFlow(), refetchDataFlowGraph()];
      
      await Promise.all(promises);
    }
    executeFunction();
  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const onChange = (value) => {
    setCurrent(value);
  };

  const toggleModal = (id, target) => {
    setOpenTable((p) => {
      p[id] = target;
      return [...p];
    });
  };

  const downloadTable = (id) => {

    if(Object.keys(dataTableInOutFlow ? dataTableInOutFlow : []).length){
      let nameFile = "";
      let data = [];
      if (id === 0) {
        nameFile = "Table_Inflow_OutFlow_Curve";
        data = dataTableInOutFlow;
      }
      else if (id === 1) {
        nameFile = "Table_Performance_Flow";
        data = dataTablePerFlow;
      }
  
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(data);
      utils.book_append_sheet(wb, ws, 'Sheet1');
      writeFile(wb, `${nameFile}.xlsx`);
    }else {
      message.info("Please click on Run to view the data in the table and download it")
    }
  };
  
  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full "></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal 
          showAlert={showAlert} 
          handleCloseModal={handleCloseModal} 
          messageAlert={messageAlert} 
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <Modal
        title="Table Inflow / Outflow"
        open={openTable[0]}
        onOk={() => downloadTable(0)}
        onCancel={() => toggleModal(0, false)}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        width={900}
        okText="Download"
        centered
        align={{
          center: true,
          middle: true,
        }}
      >
        <TableInflowOutFlow dataTable={dataTableInOutFlow}></TableInflowOutFlow>
      </Modal>
      <Modal
        title="Table Performance Analysis"
        open={openTable[1]}
        onOk={() => downloadTable(1)}
        onCancel={() => toggleModal(1, false)}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        okText="Download"
        centered
        align={{
          center: true,
          middle: true,
        }}
      >
        <TablePerformanceAnalysis dataTable={dataTablePerFlow}></TablePerformanceAnalysis>
      </Modal>
      <div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
        <Steps
          size="default"
          current={current}
          onChange={onChange}
          className="site-navigation-steps flex w-[600px] custom-steps"
          items={[
            {
              title: "Inflow",
              disabled: { status },
            },
            {
              title: "Outflow",
              disabled: { status },
            },
            {
              title: "Graph",
              disabled: { status },
            },
          ]}
        />
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center">
        {current === 0 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center pl-[120px] mb-[20px]">
              <div className="flex flex-row w-full h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Inflow Performance</h1>
              </div>
            </div>
            <div className="flex flex-col w-full h-[600px] pl-[120px] pr-[120px]">
              <div className="flex flex-row text-left gap-5 mb-[10px]">
                <div className="flex flex-col">
                  <label htmlFor="" className="flex text-[14px] mb-[7px]">
                    Oil Flow Rate Minimum (STB/D)
                    <Tooltip title="This value must be within the IPR ranges">
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                  </label>
                  <input
                    type="number"
                    name="oil_flow_rate_min"
                    value={inputDataFlow.oil_flow_rate_min}
                    onChange={handleChangeInputData}
                    min="0"
                    max="60000"
                    className={`flex w-[540px] h-[47px] rounded-[4px] px-3 border ${inputEmpty && inputDataFlow.oil_flow_rate_min === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                  />

                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="flex text-[14px] mb-[7px]">
                    Oil Flow Rate Maximum (STB/D)
                    <Tooltip title="This value must be within the IPR ranges">
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                  </label>
                  <input
                    type="number"
                    name="oil_flow_rate_max"
                    value={inputDataFlow.oil_flow_rate_max}
                    onChange={handleChangeInputData}
                    min="0"
                    max="60000"
                    className={`flex w-[540px] h-[47px] rounded-[4px] px-3 border ${inputEmpty && inputDataFlow.oil_flow_rate_max === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                  />

                </div>
                <div className="flex flex-col">
                  <label htmlFor="" className="flex text-[14px] mb-[7px]">Number of Rates</label>
                  <input
                    type="number"
                    name="number_rates_flow"
                    value={inputDataFlow.number_rates_flow}
                    onChange={handleChangeInputData}
                    min="3"
                    max="12"
                    className={`flex w-[540px] h-[47px] rounded-[4px] px-3 border ${inputEmpty && inputDataFlow.number_rates_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full max-w-[592px] h-[47px] text-left items-start justify-start rounded-[4px] mb-[10px]">
                <label htmlFor="" className="flex w-full text-[14px] mb-[7px]">Reservoir Parameters</label>
                <Checkbox
                  checked={inputDataFlow.check_import_model_ipr}
                  onChange={(e) => handleChangeCheck(e.target.checked, "check_import_model_ipr")}
                  className={`my-checkbox text-[14px] ${inputEmpty && !inputDataFlow.check_import_model_ipr ? "custom-checkbox-alert text-[#b91c1c]" : ""}`}
                >
                  Import Current IPR Model
                </Checkbox>
              </div>
            </div>
          </>
        }
        {current === 1 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Outflow Performance</h1>
              </div>
            </div>
            <div className="flex flex-col w-full h-[600px] pl-[120px] pr-[120px]">
              <div className="flex w-full mb-[10px]">
                <h2 className="flex flex-row font-bold text-[16px]">Reservoir Parameters</h2>
              </div>
              <div className="flex flex-row w-full gap-5 mb-[10px]">
                <div className="flex flex-col w-1/4 text-left">
                  <label htmlFor="" className="flex text-[14px] mb-[7px]">Parameters</label>
                  <Select
                    className={`flex w-full h-[47px] text-start`}
                    name="select_parameters"
                    value={inputDataFlow.select_parameters}
                    onChange={(value) => handleChangeSelect("select_parameters", value)}
                    placeholder="Selected option"
                  >
                    <Option value={VALUE_SELECT_PARAMETERS.lift_gas_injection_rate}>Lift Gas Injection Rate</Option>
                    <Option value={VALUE_SELECT_PARAMETERS.water_cut}>Water Cut</Option>
                    <Option value={VALUE_SELECT_PARAMETERS.formation_gor}>Formation GOR</Option>
                  </Select>
                </div>
                {inputDataFlow.select_parameters !== "" &&
                  <>
                    <div className="flex flex-col w-1/4 text-left">
                      <label htmlFor="" className="flex text-[14px] mb-[7px]">Minimum {inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                        ? "(MSCF/D)" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                          ? "(%)" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor
                            ? "(SCF/STB)" : ""}</label>
                      <input
                        type="number"
                        name={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? "max_inj_flow" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? "max_wt_flow" : "max_gor_flow"
                        }
                        value={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? inputDataFlow.max_inj_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? inputDataFlow.max_wt_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor
                              ? inputDataFlow.max_gor_flow : null
                        }
                        onChange={handleChangeInputData}
                        min="0"
                        max={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? "90000" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? "100" : "60000"}
                        className={`flex w-full h-[47px] rounded-[4px] px-3  border 
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate && inputDataFlow.max_inj_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut && inputDataFlow.max_wt_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor && inputDataFlow.max_gor_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`
                        }
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-left">
                      <label htmlFor="" className="flex text-[14px] mb-[7px]">
                        Maximum {inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                        ? "(MSCF/D)" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                          ? "(%)" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor
                            ? "(SCF/STB)" : ""
                        }
                      </label>
                      <input
                        type="number"
                        name={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? "min_inj_flow" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? "min_wt_flow" : "min_gor_flow"
                        }
                        value={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? inputDataFlow.min_inj_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? inputDataFlow.min_wt_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor
                              ? inputDataFlow.min_gor_flow : null
                        }
                        onChange={handleChangeInputData}
                        min="0"
                        max={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? "90000" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? "100" : "60000"
                        }
                        className={`flex w-full h-[47px] rounded-[4px] px-3  border 
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate && inputDataFlow.min_inj_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut && inputDataFlow.min_wt_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor && inputDataFlow.min_gor_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`
                        }
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-left">
                      <label htmlFor="" className="flex text-[14px] mb-[7px]">Number of cases</label>
                      <input
                        type="number"
                        name={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? "number_cases_inj_flow" : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? "number_cases_wt_flow" : "number_cases_gor_flow"
                        }
                        value={inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate
                          ? inputDataFlow.number_cases_inj_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut
                            ? inputDataFlow.number_cases_wt_flow : inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor
                              ? inputDataFlow.number_cases_gor_flow : null
                        }
                        onChange={handleChangeInputData}
                        min="2"
                        max="5"
                        className={`flex w-full h-[47px] rounded-[4px] px-3  border 
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.lift_gas_injection_rate && inputDataFlow.number_cases_inj_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.water_cut && inputDataFlow.number_cases_wt_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}
                          ${inputEmpty && inputDataFlow.select_parameters === VALUE_SELECT_PARAMETERS.formation_gor && inputDataFlow.number_cases_gor_flow === '' ? ' border-red-700 bg-yellow-100' : 'border-solid border-[#BCBCCC]'}`
                        }
                      />
                    </div>
                  </>
                }
              </div>
            </div>
          </>
        }
        {current === 2 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Graph</h1>
              </div>
            </div>
            <div className="flex flex-row w-full h-[600px]">
              <div className="flex flex-col w-full h-full ">
                <div className="flex flex-row  w-full text-left justify-start gap-5 p-3">
                  <h2 className="flex h-[47px] flex-row font-bold text-[16x]">Inflow / Outflow curve for Well: {dataFilterUserGasLift.well_id}</h2>
                  <TableIcon className="flex w-6 h-6 fill-black hover:stroke-pa-purple" onClick={() => toggleModal(0, true)} />
                </div>
                <div className="flex flex-row w-full h-full">
                  <div className="flex flex-col h-full">
                    <div className="flex h-full items-start justify-start">
                      <label htmlFor="" className="flex h-[47px] text-center justify-center items-center mr-2">Max</label>
                      <input type="text" className="flex w-[70px] h-[47px] rounded-[4px] px-3 border border-solid border-[#BCBCCC]"
                        name="maxChartFlow"
                        onChange={handleChangeInputData}
                        value={inputDataFlow.maxChartFlow}
                      />
                    </div>
                    <div className="flex h-full items-end justify-end pb-[70px]">
                      <label htmlFor="" className="flex h-[47px] text-center justify-center items-center mr-2">Min</label>
                      <input type="text" className="flex w-[70px] h-[47px] rounded-[4px] px-3 border border-solid border-[#BCBCCC]"
                        name="minChartFlow"
                        onChange={handleChangeInputData}
                        value={inputDataFlow.minChartFlow}
                      />
                    </div>
                  </div>
                  <div className="flex w-full h-full">
                    <ChartInflowOutflow 
                      dataTableGraphFlow={dataTableFlow} 
                      limits={inputDataFlow} 
                      uniqueQJ={uniqueInjection} 
                      dashboard={false}
                    ></ChartInflowOutflow>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full text-center items-center justify-center">
              <button
                className="w-[180px] h-[46px] bg-white text-pa-purple rounded-md border border-pa-purple hover:text-white hover:bg-pa-purple "
                onClick={() => handleClickRun()}
              >
                Run
              </button>

            </div>
          </>
        }
        <div className="flex w-full text-center justify-center items-center mb-[90px]">
          <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
            {current > 0 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickPrev()}
              >
                Previous
              </button>
            )}
            {current === steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickDone()}
              >
                Done
              </button>
            )}
            {current < steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickNext()}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
