// ExamplePieChart.jsx
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { COLORS_PIE_STATUS } from '../../../elements/types/type_screening_tool';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, wells } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text 
        x={ex + (cos >= 0 ? 1 : -1) * 12} 
        y={ey} 
        textAnchor={textAnchor} 
        fill="#333"
        fontSize="15px"
      >
        {`Wells ${wells}`}
      </text>
      <text 
        x={ex + (cos >= 0 ? 1 : -1) * 12} 
        y={ey} 
        dy={18} 
        textAnchor={textAnchor} 
        fill="#999"
        fontSize="12px"
      >
        {`(Percentage ${(percent * 100)?.toFixed(0)}%)`}
      </text>
    </g>
  );
};

export const ChartPieStatus = ({ dataDonut, dataDonutDepth, status }) => {
  
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [valueDonutTransform, setValueDonutTransform] = useState([]);

  useEffect(() => {
    if((!!dataDonut && !!dataDonutDepth)){
      if(status === "Donut"){
        const valueTransform = transformData(dataDonut[0]?.donut_rank);
        setValueDonutTransform(valueTransform);
      }else if(status === "DonutDepth"){
        const dataDonutDepthTransform = transformData(dataDonutDepth[0]?.donut_rank_without_depth);
        setValueDonutTransform(dataDonutDepthTransform);
      }
    }else{
      setValueDonutTransform([]);
    }
  }, [dataDonut, dataDonutDepth, status]);
  
  const handlePieEnter = (_, index) => {
    setActiveIndex(index);
  };

  function transformData(data) {
    if(!!data){
      const valueTransform = Object.entries(data)?.map(([key, value], index) => {
        return {
          name: `Cluster ${key}`,
          value: value.percentage,
          wells: value.count,
          fill: COLORS_PIE_STATUS[index % COLORS_PIE_STATUS.length],
        };
      });
      return valueTransform;
    }else{
      return [];
    }
  }
  
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={400} height={250}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={valueDonutTransform}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={90}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={handlePieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};