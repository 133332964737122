import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { read, utils } from "xlsx";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message } from "antd";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";

const { confirm } = Modal;

export const ImportFileHistory = ({ uploadfile }) => {
  
  const fileInputRef = useRef(null);
  const { selectedWell, userDataInfo } = useContext(DashboardContext);
  
  const handleImport = (event) => {
      const files = event.target.files;
      confirm({
        title: "Do you want to upload production history data?",
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          if (files) {
            const file = files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
              const wb = read(e.target.result);
              const sheetName = wb.SheetNames[0];
              const sheets = wb.Sheets[sheetName];
              if (sheets) {
                const jsonData = utils.sheet_to_json(sheets);
                uploadfile(jsonData);
                message.success("Data loaded successfully");
              }
            };
            reader.readAsArrayBuffer(file);
            event.target.value = '';
          }
        },
        onCancel() {
          console.log("Cancel");
          event.target.value = '';
        },
      });
  };
  const handleImageClick = () => {

    if (!!selectedWell && selectedWell !== "null") {
        fileInputRef.current.click();
    }else{
        message.warning( !userDataInfo?.lease || !userDataInfo?.operator || !userDataInfo?.region  ? "Please select a filter in the navigation bar" : !selectedWell || selectedWell === "null" ? "Please select a well first" : "");
      }
  };
  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        name="file"
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: "none" }}
      />
      <label htmlFor="fileInput">
        <button
          className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
          onClick={handleImageClick}
        >
          Add Historical data
        </button>
      </label>
    </div>
  );
};
