import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Checkbox, message, Modal, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorLoadingData } from "../../components/ErrorPage";
import { ImportFileHistory } from "../../components/GasLift/Complements/ImportFileHistory";
import { DATA_ALERTS } from "../../components/elements/types/type_dashboard";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabTuning } from "../../hooks/useTabTuning";
import { useFilters } from "../../hooks/useFilters";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { REQUIRED_PROPIERTIES, NAME_ACTION_BUTTON } from "../../components/elements/types/type_gas_lift";
import { 
  STATUS_CODE_WELL_GASLIFT, 
  STATUS_DATA_HISTORY_GAS_LIFT, 
  STATUS_DATA_VLP, 
  STATUS_LIST_GAS_LIFT 
} from "../../components/elements/types/type_response_API";

const emptyDataFluid= {
  res_pressure: "",
  res_temperature: "",
  res_initial_gor: "",
  res_oil_gravity: "",
  res_gas_gravity: "",
  res_H2S: "",
  res_CO2: "",
  res_N2: "",
  res_water_salinity: "",
  sep_pressure: "",
  sep_temperature: "",
  sep_oil_gravity: "",
  sep_gas_gravity: "",
  sep_solution_gor: "",
  sep_oil_volume_factor: "",
  fluidLab:false,
  temperature_lab: "",
  solution_gor_pb: "",
  bubble_point_pressure_lab: "",
  oil_fvf: "",
  table_fluid_lab:[],
  option_data_point: "",
  calculate_correlation: "",
  calculate_correlation_oil: "",
  bubble_point_pressure:"",
  data_point_table:[],
  fluidLab:false,
};

const emptyDataReservoir = {
  layer_pressure: "",
  layer_temperature: "",
  md_perf_depth: "",
  water_cut_fraction: "",
  state_reservoir: "",
  ipr_model: "",
  test_pressure_1_s: "",
  test_flow_rate_1_s: "",
  test_pressure_2_s: "",
  test_flow_rate_2_s: "",
  productivity_index_j_s: "",
  test_pressure_1_us: "",
  test_flow_rate_1_us: "",
  test_pressure_2_us: "",
  test_flow_rate_2_us: "",
  productivity_index_j_us: "",
  check_phases: false,
  pwf_ipr: false,
  straight_line: false
};

const emptyDataFlow = {
  oil_flow_rate_min : "",
  oil_flow_rate_max : "",
  number_rates_flow : "",
  check_import_model_ipr : false,
  select_parameters: "",
  min_gor_flow : "",
  max_gor_flow : "",
  number_cases_gor_flow : "",
  min_wt_flow : "",
  max_wt_flow : "",
  number_cases_wt_flow : "",
  min_inj_flow : "",
  max_inj_flow : "",
  number_cases_inj_flow : "",
  table_inflow_outflow: [],
  table_performance_flow : []
};

const initialAlertIcon = {
  edit: {
    pvt: false,
    reservoir: false,
    vlp: false,
    inflow_outflow: false,
  }
}

export const AddWellGasLift = () => {
  const Navigate = useNavigate();
  const { confirm } = Modal;
  const { dataFilter, mutationUpdateFilter } = useFilters();
  const { 
    selectedWell,
    getSelectedWell, 
    getDataPage, 
    getDataAlertGasLift, 
    someAlert, 
    getSomeAlert
  } = useContext(DashboardContext);
  const {
    User,
    userDataInfo,
    dataFilterUserGasLift,
    getDataFilterUserGaslift,
    listWellsGasLift,
    listDataTuning,
    dataWellGasLift,
    queryDataHistory,
    queryListDataHistory,
    mutationDeleteDataGasLift,
    mutationUpdateDataGasLift,
    mutationCreateDataHistory,
    mutationDeleteDataHistory,
    mutationUpdateDataHistory,
    mutationDeleteScenariosReservoir,
    refetchDataWellGasLift,
    refetchListWellsGasLift,
    refetchDataTuning,
    refetchListDataHistory,
    refetchDataHistory
  } = useTabGaslift();
  const { 
    mutationDeleteDataTuning, 
    refetchTuning 
  } = useTabTuning();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [data, setData] = useState([]);
  const [dataInformationEndFluid, setDataInformationEndFluid] = useState();
  const [dataInformationEndReservoir, setDataInformationEndReservoir] = useState();
  const [dataInformationEndFlow, setDataInformationEndFlow] = useState();
  const [listTuning, setListTuning] = useState([]);
  const [listDataHistory, setListDataHistory] = useState([]);
  const [arrayDataHistory, setArrayDataHistory] = useState(null);
  const [valuesDataHistory, setValuesDataHistory] = useState();
  const [alertIcon, setAlertIcon] = useState(initialAlertIcon);

  useEffect(() => {
    getDataPage(NAME_PAGES.gas_lift.list_well);
    refetchDataTuning();
    refetchListWellsGasLift();
    refetchListDataHistory();
  }, []);

  useEffect(() => {
    if (selectedWell !== null) {
      const dataWellFind = data.find((item) => item.well_id === selectedWell);
      const dataFilterNew = {
        county: dataWellFind?.county,
        field: dataWellFind?.field,
        well_id: dataWellFind?.well_id,
        well_platforms: dataWellFind?.well_platforms,
        well_name: dataWellFind?.well_name,
        fluid_type: dataWellFind?.fluid_type
      };
      if (data.length > 0) {
        getDataFilterUserGaslift(dataFilterNew);
      }
    }
    else{
      getDataFilterUserGaslift(null);
    }

  }, [selectedWell,data]);

  useEffect(() => {
      if (queryListDataHistory?.statusType === STATUS_DATA_HISTORY_GAS_LIFT.SUCCESS && queryListDataHistory?.data) {
        setListDataHistory(queryListDataHistory?.data);
      }else{
        setListDataHistory([]);
      }

      if (listDataTuning?.statusCode === STATUS_DATA_VLP.SUCCESS && listDataTuning?.data) {
        setListTuning(listDataTuning?.data)
      }else{
        setListTuning([]);
      }
  }, [listDataTuning, queryListDataHistory]);
  
  useEffect(() => {
    if (selectedWell !== null) {
      refetchDataWellGasLift();
      refetchDataHistory();
      refetchTuning();
    }
  }, [selectedWell]);

  useEffect(() => {
    if (dataWellGasLift) {
      if (dataWellGasLift.statusCode === STATUS_CODE_WELL_GASLIFT.SUCCESS && dataWellGasLift.data) {
        const { data: filterDataCreate } = dataWellGasLift;

        setDataInformationEndFluid(filterKeysNotIn(filterDataCreate, emptyDataFluid));
        setDataInformationEndReservoir(filterKeysNotIn(filterDataCreate, emptyDataReservoir));
        setDataInformationEndFlow(filterKeysNotIn(filterDataCreate, emptyDataFlow));

        if(queryDataHistory?.statusType === STATUS_DATA_HISTORY_GAS_LIFT.SUCCESS){
          setArrayDataHistory(queryDataHistory?.data?.data);
          const dataHistory = arrayDataHistory?.map(obj => obj['id']);
          setValuesDataHistory(dataHistory);
        }
        
        function filterKeysNotIn (source, template){
          const dataFilter = Object.fromEntries(
            Object.entries(source).filter(([key]) => !(key in template))
          );          
          return dataFilter;
        }
      }
    }
  }, [dataWellGasLift]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchDataTuning();
      refetchListWellsGasLift();
      refetchListDataHistory();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [refetchListWellsGasLift]);

  useEffect(() => {
    setTimeout(() => {
      if (listWellsGasLift) {
        if (listWellsGasLift.statusType != "ERROR") {
          setData(listWellsGasLift.data)
        }
      }
    }, 500);
  }, [listWellsGasLift]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleClickButtonCreate = (idButton) => {

    setAlertIcon(initialAlertIcon);

    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWell
    );

    const wellConfig = data[selectedWellIndex]?.wellConfig || [];
    const tuningExists = listTuning?.some((tuningItem) => tuningItem.well_id === selectedWell);
    const fluidType = dataFilterUserGasLift?.fluid_type;
    const messageExist = "information already exists for this well, please click on edit button";

    const infoButtonCreate = (message) => {
      Modal.info({
        title: "Warning",
        icon: <ExclamationCircleFilled/>,
        okText: "OK",
        okType: "danger",
        content: (<p className="text-[15px]">{message}</p>),
      });
    };
    
    switch (idButton) {
      case NAME_ACTION_BUTTON.well:
        if (
          userDataInfo?.operator &&
          userDataInfo?.lease &&
          userDataInfo?.region
        ) {
          navigateToPage(NAME_PAGES.gas_lift.create_well);
        } else {
          infoButtonCreate("Incomplete user information to create a well.");
        }
        break;
  
      case NAME_ACTION_BUTTON.pvt:
        if (wellConfig.fluidConfig) {
          infoButtonCreate(`PVT ${messageExist}`);
          setAlertIcon({...alertIcon, edit: {
            ...alertIcon.edit,
            pvt: true
          }});
        } else if(!selectedWell){
          infoButtonCreate("Please select a well to add PVT information");
        } else if (selectedWell && !wellConfig.fluidConfig) {
          const fluidPage =
            fluidType === "dry_gas"
              ? NAME_PAGES.gas_lift.create_fluid_dry_gas
              : NAME_PAGES.gas_lift.create_fluid;
          navigateToPage(fluidPage);
        }
        break;
  
      case NAME_ACTION_BUTTON.reservoir:
        if (wellConfig.reservoir) {
          infoButtonCreate(`Reservoir ${messageExist}`);
          setAlertIcon({...alertIcon, edit: {
            ...alertIcon.edit,
            reservoir: true
          }});
        } else if(!selectedWell){
          infoButtonCreate("Please select a well to add reservoir information");
        }else if (!wellConfig.fluidConfig && selectedWell) {
          infoButtonCreate("Please select a well with PVT data to add reservoir information");
        } else if (selectedWell && !wellConfig.reservoir && fluidType) {
          const reservoirPage =
            fluidType === "dry_gas"
              ? NAME_PAGES.gas_lift.create_reservoir_dry_gas
              : NAME_PAGES.gas_lift.create_reservoir;
          navigateToPage(reservoirPage);
        }
        break;
  
      case NAME_ACTION_BUTTON.vlp:
        if (tuningExists) {
          infoButtonCreate(`VLP ${messageExist}`);
          setAlertIcon({...alertIcon, edit: {
            ...alertIcon.edit,
            vlp: true
          }});
        }else if(!selectedWell){
          infoButtonCreate("Please select a well to add VLP information");
        }else if(!wellConfig.reservoir && selectedWell){
          infoButtonCreate("Please select a well with reservoir data to add VLP information");
        }else if (selectedWell && !tuningExists) {
          navigateToPage(NAME_PAGES.gas_lift.create_edit_tuning);
        }
        break;
  
      case NAME_ACTION_BUTTON.inflow_outflow:
        if (wellConfig.flowConfig) {
          infoButtonCreate(`Inflow/outflow ${messageExist}`);
          setAlertIcon({...alertIcon, edit: {
            ...alertIcon.edit,
            inflow_outflow: true
          }});
        } else if(!selectedWell){
          infoButtonCreate("Please select a well to add inflow/outflow information");
        }else if (!tuningExists && selectedWell) {
          infoButtonCreate("Please select a well with VLP data to add inflow/outflow information");
        } else if (selectedWell) {
          const flowPage =
            fluidType === "dry_gas"
              ? NAME_PAGES.gas_lift.create_inoutflow_dry_gas
              : NAME_PAGES.gas_lift.create_inoutflow;
          navigateToPage(flowPage);
        }
        break;
        
      default:
        infoButtonCreate("Invalid action. Please try again.");
    }
  };
      
  const handleClickButtonEdit = (typeConfig, id) => {

    const validateIDWell = selectedWell === id;
    const fluidType = dataFilterUserGasLift?.fluid_type;
    let routeTypeFluid = "";

    switch (typeConfig) {
      case NAME_ACTION_BUTTON.well:
        if (validateIDWell) {
          navigateToPage(NAME_PAGES.gas_lift.edit_well);
        } else {
          message.warning('Please select the proper well')
        }
        break;
      case NAME_ACTION_BUTTON.pvt:
        if (validateIDWell) {
          routeTypeFluid =
            fluidType === "dry_gas"
            ? NAME_PAGES.gas_lift.edit_fluid_dry_gas
            : NAME_PAGES.gas_lift.edit_fluid;
          
            navigateToPage(routeTypeFluid);
        } else {
          message.warning('Please select the well corresponding to this PVT')
        }
        break;
      case NAME_ACTION_BUTTON.reservoir:
        if (validateIDWell) {         
          routeTypeFluid =
            fluidType === "dry_gas"
            ? NAME_PAGES.gas_lift.edit_reservoir_dry_gas
            : NAME_PAGES.gas_lift.edit_reservoir;
        
          navigateToPage(routeTypeFluid);
        } else {
          message.warning('Please select the well corresponding to this reservoir')
        }
        break;
      case NAME_ACTION_BUTTON.vlp:
        if (validateIDWell) {
          navigateToPage(NAME_PAGES.gas_lift.create_edit_tuning);
        } else {
          message.warning('Please select the well corresponding to this tuning')
        }
        break;
      case NAME_ACTION_BUTTON.inflow_outflow:
        if (validateIDWell) {
          routeTypeFluid =
            fluidType === "dry_gas"
            ? NAME_PAGES.gas_lift.edit_inoutflow_dry_gas
            : NAME_PAGES.gas_lift.edit_inoutflow;
        
          navigateToPage(routeTypeFluid);
        }else{
          message.warning('Please select the well corresponding to this inflow/outflow')
        }
        break;
      default:
        message.warning("Invalid action. Please try again.");
        break;
    }
  }

  const handleClickDelete = (typeConfig, well_id) => {
    
    if (selectedWell !== well_id) {
      message.warning(`Please select the well corresponding to this ${typeConfig === NAME_ACTION_BUTTON.pvt ? 'PVT' : typeConfig === NAME_ACTION_BUTTON.reservoir ? 'reservoir' : typeConfig === NAME_ACTION_BUTTON.vlp ? 'VLP' : typeConfig === NAME_ACTION_BUTTON.history ? 'data history' : typeConfig === NAME_ACTION_BUTTON.inflow_outflow ? 'inflow/outflow' : 'well'}`);
      return;
    }
    
    const typeDescriptions = {
      pvt_data: "PVT",
      reservoir_data: "reservoir",
      vlp_tuning: "VLP",
      inflow_outflow_data: "inflow/outflow data",
      history_data: "historical data",
      well_gas_lift: "well",
    };

    const typeTitle = typeDescriptions[typeConfig] || "";

    const selectedWellIndex = data.findIndex(well => well.well_id === well_id);
    const tuningExist = listTuning.some((item) => item.well_id === well_id);    
    
    if (data[selectedWellIndex]?.wellConfig?.reservoir && typeConfig === NAME_ACTION_BUTTON.pvt) {
      message.info("Please delete reservoir data to delete PVT information");
    }else if(tuningExist && typeConfig === NAME_ACTION_BUTTON.reservoir){
      message.info("Please delete VLP tuning data to delete reservoir information");
    }else if(data[selectedWellIndex]?.wellConfig?.flowConfig && typeConfig === NAME_ACTION_BUTTON.vlp){
      message.info("Please delete inflow/outflow data to delete VLP information");
    }else{
      showDeleteConfirm({
        title: `Do you want to delete this ${typeTitle}?`,
        content: "Deleted information can't be recovered",
        typeConfig,
        well_id,
      });
    }
  };

  const showDeleteConfirm = ({ title, content, typeConfig, well_id }) => {
    confirm({
      title,
      icon: <ExclamationCircleFilled />,
      content,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => handleDeleteAction(typeConfig, well_id),
      onCancel: () => console.log("Cancel"),
    });
  };

  const handleDeleteAction = (typeConfig, well_id) => {
    
    let allPropertiesExist = false;
    let dataDelete = [];

    const handleMutation = (mutation, fetch, data, successMessage) => {

      const basePayload = {
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field, 
        [typeConfig === NAME_ACTION_BUTTON.vlp || successMessage === "VLP deleted successfull" ? "wellid" : "well"]: dataFilterUserGasLift.well_id, 
        username: User.user_name
      };

      if (typeConfig === NAME_ACTION_BUTTON.vlp && typeConfig === NAME_ACTION_BUTTON.history) {
        mutation.mutate(basePayload);
      }else{
        mutation.mutate({ ...basePayload, data});
      }
      
      setTimeout(fetch, 500);

      if (successMessage) {
        message.success(successMessage);
      }
    };

    switch (typeConfig) {

      case NAME_ACTION_BUTTON.pvt:
        dataDelete = Object.assign({}, emptyDataFluid, dataInformationEndFluid);
        allPropertiesExist = REQUIRED_PROPIERTIES.every(prop => prop in dataDelete);
        if (allPropertiesExist) {
          handleMutation(
            mutationUpdateDataGasLift,
            refetchListWellsGasLift,
            dataDelete,
            "PVT deleted successfully"
          );
        } else {
          message.warning('Sorry, something was wrong, please try again');
        }
        break;

      case NAME_ACTION_BUTTON.reservoir:
        dataDelete = Object.assign({}, emptyDataReservoir, dataInformationEndReservoir);
        allPropertiesExist = REQUIRED_PROPIERTIES.every(prop => prop in dataDelete);
        if (allPropertiesExist) {
          handleMutation(
            mutationDeleteScenariosReservoir,
          );
          handleMutation(
            mutationUpdateDataGasLift,
            refetchListWellsGasLift,
            dataDelete,
            "Reservoir deleted successfull",
          );
        } else {
          message.warning('Sorry, something was wrong, please try again');
        }
        break;

      case NAME_ACTION_BUTTON.vlp:
        handleMutation(
          mutationDeleteDataTuning,
          refetchDataTuning,
          [],
          "VLP deleted successfull",
        );
        break;

      case NAME_ACTION_BUTTON.inflow_outflow:
        dataDelete = Object.assign({}, emptyDataFlow, dataInformationEndFlow);
        allPropertiesExist = REQUIRED_PROPIERTIES.every(prop => prop in dataDelete);
        if (allPropertiesExist) {
          handleMutation(
            mutationUpdateDataGasLift,
            refetchListWellsGasLift,
            dataDelete,
            "Inflow/outflow deleted successfull",
          );
        } else {
          message.warning('Sorry, something was wrong, please try again');
        }
        break;

      case NAME_ACTION_BUTTON.history:
        handleMutation(
          mutationDeleteDataHistory,
          refetchListDataHistory,
          [],
          "",
        );
        break;

      case NAME_ACTION_BUTTON.well:
        
        const tuningExistsValidated = listTuning?.some((tuningItem) => tuningItem.well_id === well_id);
        if (tuningExistsValidated) {
          handleMutation(
            mutationDeleteDataTuning,
            refetchDataTuning,
            [],
            "VLP deleted successfull",
          );
        }

        const historyExists = Array.isArray(listDataHistory) && listDataHistory.some((itemHistory) => itemHistory.wellid === well_id);
        if(historyExists){
          handleMutation(
            mutationDeleteDataHistory,
            refetchListDataHistory,
          );
        }

        handleMutation(
          mutationDeleteScenariosReservoir,
        )
        handleMutation(
          mutationDeleteDataGasLift,
        );


        getSelectedWell(null);
        getDataFilterUserGaslift(null);
        break;
      default:
        message.warning("Invalid type for deletion");
        break;
    }
  }

  const handleUploadFile = (dataFile) => {
    
    const historyExists = Array.isArray(listDataHistory) && listDataHistory.some(
      (itemHistory) => itemHistory.wellid === selectedWell
    );
    const maxValueHistory = valuesDataHistory != undefined ? Math.max(...valuesDataHistory) + 1 : 0;
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + (historyExists ? maxValueHistory : 1), ...obj };
    });
    const dataFileEnd = dataFilePre.map((item)=>({
      ...item, 
      ['date']: convertDate(item.date) 
    }))

    const basePayload = {
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
    };

    const handleMutation = (mutation, data) => {
      mutation.mutate({ ...basePayload, data});
      setTimeout(refetchListDataHistory, 500);
    };

    if (dataFileEnd?.length > 0 && !historyExists) {
      handleMutation(mutationCreateDataHistory, dataFileEnd);
    }else if(dataFileEnd?.length > 0 && historyExists){
      handleMutation(mutationUpdateDataHistory, dataFileEnd);
    }
  };

  function convertDate(fechaNumerica) {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };
  
  const handleChangeCheckWell = (valueCheck, idCheck) => {
    
    if (valueCheck === true) {
            
      const dataWellFind = data.find((item) => item.well_id === idCheck);
      const dataFilterNew = {
        county: dataWellFind.county,
        field: dataWellFind.field,
        well_id: dataWellFind.well_id,
        well_platforms:  dataWellFind.well_platforms,
        well_name: dataWellFind.well_name,
        fluid_type: dataWellFind.fluid_type
      };

      getDataFilterUserGaslift(dataFilterNew);
      getSelectedWell(idCheck);
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: {...dataFilter?.data, checkedWellGaslift: idCheck}
        });
      }, 1000);
    } else {
      getSelectedWell(null);
      getDataFilterUserGaslift(null);
      getDataAlertGasLift([]);

      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: {...dataFilter?.data, checkedWellGaslift : ""}
         
        });
      }, 1000);

      getSomeAlert({
        ...someAlert,
        gas_lift: DATA_ALERTS.gas_lift
      });
    }
  }
  
  function navigateToPage (page) {return Navigate(`./${page}`)};

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full  gap-4 pr-2 pl-[90px] p-5">
        <div className="flex flex-row gap-5 pl-[76px] pr-[76pc]">
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(NAME_ACTION_BUTTON.well)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Well Config
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(NAME_ACTION_BUTTON.pvt)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add PVT data
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(NAME_ACTION_BUTTON.reservoir)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Reservoir
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(NAME_ACTION_BUTTON.vlp)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add VLP Tuning
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate(NAME_ACTION_BUTTON.inflow_outflow)}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Inflow/Outflow
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <ImportFileHistory uploadfile={handleUploadFile}></ImportFileHistory>
          </div>
        </div>
        
        {(listWellsGasLift && listWellsGasLift?.statusCode === STATUS_LIST_GAS_LIFT.ERROR) 
        ? 
          <div className="flex flex-col w-full h-[750px] justify-center items-center">
            <ErrorLoadingData />
          </div>
        :
        <ul className="flex flex-col gap-y-[17px]">
          {data?.map((item, index) => {
            const tuningExists = listTuning?.some(
              (tuningItem) => tuningItem.well_id === item.well_id
            );
            const historyExists = Array.isArray(listDataHistory) && listDataHistory.some(
              (itemHistory) => itemHistory.wellid === item.well_id
            );
            return (
              <li
                key={index}
                className="flex flex-row w-full  border-solid  p-5 rounded-md"
              >
                <div className="flex flex-row w-full gap-5">
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium text-[15px] border border-[#BCBCCC] bg-[#FAFAFA] ">
                      <tr style={{ width: '100%' }}>
                        <th scope="col" className="px-6 py-4" style={{ width: '15%' }}>
                          Select well
                        </th>
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Well Name
                        </th>
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Well ID
                        </th> 
                        <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                          Description
                        </th>
                        <th scope="col" className="px-6 py-4 justify-center text-center items-center" style={{ width: '20%' }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-b font-medium text-[15px]  border border-[#BCBCCC]">
                      <tr>
                        <td className="whitespace-nowrap px-6 py-4" rowSpan={ historyExists || item.wellConfig.flowConfig || tuningExists || item.wellConfig.fluidConfig || item.wellConfig.reservoir ? 6  : 1}>
                          <Checkbox
                            className={"custom-checkbox"}
                            onChange={(e) => {
                              handleChangeCheckWell(e.target.checked, item.well_id);
                              getSelectedWell(e.target.checked ? item.well_id : null);
                            }
                            }
                            checked={selectedWell ? item.well_id === dataFilterUserGasLift?.well_id : false}
                          ></Checkbox>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-purple-800">
                          {item.well_name}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4" rowSpan={ historyExists || item.wellConfig.flowConfig || tuningExists || item.wellConfig.fluidConfig || item.wellConfig.reservoir ? 6 : 1} >
                          {item.well_id}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4" >
                          well's data 
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                          <Tooltip title={"Edit"} color={"#662D91"}>
                            <EditOutlined 
                              key="edit" 
                              onClick={() => handleClickButtonEdit(NAME_ACTION_BUTTON.well, item.well_id)} 
                              style={{ color: '#707070' }}/>
                          </Tooltip>
                          <span className="mx-6"></span>
                          <Tooltip title={"Delete the well"} color={"#662D91"}>
                            <DeleteOutlined 
                              key="deleteWell"
                              // onClick={() => handleDelete(item.well_id)} 
                              onClick={() => handleClickDelete(NAME_ACTION_BUTTON.well, item.well_id)}
                              style={{ color: '#707070' }}
                            />
                          </Tooltip>
                        </td>
                      </tr>

                      {item.wellConfig.fluidConfig && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Fluid config
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's fluid config
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Edit"} color={"#662D91"}>
                            <EditOutlined 
                              key="edit" 
                              onClick={() => handleClickButtonEdit(NAME_ACTION_BUTTON.pvt, item.well_id)} 
                              style={{ color: alertIcon.edit.pvt ? '#b91c1c' : '#707070' }}
                            />
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                            <DeleteOutlined 
                              key="delete" 
                              onClick={() => handleClickDelete(NAME_ACTION_BUTTON.pvt, item.well_id)} 
                              style={{ color: '#707070' }}
                            />
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {item.wellConfig.reservoir && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Reservoir
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's reservoir data  
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Edit"} color={"#662D91"}>
                              <EditOutlined 
                                key="edit" 
                                onClick={() => handleClickButtonEdit(NAME_ACTION_BUTTON.reservoir, item.well_id)} 
                                style={{ color: alertIcon.edit.reservoir ? '#b91c1c' : '#707070' }}
                                />
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined 
                                key="delete"
                                onClick={() => handleClickDelete(NAME_ACTION_BUTTON.reservoir, item.well_id)} 
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {tuningExists && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            VLP Tuning
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            VLP Tuning of the well 
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Edit"} color={"#662D91"}>
                              <EditOutlined 
                                key="edit" 
                                onClick={() => handleClickButtonEdit(NAME_ACTION_BUTTON.vlp, item.well_id)} 
                                style={{ color: alertIcon.edit.vlp ? '#b91c1c' : '#707070' }}
                                />
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined 
                                key="delete" 
                                onClick={() => handleClickDelete(NAME_ACTION_BUTTON.vlp, item.well_id)} 
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      )}

                      {item.wellConfig.flowConfig && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Inflow Outflow
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            Inflow Outflow of the well
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Edit"} color={"#662D91"}>
                              <EditOutlined 
                                key="edit" 
                                onClick={() => handleClickButtonEdit(NAME_ACTION_BUTTON.inflow_outflow, item.well_id)} 
                                style={{ color: alertIcon.edit.inflow_outflow ? '#b91c1c' : '#707070' }}
                              />
                            </Tooltip>
                            <span className="mx-6"></span>  
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined 
                                key="delete" 
                                onClick={() => handleClickDelete(NAME_ACTION_BUTTON.inflow_outflow, item.well_id)} 
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                      {historyExists && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            Data history
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            well's production history data 
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined 
                                key="delete" 
                                onClick={() => handleClickDelete(NAME_ACTION_BUTTON.history, item.well_id)} 
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </li>
            );
          })}
        </ul>
        }
      </div>
    </div>
  );
};