import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { format, parse } from 'date-fns';
import moment from 'moment';
import { DashboardContext } from '../../../../context/Dashboard/DashboardContentx';
import { WELL_SENSOR_DEVICE } from '../../../elements/types/type_dashboard';
import { DashboardDCAContext } from '../../../../context/DasboardDCA/DashboardDCAContext';
// Componente de tooltip personalizado
function CustomTooltip({ active, payload, option }) {

  if (active && payload && payload.length) {
    const dataIOTDCA = payload[0].payload;
    const titleColor = option === "Gas" ? "#ed567e" : "#2bca41"; // Color del título dependiendo de Gas o Oil
    const titleColorProduction = option === "Gas" ? "#986a46" : "#4b6f50"; // Color del título dependiendo de Gas o Oil
    const valueColor = "black"; // Color del valor
    const dataCumulative =
      dataIOTDCA[
        option === "Gas"
          ? "gas_rate"
          : "acumulativeOil"
      ];
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: "white", padding: "5px" }}
      >
        <p style={{ color: valueColor }}>
          {`Date: `}
          <span style={{ color: valueColor }}>{dataIOTDCA?.Date}</span>
        </p>
        <p style={{ color: titleColor }}>
          {`${
            option === "Gas"
              ? "Production Rate"
              : "Production Rate"
          }: `}
          <span style={{ color: valueColor }}>
            {
              dataIOTDCA[
                option === "Gas"
                  ? "acumulativeGas"
                  : "oil_rate"
              ]?.toFixed(2)
            }
            {`${option === "Gas" ? " (MSCF/D)" : " (STB)"}: `}
          </span>
        </p>
        <p style={{ color: titleColorProduction }}>
          {`Cumulative Production: `}
          <span style={{ color: valueColor }}>
            {dataCumulative?.toFixed(2)}
            {`${option === "Gas" ? " (MSCF)" : " (STB)"}: `}
          </span>
        </p>
      </div>
    );
  }

  return null;
}

export default function ChartAIDCA(props) {
  const { option, dataIOTDCA, y, bottom, dataHistory, enableModal, dataForcast, typeGraph, typeDevice } = props;
  const { deviceRealTime } = useContext(DashboardContext);
  const chartRef = useRef();
  let [valueOffset, setValueOffset] = useState();
  const dataDeviceReplace = typeDevice === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDevice; 

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chartNode =  chartRef.current ;
        const offsetTop =  chartNode.offsetTop;
        // Obtener el tamaño de la pantalla
        const screenHeight = window.innerHeight;
        setValueOffset(enableModal ? offsetTop + 430 : (screenHeight/2) - offsetTop );
      }
    };

    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);

    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();

    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [enableModal]);

  const formattedData = dataIOTDCA?.map((item, index) => ({
    ...item,
      Date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0], // Formato deseado de fecha
      "oil_rate": item["Oil Rate"],
      "acumulativeOil": item["Corrected Accumulated Volume"],
      "gas_rate": item["Gas Rate"],
      "acumulativeGas": item["Corrected Accumulated Volume"]
    })).filter(
      (item) =>
        item["LSD"] === dataDeviceReplace &&
        item["oil_rate"] !== -999.25 &&
        item["acumulativeOil"] !== -999.25 &&
        item["gas_rate"] !== -999.25 &&
        item["acumulativeGas"] !== -999.25
    );

    const formatDate = (dateString) => {
      // Dividir la cadena de fecha en partes [YYYY, MM, DD]
      const [year, month, day] = dateString.split('-');
    
      // Crear un objeto Date con los valores descompuestos
      const date = new Date(year, month - 1, day); // Mes se restará en 1 ya que el objeto Date usa de 0 a 11 para los meses
    
      // Obtener el día, mes y año del objeto Date
      const formattedDay = date.getDate();
      const formattedMonth = date.getMonth() + 1; // getMonth() devuelve el mes de 0 a 11
      const formattedYear = date.getFullYear();
    
      // Formatear como "MM/D/YYYY"
      return `${formattedMonth}/${formattedDay}/${formattedYear}`;
    };

  let dataFinalHistory = [];

   if (dataHistory != [] && dataHistory != undefined && dataHistory != "undefined" && typeGraph === false ) {

    const dataHistoryMod = dataHistory?.map((item) => ({
      Date: String(item["date"]),
      "oil_rate": item["oil_rate"],
      "acumulativeOil": item["cumulative_oil"],
      "gas_rate": item["gas_rate"],
      "acumulativeGas": item["cumulative_gas"]
    })).filter(
      (item) =>
        item["oil_rate"] !== -999.25 &&
        item["acumulativeOil"] !== -999.25 && 
        item["gas_rate"] !== -999.25 &&
        item["acumulativeGas"] !== -999.25
        
    );

    if (dataForcast && Array.isArray(dataForcast.data) && dataForcast.data.length > 0) {
      const dataForecastMod = dataForcast.data?.map((item) => ({
        Date: formatDate(item["datetime"]),
        "oil_rate": item["y_plot_calc"],
        "acumulativeOil": item["cumulative_oil"],
        "gas_rate": item["gas_rate"],
        // "acumulativeGas": item["comulative_gas"]
      })).filter(
        (item) =>
          item["oil_rate"] !== -999.25 &&
          item["acumulativeOil"] !== -999.25 && 
          item["gas_rate"] !== -999.25
          
      );

      dataFinalHistory = dataHistoryMod.concat(dataForecastMod);

    }  else {
      dataFinalHistory = dataHistoryMod;
    } 
    
  } else if(formattedData && typeGraph === true){
    dataFinalHistory = formattedData;
  } 
  // // Restablecer el valor de la producción acumulada para futuros cálculos
  // cumulativeProduction = 0;

  // Encontrar el máximo valor de Corrected Accumulated Volume u Corrected Flow Rate y redondearlo al siguiente número de mil más cercano para el eje Y1
  const maxDataValue = Math.max(
    ...dataFinalHistory.map(
      (item) =>
        item[
          option === "Gas"
            ? "acumulativeGas"
            : "oil_rate"
        ]
    )
  );

  const yAxisDomain = Math.ceil(Math.round(maxDataValue * 10) / 100) * 10;

  // Encontrar el máximo valor de cumulativeProduction y redondearlo al siguiente número de mil más cercano para el eje Y2
  const maxProductionValue = Math.max(
    ...dataFinalHistory.map(
      (item) =>
        item[
          option === "Oil"
            ? "acumulativeOil"
            : "gas_rate"
        ]
    )
  );

  const yAxisProductionDomain = Math.ceil(Math.round(maxProductionValue * 10) / 100) * 10; 

  // Ancho fijo del eje Y
  const yAxisWidth = 103;
  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 1
    if ((index + 1) % 1 === 0) {
      return <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />;
    }
    return null; // No muestra el punto
  };

  const getXTicks = (data) => {
    // Ordena los datos por fecha ascendente
    data.sort((a, b) => new Date(a.Date) - new Date(b.Date));
  
    const firstDate = new Date(data[0]?.Date);
    const lastDate = new Date(data[data.length - 1]?.Date);
  
    // Crea un array de ticks de 6 meses
    const ticks = [];
    let currentDate = new Date(firstDate);
    while (currentDate <= lastDate) {
      ticks.push(currentDate.toISOString().slice(0, 10));
      currentDate.setUTCMonth(currentDate.getUTCMonth() + 6);
    }
  
    return ticks;
  };
  const formatXAxis = (tickItem) => {
    // Parsear la fecha con formato MM/dd/yyyy
    const parsedDate = parse(tickItem, 'MM/dd/yyyy', new Date());
    // Formatear la fecha a un formato legible
    return format(parsedDate, 'MMM dd, yyyy');
  };

  // Utiliza la función getXTicks para obtener los ticks para el eje x
  const xTicks = getXTicks(dataFinalHistory);

  function getDataUnity(data) {
    const fechas = data?.map(item => item?.Date); // Obtener todas las fechas
    const fechasUnicas = fechas?.filter((fecha, index) => fechas.indexOf(fecha) === index);
    return fechasUnicas;
  }
  // Obtener fechas únicas
  const dateUnity = getDataUnity(dataFinalHistory);

  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={1000}
          height={300}
          data={dataFinalHistory} //dataFinalHistory
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: bottom,
          }}
        >
          <Brush dataKey="Date" height={8}  stroke="#8884d8" y={valueOffset}/>
          <XAxis
            dataKey="Date"
            type="category"
            allowDecimals={false}
            label={{
              value: "Date",
              position: "bottom",
              fill: "black",
            }}
            tickFormatter={(unixTime) => moment(unixTime,"MM/DD/YYYY").format("MM/YYYY")}
            tick={{ fill: "black" }}
            tickValues={xTicks}
            ticks={dateUnity}
          />
          <YAxis
            yAxisId={0}
            type="number"
            dataKey={
              option === "Gas"
                ? "acumulativeGas"
                : "oil_rate"
            }
            label={{
              value:
                option === "Gas"
                  ? "Production Rate (MSCF/D)"
                  : "Production Rate (STB/D)",
              angle: -90,
              position: "insideLeft", // Centrar el label en el eje Y izquierdo
              fill: "black",
              style: { textAnchor: "middle" }, // Mover el label hacia abajo
              fontSize: 15,
            }}
            tickFormatter={(value) => Math.round(value)}
            width={yAxisWidth}
            domain={yAxisDomain}
            allowDataOverflow={true}
            padding={{bottom:10}}
            tick={{ fill: "black" }}
          />
          
          <Tooltip content={<CustomTooltip option={option} />} />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={
              option === "Gas"
                ? "gas_rate"
                : "acumulativeOil"
            }
            label={{
              value:
                option === "Gas"
                  ? "Cumulative Production (MSCF)"
                  : "Cumulative Production (STB)",
              angle: -90,
              position: "insideRight", // Centrar el label en el eje Y derecho
              fill: "black",
              style: { textAnchor: "middle" }, // Mover el label hacia arriba
              fontSize: 15,
            }}
            tickFormatter={(value) => Math.round(value)}
            width={yAxisWidth}
            domain={yAxisProductionDomain}
            allowDataOverflow={true}
            padding={{bottom:10}}
            tick={{ fill: "black" }}
          />
          {option === "Gas" && (
            <>
              <Line
                type="monotone"
                dataKey="acumulativeGas"
                name="GAS"
                stroke="#986a46"
                yAxisId={0}
                dot={<CustomDot color="#986a46" />}
                activeDot={{ r: 5 }}
              />
              <Line
                type="monotone"
                dataKey="gas_rate"
                name="Cumulative Production (SCF/M)"
                stroke="#ed567e"
                yAxisId={1}
                dot={<CustomDot color="#ed567e" />}
                activeDot={{ r: 5 }}
              />
            </>
          )}
          {option === "Oil" && (
            <>
              <Line
                type="monotone"
                dataKey="oil_rate"
                name="OIL"
                stroke="#2bca41"
                yAxisId={0}
                dot={<CustomDot color="#2bca41" />}
                activeDot={{ r: 5 }}
              />
              <Line
                type="monotone"
                dataKey="acumulativeOil"
                name="Cumulative Production (STB/M)"
                stroke="#4b6f50"
                yAxisId={1}
                dot={<CustomDot color="#4b6f50" />}
                activeDot={{ r: 5 }}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}