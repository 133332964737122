import React, { useContext, useEffect, useState } from "react";
import { message, Switch, Tooltip, Select, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as TableIcon } from "../../assets/icon/table.svg";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { useUsers } from "../../hooks/useUsers";
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import { DATA_PLUNGER_LIFT, } from "../../components/elements/types/type_plunger_Lift";
import { ChartPlungerLift } from "../../components/PlungerLift/GraphicsPL/ChartPlungerLift";
import plunger_lift from '../../assets/img/plunger_lift.svg';
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import { STATUS_CODE_PLUNGER_LIFT, STATUS_TYPE_PLUNGER_LIFT } from "../../components/elements/types/type_response_API";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import TablePlungerLift from "../../components/PlungerLift/TablePlunger/TablePlungerLift";

export const StepMenuEditPlungerLift = () => {
  const navigate = useNavigate();
  const { User } = useUsers();
  const { Option } = Select;
  const {
    mutationCalcCasing,
    mutationCalcPressure,
    mutationUpdateWellPlungerLift,
    dataCalcCasing,
    dataCalcPressure,
    dataCalcInterpolation,
    refetchCalcDataCasing,
    refetchCalcDataPressure,
    refetchDataWellPL,
    refetchCalcDataInterpolation,
    userDataInfo,
    dataFilterUserPL,
    dataWellPL,
    refetchListqIPR,
    listqIPR,
  } = useTabPlungerLift();

  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [dataPlungerLift, setDataPlungerLift] = useState(DATA_PLUNGER_LIFT.initialInputPlungerLift)
  const [dataTableTubing, setDataTableTubing] = useState(DATA_PLUNGER_LIFT.initialDataTubing);
  const [dataInformationEnd, setDataInformationEnd] = useState();
  const [activeModal, setActiveModal] = useState(false)
  const [combinedData, setCombinedData] = useState(null); // Para almacenar los datos combinados
  const [inputEmpty, setInputEmpty] = useState(false);
  const [selectCalc, setSelectCalc] = useState("");
  const [enableInputGOR, setEnableInputGOR] = useState(false);
  const [enableInputGasRate, setEnableInputGasRate] = useState(false);
  const [flagDataInterpolation, setFlagDataInterpolation] = useState([]);

  useEffect(() => {
    refetchDataWellPL();
    refetchListqIPR();
    refetchCalcDataPressure();
    refetchCalcDataCasing();
    refetchCalcDataInterpolation()
  }, []);

  // Función para redondear a dos decimales
  const roundToTwoDecimals = (value) => {
    return Math.round(value * 100) / 100;
  };

  useEffect(() => {
    // Verifica si `dataCalcInterpolation` tiene datos disponibles
    if (dataCalcInterpolation?.data?.[0]) {
      const dataValues = dataCalcInterpolation.data[0];

      // Redondea `gas_rate` y los valores `qg_glr` y `qg_pc` a dos decimales para comparación
      const gasRateRounded = roundToTwoDecimals(Number(dataPlungerLift.supplemental_gas_rate));
      const qgGlrRounded = roundToTwoDecimals(dataValues.qg_supplem_glr);
      const qgPcRounded = roundToTwoDecimals(dataValues.qg_supplem_pc);

      // Compara el valor redondeado de `gas_rate` con `qg_glr` y `qg_pc`
      if (gasRateRounded === qgGlrRounded) {
        setSelectCalc("const_by_glr"); // Configura selectCalc como GLR
      } else if (gasRateRounded === qgPcRounded) {
        setSelectCalc("const_by_casing_pressure"); // Configura selectCalc como Casing Pressure
      }
    }
  }, [dataCalcInterpolation, dataPlungerLift.supplemental_gas_rate]);



  useEffect(() => {
    setTimeout(() => {
      if (dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS && dataWellPL?.data) {
        const filterDataCreate = dataWellPL?.data;
        const filterDataEnd = DATA_PLUNGER_LIFT.initialInputPlungerLift;
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
        );
        setDataInformationEnd(filteredJson);

        setDataPlungerLift((prevState) => ({
          ...prevState,
          reservoir_mid_perf_tvd_depth: filterDataCreate.reservoir_mid_perf_tvd_depth,
          reservoir_temperature: filterDataCreate.reservoir_temperature,
          reservoir_pressure: filterDataCreate.reservoir_pressure,
          production_test_date_pp: filterDataCreate.production_test_date_pp,
          production_liquid_rate: filterDataCreate.production_liquid_rate,
          production_gas_rate: filterDataCreate.production_gas_rate,
          production_gor: filterDataCreate.production_gor,
          production_water_cut: filterDataCreate.production_water_cut,
          production_supplemental_gas_rate: filterDataCreate.production_supplemental_gas_rate,
          twp_test_date: filterDataCreate.twp_test_date,
          twp_wellhead_temperature: filterDataCreate.twp_wellhead_temperature,
          twp_wellhead_pressure: filterDataCreate.twp_wellhead_pressure,
          twp_real_time: filterDataCreate.twp_real_time,
          cwp_test_date: filterDataCreate.cwp_test_date,
          cwp_wellhead_pressure: filterDataCreate.cwp_wellhead_pressure,
          cwp_wellhead_temperature: filterDataCreate.cwp_wellhead_temperature,
          cwp_real_time: filterDataCreate.cwp_real_time,
          plunger_depth: filterDataCreate.plunger_depth,
          plunger_weight: filterDataCreate.plunger_weight,
          plunger_fvg: filterDataCreate.plunger_fvg,
          plunger_fvl: filterDataCreate.plunger_fvl,
          plunger_rising_velocity: filterDataCreate.plunger_rising_velocity,
          tubing_data: filterDataCreate.tubing_data,
          required_casing_pressure: filterDataCreate.required_casing_pressure,
          supplemental_gas_rate: filterDataCreate.supplemental_gas_rate,
          required_gas_liquid_ratio: filterDataCreate.required_gas_liquid_ratio,
          required_gas_volume_per_cycle: filterDataCreate.required_gas_volume_per_cycle,
          liquid_rate: filterDataCreate.liquid_rate,
          gas_rate: filterDataCreate.gas_rate,
          cycles_per_day: filterDataCreate.cycles_per_day,
        }));

        setDataTableTubing(filterDataCreate.tubing_data)
      }
    }, 500);
  }, [dataWellPL]);


  useEffect(() => {
    if (!!dataCalcInterpolation?.data && dataCalcInterpolation?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS) {
      const dataValues = dataCalcInterpolation?.data?.[0];
      setFlagDataInterpolation(false);

      if (selectCalc === "const_by_glr") {
        setDataPlungerLift((prevState) => ({
          ...prevState,
          supplemental_gas_rate: formatNumber(dataValues.qg_supplem_glr, 'gas_rate'),

          // Casing Pressure
          required_casing_pressure: formatNumber(dataValues.pc_inter_glr, "required_casing_pressure"),
          current_casing_pressure: formatNumber(dataValues.wh_press_casing, "required_casing_pressure"),
          change_casing_pressure: formatNumber(dataValues.pc_glr_percen_change, "required_casing_pressure"),

          // Gas Liquid Ratio
          required_gas_liquid_ratio: formatNumber(dataValues.glr_req),
          current_gas_liquid_ratio: formatNumber(dataValues.glr_available),
          change_gas_liquid_ratio: formatNumber(dataValues.glr_percen_change, "changes"),

          // Gas Rate
          gas_rate: formatNumber(dataValues.qg_glr, "gas_rate"),
          current_gas_rate: formatNumber(dataValues.qg_current, "gas_rate"), // Current gas rate
          change_gas_rate: formatNumber(dataValues.qg_glr_percen_change, "gas_rate"),

          // Liquid Rate
          liquid_rate: formatNumber(dataValues.ql_inter_glr, "liquid_rate"),
          current_liquid_rate: formatNumber(dataValues.ql_sc, "liquid_rate"),
          change_liquid_rate: formatNumber(dataValues.ql_glr_percen_change, "liquid_rate"),

          // Gas Volume per Cycle
          estimated_gas_volume_per_cycle: formatNumber(dataValues.vg_inter_glr),
          current_gas_volume_per_cycle: formatNumber(dataValues?.vg_cycle_current),

          // Cycles per Day
          cycles_per_day: formatNumber(dataValues.cycle_day_glr, "cycles_per_day"),
          current_cycles_per_day: formatNumber(dataValues.cycle_day_current, "cycles_per_day"),

          // Para la sección de Operación Óptima
          optimum_liquid_rate: formatNumber(dataValues.ql_opt, "liquid_rate"),
          optimum_gas_rate: formatNumber(dataValues.qg_opt, "gas_rate"),
          optimum_surface_casing_pressure: formatNumber(dataValues.pc_opt, "required_casing_pressure"),
          optimum_gas_volume_per_cycle: formatNumber(dataValues.vg_cycle_opt),
          optimum_gas_liquid_ratio: formatNumber(dataValues.glr_opt),
          optimum_cycles_per_day: formatNumber(dataValues.cycle_day_opt),

          // Para la sección de Parámetros Clave
          key_glr_available: formatNumber(dataValues.glr_available),
          key_glr_minimum: formatNumber(dataValues.glr_min),
          key_casing_pressure_available: formatNumber(dataValues.wh_press_casing, "required_casing_pressure"),
          key_casing_pressure_minimum: formatNumber(dataValues.pc_min, "required_casing_pressure"),
          key_max_cycles_per_day: formatNumber(dataValues.num_cycle_max, "cycles_per_day"),
          comments: dataValues?.message_grl
        }));
      } else if (selectCalc === "const_by_casing_pressure") {
        setDataPlungerLift((prevState) => ({
          ...prevState,
          supplemental_gas_rate: formatNumber(dataValues.qg_supplem_pc, 'gas_rate'),

          // Casing Pressure
          required_casing_pressure: formatNumber(dataValues.pc_req, "required_casing_pressure"),
          current_casing_pressure: formatNumber(dataValues.wh_press_casing, "required_casing_pressure"),
          change_casing_pressure: formatNumber(dataValues.pc_percen_change, "required_casing_pressure"),

          // Gas Liquid Ratio
          required_gas_liquid_ratio: formatNumber(dataValues.glr_inter_pc),
          current_gas_liquid_ratio: formatNumber(dataValues.glr_available),
          change_gas_liquid_ratio: formatNumber(dataValues.glr_pc_percen_change, "changes"),

          // Gas Rate
          gas_rate: formatNumber(dataValues.qg_pc, "gas_rate"),
          current_gas_rate: formatNumber(dataValues.qg_current, "gas_rate"), // Current gas rate
          change_gas_rate: formatNumber(dataValues.qg_pc_percen_change, "gas_rate"),

          // Liquid Rate
          liquid_rate: formatNumber(dataValues.ql_inter_pc, "liquid_rate"),
          current_liquid_rate: formatNumber(dataValues.ql_sc, "liquid_rate"),
          change_liquid_rate: formatNumber(dataValues.ql_pc_percen_change, "liquid_rate"),

          // Gas Volume per Cycle
          required_gas_volume_per_cycle: formatNumber(dataValues.vg_inter_pc), // Required gas volume
          current_gas_volume_per_cycle: formatNumber(dataValues?.vg_cycle_current),


          // Cycles per Day
          cycles_per_day: formatNumber(dataValues.cycle_day_pc, "cycles_per_day"),
          current_cycles_per_day: formatNumber(dataValues.cycle_day_current, "cycles_per_day"),

          // Para la sección de Operación Óptima
          optimum_liquid_rate: formatNumber(dataValues.ql_opt, "liquid_rate"),
          optimum_gas_rate: formatNumber(dataValues.qg_opt, "gas_rate"),
          optimum_surface_casing_pressure: formatNumber(dataValues.pc_opt, "required_casing_pressure"),
          optimum_gas_volume_per_cycle: formatNumber(dataValues.vg_cycle_opt),
          optimum_gas_liquid_ratio: formatNumber(dataValues.glr_opt),
          optimum_cycles_per_day: formatNumber(dataValues.cycle_day_opt),

          // Para la sección de Parámetros Clave
          key_glr_available: formatNumber(dataValues.glr_available),
          key_glr_minimum: formatNumber(dataValues.glr_min),
          key_casing_pressure_available: formatNumber(dataValues.wh_press_casing, "required_casing_pressure"),
          key_casing_pressure_minimum: formatNumber(dataValues.pc_min, "required_casing_pressure"),
          key_max_cycles_per_day: formatNumber(dataValues.num_cycle_max, "cycles_per_day"),
          comments: dataValues?.message_pc
        }));
      }
    }
  }, [dataCalcInterpolation, selectCalc, flagDataInterpolation]);



  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleChangeInputData = (event) => {
    const { name, value, type } = event.target;

    // Si el tipo es 'date', mantenemos el valor como string
    const isDateType = type === "date";

    // Solo convertir a número si el valor no es una cadena vacía y no contiene solo un punto decimal
    const isNumber = (!isDateType && value !== "" && !isNaN(value) && value !== ".");

    if (name === "cycles_per_day") {
      // Permitir solo números con un máximo de un decimal mientras el usuario escribe
      if (value.match(/^\d*\.?\d{0,1}$/)) {
        setDataPlungerLift({
          ...dataPlungerLift,
          [name]: value
        });
      }
    } else if (name === "required_casing_pressure" || name === "liquid_rate" || name === "gas_rate") {
      // Para los campos que requieren 2 decimales, permitir el punto decimal
      if (value.match(/^\d*\.?\d{0,2}$/)) {
        setDataPlungerLift({
          ...dataPlungerLift,
          [name]: value // Guardar temporalmente el valor con el punto decimal
        });
      }
    } else if (
      // Inputs de la gráfica que deben permitir hasta 4 decimales
      name === "supplemental_gas_rate" ||
      name === "required_gas_liquid_ratio" ||
      name === "required_gas_volume_per_cycle" ||
      name === "plunger_depth" ||
      name === "plunger_fvl" ||
      name === "plunger_fvg"
    ) {
      // Permitir hasta 4 decimales en estos campos
      if (value.match(/^\d*\.?\d{0,4}$/)) {
        setDataPlungerLift({
          ...dataPlungerLift,
          [name]: value
        });
      }
    } else {
      const numericValue = isNumber ? parseFloat(value) : value;

      if (name === "production_gas_rate" && (value === "" || value === null)) {
        setEnableInputGOR(false);
      }
      if (name === "production_gas_rate" && (value !== "" && value !== null)) {
        setEnableInputGOR(true);
      }
      if (name === "production_gor" && (value === "" || value === null)) {
        setEnableInputGasRate(false);
      }
      if (name === "production_gor" && (value !== "" && value !== null)) {
        setEnableInputGasRate(true);
      }
      if (name === "twp_wellhead_pressure" && numericValue < 14.7) {
        message.info("This value must be greater than or equal to 14.7");
        setDataPlungerLift({
          ...dataPlungerLift,
          [name]: null
        });
      } else {
        setDataPlungerLift({
          ...dataPlungerLift,
          [name]: numericValue // Se actualiza con valor numérico si es necesario
        });
      }
    }
  };
  const handleBlurInputData = (event) => {
    const { name, value } = event.target;

    let numericValue = parseFloat(value);

    if (isNaN(numericValue)) {
      numericValue = ''; // Si no es un número válido, lo dejamos vacío
    } else {
      // Validar y formatear según el campo usando getFieldFormat
      switch (getFieldFormat(name)) {
        case "1-decimal":
          numericValue = numericValue.toFixed(1); // Limitar a 1 decimal
          break;
        case "2-decimals":
          numericValue = numericValue.toFixed(2); // Limitar a 2 decimales
          break;
        case "4-decimals":
          numericValue = numericValue.toFixed(4); // Limitar a 4 decimales
          break;
        case "cwp_wellhead_pressure":
          if (numericValue < 45) {
            message.info("This value must be greater than or equal to 45");
            numericValue = null;
          }
          break;
        case "twp_wellhead_pressure":
          if (numericValue < 14.7) {
            message.info("This value must be greater than or equal to 14.7");
            numericValue = null;
          }
          break;
        default:
          break;
      }
    }

    setDataPlungerLift((prevState) => ({
      ...prevState,
      [name]: numericValue // Se actualiza con el valor formateado
    }));
  };

  // Función para mapear el tipo de formato según el campo
  const getFieldFormat = (fieldName) => {
    // Definir los campos que necesitan cada tipo de formato
    const formatMap = {
      "cycles_per_day": "1-decimal",
      "estimated_cycles_per_day": "1-decimal",
      "current_cycles_per_day": "1-decimal",
      "change_casing_pressure": "2-decimals",
      "change_gas_liquid_ratio": "2-decimals",
      "change_gas_rate": "2-decimals",
      "change_liquid_rate": "2-decimals",

      "required_casing_pressure": "2-decimals",
      "liquid_rate": "2-decimals",
      "estimated_liquid_rate": "2-decimals",
      "current_liquid_rate": "2-decimals",
      "gas_rate": "2-decimals",
      "estimated_gas_rate": "2-decimals",
      "current_gas_rate": "2-decimals",

      "supplemental_gas_rate": "4-decimals",
      "required_gas_liquid_ratio": "4-decimals",
      "required_gas_volume_per_cycle": "4-decimals",
      "plunger_depth": "4-decimals",
      "plunger_fvl": "4-decimals",
      "plunger_fvg": "4-decimals",

      "cwp_wellhead_pressure": "cwp_wellhead_pressure",
      "twp_wellhead_pressure": "twp_wellhead_pressure"
    };

    return formatMap[fieldName] || "";
  };

  const handleChangeSwitch = (name, checked) => {
    setDataPlungerLift({
      ...dataPlungerLift,
      [name]: checked
    })
  }

  const handleChangeSelect = (name, value) => {
    setSelectCalc(value);
  }

  const handleClickRun = () => {

    const validateData = dataValidatePlungerLift(dataPlungerLift);
    const validateTable = VerifyingNulls(dataTableTubing);
    let qoMax = [];
    switch (dataWellPL?.data?.ipr_model) {
      case "vogel":
        qoMax = listqIPR?.data?.qo?.vogel
        break;
      case "straight":
        qoMax = listqIPR?.data?.qo?.straight
        break;
      case "fetko":
        qoMax = listqIPR?.data?.qo?.fetko
        break;
      case "composite":
        qoMax = listqIPR?.data?.qo?.qliq_pet
        break;
      default:
        qoMax = [];
        break;
    }
    if (!validateData && !validateTable) {
      const dataCalculate = {
        id_tubing: Number(dataTableTubing[2].internal_diameter),
        od_tubing_in: Number(dataTableTubing[2].external_diameter),
        wh_temp_casing: Number(dataPlungerLift.cwp_wellhead_temperature),
        wh_press_casing: Number(dataPlungerLift.cwp_wellhead_pressure),
        wh_press_tubing: Number(dataPlungerLift.twp_wellhead_pressure),
        res_temp: Number(dataPlungerLift.reservoir_temperature),
        plunger_weight: Number(dataPlungerLift.plunger_weight),
        plunger_rising_vel: Number(dataPlungerLift.plunger_rising_velocity),
        plunger_fall_vel_liq: Number(dataPlungerLift.plunger_fvl),
        plunger_fall_vel_gas: Number(dataPlungerLift.plunger_fvg),
        plunger_depth_md: Number(dataPlungerLift.plunger_depth),
        id1_casing: Number(dataTableTubing[0].internal_diameter),
        id2_casing: Number(dataTableTubing[1].internal_diameter),
        id3_casing: Number(dataTableTubing[1].internal_diameter),
        length1: dataTableTubing[0].measured_depth,
        length2: dataTableTubing[1].measured_depth,
        length3: 100,
        sgg_mix: Number(dataWellPL?.data?.res_gas_gravity),
        yn2: dataWellPL?.data?.res_N2,
        yco2: dataWellPL?.data?.res_CO2,
        yh2s: dataWellPL?.data?.res_H2S,
        qo_max: qoMax,
        qg_sc: Number(dataPlungerLift.production_gas_rate),
        qg_supp_sc: Number(dataPlungerLift.production_supplemental_gas_rate),
        ql_sc: Number(dataPlungerLift.production_liquid_rate),
      }
      const measuredDepths = dataWellPL?.data?.dataDeviation?.map(item => item.measured_depth);
      const trueVertical = dataWellPL?.data?.dataDeviation?.map(item => item.tvd);

      const dataCalculatePress = {
        num_intervals: 10,
        mid_perf_md: Number(dataPlungerLift.reservoir_mid_perf_tvd_depth),
        res_temp: Number(dataPlungerLift.reservoir_temperature),
        wh_temp: Number(dataPlungerLift.twp_wellhead_temperature),
        gas_sgg_mix: Number(dataWellPL?.data?.res_gas_gravity),
        yN2: dataWellPL?.data?.res_N2,
        yCO2: dataWellPL?.data?.res_CO2,
        yH2S: dataWellPL?.data?.res_H2S,
        measured_depth_table: measuredDepths,
        true_vertical_depth_table: trueVertical,
        tolerance: 0.01
      }
      async function executeFunction() {
        await mutationCalcCasing.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id,
          username: User.user_name,
          data: dataCalculate
        });
        await new Promise(resolve => setTimeout(resolve, 100));
        await new Promise(resolve => setTimeout(resolve, 100));
        refetchCalcDataCasing();
        await mutationCalcPressure.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id,
          username: User.user_name,
          data: dataCalculatePress
        });

        await new Promise(resolve => setTimeout(resolve, 100));
        refetchCalcDataPressure();
        await new Promise(resolve => setTimeout(resolve, 100));
        refetchCalcDataInterpolation();
        await new Promise(resolve => setTimeout(resolve, 100));
        setFlagDataInterpolation(true);
      }

      executeFunction();
    } else {
      setInputEmpty(true);
      message.error("empty data, please validate the fields");
    }
  }

  const handleChangeTubing = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : null;

    const selectedIndex = dataTableTubing.findIndex(
      (data) => data.id === id
    );

    if (name === "measured_depth" && dataValue < 0) {
      message.error("Length (ft) value can't be less than 0 ");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    } else if (name === "internal_diameter" && dataValue > 36) {
      message.error("Diameters value can't be less than or equal to 0 and greater than 36");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    else if (name === "external_diameter") {
      if (dataValue > 36) {
        message.error("Diameters value can't be less than or equal to 0 and greater than 36");
        setDataTableTubing(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null, internal_diameter: null } : item
          )
        );
      } else if (dataValue <= dataTableTubing[selectedIndex].internal_diameter) {
        // Si el external_diameter es menor que el internal_diameter, reseteamos el internal_diameter a ""
        setDataTableTubing(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: value, internal_diameter: "" } : item
          )
        );
      } else {
        setDataTableTubing(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: value } : item
          )
        );
      }
    } else {
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: value } : item
        )
      );
    }
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : value;

    if ((name === "internal_diameter" || name === "external_diameter") && dataValue <= 0) {
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    } else {
      setDataTableTubing(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }
  };

  const handleClickDone = () => {

    const validateData = dataValidatePlungerLift(dataPlungerLift);

    if (!validateData) {

      const dataCreateFluid = Object.assign({}, dataPlungerLift, { tubing_data: [...dataTableTubing] }, dataInformationEnd);
      async function executeFunction() {
        await mutationUpdateWellPlungerLift.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id,
          username: User.user_name,
          data: dataCreateFluid,
          type: "parametersplungerlift"
        });


        await new Promise(resolve => setTimeout(resolve, 100));

        setInputEmpty(false);
        navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
        message.success("Processing complete!");
      }
      executeFunction();
    } else {
      setInputEmpty(true);
      message.error("empty data");
    }
  }

  function dataValidatePlungerLift(data) {
    let invalidFields = [];

    Object.entries(data).forEach(([key, value]) => {
      if (
        key !== "twp_real_time" &&
        key !== "cwp_real_time" &&
        key !== "tubing_data" &&
        key !== "required_casing_pressure" &&
        key !== "supplemental_gas_rate" &&
        key !== "required_gas_liquid_ratio" &&
        key !== "required_gas_volume_per_cycle" &&
        key !== "liquid_rate" &&
        key !== "gas_rate" &&
        key !== "cycles_per_day" &&
        key !== "production_gor"
      ) {
        if ((!value && value !== 0) || value === "") {
          invalidFields.push(key);  // Agregar el campo a la lista de campos inválidos
        }
      }
    });

    if (invalidFields.length > 0) {
      console.log("Los siguientes campos están vacíos o no son válidos:", invalidFields);
      return true;  // Retorna verdadero si hay campos inválidos
    }

    return false;  // Retorna falso si no hay campos inválidos
  }

  function VerifyingNulls(array) {

    let validateValuesNull = false;

    array.forEach(obj => {
      Object.values(obj).forEach(value => {
        if (value === null || value === "") {
          validateValuesNull = true;
        }
      });
    });
    return validateValuesNull;
  }
  const formatNumber = (value, type) => {
    if (!isNaN(value) && value !== "") {
      const num = parseFloat(value);

      switch (type) {
        case "cycles_per_day":
          return num.toFixed(1); // 1 decimal
        case "required_casing_pressure":
        case "liquid_rate":  // Corresponde a Estimated Liquid Rate
        case "gas_rate":     // Corresponde a Estimated Gas Rate
        case "changes":     // Corresponde a Estimated Gas Rate
          return num.toFixed(2); // 2 decimales
        default:
          return num.toFixed(4); // 4 decimales para el resto de campos
      }
    }
    return value;
  };

  const handleCLickModalTable = () => {
    // Verifica si la data del API está disponible antes de procesar
    if (dataCalcCasing?.data?.table_casing) {
      // Crear un solo objeto que contenga plunger_depth_md y los datos de table_casing
      const dataCombined = {
        plunger_depth_md: dataPlungerLift.plunger_depth,  // Añadir plunger_depth_md
        data: dataCalcCasing.data.table_casing,  // Colocar los datos de table_casing en la propiedad 'data'
      };

      // Establece los datos combinados en el estado y abre el modal
      setCombinedData(dataCombined);  // Establece el objeto combinado en lugar de usar un booleano
      setActiveModal((prev) => !prev); // Cambia el estado del modal
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div>
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full"></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <Modal
          centered
          open={activeModal}
          onOk={() => handleCLickModalTable()}
          onCancel={() => handleCLickModalTable()}
          width={1200}
          className="rounded-md mt-[10px] mb-[10px]"
          okButtonProps={{
            className: "bg-[#662D91]",
          }}
        >
          <div className='h-full0 mt-[25px]'>
            {/* Asegúrate de pasar combinedData, no flagDataInterpolation */}
            <TablePlungerLift active={activeModal} data={combinedData} />
          </div>
        </Modal>
      </div>
      <div className="flex flex-col w-full h-full pl-[150px] text-center justify-center">
        <div className="flex w-full h-[50px] justify-start text-center items-center mt-[50px] pl-[110px]">
          <div className="flex flex-row w-[1230px] h-[24px] gap-5 justify-start text-center items-center">
            <h1 className="flex flex-row font-bold text-[25px]">Plunger Lift Analysis</h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]" >Reservoir Parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Mid perf measured depth (feet)
            </label>
            <input
              type="number"
              name="reservoir_mid_perf_tvd_depth"
              value={dataPlungerLift.reservoir_mid_perf_tvd_depth}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.reservoir_mid_perf_tvd_depth === "") || dataPlungerLift.reservoir_mid_perf_tvd_depth === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir pressure (psia)
            </label>
            <input
              type="number"
              name="reservoir_pressure"
              value={dataPlungerLift.reservoir_pressure}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.reservoir_pressure === "") || dataPlungerLift.reservoir_pressure === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir temperature (deg F)
            </label>
            <input
              type="number"
              name="reservoir_temperature"
              value={dataPlungerLift.reservoir_temperature}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.reservoir_temperature === "") || dataPlungerLift.reservoir_temperature === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Production parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test date
            </label>
            <input
              type="date"
              name="production_test_date_pp"
              value={dataPlungerLift.production_test_date_pp}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.production_test_date_pp === "") || dataPlungerLift.production_test_date_pp === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Liquid rate (bbl/d)
            </label>
            <input
              type="number"
              name="production_liquid_rate"
              value={dataPlungerLift.production_liquid_rate}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.production_liquid_rate === "") || dataPlungerLift.production_liquid_rate === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Gas rate (Mscf/d)
            </label>
            <input
              type="number"
              name="production_gas_rate"
              value={dataPlungerLift.production_gas_rate}
              onChange={handleChangeInputData}
              disabled={enableInputGasRate}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.production_gas_rate === "") || dataPlungerLift.production_gas_rate === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              GOR (SCF/STB)
            </label>
            <input
              type="number"
              name="production_gor"
              value={dataPlungerLift.production_gor}
              onChange={handleChangeInputData}
              disabled={true}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(false && dataPlungerLift.production_gor === "") || dataPlungerLift.production_gor === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Water cut (%)
            </label>
            <input
              type="number"
              name="production_water_cut"
              value={dataPlungerLift.production_water_cut}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.production_water_cut === "") || dataPlungerLift.production_water_cut === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Supplement gas rate (Mscf/d)
            </label>
            <input
              type="number"
              name="production_supplemental_gas_rate"
              value={dataPlungerLift.production_supplemental_gas_rate}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.production_supplemental_gas_rate === "") || dataPlungerLift.production_supplemental_gas_rate === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]" >Tubing wellhead parameter</h1>
          </div>
        </div>
        <div className="flex w-full max-w-[592px] text-start items-center font bold text-[15px] gap-2">
          <label htmlFor="">User</label>
          <Switch
            className="items-center"
            size="small"
            style={{
              backgroundColor: dataPlungerLift?.twp_real_time ? "#662D91" : "#707070",
              borderColor: dataPlungerLift?.twp_real_time ? "#662D91" : "#707070",
            }}
            checked={dataPlungerLift?.twp_real_time}
            onChange={(checked) => handleChangeSwitch("twp_real_time", checked)}
          ></Switch>
          <label htmlFor="">Real Time</label>
          <Tooltip title={'info'}>
            <InfoCircleOutlined className="ml-2 cursor-pointer" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
          </Tooltip>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5 mt-[8px]">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test date
            </label>
            <input
              type="date"
              name="twp_test_date"
              value={dataPlungerLift.twp_test_date}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.twp_test_date === "") || dataPlungerLift.twp_test_date === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Tubing wellhead pressure (psia)
            </label>
            <input
              type="number"
              name="twp_wellhead_pressure"
              value={dataPlungerLift.twp_wellhead_pressure}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.twp_wellhead_pressure === "") || dataPlungerLift.twp_wellhead_pressure === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Wellhead temperature (degF)
            </label>
            <input
              type="number"
              name="twp_wellhead_temperature"
              value={dataPlungerLift.twp_wellhead_temperature}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.twp_wellhead_temperature === "") || dataPlungerLift.twp_wellhead_temperature === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Casing wellhead parameter</h1>
          </div>
        </div>
        <div className="flex w-full max-w-[592px] text-start items-center font bold text-[15px] gap-2">
          <label htmlFor="">User</label>
          <Switch
            className="items-center"
            size="small"
            style={{
              backgroundColor: dataPlungerLift?.cwp_real_time ? "#662D91" : "#707070",
              borderColor: dataPlungerLift?.cwp_real_time ? "#662D91" : "#707070",
            }}
            checked={dataPlungerLift?.cwp_real_time}
            onChange={(checked) => handleChangeSwitch("cwp_real_time", checked)}
          ></Switch>
          <label htmlFor="">Real Time</label>
          <Tooltip title={'info'}>
            <InfoCircleOutlined className="ml-2 cursor-pointer" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
          </Tooltip>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5 mt-[8px]">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test date
            </label>
            <input
              type="date"
              name="cwp_test_date"
              value={dataPlungerLift.cwp_test_date}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.cwp_test_date === "") || dataPlungerLift.cwp_test_date === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Casing wellhead pressure (psia)
            </label>
            <input
              type="number"
              name="cwp_wellhead_pressure"
              value={dataPlungerLift.cwp_wellhead_pressure}
              onChange={handleChangeInputData}
              onBlur={handleBlurInputData}  // Validación solo al salir del campo
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.cwp_wellhead_pressure === "") || dataPlungerLift.cwp_wellhead_pressure === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Wellhead temperature (degF)
            </label>
            <input
              type="number"
              name="cwp_wellhead_temperature"
              value={dataPlungerLift.cwp_wellhead_temperature}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.cwp_wellhead_temperature === "") || dataPlungerLift.cwp_wellhead_temperature === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Plunger lift parameters</h1>
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5 mt-[8px]">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger depth (feet)
            </label>
            <input
              type="number"
              name="plunger_depth"
              value={dataPlungerLift.plunger_depth}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.plunger_depth === "") || dataPlungerLift.plunger_depth === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger weight (lbf)
            </label>
            <input
              type="number"
              name="plunger_weight"
              value={dataPlungerLift.plunger_weight}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.plunger_weight === "") || dataPlungerLift.plunger_weight === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              min="0"
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger falling velocity in gas (ft/min)
            </label>
            <input
              type="number"
              name="plunger_fvg"
              value={dataPlungerLift.plunger_fvg}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.plunger_fvg === "") || dataPlungerLift.plunger_fvg === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
        </div>
        <div className="flex flex-row w-full text-center justify-center gap-5">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger falling valocity in liquid (ft/min)
            </label>
            <input
              type="number"
              name="plunger_fvl"
              value={dataPlungerLift.plunger_fvl}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.plunger_fvl === "") || dataPlungerLift.plunger_fvl === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Plunger rising velocity (ft/min)
            </label>
            <input
              type="number"
              name="plunger_rising_velocity"
              value={dataPlungerLift.plunger_rising_velocity}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border 
                ${(inputEmpty && dataPlungerLift.plunger_rising_velocity === "") || dataPlungerLift.plunger_rising_velocity === null
                  ? 'border-red-700 bg-yellow-100'
                  : 'border-solid border-[#BCBCCC]'
                }
              `}
              disabled
            />
          </div>
          <div className="flex flex-col w-1/3" hidden={true}></div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center">
        <div className="flex flex-col w-full h-full justify-center text-center items-center mb-[27px]">
          <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
            <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[16px]" >Tubing</h1>
            </div>
          </div>
          <ul className="flex flex-col w-full h-full">
            {/* seccion de titulos */}
            <div className="flex flex-col w-full text-start justify-end items-end mb-[7px]">
              <div className="flex flex-row w-full h-[20px] gap-2">
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px]">
                  Name
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px] pl-[5px]">
                  Md length (feet)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px] pl-[8px]">
                  External diameter (inches)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start text-[15px] mb-[7px] pl-[6px]">
                  Internal diameter (inches)
                </label>
              </div>
            </div>
            {dataTableTubing.map((item, index) => (
              <li key={item.id + "tubing" + index} className="flex flex-row w-full overflow-y-auto">
                <div className="flex flex-col w-full gap-x-[18px] mb-[10px]">
                  <div className="flex flex-row w-full gap-5">
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="text"
                        name={`nameTubing`}
                        value={dataTableTubing[index].nameTubing}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]`}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        name={`measured_depth`}
                        value={dataTableTubing[index].measured_depth}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) => handleChangeBlur(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid 
                          ${item.measured_depth === null
                            ? "border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          }`}
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        name={`external_diameter`}
                        value={dataTableTubing[index].external_diameter}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full  max-w-[592px]  h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    <div className="flex flex-col w-1/4 text-right items-end">
                      <input
                        type="number"
                        name={`internal_diameter`}
                        value={dataTableTubing[index].internal_diameter}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-start text-center items-center ">
            <button
              className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
              onClick={() => handleClickRun()}
            >
              Run
            </button>
            <Select
              className="flex w-full h-[40px] max-w-[320px] text-start"
              name="select_calc"
              value={selectCalc || "Selected option"}
              onChange={(e) => handleChangeSelect('select_calc', e)}
            >
              <Option value="const_by_glr">Constrained by GLR</Option>
              <Option value="const_by_casing_pressure">Constrained by Casing Pressure</Option>
            </Select>
            <div className="flex flex-row w-full h-[40px] gap-2">
              <label className="flex max-w-[592px] text-left items-center justify-center text-[15px] mb-[7px]">
                Comments:
              </label>
              <div className="flex flex-col w-full text-right">
                <input
                  type="text"
                  name="comments"
                  value={dataPlungerLift.comments || ''}
                  onChange={(e) => setDataPlungerLift((prevState) => ({
                    ...prevState,
                    comments: e.target.value,
                  }))}
                  className="w-full h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] text-center justify-center mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row w-full h-full gap-5 justify-center text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Operating Parameters</h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 pl-[260px] max-w-[1400px]">
        <div className="col-span-2 grid grid-cols-2 gap-4">
          {/* Columna 1: Plunger Lift - Optimum Operation */}
          <div className="col-span-1 space-y-4">
            <h1 className="text-lg font-bold mb-[7px] whitespace-nowrap">Plunger Lift - Optimum Operation</h1>

            <div>
              <label className="flex max-w-[592px] w-full text-left items-start justify-start text-[15px] mb-[7px] whitespace-nowrap">Liquid rate (bbl/d)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="optimum_liquid_rate"
                value={dataPlungerLift.optimum_liquid_rate || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex max-w-[592px] w-full text-left items-start justify-start text-[15px] mb-[7px] whitespace-nowrap">Gas rate (Mscf/d)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="optimum_gas_rate"
                value={dataPlungerLift.optimum_gas_rate || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex max-w-[592px] w-full text-left items-start justify-start text-[15px] mb-[7px] whitespace-nowrap">Surface casing pressure (psia)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="optimum_surface_casing_pressure"
                value={dataPlungerLift.optimum_surface_casing_pressure || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex max-w-[592px] w-full text-left items-start justify-start text-[15px] mb-[7px] whitespace-nowrap">Gas volume per cycle (Mscf)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="optimum_gas_volume_per_cycle"
                value={dataPlungerLift.optimum_gas_volume_per_cycle || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex max-w-[592px] w-full text-left items-start justify-start text-[15px] mb-[7px] whitespace-nowrap">Gas liquid ratio (Mscf/bbl)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="optimum_gas_liquid_ratio"
                value={dataPlungerLift.optimum_gas_liquid_ratio || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex max-w-[592px] w-full text-left items-start justify-start text-[15px] mb-[7px] whitespace-nowrap">Cycles per day (Cycle/d)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="optimum_cycles_per_day"
                value={dataPlungerLift.optimum_cycles_per_day || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>
          </div>

          {/* Columna 2: Plunger Lift - Key Parameters */}
          <div className="col-span-1 space-y-4">
            <h1 className="text-lg font-bold mb-[7px] whitespace-nowrap">Plunger Lift - Key Parameters</h1>

            <div>
              <label className="flex w-full text-left text-[15px] mb-[7px] whitespace-nowrap">GLR available (Mscf/bbl)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="key_glr_available"
                value={dataPlungerLift.key_glr_available || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex w-full text-left text-[15px] mb-[7px] whitespace-nowrap">GLR minimum (Mscf/bbl)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="key_glr_minimum"
                value={dataPlungerLift.key_glr_minimum || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex w-full text-left text-[15px] mb-[7px] whitespace-nowrap">Casing pressure available (psia)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="key_casing_pressure_available"
                value={dataPlungerLift.key_casing_pressure_available || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex w-full text-left text-[15px] mb-[7px] whitespace-nowrap">Casing pressure minimum (psia)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="key_casing_pressure_minimum"
                value={dataPlungerLift.key_casing_pressure_minimum || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>

            <div>
              <label className="flex w-full text-left text-[15px] mb-[7px] whitespace-nowrap">Maximum cycles per day (Cycle/d)</label>
              <input
                type="number"
                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                name="key_max_cycles_per_day"
                value={dataPlungerLift.key_max_cycles_per_day || ''}
                onChange={handleChangeInputData}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* Columna 3: Imagen del Esquema */}
        <div className="col-span-2 relative w-[1030px] h-[600px] mx-auto mt-[2rem]">
          <img src={plunger_lift} className="w-full h-full lg:absolute mt-[1.3rem]" />

          {/* Casing Pressure */}
          <label className="absolute left-[12em] top-[1.5rem] text-[14px] font-bold whitespace-nowrap">
            Casing pressure (psia)
          </label>
          <div className="absolute top-[3rem] left-[10.5em] flex flex-col space-y-0.5">
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Required</label>
              <input
                type="number"
                name="required_casing_pressure"
                value={dataPlungerLift.required_casing_pressure || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Current</label>
              <input
                type="number"
                name="current_casing_pressure"
                value={dataPlungerLift.current_casing_pressure || ''}
                onChange={handleChangeInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Change (%)</label>
              <input
                type="number"
                name="change_casing_pressure"
                value={dataPlungerLift.change_casing_pressure || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>

          {/* Supplement Gas Rate */}
          <div className="absolute top-[10rem] left-[28px] flex flex-col space-y-0.5">
            <label className="whitespace-nowrap font-bold text-[15px]">Supplement gas rate (Mscf/d)</label>
            <input
              type="text"
              name="supplemental_gas_rate"
              value={dataPlungerLift.supplemental_gas_rate || ''}
              onChange={handleChangeInputData}
              onBlur={handleBlurInputData}
              className="w-[260px] h-[37px] px-2 border rounded-md shadow-sm"
              readOnly
            />
          </div>

          {/* Gas Liquid Ratio */}
          <div className="absolute top-[27rem] left-[10px] flex flex-col space-y-0.5">
            <label className="whitespace-nowrap font-bold text-[15px]">Gas liquid ratio (Mscf/bbl)</label>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Required</label>
              <input
                type="number"
                name="required_gas_liquid_ratio"
                value={dataPlungerLift.required_gas_liquid_ratio || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Current</label>
              <input
                type="number"
                name="current_gas_liquid_ratio"
                value={dataPlungerLift.current_gas_liquid_ratio || ''}
                onChange={handleChangeInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Change (%)</label>
              <input
                type="number"
                name="change_gas_liquid_ratio"
                value={dataPlungerLift.change_gas_liquid_ratio || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>

          {/* Gas Volume per Cycle */}
          <div className="absolute top-[35.5rem] left-[10px] flex flex-col space-y-0.5">
            <label className="whitespace-nowrap font-bold text-[15px]">Gas volume per cycle (Mscf)</label>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Required</label>
              <input
                type="number"
                name="required_gas_volume_per_cycle"
                value={dataPlungerLift.required_gas_volume_per_cycle || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Current</label>
              <input
                type="number"
                name="current_gas_volume_per_cycle"
                value={dataPlungerLift.current_gas_volume_per_cycle || ''}
                onChange={handleChangeInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>

          {/* Estimated Liquid Rate */}
          <div className="absolute top-[12.5rem] right-[4em] flex flex-col space-y-0.5">
            <label className="whitespace-nowrap font-bold text-[15px]">Liquid rate (bbl/d)</label>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px]">Estimated</label>
              <input
                type="number"
                name="liquid_rate"
                value={dataPlungerLift.liquid_rate || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Current</label>
              <input
                type="number"
                name="current_liquid_rate"
                value={dataPlungerLift.current_liquid_rate || ''}
                onChange={handleChangeInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Change (%)</label>
              <input
                type="number"
                name="change_liquid_rate"
                value={dataPlungerLift.change_liquid_rate || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>

          {/* Estimated Gas Rate */}
          <div className="absolute top-[4rem] right-[4em] flex flex-col space-y-0.5">
            <label className="whitespace-nowrap font-bold text-[15px]">Gas rate (Mscf/d)</label>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px]">Estimated</label>
              <input
                type="number"
                name="gas_rate"
                value={dataPlungerLift.gas_rate || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Current</label>
              <input
                type="number"
                name="current_gas_rate"
                value={dataPlungerLift.current_gas_rate || ''}
                onChange={handleChangeInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Change (%)</label>
              <input
                type="number"
                name="change_gas_rate"
                value={dataPlungerLift.change_gas_rate || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>

          {/* Cycles per Day */}
          <div className="absolute top-[26rem] left-[475px] flex flex-col space-y-0.5">
            <label className="whitespace-nowrap font-bold text-[15px]">Cycles per day (Cycle/day)</label>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Estimated</label>
              <input
                type="number"
                name="cycles_per_day"
                value={dataPlungerLift.cycles_per_day || ''}
                onChange={handleChangeInputData}
                onBlur={handleBlurInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
            <div className="flex flex-row items-center gap-[1.5em]">
              <label className="w-[80px] whitespace-nowrap">Current</label>
              <input
                type="number"
                name="current_cycles_per_day"
                value={dataPlungerLift.current_cycles_per_day || ''}
                onChange={handleChangeInputData}
                className="w-[100px] h-[30px] px-2 border rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-span-4 w-full flex flex-row gap-x-4 relative mx-auto mt-[2rem] items-center">
          <TableIcon className="flex w-6 h-6 fill-black hover:stroke-pa-purple" onClick={handleCLickModalTable} /><span className="font-bold"> Plunger Lift Operation Parameters Range
          </span>
        </div>
      </div>

      <div className="relative w-[1200px] h-[600px] mx-auto mt-[6rem]">
        <ChartPlungerLift
          dataCasingChart={dataCalcCasing?.data || []}
          dataPressChart={dataCalcPressure?.data || []}
          dataInterpolation={dataCalcInterpolation?.data?.[0] || []}
        />
      </div>

      <div className="flex flex-col w-full h-full pl-[260px] pr-[170px] items-end text-center justify-center mb-[10px]">
        <button
          className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
          onClick={() => handleClickDone()}
        >
          Save
        </button>
      </div>
    </div>
  )
}