import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from '../auth/context/AuthProvider';
import { DashboardProvider } from '../context/Dashboard/DashboardProvider';
import { DashboardDCAProvider } from '../context/DashboardDCA/DashboardDCAProvider';
import { DataGasLiftProvider } from '../context/DataGasLift/DataGasLiftProvider';
import { DashboardPlottingProvider } from '../context/DashboardPlotting/DashboardPlottingProvider';
import { DashboardSCTProvider } from '../context/DashboardSCT/DashboardSCTProvider';
import { DashboardPLProvider } from '../context/DashboardPlungerLift/DashboardPLProvider';
import { DashboardInjectorProvider } from '../context/DashboardInjector/DashboardInjectorProvider';
import { UserProvider } from '../context/User/UserProvider';
import { ProtectedRoutes } from '../components/ProtectedRoutes';
import { Loading } from '../components/Loading';
import StepMenuWellInjector from '../pages/TabInjector/AddEditWell/StepMenuWellInjector';

// Lazy Loading de los componentes
const Login = React.lazy(() => import("../auth/pages/Login"));
const ForgotPassword = React.lazy(
	() => import("../auth/pages/ForgotPassword/ForgotPassword"),
);
const CreateNewPassword = React.lazy(
	() => import("../auth/pages/CreateNewPassword/CreateNewPassword"),
);
const MapWells = React.lazy(() => import("../pages/TabMap/MapWells"));
const DashboardDCA = React.lazy(
	() => import("../pages/TabDCA/Dashboard/DashboardDCA"),
);
const DashboardGasLift = React.lazy(
	() => import("../pages/TabGaslift/Dashboard/DashboardGasLift"),
);
const AddWellDCA = React.lazy(
	() => import("../pages/TabDCA/ListWells/AddWellDCA"),
);
const StepMenuEditWellDCA = React.lazy(
	() => import("../pages/TabDCA/AddEditWell/StepMenuEditWellDCA"),
);
const AddWellGasLift = React.lazy(
	() => import("../pages/TabGaslift/ListWells/AddWellGasLift"),
);
const StepMenuFluidConfig = React.lazy(
	() => import("../pages/TabGaslift/FluidConfigBlackOil/StepMenuFluidConfig"),
);
const StepMenuFluidConfigDryGas = React.lazy(
	() =>
		import("../pages/TabGaslift/FluidConfigDryGas/StepMenuFluidConfigDryGas"),
);
const StepMenuAddWellDCA = React.lazy(
	() => import("../pages/TabDCA/AddEditWell/StepMenuAddWellDCA"),
);
const StepMenuReservoir = React.lazy(
	() => import("../pages/TabGaslift/ReservoirBlackOil/StepMenuReservoir"),
);
const StepMenuWellConfig = React.lazy(
	() => import("../pages/TabGaslift/AddEditWell/StepMenuWellConfig"),
);
const Register = React.lazy(() => import("../pages/Users_Control/Register"));
const TuningGasLift = React.lazy(
	() => import("../pages/TabGaslift/Tuning/TuningGasLift"),
);
const UsersExternal = React.lazy(
	() => import("../pages/Users_Control/UsersExternal"),
);
const UsersInternal = React.lazy(
	() => import("../pages/Users_Control/UsersInternal"),
);
const StepMenuEditWellConfig = React.lazy(
	() => import("../pages/TabGaslift/AddEditWell/StepMenuEditWellConfig"),
);
const StepMenuEditFluidConfig = React.lazy(
	() =>
		import("../pages/TabGaslift/FluidConfigBlackOil/StepMenuEditFluidConfig"),
);
const StepMenuEditFluidConfigDryGas = React.lazy(
	() =>
		import("../pages/TabGaslift/FluidConfigDryGas/StepMenuEditFluidConfigDryGas"),
);
const StepMenuEditReservoir = React.lazy(
	() => import("../pages/TabGaslift/ReservoirBlackOil/StepMenuEditReservoir"),
);
const StepMenuEditReservoirDryGas = React.lazy(
	() =>
		import("../pages/TabGaslift/ReservoirDryGas/StepMenuEditReservoirDryGas"),
);
const StepMenuReservoirDryGas = React.lazy(
	() => import("../pages/TabGaslift/ReservoirDryGas/StepMenuReservoirDryGas"),
);
const StepMenuInOutFlow = React.lazy(
	() => import("../pages/TabGaslift/InOutFlowBlackOil/StepMenuInOutFlow"),
);
const StepMenuInOutFlowDryGas = React.lazy(
	() => import("../pages/TabGaslift/InOutFlowDryGas/StepMenuInOutFlowDryGas"),
);
const StepMenuEditInOutFlow = React.lazy(
	() => import("../pages/TabGaslift/InOutFlowBlackOil/StepMenuEditInOutFlow"),
);
const StepMenuEditInOutFlowDryGas = React.lazy(
	() =>
		import("../pages/TabGaslift/InOutFlowDryGas/StepMenuEditInOutFlowDryGas"),
);
const DashboardScreeningTool = React.lazy(
	() => import("../pages/TabScreeningTool/Dashboard/DashboardScreeningTool"),
);
const AddScreeningTool = React.lazy(
	() => import("../pages/TabScreeningTool/ListWells/AddScreeningTool"),
);
const StepMenuScreeningTool = React.lazy(
	() =>
		import("../pages/TabScreeningTool/AddEditReservoir/StepMenuScreeningTool"),
);
const StepMenuEditScreeningTool = React.lazy(
	() =>
		import(
			"../pages/TabScreeningTool/AddEditReservoir/StepMenuEditScreeningTool"
		),
);
const Plotting = React.lazy(() => import("../pages/TabPlotting/Plotting"));
const DashboardPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/Dashboard/DashboardPlungerLift"),
);
const AddPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/ListWells/AddPlungerLift"),
);
const StepMenuWellPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/AddEditWell/StepMenuWellPlungerLift"),
);
const StepMenuEditWellPlungerLift = React.lazy(
	() =>
		import("../pages/TabPlungerLift/AddEditWell/StepMenuEditWellPlungerLift"),
);
const StepMenuFluidPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/PVTConfig/StepMenuFluidPL"),
);
const StepMenuReservoirPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/Reservoir/StepMenuReservoirPL"),
);
const StepMenuEditReservoirPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/Reservoir/StepMenuEditReservoirPL"),
);
const StepMenuPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/PlungerLift/StepMenuPlungerLift"),
);
const StepMenuEditFluidPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/PVTConfig/StepMenuEditFluidPL"),
);
const StepMenuEditPlungerLift = React.lazy(
	() => import("../pages/TabPlungerLift/PlungerLift/StepMenuEditPlungerLift"),
);
const UsersControlParameters = React.lazy(
	() => import("../pages/Users_Control/UsersControlParameters"),
);
const MultiWellTrending = React.lazy(
	() => import("../pages/TabGaslift/MultiWellTrending/MultiWellTrending"),
);
const DashboardInjector = React.lazy(
	() => import("../pages/TabInjector/Dashboard/DashboardInjector"),
);
const AddWellInjector = React.lazy(
	() => import("../pages/TabInjector/ListWells/AddWellInjector"),
);
const StepMenuAddWellInjector = React.lazy(
	() => import("../pages/TabInjector/AddEditWell/StepMenuAddWellInjector"),
);
const StepMenuEditWellInjector = React.lazy(
	() => import("../pages/TabInjector/AddEditWell/StepMenuEditWellInjector"),
);
const ProductionTestAnalysis = React.lazy(
	() => import("../pages/TabPlotting/ProductionTest/ProductionTestAnalysis"),
);

const queryClient = new QueryClient();

export const AppRoutes = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<UserProvider>
					<DashboardProvider>
						<DashboardDCAProvider>
							<DataGasLiftProvider>
								<DashboardPlottingProvider>
									<DashboardSCTProvider>
										<DashboardPLProvider>
											<DashboardInjectorProvider>
												<Suspense fallback={<Loading />}>
													<Routes>
														<Route element={<ProtectedRoutes isAllowed="External" />}>
															<Route index element={<DashboardDCA />} />
														</Route>
														<Route element={<ProtectedRoutes isAllowed="Internal" />}>
															<Route path="/Users-Register" element={<Register />} />
															<Route path="/Users-External" element={<UsersExternal />} />
															<Route path="/Users-Internal" element={<UsersInternal />} />
															<Route
																path="/Users-Parameters"
																element={<UsersControlParameters />}
															/>
														</Route>
														<Route element={<ProtectedRoutes isAllowed="Bound" />}>
															<Route path="/Map" element={<MapWells />} />
															<Route path="/Plotting" element={<Plotting />} />
															<Route
																path="/DashboardScreeningTool"
																element={<DashboardScreeningTool />}
															/>
															<Route path="/DashboardGasLift" element={<DashboardGasLift />} />
															<Route path="/DashboardDCA" element={<DashboardDCA />} />
															<Route
																path="/DashboardInjector"
																element={<DashboardInjector />}
															/>
															<Route path="/AddWellDCA" element={<AddWellDCA />} />
															<Route
																path="/AddWellDCA/StepMenu-WellDCA"
																element={<StepMenuAddWellDCA />}
															/>
															<Route
																path="/AddWellDCA/StepMenu-EditWellDCA"
																element={<StepMenuEditWellDCA />}
															/>
															<Route path="/AddWellGasLift" element={<AddWellGasLift />} />
															<Route
																path="/AddWellGasLift/StepMenu-EditWellConfig"
																element={<StepMenuEditWellConfig />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-EditFluidConfig"
																element={<StepMenuEditFluidConfig />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-EditReservoir"
																element={<StepMenuEditReservoir />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-EditReservoir-DryGas"
																element={<StepMenuEditReservoirDryGas />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-Reservoir-DryGas"
																element={<StepMenuReservoirDryGas />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-FluidConfig"
																element={<StepMenuFluidConfig />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-Reservoir"
																element={<StepMenuReservoir />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-WellConfig"
																element={<StepMenuWellConfig />}
															/>
															<Route
																path="/AddWellGasLift/TuningGasLift"
																element={<TuningGasLift />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-InOutFlow"
																element={<StepMenuInOutFlow />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-EditInOutFlow"
																element={<StepMenuEditInOutFlow />}
															/>
															<Route path="/AddScreeningTool" element={<AddScreeningTool />} />
															<Route
																path="/AddScreeningTool/StepMenu-ScreeningTool"
																element={<StepMenuScreeningTool />}
															/>
															<Route
																path="/AddScreeningTool/StepMenu-EditScreeningTool"
																element={<StepMenuEditScreeningTool />}
															/>
															<Route
																path="/DashboardPlungerLift"
																element={<DashboardPlungerLift />}
															/>
															<Route path="/AddPlungerLift" element={<AddPlungerLift />} />
															<Route
																path="/AddPlungerLift/StepMenu-WellPlungerLift"
																element={<StepMenuWellPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-EditWellPlungerLift"
																element={<StepMenuEditWellPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-FluidPlungerLift"
																element={<StepMenuFluidPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-EditFluidPlungerLift"
																element={<StepMenuEditFluidPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-ReservoirPlungerLift"
																element={<StepMenuReservoirPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-EditReservoirPlungerLift"
																element={<StepMenuEditReservoirPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-EditPlungerLift"
																element={<StepMenuEditPlungerLift />}
															/>
															<Route
																path="/AddPlungerLift/StepMenu-PlungerLift"
																element={<StepMenuPlungerLift />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-FluidConfig-DryGas"
																element={<StepMenuFluidConfigDryGas />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-EditFluidConfig-DryGas"
																element={<StepMenuEditFluidConfigDryGas />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-InOutFlow-DryGas"
																element={<StepMenuInOutFlowDryGas />}
															/>
															<Route
																path="/AddWellGasLift/StepMenu-EditInOutFlow-DryGas"
																element={<StepMenuEditInOutFlowDryGas />}
															/>
															<Route
																path="/AddWellGasLift/MultiWellTrending"
																element={<MultiWellTrending />}
															/>
															<Route path="/AddWellInjector" element={<AddWellInjector />} />
															<Route path="/AddWellInjector/StepMenu-WellInjector" element={<StepMenuWellInjector isEditing={false} />} />
															<Route path="/AddWellInjector/StepMenu-EditWellInjector" element={<StepMenuWellInjector isEditing={true}/>} />
															<Route
																path="/Plotting/ProductionTestAnalysis"
																element={<ProductionTestAnalysis />}
															/>
														</Route>
														<Route path="/login" element={<Login />} />
														<Route path="/forgot-password" element={<ForgotPassword />} />
														<Route path="/new-password" element={<CreateNewPassword />} />
													</Routes>
												</Suspense>
											</DashboardInjectorProvider>
										</DashboardPLProvider>
									</DashboardSCTProvider>
								</DashboardPlottingProvider>
							</DataGasLiftProvider>
						</DashboardDCAProvider>
					</DashboardProvider>
				</UserProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
};
