import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";
import {
	postTuningAdd,
	getEspecificTuning,
	putTuningUpdate,
	deleteTuning,
	postTuningCalculateVLP,
	putTuningCalculateVLP,
	getTuningCalculateVLP,
	getUnTunedCalculateVLP,
	getPressDrowDown,
	getListDataChartVLP,
	getDataChartVLP,
	postDataChartVLP,
	deleteScenarioChartVLP,
	deleteScenariosChartVLP,
} from "../services/api/tuningAPI";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { AuthContext } from "../auth/context/AuthContext";
import { DataGasLiftContext } from "../context/DataGasLift/DataGasLiftContext";
import { useTabGaslift } from "./useTabGaslift";
import {
	GAS_LIFT,
	STATUS_CODE_VLP,
} from "../components/elements/types/type_response_API";

export const useTabTuning = () => {
	const { User } = useContext(AuthContext);
	const { userDataInfo, getSelectedWell, selectedWell } =
		useContext(DashboardContext);
	const {
		dataFilterUserGasLift,
		stateMessageApis,
		getStateMessageAlert,
		getViewLoadingGasLift,
		viewLoadingGasLift,
	} = useContext(DataGasLiftContext);
	const { refetchDataTuning } = useTabGaslift();
	const [enableCalculatedMse, setEnableCalculatedMse] = useState(false);
	const [optionSelectedScenario, setOptionSelectedScenario] = useState();
	const [enabledDataTuning, setEnabledDataTuning] = useState(false);

	const {
		data: queryDataTuning,
		isLoading: loadDataTuning,
		isError: errorDataTuning,
		refetch: refetchTuning,
	} = useQuery({
		queryKey: ["TuningData"],
		retry: 1,
		enabled: enabledDataTuning,
		queryFn: () =>
			getEspecificTuning(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			setEnabledDataTuning(false);
		},
		onError: () => {
			setEnabledDataTuning(false);
		},
	});

	const mutationCreateDataTuning = useMutation({
		mutationFn: postTuningAdd,
		onSuccess: (data) => {
			if (
				data?.statusType === GAS_LIFT.VLP.DATA.TYPE.SUCCESS &&
				stateMessageApis.vlp
			) {
				message.success(`VLP data uploaded Successfull`);
				refetchDataTuning();
			} else {
				message.error(`An error occurred while created VLP data`);
				getViewLoadingGasLift({
					...viewLoadingGasLift,
					loadSaveParameters: false,
				});
				getStateMessageAlert({ ...stateMessageApis, vlp: false });
			}
		},
		onError: (error) => {
			console.log(error);
			message.error(`An error has occurred at the time of create`);
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
			getStateMessageAlert({ ...stateMessageApis, vlp: false });
		},
	});

	const mutationUpdateDataTuning = useMutation({
		mutationFn: putTuningUpdate,
		onSuccess: () => {
			if (stateMessageApis.vlp) {
				message.success(`Data Update Successfull`);
				getViewLoadingGasLift({
					...viewLoadingGasLift,
					loadSaveParameters: false,
				});
				getStateMessageAlert({ ...stateMessageApis, vlp: false });
			}
			refetchDataTuning();
		},
		onError: (error) => {
			console.log(error);
			message.error(`An error has occurred at the time of update`);
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
			getStateMessageAlert({ ...stateMessageApis, vlp: false });
		},
	});

	const mutationDeleteDataTuning = useMutation({
		mutationFn: deleteTuning,
		onSuccess: () => {
			message.success(`Data Tuning Delete Successfull`);
		},
		onError: (error) => {
			console.log(error);
			message.error(`An error has occurred at the time of delete`);
		},
	});

	const mutationCreateCalTuningVLP = useMutation({
		mutationFn: postTuningCalculateVLP,
		onSuccess: () => {
			message.success(`Data Done Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when done the Data for calculate VLP`);
		},
	});

	const mutationUpdateCalTuningVLP = useMutation({
		mutationFn: putTuningCalculateVLP,
		onSuccess: () => {
			message.success(`Data Update Successfull`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when update the Data for calculate VLP`,
			);
		},
	});

	const {
		data: queryCalculateTuningVLP,
		isLoading: loadDataCalculateTuningVLP,
		isError: errorDataCalculateTuningVLP,
		refetch: refetchCalculateTuningVLP,
	} = useQuery({
		queryKey: ["TuningCalculateVLP"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getTuningCalculateVLP(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			//Crear la variable de estado para setear el calculate pressure
			if (data.statusType !== STATUS_CODE_VLP.STATUSTYPE_ERROR) {
				setEnableCalculatedMse(true);
				if (stateMessageApis) {
					message.success(`Data query Successfull`);
					getStateMessageAlert({ ...stateMessageApis, vlp: false });
				}
			}
		},
		onError: (error) => {},
	});

	const {
		data: queryCalculateUntunedVLP,
		isLoading: loadDataCalculateUntunedVLP,
		isError: errorDataCalculateUntunedVLP,
		refetch: refetchCalculateUntunedVLP,
	} = useQuery({
		queryKey: ["UntunedCalculateVLP"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getUnTunedCalculateVLP(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			//Crear la variable de estado para setear el calculate pressure
			if (data.statusType !== STATUS_CODE_VLP.STATUSTYPE_ERROR) {
				setEnableCalculatedMse(true);
				if (stateMessageApis) {
					message.success(`Data Processed Successfull`);
				}
			}
		},
		onError: () => {},
	});

	const {
		data: queryPressDrowDown,
		isLoading: loadDataPressDrowDown,
		isError: errorDataPressDrowDown,
		refetch: refetchPressDrowDown,
	} = useQuery({
		queryKey: ["DrowDown"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getPressDrowDown(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			//Crear la variable de estado para setear el calculate pressure
			if (data.statusType !== STATUS_CODE_VLP.STATUSTYPE_ERROR) {
				if (stateMessageApis) {
					message.success(`Data Processed Successfull`);
				}
			}
		},
		onError: () => {},
	});

	const {
		data: queryDataChartVLP,
		isLoading: loadQueryDataChartVLP,
		isError: errorQueryDataChartVLP,
		refetch: refetchQueryDataChartVLP,
	} = useQuery({
		queryKey: ["query_data_chart_VLP"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getDataChartVLP(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				optionSelectedScenario,
			),
		onSuccess: (data) => {
			// message.success(`Data Processed Successfull`);
		},
		onError: () => {
			message.error(`Error fetching stage data`);
		},
	});

	const {
		data: queryListChartVLP,
		isLoading: loadQueryListChartVLP,
		isError: errorQueryListChartVLP,
		refetch: refetchQueryListChartVLP,
	} = useQuery({
		queryKey: ["query_list_chart_VLP"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getListDataChartVLP(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			message.success(`Data Processed Successfull`);
		},
		onError: () => {},
	});

	const mutationDataChartVLP = useMutation({
		mutationFn: postDataChartVLP,
		onSuccess: () => {
			message.success(`Data chart VLP Successfull`);
			refetchQueryListChartVLP();
		},
		onError: (error) => {
			// message.error( `An error has occurred when post the Data chart VLP` );
		},
	});

	const mutationDeleteScenerioChartVLP = useMutation({
		mutationFn: deleteScenarioChartVLP,
		onSuccess: () => {
			message.success(`Data scenario Delete Successfull`);
			refetchQueryListChartVLP();
		},
		onError: (error) => {
			message.error(`An error has occurred at the time of delete`);
		},
	});

	const mutationDeleteSceneriosChartVLP = useMutation({
		mutationFn: deleteScenariosChartVLP,
		onSuccess: () => {
			message.success(`Data scenarios Delete Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred at the time of delete`);
		},
	});

	const [maxDepth, setMaxDepth] = useState(0);
	const [minDepth, setMinDepth] = useState(0);
	const [maxPressure, setMaxPressure] = useState(0);
	const [minPressure, setMinPressure] = useState(0);

	const calculateRangesGraphVLP = (
		axisXGraph1,
		axisYGraph1,
		axisXGraph2,
		axisYGraph2,
	) => {
		if (!!axisYGraph1 && !!axisXGraph2 && !!axisXGraph2 && !!axisYGraph2) {
			const maxD = Math.max(...axisYGraph1, ...axisYGraph2);
			const roundedMaxD = Math.ceil(maxD);
			const maxP = Math.max(...axisXGraph1, ...axisXGraph2);
			const roundedMaxP = Math.ceil(maxP / 100) * 100;

			const minD = Math.min(...axisYGraph1, ...axisYGraph2);
			const roundedMinD = Math.floor(minD);
			const minP = Math.min(...axisXGraph1, ...axisXGraph2);
			const roundedMinP = Math.floor(minP / 100) * 100;

			setMaxDepth(roundedMaxD, roundedMinP);
			setMaxPressure(roundedMaxP);
			setMinDepth(roundedMinD);
			setMinPressure(0);
		} else {
			setMaxDepth(0);
			setMinDepth(0);
			setMaxPressure(0);
			setMinPressure(0);
		}
	};

	return {
		maxDepth,
		minDepth,
		maxPressure,
		minPressure,
		mutationUpdateDataTuning,
		mutationDeleteDataTuning,
		loadDataTuning,
		errorDataTuning,
		getSelectedWell,
		selectedWell,
		dataFilterUserGasLift,
		queryDataTuning,
		queryCalculateTuningVLP,
		queryCalculateUntunedVLP,
		queryPressDrowDown,
		mutationCreateCalTuningVLP,
		mutationUpdateCalTuningVLP,
		mutationCreateDataTuning,
		enableCalculatedMse,
		queryDataChartVLP,
		queryListChartVLP,
		mutationDataChartVLP,
		mutationDeleteScenerioChartVLP,
		mutationDeleteSceneriosChartVLP,
		refetchTuning,
		refetchCalculateTuningVLP,
		refetchCalculateUntunedVLP,
		refetchPressDrowDown,
		refetchQueryDataChartVLP,
		refetchQueryListChartVLP,
		setEnabledDataTuning,
		setEnableCalculatedMse,
		setOptionSelectedScenario,
		calculateRangesGraphVLP,
	};
};
