import React from 'react'
import { DataGasLiftContext } from './DataGasLiftContext'
import { useDataGasLift } from '../../hooks/useDataGasLift'

export const DataGasLiftProvider = ({ children }) => {
  const {
    dataCreated,
    dataFilterUserGasLift,
    dataWellTables,
    dataWell,
    dataFluid,
    datReservoir,
    enableCheckLab,
    lastSelectDataIPR,
    getDataCreated,
    getDataFilterUserGaslift,
    getDataWellTables,
    getDataWell,
    getDataFluid,
    getDataReservoir,
    getEnableCheckLab,
    handleSelectWellGaslift,
    getSelectDataIPR
  } = useDataGasLift()
  
  return (
    <DataGasLiftContext.Provider value={{
      dataCreated,
      dataFilterUserGasLift,
      dataWellTables,
      dataWell,
      dataFluid,
      datReservoir,
      enableCheckLab,
      lastSelectDataIPR,
      getDataCreated,
      getDataFilterUserGaslift,
      getDataWellTables,
      getDataWell,
      getDataFluid,
      getDataReservoir,
      getEnableCheckLab,
      handleSelectWellGaslift,
      getSelectDataIPR
    }}>
      {children}
    </DataGasLiftContext.Provider>
  )
}
