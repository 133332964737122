import React, { useState, useEffect, useContext, useRef } from 'react'
import ResetIcon from '../../assets/icon/reset.png';
import { ReactComponent as EditIcon } from '../../assets/icon/edit.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand1.svg';
import ChartAIDCA from './GraphicsDashboard/ChartAIDCA/ChartAIDCA';
import { Modal } from 'antd';
import { useDashboardDCAGraphics } from '../../hooks/useDashboardDCAGraphics';
import { DashboardDCAContext } from '../../context/DasboardDCA/DashboardDCAContext';
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';

export const FrameGraph1 = (props) => {
    const { open, onCloseModal, dataHistory, dataIOT, typeDevice, dataForecastDCA, statusGraph} = props;
    const [configModalRef, setConfigModalRef] = useState('Oil'); 
    const [selectedOption, setSelectedOption] = useState('Oil'); 
    const [openModal, setOpenModal] = useState(false)
    const [enableModal, setEnabledModal] = useState(false)
    const {checkTypeGraph, getCheckTypeGraph} = useContext(DashboardContext)
    const {dataFilterUserDCA} = useContext(DashboardDCAContext)
    const  arrayDataHistory = dataHistory?.statusType === "SUCCESS" && dataFilterUserDCA != null ? dataHistory?.data.data : null;
    
    useEffect(() => {
        setOpenModal(open)
        if(open){
            setEnabledModal(true);
        }
    }, [open])

    // Función para manejar el cambio de opción
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    }; 
    //Data quemada
    // const { dataProduction } = useDashboardDCAGraphics();

    const handleTypeGraph = (event, name) => {
        if( checkTypeGraph.forecasting[name] === true){
            getCheckTypeGraph({...checkTypeGraph, forecasting: {...checkTypeGraph.forecasting, [name]: false}})

        } else{
            getCheckTypeGraph({...checkTypeGraph, forecasting: {...checkTypeGraph.forecasting, [name]: true}})
        }
    };
    
    return (
        <>
            <style>
                {`
                /* Estilo para el input no seleccionado */
                .unselectedInput {
                    border: 2px solid #ccc; /* Estilo de borde cuando no está seleccionado */
                }

                /* Estilo para el input seleccionado */
                .selectedInput {
                    border: 2px solid ${selectedOption === 'Oil' ? '#97FF8F' : '#ff0000'}; /* Estilo de borde según el selectedOptions */
                    background-color: ${selectedOption === 'Oil' ? '#97FF8F' : '#ff0000'}; /* Color de fondo según el selectedOptions */
                    padding: 3px; 
                    background-clip: content-box; /* Colorea solo el área interna del contenido, excluyendo el padding */
                }
                .selectedGraph {
                    border: 2px solid #662D91; /* Estilo de borde según el selectedOptions */
                    background-color: #662D91; /* Color de fondo según el selectedOptions */
                    padding: 3px; 
                    background-clip: content-box; /* Colorea solo el área interna del contenido, excluyendo el padding */
                }
                `}
            </style>
            <div className='flex flex-row w-full h-[50px] '>
                <div className='flex flex-row w-2/6 h-full items-center text-right justify-end gap-2'>
                    <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${selectedOption === 'Oil' ? 'selectedInput' : 'unselectedInput'} cursor-pointer`}
                        type="checkbox"
                        checked={selectedOption === 'Oil'}
                        onChange={() => handleOptionChange({ target: { value: 'Oil' } })}
                    />
                    <label htmlFor="" className='text-bc-gray text-[10px]'>Oil</label>
                    <div className='w-[20px] h-[5px]' style={{ background: '#97FF8F' }}></div>
                </div>
                <div className='flex flex-row w-[20%] h-full items-center text-right justify-end gap-2'>
                    <input
                        className={`ml-1 w-5 h-5 appearance-none rounded-full ${selectedOption === 'Gas' ? 'selectedInput' : 'unselectedInput'} cursor-pointer`}
                        type="checkbox"
                        checked={selectedOption === 'Gas'}
                        onChange={() => handleOptionChange({ target: { value: 'Gas' } })}
                    />
                    <label htmlFor="" className='text-bc-gray text-[10px]'>Gas</label>
                    <div className='w-[20px] h-[5px]' style={{ background: '#ff0000' }}></div>
                </div>
            </div> 
            <div className='flex w-full h-full bg-white '>
                <ChartAIDCA option={selectedOption} y={305} bottom={50} dataHistory={arrayDataHistory} dataIOTDCA={dataIOT} typeDevice={typeDevice} enableModal={enableModal} dataForcast={dataForecastDCA} typeGraph={statusGraph}></ChartAIDCA>
            </div>
            <Modal
                title="DCA"
                centered
                open={openModal}
                onOk={() => {
                    setEnabledModal(false);
                    setOpenModal(false);
                    onCloseModal(1)
                }
                }
                okButtonProps={{
                    className: "bg-[#662D91]",
                  }}
                onCancel={() => {
                    setEnabledModal(false);
                    setOpenModal(false);
                    onCloseModal(1); // Aquí llamamos a la función para cerrar el modal en Dashboard
                }}
                width={1000}
            >
                <div className='h-[600px] pb-6'>
                    <div className='flex flex-row w-full h-[40px] justify-center'>
                        <div className='flex flex-row w-[20%] h-full items-center text-right justify-center gap-2'>
                            <input
                                className={`ml-1 w-5 h-5 appearance-none rounded-full ${!statusGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                                type="checkbox"
                                checked={statusGraph}
                                onChange={(event) => handleTypeGraph(event, "dcaGraph")}
                            />
                            <label htmlFor="" className='text-bc-gray text-[14px]'>Historical Data + RT avg</label>
                        </div>
                        <div className='flex flex-row w-[20%] h-full items-center text-right justify-center gap-2'>
                            <input
                                className={`ml-1 w-5 h-5 appearance-none rounded-full ${statusGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                                type="checkbox"
                                checked={statusGraph}
                                onChange={(event) => handleTypeGraph(event, "dcaGraph")}
                            />
                            <label htmlFor="" className='text-bc-gray text-[14px]'>Real Time Data</label>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 h-[3%]'>
                        <div className='flex flex-row w-[50%]  items-center text-right justify-end gap-2'>
                            <input
                                className={`ml-1 w-5 h-5 appearance-none rounded-full ${selectedOption === 'Oil' ? 'selectedInput' : 'unselectedInput'} cursor-pointer`}
                                type="checkbox"
                                checked={selectedOption === 'Oil'}
                                onChange={() => handleOptionChange({ target: { value: 'Oil' } })}
                            />
                            <label htmlFor="" className='text-pc-grey text-[10px]'>Oil</label>
                            <div className='w-[20px] h-[5px]' style={{ background: '#97FF8F' }}></div>
                        </div>
                        <div className='flex flex-row w-[50%] items-center text-right justify-end gap-2'>
                            <input
                                className={`ml-1 w-5 h-5 appearance-none rounded-full ${selectedOption === 'Gas' ? 'selectedInput' : 'unselectedInput'} cursor-pointer`}
                                type="checkbox"
                                checked={selectedOption === 'Gas'}
                                onChange={() => handleOptionChange({ target: { value: 'Gas' } })}
                            />
                            <label htmlFor="" className='text-pc-grey text-[10px]'>Gas</label>
                            <div className='w-[20px] h-[5px]' style={{ background: '#ff0000' }}></div>
                        </div>
                    </div> 
                    <ChartAIDCA option={selectedOption} y={305} bottom={50} dataHistory={arrayDataHistory} dataIOTDCA={dataIOT} typeDevice={typeDevice}  enableModal={enableModal} dataForcast={dataForecastDCA} typeGraph={statusGraph}></ChartAIDCA>
                </div>
            </Modal>
        </>
    );
}