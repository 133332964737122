export const STATUS_CODE_VLP = {
	SUCCESS: 200044,
	ERROR: 101010,
	ERROR_CALCULATE: 500201,
	STATUSTYPE_SUCCESS: "SUCCESS",
	STATUSTYPE_ERROR: "ERROR",
};
export const STATUS_CODE_UNTUNED_VLP = {
	SUCCESS: 200044,
	ERROR: 101010,
	ERROR_CALCULATE: 500201,
	STATUSTYPE_SUCCESS: "SUCCESS",
	STATUSTYPE_ERROR: "ERROR",
};
export const STATUS_DATA_VLP = {
	SUCCESS: 200075,
	ERROR: 101010
};
export const STATUS_DATA_CHART_VLP = {
	SUCCESS: 200044,
	ERROR: 500201,
	STATUSTYPE_SUCCESS: "SUCCESS",
	STATUSTYPE_ERROR: "ERROR",
};

export const STATUS_CODE_FLOW = {
	SUCCESS: 200044,
	ERROR: 101010
};
export const STATUS_CODE_FLOW_GRAPH = {
	SUCCESS: 200044,
	ERROR: 500201
};

export const STATUS_CODE_DCA_TABLES = {
	SUCCESS: 200090,
	ERROR: 101010
};
export const STATUS_DATA_DCA = {
	SUCCESS: 200075,
	ERROR: 101010
};
export const STATUS_TYPE_WELL_DCA = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};

export const STATUS_DATA_INJECTOR = {
	SUCCESS: 200075,
	ERROR: 101010
};
export const STATUS_LIST_INJECTOR = {
	SUCCESS: 200075,
	ERROR: 400077
};
export const STATUS_TYPE_INJECTOR = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};

export const STATUS_DATA_GAS_LIFT = {
	SUCCESS: 200075,
	ERROR: 101010
};
export const STATUS_LIST_GAS_LIFT = {
	SUCCESS: 200075,
	ERROR: 400077
};
export const STATUS_CODE_WELL_GASLIFT = {
	SUCCESS: 200075,
	ERROR: 101010
};
export const STATUS_TYPE_WELL_GASLIFT = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};

export const STATUS_CODE_CALCULATE_IPR = {
	SUCCESS: 200044,
	ERROR: 500201
};
export const STATUS_TYPE_CALCULATE_IPR = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};

export const STATUS_TYPE_CALCULATE_PVT = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};
export const STATUS_CODE_CALCULATE_PVT = {
	SUCCESS: 200044,
	ERROR: 500001,
	ERROR_CALC: 500201,
};

export const STATUS_TYPE_DATA_IOT = {
	SUCCESS: 200077,
	ERROR: 101010,
	STATUSCODE: 401001,
};
export const STATUS_DATA_HISTORY = {
	STATUSCODE: 500201,
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};
export const STATUS_DATA_HISTORY_GAS_LIFT = {
	STATUSCODE: 200044,
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

export const STATUS_CODE_SCREENING_TOOL = {
	SUCCESS: 200044,
	ERROR: 500201
};
export const STATUS_TYPE_SCREENING_TOOL = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

export const STATUS_CODE_PLUNGER_LIFT = {
	SUCCESS: 200075,
	ERROR: 400077
};
export const STATUS_TYPE_PLUNGER_LIFT = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
};
export const STATUS_TYPE_SCENERY_PLUNGER_LIFT = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};
export const STATUS_CODE_GET_INTERPOLATION_PLUNGER_LIFT = {
	SUCCESS: 200044,
	ERROR: 400077,
};
export const STATUS_TYPE_GET_INTERPOLATION_PLUNGER_LIFT = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

export const STATUS_TYPE_PLOTTING = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
}

const CODE_RESPONSE = {
	SUCCESS: [200075, 200076, 200044, 200077, 200090, 200089],
	ERROR: [400077, 500201, 500001, 500000, 401001, 20044, 101010],
};

const MESSAGE_RESPONSE = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

export const STATUS_TYPE_GET_GRAPH_3D_GASLIFT = {
	SUCCESS: 200044,
	ERROR: 500201
}

export const GAS_LIFT = {
	// AVAILABE FOR CRUD
	DATA_WELL: {
		CODE: {
			SUCCESS: CODE_RESPONSE.SUCCESS[0],
			ERROR: CODE_RESPONSE.ERROR[0],
		},
		TYPE: {
			SUCCESS: MESSAGE_RESPONSE.SUCCESS,
			ERROR: MESSAGE_RESPONSE.ERROR,
		},
	},
	LIST_WELL: {
		CODE: {
			SUCCESS: CODE_RESPONSE.SUCCESS[0],
			ERROR: CODE_RESPONSE.ERROR[0],
		},
		TYPE: {
			SUCCESS: MESSAGE_RESPONSE.SUCCESS,
			ERROR: MESSAGE_RESPONSE.ERROR,
		},
	},
	PVT: {
		POST_CALCULATE: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[2],
				ERROR: CODE_RESPONSE.ERROR[1],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
		GET_CALCULATE: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[2],
				ERROR: CODE_RESPONSE.ERROR[2],
				// DIFFERENT CODE RESPONSE ABOUT CALCULATED
				ERROR_BO: CODE_RESPONSE.ERROR[1],
				ERROR_CO: CODE_RESPONSE.ERROR[3],
				ERROR_VISO: CODE_RESPONSE.ERROR[1],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
	},
	// AVAILABE FOR CALCULATED AND DATA
	DATA_IPR: {
		CODE: {
			SUCCESS: CODE_RESPONSE.SUCCESS[2],
			ERROR: CODE_RESPONSE.ERROR[1],
		},
		TYPE: {
			SUCCESS: MESSAGE_RESPONSE.SUCCESS,
			ERROR: MESSAGE_RESPONSE.ERROR,
		},
	},
	VLP: {
		// "DATA" AVAILABE FOR CRUD VLP AND LIST
		DATA: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[0],
				ERROR: CODE_RESPONSE.ERROR[0],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
		POST_PUT_CALCULATE: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[2],
				ERROR: CODE_RESPONSE.ERROR[1],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
		GET_CALCULATE: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[2],
				ERROR: CODE_RESPONSE.ERROR[1],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
	},
	FLOW: {
		// APPLY POST AND GET DATA
		CALCULATE: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[2],
				ERROR: CODE_RESPONSE.ERROR[1],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
	},
	DATA_HISTORY: {
		DATA: {
			CODE: {
				SUCCESS: CODE_RESPONSE.SUCCESS[2],
				// PARTICULAR CASE FOR LIST, GET Y POST DATA HISTORY BECAUSE RESPONSE IS ONLY SPANISH MESSAGE WITHOUT STRUCTURE
				ERROR: CODE_RESPONSE.ERROR[5],
			},
			TYPE: {
				SUCCESS: MESSAGE_RESPONSE.SUCCESS,
				ERROR: MESSAGE_RESPONSE.ERROR,
			},
		},
	},
	DATA_SCENARIOS: {
		// SAME FOR VLP AND IPR
		CODE: {
			SUCCESS: CODE_RESPONSE.SUCCESS[2],
			ERROR: CODE_RESPONSE.ERROR[1],
		},
		TYPE: {
			SUCCESS: MESSAGE_RESPONSE.SUCCESS,
			ERROR: MESSAGE_RESPONSE.ERROR,
		},
	},
	DATA_GRAPHICS: {
		// SAME FOR GRAPHICS DASHBOARD
		TYPE: {
			SUCCESS: MESSAGE_RESPONSE.SUCCESS,
			ERROR: MESSAGE_RESPONSE.ERROR,
		},
	},
};
export const MULTI_WELL = {
	CODE: {
		SUCCESS: CODE_RESPONSE.SUCCESS[2],
		ERROR: CODE_RESPONSE.ERROR[1],
	},
	TYPE: {
		SUCCESS: MESSAGE_RESPONSE.SUCCESS,
		ERROR: MESSAGE_RESPONSE.ERROR,
	},
};

export const FILTER_USER = {
	CODE: {
		SUCCESS: CODE_RESPONSE.SUCCESS[5],
		ERROR: "",
	},
	TYPE: {
		SUCCESS: MESSAGE_RESPONSE.SUCCESS,
		ERROR: MESSAGE_RESPONSE.ERROR,
	},
};
