export const DATA_ALERTS = {
	gas_lift: {
		valuesAlert: {
			pc_gas_rate: false,
			twc_temp: false,
			pc_oil_rate: false,
			pc_water_rate: false,
			twc_pressure: false,
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			em_eur: false,
			em_npv: false,
			awc_pressure: false,
			awc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pc_gor: false,
			pc_wor: false,
		},
		valuesCheck: {
			pc_gas_rate: false,
			twc_temp: false,
			pc_oil_rate: false,
			pc_water_rate: false,
			twc_pressure: false,
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			em_eur: false,
			em_npv: false,
			awc_pressure: false,
			awc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pc_gor: false,
			pc_wor: false,
		},
		countAlert: 0,
		countCheck: 0,
	},
	forecasting: {
		valuesAlert: {
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			wc_pressure: false,
			wc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pp_oil_rate: false,
			pp_water_rate: false,
			pp_gas_rate: false,
			pp_wor: false,
			pp_water_cute: false,
			pp_gor: false,
		},
		valuesCheck: {
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			wc_pressure: false,
			wc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pp_oil_rate: false,
			pp_water_rate: false,
			pp_gas_rate: false,
			pp_wor: false,
			pp_water_cute: false,
			pp_gor: false,
		},
		countAlert: 0,
		countCheck: 0,
	},
	plunger_lift: {
		valuesAlert: {
			alm_GLRrequired: false,
			alm_GLRproduced: false,
			alm_casing_pressure_required: false,
			alm_casing_pressure_available: false,
			alm_cycle_day: false,
			twc_Pressure: false,
			twc_temperature: false,
			em_npv: false,
			em_roi: false,
			cwc_Pressure: false,
			cwc_temperature: false,
			pic_Pressure: false,
			pic_temperature: false,
			prc_GasRate: false,
			prc_LiquidRate: false,
			prc_Glr: false,
			prc_GasVolumePer: false,
			prc_LiquidVolumPer: false,
		},
		valuesCheck: {
			alm_GLRrequired: false,
			alm_GLRproduced: false,
			alm_casing_pressure_required: false,
			alm_casing_pressure_available: false,
			alm_cycle_day: false,
			twc_Pressure: false,
			twc_temperature: false,
			em_npv: false,
			em_roi: false,
			cwc_Pressure: false,
			cwc_temperature: false,
			pic_Pressure: false,
			pic_temperature: false,
			prc_GasRate: false,
			prc_LiquidRate: false,
			prc_Glr: false,
			prc_GasVolumePer: false,
			prc_LiquidVolumPer: false,
		},
		countAlert: 0,
		countCheck: 0,
	},
	Injector: {
		valuesAlert: {
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			wc_pressure: false,
			wc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pp_oil_rate: false,
			pp_water_rate: false,
			pp_gas_rate: false,
			pp_wor: false,
			pp_water_cute: false,
			pp_gor: false,
		},
		valuesCheck: {
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			wc_pressure: false,
			wc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pp_oil_rate: false,
			pp_water_rate: false,
			pp_gas_rate: false,
			pp_wor: false,
			pp_water_cute: false,
			pp_gor: false,
		},
		countAlert: 0,
		countCheck: 0,
	},
};

export const DATA_SELECTED_DEVICE_GRAPH = {
	gas_lift: {
		chartProdHistory: "",
		chartWellCond: "",
		chartHole: "",
	},
	forecasting_: {
		chartProdHistory: "",
		chartWellCond: "",
		chartHole: "",
	},
};

export const INITIAL_TYPE_GRAPH = {
	forecasting: {
		dcaGraph: false,
		production_history: false,
	},
	gas_lift: {
		wellhead_production: false,
		wellhead_injection: false,
		production_history: false,
	},
	plunger_lift: {
		wellhead_production: false,
		production_history: false,
	},
	injector: {
		wellhead_injection: false,
		production_history: false,
	},
};

export const WELL_SENSOR_DEVICE = {
	data: [
		{
			discretized: false,
			name: "SMI-27",
			sensor: "SMI-27 IP Separator",
		},
		{
			discretized: false,
			name: "SMI-27",
			sensor: "SMI-27 LP Separator",
		},
		{
			discretized: false,
			name: "SMI-27",
			sensor: "SMI-27 Sales Gas Meter",
		},
		{
			discretized: false,
			name: "SMI-27",
			sensor: "SMI-27 Fuel Gas Meter",
		},
		{
			discretized: false,
			name: "SMI-27",
			sensor: "SMI-27 LACT",
		},
		{
			discretized: true,
			name: "V-191",
			sensor: "V-191 Test Separator",
		},
		{
			discretized: true,
			name: "V-191",
			sensor: "V-191 Well Head Pressure",
		},
	],
};

export const PLATFORM_REAL_TIME = {
	forecasting: {
		platform: "",
		discretized: false,
		name: "",
		selected_platform_graph: {
			chartProdHistory: null,
			chartWellCond: null,
			chartHole: null,
		},
		selected_device_graph: {
			chartProdHistory: null,
			chartWellCond: null,
			chartHole: null,
		},
	},
	gas_lift: {
		platform: "",
		discretized: false,
		selected_platform_graph: {
			chartProdHistory: null,
			chartWellCond: null,
			chartHole: null,
		},
		selected_device_graph: {
			chartProdHistory: null,
			chartWellCond: null,
			chartHole: null,
		},
	},
	plunger_lift: {
		platform: "",
		discretized: false,
		name: "",
		selected_platform_graph: {
			chartProdHistory: null,
			chartWellCond: {
				gas_rate: null,
				casing_pressure: null,
				tubing_pressure: null,
				pipeline_pressure: null,
			},
		},
		selected_device_graph: {
			chartProdHistory: null,
			chartWellCond: {
				gas_rate: null,
				casing_pressure: null,
				tubing_pressure: null,
				pipeline_pressure: null,
			},
		},
	},
};

export const VALUES_TYPE_DATA_IOT = {
	corrected_accumulated_volume: "Corrected Accumulated Volume",
	corrected_current_day_volume: "Corrected Current Day Volume",
	corrected_flow_rate: "Corrected Flow Rate",
	corrected_previous_day_volume: "Corrected Previous Day Volume",
	date: "Date (MM/DD/YYYY)",
	direffential_pressure: "Differential Pressure",
	flowing_temperature: "Flowing Temperature",
	gas_flow_rate: "Gas Flow Rate",
	LSD: "LSD",
	liquid_flow_rate: "Liquid Flow Rate",
	liquid_previous_day_total: "Liquid Previous Day Total",
	meter_1_current_day_volume: "Meter 1 Current Day Volume",
	meter_1_flow_rate: "Meter 1 Flow Rate",
	meter_1_net_accumulated_volume: "Meter 1 Net Accumulated Volume",
	meter_1_previous_day_volume: "Meter 1 Previous Day Volume",
	meter_2_current_day_volume: "Meter 2 Current Day Volume",
	meter_2_flow_rate: "Meter 2 Flow Rate",
	meter_2_et_accumulated_volume: "Meter 2 Net Accumulated Volume",
	meter_2_previous_day_volume: "Meter 2 Previous Day Volume",
	separator_pressure: "Separator Pressure",
	static_pressure: "Static Pressure",
};
//Este es el filtro del pozo
export const WELL_FILTER = {
	county: "",
	field: "",
	well_id: "",
	well_platforms: "",
	well_name: "",
};
//Este es el filtro del usuario
export const USER_FILTER = {
	operator: "",
	lease: "",
	region: ""
};
export const ROL_NAME = {
	simple_user: "Simple User",
	master_user: "Master"
}
