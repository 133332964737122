import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_LIST = config.API.MAP.DB_URL_LIST_MAP;
const DB_URL_ALERTS = config.API.MAP.DB_URL_ALERT_MAP;
const TOKEN_DCA = config.AUTH.TOKEN_DCA;
const TOKEN_GAS_LIFT = config.AUTH.TOKEN_GAS_LIFT;
const TOKEN_PLUNGER_LIFT = config.AUTH.TOKEN_PLUNGER_LIFT;
const TOKEN_INJECTOR = config.AUTH.TOKEN_INJECTOR;
const TOKEN_ALERTS = config.AUTH.TOKEN_ALERTS;

export const getListDCA = async (operator, lease, region, userName) => {
	if (!validateParams(operator, lease, region, userName)) return;

	try {
		const response = await fetch(
			`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${userName}/parametersdca`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_DCA,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the DCA list");
	}
};

export const getListGasLift = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the Gas Lift list");
	}
};

export const getListPlungerLift = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${username}/parametersplungerlift`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the Gas Lift list");
	}
};

export const getListInjector = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${DB_URL_LIST}/parameterslist/${operator}/${lease}/${region}/${username}/parametersinjector`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_INJECTOR,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the Gas Lift list");
	}
};

export const getAlerts = async (typeProcess, operator, lease, region, user) => {
	if (!validateParams(typeProcess, operator, lease, region, user)) return;

	try {
		const response = await fetch(
			`${DB_URL_ALERTS}/alerts/${typeProcess}/${operator}/${lease}/${region}/${user}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_ALERTS,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting alerts");
	}
};
