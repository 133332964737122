import React, { useState } from 'react'
import { ReactComponent as IconEdit } from "../../../assets/icon/edit.svg";
import { ReactComponent as IconDelete } from "../../../assets/icon/x-circle.svg";
import { Pagination } from 'antd';

export const CrudLease = ({dataList}) => {
  const [selectedItemCrud, setSelectedItemCrud] = useState();
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const totalItems = dataList ? dataList.length : 0;
  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;
  const handleSelectItem = (elemento, caso) => {
    setSelectedItemCrud(elemento);
    caso === "Editar" ? setModalEditar(true) : setModalEliminar(true);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col h-full w-full p-5">
        <div className="flex flex-col w-full border border-solid text-left">
          <table>
            <thead className="h-[30px] w-full text-left bg-zinc-800 text-white">
              <tr>
                <th className="pl-[20px]">Id Operator</th>
                <th>Id Lease</th>
                <th>Lease</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {dataList?.slice(startIdx, endIdx).map((item) => (
                <tr key={item.idproyect}>
                  <td className="pl-[20px]">{item.clientId}</td>
                  <td>{item.idproyect}</td>
                  <td>{item.proyect}</td>
                  <td className="text-center">
                    <IconEdit className="w-6 h-[47px] fill-yellow-400 cursor-pointer" onClick={() =>
                      handleSelectItem(item, "Editar")
                    } />
                  </td>
                  <td className="text-center">
                    <IconDelete className="w-6 h-[47px] fill-red-600 cursor-pointer" onClick={() =>
                      handleSelectItem(item, "Eliminar")
                    } />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row max-w-[1500px] w-full h-full">
          <div className="flex flex-row w-full ml-[20px] mr-[20px] mt-[10px]">
            <div className="flex flex-col w-full ml-[20px] text-start">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
