import { Select } from "antd";
import React, { useEffect, useState } from 'react';
import { useUsers } from "../../hooks/useUsers";
import { Loading } from "../Loading";
import { FormUser } from '../User_Control/FormUser';


export default function ModalActu({ visible, onClose, user, table, typeUser }) {
    const { Option } = Select;
    const [wellboreOptionState, setWellboreOptionState] = useState(false);
    const wellboreOption = () => {
        setWellboreOptionState(!wellboreOptionState);
    };

    //Hooks
    const {
        refetchDataClientsPlatforms,
        refetchDataClientsProjects,
        refetchDataOptionRegions,
        refetchDataOptionLeases,
        idDataClient,
        loadOptionOperators,
        loadOptionRole,
        ErrorOptionOperators,
        ErrorOptionRoles,
        ErrorDataClient,
        ErrorClientsPlatforms,
        ErrorClientsProjects,
        ErrorOptionRegion,
        ErrorOptionLeases,
    } = useUsers();
    //Declaration state
    const [loadPage, setLoadPage] = useState(false);

    useEffect(() => {
        if (idDataClient > 0) {
            try {
                refetchDataClientsPlatforms();
                refetchDataClientsProjects();
            } catch (error) {
                refetchDataOptionLeases();
                refetchDataOptionRegions();
                return console.error({ messageModal: error, status: "El cliente seleccioando no posee informacion relacionada" });
            }
        }
    }, [idDataClient])

    
    if (loadPage) return (<div className="absolute w-full w-hull"><Loading /></div>);
    
    if (loadOptionOperators || loadOptionRole) {
        return <div className="absolute w-full w-hull"><Loading /></div>
    }
    
    if (ErrorOptionOperators || ErrorOptionRoles || ErrorDataClient || ErrorClientsPlatforms || ErrorClientsProjects || ErrorOptionRegion || ErrorOptionLeases) {
        return (
            <div className="absolute w-[300px] h-[300px] bg-white">
                <div className="flex text-center justify-center items-center h-full bg-slate-400 font-sans font-bold ">
                    <h1>Ha ocurrido un error</h1>
                </div>
            </div>
        );
    }
    
    if (!visible) return null;

    return (

      <div
        data-te-modal-init
        className="fixed top-0 bg-nc-gray bg-opacity-30 backdrop-blur-sm flex justify-center items-center w-full h-full p-10"
        aria-labelledby="exampleModalLabel"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none w-auto transition-all duration-300 ease-in-out"
        >
          <div className="flex flex-col w-full h-full pointer-events-auto rounded-md border-none bg-neutral-800 bg-clip-padding text-current shadow-lg outline-none dark:bg-white">
            <div className="flex items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 dark:border-opacity-50 p-5">
              {/* <!--Modal title--> */}
  
              <h5
                className="text-xl font-medium leading-normal text-white dark:text-neutral-200"
                id="exampleModalLabel"
              >
                Edition User
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="h-6 w-6"
                  onClick={onClose}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
  
            {/* <!--Modal body--> */}
            <div className="flex w-full h-[700px] overflow-y-scroll text-center items-center justify-center pt-[100px]" data-te-modal-body-ref>
                <FormUser type={"edit"} user={user} table={table}></FormUser>
            </div>
  
          </div>
        </div>
      </div>
        
    );
}
