import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Checkbox, message, Select, Steps, Switch, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconAdd } from "../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../assets/img/icons/delete.svg";
import { AlertModal } from "../../components/AlertModal";
import { DATA_FLUID, dataCheckPVT, INITIAL_POST_DATA_PVT, INITIAL_POST_PVT_CALC } from "../../components/elements/types/type_gas_lift";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { Loading } from "../../components/Loading";
import { useTabPlungerLift } from '../../hooks/useTabPlungerLift';
import { ChartCalculationPL } from '../../components/PlungerLift/GraphicsFluid/ChartCalculation';
import { TablePVTCalculatePL } from '../../components/PlungerLift/TableFluid/TablePVTCalculate';
import { DATA_PVT_PLUNGER_LIFT } from '../../components/elements/types/type_plunger_Lift';
import { STATUS_CODE_PLUNGER_LIFT } from '../../components/elements/types/type_response_API';
import { NAME_PAGES } from '../../components/elements/types/type_pages';

const steps = [
  {
    title: "Reservoir and Separator",
  },
  {
    title: "PVT Calculations",
  }
];

const customStepNumbers = [1, 2, 3];

const typeGraphFluid = [
  {
    name: "Gas Compressibility Factor",
    value: "gas_compressibility_factor",
  },
  {
    name: "Gas Density",
    value: "gas_density",
  }
];

export const StepMenuFluidPlungerLift = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const {
    User,
    userDataInfo,
    getDataFilterPL,
    dataFilterUserPL,
    refetchDataWellPL,
    dataWellPL,
    mutationUpdateWellPlungerLift,
    mutationDataPVT,
    refetchCalcDataPVT,
    calcDataPVT
  } = useTabPlungerLift();
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [status, setStatus] = useState(false);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [inputFluidInformation, setInputFluidInformation] = useState(DATA_PVT_PLUNGER_LIFT.initial_data_pvt);
  const [dataTableAutomic, setDataTableAutomic] = useState(DATA_PVT_PLUNGER_LIFT.initialAutomaticData);
  const [dataTableUserSelection, setDataTableUserSelection] = useState(DATA_PVT_PLUNGER_LIFT.initialUserSelectionData);
  const [inputDataCalculation, setInputDataCalculation] = useState(DATA_PVT_PLUNGER_LIFT.initialDataCalculation);
  const [current, setCurrent] = useState(0);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [dataInformationEnd, setDataInformationEnd] = useState();
  const [loadingPage, setLoadingPage] = useState(false);
  const [ActiveAxisY, setActiveAxisY] = useState(false);
  const [ActiveChecks, setActiveChecks] = useState(DATA_PVT_PLUNGER_LIFT.stateChecks);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [checkPVT, setCheckPVT] = useState(dataCheckPVT);
  const [dataPoint, setDataPont] = useState([]);
  const [dataTablePVT, setDataTablePVT] = useState(DATA_PVT_PLUNGER_LIFT.initialDataTablePVT);

  useEffect(() => {
    refetchDataWellPL();
  }, []);

  useEffect(() => {
    const selected = checkPVT.find(item => item.check === true);
    const selectedName = selected ? selected.name : null;
    setSelectedCheckbox(selectedName)
  }, [checkPVT]);

  useEffect(() => {
    setTimeout(() => {
      if (dataWellPL) {
        if (dataWellPL.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS) {
          const filterDataCreate = dataWellPL?.data;
          const filterDataEnd = Object.assign({}, inputFluidInformation, { data_point_table: [] }, inputDataCalculation);
          const filteredJson = Object.fromEntries(
            Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
          );
          setDataInformationEnd(filteredJson);
        }
      }
    }, 1000);
  }, [dataWellPL]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleClickNext = () => {
    switch (current) {
      case 0:
        const reservoirKeys = validateKeys("res");
        if (reservoirKeys) {
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert("There are empty fields please or not validate");
          setInputEmpty(true);
          handleStatusChange();
        } else {
          setCurrent(current + 1);
          setInputEmpty(false);
        }
        break;
      default:
        break;
    }
  };


  const handleCalculation = () => {
    setDataTablePVT(DATA_FLUID.initialDataTablePVT);

    //action calculate
    let validateDataNull = false;
    let dataPointSelect = {
      press_calc: [],
      numbers_press_rows: null,
    };
    let temperatureVal = null;

    switch (inputDataCalculation.option_data_point) {
      case "automatic":
        validateDataNull = VerifyingNulls(dataTableAutomic)
        dataPointSelect = {
          press_calc: [dataTableAutomic[0].from_pressure, dataTableAutomic[1].to_pressure],
          numbers_press_rows: dataTableAutomic[2].values_pressure,
        }
        setDataPont(dataPointSelect);
        temperatureVal = dataTableAutomic[0].from_temperature;
        break;
      case "user_selection":
        const validatedDataPress = VerifyingNulls(dataTableUserSelection);
        const validateDataTemp = dataTableUserSelection[0].temperature === "" || dataTableUserSelection[0].temperature === null
        validateDataNull = validatedDataPress && !validateDataTemp
        const pressure = dataTableUserSelection.map(item => item.pressure);
        dataPointSelect = {
          press_calc: pressure,
          numbers_press_rows: null,
        }
        setDataPont(dataPointSelect);
        temperatureVal = dataTableUserSelection[0].temperature;
        break
      default:
        break;
    }

    if ((inputDataCalculation.option_data_point && inputDataCalculation.calculate_correlation && validateDataNull)) {

      const temperatureDataPoint = inputDataCalculation.option_data_point === "automatic" ? dataTableAutomic[0].from_temperature : dataTableUserSelection[0].temperature;
      const pressureDataPoint = inputDataCalculation.option_data_point === "automatic" ? [dataTableAutomic[0].from_pressure, dataTableAutomic[1].to_pressure] : dataTableUserSelection.map(item => item.pressure);

      const uploadDataPVT = {
        sgg_mix: inputFluidInformation.res_gas_gravity,
        yn2: inputFluidInformation.res_N2,
        yco2: inputFluidInformation.res_CO2,
        yh2s: inputFluidInformation.res_H2S,
        temp: temperatureDataPoint,
        press: pressureDataPoint,
        numbers_press_rows: inputDataCalculation.option_data_point === "automatic" ? dataTableAutomic[2].values_pressure : null
      }

      async function executeFunction() {

        await mutationDataPVT.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserPL.county,
          field: dataFilterUserPL.field,
          well: dataFilterUserPL.well_id,
          username: User.user_name,
          data: uploadDataPVT
        });

        await new Promise(resolve => setTimeout(resolve, 1000));

        refetchCalcDataPVT();
      }
      executeFunction();

      setInputEmpty(false);
    } else if ((!inputDataCalculation.option_data_point && !inputDataCalculation.calculate_correlation && !validateDataNull)) {
      message.error("Please complete information");
      setInputEmpty(true)
    }
  }

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };

  const handleClickDone = () => {

    const dataPointSelected = inputDataCalculation.option_data_point === "automatic" ? dataTableAutomic : dataTableUserSelection;
    const dataCreateFluid = Object.assign({}, inputFluidInformation, inputDataCalculation, { data_point_table: [...dataPointSelected] }, dataInformationEnd);
    setTimeout(() => {
      mutationUpdateWellPlungerLift.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserPL.county,
        field: dataFilterUserPL.field,
        well: dataFilterUserPL.well_id,
        username: User.user_name,
        data: dataCreateFluid,
        type: "parametersplungerlift"
      })
    }, 100);

    setInputEmpty(false);
    navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
    message.success("Processing complete!");
  };

  const handleChangeFluidInformation = (e) => {
    const { name, value } = e.target;
    const valueAsNumber = value === "" ? "" : parseFloat(value);
    switch (name) {
      case "res_oil_gravity":
        if (valueAsNumber > 150) {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: null,
          });
          message.error("The value can't be greater than 150");
        } else {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: valueAsNumber,
          });
        }
        break;
      case "res_gas_gravity":
        if (valueAsNumber > 1.5) {
          message.error("The value can't be greater than 1.5");
          setInputFluidInformation((prevState) => ({
            ...prevState,
            [name]: null,
          }));
        } if (valueAsNumber < 0.5) {
          message.error("The value can't be less than 0.5");
          setInputFluidInformation((prevState) => ({
            ...prevState,
            [name]: null,
          }));
        } else {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: valueAsNumber,
          });
        }
        break;
      case "res_temperature":
        setInputFluidInformation({
          ...inputFluidInformation,
          [name]: valueAsNumber,
        });
        setDataTableAutomic(prevState => [
          {
            ...prevState[0],
            from_temperature: valueAsNumber,
          },
          {
            ...prevState[1],
            to_temperature: valueAsNumber,
          },
          ...prevState.slice(2)
        ]);
        break;
      case "res_H2S":
      case "res_N2":
      case "res_CO2":
        if (valueAsNumber > 1) {
          message.error("The value can't be greater than 1");
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: null,
          });
        } else {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: valueAsNumber,
          });
        }
        break;
      default:
        setInputFluidInformation({
          ...inputFluidInformation,
          [name]: valueAsNumber,
        });
        break;
    }
  };

  const handleChangeSelectCalculation = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeDataCalculation(customEvent);
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    // Cambiar el estado según tu lógica
    setStatus(!status);
  };

  const handleAddDataTable = (name_data) => {

    let maxId = 0;
    let newRow = [];
    const validateMaxID = (data) => {
      const uniqueIds = [...new Set(data.map((obj) => obj.id))];
      const maxId = Math.max(...uniqueIds);
      return maxId;
    }

    switch (name_data) {
      case "user_selection":
        maxId = validateMaxID(dataTableUserSelection);
        newRow = {
          ...DATA_FLUID.initialUserSelectionData[0],
          id: maxId + 1
        }
        setDataTableUserSelection([...dataTableUserSelection, newRow])
        break;
      default:
        break;
    }
  };

  const handleDeleteTable = (id, name_data) => {

    let newData = [];
    const validateFilter = (data) => {
      const newData = data.filter((item) => item.id !== id);
      return newData;
    }

    switch (name_data) {
      case "user_selection":
        newData = validateFilter(dataTableUserSelection);
        setDataTableUserSelection(newData);
        break;
      default:
        break;
    }
  };

  const handleChangeDataCalculation = (e, id) => {
    const { name, value } = e.target;
    const dataValue = value != "" ? Number(value) : "";
    switch (name) {
      // case "bubble_point_pressure":
      //   setInputDataCalculation({
      //     ...inputDataCalculation,
      //     [name]: Number(value)
      //   })
      //   break
      // case "calculate_correlation_oil":
      case "option_data_point":
      case "calculate_correlation":
        setInputDataCalculation({
          ...inputDataCalculation,
          [name]: value
        })
        break
      default:
        setInputDataCalculation({
          ...inputDataCalculation,
          [name]: value
        });
        break;
    }

    //change table
    switch (inputDataCalculation.option_data_point) {
      case "automatic":
        if (name === "from_temperature") {
          setDataTableAutomic(prevState =>
            prevState.map(item =>
              item.id === id
                ? {
                  ...item,
                  [name]: dataValue
                }
                : item
            )
          );
          setDataTableAutomic(prevState =>
            prevState.map(item =>
              item.id === 1
                ? {
                  ...item,
                  to_temperature: dataValue
                }
                : item
            )
          );
        } else {
          setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: dataValue } : item
            ));
        }
        break;
      case "user_selection":
        setDataTableUserSelection((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: dataValue } : item
          ));
        break
      default:
        break;
    }
  };

  const handleChangeBlurDataPoint = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : "";

    switch (inputDataCalculation.option_data_point) {
      case "automatic":
        if ((name === "from_pressure" || name === "to_pressure") && dataValue <= 0) {
          setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
          message.error("Pressure value must be greater than zero (0)");
        } else if (name === "to_pressure" && dataValue <= dataTableAutomic[0].from_pressure) {
          setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
          message.error("Pressure value must be greater than previous row value");
        } else {
          setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: dataValue } : item
            ));
        }
        break;
      case "user_selection":
        const selectedIndex = dataTableUserSelection.findIndex(
          (data) => data.id === id
        );

        const lastIndex = selectedIndex != 0 ? (selectedIndex - 1) : selectedIndex;
        if (name === "pressure" && (dataValue <= 0)) {
          message.error("Pressure value must be greater than zero (0)");
          setDataTableUserSelection((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
        } else if (name === "pressure" && (!!dataValue) && (dataValue <= dataTableUserSelection[lastIndex].pressure && selectedIndex != lastIndex)) {
          message.error("Pressure value must be greater than previous row value");
          setDataTableUserSelection((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
        } else {
          setDataTableUserSelection((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: dataValue } : item
            ));
        }
        break;
      default:
        break;
    }
  };

  const handleCheckChange = (valueCheck, name) => {
    const updatedChecks = [...ActiveChecks]
    if (current === 1) {
      updatedChecks[0][name] = valueCheck;
    }
    setActiveChecks(updatedChecks);
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  function VerifyingNulls(array) {
    let validateValuesNull = true;

    array.forEach(obj => {
      Object.entries(obj).forEach(([key, value]) => {
        if ((value === null || value === '') && key !== 'oil_compressibility' && key !== 'temperature') {
          validateValuesNull = false;
        }
      });
    });
    return validateValuesNull;
  };

  function validateKeys(name) {
    //filtrar el objeto inputFluidInformation por las claves que empiecen por res con la funcion startWith
    const keys = Object.keys(inputFluidInformation).filter(key => key.startsWith(name));
    switch (name) {
      case "res":
        const invalValuesRes = keys.some(key => inputFluidInformation[key] === null || inputFluidInformation[key] === "");
        return invalValuesRes
      default:
        return false;
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      {loadingPage && <Loading />}
      {!loadingPage &&
        <>
          <div className="flex">
            <Navbar wellboreOption={wellboreOption}></Navbar>
            <Sidebar className="flex abosolute h-full"></Sidebar>
          </div>
          <div className="flex w-full h-full">
            <AlertModal
              showAlert={showAlert}
              handleCloseModal={handleCloseModal}
              messageAlert={messageAlert}
              typeModal={typeAlert}
            ></AlertModal>
          </div>
          <div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
            <Steps
              size="default"
              current={current}
              onChange={onChange}
              className="site-navigation-steps flex w-[500px] custom-steps"
            >
              {steps.map((step, index) => (
                <Steps.Step
                  key={index}
                  title={step.title}
                  description={step.description}
                  className={`custom-step ${index > current ? 'custom-step-disabled' : index < current ? 'custom-step-checked' : ''}`}
                  icon={
                    <div className="custom-step-icon font-bold">
                      {customStepNumbers[index]}
                    </div>}
                  disabled
                />
              ))}
            </Steps>
          </div>
          <div className="flex flex-col w-full h-full pl-[104px] text-center justify-center  pr-4 pt-4 pb-4">
            {current === 0 && (
              <>
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col  w-[67%] text-right justify ml-[300px] ">
                    <div className="flex flex-row w-full max-w-[592px] text-start justify-start text-[20px] mb-[27px] mt-[27px]">
                      <h1 className="flex flex-row font-bold text-[20px]"> Reservoir data </h1>
                    </div>
                    <div className="grid grid-cols-2 justify-start items-center" >
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Pressure (psia)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="res_pressure"
                        value={inputFluidInformation.res_pressure}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_pressure === "") || inputFluidInformation.res_pressure === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Temperature (deg F)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="res_temperature"
                        value={inputFluidInformation.res_temperature}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_temperature === "") || inputFluidInformation.res_temperature === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Oil gravity (API)
                        <Tooltip title="Maximum allowable value: 150">
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                      <input
                        type="number" min="0" max="150" style={{ WebkitAppearance: 'none' }}
                        name="res_oil_gravity"
                        value={inputFluidInformation.res_oil_gravity}
                        onChange={handleChangeFluidInformation}
                        onKeyDown={handleKeyDown}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_oil_gravity === "") || inputFluidInformation.res_oil_gravity === null
                            ? " border-red-700 bg-yellow-100"
                            : " border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Gas gravity (Air = 1.000)
                        <Tooltip title="Allowed value between 0.5 - 1.5">
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                      </label>
                      <input
                        type="number" min="0" max="1.5" style={{ WebkitAppearance: 'none' }}
                        name="res_gas_gravity"
                        value={inputFluidInformation.res_gas_gravity}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_gas_gravity === "") || inputFluidInformation.res_gas_gravity === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Initial Solucion Gas-Oil Ratio (SCF/STB)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="res_initial_gor"
                        value={inputFluidInformation.res_initial_gor}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyDown}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                          ${inputEmpty && inputFluidInformation.res_initial_gor === ""
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        H2S (mole fraction)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="res_H2S"
                        value={inputFluidInformation.res_H2S}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_H2S === "") || inputFluidInformation.res_H2S === null
                            ? " border-red-700 bg-yellow-100"
                            : " border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        CO2 (mole fraction)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="res_CO2"
                        value={inputFluidInformation.res_CO2}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_CO2 === "") || inputFluidInformation.res_CO2 === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          } `}
                      />
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        N2 (mole fraction)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="res_N2"
                        value={inputFluidInformation.res_N2}
                        onChange={handleChangeFluidInformation}
                        onWheel={(e) => e.target.blur()}
                        className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]
                          ${(inputEmpty && inputFluidInformation.res_N2 === "") || inputFluidInformation.res_N2 === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          } `}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {current === 1 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Data points
                    </h1>
                  </div>
                </div>
                <div className="flex flex-row w-full h-full justify-center text-center items-center mb-[20px] gap-10">
                  <div className="flex flex-col w-1/2 ">
                    <div className="flex flex-col w-full text-right items-end justify-end">
                      <Select
                        name="option_data_point"
                        style={{ backgroundColor: inputEmpty && inputDataCalculation.option_data_point === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.option_data_point === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        className={`w-full max-w-[592px] h-[47px] text-left mb-[15px]`}
                        value={inputDataCalculation.option_data_point || "Selected option"}
                        onChange={(value) => handleChangeSelectCalculation("option_data_point", value)}
                      >
                        <Option value="automatic">Automatic</Option>
                        <Option value="user_selection">User selection</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 h-[47px] text-right items-end justify-end"></div>
                </div>
                {inputDataCalculation.option_data_point === "automatic" && (
                  <div className="flex flex-col w-full items-center">
                    <div className="flex flex-row w-full text-center justify-center items-center">
                      <ul className="flex flex-col w-full items-center">
                        <div className="w-[76.5rem] ml-[0] items-center">
                          <div className="flex flex-row w-[100%] bg-[#E2D9E5] text-center justify-center items-center gap-5">
                            <label
                              htmlFor=""
                              className="flex w-full max-w-[388px] mr-[10px] text-start font-bold text-[15px]"
                            >
                            </label>
                            <label
                              htmlFor=""
                              className="flex w-full max-w-[388px] mr-[10px] text-start font-bold text-[15px]"
                            >
                              Temperature (deg F)
                              <Tooltip title="Value can't be greater than 10000 and less than 0">
                                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                              </Tooltip>
                            </label>
                            <label
                              htmlFor=""
                              className="flex w-full max-w-[388px] text-start font-bold text-[15px]"
                            >
                              Pressure (psia)
                              <Tooltip title="Value can't be greater than 5 and less than 1">
                                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                              </Tooltip>
                            </label>
                          </div>
                        </div>
                        {dataTableAutomic.map((item, index) => (
                          <li
                            key={item.id}
                            className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center`}
                          >
                            <input
                              style={{ WebkitAppearance: 'none' }}
                              value={item.name}
                              readOnly
                              className={`w-full max-w-[388px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[30px] mr-[30px]`}
                            />
                            <input
                              type="number" min="0" style={{ WebkitAppearance: 'none' }}
                              name={`${item.name === 'Values' ? 'values' : (item.name === 'To' ? 'to' : 'from')}_temperature`}
                              value={item.name === 'Values' ? item.values_temperature : (item.name === 'To' ? item.to_temperature : item.from_temperature)}
                              onChange={(e) =>
                                handleChangeDataCalculation(e, item.id)
                              }
                              readOnly={item.name === "Values" || item.name === "To"}
                              onWheel={(e) => e.target.blur()}
                              className={`w-full max-w-[388px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] 
                                ${(inputEmpty && (item.name === 'Values' ? item.values_temperature : (item.name === 'To' ? item.to_temperature : item.from_temperature)) === "") || (item.name === 'Values' ? item.values_temperature : (item.name === 'To' ? item.to_temperature : item.from_temperature)) === null
                                  ? " border-red-700 bg-yellow-100"
                                  : "border-[#BCBCCC]"
                                } `}
                            />
                            <input
                              type="number" min="0" style={{ WebkitAppearance: 'none' }}
                              name={`${item.name === 'Values' ? 'values' : (item.name === 'To' ? 'to' : 'from')}_pressure`}
                              value={item.name === 'Values' ? item.values_pressure : (item.name === 'To' ? item.to_pressure : item.from_pressure)}
                              onChange={(e) =>
                                handleChangeDataCalculation(e, item.id)
                              }
                              onBlur={(e) => handleChangeBlurDataPoint(e, item.id)}
                              onWheel={(e) => e.target.blur()}
                              className={`w-full max-w-[388px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] 
                                ${(inputEmpty && (item.name === 'Values' ? item.values_pressure : (item.name === 'To' ? item.to_pressure : item.from_pressure)) === "") || (item.name === 'Values' ? item.values_pressure : (item.name === 'To' ? item.to_pressure : item.from_pressure)) === null
                                  ? " border-red-700 bg-yellow-100"
                                  : "border-[#BCBCCC]"
                                } `}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {inputDataCalculation.option_data_point === "user_selection" && (
                  <div className="flex flex-col w-full items-center">
                    <div className="flex flex-row w-full text-center justify-center items-center">
                      <ul className="flex flex-col w-full items-center">
                        <div className="w-[76.5rem] ml-[0] items-center">
                          <div className="flex flex-row w-[100%] bg-[#E2D9E5] text-center justify-center items-center gap-5">
                            <label
                              htmlFor=""
                              className="flex w-full max-w-[592px] mr-[15px] text-start font-bold text-[15px]"
                            >
                              Temperature (deg F)
                              <Tooltip title="Value can't be greater than 10000 and less than 0">
                                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                              </Tooltip>
                            </label>
                            <label
                              htmlFor=""
                              className="flex w-full max-w-[592px] text-start font-bold text-[15px]"
                            >
                              Pressure (psia)
                              <Tooltip title="Value can't be greater than 5 and less than 1">
                                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                              </Tooltip>
                            </label>
                          </div>
                        </div>
                        {dataTableUserSelection.map((item, index) => (
                          <li
                            key={item.id}
                            className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center pl-5 ${dataTableUserSelection.length - 1 === index && index === 2 ? "" : index < 2 && dataTableUserSelection.length <= 3 ? "mr-6" : dataTableUserSelection.length > 3 && (index === dataTableUserSelection.length - 1) && (index != 2) ? "ml-8" : ""}`}
                          >
                            <input
                              type="number" min="0" style={{ WebkitAppearance: 'none' }}
                              name="temperature"
                              value={item.temperature}
                              onChange={(e) =>
                                handleChangeDataCalculation(e, item.id)
                              }
                              readOnly={index != 0}
                              onWheel={(e) => e.target.blur()}
                              className={`w-full max-w-[597px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[35px] mr-[30px]
                                ${(inputEmpty && item.temperature === "" && index === 0) || item.temperature === null
                                  ? " border-red-700 bg-yellow-100"
                                  : "border-[#BCBCCC]"
                                } `}
                            />
                            <input
                              type="number" min="0" style={{ WebkitAppearance: 'none' }}
                              name="pressure"
                              value={item.pressure}
                              onChange={(e) =>
                                handleChangeDataCalculation(e, item.id)
                              }
                              onBlur={(e) => handleChangeBlurDataPoint(e, item.id)}
                              onWheel={(e) => e.target.blur()}
                              className={`w-full max-w-[597px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] 
                                ${(inputEmpty && item.pressure === "") || item.pressure === null
                                  ? " border-red-700 bg-yellow-100"
                                  : "border-[#BCBCCC]"
                                } `}
                            />
                            <div className={`flex flex-row  text-right items-start gap-2`}>
                              {dataTableUserSelection.length - 1 === index && (
                                <IconAdd
                                  className="w-6 h-6 fill-neutral-500"
                                  onClick={() => handleAddDataTable(inputDataCalculation.option_data_point)}
                                />
                              )}
                              {dataTableUserSelection.length >= 4 && (
                                <IconDelete
                                  className="w-6 h-6 fill-neutral-500"
                                  onClick={() =>
                                    handleDeleteTable(item.id, inputDataCalculation.option_data_point)
                                  }
                                />
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[1rem]">
                  <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Correlations
                    </h1>
                  </div>
                </div>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[1rem]">
                  <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                    <div className="flex flex-col w-[320px]">
                      <label htmlFor="" className="w-full text-start font bold text-[15px] mb-[7px]">Z-Gas compressibility factor</label>
                      <Select
                        name="calculate_correlation"
                        style={{ backgroundColor: inputEmpty && inputDataCalculation.calculate_correlation === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.calculate_correlation === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        value={inputDataCalculation.calculate_correlation || "Selected option"}
                        className="w-full h-[47px] text-left mb-[15px]"
                        onChange={(value) => handleChangeSelectCalculation("calculate_correlation", value)}
                      >
                        <Option value="brill_and_beggs">Brill and Beggs</Option>
                      </Select>
                    </div>
                    {/* <div className="flex flex-col w-[320px]">
                      <label htmlFor="" className="w-full text-start font bold text-[15px] mb-[7px]">Oil Viscosity</label>
                      <Select
                        name="calculate_correlation_oil"
                        value={inputDataCalculation.calculate_correlation_oil || "Selected option"}
                        className="w-full h-[47px] text-left mb-[15px]"
                        style={{ backgroundColor: inputEmpty && inputDataCalculation.calculate_correlation_oil === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.calculate_correlation_oil === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                        onChange={(value) => handleChangeSelectCalculation("calculate_correlation_oil", value)}
                      >
                        <Option value="beggs_beal">Beggs et al., Beal</Option>
                        <Option value="glaso_beggs">Glaso, Beggs et al., Vazquez et al</Option>
                      </Select>
                    </div> */}
                    <div className="flex flex-col mt-[0.8rem]">
                      <button
                        className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                        onClick={() => handleCalculation()}
                      >
                        Calculate
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      PVT Calculations
                    </h1>
                  </div>
                </div>
                {/* <div className="flex flex-row w-full h-full justify-center text-center items-center mb-[0.5rem] gap-10">
                  <div className="flex flex-col w-1/2 ">
                    <div className="flex flex-col w-full text-right items-end justify-end">
                      <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                        Bubble Point Pressure (psia)
                      </label>
                      <input
                        type="number" min="0" style={{ WebkitAppearance: 'none' }}
                        name="bubble_point_pressure"
                        readOnly
                        onChange={handleChangeDataCalculation}
                        value={inputDataCalculation.bubble_point_pressure}
                        onWheel={(e) => e.target.blur()}
                        className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]`}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2 h-[47px] text-right items-end justify-end"></div>
                </div> */}
                <div className="flex flex-col w-full h-full items-center justify-center text-center">
                  <TablePVTCalculatePL dataTable={calcDataPVT?.data} />
                </div>

                <div className="flex flex-row w-full items-center justify-center text-center mt-[3rem]">
                  {(typeGraphFluid).map((item, index) => (
                    <div key={index}>
                      <Checkbox
                        key={index}
                        className="my-checkbox"
                        checked={ActiveChecks[0][item.value]}
                        onChange={(e) => handleCheckChange(e.target.checked, item.value)}
                      >
                        {item.name}
                      </Checkbox>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-full h-full items-center justify-center text-center">
                  <ChartCalculationPL
                    ActiveAxisY={ActiveAxisY}
                    stateCheck={ActiveChecks[0]}
                    dataTable={calcDataPVT?.data}
                  ></ChartCalculationPL>
                </div>
              </>
            )}

            <div className="flex w-full text-center justify-center items-center">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                {current > 0 && (
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickPrev()}
                  >
                    Previous
                  </button>
                )}
                {current < steps.length - 1 && (
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickNext()}
                  >
                    Continue
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickDone()}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};