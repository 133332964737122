
export const TUNNING_GAS_LIFT_PARAMETERS_TYPE = {
    WELL_HEAD_PARAMETERS: "Well Head Parameters",
    GAS_LIFT_PARAMETERS: "Gas Lift Parameters",
    PRODUCTION_PARAMETERS : "Production Parameters"
};

export const INITIAL_DATA_SCENARIO_CHART = {
    name: "",
    date: ""
}

export const menuItems = [
  {
    key: "0",
    label: "Correlation",
    value: "0",
  },
  {
    key: "1",
    label: "Tuning VLP",
    value: "1",
  },
];

export const DATA_VLP = {
  initialDataTubing : [
    {
      id: 0,
      nameTubing: "",
      measured_depth: "",
      internal_diameter: "",
      absolute_roughness: "",
    },
  ],
  initialDataGauge : [
    {
      id: 0,
      test_date: "",
      gauge_tvd: "",
      gauge_pressure: "",
      gauge_temperature: "",
    },
  ],
  initialDataTestVLP : [
    {
      id: 0,
      depth_tvd: "",
      measured_pressure_tvd: "",
      oil_rate_tvd: "",
    },
  ],
  initialData : {
    mid_perf_tvd_depth: "",
    reservoir_temperature: "",
    reservoir_pressure: "",
    test_date_pp: "",
    oil_rate: "",
    gas_rate: "",
    gor_pp: "",
    water_cut: "",
    test_date_pp: "",
    test_date_glp: "",
    injection_gas_rate: "",
    injection_pressure: "",
    test_date_whp: "",
    wellhead_temperature: "",
    wellhead_pressure: "",
    gas_lift_valve_depth_1: "",
    gas_lift_valve_depth_2: "",
    gas_lift_valve_depth_3: "",
    gas_injection_distribution_valve_1: "",
    gas_injection_distribution_valve_2: "",
    gas_injection_distribution_valve_3: "",
    tubing_data: [],
    gauge_data: [],
    enable_gauge_parameters: [],
    active_checks: [],
    paramter1_vlp: "",
    paramter2_vlp: "",
    number_of_intervals: "",
    test_data: [],
    calculated_pressure: "",
    mse_vlp: "",
    whpCheck: false,
    glpCheck: false,
    ppCheck: false,
    timeIOTWhp: 900000,
    drawdownPres: "",
    calculatedPressRD: "",
    calculatedDrawdown: "",
  },
  typeLineGraph : {
    0: "Beggs and Brill",
    1: "Duns and Ros",
    2: "Fancher Brown",
    3: "Hagedorn Brown",
    4: "Wolgha",
  },
  typeLineColorGraph : [
    "#5D7EC6",
    "#9D6949",
    "#FFC737",
    "#70AC53",
    "#FE3F40",
  ],
  initialDataAverage : {
    fcat: null,
    water_cut: null,
    separator_type: null,
  },
  initialDataAverageRT : {
    chartProdHistory: "",
    chartWellCond: "",
    chartHole: "",
  },
  initial_data_scenario_chart : {
    name: "",
    date: ""
}
}