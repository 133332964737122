import {
	config
} from "../../config/config";
import {
	validateParams
} from "../utils/utils";

const BASE_URL_WELL = config.API.GASLIFT.BASE_URL_WELL;
const BASE_ULR_DATA_HISTORY = config.API.GASLIFT.BASE_URL_DATA_HISTORY;
const BASE_URL_VOLUMETRACKER = config.API.GASLIFT.BASE_URL_VOLUMETRACKER;
const BASE_URL_3D = config.API.GASLIFT.BASE_URL_3D;
const BASE_URL_IOT = config.API.IOT.BASE_URL_IOT;
const TOKEN_HISTORIC = config.AUTH.TOKEN_HISTORIC;
const TOKEN_VOLUMETRACKER = config.AUTH.TOKEN_VOLUMETRACKER;
const TOKEN_GAS_LIFT = config.AUTH.TOKEN_GAS_LIFT;
const TOKEN_IOT = config.AUTH.TOKEN_IOT;
const TOKEN_3D = config.AUTH.TOKEN_IPR_GAS_LIFT

export const createParametersGasLift = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		well,
		username,
		data
	} =
	dataJson;
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_GAS_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem creating parameters gas lift");
	}
};

export const updateParametersGasLift = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		well,
		username,
		data
	} =
	dataJson;
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_GAS_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating parameters gas lift");
	}
};

export const deleteParametersGasLift = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		wellid,
		username
	} = dataJson;

	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parametersgaslift`, {
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting parameters gas lift");
	}
};

export const getParametersGasLift = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersgaslift`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting parameters gas lift");
	}
};

export const getListWellGasLift = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting well gas lift list");
	}
};

export const getWellsUserGasLift = async (
	operator,
	lease,
	region,
	username,
) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersgaslift`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting wells user gas lift");
	}
};

export const getDataHistory = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;

	try {
		const response = await fetch(
			`${BASE_ULR_DATA_HISTORY}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting data history");
	}
};

export const createDataHistory = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		wellid,
		username,
		data
	} =
	dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;

	try {
		const response = await fetch(
			`${BASE_ULR_DATA_HISTORY}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify({
					data
				}),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem creating data history");
	}
};

export const updateDataHistory = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		wellid,
		username,
		data
	} =
	dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;

	try {
		const response = await fetch(
			`${BASE_ULR_DATA_HISTORY}/update-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify({
					data
				}),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating data history");
	}
};

export const deleteDataHistory = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		wellid,
		username
	} = dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;

	try {
		const response = await fetch(
			`${BASE_ULR_DATA_HISTORY}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`, {
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting data history");
	}
};

export const getListDataHistory = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${BASE_ULR_DATA_HISTORY}/list-wells/${operator}/${lease}/${region}/${username}`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting data history list");
	}
};

export const uploadJsonVolumeTracker = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		userName,
		data
	} = dataJson;
	if (!validateParams(operator, lease, region, county, field, userName)) return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/upload_allocation_gas_lift/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem uploading volume tracker data");
	}
};

export const getDataAllocation = async (operator, lease, region, county, field, wellid, userName) => {
	if (!validateParams(operator, lease, region, county, field, wellid,userName)) return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/get_allocation_gas_lift/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem uploading volume tracker data");
	}
};

export const getJsonVolumeTracker = async (
	operator,
	lease,
	region,
	county,
	field,
	well_id,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, well_id, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/average_gas_lift_realtime/${operator}/${lease}/${region}/${county}/${field}/${well_id}/${userName}`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting volume tracker data");
	}
};

export const getChartProductionData = async (
	rows,
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
) => {
	if (
		!validateParams(rows, operator, lease, region, county, field, wellid, user)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IOT}/chart_gl_production/${rows}/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting chart production data");
	}
};

export const getJsonChartGL_well_head = async (
	rows,
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
	wellName,
) => {
	if (
		!validateParams(
			rows,
			operator,
			lease,
			region,
			county,
			field,
			wellid,
			user,
			wellName,
		)
	)
		return;

	const encodedWellName = encodeURIComponent(wellName);
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/chart_gl_well_head/${rows}/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}?well_name=${encodedWellName}`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting chart GL well head data");
	}
};

export const getJsonChartGL_well_head_inj = async (
	rows,
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
) => {
	if (
		!validateParams(rows, operator, lease, region, county, field, wellid, user)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IOT}/chart_gl_inj/${rows}/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`, {
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);

		return response.json();
	} catch (error) {
		console.error("Problem getting chart GL well head injection data");
	}
};


export const updloadFile3D = async (dataJson) => {
	const {
		operator,
		lease,
		region,
		county,
		field,
		well,
		username,
		data,
	} = dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_3D}/3d/upload_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_3D,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem uploading screening data");
	}
};

export const getDataGraph3D = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_3D}/3d/graph/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_3D,
				},
			},
		);
		const jsonResponse = response.text();
		return jsonResponse;
	} catch (error) {
		throw new Error("Problem in data gaslift");
	}
};
