import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as IconAdd } from "../../../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../../../assets/img/icons/delete.svg";
import { ImportFileInjector } from "../../../../components/Injector/complements/ImportFileInjector";
import DownloadData from "../../../../components/DownloadData/DownloadData";
import Img_Wellhead from "../../../../assets/img/wellhead-img.png";
import Table from "./Table";
import classes from "./WellEquipment.module.css";
import { tooltip } from "leaflet";
const {
	wellEquipmentInjector,
	wellEquipmentInjector__headerWrapper,
	wellEquipmentInjector__TableWrapper,
	wellEquipmentInjector__title,
	wellEquipmentInjector__actions,
	wellEquipmentInjector__imageWrapper,
	wellEquipmentInjector__image,
	"wellEquipmentInjector__list--tooltip": wellEquipmentInjector__list_tooltip,
} = classes;

const WellEquipment = ({
	dataEquipment,
	handleChangeEquipment,
	handleBlurEquipment,
	handleAddEquipment,
	handleDeleteEquipment,
	handleKeyDown,
	handleUploadFile,
	validationTable,
	isEditing,
}) => {
	const headers = [
		{
			name: "Name",
			key: "name",
			type: "text",
			width: "68%",
		},
		{
			name: "Type",
			key: "type",
			inputType: "select",
			options: ["tubing", "casing"],
			width: "62%",
		},

		{
			name: "Length MD (ft)",
			key: "measured_depth",
			tooltip: (
				<ul className={wellEquipmentInjector__list_tooltip}>
					<li>Length values must be between 0 and 50000 feet</li>
					<li>
						The value must be less than the sum of MD Length from the well deviation
						table
					</li>
				</ul>
			),
			type: "number",
			width: "50%",
		},
		{
			name: "External diameter (in)",
			key: "external_diameter",
			tooltip: (
				<ul className={wellEquipmentInjector__list_tooltip}>
					<li>External diameter values must be between 0 and 36</li>
					<li>External diameter values can't be less than Internal diameter.</li>
				</ul>
			),
			type: "number",
			width: "50%",
		},
		{
			name: "Internal diameter (in)",
			key: "internal_diameter",
			tooltip: (
				<ul className={wellEquipmentInjector__list_tooltip}>
					<li>Internal diameter values must be between 0 and 36</li>
					<li>Value can't be greater than external diameter</li>
				</ul>
			),
			type: "number",
			width: "50%",
		},
		{
			name: "Absolute roughness (in)",
			key: "absolute_roughness",
			tooltip: "This value must be greater than zero and less than 0.1 inches.",
			type: "number",
			width: "52%",
		},
	];
	return (
		<div className={wellEquipmentInjector}>
			{/* Encabezado */}
			<div className={wellEquipmentInjector__headerWrapper}>
				<h1 className={wellEquipmentInjector__title}>Well Trajectory</h1>
				<div className={wellEquipmentInjector__actions}>
					<Tooltip placement="bottom" title="UPLOAD TABLE" color="#662D91">
						<span>
							<ImportFileInjector
								uploadfile={handleUploadFile}
								tableType="well_equipment"
							/>
						</span>
					</Tooltip>
					<Tooltip placement="bottom" title="DOWNLOAD TABLE" color="#662D91">
						<span>
							<DownloadData data={dataEquipment} fileName="data Well Equipment.xlsx" />
						</span>
					</Tooltip>
				</div>
			</div>
			<div className={wellEquipmentInjector__TableWrapper}>
				{/* Tabla */}
				<Table
					headers={headers}
					data={dataEquipment}
					hiddenColumns={["id"]}
					onChange={handleChangeEquipment}
					onBlur={handleBlurEquipment}
					onAddRow={handleAddEquipment}
					onDeleteRow={handleDeleteEquipment}
					validationError={validationTable?.tableEquipment}
				/>
				<div className={wellEquipmentInjector__imageWrapper}>
					<img
						src={Img_Wellhead}
						className={wellEquipmentInjector__image}
						alt="Wellhead"
						loading="lazy"
					/>
				</div>
			</div>
		</div>
	);
};

export default WellEquipment;
