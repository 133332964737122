// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WellDeviation_wellDeviationInjector__VySD8 {

    margin-left: 1.25rem;

    display: flex;

    width: 100%;

    flex-direction: column
}

.WellDeviation_wellDeviationInjector__headerWrapper__7dFUg {

    display: flex;

    height: 24px;

    width: 1335px;

    flex-direction: row;

    align-items: center;

    justify-content: flex-start;

    gap: 1.25rem
}
.WellDeviation_wellDeviationInjector__title__WsR0d {

    margin-left: 4rem;

    margin-top: 2rem;

    margin-bottom: 2rem;

    font-size: 20px;

    font-weight: 700
}
.WellDeviation_wellDeviationInjector__actions__-GU-H {

    display: flex;

    flex-direction: row;

    align-items: center;

    gap: 0.5rem
}
.WellDeviation_wellDeviationInjector__list--tooltip__eMtgr {

    list-style-type: disc;

    padding-left: 1rem
}
`, "",{"version":3,"sources":["webpack://./src/pages/TabInjector/AddEditWell/components/WellDeviation.module.css"],"names":[],"mappings":"AACE;;IAAA,oBAAgC;;IAAhC,aAAgC;;IAAhC,WAAgC;;IAAhC;AAAgC;;AAIhC;;IAAA,aAAyE;;IAAzE,YAAyE;;IAAzE,aAAyE;;IAAzE,mBAAyE;;IAAzE,mBAAyE;;IAAzE,2BAAyE;;IAAzE;AAAyE;AAGzE;;IAAA,iBAA4C;;IAA5C,gBAA4C;;IAA5C,mBAA4C;;IAA5C,eAA4C;;IAA5C;AAA4C;AAG5C;;IAAA,aAAuC;;IAAvC,mBAAuC;;IAAvC,mBAAuC;;IAAvC;AAAuC;AAGvC;;IAAA,qBAAqB;;IAArB;AAAqB","sourcesContent":[".wellDeviationInjector {\n  @apply flex flex-col w-full ml-5;\n}\n\n.wellDeviationInjector__headerWrapper {\n  @apply flex flex-row w-[1335px] h-[24px] gap-5 justify-start items-center;\n}\n.wellDeviationInjector__title {\n  @apply font-bold text-[20px] ml-16 mt-8 mb-8;\n}\n.wellDeviationInjector__actions {\n  @apply flex flex-row items-center gap-2;\n}\n.wellDeviationInjector__list--tooltip {\n  @apply pl-4 list-disc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wellDeviationInjector": `WellDeviation_wellDeviationInjector__VySD8`,
	"wellDeviationInjector__headerWrapper": `WellDeviation_wellDeviationInjector__headerWrapper__7dFUg`,
	"wellDeviationInjector__title": `WellDeviation_wellDeviationInjector__title__WsR0d`,
	"wellDeviationInjector__actions": `WellDeviation_wellDeviationInjector__actions__-GU-H`,
	"wellDeviationInjector__list--tooltip": `WellDeviation_wellDeviationInjector__list--tooltip__eMtgr`
};
export default ___CSS_LOADER_EXPORT___;
