import React, { useState } from "react";
import styleChartWellHead from "./ChartWellHead.module.css";
import { ReactComponent as ExpandIcon } from "../../../../assets/icon/expand1.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import { Select } from "antd";
const {
  containerPrincipal,
  containerLegend,
  styleRefenence1,
  styleRefenence2,
  styleRefenence3,
  styleRefenence4,
  sizeReference,
  separatorGraph,
} = styleChartWellHead;
const data1 = [
  { date: "2023-01-01", volumetric_flow_rate: 10 },
  { date: "2023-01-02", volumetric_flow_rate: 12 },
  { date: "2023-01-03", volumetric_flow_rate: 8 },
  { date: "2023-01-04", volumetric_flow_rate: 15 },
];

const data2 = [
  {
    date: "2023-01-01",
    Casing_Pressure: 30,
    Tubing_Pressure: 40,
    Pipeline_Pressure: 10,
  },
  {
    date: "2023-01-02",
    Casing_Pressure: 35,
    Tubing_Pressure: 50,
    Pipeline_Pressure: 20,
  },
  {
    date: "2023-01-03",
    Casing_Pressure: 33,
    Tubing_Pressure: 48,
    Pipeline_Pressure: 10,
  },
  {
    date: "2023-01-04",
    Casing_Pressure: 40,
    Tubing_Pressure: 55,
    Pipeline_Pressure: 30,
  },
];
const formatNumber = (value) => {
  // 2 decimal numbers format, except integers
  if (!isNaN(value) && value !== "") {
    const num = parseFloat(value);
    return num % 1 === 0 ? num : num.toFixed(2);
  }
  return value;
};
function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        
        <p style={{ color: "#ff6347", fontSize: "11px" }}>
          {`Casing Pressure: ` +formatNumber(data.Casing_Pressure) + ` (psia)`}
        </p>
        <p style={{ color: "#00bfff", fontSize: "11px" }}>
          {`Tubing Pressure: ` +formatNumber(data.Tubing_Pressure) + ` (psia)`}
        </p>
        <p style={{ color: "#ffd700", fontSize: "11px" }}>
          {`Pipeline Pressure: ` +formatNumber(data.Pipeline_Pressure) + ` (psia)`}
        </p>
  
      </div>
    );
  }
  return null;
}
function CustomTooltip2({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        
        <p style={{ color: "#32CD32", fontSize: "11px" }}>
          {`Gas Rate: ` +formatNumber(data?.gas_rate) + ` (Mscf/D)`}
        </p>
        
  
      </div>
    );
  }
  return null;
}
const ChartWellHead = ({data}) => {
  return (
    <div className={containerPrincipal}>
      <div style={{ width: "100%", height: "550px" }}>
        <ResponsiveContainer
          width="100%"
          height="30%"
          style={{
            backgroundColor: "#f9f9f9",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <ComposedChart
            data={data?.dataset1}
            syncId="syncChart"
            margin={{ top: 5, right: 5, bottom: -15, left: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={false} axisLine={{ strokeWidth: 1 }} />
            <YAxis
              yAxisId={1}
              label={{
                value: "Gas Rate (Mscf/D)",
                angle: -90,
                position: "insideLeft",
                offset: 17,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
            />
            <Tooltip content={<CustomTooltip2 />} />
            <Line
              type="monotone"
              dataKey="gas_rate"
              stroke="#32CD32"
              name="Gas rate"
              yAxisId={1}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <div className={separatorGraph}></div>
        <ResponsiveContainer
          width="100%"
          height="70%"
          style={{
            backgroundColor: "#f9f9f9",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          <ComposedChart data={data?.dataset2} syncId="syncChart">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              label={{
                value: "Date",
                position: "insideBottom",
                offset: -1,
                style: { fontSize: 12, textAnchor: "middle" },
              }}
              tick={{ fontSize: 12 }}
            />

            <YAxis
              yAxisId={1}
              label={{
                value: "Pressure (psia)",
                angle: -90,
                position: "insideLeft",
                offset: 17,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
            />
           <Tooltip content={<CustomTooltip />} />

            <Line
              yAxisId={1}
              type="monotone"
              dataKey="Casing_Pressure"
              stroke="#ff6347"
              name=" Pressure"
            />
            <Line
              yAxisId={1}
              type="monotone"
              dataKey="Tubing_Pressure"
              stroke="#00bfff"
              name=" Tubing_Pressure"
            />
            <Line
              yAxisId={1}
              type="monotone"
              dataKey="Pipeline_Pressure"
              stroke="#ffd700"
              name=" Pipeline_Pressure"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className={containerLegend}>
        <span className={sizeReference}>
          <span className={styleRefenence2}>●</span> Gas Rate
        </span>
        <span className={sizeReference}>
          <span className={styleRefenence1}>●</span>Casing Pressure
        </span>
        <span className={sizeReference}>
          <span className={styleRefenence3}>●</span> Pipeline Pressure
        </span>
        <span className={sizeReference}>
          <span className={styleRefenence4}>●</span>Tubing Pressure
        </span>
      </div>
    </div>
  );
};

export default ChartWellHead;
