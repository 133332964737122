import { useContext, useEffect, useState } from 'react'
import { config } from '../config/config';
import { useQuery } from 'react-query';
import { getUserDataForName, getUsersCLients, getUsersClient, getUsersClientsPlatforms, getUsersClientsProjects, getUsersData, getUsersDataClient, getUsersDataClientPlatforms, getUsersDataClientProjects, getUsersPlatforms, getUsersProjects, getUsersRoles, postClients, postPlatforms, postProjects, putUsersUpdate } from '../services/api/usersAPI';
import { UserContext } from '../context/User/UserContext';
import { AuthContext } from '../auth/context/AuthContext';

const PORT_DB = config.PORT_DB;
const initialDataUser = {
  user_name: "",
  user_password: "",
  clients: [],
  proyects: [],
  platforms: [],
  role: []
}
export const useUsers = () => {
  const {User} = useContext(AuthContext);
  const { selectUser, selectType, selectTable } = useContext(UserContext);
  const dataUser = JSON.parse(sessionStorage.getItem("userData"));
  const Token = dataUser?.token;
  const [body, setBody] = useState(initialDataUser);
  const [loadPage, setLoadPage] = useState(false);
  const [usuarioSeleccionado, setUserSelect] = useState(selectUser);
  const typeUser = selectType;
  //Fetching

  const [TableOperators, setOptionsOperators] = useState([]);
  const [TableRoles, setOptionsRoles] = useState([]);
  const [optionLeases, setLeases] = useState([]);
  const [optionRegions, setRegions] = useState([]);
  const [optionDataOperator, setOptionDataOperator] = useState("");
  const [idDataClient, setIdDataClient] = useState(0)
  const [queryEnabled, setQueryEnabled] = useState([true, User.role !== "Simple User"? true : false, false, false, false, true, true, false, false]);

  const { isLoading: loadDataUserName, isError: ErrorDataUserName, refetch: refetchDataUserName, data: dataUserName } = useQuery({
    queryKey: ['dataUserName', Token],
    queryFn: () => getUserDataForName(Token,selectUser.user_name,selectTable),
    enabled: false,
    retry: 2,
    onSuccess: (data) => {
    },
    onError: () => {
    }
  })

  const { isLoading: loadOptionOperators, isError: ErrorOptionOperators, data:dataOptionsOperators , refetch:refetchDataOptionOperators } = useQuery({
    queryKey: ['dataOptionsOperators', Token],
    queryFn: () => getUsersCLients(Token),
    enabled: false,
    retry: 2,
    onSuccess: (dataOptionsClient) => {
      const defaultOptionsClient = dataOptionsClient.map((item) => {
        return item;
      });
      setOptionsOperators(defaultOptionsClient);
    },
    onError: () => {
    }
  })

  const { isLoading: loadOptionRole, isError: ErrorOptionRoles, refetch: refetchRoles } = useQuery({
    queryKey: ['dataOptionsRoles', Token],
    queryFn: () => getUsersRoles(Token),
    enabled: false,
    retry: 2,
    onSuccess: (dataOptionsRoles) => {
      const defaultOptionsRoles = dataOptionsRoles?.map((item) => {
        return item.permissions;
      });
      setOptionsRoles(defaultOptionsRoles);
    },
    onError: () => {
    }
  })


  const { isError: ErrorDataClient } = useQuery({
    queryKey: ['dataOperator', Token],
    queryFn: () => getUsersDataClient(Token, optionDataOperator),
    enabled: false,
    retry: 2,
    onSuccess: (dataOperator) => {
      setIdDataClient(dataOperator.idclient)
    },
    onError: () => {
    }
  })

  const { isError: ErrorClientsProjects, refetch: refetchClientsProjects } = useQuery({
    queryKey: ['dataUsersClientsProjects', Token],
    queryFn: () => getUsersClientsProjects(Token, idDataClient),
    enabled: false,
    retry: 2,
    onSuccess: (dataUsersClientsProjects) => {
      const defaultDataOptionsProyects = dataUsersClientsProjects.map((item) => {
        return item.proyect;
      });
      setLeases([...new Set(defaultDataOptionsProyects)]);
    },
    onError: () => {
    }
  })

  const { data: defaultDataOptionsProyects, isError: ErrorClientsPlatforms, refetch: refetchClientsPlatforms } = useQuery({
    queryKey: ['dataUsersClientsPlatforms', Token],
    queryFn: () => getUsersClientsPlatforms(Token, idDataClient),
    enabled: false,
    retry: 2,
    onSuccess: (dataUsersClientsPlatforms) => {
      const dataOptionsLeases = dataUsersClientsPlatforms.map((item) => {
        return item.platform;
      });
      setRegions([...new Set(dataOptionsLeases)]);
    },
    onError: () => {
    }
  })

  const { data: dataOptionsLeases, isLoading:loadOptionLeases, isError: ErrorOptionLeases, refetch: refetchDataOptionLeases } = useQuery({
    queryKey: ['dataUsersProjects', Token],
    queryFn: () => getUsersProjects(Token),
    enabled: false,
    retry: 2,
    onSuccess: (dataUsersProjects) => {
      const defaultDataOptionsProyects = dataUsersProjects.map((item) => {
        return item.proyect;
      });
      const OptionsProyects = defaultDataOptionsProyects.filter(
        (valor, indice, self) => {
          return self.indexOf(valor) === indice;
        }
      );
      setLeases(OptionsProyects);
    },
    onError: () => {
    }
  })

  const { data:dataOptionsRegions, isLoading:loadOptionRegions, isError: ErrorOptionRegion, refetch: refetchDataOptionRegions } = useQuery({
    queryKey: ['dataOptionsRegions', Token],
    queryFn: () => getUsersPlatforms(Token),
    enabled: false,
    retry: 2,
    onSuccess: (dataUsersPlatforms) => {
      const dataOptionsLeases = dataUsersPlatforms.map((item) => {
        return item.platform;
      });
      const OptionsPlatforms = dataOptionsLeases.filter(
        (valor, indice, self) => {
          return self.indexOf(valor) === indice;
        }
      );
      setRegions(OptionsPlatforms);
    },
    onError: () => {
    }
  })

  const { isError: ErrorDataClientsProjects, refetch: refetchDataClientsProjects } = useQuery({
    queryKey: ['dataUsersDataClientsProjects', Token],
    queryFn: () => getUsersDataClientProjects(Token, idDataClient),
    enabled: false,
    retry: 2,
    onSuccess: (dataClientsProjects) => {
      const defaultDataOptionsProyects = dataClientsProjects.map((item) => {
        return item.proyect;
      });
      setLeases([...new Set(defaultDataOptionsProyects)]);
    },
    onError: () => {
    }
  })

  const { isError: ErrorDataClientsPlatforms, refetch: refetchDataClientsPlatforms } = useQuery({
    queryKey: ['dataUsersDataClientsPlatforms', Token],
    queryFn: () => getUsersDataClientPlatforms(Token, idDataClient),
    enabled: false,
    retry: 2,
    onSuccess: (dataClientsPlatrforms) => {
      const dataOptionsLeases = dataClientsPlatrforms.map((item) => {
        return item.platform;
      });
      setRegions([...new Set(dataOptionsLeases)]);
    },
    onError: () => {
    }
  })



  //Funciones
  function validatePassword(password) {
    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    return (
      lengthRegex.test(password) &&
      uppercaseRegex.test(password) &&
      lowercaseRegex.test(password) &&
      digitRegex.test(password) &&
      specialCharacterRegex.test(password)
    );
  }

  async function handleCheckDuplicates() {
    const validateClients = TableOperators.map(item => item.client);
    const uniqueArrayvalidateClients = [...new Set(validateClients)];
    const dataClients = await getUsersCLients(Token);
    const ArrayDataClients = dataClients.map((item) => {
      return item.client;
    });
    
    const uniqueArrayClients = [...new Set(ArrayDataClients)];
    const newClient = uniqueArrayvalidateClients.filter(
      (element) => !uniqueArrayClients.includes(element)
    );
    const oldClient = uniqueArrayvalidateClients.filter(
      (element) => uniqueArrayClients.indexOf(element) !== -1
    );
    
    const validateProyects = optionLeases;
    const uniqueArrayvalidateProyects = [...new Set(validateProyects)];
    const dataProyects = await getUsersProjects(Token)
    const ArrayDataProyects = dataProyects.map((item) => {
      return item.proyect;
    });
    const uniqueArrayProyects = [...new Set(ArrayDataProyects)];
    const newProyect = uniqueArrayvalidateProyects.filter(
      (element) => !uniqueArrayProyects.includes(element)
    );
    const oldProyect = uniqueArrayvalidateProyects.filter(
      (element) => uniqueArrayProyects.indexOf(element) !== -1
    );

    const validatePlatforms = optionRegions;
    const uniqueArrayvalidatePlatforms = [...new Set(validatePlatforms)];
    const dataPlatforms = await getUsersPlatforms(Token)
    const ArrayDataPlatforms = dataPlatforms.map((item) => {
      return item.platform;
    });
    const uniqueArrayPlatforms = [...new Set(ArrayDataPlatforms)];
    const newPlatform = uniqueArrayvalidatePlatforms.filter(
      (element) => !uniqueArrayPlatforms.includes(element)
    );
    const oldPlatform = uniqueArrayvalidatePlatforms.filter(
      (element) => uniqueArrayPlatforms.indexOf(element) !== -1
    );

    //Si se creea un nuevo cliente asocia los datos de ese cliente con los proyectos y plataformas que se seleccionen así estas sean nuevas
    if (newClient?.length > 0) {
      async function executeSequentialCreate() {
        await sequentialCreate(newClient, newProyect, newPlatform )
      }
      executeSequentialCreate()
    }
    //Si se crea un nuevo proyecto pero el client ya esta existente
    if (newProyect.length > 0 && newClient.length === 0) {
      const lastClient = TableOperators.length > 0 ? TableOperators[TableOperators.length - 1].idclient : 0;
      //Se encarga de asociar los proyectos nuevos al cliente cuando no haya clientes nuevos
      newProyect.forEach(async (elemento) => {
        try {
            const data = await postProjects({
              proyect: elemento,
              clientId: lastClient,
            }, Token);
          } catch (error) {
            console.error("Error al realizar la solicitud:", error);
          } 
      });
      //Se encarga de asociar las plataformas nuevas al cliente cuando no haya un cliente nuevo
       newPlatform.forEach(async (elemento) => {
        try {
          const data = await postPlatforms({
            platform: elemento,
            clientId: lastClient,
          }, Token);
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
        }
      }); 
    }
    
    //Si se crea una nueva plataforma pero el client ya esta existente y el proyecto tambien
    if (
      newPlatform.length > 0 &&
      newClient.length === 0 &&
      newProyect.length === 0
    ) {
      const lastClient = TableOperators.length > 0 ? TableOperators[TableOperators.length - 1].idclient : 0;
      //Se encarga de asociar las plataformas nuevas al cliente cuando no haya proyectos ni clientes nuevos
      newPlatform.forEach(async (elemento) => {
        try {
          const data = await postPlatforms({
            platform: elemento,
            clientId: lastClient,
          }, Token);
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
        }
      });
    }
    // //Validar que los proyectos y las plataformas viejas ya esten asociados al cliente viejo
    // oldProyect.forEach(async (elemento) => {
    //   oldClient.forEach(async (elemento2) => {
    //     try {
    //       const idClient = await getUsersDataClient(Token, elemento2);
    //       const validateProyect = await getUsersClientsProjects(Token, idClient.idclient);
    //       const ArrayValidateProyect = validateProyect.map((item) => {
    //         return item.proyect;
    //       });
    //       if (ArrayValidateProyect.includes(elemento) === false) {
    //         const data = await postProjects({
    //           proyect: elemento,
    //           clientId: idClient.idclient,
    //         }, Token);
    //       }
    //     } catch (error) {
    //       console.error("Error al realizar la solicitud:", error);
    //     }
    //   });
    // });
    // //Se encarga de asociar los plataformas existentes al cliente cuando no haya clientes ni proyectos nuevos
    // oldPlatform.forEach(async (elemento) => {
    //   oldClient.forEach(async (elemento2) => {
    //     try {
    //       const idClient = await getUsersDataClient(Token, elemento2);
    //       const validatePlatform = await getUsersClientsPlatforms(Token, idClient.idclient);
    //       const ArrayValidatePlatform = validatePlatform.map((item) => {
    //         return item.platform;
    //       });
    //       if (ArrayValidatePlatform.includes(elemento) === false) {
    //         const data = await postPlatforms({
    //           platform: elemento,
    //           clientId: idClient.idclient,
    //         }, Token);
    //       }
    //     } catch (error) {
    //       console.error("Error al realizar la solicitud:", error);
    //     }
    //   });
    // }); 
  }

  const sequentialCreate = async (newClient, newProyect, newPlatform) => {
    
    let data = null;
    // Iterar sobre newClient secuencialmente
    for (const elemento of newClient) {
      try {
        data = await postClients({ client: elemento }, Token);
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
      }
    }

    // Iterar sobre newProyect secuencialmente
    for (const elemento of newProyect) {
      try {
        const data2 = await postProjects({
          proyect: elemento,
          clientId: data.idclient,
        }, Token);
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
      }
    }

    // Iterar sobre newPlatform secuencialmente
    for (const elemento of newPlatform) {
      try {
        const data3 = await postPlatforms({
          platform: elemento,
          clientId: data.idclient,
        }, Token);
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
      }
    }
    
  }

  async function handleCheckDuplicatesUpdate() {
    let validateClients = selectUser.clients;

    if (!Array.isArray(validateClients)) {
      validateClients = [validateClients];
    }

    const dataClients = await getUsersCLients(Token);
    const ArrayDataClients = dataClients.map((item) => {
      return item.client;
    });
    const uniqueArrayClients = [...new Set(ArrayDataClients)];
    const newClient = validateClients.filter(
      (element) => !ArrayDataClients.includes(element)
    );
    const oldClient = validateClients.filter(
      (element) => uniqueArrayClients.indexOf(element) !== -1
    );

    if (typeUser === 'Simple User') {   //Para tabla Externa
      const preNewClient = validateClients.map(id => {
        const elemento = TableOperators.find(item => item.idclient === id);
        return elemento ? elemento.client : "";
      });
      const preNewClient2 = preNewClient.filter(elemento => !dataClients.some(item => item.client === elemento));
      const prevOldClient = validateClients.map(id => {
        const elemento = dataClients.find(item => item.idclient === id);
        return elemento ? elemento.client : "";
      });

      if (!preNewClient2.includes("") && preNewClient2.length > 0) { newClient = preNewClient2 } else { newClient = [] }
      if (!prevOldClient.includes("") && prevOldClient.length > 0) { oldClient = prevOldClient; } else { oldClient = [] }
    }
    else if (typeUser === "Master") {    //para tabla interna

      const arrayDataClient = dataClients.map((item) => {
        return item.idclient
      })
      const stringArray = arrayDataClient.map(number => number.toString());
      const stringArraySelected = validateClients.map(number => number.toString());
      const prevOldClientString = stringArraySelected.filter(element => stringArray.includes(element)); // old client convertir
      const prevOldClientNumber = prevOldClientString.map(element => Number(element));
      const prevOldClient = prevOldClientNumber.map(id => {
        const elemento = dataClients.find(item => item.idclient === id);
        return elemento ? elemento.client : "";
      });
      const preNewClient = stringArraySelected.filter(element => !stringArray.includes(element)); //newclient
      if (!preNewClient.includes("") && preNewClient.length > 0) { newClient = preNewClient } else { newClient = [] }
      if (!prevOldClient.includes("") && prevOldClient.length > 0) { oldClient = prevOldClient } else { oldClient = [] }
    }

    const validateProyects = selectUser.proyects;
    const uniqueArrayvalidateProyects = [...new Set(validateProyects)];

    const dataProyects = await getUsersProjects(Token);
    const ArrayDataProyects = dataProyects.map((item) => {
      return item.proyect;
    });
    const uniqueArrayProyects = [...new Set(ArrayDataProyects)];
    const newProyect = uniqueArrayvalidateProyects.filter(
      (element) => !uniqueArrayProyects.includes(element)
    );
    const oldProyect = uniqueArrayvalidateProyects.filter(
      (element) => uniqueArrayProyects.indexOf(element) !== -1
    );

    const validatePlatforms = selectUser.platforms;
    const uniqueArrayvalidatePlatforms = [...new Set(validatePlatforms)];

    const dataPlatforms = await getUsersPlatforms(Token);
    const ArrayDataPlatforms = dataPlatforms.map((item) => {
      return item.platform;
    });
    const uniqueArrayPlatforms = [...new Set(ArrayDataPlatforms)];
    const newPlatform = uniqueArrayvalidatePlatforms.filter(
      (element) => !uniqueArrayPlatforms.includes(element)
    );
    const oldPlatform = uniqueArrayvalidatePlatforms.filter(
      (element) => uniqueArrayPlatforms.indexOf(element) !== -1
    );


    //Si se creea un nuevo cliente asocia los datos de ese cliente con los proyectos y plataformas que se seleccionen así estas sean nuevas
    if (newClient.length > 0) {
      newClient.forEach(async (elemento) => {
        try {
          const data = await postClients({ client: elemento }, Token);
          validateProyects.forEach(async (elemento) => {
            try {
              const data2 = await postProjects({
                proyect: elemento,
                clientId: data.idclient,
              }, Token);
            } catch (error) {
              console.error("Error al realizar la solicitud:", error);
            }
          });
          validatePlatforms.forEach(async (elemento) => {
            try {
              const data3 = await postPlatforms({
                platform: elemento,
                clientId: data.idclient,
              }, Token);
            } catch (error) {
              console.error("Error al realizar la solicitud:", error);
            }
          });
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
        }
      });
    }
    //Si se crea un nuevo proyecto pero el client ya esta existente
    if (newProyect.length > 0 && newClient.length === 0) {
      //Se encarga de asociar los proyectos nuevos al cliente cuando no haya clientes nuevos
      newProyect.forEach(async (elemento) => {
        validateClients.forEach(async (elemento2) => {
          try {
            const idClient = await getUsersDataClient(Token, elemento2);
            const data = await postProjects({
              proyect: elemento,
              clientId: idClient.idclient,
            }, Token);
          } catch (error) {
            console.error("Error al realizar la solicitud:", error);
          }
        });
      });
      //Se encarga de asociar las plataformas nuevas al cliente cuando no haya un cliente nuevo
      newPlatform.forEach(async (elemento) => {
        validateClients.forEach(async (elemento2) => {
          try {
            const idClient = await getUsersDataClient(Token, elemento2);
            const data = await postPlatforms({
              platform: elemento,
              clientId: idClient.idclient,
            }, Token);
          } catch (error) {
            console.error("Error al realizar la solicitud:", error);
          }
        });
      });
    }
    //Si se crea una nueva plataforma pero el client ya esta existente y el proyecto tambien
    if (
      newPlatform.length > 0 &&
      newClient.length === 0 &&
      newProyect.length === 0
    ) {
      //Se encarga de asociar las plataformas nuevas al cliente cuando no haya proyectos ni clientes nuevos
      newPlatform.forEach(async (elemento) => {
        validateClients.forEach(async (elemento2) => {
          try {
            const idClient = await getUsersDataClient(Token, elemento2);
            const data = await postPlatforms({
              platform: elemento,
              clientId: idClient.idclient,
            }, Token);
          } catch (error) {
            console.error("Error al realizar la solicitud:", error);
          }
        });
      });
    }
    //Validar que los proyectos y las plataformas viejas ya esten asociados al cliente viejo
    oldProyect.forEach(async (elemento) => {
      oldClient.forEach(async (elemento2) => {
        try {
          const idClient = await getUsersDataClient(Token, elemento2);
          const validateProyect = await getUsersClientsProjects(Token, idClient.idclient);
          const ArrayValidateProyect = validateProyect.map((item) => {
            return item.proyect;
          });
          if (ArrayValidateProyect.includes(elemento) === false) {
            const data = await postProjects({
              proyect: elemento,
              clientId: idClient.idclient,
            }, Token);
          }
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
        }
      });
    });
    //Se encarga de asociar los plataformas existentes al cliente cuando no haya clientes ni proyectos nuevos
    oldPlatform.forEach(async (elemento) => {
      oldClient.forEach(async (elemento2) => {
        try {
          const idClient = await getUsersDataClient(Token, elemento2);

          const validatePlatform = await getUsersClientsPlatforms(Token, idClient.idclient);
          const ArrayValidatePlatform = validatePlatform.map((item) => {
            return item.platform;
          });
          if (ArrayValidatePlatform.includes(elemento) === false) {
            const data = await postPlatforms({
              platform: elemento,
              clientId: idClient.idclient,
            }, Token);
          }
        } catch (error) {
          console.error("Error al realizar la solicitud:", error);
        }
      });
    });
  }

  //Function to update super user
  async function UpdateSuperUser() {
    const data_query_roles = await getUsersData(Token, "users_enovates");
    const filteredAdmin = data_query_roles.filter((item) =>
      item.roles_name.includes("Admin")
    );

    filteredAdmin.forEach(async (item) => {

      const dataSuperUser = await getUserDataForName(Token, item.user_name, "users_enovates");
      const dataClients = await getUsersCLients(Token);
      const ArrayClients = dataClients.map((item) => {
        return item.idclient;
      });
      const ArrayClientsUnique = [...new Set(ArrayClients)];
      const dataProyects = await getUsersProjects(Token);
      const ArrayProyects = dataProyects.map((item) => {
        return item.proyect;
      });
      const ArrayProyectsUnique = [...new Set(ArrayProyects)];
      const dataPlatforms = await getUsersPlatforms(Token);
      const ArrayPlatforms = dataPlatforms.map((item) => {
        return item.platform;
      });
      const ArrayPlatformsUnique = [...new Set(ArrayPlatforms)];

      const updateDataSuperUser = {
        user_name: dataSuperUser.user_name,
        proyects: ArrayProyectsUnique,
        platforms: ArrayPlatformsUnique,
        user_clientId: ArrayClientsUnique,
      };

      const updateSuperUser = await putUsersUpdate(updateDataSuperUser, dataSuperUser.iduser, "users_enovates", Token);
    });
    //si aumentan los super usuarios este tiempo va cambiar
    setTimeout(() => {
      setLoadPage(false);
      window.location.reload();
    }, 8000);
  };

  return {
    User,
    Token,
    loadOptionRole,
    TableOperators,
    TableRoles,
    body,
    idDataClient,
    optionLeases,
    optionRegions,
    usuarioSeleccionado,
    defaultDataOptionsProyects,
    dataOptionsLeases,
    dataUserName,
    dataOptionsOperators,
    dataOptionsOperators,
    dataOptionsLeases,
    dataOptionsRegions,
    setUserSelect,
    setLeases,
    setRegions,
    setIdDataClient,
    setOptionsOperators,
    setOptionsRoles,
    setBody,
    setLoadPage,
    setOptionDataOperator,
    refetchDataOptionRegions,
    refetchDataOptionLeases,
    refetchDataClientsPlatforms,
    refetchDataClientsProjects,
    refetchDataUserName,
    refetchDataOptionRegions,
    refetchDataOptionOperators,
    refetchDataOptionLeases,
    refetchClientsProjects,
    refetchClientsPlatforms,
    refetchRoles,
    ErrorOptionOperators,
    ErrorOptionRegion,
    ErrorOptionLeases,
    ErrorDataUserName,
    ErrorOptionRoles,
    ErrorDataClient,
    ErrorClientsProjects,
    ErrorClientsPlatforms,
    loadPage,
    loadOptionOperators,
    loadOptionRegions,
    loadDataUserName,
    loadOptionLeases,
    validatePassword,
    UpdateSuperUser,
    handleCheckDuplicates,
    handleCheckDuplicatesUpdate
  }
}
