export const DATA_CREATE_WELL_INJECTOR = {
	county: "",
	data_alert: {
		wc_pressure_min: null,
		wc_temp_min: null,
		bhc_pressure_min: null,
		bhc_temp_min: null,
		im_injection_min: null,
		im_inj_rate_min: null,
		im_inj_cumulative_min: null,
		wc_pressure_max: null,
		wc_temp_max: null,
		bhc_pressure_max: null,
		bhc_temp_max: null,
		im_injection_max: null,
		im_inj_rate_max: null,
		im_inj_cumulative_max: null,
	},
	data_check: [],
	data_domain_graph: {
		pressure_max_perfor: null,
		pressure_min_perfor: null,
		temperature_max_perfor: null,
		temperature_min_perfor: null,
		injection_max_ih: null,
		injection_min_ih: null,
		cumulative_max_ih: null,
		cumulative_min_ih: null,
	},
	field: "",
	latitude: ``,
	longitude: ``,
	region: "",
	type_graph_dashboard: [],
	well_id: "",
	well_name: "",
	discretized_well: false,
	well_platforms: "",
	well_type: "",
};

export const STATE_DOMAIN = {
	PRESSURE_MAX_PERFOR: "pressure_max_perfor",
	PRESSURE_MIN_PERFOR: "pressure_min_perfor",
	TEMPERATURE_MAX_PERFOR: "temperature_max_perfor",
	TEMPERATURE_MIN_PERFOR: "temperature_min_perfor",
	INJECTION_MAX_IH: "injection_max_ih",
	INJECTION_MIN_IH: "injection_min_ih",
	CUMULATIVE_MAX_IH: "cumulative_max_ih",
	CUMULATIVE_MIN_IH: "cumulative_min_ih",
};

export const DATA_DOMAIN_GRAPH_INJECTOR = {
	pressure_max_perfor: null,
	pressure_min_perfor: null,
	temperature_max_perfor: null,
	temperature_min_perfor: null,
	injection_max_ih: null,
	injection_min_ih: null,
	cumulative_max_ih: null,
	cumulative_min_ih: null,
};

export const INITIAL_COORDINATES = {
	latitudeDegrees: "",
	latitudeMinutes: "",
	latitudeSeconds: "",
	latitudeDirection: "",
	longitudeDegrees: "",
	longitudeMinutes: "",
	longitudeSeconds: "",
	longitudeDirection: "",
};

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
	"date",
	"gas_injection_rate",
	"water_injection_rate",
	"cumulative_gas",
	"cumulative_water",
	"injection_pressure",
];

export const TYPE_CHARTS = {
	prod_hist: "production_history",
	ai_DCA: "AI_DCA",
	performance: "performance",
};

export const STATE_ALERTS = {
	alert_wc_pressure: "wc_pressure",
	alert_wc_temp: "wc_temp",
	alert_bhc_pressure: "bhc_pressure",
	alert_bhc_temp: "bhc_temp",
	alert_im_injection: "im_injection",
	alert_im_inj_rate: "im_inj_rate",
	alert_im_inj_cumulative: "im_inj_cumulative",
};

export const INITIAL_DATA_ALERTS_INJECTION = {
	wc_pressure_min: null,
	wc_temp_min: null,
	bhc_pressure_min: null,
	bhc_temp_min: null,
	im_injection_min: null,
	im_inj_rate_min: null,
	im_inj_cumulative_min: null,
	wc_pressure_max: null,
	wc_temp_max: null,
	bhc_pressure_max: null,
	bhc_temp_max: null,
	im_injection_max: null,
	im_inj_rate_max: null,
	im_inj_cumulative_max: null,
};

export const INITIAL_DATA_WELL_DEVIATION = [
	{
		id: 0,
		measured_depth: 0,
		tvd: 0,
		inc: 0,
	},
	{
		id: 1,
		measured_depth: "",
		tvd: "",
		inc: null,
	},
	{
		id: 2,
		measured_depth: "",
		tvd: "",
		inc: null,
	},
];

export const INITIAL_DATA_WELL_EQUIPMENT = [
	{
		id: 0,
		name: "",
		type: "",
		measured_depth: "",
		internal_diameter: "",
		external_diameter: "",
		absolute_roughness: "",
	},
	{
		id: 1,
		name: "",
		type: "",
		measured_depth: "",
		internal_diameter: "",
		external_diameter: "",
		absolute_roughness: "",
	},
	{
		id: 2,
		name: "",
		type: "",
		measured_depth: "",
		internal_diameter: "",
		external_diameter: "",
		absolute_roughness: "",
	},
];

export const REQUIRED_COLUMNS_TABLE_DEVIATION = [
	"measured_depth",
	"tvd",
	"inc",
];

export const REQUIRED_COLUMNS_TABLE_EQUIPMENT = [
	"name",
	"type",
	"measured_depth",
	"internal_diameter",
	"external_diameter",
	"absolute_roughness",
];

export const INITIAL_DATA_CARDS_INJECTOR = {
	wellhead_conditions_press: "",
	wellhead_conditions_temp: "",
	production_performance_gas_rate: "",
	production_performance_oil_rate: "",
	production_performance_water_rate: "",
	production_performance_gor: "",
	production_performance_wor: "",
}
