import React, { useState, useEffect, useContext } from 'react';
import ResetIcon from '../../assets/icon/reset.png';
import { ReactComponent as EditIcon } from '../../assets/icon/edit.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icon/expand.svg';
import { ChartHistory } from './GraphicsDashboard/ChartHistory/ChartHistory';
import { Modal } from 'antd';
import { DashboardDCAContext } from '../../context/DasboardDCA/DashboardDCAContext';
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';

export const FrameGraph3 = (props) => {
    const { open, onCloseModal, dataHistory, dataIOT, typeDevice, typeGraph3, statusGraph} = props;
    const {checkTypeGraph, getCheckTypeGraph} = useContext(DashboardContext)
    const [openModal, setOpenModal] = useState(false)
    const [enableModal, setEnabledModal] = useState(false)

    useEffect(() => {
        setOpenModal(open);
        if(open){
            setEnabledModal(true);
        }
    }, [open])

    const {dataFilterUserDCA} = useContext(DashboardDCAContext)
    const arrayDataHistory = dataHistory?.statusType === "SUCCESS" && dataFilterUserDCA != null ? dataHistory?.data.data : null;

    const handleTypeGraph = (event, name) => {
        if( checkTypeGraph.forecasting[name] === true){
            getCheckTypeGraph({...checkTypeGraph, forecasting: {...checkTypeGraph.forecasting, [name]: false}})

        } else{
            getCheckTypeGraph({...checkTypeGraph, forecasting: {...checkTypeGraph.forecasting, [name]: true}})
        }
    };

    return (
        <>
            <style>
                {`
                /* Estilo para el tipo de grafica no seleccionado */
                    .unselectedInput {
                        border: 2px solid #ccc; /* Estilo de borde cuando no está seleccionado */
                    }
                    /* Estilo para el tipo de grafica seleccionado */
                    .selectedGraph {
                        border: 2px solid #662D91; /* Estilo de borde según el selectedOptions */
                        background-color: #662D91; /* Color de fondo según el selectedOptions */
                        padding: 3px; 
                        background-clip: content-box; /* Colorea solo el área interna del contenido, excluyendo el padding */
                    }
                `}
            </style>
            <div className='flex flex-row w-full'>
                <div className='flex w-full h-full text-center items-center justify-center gap-2'>
                    <div className='rounded-full w-3 h-3 bg-red-600'></div>
                    <h2 className='text-bc-grey text-[13px]'>Gas</h2>
                    <div className='rounded-full w-3 h-3 bg-nc-green'></div>
                    <h2 className='text-bc-grey text-[13px]'>Oil</h2>
                    <div className='rounded-full w-3 h-3 bg-blue-500'></div>
                    <h2 className='text-bc-grey text-[13px]'>Water</h2>
                </div>
            </div>
            <div className='w-full h-full bg-white'>
                <ChartHistory typeGraph={typeGraph3} y={305} bottom={50} dataHistory={arrayDataHistory} dataIOTDCA={dataIOT} typeDevice={typeDevice}  enableModal={enableModal} graphType={statusGraph}></ChartHistory>
            </div>
            <Modal
                title="Production history"
                centered
                open={openModal}
                onOk={() => {
                    setEnabledModal(false);
                    setOpenModal(false);
                    onCloseModal(1)
                }
                }
                onCancel={() => {
                    setEnabledModal(false);
                    setOpenModal(false);
                    onCloseModal(1); // Aquí llamamos a la función para cerrar el modal en Dashboard
                }}
                width={1000}
                okButtonProps={{
                    className: "bg-[#662D91]",
                  }}
            >
                <div className='h-[600px] pb-6'>
                    <div className='flex flex-row w-full h-[40px] justify-center'>
                        <div className='flex flex-row w-[20%] h-full items-center text-right justify-center gap-2'>
                            <input
                                className={`ml-1 w-5 h-5 appearance-none rounded-full ${!statusGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                                type="checkbox"
                                checked={statusGraph}
                                onChange={(event) => handleTypeGraph(event, "production_history")}
                            />
                            <label htmlFor="" className='text-bc-gray text-[14px]'>Historical Data + RT avg</label>
                        </div>
                        <div className='flex flex-row w-[20%] h-full items-center text-right justify-center gap-2'>
                            <input
                                className={`ml-1 w-5 h-5 appearance-none rounded-full ${statusGraph ? 'selectedGraph' : 'unselectedInput'} cursor-pointer`}
                                type="checkbox"
                                checked={statusGraph}
                                onChange={(event) => handleTypeGraph(event, "production_history")}
                            />
                            <label htmlFor="" className='text-bc-gray text-[14px]'>Real Time Data</label>
                        </div>
                    </div>
                    <div className='flex flex-row w-full'>
                        <div className='flex w-full h-full text-center items-center justify-center gap-2'>
                            <div className='rounded-full w-3 h-3 bg-red-600'></div>
                            <h2 className='text-bc-grey text-[13px]'>Gas</h2>
                            <div className='rounded-full w-3 h-3 bg-nc-green'></div>
                            <h2 className='text-bc-grey text-[13px]'>Oil</h2>
                            <div className='rounded-full w-3 h-3 bg-blue-500'></div>
                            <h2 className='text-bc-grey text-[13px]'>Water</h2>
                        </div>
                    </div>
                    <div className='w-full h-full bg-white'>
                        <ChartHistory typeGraph={typeGraph3} y={305} bottom={50} dataHistory={arrayDataHistory} dataIOTDCA={dataIOT} typeDevice={typeDevice} enableModal={enableModal} graphType={statusGraph}></ChartHistory>
                    </div>
                </div>
            </Modal >
        </>
    );
};
