// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardGraph_containerCard__lMIHv {
  display: flex;
  width: 100%;
  height: 73px;
  padding: 4px;
  gap: 5px;
}
.CardGraph_containerColumn1__eP7Fp {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3px;
}
.CardGraph_containerColumn2__WyLq1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 3px;
}

.CardGraph_tittle__AOOxw {
  font-size: 10px;
}
.CardGraph_units__B\\+Lfa {
  font-size: 10px;
}
.CardGraph_values__vFP1g {
  font-size: 18px;
  font-weight: 400;
}
.CardGraph_containerKpi__x5xAm {
  display: flex;
  height: 13px;
  padding-left: 24px;
}
@media (min-width: 767px) {
  .CardGraph_tittle__AOOxw {
    font-size: 12px;
  }
  .CardGraph_units__B\\+Lfa {
    font-size: 12px;
  }
  .CardGraph_containerKpi__x5xAm {
    padding-left: 14px;
  }
}
@media (min-width: 1024px) {
  .CardGraph_values__vFP1g {
    font-size: 20px;
  }
  .CardGraph_containerKpi__x5xAm {
    padding-left: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PlungerLift/Complements/CardGraph.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,QAAQ;AACV;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,QAAQ;AACV;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".containerCard {\n  display: flex;\n  width: 100%;\n  height: 73px;\n  padding: 4px;\n  gap: 5px;\n}\n.containerColumn1 {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  gap: 3px;\n}\n.containerColumn2 {\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  gap: 3px;\n}\n\n.tittle {\n  font-size: 10px;\n}\n.units {\n  font-size: 10px;\n}\n.values {\n  font-size: 18px;\n  font-weight: 400;\n}\n.containerKpi {\n  display: flex;\n  height: 13px;\n  padding-left: 24px;\n}\n@media (min-width: 767px) {\n  .tittle {\n    font-size: 12px;\n  }\n  .units {\n    font-size: 12px;\n  }\n  .containerKpi {\n    padding-left: 14px;\n  }\n}\n@media (min-width: 1024px) {\n  .values {\n    font-size: 20px;\n  }\n  .containerKpi {\n    padding-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerCard": `CardGraph_containerCard__lMIHv`,
	"containerColumn1": `CardGraph_containerColumn1__eP7Fp`,
	"containerColumn2": `CardGraph_containerColumn2__WyLq1`,
	"tittle": `CardGraph_tittle__AOOxw`,
	"units": `CardGraph_units__B+Lfa`,
	"values": `CardGraph_values__vFP1g`,
	"containerKpi": `CardGraph_containerKpi__x5xAm`
};
export default ___CSS_LOADER_EXPORT___;
