export  const VALUES_FO_CALCULATE = {
    FO1: 1,
    FO2: 1,
    FO3: 1,
    FO4: 1,
    FO5: 1,
    FO6: 1,
    FO7: 1,
    FO8: 1,
    FO9: 1,
    FO10: 1,
    FO11: 1
  }

  export const DATA_CREATE_WELL_GAS_LIFT = {
    well_id: '',
    region: '',
    field: '',
    latitude: '',
    company: '',
    well_type: '',
    artificial_method: '',
    flow_type: '',
    well_name: '',
    discretized_well: false,
    well_platforms: '',
    country: '',
    county: '',
    longitude: '',
    objective: '',
    well_orientation: '',
    well_reference: '',
    fluid_type: '',
  };

  export const DATA_END_PARAMETERS = {

    // data fluid PVT
    res_pressure: "",
    res_temperature: "",
    res_initial_gor: "",
    res_oil_gravity: "",
    res_gas_gravity: "",
    res_H2S: "",
    res_CO2: "",
    res_N2: "",
    res_water_salinity: "",
    sep_pressure: "",
    sep_temperature: "",
    sep_oil_gravity: "",
    sep_gas_gravity: "",
    sep_solution_gor: "",
    sep_oil_volume_factor: "",

    temperature_lab: "",
    solution_gor_pb: "",
    bubble_point_pressure_lab: "",
    oil_fvf: "",

    option_data_point: "",
    calculate_correlation: "",
    calculate_correlation_oil: "",
    bubble_point_pressure:"",
    data_point_table:[],
    fluidLab:false,
    table_fluid_lab:[],

    // reservoir black oil
    layer_pressure: "",
    layer_temperature: "",
    md_perf_depth: "",
    water_cut_fraction: "",
    state_reservoir: "",
    ipr_model: "",
    test_pressure_1_s: "",
    test_flow_rate_1_s: "",
    test_pressure_2_s: "",
    test_flow_rate_2_s: "",
    liquid_flow_rate_s: "",
    productivity_index_j_s: "",
    test_pressure_1_us: "",
    test_flow_rate_1_us: "",
    test_pressure_2_us: "",
    test_flow_rate_2_us: "",
    liquid_flow_rate_us: "",
    productivity_index_j_us: "",
    check_phases: false,
    pwf_ipr: false,
    straight_line: false,
    bubblePointPressure: "",

    // reservoir black oil
    dry_gas_reservoir_pressure: "",
    dry_gas_reservoir_temperature: "",
    dry_gas_api_gravity_hydrocarbon: "",
    dry_gas_ipr_model: "",
    dry_gas_pseudo_pressure_single_rate: {
      single_gas_rate: "",
      single_bottom_hole_flowing_pressure: "",
      single_exponent_n: "",
      single_result_coefficient_c: ""
    },
    dry_gas_pseudo_pressure_multi_rate: {
      multi_rate_data_table: {},
      multi_rate_result_coefficient_c: "",
      multi_rate_result_exponent_n: ""
    },
    dry_gas_forchheimer_reservoir_propierties: {
      forchheimer_formation_thickness: "",
      forchheimer_porosity: "",
      forchheimer_permeability_to_gas: "",
      forchheimer_drainage_area: "",
      forchheimer_dietz_shape_factor: "",
      forchheimer_perforated_interval: "",
      forchheimer_wellbore_radius: "",
      forchheimer_skin_factor: ""
    },
    dry_gas_forchheimer_multi_rate: {
      forchheimer_data_table: {},
      forchheimer_result_coefficient_a: "",
      forchheimer_result_exponent_b: ""
    },    

    // data inflow/outflow
    oil_flow_rate_min : "",
    oil_flow_rate_max : "",
    number_rates_flow : "",
    check_import_model_ipr : false,
    select_parameters: "",
    min_gor_flow : "",
    max_gor_flow : "",
    number_cases_gor_flow : "",
    min_wt_flow : "",
    max_wt_flow : "",
    number_cases_wt_flow : "",
    min_inj_flow : "",
    max_inj_flow : "",
    number_cases_inj_flow : "",
    table_inflow_outflow: [],
    table_performance_flow : [],
    minChartFlow: "",
    maxChartFlow: "",

    data_alert: [],
    data_check: [],
    data_domain_graph: [],
    type_graph_dashboard: [],
    selected_device_graph:{},
    selected_platform_graph:{},
    volumeTracker:{},
    volumeTrackerWP:{},
    volumeTrackerWIC:{},
  }

  export const REQUIRED_PROPIERTIES = [
    "well_id",
    "well_platforms",
    "well_name",
    "fluid_type",
    "region",
    "country",
    "field",
    "latitude",
    "longitude",
    "res_pressure",
    "res_temperature",
    "res_initial_gor",
    "res_oil_gravity",
    "res_gas_gravity",
    "res_H2S",
    "res_CO2",
    "res_N2",
    "layer_pressure",
    "layer_temperature",
    "md_perf_depth",
    "water_cut_fraction",
    "oil_flow_rate_min",
    "oil_flow_rate_max",
    "number_rates_flow",
    "check_import_model_ipr"
  ];

  export const INITIAL_DATA_WELL_DEVIATION = [
    {
      id: 0,
      measured_depth: "",
      tvd: "",
      inc: null,
    },
    {
      id: 1,
      measured_depth: "",
      tvd: "",
      inc: null,
    },
    {
      id: 2,
      measured_depth: "",
      tvd: "",
      inc: null,
    }
  ];

  export const INITIAL_DATA_WELL_EQUIPMENT = [
    {
      id: 0,
      name: "",
      type: "",
      measured_depth: "",
      internal_diameter: "",
      external_diameter: "",
      absolute_roughness: "",
    }
  ];

  export const INITIAL_POST_DATA_PVT = {
    API_gravity_of_oil: null,
    specific_gravity_of_gas: null,
    temp_res: null,
    rsb_init: null,
    rsb_sep: null,
    bob_sep: null,
    sGg_sep: null,
    p_sep: null,
    t_sep: null,
    API_sep: null,
    lab_temp: null,
    pb_lab: null,
    rsb_lab: null,
    rsdb_lab: null,
    bodb_lab: null,
    rsw_calc: null,
    pb_calc: null,
    specific_gravity_of_oil: null
  }

  export const INITIAL_POST_PVT_CALC = {
    p_lab: null,
    rs_lab: null,
    rs_init: null,
    co_lab: null,
    bo_lab: null,
    visc_oil_lab: null,
    press_calc: null,
    numbers_press_rows: null
  }

  export const DATA_FLUID = {
    initial_data_fluid : {
      res_pressure: "",
      res_temperature: "",
      res_initial_gor: "",
      res_oil_gravity: "",
      res_gas_gravity: "",
      res_H2S: "",
      res_CO2: "",
      res_N2: "",
      res_water_salinity: "",
      sep_pressure: "",
      sep_temperature: "",
      sep_oil_gravity: "",
      sep_gas_gravity: "",
      sep_solution_gor: "",
      sep_oil_volume_factor: ""
    },
    initialInputFluidLab : {
      temperature_lab: "",
      solution_gor_pb: "",
      bubble_point_pressure_lab: "",
      oil_fvf: "",
    },
    initialDataTableFluidLab1 : [
      {
        id: 0,
        pressureTable1: "",
        gas_oil_ratio: "",
        oil_fvf: "",
        oil_viscosity: "",
        oil_compressibility: "",
      },
      {
        id: 1,
        pressureTable1: "",
        gas_oil_ratio: "",
        oil_fvf: "",
        oil_viscosity: "",
        oil_compressibility: "",
      },
      {
        id: 2,
        pressureTable1: "",
        gas_oil_ratio: "",
        oil_fvf: "",
        oil_viscosity: "",
        oil_compressibility: "",
      },
      {
        id: 3,
        pressureTable1: "",
        gas_oil_ratio: "",
        oil_fvf: "",
        oil_viscosity: "",
        oil_compressibility: "",
      }
    ],
    initialAutomaticData : [
      {
        id: 0,
        name: "From",
        from_temperature: "",
        from_pressure: "",
      },
      {
        id: 1,
        name: "To",
        to_temperature: "",
        to_pressure: "",
      },
      {
        id: 2,
        name: "Values",
        values_temperature: 1,
        values_pressure: "",
      }
    ],
    initialDataCalculation : {
      option_data_point: "",
      calculate_correlation: "",
      calculate_correlation_oil: "",
      bubble_point_pressure:""
    },
    initialUserSelectionData: [
      {
        id: 0,
        temperature: "",
        pressure: "",
      },
      {
        id: 1,
        temperature: "",
        pressure: "",
      },
      {
        id: 2,
        temperature: "",
        pressure: "",
      }
    ],
    stateChecks : [
      {
        solution_gor: false,
        oil_fvf: false,
        oil_viscosity: false,
        oil_compressibility: false
      },
      {
        "Bubble point pressure": {
          "Laboratory": false,
          "Glaso": false,
          "Standing": false,
          "Petrosky and Farshad": false
        },
        "Solution Gas-Oil ratio": {
          "Laboratory": false,
          "Glaso": false,
          "Standing": false,
          "Petrosky and Farshad": false
        },
        "Oil formation volume factor": {
          "Laboratory": false,
          "Glaso": false,
          "Standing": false,
          "Petrosky and Farshad": false
        },
        "Oil viscosity": {
          "Laboratory": false,
          "Beggs et al., Beal": false,
          "Glaso, Beggs et al., Vazquez et al": false
        }
      },
      {
        solution_gor: false,
        oil_fvf: false,
        oil_viscosity: false,
        oil_compressibility: false
      },
      {
        "Solution GOR": false,
        "Oil FVF": false,
      }
    ],
    initialDataTablePVT : [{
        rs: [],
        press: [],
        bo: [],
        viso: [],
        co: [],
    }]
  };
  
  export const DATA_RESERVOIR = {
    initialInputReservoir : {
      layer_pressure: "",
      layer_temperature: "",
      md_perf_depth: "",
      water_cut_fraction: "",
      state_reservoir: "saturated",
      ipr_model: "",
      test_pressure_1_s: "",
      test_flow_rate_1_s: "",
      test_pressure_2_s: "",
      test_flow_rate_2_s: "",
      productivity_index_j_s: "",
      liquid_flow_rate_s: "",
      test_pressure_1_us: "",
      test_flow_rate_1_us: "",
      test_pressure_2_us: "",
      test_flow_rate_2_us: "",
      productivity_index_j_us: "",
      liquid_flow_rate_us: "",
      check_phases: "",
      pwf_ipr: false,
      straight_line: false,
      bubblePointPressure: 0,
      nameDataGraphic: "",
      dateDataGraphic: new Date().toISOString().split('T')[0],
      selectDataIPR: "",
    }
  };

  export const DATA_DOMAIN_GRAPH = {
    press_max_wpc: null,
    press_min_wpc: null,
    temp_max_wpc: null,
    temp_min_wpc: null,
    injrate_max_wic: null,
    injrate_min_wic: null,
    injpress_max_wic: null,
    injpress_min_wic: null,
    rate_max_ph: null,
    rate_min_ph: null,
    cum_max_ph: null,
    cum_min_ph: null,
    gas_min_ph: null,
    gas_max_ph: null
  }

  export const WELL_SENSOR = {
    well: [
      {
        name: "SMI-27"
      },
      {
        name: "V-191"
      }
    ]
  }

  export const WELL_SENSOR_TYPE_DEFAULT = {
    data: [
      {
        name: "SMI-27",
        sensor: "SMI-27 IP Separator"
      },
      {
        name: "V-191",
        sensor: "V-191 Test Separator"
      }
    ]
  }

  export const STATE_ALERTS = {
    alert_pc_gas_rate: "pc_gas_rate", 
    alert_pc_oil_rate: "pc_oil_rate", 
    alert_pc_water_rate: "pc_water_rate",
    alert_twc_temp: "twc_temp", 
    alert_twc_pressure: "twc_pressure",
    alert_awc_pressure: "awc_pressure",
    alert_awc_temp: "awc_temp",
    alert_alm_injection: "alm_injection",
    alert_alm_inj_gas: "alm_inj_gas",
    alert_alm_water_cut: "alm_water_cut",
    alert_bhc_pressure: "bhc_pressure",
    alert_alm_formation: "alm_formation",
    alert_alm_inj_GLR: "alm_inj_GLR",
    alert_pc_wor: "pc_wor",
    alert_pc_gor: "pc_gor",
  }

  export const INITIAL_DATA_ALERTS_GASLIFT = {
    twc_pressure_min: 0,
    twc_temp_min: 0,
    awc_pressure_min: 0,
    awc_temp_min: 0,
    pc_oil_rate_min: 0,
    pc_gas_rate_min: 0,
    pc_water_rate_min: 0,
    pc_gor_min: 0,
    pc_wor_min: 0,
    alm_injection_min: 0,
    alm_inj_gas_min: 0,
    alm_inj_GLR_min: 0,
    alm_formation_min: 0,
    alm_water_cut_min: 0,
    em_eur_min: 0,
    em_npv_min: 0,
    bhc_pressure_min : 0,
    bhc_temp_min : 0,
    twc_pressure_max: 0,
    twc_temp_max: 0,
    awc_pressure_max: 0,
    awc_temp_max: 0,
    pc_oil_rate_max: 0,
    pc_gas_rate_max: 0,
    pc_water_rate_max: 0,
    pc_gor_max: 0,
    pc_wor_max: 0,
    alm_injection_max: 0,
    alm_inj_gas_max: 0,
    alm_inj_GLR_max: 0,
    alm_formation_max: 0,
    alm_water_cut_max: 0,
    em_eur_max: 0,
    em_npv_max: 0,
    bhc_pressure_max: 0,
    bhc_temp_max: 0,
};

export const dataCheckPVT = [
  {
    name: "Bubble point pressure",
    check:false
  },
  {
    name: "Solution Gas-Oil ratio",
    check:false
  },
  {
    name: "Oil formation volume factor",
    check:false
  },
  {
    name: "Oil viscosity",
    check:false
  },
];

export const TYPE_LINE_TUNNING_GRAPH = [
  // Stroke Dasharray values
  ["15 2", "5 3", "2 4", "10 10", "2 10 5 5"],

  // Line types
  ["monotone", "monotone", "monotone", "monotone", "monotone"],

  // Stroke Width values
  [4, 2, 4, 2, 2]
];

export const INITIAL_DATA_RESERVOIR_DRY_GAS = {
  dry_gas_reservoir_pressure: "",
  dry_gas_reservoir_temperature: "",
  dry_gas_ipr_model: "",
  dry_gas_pseudo_pressure_single_rate: {
    single_gas_rate: "",
    single_bottom_hole_flowing_pressure: "",
    single_exponent_n: "",
    single_result_coefficient_c: ""
  },
  dry_gas_pseudo_pressure_multi_rate: {
    multi_rate_data_table: {},
    multi_rate_result_coefficient_c: "",
    multi_rate_result_exponent_n: ""
  },
  dry_gas_forchheimer_reservoir_propierties: {
    forchheimer_formation_thickness: "",
    forchheimer_porosity: "",
    forchheimer_permeability_to_gas: "",
    forchheimer_drainage_area: "",
    forchheimer_dietz_shape_factor: "",
    forchheimer_perforated_interval: "",
    forchheimer_wellbore_radius: "",
    forchheimer_skin_factor: ""
  },
  dry_gas_forchheimer_multi_rate: {
    forchheimer_data_table: {},
    forchheimer_result_coefficient_a: "",
    forchheimer_result_exponent_b: ""
  },
}

export const INITIAL_DATA_TABLE_MULTI_RATE = [
  {
    id: 0,
    check: true,
    gas_rate: "",
    liquid_rate: "",
    water_cut: "",
    bottom_hole_flowing_pressure: "",
  },
  {
    id: 1,
    check: true,
    gas_rate: "",
    liquid_rate: "",
    water_cut: "",
    bottom_hole_flowing_pressure: "",
  },
  {
    id: 2,
    check: true,
    gas_rate: "",
    liquid_rate: "",
    water_cut: "",
    bottom_hole_flowing_pressure: "",
  }
]

export const OPTION_IPR_MODEL = {
  forchheimer_pseudo_press_prop: 1,
  pseudo_press_single_rate: 2,
  pseudo_press_multi_rate: 3,
  forchheimer_pseudo_press_multi_rate: 4,
}

export const INITIAL_BODY_IPR_DRY_GAS = {
  press_res: 0,
  sgg_mix: 0,
  yn2: 0,
  yco2: 0,
  yh2s: 0,
  perm_gas: 0,
  temp_res: 0,
  dietz_factor: 0,
  h_res: 0,
  rw_in: 0,
  skin_factor: 0,
  area_acre: 0,
  h_perforated: 0,
  option: 0,
  gas_rate_test: 0,
  bhp_1P_test: 0,
  n_exponent_1P: 0,
  test_number: 0,
  gas_rate_test_faf: [],
  bhp_test: []
}

export const NAME_ACTION_BUTTON = {
  well: "well_gas_lift",
  pvt: "pvt_data",
  reservoir: "reservoir_data",
  vlp: "vlp_tuning",
  inflow_outflow: "inflow_outflow_data",
  history: "history_data"
}

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
  'date',
  'oil_rate',
  'gas_rate',
  'water_rate',
  'cumulative_oil',
  'cumulative_gas',
  'cumulative_water',
  'GOR',
  'w_cut'
];

export const REQUIRED_COLUMNS_TABLE_DEVIATION = [
  'measured_depth', 
  'tvd', 
  'inc'
];

export const REQUIRED_COLUMNS_TABLE_EQUIPMENT = [
  'name',
  'type',
  'measured_depth',
  'internal_diameter',
  'external_diameter',
  'absolute_roughness'
];

export const REQUIRED_COLUMNS_TABLE_LAB_PVT = [
  'pressureTable1',
  'gas_oil_ratio',
  'oil_fvf',
  'oil_viscosity',
  'oil_compressibility',
];

export const REQUIRED_COLUMNS_TEMPLATE_ALERTS = [
  'item',
  'min',
  'max'
];