

import L from 'leaflet'

export const IconLocationRed = L.icon({
  iconSize: [30, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],     
  iconUrl: 'data:image/svg+xml;base64,' + btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <defs>
        <style>
          .cls-1 {
            fill: #b52c2c;
          }
        </style>
      </defs>
      <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
      <g>
        <g id="Capa_1">
          <path class="cls-1" d="M8,16s6-5.7,6-10S11.3,0,8,0,2,2.7,2,6s6,10,6,10M8,9c-1.7,0-3-1.3-3-3s1.3-3,3-3,3,1.3,3,3-1.3,3-3,3"/>
        </g>
      </g>
    </svg>
  `),
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});