import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function CustomTooltip({ active, payload, stateContent }) {
  if (active && payload && payload.length) {
    
    const data = payload[0].payload;
    const titleColor = "#000000";
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: titleColor }}>{`Pressure: ` + data.pressureTable1 + ` (psia)`}</p>
        <p style={{ color: '#5D7EC6' }}>{stateContent.gas_oil_ratio.state && data.gas_oil_ratio ? `Solution GOR: ` + data.gas_oil_ratio + ` (SCF/STB)` : ``}</p>
        <p style={{ color: '#dc2626' }}>{stateContent.oil_fvf.state && data.oil_fvf? `Oil FVF: ` + data.oil_fvf + ` (RB/STB)` : ``}</p>
        <p style={{ color: '#FFC737' }}>{stateContent.oil_viscosity.state && data.oil_viscosity ? `Oil viscosity: ` + data.oil_viscosity + ` (cp)` : ``}</p>
        <p style={{ color: '#008A15' }}>{stateContent.oil_compressibility.state && data.oil_compressibility ? `Oil compressibility: ` + data.oil_compressibility + ` (1/psi)` : ``}</p>
      </div>
    );
  }
  return null;
}

export const ChartFluidData = ({ dataTable, stateCheck }) => {
  const linesAxis = Object.keys(stateCheck).filter(key => stateCheck[key]);
  
  const colorsByType = {
    solution_gor: "#5D7EC6",
    oil_fvf: "#dc2626",
    oil_viscosity: "#FFC737",
    oil_compressibility: "#008A15"
  };

  const namData = {
    gas_oil_ratio: {
      state: stateCheck.solution_gor,
      name: "Solution GOR (SCF/STB)"
    },
    oil_fvf: {
      state: stateCheck.oil_fvf,
      name: "Oil FVF (RB/STB)"
    },
    oil_viscosity: {
      state: stateCheck.oil_viscosity,
      name: "Oil viscosity (cp)"
    },
    oil_compressibility: {
      state: stateCheck.oil_compressibility,
      name: "Oil compressibility (1/psi)"
    }
  };

  const DataAxisY = Object.keys(namData).filter(key => namData[key].state);
  const nameAxisY = DataAxisY.map((key) => namData[key].name).join(",  ");

  const dy = -10 - (DataAxisY.length - 1) * -105;

  const getColorByType = (type) => colorsByType[type] || "#5D7EC6";

  const DomainY = () => {
    if (DataAxisY.length === 1) {
      const key = DataAxisY[0];
      const values = dataTable.map(item => parseFloat(item[key])).filter(value => !isNaN(value)); 
      const min = Math.min(...values);
      const max = Math.max(...values);
      return [min, max];
    }
    return ['auto', 'auto'];
  };

  const DomainX = (data, key) => {
    const values = data.map(item => item[key]).filter(value => value !== null && value !== undefined);
    return [Math.min(...values), Math.max(...values)];
  };
  
  const xDomain = DomainX(dataTable, 'pressureTable1');
  return (
    <>
      <div className='flex w-[1500px] h-[700px] mb-[20px]'>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={dataTable}
            margin={{
              top: 20,
              right: 100,
              left: 45,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number" 
              dataKey="pressureTable1"
              allowDataOverflow={true}
              domain={xDomain}
              label={{ 
                value: "Pressure (psia)", 
                position: "insideBottom", 
                offset: -15, 
                fontSize: 14 
              }}
              tickCount={20} // Esto divide el dominio en 5 ticks (puedes ajustarlo si es necesario)
              tick={{ fontSize: 12}}
            />
            {linesAxis.map((lineKey, index) => (
              <YAxis
                key={`yAxis-${namData[lineKey]?.name}`}
                type="number" 
                label={{
                  value: lineKey === "solution_gor" ? "Solution GOR (SCF/STB)" : namData[lineKey]?.name, // Etiqueta del eje Y
                  angle: index % 2 === 0 ? -90 : 90,
                  position: index % 2 === 0 ? 'outsideLeft' : 'outsideRight', // Coloca las etiquetas fuera
                  fill: colorsByType[lineKey],
                  style: { textAnchor: "middle" }, // Mover el label hacia arriba
                  fontSize: 14,
                  dx: index % 2 === 0 && lineKey != "oil_compressibility" ? -17 : lineKey === "oil_compressibility" && index % 2 != 0 ? 45 : index % 2 === 0 && lineKey === "oil_compressibility" ? -50 : 20,
                }}
                tick={{ fill: colorsByType[lineKey], fontSize: 12}} // Color de las marcas
                stroke={colorsByType[lineKey]} // Color del eje, igual al de la línea
                yAxisId={index} // Cada eje tiene un ID único
                orientation={index % 2 === 0 ? 'left' : 'right'} // Alterna la orientación
                interval={0} // Muestra todos los ticks
                width={80} 
                tickCount={10} 
                domain={DomainY()}
                allowDataOverflow={true}
              />
            ))}
            <Tooltip content={<CustomTooltip stateContent={namData} />} />
            {DataAxisY.map((key, index) => (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                yAxisId={index}
                stroke={getColorByType(key)}
                dot={{ r: 4 }} // Asegura que los puntos sean visibles
              />
          ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
