import { Select } from 'antd';
import React, { useState } from 'react'

export const ParametersPlungerLift = ({dataListParameters,handleChangeSelect,selectedParameters}) => {
    const { Option } = Select;

  return (
    <div className='flex flex-row w-full h-full'>
      <div className='flex flex-col w-1/2 h-full p-5'>
        <h1 className='font-bold text-pa-purple text-[16px] mb-[10px]'>Standar Paramters</h1>
        <div className='flex flex-col w-full h-full gap-5'>
          <div className='flex w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3'>
            <label htmlFor="" className='flex w-full h-full font-sans font-bold text-[14px] '>Volumetric Flow Rate</label>
          </div>
          <div className='fle w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3'>
            <label htmlFor="" className='flex w-full h-full font-sans font-bold text-[14px] '>Pipeline Pressure</label>
          </div>
          <div className='flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3'>
            <label htmlFor="" className='flex w-full h-full font-sans font-bold text-[14px] '>Tubing Pressure</label>
          </div>
          <div className='flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3'>
            <label htmlFor="" className='flex w-full h-full font-sans font-bold text-[14px] '>Casing Pressure</label>
          </div>
          <div className='flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3'>
            <label htmlFor="" className='flex w-full h-full font-sans font-bold text-[14px] '>Liquid</label>
          </div>
          <div className='flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3'>
            <label htmlFor="" className='flex w-full h-full font-sans font-bold text-[14px] '>Gas</label>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-1/2 h-full p-4'>
        <h1 className='font-bold text-pa-purple text-[16px] mb-[10px]'>Parameters Real Time</h1>
        <div className='flex flex-col gap-5'>
          <div className='w-full h-[50px] rounded-sm border border-solid border-gray-500 p-'>
            <Select
              notFoundContent={"Loading..."} // Show loading message
              name="volumetric_flow_rate"
              value={selectedParameters.volumetric_flow_rate || ""}
              placeholder="Selected option"
              className="w-full min-h-[50px] text-left"
              onChange={(value) =>
                handleChangeSelect("volumetric_flow_rate", value)
              }
            >
              {dataListParameters
                .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item.toLowerCase() === "all" ? "All" : item}
                  </Option>
                ))}
            </Select>
          </div>
          <div className='w-full h-[50px] rounded-sm border border-solid border-gray-500 p-'>
            <Select
              notFoundContent={"Loading..."} // Show loading message
              name="Pipeline_Pressure"
              value={selectedParameters.Pipeline_Pressure || ""}
              placeholder="Selected option"
              className="w-full min-h-[50px] text-left"
              onChange={(value) =>
                handleChangeSelect("Pipeline_Pressure", value)
              }
            >
              {dataListParameters
                .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item.toLowerCase() === "all" ? "All" : item}
                  </Option>
                ))}
            </Select>
          </div>
          <div className='w-full h-[50px] rounded-sm border border-solid border-gray-500 p-'>
            <Select
              notFoundContent={"Loading..."} // Show loading message
              name="Tubing_Pressure"
              value={selectedParameters.Tubing_Pressure || ""}
              placeholder="Selected option"
              className="w-full min-h-[50px] text-left"
              onChange={(value) =>
                handleChangeSelect("Tubing_Pressure", value)
              }
            >
              {dataListParameters
                .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item.toLowerCase() === "all" ? "All" : item}
                  </Option>
                ))}
            </Select>
          </div>
          <div className='w-full h-[50px] rounded-sm border border-solid border-gray-500 p-'>
            <Select
              notFoundContent={"Loading..."} // Show loading message
              name="Casing_Pressure"
              value={selectedParameters.Casing_Pressure || ""}
              placeholder="Selected option"
              className="w-full min-h-[50px] text-left"
              onChange={(value) =>
                handleChangeSelect("Casing_Pressure", value)
              }
            >
              {dataListParameters
                .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item.toLowerCase() === "all" ? "All" : item}
                  </Option>
                ))}
            </Select>
          </div>
          <div className='w-full h-[50px] rounded-sm border border-solid border-gray-500 p-'>
            <Select
              notFoundContent={"Loading..."} // Show loading message
              name="liquid"
              value={selectedParameters.liquid || ""}
              placeholder="Selected option"
              className="w-full min-h-[50px] text-left"
              onChange={(value) =>
                handleChangeSelect("liquid", value)
              }
            >
              {dataListParameters
                .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item.toLowerCase() === "all" ? "All" : item}
                  </Option>
                ))}
            </Select>
          </div>
          <div className='w-full h-[50px] rounded-sm border border-solid border-gray-500 p-'>
            <Select
              notFoundContent={"Loading..."} // Show loading message
              name="gas"
              value={selectedParameters.gas || ""}
              placeholder="Selected option"
              className="w-full min-h-[50px] text-left"
              onChange={(value) =>
                handleChangeSelect("gas", value)
              }
            >
              {dataListParameters
                .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item.toLowerCase() === "all" ? "All" : item}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  )
}
