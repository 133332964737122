import React, { useContext, useState } from "react";
import HomeIcon from "../../assets/img/ProductionAnalytics_Logo.png";
import { ReactComponent as SaveIcon } from "../../assets/icon/save.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icon/settings.svg";
import { ReactComponent as MapIcon } from "../../assets/icon/map-button.svg";
import { ReactComponent as DashbaordIcon } from "../../assets/icon/analytics-button.svg";
import { ReactComponent as WellboreIcon } from "../../assets/icon/wellbore-button.svg";
import { ReactComponent as AdddIcon } from "../../assets/icon/adds.svg";
import { Collapse, initTE, Dropdown } from "tw-elements";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Avatar, DatePicker, Space, ConfigProvider, Segmented, Tooltip, Modal, message, Select } from "antd";
import { AuthContext } from "../../auth/context/AuthContext";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { ReactComponent as SaveNavbar } from '../../assets/img/icons/save.svg';
import { ReactComponent as SettingNavbar } from '../../assets/img/icons/setting.svg';
import { ReactComponent as AddNavbar } from '../../assets/img/icons/add.svg';
import { ReactComponent as AddFilter } from '../../assets/img/icons/filterUser.svg';
import { ReactComponent as ReturnPage } from '../../assets/icon/return.svg';
import { ReactComponent as TuningNavbar } from '../../assets/img/icons/tuning.svg';
import { ReactComponent as AddParameter } from '../../assets/img/icons/assing.svg';
import { useFilters } from "../../hooks/useFilters";
import { useLocation } from "react-router-dom";
import { STATUS_DATA_GAS_LIFT, STATUS_DATA_DCA} from "../elements/types/type_response_API";
import { ChevronDown, Gear, Save } from "react-bootstrap-icons";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabDCA } from "../../hooks/useTabDCA";
import { FilterUser } from "../Dashboard/Filters/FilterUser";
import { ParametersConfig } from "../User_Control/Parameters/ParametersConfig";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { DashboardDCAContext } from "../../context/DasboardDCA/DashboardDCAContext";
import { DataGasLiftContext } from "../../context/DataGasLift/DataGasLiftContext";
import { WELL_SENSOR_DEVICE } from "../elements/types/type_dashboard";
import { DashboardSCTContext } from "../../context/DashboardSCT/DasboardSCTContext";
import { useTabIOT } from "../../hooks/useTabIOT";
import { DashboardPlottingContext } from "../../context/DashboardPlotting/DashboardPlottingContext";
import { useTabPlotting } from "../../hooks/useTabPlotting";
import { NAME_PAGES } from "../elements/types/type_pages";

const { RangePicker } = DatePicker;

const initialFilter = {
  operator: "",
  lease: "",
  region: ""
};

export const Navbar = ({ wellboreOption, addwellOption }) => {
  const { Option } = Select;
  const {         
    dataListDevicesDCA,
    dataListDevicesGaslift,
    refetchDataListDevicesDCA,
    refetchDataListDevicesGaslift
    } = useTabIOT();
  const { dataParamsPlotting, addParameters, modifyParameters } = useTabPlotting();
  const { dataPlotting } = useContext(DashboardPlottingContext)
  const { 
    dataPage, 
    dataDomainGraph, 
    openFirstModal, 
    toggleOpenFirstModal, 
    selectedWell, 
    selectedWellDCA, 
    alertDCAData, 
    dataAlertGasLift, 
    getSomeAlert, 
    someAlert, 
    deviceRealTime, 
    getDeviceRealTime, 
    checkTypeGraph, 
    selectedReservoirSCT, 
    selectedWellPL
  } = useContext(DashboardContext);
  const { dataFilterUserSCT } = useContext(DashboardSCTContext);
  const { signout } = useContext(AuthContext);
  const {dataFilterUserDCA} = useContext(DashboardDCAContext);
  const {dataFilterUserGasLift} = useContext(DataGasLiftContext);
  const { User, userDataInfo, mutationCreateFilter, refetchGetDataFilter, dataFilter, mutationUpdateFilter, getUserDataInfo } = useFilters();
  const dataFilterUser = dataFilter != null ? dataFilter?.data : null;
  const location = useLocation();
  const { dataWellGasLift, mutationUpdateDataGasLift} = useTabGaslift();
  const { dataGetWellDCA, mutationUpdateJsonWellDCA } = useTabDCA();
  const [ dataWellDCA, setDataWellDCA ] = useState();
  const [ dataWellGL, setDataWellGL ] = useState();
  const [ seletedFilter, setSeletedFilter] = useState();
  const [ modalContent, setModalContent] = useState("")
  const Navigate = useNavigate();
  const { confirm } = Modal;

  useEffect(() => {
    initTE({ Collapse, Dropdown });
    const filter = dataFilter ? dataFilter?.data : null;
    setSeletedFilter(filter)
    refetchGetDataFilter();
  }, []);

    useEffect(() => {
    initTE({ Collapse, Dropdown });
    const filter = dataFilter ? dataFilter?.data : null;
    setSeletedFilter(filter)
    refetchGetDataFilter();
  }, []);
  
  useEffect(() => {
    if(dataPage === "DashboardDCA" && !!selectedWellDCA){
      refetchDataListDevicesDCA();
    }
    if(dataPage === "DashboardGasLift" && !!selectedWell){
      refetchDataListDevicesGaslift();
    }
  },[dataPage]);

  useEffect(() => {
    if (dataFilterUser?.operator === "" && dataFilterUser?.lease === "" && dataFilterUser?.region === "") {
      getUserDataInfo(initialFilter);
    }
    else if (dataFilterUser?.operator != "" && dataFilterUser?.lease != "" && dataFilterUser?.region != "") {
      getUserDataInfo({
        ...getUserDataInfo,
        operator: dataFilterUser?.operator,
        lease: dataFilterUser?.lease,
        region: dataFilterUser?.region
      });
    }
  }, [dataFilterUser]);

  useEffect(() => {
    setTimeout(() => {
      if (dataFilter != null) {
        if(dataWellGasLift?.statusCode === STATUS_DATA_GAS_LIFT.SUCCESS){
          setDataWellGL(dataWellGasLift.data);
        }
      }
    }, 500);
  },[dataWellGasLift]);

  useEffect(() => {
    setTimeout(() => {
      if (dataFilter != null) {
        if(dataGetWellDCA?.statusCode === STATUS_DATA_DCA.SUCCESS){
          setDataWellDCA(dataGetWellDCA.data);
        }
      }
    }, 500);
  },[dataGetWellDCA]);

  const handleSave = () => {
    if(dataPage === NAME_PAGES.gas_lift.dashboard){
      if(dataAlertGasLift && selectedWell !== "null" && dataWellGL){
        const data_alert = dataAlertGasLift;
        const data_check = someAlert.gas_lift;
        const data_selected_device_graph = deviceRealTime.device_gaslift.selected_device_graph;
        const data_selected_platform_graph = deviceRealTime.device_gaslift.selected_platform_graph;
        const type_graph_dashboard = checkTypeGraph?.gas_lift;
        const filteredJson = Object.fromEntries(
          Object.entries(dataWellGL).filter(([key]) => !(key in dataDomainGraph))
        );
        const dataFinalWell = {...filteredJson, data_domain_graph: dataDomainGraph, data_alert: data_alert, data_check: data_check, selected_device_graph:data_selected_device_graph, selected_platform_graph:data_selected_platform_graph, type_graph_dashboard: type_graph_dashboard,};
        setTimeout(() => {
          mutationUpdateDataGasLift.mutate({ 
            operator:dataFilterUser?.operator, 
            lease:dataFilterUser?.lease, 
            region:dataFilterUser.region, 
            county: dataWellGL?.county,
            field: dataWellGL?.field,
            well: dataWellGL?.well_id,
            username: User?.user_name, 
            data:dataFinalWell
          })
        }, 500);
        message.success("Processing complete!");
        getSomeAlert({...someAlert, messageChange: false});
      }
    } else if(dataPage === NAME_PAGES.DCA.dashboard){
      if(alertDCAData && !!selectedWellDCA && dataGetWellDCA){
        const data_alert_dca = alertDCAData;
        const data_check_dca = someAlert.forecasting;
        const data_selected_device_graph = deviceRealTime.device_dca.selected_device_graph;
        const data_selected_platform_graph = deviceRealTime.device_dca.selected_platform_graph;
        const type_graph_dashboard = checkTypeGraph?.forecasting;
        const dataFinalWellDCA = {...dataWellDCA, data_alert: data_alert_dca, check_Alert: data_check_dca, selected_device_graph:data_selected_device_graph, selected_platform_graph:data_selected_platform_graph,type_graph_dashboard: type_graph_dashboard};
        
        setTimeout(() => {
          mutationUpdateJsonWellDCA.mutate({ 
            operator: seletedFilter?.operator, 
            lease: seletedFilter?.lease, 
            region: seletedFilter?.region, 
            county: dataWellDCA?.county,
            field: dataWellDCA?.field,
            wellid: dataWellDCA?.well_id,
            userName: User?.user_name, 
            data: dataFinalWellDCA
          })
        }, 500);
        message.success("Processing complete!");
        getSomeAlert({...someAlert, messageChange: false});
      }
    }else if(dataPage === NAME_PAGES.gas_lift.list_well || dataPage === NAME_PAGES.DCA.list_well || dataPage === NAME_PAGES.screening_tool.list_well || dataPage === NAME_PAGES.plunger_lift.list_well){
      if (userDataInfo && userDataInfo?.operator != "" && userDataInfo?.lease != "" && userDataInfo?.region != "") {
        const dataFilterCreate = Object.assign({}, userDataInfo,{
          checkedWellDCA:selectedWellDCA ? selectedWellDCA : "", 
          checkedWellGaslift:selectedWell ? selectedWell : "",
          checkedWellScreeningTool:selectedReservoirSCT ? selectedReservoirSCT : "",
          checkedWellPlungerLift:selectedWellPL ? selectedWellPL : "",
        });

        setTimeout(() => {
          mutationUpdateFilter.mutate({
            user: User.user_name,
            data: dataFilterCreate
          });
          toggleOpenFirstModal("filters")
        }, 1000);
      }
    }
    else if (dataPage === NAME_PAGES.plotting.dashboard) {
      if (userDataInfo && userDataInfo?.operator !== "" && userDataInfo?.lease !== "" && userDataInfo?.region !== "") {

        if (!dataPlotting ||
          dataPlotting.selected_platform === "" ||
          dataPlotting.selected_device.length === 0 ||
          dataPlotting.selected_parameter.length === 0 ||
          dataPlotting.optionsParmsPlotting.length === 0 ||
          dataPlotting.dataPlotting.length === 0) {

          message.error('Please validate the form information.');
          return;
        }
        if (dataParamsPlotting?.data[0]) {
          modifyParameters.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            user: User?.user_name,
            data: dataPlotting
          });
        } else {
          addParameters.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            user: User?.user_name,
            data: dataPlotting
          });
        }
      }
    }
  }

  // const [activeModal, setactiveModal] = useState(false)
  const handleCLickExpand = (option) => {
    toggleOpenFirstModal(option);
  }

  const logOut = async (e) => {
    signout();
  };

  const setUserDataFilter = () => {
    if(openFirstModal[0].enabled && openFirstModal[0].modal === "filters"){
      if (dataFilterUser === null) {
        if (userDataInfo && userDataInfo?.operator != "" && userDataInfo?.lease != "" && userDataInfo?.region != "") {
          const dataFilterCreate = Object.assign({}, userDataInfo,{selectedWellDCA:"", selectedWellGaslift:"", selectedWellScreeningTool:"", selectedWellPlungerLift:""});
          setTimeout(() => {
            mutationCreateFilter.mutate({
              user: User.user_name,
              data: dataFilterCreate
            });
            toggleOpenFirstModal("filters");
          }, 1000);
        }
      } else if (dataFilterUser != null) {
        const dataFilterUpdate = Object.assign({}, userDataInfo,{checkedWellDCA:selectedWellDCA ? selectedWellDCA :"", checkedWellGaslift: selectedWell? selectedWell: "" , checkedWellScreeningTool: selectedReservoirSCT ? selectedReservoirSCT: "" , checkedWellPlungerLift: selectedWellPL? selectedWellPL: ""});
        if (userDataInfo?.operator != "" && userDataInfo?.lease != "" && userDataInfo?.region != "") {
          setTimeout(() => {
            mutationUpdateFilter.mutate({
              user: User.user_name,
              data: dataFilterUpdate,
            });
            toggleOpenFirstModal("filters");
          }, 1000);
        }
      }
    }
    else if(openFirstModal[1].enabled && openFirstModal[1].modal === "parameters"){
      toggleOpenFirstModal("parameters");
    }
  }
  
  const closeDataFilter = () => {
    if(openFirstModal[0].enabled && openFirstModal[0].modal === "filters"){
      if (dataFilterUser?.operator != userDataInfo.operator || dataFilterUser?.lease != userDataInfo.lease || dataFilterUser?.region != userDataInfo.region) {
        getUserDataInfo({
          ...getUserDataInfo,
          operator: dataFilterUser?.operator,
          lease: dataFilterUser?.lease,
          region: dataFilterUser?.region
        });
      } 
      toggleOpenFirstModal("filters")
    }
    else if(openFirstModal[1].enabled && openFirstModal[1].modal === "parameters"){
      toggleOpenFirstModal("parameters")
    }
  }

  const handleConfirm = (link) => {
    if (selectedWell !== "null" && seletedFilter) {
      confirm({
        title: `Do you want to save the changes of the alarm's thresholds?`,
        icon: <ExclamationCircleFilled />,
        //content: 'Remember that if you eliminate the well you will not be able to recover it',
        okText: 'Save Changes',
        okButtonProps:{
          className: "bg-[#662D91]"
        },
        cancelText: 'Exit',
        onOk() {
          switch (dataPage) {
            case "DashboardGasLift":
              const data_alert = dataAlertGasLift;
              const data_selected_device_graph = deviceRealTime.device_gaslift.selected_device_graph;
              const data_selected_platform_graph = deviceRealTime.device_gaslift.selected_device_graph;
              const data_check = someAlert.gas_lift;
              const dataFinalWell = {...dataWellGL, data_alert: data_alert, data_check: data_check, selected_device_graph:data_selected_device_graph,selected_platform_graph:data_selected_platform_graph};
              setTimeout(() => {
                mutationUpdateDataGasLift.mutate({ 
                  operator: seletedFilter?.operator, 
                  lease: seletedFilter?.lease, 
                  region: seletedFilter?.region, 
                  county: dataWellGL?.county,
                  field: dataWellGL?.field,
                  well: dataWellGL?.well_id,
                  username: User?.user_name, 
                  data: dataFinalWell
                })
              }, 500);

              getSomeAlert({...someAlert, messageChange: false})
              Navigate(link);

              break;
            case "DashboardDCA":

              const data_alert_dca = alertDCAData;
              const data_check_dca = someAlert.forecasting;
              const dataFinalWellDCA = {...dataWellDCA, data_alert: data_alert_dca, check_Alert: data_check_dca};

              setTimeout(() => {
                mutationUpdateJsonWellDCA.mutate({ 
                  operator: seletedFilter?.operator, 
                  lease: seletedFilter?.lease, 
                  region: seletedFilter?.region, 
                  county: dataWellDCA?.county,
                  field: dataWellDCA?.field,
                  wellid: dataWellDCA?.well_id,
                  userName: User?.user_name, 
                  data: dataFinalWellDCA
                })
              }, 500);

              getSomeAlert({...someAlert, messageChange: false})
              Navigate(link);
              break;

            default:
              break;
          }
        },
        onCancel() {
          getSomeAlert({...someAlert, messageChange: false});
          Navigate(link);
        },
      });
    }
  }

  const selectName = dataPage === NAME_PAGES.gas_lift.dashboard ? "device_real_time_gaslift" : "device_real_time_dca";
  const selectedValue  =  dataPage === NAME_PAGES.gas_lift.dashboard ? deviceRealTime?.device_gaslift?.name : deviceRealTime?.device_dca?.name ;

  return (
    <>
      <Modal
        title="Setting filters"
        centered
        open={openFirstModal[0].enabled || openFirstModal[1].enabled}
        onOk={() => setUserDataFilter()}
        onCancel={() => closeDataFilter()}
        width={1000}
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
      >
        { openFirstModal[0].enabled && openFirstModal[0].modal === "filters" &&
          <div className='h-[400px]'>
            <FilterUser active={openFirstModal[0].enabled}></FilterUser>
          </div>
        }
        { openFirstModal[1].enabled && openFirstModal[1].modal === "parameters" &&
          <div className='h-[800px]'>
            <ParametersConfig active={openFirstModal[1].enabled} ></ParametersConfig>
          </div>
        }
      </Modal>
      <nav
        className="relative flex w-full pl-[90px] flex-nowrap items-center justify-between bg-white text-pa-font1 border-b border-solid hover:text-neutral-700 focus:text-neutral-700 lg:flex-wrap lg:justify-start p-1"
        data-te-navbar-ref
      >
        <div className="flex flex-wrap items-center justify-between w-full px-3">
          <button
            className="block px-2 bg-transparent border-0 text-neutral-300 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent9"
            aria-controls="navbarSupportedContent9"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-7 w-7"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  fillrulecliprule="evenodd"
                />
              </svg>
            </span>
          </button>

          <div
            className="!visible mt-2 hidden flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto"
            id="navbarSupportedContent9"
            data-te-collapse-item
          >
            <ul
              className="flex flex-col pl-0 mr-auto list-style-none lg:mt-1 lg:flex-row"
              data-te-navbar-nav-ref
            >
              <Tooltip placement="bottom" title={"ADD FILTER"} color={"#662D91"} >
                <li className="my-4 pl-2 lg:my-0 " data-te-nav-item-ref>
                  <AddFilter className={`w-6 h-6 ${openFirstModal[0].enabled ? "fill-red-600" : "fill-neutral-500"}  hover:fill-pa-purple bg-opacity-50`} onClick={()=>handleCLickExpand("filters")} />
                </li>
              </Tooltip>
              {location.pathname === "/Users-Parameters" && dataPage === "Users-Parameters" && (
                <Tooltip placement="bottom" title={"CONFIG PARAMETERS"} color={"#662D91"} >
                  <li className="my-4 pl-2 lg:my-0 " data-te-nav-item-ref>
                    <AddParameter className={`w-6 h-6 ${openFirstModal[1].enabled ? "fill-red-600" : "fill-neutral-500"}  hover:fill-pa-purple bg-opacity-50`} onClick={()=>handleCLickExpand("parameters")} />
                  </li>
                </Tooltip>

              )}
              {(location.pathname === "/DashboardDCA" && dataPage === "DashboardDCA") && (
                <Tooltip placement="bottom" title={"ADD WELL"} color={"#662D91"}>
                  <li className="my-4 pl-2 lg:my-0 " data-te-nav-item-ref>
                    <NavLink 
                      to={!someAlert?.messageChange ? `/${NAME_PAGES.DCA.list_well}` : "#" }
                      onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.DCA.list_well}`) : null}
                      >
                      <AddNavbar 
                        className={`w-6 h-6 hover:fill-pa-purple
                          ${!selectedWellDCA || selectedWellDCA === "null"
                            ? "fill-red-700 bg-yellow-100"
                            : "fill-neutral-500 bg-opacity-50"
                          } `}
                      />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              { (location.pathname === "/AddWellDCA/StepMenu-EditWellDCA" || location.pathname === "/AddWellDCA/StepMenu-WellDCA") && (
                <Tooltip placement="bottom" title={"RETURN"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to={`/${NAME_PAGES.DCA.list_well}`}>
                      <ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              { (location.pathname === "/AddScreeningTool/StepMenu-EditScreeningTool" || location.pathname === "/AddScreeningTool/StepMenu-ScreeningTool") && (
                <Tooltip placement="bottom" title={"RETURN"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to="/AddScreeningTool">
                      <ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(location.pathname === "/AddScreeningTool")   && (
                <Tooltip placement="bottom" title={"Screening Tools"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to="/DashboardScreeningTool">
                      <TuningNavbar className="w-6 h-6 fill-neutral-500" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(location.pathname === "/DashboardGasLift" && dataPage === "DashboardGasLift" || dataPage === '"DashboardGasLift"')  && (
                <Tooltip placement="bottom" title={"ADD PROCESS"} color={"#662D91"}>
                  <li className="my-4 pl-2 lg:my-0 " data-te-nav-item-ref>
                    <NavLink 
                      to={!someAlert?.messageChange ? `/${NAME_PAGES.gas_lift.list_well}` : "#" }
                      onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.gas_lift.list_well}`) : null}
                      >
                      {location.pathname === "/DashboardGasLift" && dataPage === "DashboardGasLift" &&
                        <AddNavbar 
                          className={`w-6 h-6 hover:fill-pa-purple
                            ${!selectedWell || selectedWell === "null"
                              ? "fill-red-700 bg-yellow-100"
                              : "fill-neutral-500 bg-opacity-50"
                            } `}
                        />
                      }
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(location.pathname !== `/${NAME_PAGES.gas_lift.list_well}` && dataPage === "AddWellGasLift" || dataPage === '"AddWellGasLift"')  && (
                <Tooltip placement="bottom" title={ "RETURN"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to={`/${NAME_PAGES.gas_lift.list_well}`}>
                      <ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(dataPage === "AddWellDCA" || dataPage === '"AddWellDCA"')   && (
                <Tooltip placement="bottom" title={"Forecasting"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to={`/${NAME_PAGES.DCA.dashboard}`}>
                      <TuningNavbar className="w-6 h-6 fill-neutral-500" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(dataPage === "AddWellGasLift" || dataPage === '"AddWellGasLift"')   && (
                <Tooltip placement="bottom" title={"GAS LIFT"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to={`/${NAME_PAGES.gas_lift.dashboard}`}>
                      <TuningNavbar className="w-6 h-6 fill-neutral-500" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(location.pathname === "/DashboardScreeningTool" && dataPage === "DashboardScreeningTool") && (
                <Tooltip placement="bottom" title={"Add / Edit Reservoir"} color={"#662D91"}  >
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink 
                      to={!someAlert?.messageChange ? "/AddScreeningTool" : "#" }
                      onClick={someAlert?.messageChange ? () => handleConfirm("/AddScreeningTool") : null}
                      >
                        <AddNavbar 
                          className={`w-6 h-6 hover:fill-pa-purple
                            ${!selectedReservoirSCT
                              ? "fill-red-700 bg-yellow-100"
                              : "fill-neutral-500 bg-opacity-50"
                            } `}
                        />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(location.pathname === "/DashboardPlungerLift" && dataPage === "DashboardPlungerLift") && (
                <Tooltip placement="bottom" title={"Add / Edit Plunger Lift"} color={"#662D91"}  >
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink 
                      to={!someAlert?.messageChange ? "/AddPlungerLift" : "#" }
                      onClick={someAlert?.messageChange ? () => handleConfirm("/AddPlungerLift") : null}
                      >
                        {/* <AddNavbar 
                          className={`w-6 h-6 hover:fill-pa-purple
                            ${!selectedReservoirSCT
                              ? "fill-red-700 bg-yellow-100"
                              : "fill-neutral-500 bg-opacity-50"
                            } `}
                        /> */}
                        <AddNavbar 
                          className={`w-6 h-6 hover:fill-pa-purple fill-neutral-500 bg-opacity-50 `}
                        />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              { (location.pathname === "/AddPlungerLift/StepMenu-WellPlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-EditWellPlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-FluidPlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-EditFluidPlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-ReservoirPlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-EditReservoirPlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-PlungerLift" || location.pathname === "/AddPlungerLift/StepMenu-EditPlungerLift") && (
                <Tooltip placement="bottom" title={"RETURN"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to="/AddPlungerLift">
                      <ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {(location.pathname === "/AddPlungerLift")   && (
                <Tooltip placement="bottom" title={"Plunger Lift"} color={"#662D91"}>
                  <li className="pl-4 my-4 lg:my-0 " data-te-nav-item-ref>
                    <NavLink to="/DashboardPlungerLift">
                      <TuningNavbar className="w-6 h-6 fill-neutral-500" />
                    </NavLink>
                  </li>
                </Tooltip>
              )}
              {dataPage === "DashboardDCA" && (
                <>
                  <li
                    className="pl-2 mb-4 lg:mb-0 lg:pl-0 lg:pr-1"
                    data-te-nav-item-ref
                    data-te-dropdown-ref
                  >
                    <ul
                      className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                      aria-labelledby="dropdownMenuButton2"
                      data-te-dropdown-menu-ref
                    >
                      <optgroup
                        label="Label 1"
                        className="pl-2 text-sm"
                      ></optgroup>
                      <li>
                        <a
                          className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                          href="#"
                          data-te-dropdown-item-ref
                        >
                          Action
                        </a>
                      </li>
                      <li>
                        <a
                          className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                          href="#"
                          data-te-dropdown-item-ref
                        >
                          Another action
                        </a>
                      </li>
                      <optgroup
                        label="Label 2"
                        className="pl-2 text-sm"
                      ></optgroup>
                      <li>
                        <a
                          className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                          href="#"
                          data-te-dropdown-item-ref
                        >
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>

          {/* <div className="relative flex items-center w-[50%]">
            <h1 htmlFor="" className="mr-2 text-white">
              SELECTED WELL
            </h1>
            <Space direction="vertical" size={12}>
              <RangePicker
                popupClassName="custom-popup-className"
                placement={"bottomRight"}
                bordered={true}
              />
            </Space>
          </div> */}

          <div className="relative  flex items-center gap-2 ">
            <Tooltip placement="bottom" title={"SETTING"} color={"#662D91"}>
              {((location.pathname === NAME_PAGES.gas_lift.routes.dashboard && !!selectedWell ) || (location.pathname === NAME_PAGES.DCA.routes.dashboard && !!selectedWellDCA) || (location.pathname === NAME_PAGES.screening_tool.routes.dashboard && !!selectedReservoirSCT) || location.pathname === NAME_PAGES.gas_lift.routes.list_well || location.pathname === NAME_PAGES.DCA.routes.list_well || location.pathname === NAME_PAGES.plotting.routes.dashboard || location.pathname === NAME_PAGES.screening_tool.routes.list_well || location.pathname === NAME_PAGES.plunger_lift.routes.list_well) && (
                <NavLink>
                  <SettingNavbar className="flex w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
                </NavLink>
              )}
            </Tooltip>

            <Tooltip placement="bottom" title={"SAVE"} color={"#662D91"}>
              {((location.pathname === NAME_PAGES.gas_lift.routes.dashboard && !!selectedWell ) || (location.pathname === NAME_PAGES.DCA.routes.dashboard && !!selectedWellDCA) || (location.pathname === NAME_PAGES.screening_tool.routes.dashboard && !!selectedReservoirSCT) || location.pathname === NAME_PAGES.gas_lift.routes.list_well || location.pathname === NAME_PAGES.DCA.routes.list_well || location.pathname === NAME_PAGES.plotting.routes.dashboard || location.pathname === NAME_PAGES.screening_tool.routes.list_well || location.pathname === NAME_PAGES.plunger_lift.routes.list_well) && (
                <NavLink> 
                  <SaveNavbar 
                    onClick={() => handleSave()}
                    className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                  />
                </NavLink>
              )}
            </Tooltip>
            <div
              className="relative "
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              <a
                className="hidden-arrow mr-4 flex items-center text-neutral-600 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                href="#"
                id="dropdownMenuButton1"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <div className="ml-2 mr-2">
                  <h1 className="font-bold text-md">{User.user_name}</h1>
                  <h2 className="text-sm">Operator Co.</h2>
                </div>
                <ChevronDown />
              </a>

              <ul
                className="optionsNavBar float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton1"
                data-te-dropdown-menu-ref
              >
                <li>
                  <a
                    className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Settings
                  </a>
                </li>
                <li>
                  <a
                    onClick={logOut}
                    className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
