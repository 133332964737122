import { serviceEndPoints } from "../../config/config";

const DB_URL_IOT = serviceEndPoints.IOT.BASE_URL_IOT;
const DB_URL_DEVICES_DCA = serviceEndPoints.DCA.BASE_URL_DEVICE;
const DB_URL_DEVICES_GASLIFT = serviceEndPoints.GASLIFT.BASE_URL_DEVICE;

export const getJsonIOT = async (operator, lease, region, wellplatform) => {
    try{
        const response = await fetch(`${DB_URL_IOT}/iotwelldata/${operator}/${lease}/${region}/${wellplatform}/200`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListPlatforms = async (operator, lease, region) => {
    try{
        const response = await fetch(`${DB_URL_IOT}/platformslist/${operator}/${lease}/${region}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListPlatformsWells = async (operator, lease, region, wellplatform) => {
    try{
        const response = await fetch(`${DB_URL_IOT}/platformwells/${operator}/${lease}/${region}/${wellplatform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListDevicesDCA = async (operator, lease, region, wellplatform) => {
    try{
        const response = await fetch(`${DB_URL_DEVICES_DCA}/platformsensorsplotting/${operator}/${lease}/${region}/${wellplatform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListDevicesGaslift = async (operator, lease, region, wellplatform) => {
    try{
        const response = await fetch(`${DB_URL_DEVICES_GASLIFT}/platformsensorsplotting/${operator}/${lease}/${region}/${wellplatform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};