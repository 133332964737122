// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadData_containerBtn__lgtqX {
	display: flex;
	justify-content: center;
	flex-direction: column;
	background: transparent;
	cursor: pointer;
}
.DownloadData_downloadTemplate__4HlNv {
	display: flex;
	fill: #737373;
	margin-left: 10px;
}
.DownloadData_downloadTemplate__4HlNv:hover {
	fill: #662d91;
}
`, "",{"version":3,"sources":["webpack://./src/components/DownloadData/DownloadData.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,sBAAsB;CACtB,uBAAuB;CACvB,eAAe;AAChB;AACA;CACC,aAAa;CACb,aAAa;CACb,iBAAiB;AAClB;AACA;CACC,aAAa;AACd","sourcesContent":[".containerBtn {\n\tdisplay: flex;\n\tjustify-content: center;\n\tflex-direction: column;\n\tbackground: transparent;\n\tcursor: pointer;\n}\n.downloadTemplate {\n\tdisplay: flex;\n\tfill: #737373;\n\tmargin-left: 10px;\n}\n.downloadTemplate:hover {\n\tfill: #662d91;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBtn": `DownloadData_containerBtn__lgtqX`,
	"downloadTemplate": `DownloadData_downloadTemplate__4HlNv`
};
export default ___CSS_LOADER_EXPORT___;
