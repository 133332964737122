import { useEffect, useState } from "react";
import { WELL_FILTER } from "../components/elements/types/type_dashboard";
import { INITIAL_DATA_CARDS_INJECTOR } from "../components/elements/types/type_injector";

const initialViewLoadingInjector = {
	loadSaveParameters: false,
};

const initialValidateAlertInjector = {
	alertCard: false,
	alertDomain: false,
};

export const useDashboardInjector = () => {
	const [dataFilterUserInjector, setDataFilterUserInjector] =
		useState(WELL_FILTER);
	const [dataCardsInjector, setDataCardsInjector] = useState(
		INITIAL_DATA_CARDS_INJECTOR,
	);
	const [viewLoadingInjector, setViewLoadingInjector] = useState(
		initialViewLoadingInjector,
	);
	const [validateAlertInjector, setValidateAlertInjector] = useState(
		initialValidateAlertInjector,
	);

	useEffect(() => {
		const getDataFilterUserInjector = JSON.parse(
			sessionStorage.getItem("dataFilterUserInjector"),
		);

		if (getDataFilterUserInjector) {
			setDataFilterUserInjector(getDataFilterUserInjector);
		}
	}, []);

	const getDataCardsInjector = (options) => {
		setDataCardsInjector(options);
	};

	const getDataFilterUserInjector = (options) => {
		setDataFilterUserInjector(options);
		sessionStorage.setItem("dataFilterUserInjector", JSON.stringify(options));
	};

	const getViewLoadingInjector = (options) => {
		setViewLoadingInjector(options);
	};

	const getValidateAlertInjector = (options) => {
		setValidateAlertInjector(options);
	};

	return {
		dataFilterUserInjector,
		dataCardsInjector,
		validateAlertInjector,
		viewLoadingInjector,
		getDataFilterUserInjector,
		getDataCardsInjector,
		getViewLoadingInjector,
		getValidateAlertInjector,
	};
};
