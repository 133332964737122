import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { message, Modal, Select, Tag } from "antd";
import { useTabDCA } from "../../hooks/useTabDCA";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { ChartPlotting } from "../../components/Plotting/GraphicsPlotting/ChartPlotting";
import { TablePlotting } from "../../components/Plotting/Complements/TablePlotting";
import {
  COLORS_LINES,
  LINE_TYPES,
} from "../../components/elements/types/type_plotting";
import { useTabPlotting } from "../../hooks/useTabPlotting";
import { DashboardPlottingContext } from "../../context/DashboardPlotting/DashboardPlottingContext";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { Loading } from "../../components/Loading";
import "./plotting.css";

export const Plotting = () => {
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { Option } = Select;
  const { getDataPage } = useContext(DashboardContext);
  const {
    dataListPlatforms,
    refetchDataListPlatforms,
    dataListDevices,
    refetchDataListDevices,
    dataPlottingIOT,
    refetchDataPlottingIOT,
    inputDataPlot,
    setInputDataPlot,
    optionsAllIOT,
    loadDataPlottingIOT,
    dataParamsPlotting,
    refetchParamsPlotting,
    getSpecificColor
  } = useTabPlotting();

  const loadingMessage = true;
  const { dataPlotting, getDataPlotting } = useContext(DashboardPlottingContext);
  const [colorMapping, setColorMapping] = useState({});

  useEffect(() => {
    getDataPage(NAME_PAGES.plotting.dashboard);
    refetchDataListPlatforms();
    refetchDataPlottingIOT();
    refetchParamsPlotting();
  }, []);

  useEffect(() => {
    if (!!dataPlotting && dataParamsPlotting?.data[0]) {
      const dataSave = {
        selected_platform: dataParamsPlotting?.data[0]?.data?.selected_platform,
        selected_device: dataParamsPlotting?.data[0]?.data?.selected_device,
        selected_parameter:
          dataParamsPlotting?.data[0]?.data?.selected_parameter,
        optionsParmsPlotting:
          dataParamsPlotting?.data[0]?.data?.optionsParmsPlotting,
        dataPlotting: dataParamsPlotting?.data[0]?.data?.dataPlotting,
      };
      const initialColorMapping = {}; 
      dataParamsPlotting?.data[0]?.data?.selected_parameter.forEach((param) => {
        const specificColor = getSpecificColor(param);
        initialColorMapping[param] = {
          color: specificColor || COLORS_LINES[param % COLORS_LINES.length],
        };
      });

      const deviceColorMap =
        dataParamsPlotting?.data[0]?.data?.dataPlotting?.reduce((acc, item) => {
          acc[item.device] = { color: item.color };
          return acc;
        }, {});

      setColorMapping(deviceColorMap || initialColorMapping);
      setInputDataPlot(dataSave);
      if (
        !!dataParamsPlotting?.data[0]?.data?.selected_platform &&
        !!dataParamsPlotting?.data[0]?.data?.selected_device &&
        !!dataParamsPlotting?.data[0]?.data?.selected_device &&
        !!dataParamsPlotting?.data[0]?.data?.optionsParmsPlotting &&
        dataParamsPlotting?.data[0]?.data?.dataPlotting
      ) {
        setTimeout(() => {
          refetchDataListDevices();
        }, 2000);
      }
    }
  }, [dataParamsPlotting]);

  useEffect(() => {
    if (loadingMessage) {
      setIsModalVisible(true); // Show the modal when the page loads
    }
  }, [loadingMessage]);

  const handleOk = () => {
    setIsModalVisible(false); // Hide the modal when the user clicks OK
  };

  const updateStartDate = (newStartDate) => setStartDate(newStartDate);
  const updateEndDate = (newEndDate) => setEndDate(newEndDate);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleChangeSelect = (nameValue, selectedValue) => {
    if (nameValue === "selected_platform") {
      setInputDataPlot((prevState) => ({
        ...prevState,
        ["selected_device"]: [],
        ["selected_parameter"]: [],
      }));
      getDataPlotting({
        ...dataPlotting,
        selected_platform: selectedValue,
        ["selected_device"]: [],
        ["selected_parameter"]: [],
      });
      setTimeout(() => {
        refetchDataListDevices();
      }, 700);
    } else if (nameValue === "selected_device") {
      setInputDataPlot((prevState) => ({
        ...prevState,
        ["selected_parameter"]: [],
      }));

      const filteredData = optionsAllIOT
        ?.filter((item) =>
          selectedValue.some((label) => item.label.startsWith(label))
        )
        .sort((a, b) => a.label.localeCompare(b.label));

      const initialColorMapping = {};
      dataParamsPlotting?.data[0]?.data?.selected_parameter.forEach((param) => {
        const specificColor = getSpecificColor(param);
        initialColorMapping[param] = {
          color: specificColor || COLORS_LINES[param % COLORS_LINES.length],
        };
      });
      const infoPlotting = filteredData?.map((item, index) => ({
        id: index,
        device: item.label,
        color: initialColorMapping,
        domain: [0, 0],
      }));

      getDataPlotting({
        ...dataPlotting,
        dataPlotting: infoPlotting,
        optionsParmsPlotting: filteredData,
        selected_device: selectedValue,
        ["selected_parameter"]: [],
      });
    }
    if (nameValue === "selected_parameter") {
      if (selectedValue?.length > 10) {
        message.warning("You can only select up to 10 options.");
        return;
      }

      const colorUsageCount = {}; // Cuenta de uso de cada color para asignar tipo de línea
      const newColorMapping = {};

      selectedValue.forEach((value) => {
        const specificColor = getSpecificColor(value); // Color específico desde el archivo
        const color =
          specificColor || COLORS_LINES[value % COLORS_LINES.length];

        // Conteo de uso de color y asignación de tipo de línea alternativo
        colorUsageCount[color] = (colorUsageCount[color] || 0) + 1;
        const lineTypeIndex = (colorUsageCount[color] - 1) % LINE_TYPES.length;
        const lineType = LINE_TYPES[lineTypeIndex];

        // Asignar color y tipo de línea al parámetro
        newColorMapping[value] = { color, lineType };
      });

      setColorMapping(newColorMapping);

      const updatedPlottingData = dataPlotting?.dataPlotting?.map((item) => {
        const values = dataPlottingIOT?.data
          ?.map((dataEntry) => dataEntry[item.device])
          ?.filter((value) => value !== null && value !== undefined);

        const offset = values.length
          ? Math.ceil(Math.max(...values) * 0.1)
          : 10;

        // Calculate initial maxValue and minValue
        let maxValue = values.length
          ? Math.ceil(Math.max(...values)) + offset
          : offset;
        let minValue = values.length
          ? Math.max(Math.floor(Math.min(...values)) - offset, -5)
          : 0;

        if (newColorMapping[item.device]) {
          return {
            ...item,
            color: newColorMapping[item.device].color,
            domain: [minValue, maxValue],
          };
        }
        return item;
      });

      getDataPlotting({
        ...dataPlotting,
        selected_parameter: selectedValue,
        dataPlotting: updatedPlottingData,
      });
    }

    setInputDataPlot((prevState) => ({
      ...prevState,
      [nameValue]: selectedValue,
    }));
  };

  const getColorIndex = (value) => {
    const index = inputDataPlot.selected_parameter?.indexOf(value);
    return index < COLORS_LINES?.length ? index : index % COLORS_LINES?.length;
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose, type } = props;

    const colorIndex = getColorIndex(value); // Calculate the colorIndex using the getColorIndex function

    // Gets the color based on colorIndex or the color defined in deviceConfig
    const deviceConfig = dataPlotting?.dataPlotting?.find(
      (item) => item.device === value
    );
    const color = deviceConfig
      ? deviceConfig.color
      : COLORS_LINES[colorIndex % COLORS_LINES?.length];

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={color} // Apply the calculated color here
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
          width: type === "parameters" ? "290px" : "150px",
          height: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", 
          whiteSpace: "nowrap", 
          overflow: "hidden",
          padding: "0 8px",
          boxSizing: "border-box",
          fontSize: "11px",
        }}
      >
        {label}
      </Tag>
    );
  };

  const hasSelectedItems = inputDataPlot?.selected_parameter?.length > 0;
  const hasSelectedItemsDevice = inputDataPlot?.selected_device?.length > 0;

  if (loadDataPlottingIOT) {
    return (
      <div className="flex flex-col w-full h-screen bg-[#F8F8F8]">
        <div className="flex">
          <Navbar wellboreOption={wellboreOption}></Navbar>
          <Sidebar className="flex h-full abosolute"></Sidebar>
        </div>
        <div className="absolute w-full h-full">
          <Loading></Loading>
        </div>
        <Modal
          title={<span style={{ color: "#000" }}>Information</span>}
          open={isModalVisible}
          onOk={handleOk} // Acción al hacer clic en OK
          okText="OK"
          footer={null} // Remove the default footer (remove "Cancel")
          closable={false} // Prevent closing with the close button
          maskClosable={false} // Prevent closing when clicking outside the
        >
          <p style={{ color: "#000" }}>
            Loading the tab, please wait a moment.
          </p>{" "}
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              onClick={handleOk}
              style={{
                padding: "10px 20px",
                backgroundColor: "#662D91",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              OK
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full bg-[#F8F8F8]">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex h-full abosolute"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full pl-[101px] p-[21px] ">
        <div className="flex flex-col w-full h-full bg-white">
          <div className="flex w-full h-[30px] justify-start text-start items-start mt-[20px] pl-[55px] mb-[20px] ">
            <div className="flex flex-row items-center justify-start w-full gap-5 text-center ">
              <h1 className="flex flex-row font-bold text-[20px]">Plotting</h1>
            </div>
          </div>
          <div className="flex flex-row w-full gap-[14px] ml-[55px] mb-[15px]">
            <div className="flex flex-col w-full max-w-[210px]">
              <label htmlFor="" className="text-[12px]">
                Platforms
              </label>
              <Select
                notFoundContent={"Loading..."} // Show loading message
                name="selected_platform"
                value={inputDataPlot.selected_platform || ""}
                placeholder="Selected option"
                className="w-full min-h-[47px] text-left"
                onChange={(value) =>
                  handleChangeSelect("selected_platform", value)
                }
              >
                {dataListPlatforms?.data
                  .sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
                  .map((item, index) => (
                    <Option key={index} value={item}>
                      {item.toLowerCase() === "all" ? "All" : item}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="flex flex-col w-full max-w-[350px] ">
              <label htmlFor="" className="text-[12px]">
                Devices
              </label>
              <Select
                disabled={!inputDataPlot?.selected_platform === ""}
                notFoundContent={"Loading..."}
                name="selected_device"
                mode="multiple"
                value={inputDataPlot.selected_device}
                tagRender={tagRender}
                placeholder="Selected option"
                className={`min-h-[47px] text-left custom-select-multiple custom-select-multiple-devices ${
                  hasSelectedItemsDevice ? "has-items" : ""
                }`}
                onChange={(value) =>
                  handleChangeSelect("selected_device", value)
                }
              >
                {dataListDevices?.data
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.filter((item) => item !== "all")
                  .map((item, index) => (
                    <Option key={index} value={item}>
                      {item.toLowerCase() === "all" ? "All" : item}
                    </Option>
                  ))}
              </Select>
            </div>

            <div className="flex flex-col w-auto min-w-[390px]">
              <label htmlFor="" className="text-[12px]">
                Parameters
              </label>
              <Select
                disabled={
                  inputDataPlot?.selected_device === "" ||
                  !inputDataPlot?.selected_device?.length
                }
                notFoundContent={"Loading..."}
                name="selected_parameter"
                mode="multiple"
                tagRender={(props) =>
                  tagRender({ ...props, type: "parameters" })
                }
                style={{
                  width: "100%",
                }}
                placeholder="Selected option"
                defaultValue={["Static Pressure"]}
                className={`min-h-[47px] text-left custom-select-multiple custom-select-multiple-parameters ${
                  hasSelectedItems ? "has-items" : ""
                }`}
                value={inputDataPlot.selected_parameter}
                onChange={(value) =>
                  handleChangeSelect("selected_parameter", value)
                }
                options={dataPlotting.optionsParmsPlotting}
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>

            {/* <div className="flex flex-col ">
              <label
                htmlFor=""
                className="w-full max-w-[140px] text-start font bold text-[12px]"
              >
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                name="test_date_pp"
                onChange={handleStartDateChange}
                className={`w-full max-w-[140px] h-[47px] px-2 rounded-[4px]  mb-[17px] border text-[12px]`}
              />
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="w-full max-w-[140px] text-start font bold text-[12px] "
              >
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                name="test_date_pp"
                onChange={handleEndDateChange}
                className={`w-full max-w-[140px] h-[47px] px-2 rounded-[4px]  mb-[17px] border text-[12px]`}
              />
            </div> */}
          </div>
          <div className="flex flex-col w-full h-full">
            <div className="flex w-full h-[100vh] justify-center">
              <ChartPlotting
                data={dataPlottingIOT ? dataPlottingIOT?.data : []}
                linesData={
                  inputDataPlot.selected_parameter
                    ? inputDataPlot.selected_parameter
                    : []
                }
                colorMapping={colorMapping}
                startDate={startDate}
                endDate={endDate}
                updateStartDate={updateStartDate}
                updateEndDate={updateEndDate}
              ></ChartPlotting>
            </div>
            <div className="w-full mt-10 ">
              <TablePlotting
                data={dataPlottingIOT ? dataPlottingIOT?.data : []}
                platform={inputDataPlot ? inputDataPlot.selected_platform : ""}
                selectedData={
                  inputDataPlot.selected_parameter
                    ? inputDataPlot.selected_parameter
                    : []
                }
                colorMapping={colorMapping}
                setColorMapping={setColorMapping}
              ></TablePlotting>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
