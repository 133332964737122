export const DATA_ALERTS = {
  gas_lift : {
      valuesAlert :{
          pc_gas_rate: false,
          twc_temp: false,
          pc_oil_rate: false,
          pc_water_rate: false,
          twc_pressure: false,
          alm_injection: false,
          alm_inj_gas: false,
          alm_inj_GLR: false,
          alm_formation: false,
          alm_water_cut: false,
          em_eur: false,
          em_npv: false,
          awc_pressure: false,
          awc_temp: false,
          bhc_pressure: false,
          bhc_temp: false,
          pc_gor: false,
          pc_wor: false,
      },
      valuesCheck: {
          pc_gas_rate: true,
          twc_temp: true,
          pc_oil_rate: true,
          pc_water_rate: true,
          twc_pressure: true,
          alm_injection: true,
          alm_inj_gas: true,
          alm_inj_GLR: true,
          alm_formation: true,
          alm_water_cut: true,
          em_eur: true,
          em_npv: true,
          awc_pressure: true,
          awc_temp: true,
          bhc_pressure: true,
          bhc_temp: true,
          pc_gor: true,
          pc_wor: true,
      },
      countAlert: 0,
      countCheck: 0
  },
  forecasting : {
      valuesAlert: {
          alm_injection: false,
          alm_inj_gas: false,
          alm_inj_GLR: false,
          alm_formation: false,
          alm_water_cut: false,
          wc_pressure: false,
          wc_temp: false,
          bhc_pressure: false,
          bhc_temp: false,
          pp_oil_rate: false,
          pp_water_rate: false,
          pp_gas_rate: false,
          pp_wor: false,
          pp_water_cute: false,
          pp_gor: false
      },
      valuesCheck: {
          alm_injection: true,
          alm_inj_gas: true,
          alm_inj_GLR: true,
          alm_formation: true,
          alm_water_cut: true,
          wc_pressure: true,
          wc_temp: true,
          bhc_pressure: true,
          bhc_temp: true,
          pp_oil_rate: true,
          pp_water_rate: true,
          pp_gas_rate: true,
          pp_wor: true,
          pp_water_cute: true,
          pp_gor: true
      },
      countAlert: 0,
      countCheck: 0
  }
}
export const DATA_SELECTED_DEVICE_GRAPH = {
  gas_lift:{
    chartProdHistory:"",
    chartWellCond:"",
    chartHole:""
  },
  forecasting_:{
    chartProdHistory:"",
    chartWellCond:"",
    chartHole:""
  }
}

export const INITIAL_TYPE_GRAPH = {
  forecasting:{
      dcaGraph: false,
      production_history: false
  },
  gas_lift:{
      wellhead_production: false,
      wellhead_injection: false,
      production_history: false
  }
}

export const WELL_SENSOR_DEVICE = {
  data: [
    {
      discretized : false,
      name: "SMI-27",
      sensor: "SMI-27 IP Separator"
    },
    {
      discretized : false,
      name: "SMI-27",
      sensor: "SMI-27 LP Separator"
    },
    {
      discretized : false,
      name: "SMI-27",
      sensor: "SMI-27 Sales Gas Meter"
    },
    {
      discretized : false,
      name: "SMI-27",
      sensor: "SMI-27 Fuel Gas Meter"
    },
    {
      discretized : false,
      name: "SMI-27",
      sensor: "SMI-27 LACT"
    },
    {
      discretized : true,
      name: "V-191",
      sensor: "V-191 Test Separator"
    },
    {
      discretized : true,
      name: "V-191",
      sensor: "V-191 Well Head Pressure"
    }
  ]
}

export const PLATFORM_REAL_TIME = {
  forecasting:{
    platform:"",
    discretized:false,
    name:"",
    selected_platform_graph:{
        chartProdHistory:"",
        chartWellCond:"",
        chartHole:""
    },
    selected_device_graph:{
      chartProdHistory:"",
      chartWellCond:"",
      chartHole:""
  }
  },
  gas_lift:{
    platform:"",
    discretized:false,
    selected_platform_graph:{
        chartProdHistory:"",
        chartWellCond:"",
        chartHole:""
    },
    selected_device_graph:{
      chartProdHistory:"",
      chartWellCond:"",
      chartHole:""
  }
  }
}

export const VALUES_TYPE_DATA_IOT = {
  corrected_accumulated_volume: "Corrected Accumulated Volume",
  corrected_current_day_volume: "Corrected Current Day Volume",
  corrected_flow_rate: "Corrected Flow Rate",
  corrected_previous_day_volume: "Corrected Previous Day Volume",
  date: "Date (MM/DD/YYYY)",
  direffential_pressure: "Differential Pressure",
  flowing_temperature: "Flowing Temperature",
  gas_flow_rate: "Gas Flow Rate",
  LSD: "LSD",
  liquid_flow_rate: "Liquid Flow Rate",
  liquid_previous_day_total: "Liquid Previous Day Total",
  meter_1_current_day_volume: "Meter 1 Current Day Volume",
  meter_1_flow_rate: "Meter 1 Flow Rate",
  meter_1_net_accumulated_volume: "Meter 1 Net Accumulated Volume",
  meter_1_previous_day_volume: "Meter 1 Previous Day Volume",
  meter_2_current_day_volume: "Meter 2 Current Day Volume",
  meter_2_flow_rate: "Meter 2 Flow Rate",
  meter_2_et_accumulated_volume: "Meter 2 Net Accumulated Volume",
  meter_2_previous_day_volume: "Meter 2 Previous Day Volume",
  separator_pressure: "Separator Pressure",
  static_pressure: "Static Pressure"
}