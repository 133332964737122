import React, { useContext, useEffect, useState } from 'react'
import { Navbar } from '../../components/layout/Navbar'
import { Sidebar } from '../../components/layout/Sidebar'
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { Checkbox, Input, message, Modal, Tooltip, Alert } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Plus } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { DashboardSCTContext } from '../../context/DashboardSCT/DasboardSCTContext';
import { useTabScreening } from '../../hooks/useTabScreening';
import { STATUS_CODE_SCREENING_TOOL } from "../../components/elements/types/type_response_API";
import { Loading } from "../../components/Loading";
import { useUsers } from '../../hooks/useUsers'
import { NAME_PAGES } from '../../components/elements/types/type_pages';

export const AddScreeningTool = () => {
  const { confirm } = Modal;
  const { getDataPage } = useContext(DashboardContext);
  const { dataFilterUserSCT, getDataFilterUserSCT } = useContext(DashboardSCTContext);
  const { 
    selectedReservoirSCT, 
    getSelectedReservoirSCT, 
    userDataInfo, 
    getOpenFirstModal
  } = useContext(DashboardContext);
  const { 
    queryListReservoir,
    refetchQueryListReservoir,
    mutationDeleteWellScreening,
    loadQueryListReservoir
  } = useTabScreening();
  const { User } = useUsers();
  const [ listWellReservoir, setListWellReservoir ] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    getDataPage(NAME_PAGES.screening_tool.list_well);
    refetchQueryListReservoir();
  }, []);
  
  useEffect(() => {
    if(queryListReservoir?.data[0] && queryListReservoir?.statusCode === STATUS_CODE_SCREENING_TOOL.SUCCESS){
      setListWellReservoir(queryListReservoir?.data[0]);
      
    }else if(!queryListReservoir?.data && queryListReservoir?.statusCode === STATUS_CODE_SCREENING_TOOL.ERROR){
      setListWellReservoir([]);
    }
  }, [queryListReservoir]);

  const hendleClickButtonCreate = () => {
    navigate(`./${NAME_PAGES.screening_tool.create_screning_tool}`);
  };
  
  const handleChangeCheckReservoir = (valueCheck, idCheck) => {
    if (!!valueCheck) {
      const dataReservoirFind = listWellReservoir?.find((item) => item.reservoir_id === idCheck);
      const dataFilterReservoir = {
        field: dataReservoirFind.field,
        state: dataReservoirFind.state,
        reservoir_id: dataReservoirFind.reservoir_id,
        reservoir_name: dataReservoirFind.reservoir_name,
        county: dataReservoirFind.country,
      };
      getDataFilterUserSCT(dataFilterReservoir);
      getSelectedReservoirSCT(idCheck);
    } else {
      getDataFilterUserSCT(null);
      getSelectedReservoirSCT(null);
    }
  };

  const handleEdit = (Reservoir) => {
    if (!!selectedReservoirSCT){
      if (
        Reservoir === dataFilterUserSCT.reservoir_id && !!dataFilterUserSCT.reservoir_id
      ) {
        navigate(`./${NAME_PAGES.screening_tool.edit_screning_tool}`);
      } else {
        info();
      }
    } else {
      message.info("Please select first a well");
    }
  };

  const handleDelete = (Reservoir) => {
    if (!!selectedReservoirSCT){
      if (
        Reservoir === dataFilterUserSCT.reservoir_id && dataFilterUserSCT.reservoir_id !== null && dataFilterUserSCT.reservoir_id !== undefined
      ) {
        confirm({
          title: "Do you want to clear the well?",
          icon: <ExclamationCircleFilled />,
          content: "Remember that if you eliminate the well not cannot be recovered",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            console.log("Ok");
            handleDeleteDataHistory(Reservoir)
          },
          onCancel() {
            console.log("Cancel");
          },
        })
      } else {
        info();
      }
    } else {
      message.info("Please select first a well");
    }
  };

  const handleDeleteDataHistory = (ReservoirID) => {
    if (!!selectedReservoirSCT && ReservoirID){
      mutationDeleteWellScreening.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        county: dataFilterUserSCT.county,
        field: dataFilterUserSCT.field,
        reservoir: dataFilterUserSCT.reservoir_id,
        userName: User.user_name,
      })
      
      setTimeout(() => {
        refetchQueryListReservoir();
        getDataFilterUserSCT(null);
        getSelectedReservoirSCT(null);
      }, 500);
    }
  };

  const info = () => {
    Modal.info({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      okText: "OK",
      okType: "danger",
      content: (
        <div>
          {(userDataInfo.operator === undefined &&
            userDataInfo.lease === undefined &&
            userDataInfo.region === undefined) ? (
            <>
              <p>Please select or fill-out the information of operator, lease, and region</p>
            </>
          ) : selectedReservoirSCT === undefined || selectedReservoirSCT === null ?
            (<p>Please select a reservoir first</p>) : (<p>please select the reservoir to edit or delete it</p>)}
          
        </div>
      ),
      onOk() { !!userDataInfo.operator === undefined || userDataInfo.lease === undefined ? getOpenFirstModal(true) : getOpenFirstModal(false)},
    });
  };
    
  return (
    <div className='flex flex-col w-full h-screen bg-white'>
      <div className='flex'>
        <Navbar/>
        <Sidebar className="flex abosolute h-full"/>
      </div>
      <div className='flex flex-col w-full h-full  gap-4 pr-2 pl-[90px] p-5'>
        <div className='flex flex-row w-full gap-5 pl-[76px] pr-[76pc]'>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={hendleClickButtonCreate}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              <Plus/>
              Add Reservoir
            </button>
          </div>
          <div>
          <Input
            className='flex w-[228px] h-[42px]'
            allowClear
            placeholder="Reservoir"
            prefix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.25)',
                }}
              />
            }
          />
          </div>
        </div>
        {
        !!Object.keys(listWellReservoir).length && !!listWellReservoir[0] ?
          <>
            <ul className="flex flex-col w-full h-full gap-y-[17px]">
              {listWellReservoir.map((item, index) => 
                <li
                  key={index}
                  className="flex flex-row w-full border-solid p-5 rounded-md"
                >
                  <div className="flex flex-row w-full h-[100px] gap-5">
                    <table className="min-w-full text-left text-sm font-light">
                      <thead className="border-b font-medium text-[15px] border border-[#BCBCCC] bg-[#FAFAFA]">
                        <tr style={{ width: '100%' }}>
                          <th scope="col" className="px-6 py-4" style={{ width: '15%' }}>
                            Select reservoir
                          </th>
                          <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                            Name
                          </th>
                          <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                            Type
                          </th> 
                          <th scope="col" className="px-6 py-4" style={{ width: '20%' }}>
                            Field
                          </th>
                          <th scope="col" className="px-6 py-4 justify-center text-center items-center" style={{ width: '20%' }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-b font-medium text-[15px] border border-[#BCBCCC]">
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4">
                            <Checkbox
                              className={"custom-checkbox"}
                              onChange={(e) => {
                                handleChangeCheckReservoir(e.target.checked, item.reservoir_id)
                                getSelectedReservoirSCT(e.target.checked ? item.reservoir_id : null);
                              }}
                              checked={selectedReservoirSCT ? item.reservoir_id === dataFilterUserSCT?.reservoir_id : false}
                            />
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-purple-800" >
                            {item.reservoir_name}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.reservoir_type}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4" >
                            {item.field}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 justify-center text-center items-center" >
                            <Tooltip title={"Update"} color={"#662D91"}>
                              <EditOutlined
                                key="editReservoir"
                                onClick={() => handleEdit(item.reservoir_id)} 
                                style={{ color: '#707070' }}/>
                            </Tooltip>
                            <span className="mx-3"></span>
                            <Tooltip title={"Delete"} color={"#662D91"}>
                              <DeleteOutlined
                                key="deleteReservoir"
                                onClick={() => handleDelete(item.reservoir_id)}
                                style={{ color: '#707070' }}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              )}
            </ul>
          </>
        : loadQueryListReservoir ? 
          <div 
            className="flex flex-col w-full h-[15rem] text-center justify-center mt-8"
          >
            <Loading/>
          </div>
        :
          <div className="flex w-full h-full text-center items-center justify-center">
            <Alert
              description="There are no reservoirs available"
              type="info"
              style={{fontSize: 20}}
            />
          </div>
        }
      </div>
    </div>
  )
}
