import React, { useEffect, useState } from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';

const ChartAuxReservoirIPR = ({dataGraph, typeGraph}) => {
  
  const [data, setData] = useState([]);

  useEffect(() => {
    if(dataGraph){

      const transformData = dataGraph?.press_decrease.map((_, index) => ({
        bhp_pseudopress: dataGraph?.bhp_pseudopress[index],
        press_decrease: dataGraph?.press_decrease[index]
      }));
      
      setData(transformData);
    }else{
      setData([]);
    }

  }, [dataGraph, typeGraph]);
  
  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const dataTooltip = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p style={{ color: '#000000' }}>{`Pressure: ` + (dataTooltip?.bhp_pseudopress).toFixed(2) + ` (psia)`}</p>
          <p style={{ color: '#d4362c' }}>{`Pseudo pressure: ` + (dataTooltip?.press_decrease).toFixed(2) + ` (psia2/cp)`}</p>
        </div>
      )
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 42,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="press_decrease" 
          label={{ 
            value: "Pressure (psia)", 
            position: "insideBottom", 
            offset: -5,
            dx: -5,
            dy: 10,
          }}
          type="number"
          tickCount={11}
        />
        <YAxis
          type="number"
          tickFormatter={(tick) => tick.toExponential(2)}
          label={{ 
            value: "Pseudo pressure (psia²/cp)", 
            angle: -90, 
            position: "insideLeft", 
            offset: -5,
            dx: -30,
            dy: 100,
          }}
          tickCount={11}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Line 
          type="monotone" 
          dataKey="bhp_pseudopress" 
          stroke="#d4362c" 
          activeDot={{ r: 8 }} 
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default ChartAuxReservoirIPR;

