import { serviceEndPoints } from "../../config/config"

const FILE_FORECAST = serviceEndPoints.FORECAST.FILE_FORECAST;
const RUN_FORECAST = serviceEndPoints.FORECAST.RUN_FORECAST;
const OUTPUT_FORECAST = serviceEndPoints.FORECAST.OUTPUT_FORECAST;

export const uploadForecast = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const lease = dataJson.lease;
        const region = dataJson.region;
        const wellid = dataJson.wellid;
        const userName = dataJson.userName;
        const data = dataJson.data;
        const response = await fetch(`${FILE_FORECAST}/uploadhistoricfordca/${operator}/${lease}/${region}/${wellid}/${userName}`, {
            method: "POST",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            },
            body: data
        });
        return response.json();
    } catch (error){
        throw new Error("Problem update the data history")
    }
} 

export const postForecast = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const region = dataJson.lease;
        const field = dataJson.region;
        const wellid = dataJson.wellid;
        const userName = dataJson.userName;
        const forecastHooorizont = dataJson.forcastHorizont;

        const response = await fetch(`${RUN_FORECAST}/dcaforecastmain1/${operator}/${region}/${field}/${wellid}/${userName}/${forecastHooorizont}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token aDGv920YoseaqkbHrMs7gJND6vKMPJICmUosFxFvw"
            }
        });
        return response.json();
    } catch (error){
        throw new Error("Problem update the data history")
    }
} 

export const getForecast = async (operator, lease, region, wellid, userName, table) => {
    try {
        const response = await fetch(`${OUTPUT_FORECAST}/outputtable/${operator}/${lease}/${region}/${wellid}/${userName}?table=${table}`, {
            method: "GET",
            headers: {
                "Authorization": "Token rXoRlnuOct6jwFQLGDG4ThbfkgvbHjSRooIzpmdKF"
            }
        })
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the data")
    }
};

export const getConfirmUploadFile = async (operator, lease, region, wellid, userName) => {
    try {
        const response = await fetch(`${OUTPUT_FORECAST}/uploadedhistoricflag/${operator}/${lease}/${region}/${wellid}/${userName}`, {
            method: "GET",
            headers: {
                "Authorization": "Token rXoRlnuOct6jwFQLGDG4ThbfkgvbHjSRooIzpmdKF"
            }
        })
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the data")
    }
};

export const postForecastSemi = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const region = dataJson.lease;
        const field = dataJson.region;
        const wellid = dataJson.wellid;
        const forecastHooorizont = dataJson.forcastHorizont;
        const startForecast = dataJson.startForecast;
        const qi_custom = dataJson.qi_custom;
        const sensitivity = dataJson.sensitivity;
        const period = dataJson.period;
        const userName = dataJson.userName;

        const response = await fetch(`${RUN_FORECAST}/dcaforecastmain2semi/${operator}/${region}/${field}/${wellid}/${userName}/${forecastHooorizont + sensitivity + period + startForecast + qi_custom}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token aDGv920YoseaqkbHrMs7gJND6vKMPJICmUosFxFvw"
            }
        });
        return response.json();
    } catch (error){
        throw new Error("Problem update the data history")
    }
} 

export const postForecastFull = async (dataJson) => {
    try {
        const operator = dataJson.operator;
        const region = dataJson.lease;
        const field = dataJson.region;
        const wellid = dataJson.wellid;
        const forecastHorizont = dataJson.forcastHorizont;
        const startForecast = dataJson.startForecast;
        const qi_custom = dataJson.qi_custom;
        const start_select = dataJson?.start_select;
        const end_select = dataJson.end_select;
        const userName = dataJson.userName;

        const response = await fetch(`${RUN_FORECAST}/dcaforecastmain2full/${operator}/${region}/${field}/${wellid}/${userName}/${forecastHorizont + start_select + end_select +startForecast + qi_custom}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token aDGv920YoseaqkbHrMs7gJND6vKMPJICmUosFxFvw"
            }
        });
        return response.json();
    } catch (error){
        throw new Error("Problem update the data history")
    }
} 