import React, { useRef, useState } from 'react'
import { ReactComponent as ImportFileIcon } from "../../../assets/img/icons/upload.svg";
import { message } from 'antd';
import { read, utils } from 'xlsx';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { REQUIRED_COLUMNS_DOCUMENT } from '../../elements/types/type_screening_tool';

export const ImportFileHistory = ({uploadfile}) => {

  const fileInputRef = useRef(null);
  const [file, setFile] = useState([]);

  const handleImport = (event) => {

    const files = event.target.files;
    const dataFile = event.target.files[0];

    if (files) {
      const file = files[0];
      //validation type file
      const validExtensions = ['.xls', '.xlsx', '.csv'];
      const fileType = file.name.split('.').pop();
      const mimeType = file.type;

      if(
        (validExtensions.includes(`.${fileType}`)) || 
        (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
        mimeType === 'application/vnd.ms-excel')
      ){

        const reader = new FileReader();
  
        reader.onload = (e) => {
          const wb = read(e.target.result);
          const sheetName = wb.SheetNames[0];
          const sheets = wb.Sheets[sheetName];
          if (sheets) {
            const jsonData = utils.sheet_to_json(sheets, { header: 1 });
            const fileColumns = jsonData[0] || [];

            const hasAllRequiredColumns = REQUIRED_COLUMNS_DOCUMENT.every(column => fileColumns.includes(column));

            if (!hasAllRequiredColumns) {
              message.error("The uploaded file is missing one or more required columns.");
              uploadfile([], []);
            } else {
              const data = utils.sheet_to_json(sheets);
              setFile(data);
              uploadfile(data, dataFile);
              message.success("Upload successfully");
            }
          }
        };
        reader.readAsArrayBuffer(file);
      }else{
        message.info('Please upload a valid Excel file');
      }
      event.target.value = '';
    }
  };

  const handleClickIcon = () => {
    fileInputRef.current.click();
  }

  return (
    <div className='flex flex-row w-[full] max-w-[592px] h-[47px] justify-start items-center text-center gap-2'>
      <input
        ref={fileInputRef}
        type='file'
        name='file'
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: "none" }}
      />
        <button
          className="flex w-[257px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
          onClick={handleClickIcon}
        >
        <div className='flex h-[32px] justify-center items-center text-center'>
            <ImportFileIcon className="w-4 h-4 fill-neutral-500 stroke-pa-purple mr-[10px] " ></ImportFileIcon>
        </div>
          Add Historical data
        </button>
    </div>
  )
} 
