import { Modal, Select, Steps, Switch, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from 'xlsx';
import { ReactComponent as IconCheckFile } from "../../assets/icon/check-circle.svg";
import { ReactComponent as IconCircle } from "../../assets/icon/circle.svg";
import { ReactComponent as IconTableForecast } from "../../assets/img/icons/tableDCA.svg";
import { AlertModal } from "../../components/AlertModal";
import { ChartProduction } from "../../components/DCA/GraphicsDCA/ChartProduction";
import { ChartResults } from "../../components/DCA/GraphicsDCA/ChartResults";
import { ImportFileForcasting } from "../../components/DCA/complements/ImportFileForcasting";
import { ModalTable } from "../../components/DCA/complements/ModalTable";
import { TableForecast } from "../../components/DCA/complements/TableForecast";
import { Loading, LoadingForecast } from "../../components/Loading";
import { ALL_TABLES_HISTORY, DATA_CREATE_WELL_DCA } from "../../components/elements/types/type_dca";
import { STATUS_CODE_DCA_TABLES, STATUS_DATA_HISTORY } from "../../components/elements/types/type_response_API";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardDCAContext } from "../../context/DasboardDCA/DashboardDCAContext";
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { useTabDCA } from "../../hooks/useTabDCA";
import { useTabIOT } from "../../hooks/useTabIOT";
import { useUsers } from "../../hooks/useUsers";
import { NAME_PAGES } from "../../components/elements/types/type_pages";

const steps = [
  {
    title: "Well",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Third",
    content: "Third-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
  {
    title: "fifth",
    content: "fifth-content",
  },
];

const initialCoordinates = {
  latitudeDegrees: "",
  latitudeMinutes: "",
  latitudeSeconds: "",
  latitudeDirection: "",
  longitudeDegrees: "",
  longitudeMinutes: "",
  longitudeSeconds: "",
  longitudeDirection: "",
};
export const StepMenuAddWellDCA = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const { User } = useUsers();
  const {
    userDataInfo,
    mutationUploadJsonWellDCA,
    mutationUpdateJsonWellDCA,
    mutationUploadDataHistory,
    dataFilterUserDCA,
    getDataFilterUserDCA,
    generateRandomID,
    refetchDataADDWellDCA,
    dataGetADDWellDCA,
    dataForecast,
    refetchDataForecast,
    mutationPostForecast,
    mutationUploadForecast,
    generateTable,
    setGenerateTable,
    setTypeTable,
    setLastIndexSelected,
    dataHistoryDCA,
    refetchDataHistoryDCA,
    refetchConfirmUploadFile,
    confirmUploadFile,
  } = useTabDCA();
  const { dataListPlatforms, refetchDataListPlatforms, dataListPlatformsWells, refetchDataListPlatformsWells, setTypeProcessPlatform} = useTabIOT();
  const { checkedValuesLastCol, getCheckedValuesFirstCol, getCheckedValuesLastCol} = useContext(DashboardDCAContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [dataPVTModelApi, setDataPVTModelApi] = useState([]);
  const [dataiprModelApi, setDataiprModelApi] = useState([]);
  const [inputDataDCA, setInputDataDCA] = useState(DATA_CREATE_WELL_DCA);
  const [fileDeviation, setFileDeviation] = useState([]);
  const { getDataPage, selectedWellDCA, getSelectedWellDCA, deviceRealTime, getDeviceRealTime } = useContext(DashboardContext)
  const [fileEquipment, setFileEquipment] = useState([]);
  const [activeModal, setActiveModal] = useState(false)
  const [current, setCurrent] = useState(0);
  const [changeGraphics, setChangeGraphics] = useState(0)
  const [status, setStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [coordinate, setCoordinate] = useState(initialCoordinates);
  const [dataForm, setDataForm] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [graph1, setGraph1] = useState(false);
  const [graph2, setGraph2 ] = useState(false);
  const [graph3, setGraph3] = useState(false);
  const [graph4, setGraph4] = useState(false);
  const [numGraphs, setNumGraphs] = useState();
  const [tables, setTables] = useState([]); 
  const [bestScenario, setBestScenario] = useState("");
  const [dataTableDCAResult,setDataTableDCAResult] = useState([]);
  const flagUploadFile = confirmUploadFile && dataHistoryDCA.statusCode != STATUS_DATA_HISTORY.STATUSCODE ? confirmUploadFile.data : false;
  const mutateLoadFile = mutationUploadForecast.isLoading || mutationUploadJsonWellDCA.isLoading || mutationUploadDataHistory.isLoading
  const mutateLoadRun = mutationPostForecast.isLoading;
  const dataListWellDCA = dataGetADDWellDCA != null ? dataGetADDWellDCA?.data : null;
  const getDataForecast = !!dataForecast  ? dataForecast?.data : "undefined";
  

  const setGraphState = (graph1, graph2, graph3, graph4) => {
    setGraph1(graph1);
    setGraph2(graph2);
    setGraph3(graph3);
    setGraph4(graph4);
  };

  useEffect(() => {
    refetchDataListPlatforms();
    refetchDataADDWellDCA();
    setInputDataDCA((prevState) => ({
      ...prevState,
      region: userDataInfo.region,
    }));
    getCheckedValuesFirstCol('');
    getDeviceRealTime({
      ...deviceRealTime,
      device_dca: {
        name:"",
        platform:"",
        discretized: false
      }
    });
  }, []);

  useEffect(() => {
    if (generateTable) {
      function getValues() {
        const values = [];
        Object.keys(ALL_TABLES_HISTORY).forEach(sensitivity => {
          const sensitivityData = ALL_TABLES_HISTORY[sensitivity];
          sensitivityData.forEach(item => {
            Object.values(item).forEach(value => {
              values.push(value);
            });
          });
        });
        return values;
      }
  
      const tableQuery = getValues();
      const handleRefetch = async () => {
        for (let i = 0; i < tableQuery.length; i++) {
          setTypeTable(tableQuery[i]);
          await new Promise(resolve => setTimeout(resolve, 500));
          await refetchDataForecast();
        }
      };
      if(dataTableDCAResult.length !== 12){
        handleRefetch();
      }
      setGenerateTable(false);
    }
  }, [generateTable]);

  useEffect(()=>{
    if(dataForecast){
      if(dataForecast.statusCode === STATUS_CODE_DCA_TABLES.SUCCESS){
        const elementsData = dataForecast?.data; 
        const tableMessage = dataForecast?.statusMessage; 
        const parts = tableMessage.split(": ");
        const tableTypeSearch = parts[parts.length - 1];
        const partsData = tableTypeSearch.split('_');
        const sensitivity = partsData[partsData.length - 2];
        const period = partsData[partsData.length - 1];
        const endElements = elementsData[elementsData.length -1]
        if(dataTableDCAResult){
          setDataTableDCAResult(prevData => {
            const newData = {
              sensitivity,
              period,
              initial_rate: endElements.qi_adj,
              b_parameter: endElements.b_adj,
              di_parameter: endElements.di_adj
            };
            const exists = prevData.some(item => item.sensitivity === newData.sensitivity && item.period === newData.period);
            return exists ? prevData : [...prevData, newData];
          });
        }
      }
    }
  },[dataForecast])


  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const onChange = (value) => {
    setCurrent(value);
  };
  
  const handleClickNext = () => {
    if (current === 0) {
      if (
        !!inputDataDCA.well_id &&
        !!inputDataDCA.well_name &&
        !!inputDataDCA.well_platforms &&
        !!inputDataDCA.fluid_type &&
        !!inputDataDCA.region &&
        !!inputDataDCA.county &&
        !!inputDataDCA.field &&
        !!coordinate.latitudeDegrees && 
        !!coordinate.latitudeMinutes && 
        !!coordinate.latitudeSeconds &&
        !!coordinate.latitudeDirection &&
        !!coordinate.longitudeDegrees && 
        !!coordinate.longitudeMinutes && 
        !!coordinate.longitudeSeconds &&
        !!coordinate.longitudeDirection 
      ) {
        //En el proceso de creacion se deben setear los parametros de los filtros para las consultas
        getDataFilterUserDCA({
          ...dataFilterUserDCA,
          county: inputDataDCA.county,
          field: inputDataDCA.field,
          well_id: inputDataDCA.well_id,
          well_platforms: inputDataDCA.well_platforms,
          well_name: inputDataDCA.well_name,
        });
        setInputEmpty(false);
        setCurrent(current + 1);
        setTimeout(() => {
          mutationUploadJsonWellDCA.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: inputDataDCA.county,
            field: inputDataDCA.field,
            wellid: inputDataDCA.well_id,
            userName: User.user_name,
            data: inputDataDCA,
          });
        }, 500);
        getSelectedWellDCA(inputDataDCA.well_id)
        setTimeout(() => {
          refetchDataHistoryDCA()
        }, 1000);
      } else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty fields please validate that the information is complete"
        );
        setInputEmpty(true);
        handleStatusChange();
      }
    }
    if (current === 1) {
      if (
        !!inputDataDCA.productForescast &&
        !!inputDataDCA.iprModel
      ) {
        setInputEmpty(false);
        setCurrent(current + 1);
        refetchConfirmUploadFile();
      } else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty fields please validate that the information is complete"
        );
        setInputEmpty(true);
        handleStatusChange();
      }
    }
    if (current === 2) {
      if (
        !!inputDataDCA.generateForeOil &&
        !!inputDataDCA.reporting_frequency 
      ) {
        setTimeout(() => {
          mutationPostForecast.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            wellid: dataFilterUserDCA.well_id,
            userName: User.user_name,
          });
        }, 500);    
        setInputEmpty(false);
        setCurrent(current + 1);
        setTypeTable([])
      } else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty fields please validate that the information is complete"
        );
        setInputEmpty(true);
        handleStatusChange();
      }
    }

    if (current === 3) {
      if (
        Object.values(checkedValuesLastCol).some(value => value === true)
      ) {
        setInputEmpty(false);
        setCurrent(current + 1);
      } else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "Please select the best scenario"
        );
        setInputEmpty(true);
        handleStatusChange();
      }
    } 
    if (current === 4) {
        setInputEmpty(false);
        setCurrent(current + 1);
    } 
  };

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };
  
  const handleClickDone = () => {
    if (
      !!inputDataDCA.well_id &&
      !!inputDataDCA.well_name &&
      !!inputDataDCA.fluid_type &&
      !!inputDataDCA.region &&
      !!inputDataDCA.county &&
      !!inputDataDCA.field &&
      !!inputDataDCA.latitude &&
      !!inputDataDCA.longitude &&
      !!inputDataDCA.productForescast &&
      !!inputDataDCA.iprModel &&
      !!inputDataDCA.generateForeOil
    ) {
      setTimeout(() => {
        mutationUpdateJsonWellDCA.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserDCA.county,
          field: dataFilterUserDCA.field,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          data: inputDataDCA,
        });
      }, 1000);
      getCheckedValuesFirstCol(false)
      getCheckedValuesLastCol(false)
      navigate(`/${NAME_PAGES.DCA.list_well}`);
      message.success("Processing complete!");
    } else {
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The table is empty. Please enter rows or load a file."); }
      setInputEmpty(true);
  };


  const handleChangeInputData = (e) => {
    const { name, value } = e.target;
    if(name === "well_platforms"){
      getDeviceRealTime({
        ...deviceRealTime,
        device_dca: {
          ...deviceRealTime.device_dca,
          platform:value
        }
      })
      setTypeProcessPlatform(value);
      setTimeout(() => {
        refetchDataListPlatformsWells();
      }, 500);
    }
    else if(name === "discretized_well"){
      getDeviceRealTime({
        ...deviceRealTime,
        device_dca: {
          ...deviceRealTime.device_dca,
          discretized: value
        }
      })
    }
    
    let dataValue = value !== "" ? Number(value) : null;
    const wellIdExists =
      name === "well_id"
        ? dataListWellDCA?.some((obj) => obj.well_id === value)
        : null;

    const wellNameExists =
      name === "well_name"
        ? dataListWellDCA?.some((obj) => obj.well_name === value)
        : null;

    if (wellIdExists) {
      message.error(
        "There is already a well with the same ID, please generate a new ID"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        well_id: null,
      }));
    } 
    else if (wellNameExists) {
      message.error(
        "There is already a well with the same Name, please select a new name"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        well_name: null,
      }));
    } 
    else if (
      (name === "oilRateLow" ||
        name === "gasRateLow" ||
        name === "WaterRateLow" ||
        name === "forecastTime") &&
      dataValue <= 0
    ) {
      message.error("The value needs to be greater than 0");
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else if (
      name === "staticResPress" &&
      (dataValue < 0 || dataValue > 50000)
    ) {
      message.error(
        "Value Static reservoir pressure (psi) can't less than 0 and greater than 50000"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else if ((name === "apiGravity" && value <= 0) || value > 150) {
      message.error(
        "Value Api Gravity can't be less than 0 and greater than 150"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else if (
      name === "gasSpecificGravity" &&
      (dataValue <= 0 || dataValue > 3)
    ) {
      message.error(
        "Value Gas specific gravity can't be less than or equal 0 and greater than 3"
      );
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else if (
      (name === "co2Fraction" || name === "n2Fraction") &&
      (dataValue < 0 || dataValue > 1)
    ) {
      message.error("Value can't be less than 0 and greater than 1");
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else if (
      (name === "numberInteraction" ||
        name === "prodMonthsModel" ||
        name === "srvPermeability" ||
        name === "fractureHalfLength" ||
        name === "stimulationEfficency") &&
      value < 0
    ) {
      message.error("Value can't be less than 0");
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    } else {
      setInputDataDCA((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    // Cambiar el estado según tu lógica
    setStatus(!status);
  };

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInputData(customEvent);
  };

  const handleChangeSelectWellData = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInputData(customEvent);
  };
  const handleClickGenerateID = () => {
    const idGenerated = generateRandomID();
    setInputDataDCA({ ...inputDataDCA, well_id: idGenerated });
  };

  const handleChangeCoor = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (
      name === "latitudeDegrees" ||
      name === "latitudeMinutes" ||
      name === "longitudeDegrees" ||
      name === "longitudeMinutes"
    ) {
      newValue = value.replace(/[^\d]/g, "");
    } else if (name === "latitudeSeconds" || name === "longitudeSeconds") {
      newValue = value.replace(/[^\d.]/g, "");
      if (value.length === 3 && value.indexOf(".") === -1) {
        newValue = value.slice(0, 2) + "." + value.slice(2); // Agrega el punto al lado del segundo número entero
      }
      const decimalIndex = newValue.indexOf(".");
      if (decimalIndex !== -1) {
        const integerPart = newValue.slice(0, decimalIndex);
        const decimalPart = newValue.slice(decimalIndex + 1, decimalIndex + 3);
        newValue = `${integerPart}.${decimalPart}`;
        if (value.indexOf(".") !== -1 && decimalPart === '') {
          newValue = value.slice(0, -1); // Quita el punto si no hay decimales
        }
      }
    }

    setCoordinate((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };
  const handleChangeSelectCoor = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeCoor(customEvent);
  };

  useEffect(() => {
    setInputDataDCA((prevState) => ({
      ...prevState,
      latitude: `${coordinate.latitudeDegrees}${"°"}${
        coordinate.latitudeMinutes
      }${"'"}${coordinate.latitudeSeconds}${'"'}${
        coordinate.latitudeDirection
      }`,
    }));

    setInputDataDCA((prevState) => ({
      ...prevState,
      longitude: `${coordinate.longitudeDegrees}${"°"}${
        coordinate.longitudeMinutes
      }${"'"}${coordinate.longitudeSeconds}${'"'}${
        coordinate.longitudeDirection
      }`,
    }));
  }, [coordinate]);

  const handleKeyDown = (event) => {
    if (event.key === "-" || event.key === '/' ) {
      event.preventDefault();
    }
  };

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const handleUploadFile = (dataFile,files) => {
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + 1, ...obj };
    });
    const dataFileEnd = dataFilePre.map((item)=>({
      ...item, 
      ['date']: convertDate(item.date) 
    }))
    if (dataFileEnd?.length > 0) {
      setTimeout(() => {
        mutationUploadDataHistory.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserDCA.county,
          field: dataFilterUserDCA.field,
          wellid: dataFilterUserDCA.well_id,
          userName: User.user_name,
          data: dataFileEnd,
        });
      }, 1000);
      setTimeout(() => {
        refetchDataHistoryDCA();
      }, 1500);
    } 
    const formData = new FormData();
      formData.append('upload', files);
    setTimeout(() => {
      mutationUploadForecast.mutate({
        operator: userDataInfo.operator,
        lease: userDataInfo.lease,
        region: userDataInfo.region,
        wellid: dataFilterUserDCA.well_id,
        userName: User.user_name,
        data: formData,
      });
    }, 1000);

  };

  const handleCLickModalTable = () => {
    if (activeModal){
      setActiveModal(false)
    }else{
      setActiveModal(true)
    }
  }

  const handleCheckChange = (checkedCheckboxes) => {
    // Build the names of the  tables based in the checkboxes selected 
    const newTables = checkedCheckboxes.map(checkbox =>
        `df_cp_hist_${checkbox.sensitivity}_${checkbox.period}`
    );
    // Update state of the tables
    setTables(newTables);
  };

  const handleCheckChangeLast = (checkedCheck) => {
    const newTables = `df_cp_forecast_${checkedCheck}`;
    setBestScenario(newTables);
  };

  const handleSwitch = (enabled) => {
    const customEvent = {
      target: {
        name: "discretized_well",
        value: enabled,
      },
    };
    handleChangeInputData(customEvent);
  }

  const downloadTable = () => {
    let nameFile = "";
    let data = [];
    nameFile = "Table_forecast";
    data = !!dataForecast ? dataForecast.data : [];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, `${nameFile}.xlsx`)
  }

  const downloadTableForecast = () => {
    let nameFile = "";
    let data = [];
    nameFile = "Table_forecast";
    data = !!dataForecast ? dataForecast.data : [];

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, `${nameFile}.xlsx`)}

    if (mutateLoadRun) {
      return (<LoadingForecast />)
    }

    return (
    <>
      <div className="flex flex-col w-full h-full bg-white min-h-screen">
        <div className="flex">
          <Navbar wellboreOption={wellboreOption}></Navbar>
          <Sidebar className="flex absolute h-full "></Sidebar>
        </div>
        <div className="flex w-full h-full">
          <Modal
            centered
            open={activeModal}
            onOk={() => handleCLickModalTable()}
            onCancel={() => handleCLickModalTable()}
            width={1200}
            className="rounded-md mt-[10px] mb-[10px]"
            okButtonProps={{
              className: "bg-[#662D91]",
            }}>
            <div className='h-full0 mt-[25px]'>
              <TableForecast active={activeModal} forecastSelected={bestScenario}></TableForecast>
            </div>
          </Modal>
        </div>
        <div className="flex w-full h-full">
          <AlertModal
            showAlert={showAlert}
            handleCloseModal={handleCloseModal}
            messageAlert={messageAlert}
            typeModal={typeAlert}
            ></AlertModal>
        </div>
        { (mutateLoadFile) 
        ?
          <Loading/>
        :
        <>
          <div className="flex w-[full] pl-[88px] text-center justify-center mt-[30px]">
            <Steps
              size="default"
              current={current}
              onChange={onChange}
              className="site-navigation-steps flex w-[900px] custom-steps"
              items={[
                {
                  title: "Well definition",
                  disabled: { status },
                  description: "",
                },
                {
                  title: "AI DCA Model ",
                  disabled: { status },
                  description: "",
                },
                {
                  title: "AI DCA Parameters",
                  disabled: { status },
                  description: "",
                },
                {
                  title: "AI DCA Results",
                  disabled: { status },
                  description: "",
                },
                {
                  title: "Forecasting",
                  disabled: { status },
                  description: "",
                },
              ]}
            />
          </div>
          <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center ">
            {current == 0 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[67%] h-[24px] gap-5">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      {" "}
                      Well definition
                    </h1>
                  </div>
                </div>
                <div className="flex w-full h-[42em] justify-center text-center mb-[0]">
                  <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-[10em]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Well ID
                    </label>
                    <div className="flex flex-row max-w-[592px]  h-[47px] gap-x-2 text-right justify-start items-center mb-[17px]">
                      <input
                        type="text"
                        name="well_id"
                        value={inputDataDCA.well_id}
                        onChange={handleChangeInputData}
                        className={`flex w-2/3 h-[47px] rounded-[4px] px-3  border border-solid  ${
                          (inputEmpty && inputDataDCA.well_id === "") ||
                          inputDataDCA.well_id === null
                            ? " border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                        } `}
                      />
                      <button
                        onClick={handleClickGenerateID}
                        className="flex w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                      >
                        Generate ID
                      </button>
                    </div>
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Platforms
                    </label>
                    <Select
                      style={{
                        backgroundColor: inputEmpty && inputDataDCA.well_platforms === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputDataDCA.well_platforms === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="well_platforms"
                      value={inputDataDCA.well_platforms || ""}
                      className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("well_platforms", value)}
                    >
                      <Option value="">Selected option</Option>
                      {dataListPlatforms?.data?.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                      ))}
                    </Select>

                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Discretized well
                    </label>
                    <div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
                      <Switch
                        style={{
                          backgroundColor: inputDataDCA.discretized_well ? "#662D91" : "#707070",
                          borderColor: inputDataDCA.discretized_well ? "#662D91" : "#707070",
                        }}
                        checked={inputDataDCA.discretized_well}
                        onChange={handleSwitch}
                      ></Switch>
                    </div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Well name
                    </label>
                    {inputDataDCA.discretized_well ?
                      <Select
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.well_name === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.well_name === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="well_name"
                        value={inputDataDCA.well_name || ""}
                        className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                        onChange={(value) => handleChangeSelect("well_name", value)}
                      >
                      <Option value="">Selected option</Option>
                      {dataListPlatformsWells?.data?.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                      ))}
                      </Select>
                    :
                      <input
                        type="text"
                        name="well_name"
                        value={inputDataDCA.well_name}
                        onChange={handleChangeInputData}
                        className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid px-3 ${
                          inputEmpty && inputDataDCA.well_name === ""
                            ? " border-red-700 bg-yellow-100"
                            : "border border-solid border-[#BCBCCC]"
                        } `}
                      />
                    }
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Fluid type
                    </label>

                    <Select
                      style={{
                        backgroundColor: inputEmpty && inputDataDCA.fluid_type === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputDataDCA.fluid_type === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="fluid_type"
                      value={inputDataDCA.fluid_type || "Selected option"}
                      className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("fluid_type", value)}
                    >
                      <Option value="oil">Oil</Option>
                      <Option value="gas">Gas</Option>
                    </Select>
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Region
                    </label>
                    <input
                      type="text"
                      name="region"
                      onKeyDown={handleKeyDown}
                      value={inputDataDCA.region}
                      onChange={handleChangeInputData}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                        inputEmpty && inputDataDCA.region === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                      } `}
                    />
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      County (if not available please add NA)
                    </label>
                    <input
                      type="text"
                      name="county"
                      onKeyDown={handleKeyDown}
                      value={inputDataDCA.county}
                      onChange={handleChangeInputData}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                        inputEmpty && inputDataDCA.county === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                      } `}
                    />
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Field
                    </label>
                    <input
                      type="text"
                      name="field"
                      onKeyDown={handleKeyDown}
                      value={inputDataDCA.field}
                      onChange={handleChangeInputData}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                        inputEmpty && inputDataDCA.field === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                      } `}
                    />
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Latitude
                    </label>
                    <div className="grid grid-cols-4 max-w-[592px]  h-[47px] gap-[15px] text-right justify-start items-center mb-[17px] ">
                      <div className="relative">
                        <input
                          placeholder={"29°"}
                          type="text"
                          name="latitudeDegrees"
                          value={coordinate.latitudeDegrees}
                          onChange={handleChangeCoor}
                          maxLength={2}
                          className={`w-full h-[47px] rounded-[4px] px-3 mb-[12px] border border-solid ${
                            inputEmpty && coordinate.latitudeDegrees === ""
                              ? "border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                          } `}
                        />
                        <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          °
                        </div>
                      </div>
                      <div className="relative">
                        <input
                          placeholder={"13'"}
                          type="text"
                          name="latitudeMinutes"
                          value={coordinate.latitudeMinutes}
                          onChange={handleChangeCoor}
                          maxLength={2}
                          className={`w-full h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${
                            inputEmpty && coordinate.latitudeMinutes === ""
                              ? "border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                          } `}
                        />
                        <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          '
                        </div>
                      </div>
                      <div className="relative">
                        <input
                          placeholder={'47.9"'}
                          type="text"
                          name="latitudeSeconds"
                          value={coordinate.latitudeSeconds}
                          onChange={handleChangeCoor}
                          maxLength={5}
                          className={`w-full h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid ${
                            inputEmpty && coordinate.latitudeSeconds === ""
                              ? "border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                          } `}
                        />
                        <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          "
                        </div>
                      </div>
                      <Select
                        style={{
                          backgroundColor:
                            inputEmpty && coordinate.latitudeDirection === ""
                              ? "#FEF9C3"
                              : "",
                          border:
                            inputEmpty && coordinate.latitudeDirection === ""
                              ? "1px solid #B91C1C"
                              : "",
                          borderRadius: "8px",
                          textAlignalign: "left",
                        }}
                        name="latitudeDirection"
                        value={coordinate.latitudeDirection || "Direction"}
                        className="w-full max-w-[592px]  h-[47px] text-left mb-[15px]"
                        onChange={(value) =>
                          handleChangeSelectCoor("latitudeDirection", value)
                        }
                      >
                        <option value="N">N</option>
                        <option value="S">S</option>
                      </Select>
                    </div>
                    <label
                      htmlFor=""
                      className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Longitude
                    </label>
                    <div className="grid grid-cols-4 max-w-[592px]  h-[47px] gap-[15px] text-right justify-start items-center mb-[17px] ">
                      <div className="relative">
                        <input
                          placeholder={"29°"}
                          type="text"
                          name="longitudeDegrees"
                          value={coordinate.longitudeDegrees}
                          onChange={handleChangeCoor}
                          maxLength={3}
                          className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                            inputEmpty && coordinate.longitudeDegrees === ""
                              ? " border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                          } `}
                        />
                        <div className="absolute top-0 right-0 flex items-center pt-1 pr-3 te" style={{ fontSize: '1.5rem' }}>
                          °
                        </div>
                      </div>
                      <div className="relative">
                        <input
                          placeholder={"13'"}
                          type="text"
                          name="longitudeMinutes"
                          value={coordinate.longitudeMinutes}
                          onChange={handleChangeCoor}
                          maxLength={2}
                          className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                            inputEmpty && coordinate.longitudeMinutes === ""
                              ? " border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                          } `}
                        />
                        <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          '
                        </div>
                      </div>
                      <div className="relative">
                        <input
                          placeholder={'47.9"'}
                          type="text"
                          name="longitudeSeconds"
                          value={coordinate.longitudeSeconds}
                          onChange={handleChangeCoor}
                          maxLength={5}
                          className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                            inputEmpty && coordinate.longitudeSeconds === ""
                              ? " border-red-700 bg-yellow-100"
                              : "border border-solid border-[#BCBCCC]"
                          } `}
                        />
                        <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                          "
                        </div>
                      </div>
                      <Select
                        style={{
                          backgroundColor:
                            inputEmpty && coordinate.longitudeDirection === ""
                              ? "#FEF9C3"
                              : "",
                          border:
                            inputEmpty && coordinate.longitudeDirection === ""
                              ? "1px solid #B91C1C"
                              : "",
                          borderRadius: "8px",
                          textAlignalign: "left",
                        }}
                        name="longitudeDirection"
                        value={coordinate.longitudeDirection || "Direction"}
                        className="w-full  h-[47px] text-left mb-[15px]"
                        onChange={(value) =>
                          handleChangeSelectCoor("longitudeDirection", value)
                        }
                      >
                        <option value="W">W</option>
                        <option value="E">E</option>
                      </Select>
                    </div>
                  </div>
                </div>
              </>
            )}
            {current == 1 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[67%] h-[24px] gap-5">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Model Definition
                    </h1>
                  </div>
                </div>
                <div className="flex w-full h-[42em] justify-center text-center  mb-[0]">
                  <div className="grid grid-cols-2 text-right w-[67%] items-center justify-start h-[10em]">
                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      Production forecast model
                    </label>
                    <Select
                      style={{
                        backgroundColor: inputEmpty && inputDataDCA.productForescast === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputDataDCA.productForescast === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="productForescast"
                      value={inputDataDCA.productForescast || "Selected option"}
                      className="min-w-[592px] h-[47px]  max-w-[592px] text-start rounded-[4px] mb-[17px]"
                      onChange={(value) =>
                        handleChangeSelectWellData("productForescast", value)
                      }
                    >
                      <Option value="hybrid_arps">Hybrid Arps</Option>
                      <Option value="physics_based" disabled>Physics based</Option>
                    </Select>

                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      IPR model
                    </label>

                    <Select
                      className="min-w-[592px] h-[47px] max-w-[592px] text-start mb-[17px]"
                      style={{
                        backgroundColor: inputEmpty && inputDataDCA.iprModel === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputDataDCA.iprModel === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="iprModel"
                      value={inputDataDCA.iprModel || "Selected option"}
                      onChange={(value) =>
                        handleChangeSelectWellData("iprModel", value)
                      }
                    >
                      <Option value="oil_vogel">Oil Vogel</Option>
                      <Option value="physics_based" disabled>Physics Based</Option>
                    </Select>
                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      Data history
                    </label>
                    <div className="flex flex-row w-full h-full justify-center text-center items-center gap-5">
                      <div className="flex w-[157px] h-[42px] justify-start items-start">
                        <ImportFileForcasting uploadfile={handleUploadFile}></ImportFileForcasting>
                      </div>
                      { flagUploadFile
                        ? 
                        <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                          <IconCheckFile className="w-5 h-5 fill-green-600"></IconCheckFile>
                          <label htmlFor="" className="font-sans text-green-600">The file has been loaded</label>
                        </div>
                        :  
                        <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                          <IconCircle className="w-5 h-5 fill-pa-purple"></IconCircle>
                          <label htmlFor="" className="font-sans text-red-600">The file has not been loaded</label>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
            )}
            {current == 2 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[67%] h-[24px] gap-5">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      DCA Parameters
                    </h1>
                  </div>
                </div>
                <div className="flex w-full h-[42em] justify-center text-center mb-[0]">
                  <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-[10em]">
                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      Generate forecast for oil production
                    </label>
                    <Select
                      className="min-w-[592px] h-[47px]  max-w-[592px] text-start mb-[17px]"
                      style={{
                        backgroundColor: inputEmpty && inputDataDCA.generateForeOil === "" ? "#B91C1C" : "",
                        border: inputEmpty && inputDataDCA.generateForeOil === "" ? "1px solid #B91C1C" : "",
                        borderRadius: "8px",
                        textAlignalign: "left"
                      }}
                      name="generateForeOil"
                      value={inputDataDCA.generateForeOil || "Selected option"}
                      onChange={(value) =>
                        handleChangeSelectWellData("generateForeOil", value)
                      }
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>

                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      Generate forecast for gas production
                    </label>
                    <Select
                      className="min-w-[592px] h-[47px] max-w-[592px] text-start mb-[17px]"
                      name="generateForeGas"
                      value={inputDataDCA.generateForeGas || "Selected option"}
                      onChange={(value) =>
                        handleChangeSelectWellData("generateForeGas", value)
                      }
                      disabled
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>

                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      Generate forecast for water production
                    </label>
                    <Select
                      className="min-w-[592px] h-[47px] max-w-[592px] text-start mb-[17px]"
                      name="generateForeOilWater"
                      value={inputDataDCA.generateForeOilWater || "Selected option"}
                      onChange={(value) =>
                        handleChangeSelectWellData("generateForeOilWater", value)
                      }
                      disabled
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    <label className="flexxam w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">
                      Reporting Frequency:
                    </label>
                    <div className="w-[592px]">
                      <Select
                        className="min-w-[592px] h-[47px]  max-w-[592px] text-start mb-[17px]"
                        style={{
                          backgroundColor: inputEmpty && inputDataDCA.reporting_frequency === "" ? "#B91C1C" : "",
                          border: inputEmpty && inputDataDCA.reporting_frequency === "" ? "1px solid #B91C1C" : "",
                          borderRadius: "8px",
                          textAlignalign: "left"
                        }}
                        name="reporting_frequency"
                        value={inputDataDCA.reporting_frequency || "Selected option"}
                        onChange={(value) =>
                          handleChangeSelectWellData("reporting_frequency", value)
                        }
                        >
                        <Option value="monthly">Monthly</Option>
                        <Option value="quarterly" disabled>Quarterly</Option>
                        <Option value="yearly" disabled>Yearly</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </>
            )}
            {current == 3 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[67%] h-[24px] gap-5">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      AI DCA Results
                    </h1>
                  </div>
                </div>
                <div className="flex flex-row w-full min-h-[960px] justify-center text-center">
                  <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-full gap-3">
                    <div className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] gap-3 col-span-2">
                      <h1>
                        (x Axis "months", y Axis STB/d)
                      </h1>
                      {/* <Tooltip title={"Download table"}>
                        <IconDownload className={`w-3.5 h-3.5 mt-[4px] hover:fill-pa-purple bg-opacity-50 cursor-pointer`} onClick={downloadTable}/>
                      </Tooltip> */}
                    </div>
                    {tables.length > 0 ? (
                        tables.map((table, index) => (
                          <div key={index} className={`flex w-full h-[400px] text-start font bold text-[15px] mb-[7px] ${tables.length === 1 ? "col-span-2" : ""}`}>
                            <ChartResults table={table} />
                          </div>
                        ))
                      ) : null
                    }
                    <div className="flex w-full text-start font bold text-[15px] mb-[7px] col-span-2">
                      <ModalTable 
                        active={activeModal} 
                        sensitivitie={inputDataDCA.sensitivities} 
                        period={inputDataDCA.periods} 
                        dataTable={dataTableDCAResult} 
                        onCheckChange={handleCheckChange} 
                        onCheckChangeLast={handleCheckChangeLast}
                      ></ModalTable>
                    </div>
                  </div>
                </div>
              </>
            )}
            {current == 4 && (
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
                  <div className="flex flex-row w-[67%] h-[24px] gap-5">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Production Forecasting
                    </h1>
                  </div>
                </div>
                <div className="flex flex-row w-full min-h-[780px] justify-center text-center" >
                  <div className="grid grid-cols-2 text-right w-[67%]  justify-start items-center h-[10em] gap-3">
                    <div className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] gap-3 col-span-2">
                      <h1 className="flex flex-row font-bold">
                      Production results
                      </h1>
                      <h1>
                        (x Axis "months", y Axis STB/d)
                      </h1>
                      <Tooltip title={"Data"}>
                        <IconTableForecast className={`w-3.5 h-3.5 mt-[4px] hover:fill-pa-purple bg-opacity-50 cursor-pointer`} onClick={handleCLickModalTable}/>
                      </Tooltip>
                      {/* <Tooltip title={"Download data"}>
                        <IconDownload className={`w-3.5 h-3.5 mt-[4px] hover:fill-pa-purple bg-opacity-50 cursor-pointer`} onClick={downloadTableForecast}/>
                      </Tooltip> */}
                    </div>
                    <div className="flex w-full h-[400px] text-start font bold text-[15px] mb-[7px] col-span-2">
                      <ChartProduction forecastSelected={bestScenario}></ChartProduction>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex w-full text-center justify-center items-center mt-auto">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                {current > 0 && (
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickPrev()}
                  >
                    Previous
                  </button>
                )}
                {current < steps.length - 1 && (
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickNext()}
                  >
                    Continue
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button
                    className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickDone()}
                  >
                    Done
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
        }
      </div>
    </>
  );
};