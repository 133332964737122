import React, { useContext, useEffect, useRef, useState } from "react";
import { read, utils } from "xlsx";
import { message } from "antd";
import { ReactComponent as IconUpload } from "../../../assets/img/icons/upload.svg";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import {
	REQUIRED_COLUMNS_TABLE_DEVIATION,
	REQUIRED_COLUMNS_TABLE_EQUIPMENT,
} from "../../elements/types/type_injector";

export const ImportFileInjector = ({ uploadfile, tableType }) => {
	const { selectedWellInjector } = useContext(DashboardContext);
	const fileInputRef = useRef(null);
	const handleImport = (event) => {
		const files = event.target.files;
		if (files) {
			const file = files[0];
			const validExtensions = [".xls", ".xlsx", ".csv"];
			const fileType = file.name.split(".").pop();
			const mimeType = file.type;

			if (
				validExtensions.includes(`.${fileType}`) ||
				mimeType ===
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
				mimeType === "application/vnd.ms-excel"
			) {
				const reader = new FileReader();

				reader.onload = (e) => {
					const wb = read(e.target.result);
					const sheetName = wb.SheetNames[0];
					const sheets = wb.Sheets[sheetName];
					if (sheets) {
						const jsonData = utils.sheet_to_json(sheets);
						switch (tableType) {
							case "well_deviation":
								const hasRequiredColumns = REQUIRED_COLUMNS_TABLE_DEVIATION.every(
									(col) => Object.keys(jsonData[0]).includes(col),
								);

								if (!hasRequiredColumns) {
									message.error(
										"The uploaded file does not contain the corresponding columns.",
									);
									return;
								}
								break;
							case "well_equipment":
								const hasRequiredColumns2 = REQUIRED_COLUMNS_TABLE_EQUIPMENT.every(
									(col) => Object.keys(jsonData[0]).includes(col),
								);

								if (!hasRequiredColumns2) {
									message.error(
										"The uploaded file does not contain the corresponding columns.",
									);
									return;
								}
								break;
							case "alerts":
								if (!!selectedWellInjector) {
									message.info("Please select the well to load the alert domain.");
									return;
								}
								break;
							default:
								break;
						}

						const noID = jsonData.map((item) => {
							const { id, ...excludeID } = item;
							return excludeID;
						});
						uploadfile(noID);
						message.success("The file data has been loaded successfully");
					}
				};
				reader.readAsArrayBuffer(file);
				event.target.value = "";
			} else {
				message.info("Please upload a valid Excel or Csv file");
			}
		}
	};
	const handleImageClick = () => {
		fileInputRef.current.click();
	};

	return (
		<div>
			<input
				ref={fileInputRef}
				type="file"
				name="file"
				id="inputGroupFile"
				onChange={handleImport}
				style={{ display: "none" }}
			/>
			<label htmlFor="fileInput">
				<IconUpload
					size={32}
					className="flex w-4 h-4 fill-neutral-500 hover:fill-pa-purple bg-opacity-50"
					onClick={handleImageClick}
				/>
			</label>
		</div>
	);
};
