import { Checkbox, message, Modal, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DATA_RESERVOIR, INITIAL_DATA_RESERVOIR_DRY_GAS, INITIAL_DATA_TABLE_MULTI_RATE, OPTION_IPR_MODEL } from "../../../components/elements/types/type_gas_lift";
import { Navbar } from "../../../components/layout/Navbar";
import { Sidebar } from "../../../components/layout/Sidebar";
import { useTabGaslift } from "../../../hooks/useTabGaslift";
import { NAME_PAGES } from '../../../components/elements/types/type_pages';
import { ReactComponent as IconDelete } from "../../../assets/img/icons/delete.svg";
import { ReactComponent as IconAdd } from "../../../assets/img/icons/add.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/icon/expand1.svg";
import ChartReservoirIPR from "../../../components/GasLift/GraphicsReservoir/ChartDryGas/ChartReservoirIPR";
import ChartReservoirLog from "../../../components/GasLift/GraphicsReservoir/ChartDryGas/ChartReservoirLog";
import ChartReservoir from "../../../components/GasLift/GraphicsReservoir/ChartDryGas/ChartReservoir";
import { InfoCircleOutlined } from "@ant-design/icons";
import circleReservoir from "../../../assets/img/reservoirFigures/circle.svg"
import squareReservoir from "../../../assets/img/reservoirFigures/square.svg"
import hexagonReservoir from "../../../assets/img/reservoirFigures/hexagon.svg"
import triangleReservoir from "../../../assets/img/reservoirFigures/triangle.svg"
import squaresCircleUpReservoir from "../../../assets/img/reservoirFigures/squares_circle_up.svg"
import squaresCircleRightReservoir from "../../../assets/img/reservoirFigures/squares_circle_right.svg"
import squareCircleRightCenterReservoir from "../../../assets/img/reservoirFigures/square_circle_right_center.svg"
import longSquaresCircleUpReservoir from "../../../assets/img/reservoirFigures/long_squares_circle_up.svg"
import longSquaresCircleRightReservoir from "../../../assets/img/reservoirFigures/long_squares_circle_right.svg"
import { STATUS_CODE_CALCULATE_IPR, STATUS_DATA_GAS_LIFT } from "../../../components/elements/types/type_response_API";
import ChartAuxReservoirIPR from "../../../components/GasLift/GraphicsReservoir/ChartDryGas/ChartAuxReservoirIPR";

const dataDietzOptions = {
  firs_column: [
    { img: circleReservoir, value: "31.620", name: "circleReservoir" },
    { img: squareReservoir, value: "30.833", name: "squareReservoir"},
    { img: hexagonReservoir, value: "31.600", name: "hexagonReservoir" },
    { img: triangleReservoir, value: "27.600", name: "triangleReservoir" },
  ],
  second_column: [
    { img: squaresCircleUpReservoir, value: "10.830", name: "squaresCircleUpReservoir" },
    { img: squaresCircleRightReservoir, value: "4.514", name: "squaresCircleRightReservoir" },
    { img: squareCircleRightCenterReservoir, value: "2.077", name: "squareCircleRightCenterReservoir" },
    { img: longSquaresCircleUpReservoir, value: "2.690", name: "longSquaresCircleUpReservoir" },
    { img: longSquaresCircleRightReservoir, value: "0.230", name: "longSquaresCircleRightReservoir" },
  ]
}

const initialOpcionDietz = {
  name: "circleReservoir",
  value: 31.620
}

export const StepMenuReservoirDryGas = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const {
    User,
    userDataInfo,
    dataFilterUserGasLift,
    refetchDataWellGasLift,
    refetchDataIPRDryGas,
    dataWellGasLift,
    queryDataIPRDryGas,
    mutationUpdateDataGasLift,
    mutationDataIPRDryGas
  } = useTabGaslift();

  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [inputReservoir, setInputReservoir] = useState(INITIAL_DATA_RESERVOIR_DRY_GAS);
  const [dataTableForchheimerMultiRate, setDataTableForchheimerMultiRate] = useState(INITIAL_DATA_TABLE_MULTI_RATE);
  const [dataTableMultiRate, setDataTableMultiRate] = useState(INITIAL_DATA_TABLE_MULTI_RATE);
  const [typeModalGraph, setTypeModalGraph] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalDietz, setOpenModalDietz] = useState(false);
  const [checkFigureDietz, setCheckFigureDietz] = useState(initialOpcionDietz);
  const [dataInformationEnd, setDataInformationEnd] = useState([]);
  const [executeCalc, setExecuteCalc] = useState(false);

  useEffect(() => {
    refetchDataWellGasLift();
  }, []);

  useEffect(() => {
    if (dataWellGasLift) {
      if (dataWellGasLift?.statusCode === STATUS_DATA_GAS_LIFT.SUCCESS) {
        const filterDataCreate = dataWellGasLift?.data;
        const filterDataEnd = INITIAL_DATA_RESERVOIR_DRY_GAS
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
        );
        setDataInformationEnd(filteredJson);
      }
    }
  }, [dataWellGasLift]);

  useEffect(() => {
    setTimeout(() => {
      if(queryDataIPRDryGas){
        if (queryDataIPRDryGas?.statusCode === STATUS_CODE_CALCULATE_IPR.SUCCESS && executeCalc) {
          const dataQueryIPR = queryDataIPRDryGas?.data ? queryDataIPRDryGas?.data : [];
  
          switch (inputReservoir.dry_gas_ipr_model) {
            case "pseudo_press_single_rate":
              if (dataQueryIPR?.c_factor_1P) {
                setInputReservoir({
                  ...inputReservoir,
                  dry_gas_pseudo_pressure_single_rate: {
                    ...inputReservoir.dry_gas_pseudo_pressure_single_rate,
                    single_result_coefficient_c: Number(dataQueryIPR?.c_factor_1P).toExponential(4),
                  }
                })
              }
              break;
            case "forchheimer_pseudo_press_prop":
              
              break;
            case "pseudo_press_multi_rate":
              if (dataQueryIPR?.c_factor_faf && dataQueryIPR?.n_exponent_faf) {
                setInputReservoir({
                  ...inputReservoir,
                  dry_gas_pseudo_pressure_multi_rate: {
                    ...inputReservoir.dry_gas_pseudo_pressure_multi_rate,
                    multi_rate_result_coefficient_c: Number(dataQueryIPR?.c_factor_faf).toExponential(4),
                    multi_rate_result_exponent_n: Number(dataQueryIPR?.n_exponent_faf).toFixed(4)
                  }
                })
              }
              break;
            case "forchheimer_pseudo_press_multi_rate":
              
              break;
            default:
              break;
          }
          message.success("Data calculated correctly");
          return
        }else if(queryDataIPRDryGas?.statusType !== STATUS_CODE_CALCULATE_IPR.SUCCESS && executeCalc){
          message.error("There are incorrect fields, please validate that the information is complete")
          return
        }else{
        }
      }
      setExecuteCalc(false);
    }, 500);
  }, [queryDataIPRDryGas, executeCalc]);

  const handleChangeSelectDryGas = (name, valueData) => {
    if (!!name && !!valueData) {
      setInputReservoir({...inputReservoir, [name]: valueData});    
    }
  };
  
  const handleChangeDataReservoir = (e) => {
    const { name, value } = e.target;

    let dataValue = value !== "" ? Number(value) : null;

    setInputReservoir({...inputReservoir, [name]: dataValue})

  };

  const handleChangeDataOptionReservoir = (e) => {
    const { name, value } = e.target;

    let dataValue = value !== "" ? Number(value) : null;

    if (inputReservoir.dry_gas_ipr_model === "pseudo_press_single_rate") {
      setInputReservoir(
        {
          ...inputReservoir,
          dry_gas_pseudo_pressure_single_rate: {
            ...inputReservoir.dry_gas_pseudo_pressure_single_rate,
            [name]: dataValue
          }
        }
      )
    }else if(inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_prop"){
      setInputReservoir(
        {
          ...inputReservoir,
          dry_gas_forchheimer_reservoir_propierties: {
            ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
            [name]: dataValue
          }
        }
      )
    }
  };

  const handleInputReservoirBlur = (e) => {
    const {name, value} = e.target;
    let dataValue = value !== "" ? Number(value) : null;
    
    if (name === "dry_gas_reservoir_pressure" && (dataValue < 14.7 || dataValue > 25000) && !!inputReservoir.dry_gas_reservoir_pressure) {
      message.error("Reservoir pressure must be between 14.7 and 25000 psia");
      setInputReservoir({...inputReservoir, [name]: null})
    }else if (name === "dry_gas_reservoir_temperature" && (dataValue < 70 || dataValue > 350) && !!inputReservoir.dry_gas_reservoir_temperature) {      
      message.error("Reservoir temperature must be between 70 and 350 deg F");
      setInputReservoir({...inputReservoir, [name]: null})
    }else if(name === "single_gas_rate" && (dataValue < 0.1 || dataValue > 150000) && inputReservoir.dry_gas_pseudo_pressure_single_rate.single_gas_rate !== null){
      message.error("Gas rate must be between 0.1 and 150,000 Mscf/d");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_pseudo_pressure_single_rate: {
          ...inputReservoir.dry_gas_pseudo_pressure_single_rate,
          [name]: null
        }
      })
    }else if(name === "single_bottom_hole_flowing_pressure" && (dataValue < 14.7 || dataValue > 25000) && !!inputReservoir.dry_gas_pseudo_pressure_single_rate.single_bottom_hole_flowing_pressure){
      message.error("Pressure values must be between 14.7 and 25,000 psia");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_pseudo_pressure_single_rate: {
          ...inputReservoir.dry_gas_pseudo_pressure_single_rate,
          [name]: null
        }
      })
    }else if(name === "single_exponent_n" && (dataValue < 0.25 || dataValue > 1.5) && !!inputReservoir.dry_gas_pseudo_pressure_single_rate.single_exponent_n){
      message.error("The value of n ranges between 0.5 and 1.0. A value of 0.5 indicates turbulent flow throughout the drainage area, a value of 1.0 indicates laminar flow");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_pseudo_pressure_single_rate: {
          ...inputReservoir.dry_gas_pseudo_pressure_single_rate,
          [name]: null
        }
      })
    }else if((name === "forchheimer_formation_thickness" || name === "forchheimer_perforated_interval") && (dataValue < 0.1 || dataValue > 2000) && (name === "forchheimer_formation_thickness" && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_formation_thickness !== null || name === "forchheimer_perforated_interval" && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_perforated_interval !== null)){
      message.error("The values must be between 0.1 and 2000 feet");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_forchheimer_reservoir_propierties: {
          ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
          [name]: null
        }
      })
    }else if(name === "forchheimer_porosity" && (dataValue < 0.1 || dataValue > 50) && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_porosity !== null){
      message.error("Porosity values must be between 0.1 and 50 %");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_forchheimer_reservoir_propierties: {
          ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
          [name]: null
        }
      })
    }else if((name === "forchheimer_permeability_to_gas" || name === "forchheimer_drainage_area") && (dataValue <= 0) && (name === "forchheimer_permeability_to_gas" && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_permeability_to_gas !== null || name === "forchheimer_drainage_area" && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_drainage_area !== null)){
      message.error("The value ​​must be greater than 0");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_forchheimer_reservoir_propierties: {
          ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
          [name]: null
        }
      })
    }else if(name === "forchheimer_wellbore_radius" && (dataValue < 0.1 || dataValue > 36) && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_wellbore_radius !== null){
      message.error("Wellbore radius values must be between 0.1 and 36 inches");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_forchheimer_reservoir_propierties: {
          ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
          [name]: null
        }
      })
    }
    else if(name === "forchheimer_skin_factor" && (dataValue < -100 || dataValue > 1000) && inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_skin_factor !== null){
      message.error("Skin factor values must be between -100 and 1000 dimensionless");
      setInputReservoir({
        ...inputReservoir,
        dry_gas_forchheimer_reservoir_propierties: {
          ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
          [name]: null
        }
      })
    }
  }

  const handleChangeTableDryGas = (e, id) => {

    const { name, value } = e.target;
    
    let dataValue = value !== "" ? Number(value) : "";
    
    if (inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_multi_rate") {
      if (name === "check") {
        setDataTableForchheimerMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: e.target.checked } : item
        ));
      }else if(name === "liquid_rate" && (dataValue > 10000)){
        message.error("Liquid rate must be between 0 and 10000 STB/D");
        setDataTableForchheimerMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
        ));
      }else{
        setDataTableForchheimerMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: value } : item
        ));
      }
    }else if(inputReservoir.dry_gas_ipr_model === "pseudo_press_multi_rate"){      
      if (name === "check") {
        setDataTableMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: e.target.checked } : item
        ));
      }else if(name === "liquid_rate" && (dataValue > 10000)){
        message.error("Liquid rate must be between 0 and 10000 STB/D");
        setDataTableMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
        ));
      }else{
        setDataTableMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: value } : item
        ));
      }
    }
  }

  const handleInputReservoirTableBlur = (e, id) => {
    const { name, value } = e.target;
    
    let dataValue = value !== "" ? Number(value) : "";
    let position = "";

    if (inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_multi_rate") {
      position = dataTableForchheimerMultiRate.findIndex((item) => item.id === id);

      if (name === "gas_rate" && (dataValue < 0.1 || dataValue > 150000) && dataTableForchheimerMultiRate[position][name] !== null) {
        message.error("Gas rate must be between 0.1 and 150,000 Mscf/d");
        setDataTableForchheimerMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
        ));
      }else if(name === "bottom_hole_flowing_pressure" && (dataValue < 14.7 || dataValue > 25000) && !!dataTableForchheimerMultiRate[position][name]){
        message.error("Pressure values must be between 14.7 and 25,000 psia");
        setDataTableForchheimerMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
        ));
      }

    }else if(inputReservoir.dry_gas_ipr_model === "pseudo_press_multi_rate"){
      position = dataTableMultiRate.findIndex((item) => item.id === id);

      if (name === "gas_rate" && (dataValue < 0.1 || dataValue > 150000) && dataTableMultiRate[position][name] !== null) {
        message.error("Gas rate must be between 0.1 and 150,000 Mscf/d");
        setDataTableMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
        ));
      }else if(name === "bottom_hole_flowing_pressure" && (dataValue < 14.7 || dataValue > 25000) && !!dataTableMultiRate[position][name]){
        message.error("Pressure values must be between 14.7 and 25,000 psia");
        setDataTableMultiRate((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
        ));
      }
    }
  }

  const handleChangeRowTableGas = (id, action) => {
    const models = {
      forchheimer_pseudo_press_multi_rate: {
        data: dataTableForchheimerMultiRate,
        setData: setDataTableForchheimerMultiRate,
      },
      pseudo_press_multi_rate: {
        data: dataTableMultiRate,
        setData: setDataTableMultiRate,
      },
    };

    const currentModel = models[inputReservoir.dry_gas_ipr_model];

    if (action === "add") {
      const maxId = validateMaxID(currentModel.data);
      const newRow = {
        id: maxId + 1,
        check: true,
        gas_rate: "",
        liquid_rate: "",
        bottom_hole_flowing_pressure: "",
      };
      updateData(currentModel, [...currentModel.data, newRow]);
    } else if (action === "delete") {
      const newData = validateFilter(id, currentModel.data);
      updateData(currentModel, newData);
    }
    
    function updateData(currentModel, newData) {
      currentModel.setData(newData);
    }
  };

  const handleClickDietz = (name, nameProcess, value) => {
    if (nameProcess === "modalDietz") {
      
      if (!openModalDietz === value && (!!checkFigureDietz.value && checkFigureDietz.name !== "checkOtherOption")) {    
        setInputReservoir(
          {
            ...inputReservoir,
            dry_gas_forchheimer_reservoir_propierties: {
              ...inputReservoir.dry_gas_forchheimer_reservoir_propierties,
              forchheimer_dietz_shape_factor: !!checkFigureDietz.value ? checkFigureDietz.value : inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor
            }
          }
        )
        setOpenModalDietz(!openModalDietz);
      }else if (checkFigureDietz?.name === "checkOtherOption" && !inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor){
        message.error("Por favor complete los datos");
      }else{
        setOpenModalDietz(!openModalDietz);
      }
    }else if(nameProcess === "optionDietz"){
      setCheckFigureDietz({name: name, value: value});
    }
  };
  
  const handleOpenModal = (graph, state) => {
    setTypeModalGraph(graph)
    setOpenModal(state);
  };

  const handleCalculate = () => {
    if(
      !inputReservoir.dry_gas_reservoir_pressure ||
      !inputReservoir.dry_gas_reservoir_temperature ||
      !inputReservoir.dry_gas_ipr_model
    ){
      message.error("There are empty fields please validate that the information is complete");
      setInputEmpty(true);
    }else{
      let validateEmpty = false;
      let dataMultiRate = [];

      let dataFinalCalculateIPR = {
        press_res: Number(inputReservoir.dry_gas_reservoir_pressure),
        temp_res: Number(inputReservoir.dry_gas_reservoir_temperature),
        // Forchheimer with Pseudo-Pressure and reservoir properties
        perm_gas: Number(inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_permeability_to_gas),
        dietz_factor: Number(inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor),
        h_res: Number(inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_formation_thickness),
        rw_in: Number(inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_wellbore_radius),
        skin_factor: Number(inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_skin_factor),
        area_acre: Number(inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_drainage_area),
        h_perforated: inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_perforated_interval,
        // C and n with Pseudo-Pressure and single rate test
        gas_rate_test: Number(inputReservoir.dry_gas_pseudo_pressure_single_rate.single_gas_rate),
        bhp_1P_test: Number(inputReservoir.dry_gas_pseudo_pressure_single_rate.single_bottom_hole_flowing_pressure),
        n_exponent_1P: Number(inputReservoir.dry_gas_pseudo_pressure_single_rate.single_exponent_n),
  
        option: OPTION_IPR_MODEL[inputReservoir.dry_gas_ipr_model],
        // C and n with Pseudo-Pressure and multi-rate test
        test_number: 0,
        gas_rate_test_faf: [],
        bhp_test: [],
        // PVT information
        sgg_mix: Number(dataInformationEnd.res_gas_gravity),
        yn2: Number(dataInformationEnd.res_N2),
        yco2: Number(dataInformationEnd.res_CO2),
        yh2s: Number(dataInformationEnd.res_H2S),
      }

      const basePayload = {
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field, 
        well: dataFilterUserGasLift.well_id, 
        username: User.user_name, 
      };

      const handleMutation = (mutation, data) => {
        mutation.mutate({ ...basePayload, data});
        setTimeout(() => {
          refetchDataIPRDryGas(); 
          setExecuteCalc(true);
        }, 500);
      };

      switch (inputReservoir.dry_gas_ipr_model) {
        case "pseudo_press_single_rate":
          validateEmpty = checkEmptyValues(inputReservoir.dry_gas_pseudo_pressure_single_rate, "single-rate");
          if (validateEmpty) {
            setInputEmpty(false);
            handleMutation(mutationDataIPRDryGas, dataFinalCalculateIPR);
          }else{
            setInputEmpty(true);
            message.error("There are empty fields please validate that the information is complete");
          }
          break;
        case "pseudo_press_multi_rate":
          validateEmpty = checkEmptyValues(dataTableMultiRate, "multi-rate");
          if (validateEmpty) {
            setInputEmpty(false);

            dataMultiRate = transformDataToTestFormat(dataTableMultiRate);
            dataFinalCalculateIPR = {
              ...dataFinalCalculateIPR,
              test_number: dataMultiRate.test_number,
              gas_rate_test_faf: dataMultiRate.gas_rate_test_faf,
              bhp_test: dataMultiRate.bhp_test,
            }
            handleMutation(mutationDataIPRDryGas, dataFinalCalculateIPR);
          }else{
            setInputEmpty(true);
            message.error("There are empty fields please validate that the information is complete");
          }        
          break;
        case "forchheimer_pseudo_press_prop":
          validateEmpty = checkEmptyValues(inputReservoir.dry_gas_forchheimer_reservoir_propierties, "propierties");
          if (validateEmpty) {
            setInputEmpty(false);
            handleMutation(mutationDataIPRDryGas, dataFinalCalculateIPR);
          }else{
            setInputEmpty(true);
            message.error("There are empty fields please validate that the information is complete");
          }
          break;
        case "forchheimer_pseudo_press_multi_rate":
          validateEmpty = checkEmptyValues(dataTableForchheimerMultiRate, "multi-rate");
          if (validateEmpty) {
            setInputEmpty(false);

            dataMultiRate = transformDataToTestFormat(dataTableForchheimerMultiRate);
            dataFinalCalculateIPR = {
              ...dataFinalCalculateIPR,
              test_number: dataMultiRate.test_number,
              gas_rate_test_faf: dataMultiRate.gas_rate_test_faf,
              bhp_test: dataMultiRate.bhp_test,
            }
            handleMutation(mutationDataIPRDryGas, dataFinalCalculateIPR);
          }else{
            setInputEmpty(true);
            message.error("There are empty fields please validate that the information is complete");
          }
          break;
        default:
          break;
      }

      function checkEmptyValues (dataTable, option) {
        if (option === "multi-rate") {
          const rowsWithEmptyValues = dataTable.filter(row =>
            Object.keys(row).some(
              key =>
                key !== "check" &&
                key !== "id" &&
                (row[key] === "" || row[key] === null)
            )
          );
        
          return rowsWithEmptyValues.length === 0;
        }else if(option === "single-rate" || option === "propierties"){    
          const emptyFields = Object.keys(dataTable).filter(
            key =>
              key !== "single_result_coefficient_c" &&
              (dataTable[key] === "" || dataTable[key] === null || dataTable[key] === undefined)
          );
          
          return emptyFields.length === 0;
        }
      };

      function transformDataToTestFormat (dataTable) {
        const filteredRows = dataTable.filter(row => row.check);
        const gas_rate_test_faf = filteredRows.map(row => Number(row.gas_rate));
        const bhp_test = filteredRows.map(row => Number(row.bottom_hole_flowing_pressure));
      
        return {
          test_number: filteredRows.length,
          gas_rate_test_faf,
          bhp_test,
        };
      };
      
    }
  }

  const handleClickDone = () => {
    if(
      !inputReservoir.dry_gas_reservoir_pressure ||
      !inputReservoir.dry_gas_reservoir_temperature ||
      !inputReservoir.dry_gas_ipr_model
    ){
      message.error("There are empty fields please validate that the information is complete");
      setInputEmpty(true);
    }else{
      setInputEmpty(false);

      const dataValueRequired = {
        ...inputReservoir,
        layer_pressure: inputReservoir.dry_gas_reservoir_pressure,
        layer_temperature: inputReservoir.dry_gas_reservoir_temperature,
        md_perf_depth: 12721,
        water_cut_fraction: 69,
        dry_gas_pseudo_pressure_multi_rate: {
          ...inputReservoir.dry_gas_pseudo_pressure_multi_rate,
          multi_rate_data_table: dataTableMultiRate
        },
        dry_gas_forchheimer_multi_rate: {
          ...inputReservoir.dry_gas_forchheimer_multi_rate,
          forchheimer_data_table: dataTableForchheimerMultiRate
        }
      }
      const filterDataEnd = Object.assign({}, dataInformationEnd, inputReservoir, dataValueRequired);
     
      async function executeFunctionCreate() {
        await mutationUpdateDataGasLift.mutateAsync({ 
          operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserGasLift.county, 
          field: dataFilterUserGasLift.field, 
          well: dataFilterUserGasLift.well_id, 
          username: User.user_name, 
          data: filterDataEnd
        });

        await new Promise(resolve => setTimeout(resolve, 500));

        navigate(`/${NAME_PAGES.gas_lift.list_well}`);
        message.success("Processing complete!");
      }
      executeFunctionCreate();
    }
  }
  
  function validateMaxID(data) {
    return data.length === 0 ? 0 : Math.max(...data.map((obj) => obj.id), 0);
  }
  
  function validateFilter(id, data) {
    return data.filter((item) => item.id !== id);
  }
  
  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <Modal
        title={typeModalGraph === "chart_log" ? "Log-Log" : typeModalGraph === "chart_reservoir" ? "Graphic" : typeModalGraph === "aux_chart_ipr" ? "Pseudo pressure vs. Pressure" : "Inflow performance relationship"}
        centered
        open={openModal}
        onOk={() => handleOpenModal("", false)}
        onCancel={() => handleOpenModal("", false)}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        width="90%"
      >
        <div className="h-[900px]">
          {typeModalGraph === "chart_log" && (
            <ChartReservoirLog
              dataLog={queryDataIPRDryGas?.data}
            ></ChartReservoirLog>
          )}

          {typeModalGraph === "chart_reservoir" && (
            <ChartReservoir></ChartReservoir>
          )}

          {typeModalGraph === "chart_ipr" && (
            <ChartReservoirIPR
              dataGraph={queryDataIPRDryGas?.data}
              typeGraph={inputReservoir.dry_gas_ipr_model}
            ></ChartReservoirIPR>
          )}

          {typeModalGraph === "aux_chart_ipr" && (
            <ChartAuxReservoirIPR
              dataGraph={queryDataIPRDryGas?.data}
              typeGraph={inputReservoir.dry_gas_ipr_model}
            ></ChartAuxReservoirIPR>
          )}
        </div>
      </Modal>
      <Modal
        title={
          <span className="text-[20px] font-bold">
            Dietz shape factor
          </span>
        }
        centered
        open={openModalDietz}
        onOk={() => handleClickDietz(null, "modalDietz", false)}
        onCancel={() => handleClickDietz(null, "modalDietz")}
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
        width="40%"
      >
        <div className="h-[500px]">
          <div className="flex flex-row mt-[20px]">
            <div className="flex flex-col w-1/2 items-center">
              {dataDietzOptions.firs_column.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-start gap-4 w-[100px] mb-4"
                >
                  <img
                    src={item.img}
                    className="w-20 h-20"
                    alt=""
                    loading="lazy"
                  />
                  <p className={`${item.name === "hexagonReservoir" ? "pl-[5px]" : ""} font-bold`}>{item.value}</p>
                  <Checkbox 
                    name={item.name}
                    className={"custom-checkbox"}
                    onChange={(e) => handleClickDietz(item.name, "optionDietz", item.value)}
                    checked={item.name === checkFigureDietz?.name}
                  />
                </div>
              ))}
              <label
                htmlFor=""
                className="flex w-full full text-center justify-center text-[12px] pl-[45px] font-bold"
              >
                Custom Dietz shape factor
              </label>
              <div
                className="flex items-center justify-center gap-4 w-full mb-4 pl-[70px]"
              >
                <input
                  type="number"
                  name="forchheimer_dietz_shape_factor"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor}
                  onChange={handleChangeDataOptionReservoir}
                  onWheel={(e) => e.target.blur()}
                  className="w-[140px] h-[47px] rounded-[4px] px-3 border border-solid border-[#BCBCCC]"
                  disabled={!("checkOtherOption" === checkFigureDietz?.name)}
                />
                <Checkbox 
                  name="check" 
                  className={"custom-checkbox"}
                  onChange={(e) => handleClickDietz("checkOtherOption", "optionDietz", "")}
                  checked={"checkOtherOption" === checkFigureDietz?.name}
                />
              </div>
            </div>

            <div className="flex flex-col w-1/2 items-start">
              {dataDietzOptions.second_column.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-start gap-4 w-[200px] mb-4"
                >
                  <img
                    src={item.img}
                    className={`${item.name === "squaresCircleUpReservoir" || item.name === "squareCircleRightCenterReservoir" || item.name === "squaresCircleRightReservoir" ? "pl-[28px]" : ""} w-37 h-20`}
                    alt=""
                    loading="lazy"
                  />
                  <p className={`${item.name === "squaresCircleUpReservoir" ? "pl-[18px]" : item.name !== "longSquaresCircleRightReservoir" && item.name !== "longSquaresCircleUpReservoir" ? "pl-[25px]" : ""} font-bold`}>{item.value}</p>
                  <Checkbox 
                    name={item.name}
                    className={"custom-checkbox"}
                    onChange={(e) => handleClickDietz(item.name, "optionDietz", item.value)}
                    checked={item.name === checkFigureDietz?.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center mt-[30px]">
        <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
          <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center">
            <h1 className="flex flex-row font-bold text-[20px]">Reservoir information</h1>
          </div>
        </div>
        <div className="flex flex-row gap-10">
          <div className="flex flex-col w-1/2 items-end">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir pressure (psia)
              <Tooltip title={`Reservoir pressure must be between 14.7 and 25000 psia`}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="dry_gas_reservoir_pressure"
              onKeyDown={handleKeyDown}
              value={inputReservoir.dry_gas_reservoir_pressure}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleInputReservoirBlur(e)}
              onWheel={(e) => e.target.blur()}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${(inputEmpty && (inputReservoir.dry_gas_reservoir_pressure === null || inputReservoir.dry_gas_reservoir_pressure === '')) || inputReservoir.dry_gas_reservoir_pressure === null
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] font bold text-[15px] mb-[5px] justify-left"
            >
              IPR model
            </label>
            <Select
              className="flex w-full h-[47px] max-w-[592px] text-start mb-[7px]"
              name="dry_gas_ipr_model"
              value={inputReservoir.dry_gas_ipr_model || "Selected option"}
              onChange={(e) => handleChangeSelectDryGas('dry_gas_ipr_model', e)}
            >
              <Option value="pseudo_press_single_rate">C and n with Pseudo-Pressure and single rate test</Option>
              <Option value="pseudo_press_multi_rate">C and n with Pseudo-Pressure and multi-rate test</Option>
              <Option value="forchheimer_pseudo_press_prop">Forchheimer with Pseudo-Pressure and reservoir properties</Option>
              <Option value="forchheimer_pseudo_press_multi_rate">Forchheimer with Pseudo-Pressure and multi-rate test</Option>
            </Select>
          </div>
          <div className="flex flex-col w-1/2 text-left items-start justify-start">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir temperature (deg F)
              <Tooltip title={`Reservoir temperature must be between 70 and 350 deg F`}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="dry_gas_reservoir_temperature"
              onKeyDown={handleKeyDown}
              value={inputReservoir.dry_gas_reservoir_temperature}
              onChange={handleChangeDataReservoir}
              onBlur={(e) => handleInputReservoirBlur(e)}
              onWheel={(e) => e.target.blur()}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${(inputEmpty && (inputReservoir.dry_gas_reservoir_temperature === "" || inputReservoir.dry_gas_reservoir_temperature === null)) ||  inputReservoir.dry_gas_reservoir_temperature === null
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
          </div>
        </div>
        {inputReservoir.dry_gas_ipr_model !== "forchheimer_pseudo_press_prop" &&(
          <div className="flex w-full h-[24px] justify-center text-center items-center mb-[20px] mt-[20px]">
            <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[20px]">Production test data</h1>
            </div>
          </div>
        )}
        {inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_prop" &&(
          <div className="flex w-full h-[24px] justify-center text-center items-center mb-[20px] mt-[20px]">
            <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[20px]">Reservoir parameters</h1>
            </div>
          </div>
        )}
        {inputReservoir.dry_gas_ipr_model === "pseudo_press_single_rate" && (
          <div className="flex flex-row gap-[25px] justify-center items-center w-full">
            <div className="flex flex-col items-center">
              <label
                htmlFor=""
                className="flex w-full text-start font bold text-[15px] mb-[7px]"
              >
                Gas rate (Mscf/d)
                <Tooltip title={`Gas rate must be between 0.1 and 150,000 Mscf/d`}>
                  <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                </Tooltip>
              </label>
              <input
                type="number"
                name="single_gas_rate"
                onKeyDown={handleKeyDown}
                value={inputReservoir.dry_gas_pseudo_pressure_single_rate.single_gas_rate}
                onChange={handleChangeDataOptionReservoir}
                onBlur={(e) => handleInputReservoirBlur(e)}
                onWheel={(e) => e.target.blur()}
                className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                  (inputEmpty && (inputReservoir.dry_gas_pseudo_pressure_single_rate.single_gas_rate === null || inputReservoir.dry_gas_pseudo_pressure_single_rate.single_gas_rate === '')) || inputReservoir.dry_gas_pseudo_pressure_single_rate.single_gas_rate === null
                    ? "border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                }`}
              />
            </div>
            <div className="flex flex-col items-center">
              <label
                htmlFor=""
                className="flex w-full text-start font bold text-[15px] mb-[7px]"
              >
                Bottom hole flowing pressure (psia)
                <Tooltip title={`Pressure values must be between 14.7 and 25,000 psia`}>
                  <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                </Tooltip>
              </label>
              <input
                type="number"
                name="single_bottom_hole_flowing_pressure"
                onKeyDown={handleKeyDown}
                value={inputReservoir.dry_gas_pseudo_pressure_single_rate.single_bottom_hole_flowing_pressure}
                onChange={handleChangeDataOptionReservoir}
                onBlur={(e) => handleInputReservoirBlur(e)}
                onWheel={(e) => e.target.blur()}
                className={`w-[390px]  h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                  (inputEmpty && (inputReservoir.dry_gas_pseudo_pressure_single_rate.single_bottom_hole_flowing_pressure === null || inputReservoir.dry_gas_pseudo_pressure_single_rate.single_bottom_hole_flowing_pressure === '')) || inputReservoir.dry_gas_pseudo_pressure_single_rate.single_bottom_hole_flowing_pressure === null
                    ? "border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                }`}
              />
            </div>
            <div className="flex flex-col items-center">
              <label
                htmlFor=""
                className="flex w-full text-start font bold text-[15px] mb-[7px]"
              >
                Exponent n
                <Tooltip title={`The value of n ranges between 0.5 and 1.0. A value of 0.5 indicates turbulent flow throughout the drainage area, a value of 1.0 indicates laminar flow`}>
                  <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                </Tooltip>
              </label>
              <input
                type="number"
                name="single_exponent_n"
                onKeyDown={handleKeyDown}
                value={inputReservoir.dry_gas_pseudo_pressure_single_rate.single_exponent_n}
                onChange={handleChangeDataOptionReservoir}
                onBlur={(e) => handleInputReservoirBlur(e)}
                onWheel={(e) => e.target.blur()}
                className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                  (inputEmpty && (inputReservoir.dry_gas_pseudo_pressure_single_rate.single_exponent_n === "" || inputReservoir.dry_gas_pseudo_pressure_single_rate.single_exponent_n === null)) || inputReservoir.dry_gas_pseudo_pressure_single_rate.single_exponent_n === null
                    ? "border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                }`}
              />
            </div>
          </div>
        )}

        {inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_prop" && (
          <>
            <div className="flex flex-row gap-[25px] justify-center items-center w-full">
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Formation thickness (feet)
                  <Tooltip title={`Formation thickness values must be between 0.1 and 2000 feet`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_formation_thickness"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_formation_thickness}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}  
                  onWheel={(e) => e.target.blur()}      
                  className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_formation_thickness === null || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_formation_thickness === '')) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_formation_thickness === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Porosity (%)
                  <Tooltip title={`Porosity values must be between 0.1 and 50 %`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_porosity"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_porosity}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}
                  onWheel={(e) => e.target.blur()}
                  className={`w-[390px]  h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_porosity === null || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_porosity === '')) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_porosity === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Permeability to gas (md)
                  <Tooltip title={`Permeability to gas values ​​must be greater than 0 md`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_permeability_to_gas"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_permeability_to_gas}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}
                  onWheel={(e) => e.target.blur()}
                  className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_permeability_to_gas === "" || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_permeability_to_gas === null)) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_permeability_to_gas === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
            </div>

            <div className="flex flex-row gap-[25px] justify-center items-center w-full">
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Drainage area (acres)
                  <Tooltip title={`Drainage area values ​​must be greater than 0 acres`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_drainage_area"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_drainage_area}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}
                  onWheel={(e) => e.target.blur()}
                  className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_drainage_area === null || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_drainage_area === '')) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_drainage_area === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Dietz shape factor
                  <Tooltip title={`Dietz shape factor values must be between 0.01 and 40`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_dietz_shape_factor"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor}
                  onClick={() => 
                    handleClickDietz(null, "modalDietz")
                  }              
                  className={`w-[390px]  h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor === null || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_dietz_shape_factor === '')
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Perforated interval (feet)
                  <Tooltip title={`Perforated interval values must be between 0.1 and 2000 feet`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_perforated_interval"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_perforated_interval}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}
                  onWheel={(e) => e.target.blur()}
                  className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_perforated_interval === "" || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_perforated_interval === null)) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_perforated_interval === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
            </div>

            <div className="flex flex-row gap-[25px] justify-center items-center w-full pr-[415px]">
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Wellbore radius (inches)
                  <Tooltip title={`Wellbore radius values must be between 0.1 and 36 inches`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_wellbore_radius"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_wellbore_radius}
                  onKeyDown={handleKeyDown}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}
                  onWheel={(e) => e.target.blur()}
                  className={`w-[390px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_wellbore_radius === null || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_wellbore_radius === '')) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_wellbore_radius === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Skin factor (dimensionless)
                  <Tooltip title={`Skin factor values must be between -100 and 1000 dimensionless`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number"
                  name="forchheimer_skin_factor"
                  value={inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_skin_factor}
                  onChange={handleChangeDataOptionReservoir}
                  onBlur={(e) => handleInputReservoirBlur(e)}
                  onWheel={(e) => e.target.blur()}               
                  className={`w-[390px]  h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${
                    (inputEmpty && (inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_skin_factor === null || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_skin_factor === '')) || inputReservoir.dry_gas_forchheimer_reservoir_propierties.forchheimer_skin_factor === null
                      ? "border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                  }`}
                />
              </div>
            </div>
          </>
        )}

        {(inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_multi_rate" || inputReservoir.dry_gas_ipr_model === "pseudo_press_multi_rate") && (
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-row w-full text-center justify-center items-center">
              <ul className="flex flex-col w-full items-center">
                <div className="w-[76.5rem] ml-[0] items-center">
                  <div className="flex flex-row w-[100%] text-center justify-center items-center gap-5">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] mr-[15px] text-start text-[15px]"
                    >
                      Gas rate (Mscf/d)
                      <Tooltip title={`Gas rate must be between 0.1 and 150,000 Mscf/d`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] pr-[20px] text-start text-[15px]"
                    >
                      Liquid rate (STB/D)
                      <Tooltip title={`Liquid rate must be between 0 and 10000 STB/D`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] pr-[20px] text-start text-[15px]"
                    >
                      Water cut (%)
                      <Tooltip title={`Water cut must be between 0 and 10 %`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] pr-[20px] text-start text-[15px]"
                    >
                      Bottom hole flowing pressure (psia)
                      <Tooltip title={`Pressure values must be between 14.7 and 25,000 psia`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                  </div>
                </div>
                {inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_multi_rate" && dataTableForchheimerMultiRate.map((item, index) => (
                  <li
                    key={item.id}
                    className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center 
                      ${
                        (dataTableForchheimerMultiRate.length === 2 && index === 0) ? "pr-[30px]"
                        : (index === 1 && dataTableForchheimerMultiRate.length - 1 === index) ? "pr-[5px]"
                        : (dataTableForchheimerMultiRate.length >= 5 && dataTableForchheimerMultiRate.length - 1 === index) ? "pr-[5px]"
                        : (dataTableForchheimerMultiRate.length < 5 && dataTableForchheimerMultiRate.length - 1 === index) ? "pl-[25px]"
                        : "pr-[5px]"
                      }`
                    }
                  >
                    <div className={`flex flex-row text-right items-start gap-2 pr-2`}>
                      <Checkbox
                        name="check"
                        className={"custom-checkbox"}
                        onChange={(e) => handleChangeTableDryGas(e, item.id)}
                        checked={item.check}
                      ></Checkbox>
                    </div>
                    <input
                      type="number" 
                      name="gas_rate"
                      value={item.gas_rate}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onBlur={(e) => handleInputReservoirTableBlur(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[22px] mr-[20px]
                        ${(item.gas_rate === null)
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <input
                      type="number" 
                      name="liquid_rate"
                      value={item.liquid_rate}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[20px] 
                        ${(item.liquid_rate === null)
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <input
                      type="number" 
                      name="water_cut"
                      value={item.water_cut}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[20px] 
                        ${(item.water_cut === null)
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <input
                      type="number"
                      name="bottom_hole_flowing_pressure"
                      value={item.bottom_hole_flowing_pressure}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onBlur={(e) => handleInputReservoirTableBlur(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[20px]
                        ${(item.bottom_hole_flowing_pressure === null)
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <div className={`flex flex-row  text-right items-start gap-2`}>
                      {(dataTableForchheimerMultiRate.length - 1 === index && dataTableForchheimerMultiRate.length < 5)  && (
                      <IconAdd
                          className="w-6 h-6 fill-neutral-500"
                          onClick={() => 
                            handleChangeRowTableGas(null, "add",)
                          }
                        />
                      )}
                    {dataTableForchheimerMultiRate.length >= 3 && (
                        <IconDelete
                          className="w-6 h-6 fill-neutral-500"
                          onClick={() =>
                            handleChangeRowTableGas(item.id, "delete")
                          }
                        />
                      )}
                    </div>
                  </li>
                ))}
                {inputReservoir.dry_gas_ipr_model === "pseudo_press_multi_rate" && dataTableMultiRate.map((item, index) => (
                  <li
                    key={item.id}
                    className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center 
                      ${
                        (dataTableMultiRate.length === 2 && index === 0) ? "pr-[30px]"
                        : (index === 1 && dataTableMultiRate.length - 1 === index) ? "pr-[5px]"
                        : (dataTableMultiRate.length >= 5 && dataTableMultiRate.length - 1 === index) ? "pr-[5px]"
                        : (dataTableMultiRate.length < 5 && dataTableMultiRate.length - 1 === index) ? "pl-[25px]"
                        : "pr-[5px]"
                      }`
                    }
                  >
                    <div className={`flex flex-row text-right items-start gap-2 pr-2`}>
                      <Checkbox
                        name="check"
                        className={"custom-checkbox"}
                        onChange={(e) => handleChangeTableDryGas(e, item.id)}
                        checked={item.check}
                      ></Checkbox>
                    </div>
                    <input
                      type="text" 
                      name="gas_rate"
                      value={item.gas_rate}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onBlur={(e) => handleInputReservoirTableBlur(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[22px] mr-[20px]
                        ${(item.gas_rate === null || (inputEmpty && (item.gas_rate === null || item.gas_rate === "")))
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <input
                      type="number" 
                      name="liquid_rate"
                      value={item.liquid_rate}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[20px] 
                        ${(item.liquid_rate === null)
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <input
                      type="number" 
                      name="water_cut"
                      value={item.water_cut}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[20px] 
                        ${(item.water_cut === null)
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <input
                      type="number"
                      name="bottom_hole_flowing_pressure"
                      value={item.bottom_hole_flowing_pressure}
                      onChange={(e) => handleChangeTableDryGas(e, item.id)}
                      onBlur={(e) => handleInputReservoirTableBlur(e, item.id)}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[290px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[20px]
                        ${(item.bottom_hole_flowing_pressure === null || (inputEmpty && (item.bottom_hole_flowing_pressure === null || item.bottom_hole_flowing_pressure === "")))
                          ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"
                        } `}
                    />
                    <div className={`flex flex-row text-right items-start gap-2`}>
                      {(dataTableMultiRate.length - 1 === index && dataTableMultiRate.length < 5)  && (
                      <IconAdd
                          className="w-6 h-6 fill-neutral-500"
                          onClick={() => 
                            handleChangeRowTableGas(null, "add",)
                          }
                        />
                      )}
                     {dataTableMultiRate.length >= 3 && (
                        <IconDelete
                          className="w-6 h-6 fill-neutral-500"
                          onClick={() =>
                            handleChangeRowTableGas(item.id, "delete")
                          }
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {!!inputReservoir.dry_gas_ipr_model && (
          <div className="flex w-full h-[24px] justify-center text-center items-center mb-[20px] mt-[20px]">
            <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
              <button
                className={`${inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_multi_rate" || inputReservoir.dry_gas_ipr_model === "pseudo_press_multi_rate" ? "w-[285.5px]" : "w-[390px]"} h-[47px] w-[390px] bg-pa-purple text-white rounded-md hover:bg-pc-purple`}
                onClick={handleCalculate}
              >
                Calculate
              </button>
            </div>
          </div>
        )}

        {inputReservoir.dry_gas_ipr_model !== "forchheimer_pseudo_press_prop" && (
          <div className="flex w-full h-[24px] justify-center text-center items-center mb-[20px] mt-[20px]">
            <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
              <h1 className="flex flex-row font-bold text-[20px]">Results</h1>
            </div>
          </div>
        )}

        {inputReservoir.dry_gas_ipr_model === "pseudo_press_single_rate" && (
          <div className="flex flex-row w-full pr-[440px] mb-[7px]">
            <div className="flex flex-col w-1/2 items-end">
              <label
                htmlFor=""
                className="flex w-full max-w-[390px] text-start font bold text-[15px] mb-[7px]"
              >
                Coefficient C
              </label>
              <input
                type="number"
                name="single_result_coefficient_c"
                onKeyDown={handleKeyDown}
                value={inputReservoir.dry_gas_pseudo_pressure_single_rate.single_result_coefficient_c}
                className={`w-full h-[47px] max-w-[390px] text-start mb-[7px] rounded-[4px] px-3 border border-solid mb-[17px] border-[#BCBCCC]`}
                readOnly
              />
            </div>
          </div>
        )}

        {inputReservoir.dry_gas_ipr_model === "pseudo_press_multi_rate" && (
          <>
            <div className="flex flex-row gap-[20px] justify-center items-center w-full pr-[622px]">
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Coefficient C
                </label>
                <input
                  type="number"
                  name="multi_rate_result_coefficient_c"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.dry_gas_pseudo_pressure_multi_rate.multi_rate_result_coefficient_c}
                  className={`w-[290px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] border-[#BCBCCC]`}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Exponent n
                </label>
                <input
                  type="number"
                  name="multi_rate_result_exponent_n"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.dry_gas_pseudo_pressure_multi_rate.multi_rate_result_exponent_n}
                  className={`w-[290px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] border-[#BCBCCC]`}
                  readOnly
                />
              </div>
            </div>

            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[15px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Rawlins-Schellhardt Analysis</h1>
              </div>
            </div>

            <div className="flex w-full h-full text-center items-center justify-center mb-[20px]">
              <div className="flex flex-col w-[1220px] h-[500px] text-center items-center justify-center border-solid" 
                style={{
                  border: '1px solid #BCBCCC',
                }}
              >
                <div className="flex w-full justify-end mt-[20px] mb-5 mr-5">
                  <ExpandIcon
                    className="w-5 h-5 hover:bg-pc-grey3"
                    onClick={() => handleOpenModal("chart_log", true)}
                  />
                </div>
                <ChartReservoirLog
                  dataLog={queryDataIPRDryGas?.data}
                ></ChartReservoirLog>
              </div>
            </div>
          </>
        )}

        {inputReservoir.dry_gas_ipr_model === "forchheimer_pseudo_press_multi_rate" && (
          <>
            <div className="flex flex-row gap-[20px] justify-center items-center w-full pr-[622px]">
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Coefficient a
                </label>
                <input
                  type="number"
                  name="forchheimer_result_coefficient_a"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.dry_gas_forchheimer_multi_rate.result_coefficient_a}
                  className={`w-[290px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] border-[#BCBCCC]`}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-center">
                <label
                  htmlFor=""
                  className="flex w-full text-start font bold text-[15px] mb-[7px]"
                >
                  Exponent b
                </label>
                <input
                  type="number"
                  name="forchheimer_result_exponent_b"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.dry_gas_forchheimer_multi_rate.result_exponent_b}
                  className={`w-[290px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] border-[#BCBCCC]`}
                  readOnly
                />
              </div>
            </div>

            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[20px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Houpeurt analysis</h1>
              </div>
            </div>

            <div className="flex w-full h-full text-center items-center justify-center mb-[20px]">
              <div className="flex flex-col w-[1220px] h-[450px] text-center items-center justify-center border-solid" 
                style={{
                  border: '1px solid #BCBCCC',
                }}
              >
                <div className="flex w-full justify-end mt-[20px] mb-5 mr-5">
                  <ExpandIcon
                    className="w-5 h-5 hover:bg-pc-grey3"
                    onClick={() => handleOpenModal("chart_reservoir", true)}
                    />
                </div>
                <ChartReservoir></ChartReservoir>
              </div>
            </div>
          </>
        )}

        <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[20px]">
          <div className="flex flex-row w-[610px] h-[24px] gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Inflow performance relationship</h1>
          </div>
          <div className="flex flex-row gap-4 w-[610px] h-full pl-[88px] text-right justify-end items-center">
            <button
              className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
              onClick={() => handleOpenModal("aux_chart_ipr", true)}
            >
              Pseudo pressure vs Pressure
            </button>
          </div>
        </div>
        <div className="flex w-full h-full text-center items-center justify-center mb-[20px]">
          <div className="flex flex-col w-[1220px] h-[750px] text-center items-center justify-center border-solid" 
            style={{
              border: '1px solid #BCBCCC',
            }}
          >
            <div className="flex w-full justify-end mt-[20px] mb-5 mr-5">
              <ExpandIcon
                className="w-5 h-5 hover:bg-pc-grey3"
                onClick={() => handleOpenModal("chart_ipr", true)}
              />
            </div>
            <ChartReservoirIPR
              dataGraph={queryDataIPRDryGas?.data}
              typeGraph={inputReservoir.dry_gas_ipr_model}
            ></ChartReservoirIPR>
          </div>
        </div>
        <div className="flex w-full text-center justify-center items-center mt-[10px] mb-[40px]">
          <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
            <button
              className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
              onClick={() => handleClickDone()}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};