import { Checkbox, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTapMap } from "../../hooks/useTabMap";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabDCA } from "../../hooks/useTabDCA";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";

export const TableWell = () => {
  const [data, setData] = useState([]);
  const { getSelectedWell, getSelectedWellDCA, getSelectedWellPL } = useContext(DashboardContext);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  
  const {
    dataListDCA,
    listWellsGasLift,
    getDataFilterUserDCA,
    dataFilterUserDCA,
    getDataFilterUserGaslift,
    dataFilterUserGasLift
  } = useTapMap();
  const {dataFilterUserPL, getDataFilterPL, listWellPL, refetchDataWellPL} = useTabPlungerLift();
  const {refetchDataWellDCA} = useTabDCA();
  const {refetchDataWellGasLift} = useTabGaslift();

  useEffect(() => {
    setTimeout(() => {
      if (dataListDCA && listWellsGasLift && listWellPL ) {
        const validDataLists = [dataListDCA, listWellsGasLift, listWellPL].filter(
          list => list.statusType !== "ERROR"
        );

        const combinedData = validDataLists.flatMap(list => list.data || []);

        if (combinedData.length > 0) {
          setData(combinedData);
        }
      }
    }, 1000);
  }, [dataListDCA, listWellsGasLift, listWellPL]);

  const handleChangeCheckWell = (valueCheck, idCheck, type_process) => {
    if (valueCheck === true) {
      // Generates only the filter depending on the key found in the filtered data
      if (type_process === "Forecasting") {
        const dataListDCA = data.find((item) => item.well_id === idCheck);
        const dataFilterDCA = {
          county: dataListDCA.county,
          field: dataListDCA.field,
          well_id: dataListDCA.well_id,
          well_platforms:  dataListDCA.well_platforms,
          well_name: dataListDCA.well_name,
        };
        getDataFilterUserDCA(dataFilterDCA);
        getSelectedWellDCA(valueCheck ? dataListDCA.well_id : null)
        setTimeout(() => {
          refetchDataWellDCA();
        }, 1000);
      }
      if (type_process === "Gas Lift") {
        const dataListFind = data.find((item) => item.well_id === idCheck);
        const dataFilterNew = {
          county: dataListFind.county,
          field: dataListFind.field,
          well_id: dataListFind.well_id,
          well_platforms:  dataListFind.well_platforms,
          well_name: dataListFind.well_name,
        };
        getDataFilterUserGaslift(dataFilterNew);
        getSelectedWell(valueCheck ? dataListFind.well_id : null)
        setTimeout(() => {
          refetchDataWellGasLift();
        }, 1000);
      } 
      if (type_process === "Plunger Lift") {
        const dataListFind = data.find((item) => item.well_id === idCheck);
        const dataFilterNew = {
          county: dataListFind.county,
          field: dataListFind.field,
          well_id: dataListFind.well_id,
          well_platforms:  dataListFind.well_platforms,
          well_name: dataListFind.well_name,
        };
        getDataFilterPL(dataFilterNew);
        getSelectedWellPL(valueCheck ? dataListFind.well_id : null)
        setTimeout(() => {
          refetchDataWellPL();
        }, 1000);
      } 
      
    } 
  };

  const handleChange = ( pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getData = data.map((item, index) => {
    const check = 
      <Checkbox
        className={"custom-checkbox"}
        onChange={(e) => {
          handleChangeCheckWell(e.target.checked, item.well_id, item.type_process);
        }}
        checked={(item.type_process === "Forecasting" && item.well_id === dataFilterUserDCA?.well_id ) || (item.type_process === "Gas Lift" && item.well_id === dataFilterUserGasLift?.well_id ) || (item.type_process === "Plunger Lift" && item.well_id === dataFilterUserPL?.well_id ) }
      ></Checkbox>
    const valuedata = {
      key: item.well_id + item.type_process + index,
      checkwell: check,
      alarms: item.type_process === "Gas Lift" ? item?.data_check?.countAlert : item?.check_alert?.countAlert,
      wellname: item.well_name,
      typeprocess: item.type_process,
      fluidtype : item.fluid_type,
      region: item.region,
      county: item.county,
      filed: item.field,
      latitude: item.latitude,
      longitude: item.longitude
    }
    
    return valuedata
  })


  const getUniqueFilters = (key) => {

    const filteredData = getData.filter(item => {
      return Object.entries(filteredInfo).every(([filterKey, filterValue]) => {

        if (filterValue) {
          return filterValue.includes(item[filterKey]);
        }
        return true;
      });
    });
  
    // generates only the filter depending on the key found in the filtered data
    return [...new Set(filteredData.map(item => item[key]))].map(value => ({
      text: value,
      value: value,
    }));
  };
  

  const filtersWellName = getUniqueFilters('wellname');
  const filtersTypeProcess = getUniqueFilters('typeprocess');
  const filtersFluidType = getUniqueFilters("fluidtype");
  const filtersRegion = getUniqueFilters('region');
  const filtersCounty = getUniqueFilters("county");
  const filtersFiled = getUniqueFilters("filed");
  const filtersLatitude = getUniqueFilters("latitude");
  const filtersLongitude = getUniqueFilters("longitude");


  const columns = [
    {
      title: 'Select well',
      dataIndex: 'checkwell',
      key: 'checkwell',
      className: 'px-3 py-4',
      width: 115,
      render: (text) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {text}
        </div>
    )
    },
    {
      title: 'Alarms',
      dataIndex: 'alarms',
      key: 'alarms',
      className: 'px-6 py-4', 
      width: 135,
      align: 'center',
      render: (text, record) => {
        return (
        <>
        {(!!text) && (text != 0) ? (<Tooltip title={" Number of alerts"} >
        <div  style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} className="relative flex items-center text-center">
              <span className="relative w-5 h-5 z-10 bg-red-500 rounded-full items-center text-center">{text}</span>
        </div>
        </Tooltip>) : 
        //<Tooltip title={"Don't already alerts "} >
        <div  style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} className="relative flex items-center text-center">
              <span className="relative w-5 h-5 z-10 bg-[#20e825] rounded-full items-center text-center"></span>
        </div>
        //</Tooltip>
        } 
        </>
      )},
    },
    {
      title: 'Well name',
      dataIndex: 'wellname',
      key: 'wellname',
      className: 'px-6 py-4 ',
      filters: filtersWellName,
      filteredValue: filteredInfo.wellname || null,
      onFilter: (value, record) => record.wellname.includes(value),
      sorter: (a, b) => a.wellname.localeCompare(b.wellname),
      sortOrder: sortedInfo.columnKey === 'wellname' ? sortedInfo.order : null,
      ellipsis: true
    },
    {
      title: 'Type Process',
      dataIndex: 'typeprocess',
      key: 'typeprocess',
      className: 'px-6 py-4',
      filters: filtersTypeProcess,
      filteredValue: filteredInfo.typeprocess || null,
      onFilter: (value, record) => record.typeprocess.includes(value),
      sorter: (a, b) => a.typeprocess.localeCompare(b.typeprocess),
      sortOrder: sortedInfo.columnKey === 'typeprocess' ? sortedInfo.order : null,
      ellipsis: true
    },
    {
      title: 'Fluid type',
      dataIndex: 'fluidtype',
      key: 'fluidtype',
      className: 'px-6 py-4',
      filters: filtersFluidType,
      filteredValue: filteredInfo.fluidtype || null,
      onFilter: (value, record) => record.fluidtype.includes(value),
      sorter: (a, b) => a.fluidtype.localeCompare(b.fluidtype),
      sortOrder: sortedInfo.columnKey === 'fluidtype' ? sortedInfo.order : null,
      ellipsis: true, 
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      className: 'px-6 py-4',
      filters: filtersRegion,
      filteredValue: filteredInfo.region || null,
      onFilter: (value, record) => record.region.includes(value),
      sorter: (a, b) => a.region.localeCompare(b.region),
      sortOrder: sortedInfo.columnKey === 'region' ? sortedInfo.order : null,
      ellipsis: true, 
    },
    {
      title: 'County',
      dataIndex: 'county',
      key: 'county',
      className: 'px-6 py-4',
      filters: filtersCounty,
      filteredValue: filteredInfo.county || null,
      onFilter: (value, record) => record.county.includes(value),
      sorter: (a, b) => a.county.localeCompare(b.county),
      sortOrder: sortedInfo.columnKey === 'county' ? sortedInfo.order : null,
      ellipsis: true, 
    },
    {
      title: 'Filed',
      dataIndex: 'filed',
      key: 'filed',
      className: 'px-6 py-4',
      filters: filtersFiled,
      filteredValue: filteredInfo.filed || null,
      onFilter: (value, record) => record.filed.includes(value),
      sorter: (a, b) => a.filed.localeCompare(b.filed),
      sortOrder: sortedInfo.columnKey === 'filed' ? sortedInfo.order : null,
      ellipsis: true, 
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      className: 'px-6 py-4',
      filters: filtersLatitude,
      filteredValue: filteredInfo.latitude || null,
      onFilter: (value, record) => record.latitude.includes(value),
      sorter: (a, b) => a.latitude.localeCompare(b.latitude),
      sortOrder: sortedInfo.columnKey === 'latitude' ? sortedInfo.order : null,
      ellipsis: true, 
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      className: 'px-6 py-4',
      filters: filtersLongitude,
      filteredValue: filteredInfo.longitude || null,
      onFilter: (value, record) => record.longitude.includes(value),
      sorter: (a, b) => a.longitude.localeCompare(b.longitude),
      sortOrder: sortedInfo.columnKey === 'longitude' ? sortedInfo.order : null,
      ellipsis: true, 
    },
  ];
  
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <Table 
              columns={columns}
              dataSource={getData}
              onChange={handleChange} 
              pagination={false}
              className="custom-table"
              rowClassName="border-b transition duration-300 ease-in-out"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
