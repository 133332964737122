export const NAME_PAGES = {
  gas_lift: {
    routes:{
      list_well: "/AddWellGasLift",
      dashboard: "/DashboardGasLift",
    },
    list_well: "AddWellGasLift",
    dashboard: "DashboardGasLift",
    create_well: "StepMenu-WellConfig",
    edit_well: "StepMenu-EditWellConfig",
    create_reservoir: "StepMenu-Reservoir",
    create_reservoir_dry_gas: "StepMenu-Reservoir-DryGas",
    edit_reservoir: "StepMenu-EditReservoir",
    edit_reservoir_dry_gas: "StepMenu-EditReservoir-DryGas",
    create_fluid: "StepMenu-FluidConfig",
    create_fluid_dry_gas: "StepMenu-FluidConfig-DryGas",
    edit_fluid: "StepMenu-EditFluidConfig",
    edit_fluid_dry_gas: "StepMenu-EditFluidConfig-DryGas",
    create_edit_tuning: "TuningGasLift",
    create_inoutflow: "StepMenu-InOutFlow",
    create_inoutflow_dry_gas: "StepMenu-InOutFlow-DryGas",
    edit_inoutflow:"StepMenu-EditInOutFlow",
    edit_inoutflow_dry_gas:"StepMenu-EditInOutFlow-DryGas"
  },
  DCA: {
    routes:{
      list_well: "/AddWellDCA",
      dashboard: "/DashboardDCA",
    },
    list_well: "AddWellDCA",
    dashboard: "DashboardDCA",
    create_well: "StepMenu-WellDCA",
    edit_well: "StepMenu-EditWellDCA"
  },
  plunger_lift: {
    routes:{
      list_well: "/AddPlungerLift",
      dashboard: "/DashboardPlungerLift",
    },
    list_well: "AddPlungerLift",
    dashboard: "DashboardPlungerLift",
    create_well: "StepMenu-WellPlungerLift",
    edit_well: "StepMenu-EditWellPlungerLift",
    create_pvt: "StepMenu-FluidPlungerLift",
    edit_pvt: "StepMenu-EditFluidPlungerLift",
    create_reservoir: "StepMenu-ReservoirPlungerLift",
    edit_reservoir: "StepMenu-EditReservoirPlungerLift",
    create_plunger_lift: "StepMenu-PlungerLift",
    edit_plunger_lift: "StepMenu-EditPlungerLift"
  },
  plotting: {
    routes:{
      dashboard: "/Plotting",
    },
    dashboard: "Plotting"
  },
  screening_tool: {
    routes:{
      list_well: "/AddScreeningTool",
      dashboard: "/DashboardScreeningTool",
    },
    dashboard: "DashboardScreeningTool",
    list_well: "AddScreeningTool",
    create_screning_tool: "StepMenu-ScreeningTool",
    edit_screning_tool: "StepMenu-EditScreeningTool"
  },
  user: {
    internal:{
      list_users: "Users-Internal"
    },
    external:{
      list_users: "Users-External"
    },
    register:{
      create: "Users-Register"
    },
    control:{
      create: "Users-Parameters"
    }
  },
  map: "Map"
}