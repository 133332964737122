import React, { useState, useEffect, useRef } from 'react';

export default function TablePlungerLift({ data }) {
  const [columnsPerPage, setColumnsPerPage] = useState(10); // Initially, a default value
  const [currentPage, setCurrentPage] = useState(0); // current page
  const containerRef = useRef(null); // REF for the table container
  const dataRef = useRef(null); // REF for the table container

 // Function to calculate the number of columns based on the container width
  const calculateColumnsPerPage = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const columnWidth = 100; // estimated average width of each column (adjust according to your design)
      const newColumnsPerPage = Math.floor(containerWidth / columnWidth);
      setColumnsPerPage(newColumnsPerPage);
    }
  };


  // Call calculatecolumnsperpage when the component is mounted and when the size of the window changes
  useEffect(() => {
    calculateColumnsPerPage(); // Calculate when assembling the component
    window.addEventListener('resize', calculateColumnsPerPage); // Recalculate when changing the window size
    return () => {
      window.removeEventListener('resize', calculateColumnsPerPage); // Clean the event when disassembling
    };
  }, []);
  useEffect(() => {
    if (data.plunger_depth_md !== dataRef.current?.plunger_depth_md) {
      dataRef.current = data
      setCurrentPage(0)
    }
  }, [data]);
  // Verify that the data is available (after hooks)
  if (!data || !data.data) {
    return <p>No data available</p>;
  }

  // Calculate the start and end index of the columns for the current page
  const startIndex = currentPage * columnsPerPage;
  const endIndex = startIndex + columnsPerPage;

  // Extract the visible columns of the `v_slug` values
  const columns = data.data.map(item => item.v_slug).slice(startIndex, endIndex);

  // Function to change page
  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

 // Total pages
  const totalPages = Math.ceil(data.data.length / columnsPerPage);


  return (
    <div ref={containerRef} className="table-container-plunger">
      <table className="plunger-table">
        <thead>
          <tr>
            <th rowSpan="2" className="with-border text-center-important">Depth (feet)</th>
            <th colSpan={columns.length + 1} className="with-border full-width text-center-important">Liquid surface delivery per cycle(bbl)</th>
          </tr>
          <tr>
            <th className="with-border-bottom"></th>
            {columns.map((col, index) => (
              <th key={index} className="with-border-bottom">{col.toFixed(4)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Depth */}
          <tr>
            <td rowSpan="5" className="with-border text-center-important">{data.plunger_depth_md || 'N/A'}</td>
            <td className='font-bold  whitespace-nowrap'>GLR required per cycle (Mscf/bbl)</td>
            {data.data.slice(startIndex, endIndex).map((item, index) => (
              <td key={index}>{item.glr.toFixed(4)}</td>
            ))}
          </tr>
          <tr>
            <td className='font-bold  whitespace-nowrap'> Gas volume required per cycle (Mscf)</td>
            {data.data.slice(startIndex, endIndex).map((item, index) => (
              <td key={index}>{item.vg_per_cycle.toFixed(4)}</td>
            ))}
          </tr>
          <tr>
            <td className='font-bold  whitespace-nowrap'>Required avg surface casing pressure (psia)</td>
            {data.data.slice(startIndex, endIndex).map((item, index) => (
              <td key={index}>{item.press_csg_avg.toFixed(2)}</td>
            ))}
          </tr>
          <tr>
            <td className='font-bold  whitespace-nowrap'>Maximum liquid production (bbl/d)</td>
            {data.data.slice(startIndex, endIndex).map((item, index) => (
              <td key={index}>{item.ql_max.toFixed(2)}</td>
            ))}
          </tr>
          <tr>
            <td className='font-bold  whitespace-nowrap'>Maximum cycles per day (Cycle/d)</td>
            {data.data.slice(startIndex, endIndex).map((item, index) => (
              <td key={index}>{item.num_cycle_max.toFixed(1)}</td>
            ))}
          </tr>
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination-controls">
        <button onClick={() => changePage(0)} disabled={currentPage === 0}>
          First
        </button>
        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 0}>
          Previous
        </button>

        {/* Show number of pages and direct navigation*/}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => changePage(index)}
            className={`page-button ${index === currentPage ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}

        <button onClick={() => changePage(currentPage + 1)} disabled={endIndex >= data.data.length}>
          Next
        </button>
        <button onClick={() => changePage(totalPages - 1)} disabled={currentPage === totalPages - 1}>
          Last
        </button>
      </div>
    </div>
  );
}