/**
 * Synchronizes the keys of `obj1` with `obj2`, adding missing ones and removing extra ones.
 * @param {Object} obj1 - Base object to modify.
 * @param {Object} obj2 - Reference object.
 * @returns {Object} - Modified object with the keys of `obj2`.
 */

// Validate that the data complies with the keys of the typification
export function AddKeysMissing(obj1, obj2) {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	keys2.forEach((key) => {
		if (!keys1.includes(key)) {
			obj1[key] = obj2[key];
		}
	});
	keys1.forEach((key) => {
		if (!keys2.includes(key)) {
			delete obj1[key];
		}
	});
	return obj1;
}

/**
 * Ensures that `baseObj` has all the keys from `referenceObj`, initializing missing ones as `null`.
 * @param {Object} baseObj - Base object to modify.
 * @param {Object} referenceObj - Reference object.
 * @returns {Object} - Object with synchronized keys.
 */

export function AddKeysMissingDomain(baseObj, referenceObj) {
	const result = {};
	// Iterate over the keys of the second object (referenceObj)
	for (const key in referenceObj) {
		if (key in baseObj) {
			// If the key exists in the first object, keep its value
			result[key] = baseObj[key];
		} else {
			// If it's missing in the first object, initialize it with null
			result[key] = null;
		}
	}
	// Return the synchronized object
	return result;
}

/**
 * Validates if there are keys with empty values, `null`, or `undefined` in `data2`,
 * considering only those with the `_min` or `_max` suffix and that are active in `valuesCheck`.
 * @param {Object} data - Object containing `valuesCheck`, indicating which keys to validate.
 * @param {Object} data2 - Object with the values to check.
 * @returns {string[]} - List of base keys (without `_min` or `_max`) that have invalid values.
 */

export function validateJsonEmptyAlert(data, data2) {
	const { valuesCheck } = data;

	const validationObject = data2; // Second object
	// 1. Find keys that are null, undefined, or empty in validationObject
	const invalidKeys = Object.keys(validationObject).filter((key) => {
		const isMinOrMaxKey = key.endsWith("_min") || key.endsWith("_max");
		const value = validationObject[key];
		// Include only keys related to "_min" or "_max" that have invalid values
		return (
			isMinOrMaxKey && (value === null || value === undefined || value === "")
		);
	});
	// 2. Filter keys by valuesCheck (include only if they are `true`)
	const result = invalidKeys
		.map((key) => key.replace(/_min$|_max$/, "")) // Extract the base name without _min/_max
		.filter((baseKey) => valuesCheck[baseKey] === true); // Filter if the value in valuesCheck is `true`
	return result;
}

/**
 * Checks if an object is not empty.
 * @param {Object} obj - Object to validate.
 * @returns {boolean} - `true` if the object is not empty, otherwise `false`.
 */

export const isObjectNotEmpty = (obj) =>
	obj &&
	typeof obj === "object" &&
	!Array.isArray(obj) &&
	Object.keys(obj).length > 0;

/**
 * Validates if any field in `data` is empty (`null`, `undefined`, `""`) or if an array is empty.
 * Also validates if any array of objects contains empty fields.
 * @param {Object} data - Object with the data to validate.
 * @returns {boolean} - `true` if at least one field is invalid, otherwise `false`.
 */

export function validateFieldsEmptyProduction(data) {
	return Object.keys(data).some(key => {
					if (Array.isArray(data[key])) {
									// Si el array está vacío, es inválido
									if (data[key].length === 0) return true;

									// Detecta si el array contiene objetos (para validar sus campos internos)
									if (data[key].every(item => typeof item === "object" && item !== null)) {
													return data[key].some(item =>
																	Object.values(item).some(value =>
																					value === "" || value === null || value === undefined
																	)
													);
									}
					}

					// Valida campos normales
					return data[key] === "" || data[key] === null || data[key] === undefined;
	});
}
