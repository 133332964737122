import React, { useContext, useEffect, useState } from "react";
import { Sidebar } from "../../components/layout/Sidebar";
import { Navbar } from "../../components/layout/Navbar";
import { Steps, message, Select, Tooltip, Switch } from "antd";
import { StepForwardOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as IconUpload } from '../../assets/img/icons/upload.svg';
import { ReactComponent as IconArrowsVertical } from '../../assets/img/icons/arrowsVertical.svg';
import { ReactComponent as IconDelete } from '../../assets/img/icons/delete.svg';
import { ReactComponent as IconAdd } from '../../assets/img/icons/add.svg';
import Img_Wellhead from '../../assets/img/wellhead-img.png'
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import { CLOSING } from "ws";
import { AlertModal } from "../../components/AlertModal";
import { useNavigate } from "react-router-dom";
import { DataGasLiftContext } from "../../context/DataGasLift/DataGasLiftContext";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { STATUS_TYPE_WELL_GASLIFT } from "../../components/elements/types/type_response_API";
import { DATA_CREATE_WELL_GAS_LIFT, DATA_END_PARAMETERS } from "../../components/elements/types/type_gas_lift";
import { NAME_PAGES } from "../../components/elements/types/type_pages";

const steps = [
  {
    title: "Well",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
  {
    title: "Last",
    content: "Last-content",
  },
];

const InitialWellDeviation = [
  {
    id: 0,
    measured_depth: null,
    tvd: null,
    inc: null,
  },
  {
    id: 1,
    measured_depth: "",
    tvd: "",
    inc: null,
  },
  {
    id: 2,
    measured_depth: "",
    tvd: "",
    inc: null,
  }
]

const InitialWelEquipment = [
  {
    id: 0,
    name: "",
    type: "",
    measured_depth: null,
    internal_diameter: null,
    external_diameter: null,
    absolute_roughness: null,
  }
]

const initialCoordinates = {
  latitudeDegrees: '',
  latitudeMinutes: '',
  latitudeSeconds: '',
  latitudeDirection: '',
  longitudeDegrees: '',
  longitudeMinutes: '',
  longitudeSeconds: '',
  longitudeDirection: ''
}

export const StepMenuEditWellConfig = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const {
    User,
    userDataInfo,
    mutationUpdateJsonPVT,
    mutationUpdateJsonDataPVT,
    mutationUpdateFoPVT,
    getDataWell,
    getDataWellTables,
    setDataDeviationApi,
    setDataEquipmentApi,
    setBodyAddWell,
    dataWellGasLift,
    refetchDataWellGasLift,
    getDataFilterUserGaslift,
    dataFilterUserGasLift,
    mutationUpdateDataGasLift,
  } = useTabGaslift();


  const { dataCreated, getDataCreated } = useContext(DataGasLiftContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [dataDeviation, setDataDeviation] = useState(InitialWellDeviation);
  const {getDataPage } = useContext(DashboardContext);
  const [dataEquipment, setDataEquipment] = useState(InitialWelEquipment);
  const [inputWellInformation, setInputWellInformation] = useState(DATA_CREATE_WELL_GAS_LIFT);
  // const [inputWellInformationEnd, setInputWellInformationEnd] = useState(initialdataEndInformation);
  const [fileDeviation, setFileDeviation] = useState([]);
  const [fileEquipment, setFileEquipment] = useState([]);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [coordinate , setCoordinate] =  useState(initialCoordinates);
  const [dataEndInformation, setDataEndInformation] = useState();
  const [wellOrientation, setWellOrientation] = useState()

  function AddKeysMissing(obj1, obj2) {
    //This function is used to validate each well parameter that corresponds to the classification.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    keys2.forEach((key) => {
      if (!keys1.includes(key)) {
        obj1[key] = obj2[key];
      }
    });
    return obj1;  
  }

  useEffect(()=>{
    refetchDataWellGasLift();
  },[])

  useEffect(()=>{
    setTimeout(() => {
      if(dataWellGasLift){
        if(dataWellGasLift.statusType === STATUS_TYPE_WELL_GASLIFT.SUCCESS){
          const dataGetEdit = dataWellGasLift?.data
          const latitudeData = dataGetEdit?.latitude.split(/[°'"]/);
          const longitudeData = dataGetEdit?.longitude.split(/[°'"]/);
          const filterDataEnd = Object.assign({}, inputWellInformation, { dataDeviation: dataDeviation }, { dataEquipment: dataEquipment });
          const filteredJson = Object.fromEntries(
            Object.entries(dataGetEdit).filter(([key]) => !(key in filterDataEnd))
          );
          const filteredJsonEnd = AddKeysMissing(filteredJson,DATA_END_PARAMETERS)
          setDataEndInformation(filteredJsonEnd);

          setInputWellInformation({
            ...inputWellInformation,
            well_id: dataGetEdit?.well_id,
            region: dataGetEdit?.region,
            field: dataGetEdit?.field,
            latitude: dataGetEdit?.latitude,
            company: dataGetEdit?.company,
            well_type: dataGetEdit?.well_type,
            artificial_method: dataGetEdit?.artificial_method,
            flow_type: dataGetEdit?.flow_type,
            well_name: dataGetEdit?.well_name,
            discretized_well: dataGetEdit?.discretized_well,
            well_platforms: dataGetEdit?.well_platforms,
            country: dataGetEdit?.country,
            county: dataGetEdit?.county,
            longitude: dataGetEdit?.longitude,
            objective: dataGetEdit?.objective,
            well_orientation: dataGetEdit?.well_orientation,
            well_reference: dataGetEdit?.well_reference,
            fluid_type: dataGetEdit?.fluid_type
          })
          setDataDeviation(dataGetEdit?.dataDeviation);
          setDataEquipment(dataGetEdit?.dataEquipment);
          setCoordinate({
            ...coordinate,
            latitudeDegrees: latitudeData[0],
            latitudeMinutes: latitudeData[1],
            latitudeSeconds: latitudeData[2],
            latitudeDirection: latitudeData[3],
            longitudeDegrees: longitudeData[0],
            longitudeMinutes: longitudeData[1],
            longitudeSeconds: longitudeData[2],
            longitudeDirection: longitudeData[3]
          })
          setWellOrientation(dataGetEdit?.well_orientation)
        }
      }
    }, 1000);
  },[dataWellGasLift]);

  useEffect(() => {
    setInputWellInformation(prevState => ({
      ...prevState,
      latitude: `${coordinate.latitudeDegrees}${'°'}${coordinate.latitudeMinutes}${'\''}${coordinate.latitudeSeconds}${'"'}${coordinate.latitudeDirection}`
    }));
  
    setInputWellInformation(prevState => ({
      ...prevState,
      longitude: `${coordinate.longitudeDegrees}${'°'}${coordinate.longitudeMinutes}${'\''}${coordinate.longitudeSeconds}${'"'}${coordinate.longitudeDirection}`
    }));
  }, [coordinate]);
  
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const onChange = (value) => {
    setCurrent(value);
  };

  const handleClickNext = () => {
    if (current === 0) {
      if (inputWellInformation.well_id === '' || inputWellInformation.region === '' || inputWellInformation.field === '' || coordinate.latitudeDegrees === '' || coordinate.latitudeMinutes === '' || coordinate.latitudeSeconds === '' || coordinate.latitudeDirection === '' || inputWellInformation.company === ''
        || inputWellInformation.well_type === '' || inputWellInformation.artificial_method === '' || inputWellInformation.flow_type === '' || inputWellInformation.well_name === '' ||
        inputWellInformation.country === '' || inputWellInformation.county === '' || coordinate.longitudeDegrees === '' || coordinate.longitudeMinutes === '' || coordinate.longitudeSeconds === '' || coordinate.longitudeDirection === '' || inputWellInformation.objective === '' || inputWellInformation.well_orientation === '' ||
        inputWellInformation.well_reference === '' || inputWellInformation.fluid_type === ''
      ) {
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('There are empty fields please validate that the information is complete')
        setInputEmpty(true)
        handleStatusChange();
      }
      else {
        //En el proceso de creacion se deben setear los parametros de los filtros para las consultas
        getDataFilterUserGaslift({
          ...dataFilterUserGasLift,
          county: inputWellInformation.county,
          field: inputWellInformation.field,
          well_id: inputWellInformation.well_id,
          well_platforms: inputWellInformation.well_platforms,
          well_name: inputWellInformation.well_name,
        })
        setInputEmpty(false)
        setCurrent(current + 1);
      }
    }
    if (current === 1) {

      const validateDataNull = VerifyingNulls(dataDeviation)

      if (false) {
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('The table is empty please enter rows or load a file')
      }
      else {
        setCurrent(current + 1);
      }
    }
  };

  const handleClickPrev = () => {
    setCurrent(current - 1);
  };

  const handleClickDone = () => {
    const validateDataNull = VerifyingNulls(dataEquipment)
    const validateMD = sumMeasureDepth();
    
    if (validateDataNull || validateMD) {
      handleOpenModal();
      setTypeAlert("Warning")
      setMessageAlert('The table is empty or populated incorrectly, please enter rows or upload a file')
    }
    else {
      const arraymd = dataDeviation.map((item) => item.measured_depth);
      const arraytvd = dataDeviation.map((item) => item.tvd);
      const arrayinc = dataDeviation.map((item) => item.inc);
      const nuevoArrayDeviation = {
        md: arraymd,
        tvd: arraytvd,
        inc: arrayinc,
        t: null,
        p: null,
      }

      setDataDeviationApi(nuevoArrayDeviation);

      //Toma los ultimos datos de la tabla
      let nuevoArrayEquipment
      dataEquipment.map((objeto, index) => {
        if (index === dataEquipment.length - 1) {
          nuevoArrayEquipment = {
            reservoir_pressure: null,
            reservoir_temperature: null,
            specific_gravity_of_oil: null,
            specific_gravity_of_gas: null,
            'gas-oil_ratio': null,
            water_salinity: null,
            API_gravity_of_oil: null,
            bubble_point_pressure: null,
            tubing_ID: dataEquipment[index].type === 'tubing' ? dataEquipment[dataEquipment.length -1].internal_diameter: null,
            pipe_roughness: dataEquipment[dataEquipment.length -1].absolute_roughness,
            pipe_inclination_from_hor: null,
            casing_ID: dataEquipment[index].type === 'casing' ? dataEquipment[dataEquipment.length -1].internal_diameter : null,
            qo: null,
            qg: 0,
            ql: null,
            wc: 0.5,
            pwf: null
          }
        }
      });

      setDataEquipmentApi(nuevoArrayEquipment);
      getDataWell({ inputWellInformation, dataDeviation: [...dataDeviation], dataEquipment: [...dataEquipment] })
      const initialDataWellTables = {
        wellDeviation: nuevoArrayDeviation,
        wellEquipment: nuevoArrayEquipment,
      }
      getDataWellTables(initialDataWellTables);
      setBodyAddWell({ inputWellInformation, dataDeviation: [...dataDeviation], dataEquipment: [...dataEquipment] });
      let maxId = 0
      if (dataCreated.length > 0) {
        maxId = Math.max(...dataCreated.map(item => item.id));
      }
      getDataCreated([...dataCreated,
      {
        id: maxId + 1,
        wellConfig: {
          fluidConfig: false,
          reservoir: false,
          tuning: false
        },
        wellId: inputWellInformation.well_id,
        wellName: inputWellInformation.well_name,
        wellRegion: inputWellInformation.region,
        wellFiled: inputWellInformation.field,
        wellCountry: inputWellInformation.country,
      }
      ]);
      const valuesCalculate = {
        FO1: 1,
        FO2:1,
        FO3: 1,
        FO4: 1,
        FO5: 1,
        FO6: 1,
        FO7: 1,
        FO8: 1,
        FO9: 1,
        FO10: 1,
        FO11: 1
      }

      const dataCreateWellTables= { dataDeviation:[...dataDeviation], dataEquipment:[...dataEquipment]};
      const dataCreateWell = Object.assign({}, inputWellInformation, dataCreateWellTables, dataEndInformation);
      setTimeout(() => {
        mutationUpdateDataGasLift.mutate({ operator:userDataInfo.operator , lease:userDataInfo.lease , region:userDataInfo.region , county: dataFilterUserGasLift.county,field: dataFilterUserGasLift.field,well: dataFilterUserGasLift.well_id, username: User.user_name, data:dataCreateWell})
      }, 1000);

      setTimeout(() => {
        mutationUpdateFoPVT.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: valuesCalculate })
      }, 2000);

      setTimeout(() => {
        mutationUpdateJsonPVT.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: nuevoArrayEquipment })
      }, 3000);

      setTimeout(() => {
        mutationUpdateJsonDataPVT.mutate({ operator: userDataInfo.operator, lease: userDataInfo.lease, region: userDataInfo.region, county: dataFilterUserGasLift.county, field: dataFilterUserGasLift.field, well: dataFilterUserGasLift.well_id, username: User.user_name, data: nuevoArrayDeviation })
      }, 4000);


      navigate(`/${NAME_PAGES.gas_lift.list_well}`);
      message.success("Processing complete!")
    }
  };


  const handleAddDeviation = () => {
    const uniqueIds = [...new Set(dataDeviation.map(obj => obj.id))];
    const maxId = Math.max(...uniqueIds);
    const newDeviation = {
      id: maxId + 1,
      measured_depth: null,
      tvd: null,
      inc: null,
    };

    setDataDeviation([...dataDeviation, newDeviation]);
  };

  const handleDeleteDeviation = (id) => {
    const newData = dataDeviation.filter((item) => item.id !== id);
    setDataDeviation(newData);
  };

  const handleAddEquipment = () => {
    const uniqueIds = [...new Set(dataEquipment.map(obj => obj.id))];
    const maxId = Math.max(...uniqueIds);

    const newDeviation = {
      id: maxId + 1,
      name: "",
      type: "",
      measured_depth: null,
      internal_diameter: null,
      external_diameter: null,
      absolute_roughness: null,
    };
    setDataEquipment([...dataEquipment, newDeviation]);
  };

  const handleDeleteEquipment = (id) => {
    const newData = dataEquipment.filter((item) => item.id !== id);
    setDataEquipment(newData);
  };


  const handleUploadFile = (dataFile) => {
    const uniqueIds = [...new Set(dataDeviation.map(obj => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };

    });
    const previousIndex = (dataDeviation.length - 1);
    const lastValue = dataDeviation[dataDeviation.length - 1].measured_depth;

    const calculateMaxIndex = (array, currentIndex) => {
      let max = -Infinity;
      for (let i = 0; i < currentIndex; i++) {
          if (array[i].measured_depth > max) {
              max = array[i].measured_depth;
          }
      }
      return max;
    };
  
    const dataFileUp = dataFileEnd.map((item, index) => {
  
      const previousItem = dataFileEnd[index - 1];
  
      const previousMeasuredDepth = previousItem ? previousItem.measured_depth : null;
      const lastMax = calculateMaxIndex(dataFileEnd, index);
  
      const newMeasuredDepth = (
        (item.measured_depth < 0 || item.measured_depth > 60000) ||
        (item.measured_depth <= lastValue && (index + previousIndex) === previousIndex) ||
        (previousMeasuredDepth >= item.measured_depth) || 
        (
          (previousMeasuredDepth >= item.measured_depth  ? previousMeasuredDepth : lastMax) >= (item.measured_depth)
        ) || (item.measured_depth === lastMax)
    ) ? null : item.measured_depth;
        
      return {
          ...item,
          measured_depth: newMeasuredDepth,
          tvd: item.tvd > item.measured_depth || (item.tvd > 0 || item.tvd > 60000) ? null : item.tvd
      };
    });
    
    setDataDeviation([...dataDeviation, ...dataFileUp]);
    setFileDeviation(dataFile);
  };

  const handleUploadFile2 = (dataFile) => {
    const uniqueIds = [...new Set(dataEquipment.map(obj => obj.id))];
    const maxId = (Math.max(...uniqueIds)) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });

    const dataFileValidate= dataFileEnd.map(item => ({
      ...item,
      type: (item.type !== 'tubing' && item.type !== 'casing') ? null : item.type,
      measured_depth: item.measured_depth < 0 ? null : item.measured_depth,
      internal_diameter: (item.internal_diameter <= 0 || item.internal_diameter > 4) ? null : item.internal_diameter,
      external_diameter: (item.external_diameter <= 0 || item.external_diameter > 4) ? null : item.external_diameter,
      internal_diameter: item.internal_diameter >= item.external_diameter ? null : item.internal_diameter,
      absolute_roughness: (item.absolute_roughness > 0.1 || item.absolute_roughness < 0) ? null : item.absolute_roughness
    }));

    setDataEquipment([...dataEquipment, ...dataFileValidate]);
    setFileEquipment(dataFile);
  };

  const handleChangeInformation = (e) => {
    const { name, value } = e.target;
    setInputWellInformation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeDeviation = (e, id) => {
    const { name, value } = e.target;

    let dataValue = value !== "" ? Number(value) : null;

    const selectedIndex = dataDeviation.findIndex(
      (data) => data.id === id
    );

    if (name === 'tvd' && dataValue > dataDeviation[selectedIndex].measured_depth) {
      message.error("The value can't be greater than measured depth value");
      setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else if(name === "measured_depth" && (dataValue < 0 || dataValue > 60000)){
      message.error("Measured depth value can't be less than to 0 or greater than 60000");
      setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null, tvd: null } : item
        )
      );
    }else if(name === "tvd" && (dataValue < 0 || dataValue > 60000)){
      message.error("TVD value can't be less than to 0 or greater than 60000");
      setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    // else if(wellOrientation === "vertical" && name === "measured_depth"){
    //   setDataDeviation(prevState =>
    //     prevState.map(item =>
    //       item.id === id ? { ...item, [name]: dataValue, tvd: dataValue } : item
    //     )
    //   );
    // } 
    else {
      setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }
  };

  const handleInputBlurTest = (e, id) => {
    const {name, value} = e.target;
    let dataValue = value !== "" ? Number(value) : null;

    const selectedIndex = dataDeviation.findIndex(
      (data) => data.id === id
    );

    const lastIndex = selectedIndex != 0 ? (selectedIndex - 1) : selectedIndex;

    if (name === 'measured_depth' && (dataValue <= dataDeviation[lastIndex].measured_depth && selectedIndex != lastIndex)) {
      message.error("Measured depth value can't be less than previous row value");
      setDataDeviation(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
  }

  const handleChangeEquipment = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : null;
    
    const selectedIndex = dataEquipment.findIndex(
      (data) => data.id === id
    );

    if(name === "measured_depth" && dataValue < 0){
      message.error("Length (ft) value can't be less than 0 ");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else if(name === "internal_diameter" && dataValue > 36){
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    else if(name === "external_diameter" && dataValue > 36){
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null, internal_diameter: null } : item
        )
      );
    }
    else if(name === "internal_diameter" && dataValue >= dataEquipment[selectedIndex].external_diameter){
      message.error("Internal diamater value can't be greater than or equal External diamater value");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    else if(name === "external_diameter" && dataValue <= dataEquipment[selectedIndex].internal_diameter){
      message.error("Internal diamater value can't be greater than or equal External diamater value");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }
    else if(name === "absolute_roughness" && (dataValue > 0.1 || dataValue < 0)){
      message.error("Absolute roughness value can't be greater than 0.1 and less than 0");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else{
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: value } : item
        )
      );
    }
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : value;
    
    if((name === "internal_diameter" || name === "external_diameter") && dataValue <= 0){
      message.error("Diamaters value can't be less than or equal to 0 and greater than 36");
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: null } : item
        )
      );
    }else if (name === "measured_depth"){
      const valMeasuredDepth = sumMeasureDepth()
      
      if (valMeasuredDepth) {
        message.error("the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation");
        setDataEquipment(prevState =>
          prevState.map(item =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      }      
    }else{
      setDataEquipment(prevState =>
        prevState.map(item =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }    
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleChangeSelect = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeInformation(customEvent);
    // Puedes realizar cualquier otra lógica aquí con el valor seleccionado
    if(nameData === "well_orientation"){
      setWellOrientation(valueData)
    }
  };

  const handleChangeSelectCurrent2 = (nameData, valueData, id) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeEquipment(customEvent, id);
    // Puedes realizar cualquier otra lógica aquí con el valor seleccionado
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  }; 


  const handleChangeCoor = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "latitudeDegrees" || 
        name === "latitudeMinutes" || 
        name === "longitudeDegrees" || 
        name === "longitudeMinutes"
    ) {
      newValue = value.replace(/[^\d]/g, '');
    } else if (name === "latitudeSeconds" || name === "longitudeSeconds") {
      newValue = value.replace(/[^\d.]/g, '');
      if (value.length === 3 && value.indexOf(".") === -1) {
        newValue = value.slice(0, 2) + "." + value.slice(2); // Agrega el punto al lado del segundo número entero
      }
      const decimalIndex = newValue.indexOf('.');
      if (decimalIndex !== -1) {
        const integerPart = newValue.slice(0, decimalIndex);
        const decimalPart = newValue.slice(decimalIndex + 1, decimalIndex + 3);
        newValue = `${integerPart}.${decimalPart}`;
        if (value.indexOf(".") !== -1 && decimalPart === '') {
          newValue = value.slice(0, -1); // Quita el punto si no hay decimales
        }
      } 
    }

    setCoordinate((prevState) =>({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleChangeSelectCoor = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeCoor(customEvent);
  };

  function VerifyingNulls(array) {
    let validateValuesNull = false;
    if (wellOrientation === "vertical") {
      array.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
          if (key !== 'inc' && (value === null || value === '')) {
            validateValuesNull = true;
          }
        });
      });
    }else{
      array.forEach(obj => {
        Object.values(obj).forEach(value => {
          if (value === null || value === '') {
            validateValuesNull = true;
          }
        });
      });
    }

    return validateValuesNull;
  }

  function sumMeasureDepth() {
    const valuesDeviation = dataDeviation.length ? dataDeviation.length : null;
    const lastDeviation = dataDeviation[valuesDeviation - 1].measured_depth;
    
    const value= dataEquipment.reduce((total, item) => {
      return total + parseFloat(item.measured_depth);
    }, 0);

    return(value > lastDeviation);
  }
  
  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex absolute h-full "></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal showAlert={showAlert} handleCloseModal={handleCloseModal} messageAlert={messageAlert} typeModal={typeAlert}></AlertModal>
      </div>

      <div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
        <Steps
          size="default"
          current={current}
          onChange={onChange}
          className="site-navigation-steps flex w-[600px] custom-steps"
          items={[
            {
              title: "Well",
              disabled: { status },
              description: "Detailed well information",
            },
            {
              title: "Well deviation",
              disabled: { status },
              description: "Detailed well deviation",
            },
            {
              title: "Well equipment",
              disabled: { status },
              description: "Detailed well equipment",
            },
          ]}
        />
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center">
        {current == 0 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]" >Well information</h1>
              </div>
            </div>
            <div className="grid grid-cols-2 w-full h-[750px]  gap-x-[43px]">
              <div className="flex flex-col  text-right items-end">
                <label htmlFor="" className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well ID</label>
                <div className="flex flex-row w-full max-w-[592px] gap-5">
                <input type="text" name="well_id" disabled={true} value={inputWellInformation.well_id} onChange={handleChangeInformation} className={`w-full h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_id === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                {/* <button disabled onClick={handleClickGenerateId} className="w-1/3 h-[47px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1">Generate Id</button> */}
                </div>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well Name</label>
                <Select
                  disabled={true} 
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_name === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_name === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                  name="well_name"
                  value={inputWellInformation.well_name || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_name", value)}
                >
                  <Option value="C6">C6</Option>
                  <Option value="C9">C9</Option>
                </Select>
                {/* <input type="text" name="well_name" value={inputWellInformation.well_name} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_name === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} /> */}
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Region</label>
                <input type="text" disabled={true} name="region" value={inputWellInformation.region} onChange={handleChangeInformation} className={`w-full max-w-[592px] px-3 h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.region === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Field</label>
                <input type="text" disabled={true} name="field" value={inputWellInformation.field} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.field === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Latitude</label>
                <div className="grid grid-cols-4 w-[592px] h-[65px] gap-[15px] ">
                <div className="relative">
                  <input placeholder={'29°'} type="number" name="latitudeDegrees"  value={coordinate.latitudeDegrees} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeDegrees === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                    °
                  </div>
                </div>
                <div className="relative">
                  <input placeholder={'13\''} type="text" name="latitudeMinutes" value={coordinate.latitudeMinutes} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeMinutes === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                      '
                  </div>
                </div>
                <div className="relative">
                  <input placeholder={'47.9"'} type="text" name="latitudeSeconds" value={coordinate.latitudeSeconds} onChange={handleChangeCoor} maxLength={5} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.latitudeSeconds === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                      "
                  </div>
                </div>
                <Select name="latitudeDirection"  value={coordinate.latitudeDirection}  className="w-full  h-[47px] text-left mb-[15px]"  onChange={(value) => handleChangeSelectCoor('latitudeDirection', value)}>
                  <Option value="N">N</Option>
                  <Option value="S">S</Option>
                </Select>
                </div>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Company</label>
                <input type="text" name="company" value={inputWellInformation.company} onChange={handleChangeInformation} className={`w-full max-w-[592px] px-3 h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.company === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well type</label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="well_type"
                  value={inputWellInformation.well_type || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_type", value)}
                >
                  <Option value="producer">Producer</Option>
                  <Option value="injector">Injector</Option>
                </Select>
                {/* <input type="text"  name="well_type" value={inputWellInformation.well_type} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_type === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Artificial lift method</label>
                {/* <input type="text"  name="artificial_method" value={inputWellInformation.artificial_method} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.artificial_method === ''? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.artificial_method === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.artificial_method === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="artificial_method"
                  value={inputWellInformation.artificial_method || "Selected option"}
                  onChange={(value) => handleChangeSelect("artificial_method", value)}
                >
                  <Option value="none">None</Option>
                  <Option value="continuous_gas_lift">Continuous Gas Lift</Option>
                  <Option value="esp" disabled>Esp</Option>
                </Select>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Flow type</label>
                {/* <input type="text"  name="flow_type" value={inputWellInformation.flow_type} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.flow_type === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.flow_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.flow_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="flow_type"
                  value={inputWellInformation.flow_type || "Selected option"}
                  onChange={(value) => handleChangeSelect("flow_type", value)}
                >
                  <Option value="tubing">Tubing</Option>
                  <Option value="annular">Annular</Option>
                  <Option value="tubing_annular">Tubing Annular</Option>
                </Select>
              </div>
              <div className="flex flex-col text-left items-start">
                <div className="flex flex-row w-full max-w-[592px] mb-[7px] gap-5">
                  <label htmlFor="" className="flex w-[80%] text-start font bold text-[15px]">Platforms</label>
                  <label htmlFor="" className="flex w-[20%] text-start font bold text-[15px]">Discretized well</label>
                </div>
                <div className="flex flex-row w-full max-w-[592px] h-[47px] mb-[15px] items-center justify-center text-center gap-5">
                  <Select
                    disabled={true} 
                    style={{ backgroundColor: inputEmpty && inputWellInformation.well_platforms === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_platforms === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                    className="w-[80%] h-full text-left"
                    name="well_platforms"
                    value={inputWellInformation.well_platforms || "Selected option"}
                    onChange={(value) => handleChangeSelect("well_platforms", value)}
                  >
                    <Option value="SMI-27">SMI-27 </Option>
                    <Option value="V-191">V-191</Option>
                  </Select>
                  <div className="flex w-[20%] h-full justify-start text-left items-center">
                    <Switch
                     disabled={true} 
                      style={{
                        backgroundColor: inputWellInformation.discretized_well ? "#662D91" : "#707070",
                        borderColor: inputWellInformation.discretized_well ? "#662D91" : "#707070",
                      }}
                      checked={inputWellInformation.discretized_well}
                    ></Switch>
                  </div>
                </div>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Country</label>
                <input type="text" disabled={true} name="country" value={inputWellInformation.country} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.country === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">County</label>
                <input type="text" disabled={true} name="county" value={inputWellInformation.county} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.county === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Longitude</label>
                <div className="grid grid-cols-4 w-[592px] h-[65px] gap-[15px] ">
                <div className="relative">
                  <input placeholder={'29°'} type="text" name="longitudeDegrees"  value={coordinate.longitudeDegrees} onChange={handleChangeCoor} maxLength={3} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeDegrees === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                        °
                  </div>
                </div>
                <div className="relative">
                  <input placeholder={'13\''} type="text" name="longitudeMinutes" value={coordinate.longitudeMinutes} onChange={handleChangeCoor} maxLength={2} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeMinutes === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                      '
                  </div>
                </div>
                <div className="relative">
                  <input placeholder={'47.9"'} type="text" name="longitudeSeconds" value={coordinate.longitudeSeconds} onChange={handleChangeCoor} maxLength={5} className={`w-full  h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && coordinate.longitudeSeconds === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                  <div className="absolute top-0 right-0 flex items-center pt-1 pr-3" style={{ fontSize: '1.5rem' }}>
                        "
                  </div>
                </div>
                <Select 
                style={{ backgroundColor: inputEmpty && coordinate.longitudeDirection === '' ? '#FEF9C3' : '', border: inputEmpty && coordinate.longitudeDirection === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                name="longitudeDirection" 
                value={coordinate.longitudeDirection || "Direction"}
                className="w-full  h-[47px] text-left mb-[15px]"
                onChange={(value) => handleChangeSelectCoor('longitudeDirection', value)}>
                  <Option value="W">W</Option>
                  <Option value="E">E</Option>
                </Select>
                </div><label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Objective</label>
                <input type="text" name="objective" value={inputWellInformation.objective} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.objective === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `} />
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well orientation</label>
                {/* <input type="text" name="well_orientation" value={inputWellInformation.well_orientation} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_orientation === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_orientation === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_orientation === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="well_orientation"
                  value={inputWellInformation.well_orientation || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_orientation", value)}
                >
                  <Option value="vertical">Vertical</Option>
                  <Option value="deviated">Deviated</Option>
                  <Option value="horizontal">Horizontal</Option>
                </Select>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Well reference</label>
                {/* <input type="text" name="well_reference" value={inputWellInformation.well_reference} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.well_reference === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.well_reference === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.well_reference === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-left"
                  name="well_reference"
                  value={inputWellInformation.well_reference || "Selected option"}
                  onChange={(value) => handleChangeSelect("well_reference", value)}
                >
                  <Option value="onshore">Onshore</Option>
                  <Option value="offshore">Offshore</Option>

                </Select>
                <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Fluid type</label>
                {/* <input type="text" name="fluid_type" value={inputWellInformation.fluid_type} onChange={handleChangeInformation} className={`w-full max-w-[592px] h-[47px] rounded-[4px]  mb-[17px] border border-solid  ${inputEmpty && inputWellInformation.fluid_type === '' ? ' border-red-700 bg-yellow-100' : 'border border-solid border-[#BCBCCC]'} `}/> */}
                <Select
                  style={{ backgroundColor: inputEmpty && inputWellInformation.fluid_type === '' ? '#FEF9C3' : '', border: inputEmpty && inputWellInformation.fluid_type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full max-w-[592px] h-[47px] mb-[15px] text-start"
                  name="fluid_type"
                  value={inputWellInformation.fluid_type || "Selected option"}
                  onChange={(value) => handleChangeSelect("fluid_type", value)}
                >
                  <Option value="black_oil">Black Oil</Option>
                  <Option value="volatile_oil" disabled>Volatile Oil</Option>
                  <Option value="condensate" disabled>Condensatee</Option>
                  <Option value="dry_gas" disabled>Dry gas</Option>
                </Select>
              </div>
            </div>
          </>
        }
        {current == 1 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Well orientation ({wellOrientation})</h1>
                <ImportFile uploadfile={handleUploadFile}></ImportFile>
              </div>
            </div>
            <div className="flex flex-row w-full min-h-[710px] min-w-[1550px] item-center justify-center  overflow-x-auto">
              <ul className="flex flex-col">
                <div className="flex w-[1300px] gap-[26px] mb-[7px] ml-[124px]">
                  <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                    Measured depth (ft)
                    <Tooltip title={'Value greater than 0'}>
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip> 
                  </label>
                  <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                    True vertical depth (ft)
                    <Tooltip title={'Value less than or equal to Measured depth (ft)'}>
                      <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                  </label>
                  <label className="w-full max-w-[392px] text-[15px] item-start justify-start text-left">
                    Inclination (°)
                  </label>
                </div>
                {dataDeviation.map((item, index) => (
                  <li
                    key={item.id}
                    className="flex flex-row w-full overflow-y-auto"
                  >
                    <div className="flex w-full gap-[26px] mb-[10px]">
                      {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500 " /> */}
                      <label htmlFor="" className="flex w-[20px] font-bold text-lg text-center items-center justify-center ">{index + 1}</label>
                      <input 
                        type="number" min="0" onKeyDown={handleKeyDown} 
                        defaultValue={item.measured_depth} 
                        name={`measured_depth`} 
                        value={dataDeviation.measured_depth} 
                        onChange={(e) => handleChangeDeviation(e, item.id)}
                        onBlur={(e) => handleInputBlurTest(e, item.id)}
                        className={`w-full max-w-[392px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${item.measured_depth === null ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"}`} />
                      <input 
                        type="number" 
                        min="0" onKeyDown={handleKeyDown} 
                        //defaultValue={wellOrientation === "vertical" ? item.measured_depth : item.tvd} 
                        defaultValue={item.tvd}
                        name={`tvd`} 
                        //value={wellOrientation === "vertical" ? item.measured_depth : dataDeviation.tvd} 
                        value={dataDeviation.tvd} 
                        onChange={(e) => handleChangeDeviation(e, item.id)} 
                        className={`w-full max-w-[392px] h-[47px] rounded-[4px] border border-solid border-[#BCBCCC] px-3  ${item.tvd === null || item.tvd > item.measured_depth ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"}`} 
                        //disabled={wellOrientation === "vertical" || item.measured_depth === null || item.measured_depth === ""} 
                        disabled={item.measured_depth === null || item.measured_depth === ""} 
                        />
                      <input type="number" min="0"
                        onKeyDown={handleKeyDown} 
                       //defaultValue={wellOrientation === "vertical" ? null : item.inc} 
                        defaultValue={item.inc} 
                        name={"inc"} 
                        value={wellOrientation === "vertical" ? null : dataDeviation.inc}
                       //disabled={wellOrientation === "vertical"} 
                        onChange={(e) => handleChangeDeviation(e, item.id)} 
                        className="w-full max-w-[392px] h-[47px] border border-solid border-[#BCBCCC] px-3 rounded-sm" />

                      {(dataDeviation.length - 1 === index) &&
                        <IconAdd className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleAddDeviation()} />
                      }
                      {/* {(wellOrientation === "vertical" && dataDeviation.length <= 1 ) &&
                        <IconAdd className="w-6 h-[47px] fill-neutral-500 " disabled="true" onClick={() => handleAddDeviation()} />
                      } */}
                      {dataDeviation.length >= 4 &&
                        <IconDelete className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleDeleteDeviation(item.id)} />
                      }
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        }
        {current == 2 &&
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">Well equipment
                </h1>
                <ImportFile uploadfile={handleUploadFile2}></ImportFile>
              </div>
            </div>
            <div className="flex flex-row w-full min-h-[710px] min-w-[1800px] overflow-x-auto">
              <div className="flex flex-row w-5/6 h-full item-center justify-center">
                <ul className="flex flex-col w-full pl-[20px]">
                  <div className="flex w-full gap-[26px] mb-[7px] pl-[90px] pr-[50px]">
                    <label className="w-full max-w-[280px] text-[15px] item-start justify-start text-left">Name</label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">Type</label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">Length in contact with fluid flow MD (ft)
                      <Tooltip title={`The value can't be less than 0`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">External diameter (in)
                      <Tooltip title={`Value can't be less than Internal diameter`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label className="w-full max-w-[177px] text-[15px] item-start justify-start text-left">Internal diameter (in)
                      <Tooltip title={`Maximum allowed value: 36 (in)`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <label className="flex w-full max-w-[177px] text-[15px] item-start justify-start text-left ">Absolute roughness (in)
                      <Tooltip title={`Value can't be less than 0 and greater than 0.1`}>
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                  </div>
                  {dataEquipment.map((item, index) => (
                    <li
                      key={item.id}
                      className="flex flex-row w-full overflow-y-auto"
                    >
                      <div className={`flex w-full gap-[26px] mb-[10px] pl-[30px]`}>
                        {/* <IconArrowsVertical className="w-6 h-[47px] fill-neutral-500 " /> */}
                        <label 
                          min="0" 
                          htmlFor="" 
                          className="flex w-[10px] font-bold text-lg text-center items-center justify-center ">
                            {index + 1}
                        </label>
                        <input 
                          type="text" 
                          defaultValue={item.name} 
                          name="name" 
                          value={dataEquipment[index].name} 
                          onChange={(e) => handleChangeEquipment(e, item.id)} 
                          className="w-full max-w-[280px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3" 
                        />
                        <Select
                          style={{ backgroundColor: inputEmpty && dataEquipment.type === '' ? '#FEF9C3' : '', border: inputEmpty && dataEquipment.type === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                          className="w-[12%] max-w-[12%] h-[47px] text-left"
                          name="type"
                          value={dataEquipment[index].type}
                          defaultValue={item.type}
                          onChange={(value) => handleChangeSelectCurrent2('type', value, item.id)}
                        >
                          <Option value="tubing">Tubing</Option>
                          <Option value="casing">Casing</Option>
                        </Select>
                        <input 
                          type="number" 
                          min="0" 
                          onKeyDown={handleKeyDown} 
                          defaultValue={item.measured_depth} 
                          name="measured_depth" 
                          value={dataEquipment[index].measured_depth} 
                          onChange={(e) => handleChangeEquipment(e, item.id)}
                          onBlur={(e) => handleChangeBlur(e, item.id)}
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 
                            ${item.measured_depth === null
                            ? "border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"}`} />
                        <input 
                          type="number"
                          min="0"
                          max="36"
                          onKeyDown={handleKeyDown} 
                          defaultValue={item.external_diameter}
                          name="external_diameter"
                          value={dataEquipment[index].external_diameter}
                          onChange={(e) => handleChangeEquipment(e, item.id)} 
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${item.external_diameter === null ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`} />
                        <input 
                          type="number"
                          min="0"
                          max={item.external_diameter}
                          onKeyDown={handleKeyDown}
                          defaultValue={item.internal_diameter}
                          name="internal_diameter"
                          disabled={item.external_diameter === "" || item.external_diameter === null || item.external_diameter > 36} 
                          value={dataEquipment[index].internal_diameter}
                          onChange={(e) => handleChangeEquipment(e, item.id)}
                          onBlur={(e) => handleChangeBlur(e, item.id)}
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${item.internal_diameter === null || item.internal_diameter > 36 ? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`}/>
                        <input 
                          type="number" 
                          min="0" 
                          max="36" 
                          onKeyDown={handleKeyDown}
                          defaultValue={item.absolute_roughness} 
                          name="absolute_roughness" 
                          value={dataEquipment[index].absolute_roughness} 
                          onChange={(e) => handleChangeEquipment(e, item.id)} 
                          className={`w-full max-w-[177px] h-[47px] border border-solid border-[#BCBCCC] rounded-[4px] px-3 ${item.absolute_roughness === 0 || item.absolute_roughness === null? " border-red-700 bg-yellow-100"
                          : "border-[#BCBCCC]"}`} />
                        {dataEquipment.length - 1 === index &&
                          <IconAdd className="w-6 h-[47px] fill-neutral-500 " onClick={handleAddEquipment} />
                        }
                        {dataEquipment.length >= 2 &&

                          <IconDelete className="w-6 h-[47px] fill-neutral-500 " onClick={() => handleDeleteEquipment(item.id)} />
                        }

                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex w-1/6 h-full justify-center items-center  text-end mt-[300px]">
                <img
                  src={Img_Wellhead}
                  className="w-[219px] h-[650px] lg:absolute"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </>
        }
        <div className="flex w-full text-center justify-center items-center mb-[90px]">
          <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
            {current > 0 && (
              <button
              className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
              onClick={() => handleClickPrev()}
              >
                Previous
              </button>
            )}
            {current < steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickNext()}
              >
                Continue
              </button>
            )}
            {current === steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickDone()}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
