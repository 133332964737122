import { serviceEndPoints } from "../../config/config"

const DB_URL_DCA = serviceEndPoints.DCA.BASE_URL_DCA;
const DB_DataHistory = serviceEndPoints.DCA.BASE_URL_DATA_HISTORY;
const DB_URL_VOLUMETRACKER = serviceEndPoints.DCA.BASE_URL_VOLUMETRACKER;
const BASE_URL_VOLUMETRACKER_RT = serviceEndPoints.DCA.BASE_URL_VOLUMETRACKER_RT; 

  export const getJsonIOTAllPlatforms = async (operator, lease, region, county, field, wellid, user) => {
    try{
        const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/data_devices/get_all_devices/200/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`, {
            method: "GET",
            headers: {
              Authorization:
                "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9",
            },
          }
        );
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
  };

  export const uploadJsonWellDCA = async (dataJson) => {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const wellid = dataJson.wellid;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const response = await fetch(`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"},
        body: JSON.stringify(data)
      }); 
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    }
  };

  export const updateJsonWellDCA = async (dataJson) => {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const wellid = dataJson.wellid;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const response = await fetch (`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"},
        body: JSON.stringify(data)
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem update the information of well");
    }
  };

  export const deleteJsonWellDCA = async (dataJson) => {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const wellid = dataJson.wellid;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const response = await fetch(`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`, {
        method: "DELETE",
        headers: {
          "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"},
        body: JSON.stringify(data) 
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem deleting the information of the well");
    }
  };

  export const getJsonWellDCA = async (operator,lease,region,county,field,wellid,userName) => {
    try {
      const response = await fetch(`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`, {
      method: "GET",
      headers: {
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"},
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    }
  };

  export const getJsonADDWellDCA = async (operator,lease,region,userName) => {
    try {
      const response = await fetch(`${DB_URL_DCA}/parameterslist/${operator}/${lease}/${region}/${userName}/parametersdca`, {
      method: "GET",
      headers: {
        "Authorization": "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3"},
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    }
  };

  export const uploadDataHistory = async (dataJson) => {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const wellid = dataJson.wellid;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const body = {data: data};
      const response = await fetch(`${DB_DataHistory}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"
        }, body: JSON.stringify(body)
      });
      return response.json();
    } catch (error){
      throw new Error("Problem update the data history")
    }
  }

  export const updateDataHistory = async (dataJson) =>  {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const wellid = dataJson.wellid;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const response = await fetch (`${DB_DataHistory}/update-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"
        }, body: JSON.stringify(data)
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem update the information of the data history");
    }
  };

  export const deleteDataHistory = async (dataJson) => {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const wellid = dataJson.wellid;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const response = await fetch(`${DB_DataHistory}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`, {
        method: "DELETE",
        headers: {
          "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"
        },  body: JSON.stringify(data) 
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem deleting the information of the well");
    }
  };

  export const getDataHistory = async (operator,lease,region,county,field,wellid,userName) => {
    try {
      const response = await fetch(`${DB_DataHistory}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`, {
      method: "GET",
      headers: {
        "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"},
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the data history")
    }
  };

  export const getListDataHistory = async (operator,lease,region,userName) => {
    try {
      const response = await fetch(`${DB_DataHistory}/list-wells/${operator}/${lease}/${region}/${userName}`, {
      method: "GET",
      headers: {
        "Authorization": "Token 3Mex3aNFqENWFpjQ2mhgNhYhOxX9TJdeuez7yvEvh"},
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    }
  };

  export const uploadJsonVolumeTracker = async (dataJson) => {
    try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const userName = dataJson.userName;
      const data = dataJson.data;
      const response = await fetch(`${DB_URL_VOLUMETRACKER}/calculate_averages_volume/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9"},
        body: JSON.stringify(data)
      }); 
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    } 
  };

  export const getJsonVolumeTracker = async (operator,lease,region,county,field,wellid,userName) => {
    try {
      const response = await fetch(`${DB_URL_VOLUMETRACKER}/results_averages_volume/${operator}/${lease}/${region}/${county}/${field}/${userName}`, {
      method: "GET",
      headers: {
        "Authorization": "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9"},
      });
      return response.json();
    } catch (error) {
      throw new Error("Problem getting the well")
    }
  };

  export const getJsonIOTVolumeTracker = async (operator, lease, region, county, field, wellid, user) => {
    try{
        const response = await fetch(`${BASE_URL_VOLUMETRACKER_RT}/data_devices/get_last_devices/200/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`, {
            method: "GET",
            headers: {
                "Authorization": "Token 23df4b99a9d4833bdcb0dbc5192b55b5250da7fee8a6a51fc82694721b0d04c9"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
  };
