import React from "react";
import { DashboardPLContext } from "./DashboardPLContext";
import { useDashboardPL } from "../../hooks/useDashboardPlungerLift";

export const DashboardPLProvider = ({ children }) => {
	const {
		dataFilterUserPL,
		selectDashboardPL,
		lastSelectDataIPRPL,
		validateAlertPlungerLift,
		viewLoadingPlungerLift,
		stateMessageApisPL,
		dataChartReservoir,
		dataChartVLP,
		getDataFilterPL,
		getSelectDashboardPL,
		handleSelectWellGaslift,
		getSelectDataIPRPL,
		getValidateAlertPlungerLift,
		getViewLoadingPlungerLift,
		getStateMessageApisPL,
		getDataChartReservoirPL,
		getDataChartVLP,
	} = useDashboardPL();
	return (
		<DashboardPLContext.Provider value={{
			dataFilterUserPL,
			selectDashboardPL,
			lastSelectDataIPRPL,
			validateAlertPlungerLift,
			viewLoadingPlungerLift,
			stateMessageApisPL,
			dataChartReservoir,
			dataChartVLP,
			getDataFilterPL,
			getSelectDashboardPL,
			handleSelectWellGaslift,
			getSelectDataIPRPL,
			getValidateAlertPlungerLift,
			getViewLoadingPlungerLift,
			getStateMessageApisPL,
			getDataChartReservoirPL,
			getDataChartVLP,
		}}>
			{children}
		</DashboardPLContext.Provider>
	)
}
