import { Alert, Modal, Select, Steps, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../../../components/layout/Navbar";
import { Sidebar } from "../../../components/layout/Sidebar";
import { useTabInjector } from "../../../hooks/useTabInjector";
import { useTabIOT } from "../../../hooks/useTabIOT";
import { useUsers } from "../../../hooks/useUsers";
import { NAME_PAGES } from "../../../components/elements/types/type_pages";
import {
	INITIAL_COORDINATES,
	DATA_CREATE_WELL_INJECTOR,
} from "../../../components/elements/types/type_injector";
import { Loading } from "../../../components/Loading";
import { AlertModal } from "../../../components/AlertModal";
import {
	processValue,
	validateMeasuredDepthSequence,
} from "../../../components/utils/utils";
import WellDefinition from "./components/WellDefinition";
import WellDeviation from "./components/WellDeviation";
import WellEquipment from "./components/WellEquipment";
import {
	INITIAL_DATA_WELL_DEVIATION,
	INITIAL_DATA_WELL_EQUIPMENT,
} from "../../../components/elements/types/type_gas_lift";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import { removeEmptyRows } from "../../../helpers/helpersProcess";
import { AlertModalOption } from "../../../components/AlertModalOption";
import { CUSTOM_SCENARIO_TYPES } from "../types/customScenariosTypes";

const steps = [
	{ title: "Well", content: "First-content" },
	{ title: "Well Deviation", content: "Second-content" },
	{ title: "Well Equipment", content: "Last-content" },
];

const initialValidatioTable = {
	tableDeviation: false,
	tableEquipment: false,
};

const StepMenuWellInjector = ({ isEditing }) => {
	const navigate = useNavigate();
	const { getSelectedWellInjector, deviceRealTime, getDeviceRealTime } =
		useContext(DashboardContext);
	const { Option } = Select;
	const {
		userDataInfo,
		mutationUploadDataInjector,
		mutationUpdateDataInjector,
		mutationUploadDataHistory,
		dataWellInjector,
		dataFilterUserInjector,
		refetchDataWellInjector,
		refetchListWellInjector,
		listWellInjector,
		setGenerateTable,
		getDataFilterUserInjector,
		generateRandomID,
		setTypeTable,
	} = useTabInjector();
	const {
		dataListPlatforms,
		refetchDataListPlatforms,
		dataListPlatformsWells,
		refetchDataListPlatformsWells,
		setTypeProcessPlatform,
		typeProcessPlatform,
	} = useTabIOT();
	const { User } = useUsers();
	const [wellboreOptionState, setWellboreOptionState] = useState(false);
	const [current, setCurrent] = useState(0);
	const [status, setStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [messageAlert, setMessageAlert] = useState(false);
	const [typeAlert, setTypeAlert] = useState(false);
	const [inputEmpty, setInputEmpty] = useState(false);
	const [coordinate, setCoordinate] = useState(INITIAL_COORDINATES);
	const [inputDataInjector, setInputDataInjector] = useState(DATA_CREATE_WELL_INJECTOR);
	const mutateLoadFile = mutationUploadDataInjector.isLoading;
	const dataListWellInjector = listWellInjector != null ? listWellInjector?.data : null;
	const getDataInjector = dataWellInjector ? dataWellInjector.data : null;
	const [dataDeviation, setDataDeviation] = useState(	INITIAL_DATA_WELL_DEVIATION);
	const [dataEquipment, setDataEquipment] = useState(	INITIAL_DATA_WELL_EQUIPMENT);
	const [validationTable, setValidationTable] = useState(initialValidatioTable);

	useEffect(() => {
		if (isEditing) {
			// Si estamos en modo edición, recargamos datos del inyector
			refetchDataWellInjector();
			refetchDataListPlatforms();
		} else {
			// Si estamos en modo creación, recargamos listas de plataformas y pozos
			refetchDataListPlatformsWells();
			refetchDataListPlatforms();
			refetchListWellInjector();

			// Inicializamos datos con región predefinida
			setInputDataInjector((prevState) => ({
				...prevState,
				region: userDataInfo.region,
			}));
			getDeviceRealTime({
				...deviceRealTime,
				device_injector: {
					name: "",
					platform: "",
					discretized: false,
				},
			});
			// Reiniciamos los filtros y seleccionamos ningún pozo por defecto
			getDataFilterUserInjector({
				county: null,
				field: null,
				well_id: null,
				well_platforms: null,
				well_name: null,
			});
			getSelectedWellInjector(null);
		}
	}, [isEditing]);

	useEffect(() => {
		if (isEditing && getDataInjector !== null) {
			// Procesamos coordenadas en caso de que existan datos previos
			const latitudeData = getDataInjector?.latitude.split(/[°'"]/);
			const longitudeData = getDataInjector?.longitude.split(/[°'"]/);

			// Seteamos los valores obtenidos
			setInputDataInjector((prevState) => ({
				...prevState,
				well_id: getDataInjector?.well_id,
				well_name: getDataInjector?.well_name,
				discretized_well: getDataInjector?.discretized_well,
				well_platforms: getDataInjector?.well_platforms,
				well_type: getDataInjector?.well_type || prevState.well_type,
				region: getDataInjector?.region,
				county: getDataInjector?.county,
				field: getDataInjector?.field,
				latitude: getDataInjector?.latitude,
				longitude: getDataInjector?.longitude,
			}));

			// Cargamos los datos de desviación y equipo si existen
			setDataDeviation(
				getDataInjector?.dataDeviation || INITIAL_DATA_WELL_DEVIATION,
			);
			setDataEquipment(
				getDataInjector?.dataEquipment || INITIAL_DATA_WELL_EQUIPMENT,
			);

			// Actualizamos coordenadas separadas
			setCoordinate({
				latitudeDegrees: latitudeData[0],
				latitudeMinutes: latitudeData[1],
				latitudeSeconds: latitudeData[2],
				latitudeDirection: latitudeData[3],
				longitudeDegrees: longitudeData[0],
				longitudeMinutes: longitudeData[1],
				longitudeSeconds: longitudeData[2],
				longitudeDirection: longitudeData[3],
			});
		}
	}, [isEditing, getDataInjector]);

	useEffect(() => {
		setInputDataInjector((prevState) => ({
			...prevState,
			latitude: `${coordinate.latitudeDegrees}°${coordinate.latitudeMinutes}'${coordinate.latitudeSeconds}"${coordinate.latitudeDirection}`,
		}));

		setInputDataInjector((prevState) => ({
			...prevState,
			longitude: `${coordinate.longitudeDegrees}°${coordinate.longitudeMinutes}'${coordinate.longitudeSeconds}"${coordinate.longitudeDirection}`,
		}));
	}, [coordinate]);
	useEffect(() => {
		if (typeProcessPlatform) {
			refetchDataListPlatformsWells();
		}
	}, [typeProcessPlatform]);
	const wellboreOption = () => {
		setWellboreOptionState(!wellboreOptionState);
	};

	const onChangeStep = (value) => {
		setCurrent(value);
	};

	const handleClickNext = () => {
		switch (current) {
			case 0:
				if (validationForCurrent(current)) {
					getDataFilterUserInjector({
						...dataFilterUserInjector,
						county: inputDataInjector.county,
						field: inputDataInjector.field,
						well_id: inputDataInjector.well_id,
						well_platforms: inputDataInjector.well_platforms,
						well_name: inputDataInjector.well_name,
					});

					if (!isEditing) {
						getSelectedWellInjector(inputDataInjector.well_id);
					}

					setInputEmpty(false);
					setCurrent(current + 1);
				} else {
					setInputEmpty(true);
					showWarning(
						"Please, complete the missing information in the mandatory fields",
						current,
					);
					handleStatusChange();
				}
				break;

			case 1:
				if (validationForCurrent(current)) {
					setInputEmpty(false);
					setCurrent(current + 1);
				} else {
					setValidationTable({ ...validationTable, tableDeviation: true });
					showWarning(
						"Please, complete the missing information in the mandatory fields",
						current,
					);
					handleStatusChange();
				}
				break;

			case 2:
				if (validationForCurrent(current)) {
					setInputEmpty(false);
					setCurrent(current + 1);

					if (isEditing) {
						setGenerateTable(true);
					} else {
						setTypeTable([]);
					}
				} else {
					setValidationTable({ ...validationTable, tableEquipment: true });
					showWarning(
						"Please, complete the missing information in the mandatory fields",
						current,
					);
					handleStatusChange();
				}
				break;

			default:
				break;
		}
	};

	const handleClickPrev = () => {
		setCurrent(current - 1);
	};

	const handleClickDone = () => {
		// Validación de todos los pasos
		const validationForStep =
			validationForCurrent(0) &&
			validationForCurrent(1) &&
			validationForCurrent(2);
		const messageNullEmpty =
			"Please, complete the missing information in the mandatory fields";
		if (!validationForStep) {
			setInputEmpty(true);
			setValidationTable({
				...validationTable,
				tableDeviation: true,
				tableEquipment: true,
			});
			showWarning(messageNullEmpty, current);
		} else {
			setInputEmpty(false);
			setValidationTable({
				...validationTable,
				tableDeviation: false,
				tableEquipment: false,
			});

			// Unificamos los datos de Well con las tablas de desviación y equipo
			const dataCreateWellTables = {
				dataDeviation: [...dataDeviation],
				dataEquipment: [...dataEquipment],
			};
			const dataCreateWell = { ...inputDataInjector, ...dataCreateWellTables };

			if (isEditing) {
				// Modo Edición: Actualizar datos existentes
				mutationUpdateDataInjector.mutate({
					operator: userDataInfo.operator,
					lease: userDataInfo.lease,
					region: userDataInfo.region,
					county: dataFilterUserInjector.county,
					field: dataFilterUserInjector.field,
					wellid: dataFilterUserInjector.well_id,
					userName: User.user_name,
					data: dataCreateWell,
				});
			} else {
				// Modo Creación: Subir nuevos datos
				mutationUploadDataInjector.mutate({
					operator: userDataInfo.operator,
					lease: userDataInfo.lease,
					region: userDataInfo.region,
					county: inputDataInjector.county,
					field: inputDataInjector.field,
					wellid: inputDataInjector.well_id,
					userName: User.user_name,
					data: dataCreateWell,
				});
			}

			// Redirigir a la lista de pozos después de la operación
			navigate(`/${NAME_PAGES.well_injector.list_well}`);
		}
	};

	const handleChangeInputData = (e) => {
		const { name, value } = e.target;

		if (isEditing) {
			// Modo Edición: Simplemente actualizamos el estado sin validaciones adicionales
			setInputDataInjector((prevState) => ({
				...prevState,
				[name]: value,
			}));
		} else {
			// Modo Creación: Validamos y procesamos los valores antes de asignarlos
			const processedValue = processValue(name, value);

			const wellIdExists =
				name === "well_id"
					? dataListWellInjector?.some((obj) => obj?.well_id === processedValue)
					: false;

			const wellNameExists =
				name === "well_name"
					? dataListWellInjector?.some((obj) => obj?.well_name === processedValue)
					: false;

			if (wellIdExists) {
				message.error(
					"There is already a well with the same ID, please generate a new ID.",
				);
				setInputDataInjector((prevState) => ({
					...prevState,
					well_id: null,
				}));
			} else if (wellNameExists) {
				message.error(
					"There is already a well with the same Name, please select a new name.",
				);
				setInputDataInjector((prevState) => ({
					...prevState,
					well_name: null,
				}));
			} else {
				setInputDataInjector((prevState) => ({
					...prevState,
					[name]: processedValue,
				}));
				if (name === "well_platforms") {
					getDeviceRealTime({
						...deviceRealTime,
						device_injector: {
							...deviceRealTime.device_injector,
							platform: value,
						},
					});
					setTypeProcessPlatform(value);
				} else if (name === "discretized_well") {
					getDeviceRealTime({
						...deviceRealTime,
						device_injector: {
							...deviceRealTime.device_injector,
							discretized: value,
						},
					});
				}
			}
		}
	};
	const handleSwitch = (enabled) => {
		const customEvent = {
			target: {
				name: "discretized_well",
				value: enabled,
			},
		};
		handleChangeInputData(customEvent);
	};

	const handleOpenModal = () => {
		setShowAlert(true);
	};

	const handleOkModal = () => {
		setShowAlert(false);
	};

	const handleCloseModal = () => {
		setShowAlert(false);
	};

	const handleStatusChange = () => {
		setStatus(!status);
	};

	// Manejo de cambios en Selects
	const handleChangeSelect = (nameData, valueData) => {
		const customEvent = { target: { name: nameData, value: valueData } };
		handleChangeInputData(customEvent);
	};

	const handleChangeCoor = (e) => {
		const { name, value } = e.target;
		let newValue = value;

		// --- DEGREES Y MINUTES (solo números enteros) ---
		if (
			[
				"latitudeDegrees",
				"latitudeMinutes",
				"longitudeDegrees",
				"longitudeMinutes",
			].includes(name)
		) {
			newValue = value.replace(/[^\d]/g, "");

			const numericValue = parseInt(newValue, 10);

			if (isNaN(numericValue)) {
				newValue = "";
			} else {
				// Validar por tipo
				if (name.includes("Degrees")) {
					if (name.includes("latitude") && numericValue > 90) return;
					if (name.includes("longitude") && numericValue > 180) return;
				} else if (name.includes("Minutes") && numericValue > 59) {
					return;
				}
			}

			newValue = newValue.slice(0, 3); // Asegura que no pasen más de 3 dígitos
		}

		// --- SECONDS (con punto decimal permitido, hasta 59.999) ---
		else if (["latitudeSeconds", "longitudeSeconds"].includes(name)) {
			newValue = value.replace(/[^\d.]/g, "");

			// Insertar punto automáticamente si hay 3 dígitos sin punto
			if (newValue.length === 3 && newValue.indexOf(".") === -1) {
				newValue = newValue.slice(0, 2) + "." + newValue.slice(2);
			}

			// Formatear a solo 3 decimales
			const decimalIndex = newValue.indexOf(".");
			if (decimalIndex !== -1) {
				const integerPart = newValue.slice(0, decimalIndex);
				const decimalPart = newValue.slice(decimalIndex + 1, decimalIndex + 4);
				newValue = `${integerPart}.${decimalPart}`;
			}

			const numericValue = parseFloat(newValue);
			if (!isNaN(numericValue) && numericValue > 59.999) return;
		}

		setCoordinate((prev) => ({ ...prev, [name]: newValue }));
	};

	// Manejo de cambios en Selects para coordenadas
	const handleChangeSelectCoor = (nameData, valueData) => {
		handleChangeCoor({ target: { name: nameData, value: valueData } });
	};

	// Evitar caracteres inválidos en los inputs de coordenadas
	const handleKeyDown = (event) => {
		if (event.key === "-" || event.key === "/") {
			event.preventDefault();
		}
	};

	// Generar ID solo en modo creación
	const handleClickGenerateID = () => {
		if (!isEditing) {
			const idGenerated = generateRandomID();
			setInputDataInjector((prevState) => ({
				...prevState,
				well_id: idGenerated,
			}));
		}
	};

	const convertDate = (fechaNumerica) => {
		const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000);
		const mes = fecha.getMonth() + 1;
		const dia = fecha.getDate();
		const anio = fecha.getFullYear();
		return `${mes}/${dia}/${anio}`;
	};

	// Determina si un campo debe estar deshabilitado
	const getDisabledStatus = (condition) => (condition ? false : { status });

	// Muestra una advertencia con un mensaje y resalta el paso actual
	const showWarning = (message, currentStep) => {
		handleOpenModal();
		setTypeAlert("Warning");
		setMessageAlert(message);
		setInputEmpty(true);
		if (currentStep !== undefined) setCurrent(currentStep);
	};

	// Validación para cada paso del formulario
	const validationForCurrent = (current) => {
		let validate = false;
		switch (current) {
			case 0:
				validate =
					!!inputDataInjector.well_id &&
					!!inputDataInjector.well_name &&
					!!inputDataInjector.well_platforms &&
					!!inputDataInjector.well_type &&
					!!inputDataInjector.region &&
					!!inputDataInjector.county &&
					!!inputDataInjector.field &&
					!!coordinate.latitudeDegrees &&
					!!coordinate.latitudeMinutes &&
					!!coordinate.latitudeSeconds &&
					!!coordinate.latitudeDirection &&
					!!coordinate.longitudeDegrees &&
					!!coordinate.longitudeMinutes &&
					!!coordinate.longitudeSeconds &&
					!!coordinate.longitudeDirection;
				break;
			case 1:
				validate = !dataDeviation.some((obj) =>
					Object.values(obj).some((value) => value === "" || value === null),
				);
				break;
			case 2:
				validate = !dataEquipment.some((obj) =>
					Object.values(obj).some((value) => value === "" || value === null),
				);
				break;
			default:
				break;
		}

		setInputEmpty(!validate);
		return validate;
	};

	const handleChangeSelectCurrent2 = (nameData, valueData, id) => {
		handleChangeEquipment({ target: { name: nameData, value: valueData } }, id);
	};

	const handleUploadFile = (dataFile) => {
		const dataTableProcessed = removeEmptyRows(dataDeviation);
		const uniqueIds = [...new Set(dataDeviation.map((obj) => obj.id))];
		const maxId = Math.max(...uniqueIds) + 1;

		const dataFileEnd = dataFile.map((obj, index) => ({
			id: index + maxId,
			...obj,
		}));

		const previousIndex = dataDeviation.length - 1;
		const lastValue =
			dataDeviation.length > 0
				? dataDeviation[previousIndex].measured_depth
				: null;

		// Función para calcular el máximo índice previo de `measured_depth`
		const calculateMaxIndex = (array, currentIndex) => {
			let max = -Infinity;
			for (let i = 0; i < currentIndex; i++) {
				if (array[i].measured_depth > max) {
					max = array[i].measured_depth;
				}
			}
			return max;
		};

		// Procesamiento y validación de los datos importados
		const dataFileUp = dataFileEnd.map((item, index) => {
			const previousItem = dataFileEnd[index - 1];
			const previousMeasuredDepth = previousItem
				? previousItem.measured_depth
				: null;
			const lastMax = calculateMaxIndex(dataFileEnd, index);

			const newMeasuredDepth =
				item.measured_depth < 0 ||
				item.measured_depth > 50000 ||
				(lastValue !== null &&
					item.measured_depth <= lastValue &&
					index + previousIndex === previousIndex &&
					index !== 0) ||
				(previousMeasuredDepth !== null &&
					previousMeasuredDepth >= item.measured_depth) ||
				(previousMeasuredDepth !== null ? previousMeasuredDepth : lastMax) >=
					item.measured_depth ||
				item.measured_depth === lastMax
					? null
					: item.measured_depth;

			return {
				...item,
				measured_depth: newMeasuredDepth,
				tvd:
					0 <= item.tvd && item.tvd <= 50000 && item.tvd <= item.measured_depth
						? item.tvd
						: null,
				inc: -25 <= item.inc && item.inc <= 125 ? item.inc : null,
			};
		});

		// Concatenación de datos con los ya existentes
		const dataConcat = dataTableProcessed.length
			? [...dataTableProcessed, ...dataFileUp]
			: dataFileUp;
		const validatedData = validateMeasuredDepthSequence(dataConcat);

		setDataDeviation(validatedData);
	};

	// Manejo de carga de archivos para la tabla de equipos
	const handleUploadFile2 = (dataFile) => {
		const dataTableProcessed = removeEmptyRows(dataEquipment);
		const uniqueIds = [...new Set(dataEquipment.map((obj) => obj.id))];
		const maxId = Math.max(...uniqueIds) + 1;

		const dataFileEnd = dataFile.map((obj, index) => ({
			id: index + maxId,
			...obj,
		}));

		// Validación de datos importados
		const dataFileUp = dataFileEnd.map((item) => ({
			...item,
			type:
				!item.type ||
				(item.type.toLowerCase() !== "tubing" &&
					item.type.toLowerCase() !== "casing")
					? null
					: item.type.toLowerCase(),
			measured_depth:
				item.measured_depth < 0 || item.measured_depth > 50000
					? null
					: item.measured_depth,
			external_diameter:
				item.external_diameter < 0.75 ||
				item.external_diameter > 24 ||
				item.external_diameter <= item.internal_diameter
					? null
					: item.external_diameter,
			internal_diameter:
				item.internal_diameter < 0.75 ||
				item.internal_diameter > 24 ||
				item.internal_diameter >= item.external_diameter
					? null
					: item.internal_diameter,
			absolute_roughness:
				item.absolute_roughness > 0.1 || item.absolute_roughness < 0
					? null
					: item.absolute_roughness,
		}));

		// Concatenar datos con los existentes
		const dataConcat = dataTableProcessed.length
			? [...dataTableProcessed, ...dataFileUp]
			: dataFileUp;
		setDataEquipment(dataConcat);
	};

	// Manejo de cambios en la tabla de desviaciones con validaciones
	const handleChangeDeviation = (e, id) => {
		const { name, value } = e.target;
		let dataValue = value !== "" ? parseFloat(value) : "";

		setValidationTable((prevState) => ({ ...prevState, tableDeviation: true }));

		if (name === "measured_depth" && (dataValue < 0.0 || dataValue > 60000.0)) {
			message.error(
				"Measured Depth value can't be less than 0 or greater than 60000",
			);
			setValidationTable({ ...validationTable, tableDeviation: true });
		} else if (name === "tvd" && (dataValue < 0.0 || dataValue > 60000.0)) {
			message.error("TVD value can't be less than 0 or greater than 60000");
			setValidationTable({ ...validationTable, tableDeviation: true });
		} else if (name === "inc" && (dataValue < -25.0 || dataValue > 125.0)) {
			message.error("INC value can't be less than -25 or greater than 125");
			setValidationTable({ ...validationTable, tableDeviation: true });
		} else {
			setDataDeviation((prevState) =>
				prevState.map((item) =>
					item.id === id ? { ...item, [name]: dataValue } : item,
				),
			);
		}
	};

	// Manejo de validación al perder el foco en la tabla de desviaciones
	const handleBlurDeviation = (e, id) => {
		const { name, value } = e.target;
		let dataValue = value !== "" ? Number(value) : "";

		const selectedIndex = dataDeviation.findIndex((data) => data.id === id);
		const lastIndex = selectedIndex !== 0 ? selectedIndex - 1 : selectedIndex;

		if (
			name === "tvd" &&
			dataValue > dataDeviation[selectedIndex].measured_depth
		) {
			message.error("The value can't be greater than measured depth value");
			setValidationTable({ ...validationTable, tableDeviation: true });
		}

		if (
			name === "measured_depth" &&
			dataValue <= dataDeviation[lastIndex].measured_depth &&
			selectedIndex !== lastIndex
		) {
			message.error("Measured depth value can't be less than previous row value");
			setValidationTable({ ...validationTable, tableDeviation: true });
		}
	};

	// Manejo de cambios en la tabla de equipos con validaciones
	const handleChangeEquipment = (e, id) => {
		const { name, value } = e.target;
		let dataValue =
			value !== "" && !isNaN(parseFloat(value)) ? parseFloat(value) : "";

		setValidationTable({ ...validationTable, tableEquipment: false });

		if (name === "measured_depth" && (dataValue < 0 || dataValue > 50000)) {
			message.error(
				"Length (ft) value can't be less than 0 or greater than 50000",
			);
			setValidationTable({ ...validationTable, tableEquipment: true });
		} else if (
			name === "internal_diameter" &&
			(dataValue < 0 || dataValue > 36)
		) {
			message.error(
				"Diameters value can't be less than or equal to 0 and greater than 36",
			);
			setValidationTable({ ...validationTable, tableEquipment: true });
		} else if (
			name === "external_diameter" &&
			(dataValue < 0 || dataValue > 36)
		) {
			message.error(
				"Diameters value can't be less than or equal to 0 and greater than 36",
			);
			setValidationTable({ ...validationTable, tableEquipment: true });
		} else if (
			name === "absolute_roughness" &&
			(dataValue >= 0.1 || dataValue < 0)
		) {
			message.error(
				"Absolute roughness value can't be greater than 0.1 and less than 0",
			);
			setValidationTable({ ...validationTable, tableEquipment: true });
		} else {
			setDataEquipment((prevState) =>
				prevState.map((item) =>
					item.id === id
						? {
								...item,
								[name]: name === "name" || name === "type" ? value : dataValue,
							}
						: item,
				),
			);
		}
	};

	// Manejo de validación al perder el foco en la tabla de equipos
	const handleBlurEquipment = (e, id) => {
		const { name, value } = e.target;
		let dataValue =
			value !== "" && !isNaN(parseFloat(value)) ? parseFloat(value) : "";

		const selectedIndex = dataEquipment.findIndex((data) => data.id === id);
		const externalDiameter = !isNaN(
			parseFloat(dataEquipment[selectedIndex].external_diameter),
		)
			? parseFloat(dataEquipment[selectedIndex].external_diameter)
			: "";
		const internalDiameter = !isNaN(
			parseFloat(dataEquipment[selectedIndex].internal_diameter),
		)
			? parseFloat(dataEquipment[selectedIndex].internal_diameter)
			: "";

		if (
			(name === "internal_diameter" || name === "external_diameter") &&
			dataValue <= 0
		) {
			message.error(
				"Diameters value can't be less than or equal to 0 and greater than 36",
			);
			setDataEquipment((prevState) =>
				prevState.map((item) => (item.id === id ? { ...item, [name]: "" } : item)),
			);
		} else if (name === "measured_depth") {
			if (sumMeasureDepth()) {
				message.error(
					"The sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation",
				);
				setDataEquipment((prevState) =>
					prevState.map((item) => (item.id === id ? { ...item, [name]: "" } : item)),
				);
			}
		} else if (
			name === "internal_diameter" &&
			dataValue >= externalDiameter &&
			dataValue !== ""
		) {
			message.error(
				"Internal diameter value can't be greater than or equal to external diameter value",
			);
			setDataEquipment((prevState) =>
				prevState.map((item) => (item.id === id ? { ...item, [name]: "" } : item)),
			);
		} else if (
			name === "external_diameter" &&
			dataValue <= internalDiameter &&
			dataValue !== ""
		) {
			message.error(
				"Internal diameter value can't be greater than or equal to external diameter value",
			);
			setDataEquipment((prevState) =>
				prevState.map((item) => (item.id === id ? { ...item, [name]: "" } : item)),
			);
		}
	};

	// Calcula si la suma de measured_depth en equipos supera el límite en desviaciones
	const sumMeasureDepth = () => {
		if (dataDeviation.length === 0) return false;

		const lastDeviation = dataDeviation[dataDeviation.length - 1].measured_depth;
		const totalDepth = dataEquipment.reduce((total, item) => {
			return total + parseFloat(item.measured_depth || 0);
		}, 0);

		return totalDepth > lastDeviation;
	};

	// Agregar una nueva fila en la tabla de desviaciones
	const handleAddDeviation = () => {
		const uniqueIds = [...new Set(dataDeviation.map((obj) => obj.id))];
		const maxId = uniqueIds.length ? Math.max(...uniqueIds) : 0;

		const newDeviation = {
			id: maxId + 1,
			measured_depth: null,
			tvd: null,
			inc: null,
		};

		setDataDeviation([...dataDeviation, newDeviation]);
		setValidationTable({ ...validationTable, tableDeviation: false });
	};

	// Eliminar una fila en la tabla de desviaciones
	const handleDeleteDeviation = (id) => {
		const newData = dataDeviation.filter((item) => item.id !== id);
		setDataDeviation(newData);
	};

	// Agregar una nueva fila en la tabla de equipos
	const handleAddEquipment = () => {
		const uniqueIds = [...new Set(dataEquipment.map((obj) => obj.id))];
		const maxId = uniqueIds.length ? Math.max(...uniqueIds) : 0;

		const newEquipment = {
			id: maxId + 1,
			name: "",
			type: "",
			measured_depth: null,
			internal_diameter: null,
			external_diameter: null,
			absolute_roughness: null,
		};

		setDataEquipment([...dataEquipment, newEquipment]);
	};

	// Eliminar una fila en la tabla de equipos
	const handleDeleteEquipment = (id) => {
		const newData = dataEquipment.filter((item) => item.id !== id);
		setDataEquipment(newData);
	};

	return (
		<>
			<div className="flex flex-col w-full h-full min-h-screen bg-white">
				<div className="flex">
					<Navbar wellboreOption={wellboreOption} />
					<Sidebar className="absolute flex h-full" />
				</div>

				<div className="flex w-full h-full">
					{isEditing ? (
						<AlertModal
							showAlert={showAlert}
							handleCloseModal={handleCloseModal}
							messageAlert={messageAlert}
							typeModal={typeAlert}
						/>
					) : (
						<AlertModalOption
							showAlert={showAlert}
							handleOkModal={handleOkModal}
							handleCloseModal={handleCloseModal}
							messageAlert={messageAlert}
							typeModal={typeAlert}
						/>
					)}
				</div>

				{mutateLoadFile ? (
					<Loading />
				) : (
					<>
						<div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
							<Steps
								size={CUSTOM_SCENARIO_TYPES.DEFAULT}
								current={current}
								onChange={onChangeStep}
								className="site-navigation-steps flex w-[750px] custom-steps"
								items={[
									{
										title: "Well definition",
										disabled: getDisabledStatus(current !== 0),
									},
									{
										title: "Well deviation",
										disabled: getDisabledStatus(current > 1),
									},
									{
										title: "Well equipment",
										disabled: getDisabledStatus(current > 2),
									},
								]}
							/>
						</div>

						{/* Contenedor principal ajustado */}
						<div className="flex flex-col w-full h-auto pl-[88px] text-center justify-start">
							{current === 0 && (
								<WellDefinition
									inputDataInjector={inputDataInjector}
									handleChangeInputData={handleChangeInputData}
									handleSwitch={handleSwitch}
									handleChangeSelect={handleChangeSelect}
									coordinate={coordinate}
									handleChangeCoor={handleChangeCoor}
									handleChangeSelectCoor={handleChangeSelectCoor}
									isEditing={isEditing}
									dataListPlatforms={dataListPlatforms}
									dataListPlatformsWells={dataListPlatformsWells}
									dataListWellInjector={dataListWellInjector}
									handleClickGenerateID={handleClickGenerateID}
									inputEmpty={inputEmpty}
								/>
							)}

							{current === 1 && (
								<WellDeviation
									dataDeviation={dataDeviation}
									handleChangeDeviation={handleChangeDeviation}
									handleBlurDeviation={handleBlurDeviation}
									handleAddDeviation={handleAddDeviation}
									handleDeleteDeviation={handleDeleteDeviation}
									handleUploadFile={handleUploadFile}
									validationTable={validationTable}
								/>
							)}

							{current === 2 && (
								<WellEquipment
									dataEquipment={dataEquipment}
									handleChangeEquipment={handleChangeEquipment}
									handleBlurEquipment={handleBlurEquipment}
									handleAddEquipment={handleAddEquipment}
									handleDeleteEquipment={handleDeleteEquipment}
									handleUploadFile={handleUploadFile2}
									validationTable={validationTable}
								/>
							)}
						</div>

						{/* Botones de navegación */}
						<div className="flex items-center justify-center w-full mt-[20px] text-center">
							<div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
								{current > 0 && (
									<button
										className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
										onClick={handleClickPrev}
									>
										Previous
									</button>
								)}
								{current < steps.length - 1 && (
									<button
										className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
										onClick={handleClickNext}
									>
										Continue
									</button>
								)}
								{current === steps.length - 1 && (
									<button
										className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
										onClick={handleClickDone}
									>
										Done
									</button>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default StepMenuWellInjector;
