import React from "react";
import { DataGasLiftContext } from "./DataGasLiftContext";
import { useDataGasLift } from "../../hooks/useDataGasLift";

export const DataGasLiftProvider = ({ children }) => {
	const {
		dataCreated,
		dataFilterUserGasLift,
		enableCheckLab,
		lastSelectDataIPR,
		viewLoadingGasLift,
		validateAlertGasLift,
		stateMessageApis,
		stateCheckMultiWell,
		dataChartReservoir,
		dataChartVLP,
		getDataCreated,
		getDataFilterUserGaslift,
		getEnableCheckLab,
		handleSelectWellGaslift,
		getSelectDataIPR,
		getViewLoadingGasLift,
		getValidateAlertGasLift,
		getStateMessageAlert,
		getStateCheckMultiWell,
		getDataChartReservoir,
		getDataChartVLP
	} = useDataGasLift()

	return (
		<DataGasLiftContext.Provider value={{
			dataCreated,
			dataFilterUserGasLift,
			enableCheckLab,
			lastSelectDataIPR,
			viewLoadingGasLift,
			validateAlertGasLift,
			stateMessageApis,
			stateCheckMultiWell,
			dataChartReservoir,
			dataChartVLP,
			getDataCreated,
			getDataFilterUserGaslift,
			getEnableCheckLab,
			handleSelectWellGaslift,
			getSelectDataIPR,
			getViewLoadingGasLift,
			getValidateAlertGasLift,
			getStateMessageAlert,
			getStateCheckMultiWell,
			getDataChartReservoir,
			getDataChartVLP
		}}>
			{children}
		</DataGasLiftContext.Provider>
	)
}
