import { serviceEndPoints } from "../../config/config";

const BASE_URL_IPR = serviceEndPoints.IPR.BASE_URL_IPR+"/IPR";

export const uploadJsonIPR = async (dataJson) => {
  const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const uploadCsvIPR = async (operator,lease,region,country,field,well,dataFile) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/upload_csv/${operator}/${lease}/${region}/${country}/${field}/${well}`, {
      method: "POST",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: dataFile
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateQIPR = async (operator,lease,region,country,field,well,username) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/calculate_q/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    }); 
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculatePwfIPR = async (operator,lease,region,country,field,well,dataFile) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/calculate_pwf/${operator}/${lease}/${region}/${country}/${field}/${well}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const uploadaDataGraphic = async (dataJson) => {
  try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const well = dataJson.well;
      const username = dataJson.username;
    const response = await fetch(`${BASE_URL_IPR}/upload_json_graphic/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const dataGraphic = async (operator,lease,region,country,field,well,username, nameIPR) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/ipr_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIPR}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const listDataGraphic = async (operator,lease,region,country,field,well, username) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/list_ipr/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const deleteDataGraphic = async (dataJson) => {
  
  try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const country = dataJson.county;
      const field = dataJson.field;
      const well = dataJson.well;
      const username = dataJson.username; 
      const nameIPR = dataJson.nameIPR;
      const response = await fetch(`${BASE_URL_IPR}/deleted_graphic_ipr/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIPR}`, {
          method: "DELETE",
          headers: { 
          "Content-Type": "application/json",
          "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
      });
  return response.json();
  } catch (error) {
  throw new Error("Problem getting data");
  }
};

// API`s IPR Dry Gas

export const dataReservoirDryGas = async (operator, lease, region, county, field, well, username) => {
  try {
    const response = await fetch(`${BASE_URL_IPR}/calc_ipr_gas/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "GET",
      headers: { 
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const uploadaDataReservoirDryGas = async (dataJson) => {
  try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const county = dataJson.county;
      const field = dataJson.field;
      const well = dataJson.well;
      const username = dataJson.username;
      const response = await fetch(`${BASE_URL_IPR}/upload_ipr_gas/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" },
      body: JSON.stringify(dataJson.data)
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const deleteScenariosReservoir = async (dataJson) => {
  
  try {
      const operator = dataJson.operator;
      const lease = dataJson.lease;
      const region = dataJson.region;
      const country = dataJson.county;
      const field = dataJson.field;
      const well = dataJson.well;
      const username = dataJson.username;
      const response = await fetch(`${BASE_URL_IPR}/deleted_scenarios_ipr/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`, {
          method: "DELETE",
          headers: { 
          "Content-Type": "application/json",
          "Authorization": "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm" }
      });
  return response.json();
  } catch (error) {
  throw new Error("Problem getting data");
  }
};

