import { serviceEndPoints } from "../../config/config";

const DB_URL_PLOTTING = serviceEndPoints.PLOTTING.URL_PLOTTING;
const DB_URL_PARAMETERS_PLOTTING = serviceEndPoints.PLOTTING.URL_PARAMETERS_PLOTTING;

export const getJsonPlottingIOT = async (operator, lease, region, platform, device ) => {
    if (!operator || !lease || !region || !platform || !device) {
        console.warn("Missing parameters, make sure all parameters are present.");
        return;
      }
    try{
        const response = await fetch(`${DB_URL_PLOTTING}/iotwelldataplotting/${operator}/${lease}/${region}/${platform}/${device}/2000`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs",
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListPlatformsPlotting = async (operator, lease, region) => {
    try{
        const response = await fetch(`${DB_URL_PLOTTING}/platformslistplotting/${operator}/${lease}/${region}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getListDevicesPlotting = async (operator, lease, region, platform) => {
    try{
        const response = await fetch(`${DB_URL_PLOTTING}/platformsensorsplotting/${operator}/${lease}/${region}/${platform}`, {
            method: "GET",
            headers: {
                "Authorization": "Token SMFGAHDJVqUr2xWifzjpLWC66qdNCPjFGonBROOKs"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const getParametersPlotting = async (operator, lease, region, user) => {
    try {
        let user_modify = user?.replace(/\./g, "_");
        const response = await fetch(`${DB_URL_PARAMETERS_PLOTTING}/plotting/get/${operator}/${lease}/${region}/${user_modify}/plotting`, {
            method: "GET",
            headers: {
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            }
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting the information")
    }
};

export const createParametersPlotting = async (parameters) => {
    if (!parameters.operator || !parameters.lease || !parameters.region || !parameters.user || !parameters.data) {
        console.warn("Parameters are missing, make sure that all parameters are present.");
        return;
    }
    try {
        const operator = parameters.operator
        const lease = parameters.lease
        const region = parameters.region
        const user = parameters.user
        let user_modify = user?.replace(/\./g, "_");
        const response = await fetch(`${DB_URL_PARAMETERS_PLOTTING}/plotting_input/upload_plotting/${operator}/${lease}/${region}/${user_modify}/plotting`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
            body: JSON.stringify(parameters.data)
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting users");
    }
};

export const updateParametersPlotting = async (parameters) => {
    if (!parameters.operator || !parameters.lease || !parameters.region || !parameters.user || !parameters.data) {
        console.warn("Parameters are missing, make sure that all parameters are present.");
        return;
    }
    try {
        const operator = parameters.operator
        const lease = parameters.lease
        const region = parameters.region
        const user = parameters.user
        let user_modify = user?.replace(/\./g, "_");
        const response = await fetch(`${DB_URL_PARAMETERS_PLOTTING}/plotting_update/update_plotting/${operator}/${lease}/${region}/${user_modify}/plotting`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
            body: JSON.stringify(parameters.data)
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting users");
    }
};

export const deleteParametersPlotting = async (parameters) => {
    if (!parameters.clients || !parameters.projects || !parameters.platforms || !parameters.user || !parameters.name_scenario) {
        console.warn("Parameters are missing, make sure that all parameters are present.");
        return;
    }
    try {
        const client = parameters.clients
        const project = parameters.projects
        const stream = parameters.platforms
        const name_user = parameters.user
        let user_modify = name_user?.replace(/\./g, "_");
        const name_scenario = parameters.name_scenario
        const response = await fetch(`${DB_URL_PARAMETERS_PLOTTING}/parameters/${client}/${project}/${stream}/${user_modify}/${name_scenario}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token 9TyU77LmBfId79DKaIVeuJ8cpKA4JGprZI3yH85Hii8c8DkRFSh4YZ5P4mDBUUga"
            },
            body: JSON.stringify(parameters.data)
        });
        return response.json();
    } catch (error) {
        throw new Error("Problem getting users");
    }
};
