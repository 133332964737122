import { InfoCircleOutlined } from "@ant-design/icons";
import { Select, Switch, Tooltip } from "antd";
import React from "react";
import classes from "./WellDefinition.module.css";

const {
	wellDefinitionGasLift,
	wellDefinitionGasLift__title,
	wellDefinitionGasLift__form,
	wellDefinitionGasLift__fields,
	wellDefinitionGasLift__label,
	wellDefinitionGasLift__inputGroup,
	wellDefinitionGasLift__input,
	"wellDefinitionGasLift__input--coordinates":
		wellDefinitionGasLift__inputCoordinates,
	"wellDefinitionGasLift__input--two-thirds":
		wellDefinitionGasLift__inputTwoThirds,
	"wellDefinitionGasLift__input--disabled": wellDefinitionGasLift__inputDisabled,
	"wellDefinitionGasLift__input--error": wellDefinitionGasLift__input_error,
	"wellDefinitionGasLift__input--normal": wellDefinitionGasLift__input_normal,
	wellDefinitionGasLift__button,
	"wellDefinitionGasLift__button--disable": wellDefinitionGasLift__buttonDisable,
	wellDefinitionGasLift__coordsGrid,
	wellDefinitionGasLift__select,
	"wellDefinitionGasLift__select--disabled":
		wellDefinitionGasLift__selectDisabled,
} = classes;
const { Option } = Select;

const WellDefinition = ({
	inputDataInjector,
	handleChangeInputData,
	handleChangeSelect,
	coordinate,
	handleChangeCoor,
	handleChangeSelectCoor,
	isEditing,
	dataListPlatforms,
	dataListPlatformsWells,
	dataListWellInjector,
	handleClickGenerateID,
	handleSwitch,
	inputEmpty,
}) => {
	return (
		<div className={wellDefinitionGasLift}>
			{/* Formulario */}
			<div className={wellDefinitionGasLift__form}>
				<div className={wellDefinitionGasLift__fields}>
					<h1 className={wellDefinitionGasLift__title}>Well Definition</h1>
					{/* Well API Number/Well ID */}
					<label className={wellDefinitionGasLift__label}>
						Well API Number/Well ID
					</label>

					<div className={wellDefinitionGasLift__inputGroup}>
						<input
							type="text"
							name="well_id"
							value={inputDataInjector.well_id}
							onChange={handleChangeInputData}
							className={
								isEditing
									? `${wellDefinitionGasLift__inputDisabled} ${wellDefinitionGasLift__input} ${wellDefinitionGasLift__inputTwoThirds}`
									: `${wellDefinitionGasLift__input} ${wellDefinitionGasLift__inputTwoThirds}
                                ${
																																	inputEmpty && !inputDataInjector.well_id
																																		? wellDefinitionGasLift__input_error
																																		: wellDefinitionGasLift__input_normal
																																}`
							}
							disabled={
								isEditing ||
								dataListWellInjector?.some(
									(obj) => obj?.well_id === inputDataInjector.well_id,
								)
							}
						/>
						<button
							onClick={handleClickGenerateID}
							className={
								isEditing
									? wellDefinitionGasLift__buttonDisable
									: wellDefinitionGasLift__button
							}
							disabled={isEditing}
						>
							Generate ID
						</button>
					</div>

					{/* Platform/Tank Battery */}
					<label className={wellDefinitionGasLift__label}>
						Platform/Tank Battery
						<Tooltip title="This option will become available once real-time data streaming begins">
							<InfoCircleOutlined
								className="ml-2"
								style={{ color: "rgba(0, 0, 0, 0.45)" }}
							/>
						</Tooltip>
					</label>
					<Select
						name="well_platforms"
						value={inputDataInjector.well_platforms || "Select option"}
						style={{
							border:
								inputEmpty && !inputDataInjector.well_platforms
									? "1px solid #b91c1c"
									: "",
							borderRadius: "5px",
						}}
						className={
							isEditing
								? `${wellDefinitionGasLift__selectDisabled} ${wellDefinitionGasLift__select}`
								: `${wellDefinitionGasLift__select}`
						}
						onChange={(value) => handleChangeSelect("well_platforms", value)}
						disabled={isEditing}
					>
						<Option value="">Selected option</Option>
						<Option value="LATER">Select Later</Option>
						{Array.isArray(dataListPlatforms?.data) ? (
							dataListPlatforms.data.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))
						) : (
							<Option disabled>No data available</Option>
						)}
					</Select>
					{/* Well IOT */}
					<label
						htmlFor=""
						className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
					>
						IoT well
						<Tooltip title="Enables IoT well discretization.">
							<InfoCircleOutlined
								className="ml-2"
								style={{ color: "rgba(0, 0, 0, 0.45)" }}
							/>
						</Tooltip>
					</label>
					<div className="flex w-full max-w-[592px] h-[47px] items-center justify-start mb-[17px]">
						<Tooltip
							title={
								inputDataInjector.discretized_well
									? "Disable discretization"
									: "Enable discretization"
							}
							color={"#662D91"}
						>
							<Switch
								style={{
									backgroundColor: inputDataInjector.discretized_well
										? "#662D91"
										: "#707070",
									borderColor: inputDataInjector.discretized_well
										? "#662D91"
										: "#707070",
								}}
								checked={inputDataInjector.discretized_well}
								onChange={handleSwitch}
							></Switch>
						</Tooltip>
					</div>
					{/* Well Name */}
					<label className={wellDefinitionGasLift__label}>Well Name</label>
					{inputDataInjector.discretized_well ? (
						<Select
							name="well_name"
							value={inputDataInjector.well_name || ""}
							style={{
								border:
									inputEmpty && !inputDataInjector.well_name ? "1px solid #b91c1c" : "",
								borderRadius: "5px",
							}}
							className={
								isEditing
									? ` ${wellDefinitionGasLift__selectDisabled} ${wellDefinitionGasLift__select}`
									: `${wellDefinitionGasLift__select}`
							}
							onChange={(value) => handleChangeSelect("well_name", value)}
							disabled={isEditing}
						>
							<Option value="">Selected option</Option>
							{dataListPlatformsWells?.data?.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					) : (
						<input
							type="text"
							name="well_name"
							value={inputDataInjector.well_name}
							onChange={handleChangeInputData}
							className={
								isEditing
									? `${wellDefinitionGasLift__inputDisabled} ${wellDefinitionGasLift__input} `
									: `${wellDefinitionGasLift__input}
                                ${
																																	inputEmpty && !inputDataInjector.well_name
																																		? wellDefinitionGasLift__input_error
																																		: wellDefinitionGasLift__input_normal
																																}`
							}
							disabled={isEditing}
						/>
					)}

					{/* Well Type */}
					<label className={wellDefinitionGasLift__label}>Well Type</label>
					<Select
						style={{
							border:
								inputEmpty && !inputDataInjector.well_type ? "1px solid #b91c1c" : "",
							borderRadius: "5px",
						}}
						name="well_type"
						value={inputDataInjector.well_type || "Select option"}
						className={`${wellDefinitionGasLift__select}`}
						onChange={(value) => handleChangeSelect("well_type", value)}
					>
						<Option value="injector">Injector</Option>
					</Select>

					{/* Region */}
					<label className={wellDefinitionGasLift__label}>Region</label>
					<input
						type="text"
						name="region"
						value={inputDataInjector.region}
						onChange={handleChangeInputData}
						className={`${wellDefinitionGasLift__input} ${wellDefinitionGasLift__inputDisabled}`}
						disabled
					/>

					{/* County */}
					<label className={wellDefinitionGasLift__label}>
						County (if not available please add NA)
					</label>
					<input
						type="text"
						name="county"
						value={inputDataInjector.county}
						onChange={handleChangeInputData}
						className={
							isEditing
								? ` ${wellDefinitionGasLift__inputDisabled} ${wellDefinitionGasLift__input} `
								: `${wellDefinitionGasLift__input}
                            ${
																													inputEmpty && !inputDataInjector.county
																														? wellDefinitionGasLift__input_error
																														: wellDefinitionGasLift__input_normal
																												}`
						}
						disabled={isEditing}
					/>

					{/* Field */}
					<label className={wellDefinitionGasLift__label}>Field</label>
					<input
						type="text"
						name="field"
						value={inputDataInjector.field}
						onChange={handleChangeInputData}
						className={
							isEditing
								? `${wellDefinitionGasLift__inputDisabled} ${wellDefinitionGasLift__input} `
								: `${wellDefinitionGasLift__input}
                            ${
																													inputEmpty && !inputDataInjector.field
																														? wellDefinitionGasLift__input_error
																														: wellDefinitionGasLift__input_normal
																												}`
						}
						disabled={isEditing}
					/>

					{/* Latitude y Longitude */}
					{["Latitude", "Longitude"].map((coordType) => (
						<React.Fragment key={coordType}>
							<label className={wellDefinitionGasLift__label}>{coordType}</label>
							<div className={wellDefinitionGasLift__coordsGrid}>
								{["Degrees", "Minutes", "Seconds"].map((unit, index) => (
									<div key={index} className="relative">
										<input
											type="text"
											name={`${coordType.toLowerCase()}${unit}`}
											placeholder={
												coordType === "Latitude"
													? unit === "Degrees"
														? "29°"
														: unit === "Minutes"
															? "13'"
															: '47.9"'
													: unit === "Degrees"
														? "29°"
														: unit === "Minutes"
															? "13'"
															: '47.9"'
											}
											value={coordinate[`${coordType.toLowerCase()}${unit}`]}
											onChange={handleChangeCoor}
											maxLength={
												unit === "Degrees"
													? coordType === "Latitude"
														? 2
														: 3
													: unit === "Minutes"
														? 2
														: 5
											}
											className={`${wellDefinitionGasLift__input} ${wellDefinitionGasLift__inputCoordinates}
                            ${
																													inputEmpty &&
																													!coordinate[`${coordType.toLowerCase()}${unit}`]
																														? wellDefinitionGasLift__input_error
																														: wellDefinitionGasLift__input_normal
																												}`}
										/>
										<div
											className="absolute top-0 right-0 flex items-center pt-1 pr-3"
											style={{ fontSize: "1.5rem" }}
										>
											{unit === "Degrees" ? "°" : unit === "Minutes" ? "'" : '"'}
										</div>
									</div>
								))}
								<Select
									name={`${coordType.toLowerCase()}Direction`}
									value={
										coordinate[`${coordType.toLowerCase()}Direction`] || "Direction"
									}
									onChange={(value) =>
										handleChangeSelectCoor(`${coordType.toLowerCase()}Direction`, value)
									}
									style={{
										border:
											inputEmpty && !coordinate[`${coordType.toLowerCase()}Direction`]
												? "1px solid #b91c1c"
												: "",
										borderRadius: "5px",
									}}
									className={`${wellDefinitionGasLift__select} `}
								>
									{coordType === "Latitude" ? (
										<>
											<Option value="N">N</Option>
											<Option value="S">S</Option>
										</>
									) : (
										<>
											<Option value="W">W</Option>
											<Option value="E">E</Option>
										</>
									)}
								</Select>
							</div>
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

export default WellDefinition;
