import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Checkbox, message, Modal, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorLoadingData } from "../../components/ErrorPage";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import {
  DATA_PLUNGER_LIFT,
  EMPTY_DATA_PVT,
  EMPTY_DATA_RESERVOIR,
  REQUIRED_PROPIERTIES_PL,
} from "../../components/elements/types/type_plunger_Lift";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { STATUS_CODE_PLUNGER_LIFT } from "../../components/elements/types/type_response_API";
import { useFilters } from "../../hooks/useFilters";

export const AddPlungerLift = () => {
  const { confirm } = Modal;
  const { dataFilter, mutationUpdateFilter } = useFilters();
  const Navigate = useNavigate();
  const { selectedWellPL, 
    getSelectedWellPL, 
    getDataPage, 
    setOpenFirstModal, 
  } = useContext(DashboardContext);
  const {
    User,
    userDataInfo,
    dataFilterUserPL,
    getDataFilterPL,
    mutationDeleteWell,
    mutationUpdateWellPlungerLift,
    refetchDataWellPL,
    refetchListWellPL,
    listWellPL,
    dataWellPL,
  } = useTabPlungerLift();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [data, setData] = useState([]);
  const [dataInformationEndPVT, setDataInformationEndPVT] = useState();
  const [dataInformationEndReservoir, setDataInformationEndReservoir] = useState();
  const [dataInformationEndPlungerLift, setDataInformationEndPlungerLift] = useState();
  const [listWells, setListWells] = useState(null);

  useEffect(() => {
    getDataPage(NAME_PAGES.plunger_lift.list_well);
    refetchListWellPL();
    if (!!selectedWellPL) {
      const dataWellFind = data.find((item) => item.well_id === selectedWellPL);
      const dataFilterNew = {
        county: dataWellFind?.county,
        field: dataWellFind?.field,
        well_id: dataWellFind?.well_id,
        well_platforms: dataWellFind?.well_platforms,
        well_name: dataWellFind?.well_name,
      };
      if (data.length > 0) {
        getDataFilterPL(dataFilterNew);
      }
    }
  }, []);

  useEffect(() => {
    if (listWellPL) {
      setListWells(listWellPL?.data);
    }
  }, [listWellPL]);

  useEffect(() => {
    setTimeout(() => {
      if (
        dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS &&
        dataWellPL?.data
      ) {
        const filterDataCreate = dataWellPL?.data;
        const filterDataEndPVT = Object.assign({}, EMPTY_DATA_PVT);
        const filterDataEndReservoir = Object.assign({}, EMPTY_DATA_RESERVOIR);
        const filterDataEndPlungerLift = Object.assign(
          {},
          DATA_PLUNGER_LIFT.initialInputPlungerLift
        );
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(
            ([key]) => !(key in filterDataEndPVT)
          )
        );
        const filteredJson2 = Object.fromEntries(
          Object.entries(filterDataCreate).filter(
            ([key]) => !(key in filterDataEndReservoir)
          )
        );
        const filteredJson3 = Object.fromEntries(
          Object.entries(filterDataCreate).filter(
            ([key]) => !(key in filterDataEndPlungerLift)
          )
        );
        setDataInformationEndPVT(filteredJson);
        setDataInformationEndReservoir(filteredJson2);
        setDataInformationEndPlungerLift(filteredJson3);
      }
    }, 1000);
  }, [dataWellPL]);

  useEffect(() => {
    if (!!selectedWellPL && !!listWellPL) {
      setTimeout(() => {
        refetchDataWellPL();
      }, 1000);
    }
  }, [selectedWellPL, listWellPL]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchListWellPL();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [refetchListWellPL]);

  useEffect(() => {
    setTimeout(() => {
      if (
        listWellPL?.statusType != STATUS_CODE_PLUNGER_LIFT.ERROR &&
        listWellPL?.data
      ) {
        setData(listWellPL?.data);
      }
    }, 500);
  }, [listWellPL]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleClickButtonCreate = (typeConfig) => {
    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWellPL
    );
    const wellConfig = data[selectedWellIndex]?.wellConfig;
    const contentMessage =
      "has already been assigned to this well. Please edit the existing one.";

    const buttonConfig = {
      wellConfig: {
        route: `./${NAME_PAGES.plunger_lift.create_well}`,
        condition:
          userDataInfo?.operator && userDataInfo?.lease && userDataInfo?.region,
        message:
          "Please complete all required user information to create a well.",
      },
      pvtData: {
        route: `./${NAME_PAGES.plunger_lift.create_pvt}`,
        condition: wellConfig?.pvtData,
        message: `A PVT ${contentMessage}`,
      },
      reservoir: {
        route: `./${NAME_PAGES.plunger_lift.create_reservoir}`,
        condition: wellConfig?.reservoir,
        message: `A reservoir ${contentMessage}`,
      },
      plunger_lift: {
        route: `./${NAME_PAGES.plunger_lift.create_plunger_lift}`,
        condition: wellConfig?.plungerLift,
        message: `A plunger lift ${contentMessage}`,
      },
    };

    const config = buttonConfig[typeConfig];

    if (typeConfig === "wellConfig") {
      if (config.condition) {
        Navigate(config.route);
      } else {
        message.warning(config.message);
      }
      return;
    }

    if (!selectedWellPL) {
      info();
      return;
    }

    if (config.condition) {
      message.warning(config.message);
    } else {
      Navigate(config.route);
    }
  };

  const handleClickButtonEdit = (typeConfig, id) => {
    const contentMessage = "Please select the well that matches";

    const contentType = {
      wellConfig: {
        route: `./${NAME_PAGES.plunger_lift.edit_well}`,
        message: "Please select the correct well",
      },
      pvtData: {
        route: `./${NAME_PAGES.plunger_lift.edit_pvt}`,
        message: `${contentMessage} PVT config`,
      },
      plunger_lift: {
        route: `./${NAME_PAGES.plunger_lift.edit_plunger_lift}`,
        message: `${contentMessage} plunger lift`,
      },
      reservoir: {
        route: `./${NAME_PAGES.plunger_lift.edit_reservoir}`,
        message: `${contentMessage} reservoir`,
      },
    };

    const configType = typeConfig ? contentType[typeConfig] : [];

    if (selectedWellPL === id) {
      Navigate(configType.route);
    } else {
      message.warning(configType.message);
    }
  };

  const handleClickDelete = (typeConfig, well_id) => {
    if (selectedWellPL !== well_id) {
      message.warning("Please select the correct well");
      return;
    }

    const typeDescriptions = {
      pvtData: "PVT",
      reservoir: "reservoir",
      plunger_lift: "plunger lift",
      well: "well",
    };

    const typeTitle = typeDescriptions[typeConfig] || "";

    showDeleteConfirm({
      title: `Do you want to delete this ${typeTitle}?`,
      content: "Deleted information can't be recovered",
      typeConfig,
      well_id,
    });
  };

  const showDeleteConfirm = ({ title, content, typeConfig, well_id }) => {
    confirm({
      title,
      icon: <ExclamationCircleFilled />,
      content,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => handleDeleteAction(typeConfig, well_id),
      onCancel: () => console.log("Cancel"),
    });
  };

  const handleDeleteAction = (typeConfig, well_id) => {
    let isValid = false;

    const basePayload = {
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserPL.county,
      field: dataFilterUserPL.field,
      well: dataFilterUserPL.well_id,
      username: User.user_name,
    };

    const handleMutation = (mutation, data, successMessage) => {
      mutation.mutate({ ...basePayload, data, type: "parametersplungerlift" });
      message.success(successMessage);
      setTimeout(refetchListWellPL, 500);
    };

    switch (typeConfig) {
      case "pvtData":
        const wellIndex = data.findIndex((well) => well.well_id === well_id);
        const wellConfig = data[wellIndex]?.wellConfig;

        if (wellConfig?.reservoir) {
          infoDelete(typeConfig, well_id);
        } else {
          const dataCreateFluid = {
            ...EMPTY_DATA_PVT,
            ...dataInformationEndPVT,
          };
          const isValid = REQUIRED_PROPIERTIES_PL.every(
            (prop) => prop in dataCreateFluid
          );

          if (isValid) {
            handleMutation(
              mutationUpdateWellPlungerLift,
              dataCreateFluid,
              "Fluid deleted successfully"
            );
          } else {
            message.warning("Sorry, something went wrong. Please try again.");
          }
        }
        break;

      case "reservoir":
        const dataDeleteReservoir = {
          ...EMPTY_DATA_RESERVOIR,
          ...dataInformationEndReservoir,
        };
        isValid = REQUIRED_PROPIERTIES_PL.every(
          (prop) => prop in dataDeleteReservoir
        );

        if (isValid) {
          handleMutation(
            mutationUpdateWellPlungerLift,
            dataDeleteReservoir,
            "Reservoir deleted successfully"
          );
        } else {
          message.warning("Sorry, something went wrong. Please try again.");
        }
        break;

      case "plunger_lift":
        const dataDeletePlungerLift = {
          ...DATA_PLUNGER_LIFT.initialInputPlungerLift,
          ...dataInformationEndPlungerLift,
        };
        isValid = REQUIRED_PROPIERTIES_PL.every(
          (prop) => prop in dataDeletePlungerLift
        );

        if (isValid) {
          handleMutation(
            mutationUpdateWellPlungerLift,
            dataDeletePlungerLift,
            "Plunger lift deleted successfully"
          );
        } else {
          message.warning("Sorry, something went wrong. Please try again.");
        }
        break;
      case "well":
        handleMutation(mutationDeleteWell, null, "Well deleted successfully");
        getSelectedWellPL(null);
        getDataFilterPL(null);
        break;
      default:
        message.warning("Invalid type for deletion");
    }
  };

  const info = () => {
    const selectedWellIndex = data.findIndex(
      (well) => well.well_id === selectedWellPL
    );
    Modal.info({
      title: "Warning",
      icon: <ExclamationCircleFilled />,
      okText: "OK",
      okType: "danger",
      content: (
        <div>
          {userDataInfo.operator === undefined ||
          userDataInfo.lease === undefined ? (
            <>
              <p>
                Please select or fill-out the information of operator, lease,
                and region
              </p>
            </>
          ) : (selectedWellPL === undefined || selectedWellPL === null) &&
            !!userDataInfo ? (
            <p>Please select a well first</p>
          ) : (
            !data[selectedWellIndex]?.wellConfig?.pvtData && (
              <p>Please select a well with a fluid</p>
            )
          )}
        </div>
      ),
      onOk() { userDataInfo.operator === undefined || userDataInfo.lease === undefined 
        ?  setOpenFirstModal((prevModals) => {
          const updatedModals = [...prevModals]; // Crear una copia del arreglo
          updatedModals[0] = { ...updatedModals[0], enabled: true }; // Modificar solo la segunda posición
          return updatedModals; // Retornar el arreglo actualizado
        }) 
        :  setOpenFirstModal((prevModals) => {
          const updatedModals = [...prevModals]; // Crear una copia del arreglo
          updatedModals[0] = { ...updatedModals[0], enabled: false }; // Modificar solo la segunda posición
          return updatedModals; // Retornar el arreglo actualizado
        })
      },
    });
  };

  const infoDelete = (typeConfig, id) => {
    Modal.info({
      title: "Warning",
      content: (
        <div>
          {typeConfig === "pvtData" &&
            (selectedWellPL != null ||
              selectedWellPL != "null" ||
              selectedWellPL == undefined) && (
              <>
                <p>Please delete the reservor first</p>
              </>
            )}
          {typeConfig === "reservoir" &&
            (selectedWellPL == null ||
              selectedWellPL == "null" ||
              selectedWellPL == undefined) && (
              <>
                <p>Please select a well to continue</p>
              </>
            )}
          {selectedWellPL != id && (
            <>
              <p>
                Please select the well corresponding to this{" "}
                {typeConfig === "reservoir"
                  ? "reservoir"
                  : typeConfig === "tuning"
                  ? "tuning"
                  : "fluid"}
              </p>
            </>
          )}
        </div>
      ),
      onOk() {},
    });
  };

  const handleChangeCheckWell = (valueCheck, idCheck) => {
    if (valueCheck === true) {
      const dataWellFind = data.find((item) => item.well_id === idCheck);
      const dataFilterNew = {
        county: dataWellFind.county,
        field: dataWellFind.field,
        well_id: dataWellFind.well_id,
        well_platforms: dataWellFind.well_platforms,
        well_name: dataWellFind.well_name,
      };
      getDataFilterPL(dataFilterNew);
      getSelectedWellPL(idCheck);
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: { ...dataFilter?.data, checkedWellPlungerLift: idCheck },
        });
      }, 1000);
    } else {
      getSelectedWellPL(null);
      getDataFilterPL(null);
      setTimeout(() => {
        mutationUpdateFilter.mutate({
          user: User.user_name,
          data: { ...dataFilter?.data, checkedWellPlungerLift: "" },
        });
      }, 1000);
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex h-full abosolute"></Sidebar>
      </div>
      <div className="flex flex-col w-full h-full  gap-4 pr-2 pl-[90px] p-5">
        <div className="flex flex-row gap-5 pl-[76px] pr-[76pc]">
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate("wellConfig")}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Well Confing
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate("pvtData")}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add PVT data
            </button>
          </div>
          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate("reservoir")}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Reservoir
            </button>
          </div>

          <div className="flex w-[157px] h-[42px] justify-center text-center items-center">
            <button
              onClick={() => handleClickButtonCreate("plunger_lift")}
              className="flex w-[157px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
            >
              Add Plunger Lift
            </button>
          </div>

          <div className="flex w-[157px] h-[42px] justify-center text-center items-center"></div>
        </div>
        {listWells === null ? (
          <ErrorLoadingData />
        ) : (
          <ul className="flex flex-col gap-y-[17px]">
            {data?.map((item, index) => {
              return (
                <li
                  key={index}
                  className="flex flex-row w-full p-5 border-solid rounded-md"
                >
                  <div className="flex flex-row w-full gap-5">
                    <table className="min-w-full text-sm font-light text-left">
                      <thead className="border-b font-medium text-[15px] border border-[#BCBCCC] bg-[#FAFAFA] ">
                        <tr style={{ width: "100%" }}>
                          <th
                            scope="col"
                            className="px-6 py-4"
                            style={{ width: "15%" }}
                          >
                            Select well
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4"
                            style={{ width: "20%" }}
                          >
                            Well Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4"
                            style={{ width: "20%" }}
                          >
                            Well ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-4"
                            style={{ width: "20%" }}
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="items-center justify-center px-6 py-4 text-center"
                            style={{ width: "20%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-b font-medium text-[15px]  border border-[#BCBCCC]">
                        <tr>
                          <td
                            className="px-6 py-4 whitespace-nowrap"
                            rowSpan={
                              item.wellConfig.pvtData ||
                              item.wellConfig.reservoir ||
                              item.wellConfig.PlungerLift
                                ? 6
                                : 1
                            }
                          >
                            <Checkbox
                              className={"custom-checkbox"}
                              onChange={(e) => {
                                handleChangeCheckWell(
                                  e.target.checked,
                                  item.well_id
                                );
                                getSelectedWellPL(
                                  e.target.checked ? item.well_id : null
                                );
                              }}
                              checked={
                                selectedWellPL
                                  ? item.well_id === dataFilterUserPL?.well_id
                                  : false
                              }
                            ></Checkbox>
                          </td>
                          <td className="px-6 py-4 text-purple-800 whitespace-nowrap">
                            {item.well_name}
                          </td>
                          <td
                            className="px-6 py-4 whitespace-nowrap"
                            rowSpan={
                              item.wellConfig.pvtData ||
                              item.wellConfig.reservoir ||
                              item.wellConfig.PlungerLift
                                ? 6
                                : 1
                            }
                          >
                            {item.well_id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            well's data
                          </td>
                          <td className="items-center justify-center px-6 py-4 text-center whitespace-nowrap">
                            <Tooltip title={"Update"} color={"#662D91"}>
                              <EditOutlined
                                key="edit"
                                onClick={() =>
                                  handleClickButtonEdit(
                                    "wellConfig",
                                    item.well_id
                                  )
                                }
                                style={{ color: "#707070" }}
                              />
                            </Tooltip>
                            <span className="mx-6"></span>
                            <Tooltip
                              title={"Delete the well"}
                              color={"#662D91"}
                            >
                              <DeleteOutlined
                                key="deleteWell"
                                onClick={() =>
                                  handleClickDelete("well", item.well_id)
                                }
                                style={{ color: "#707070" }}
                              />
                            </Tooltip>
                          </td>
                        </tr>

                        {item.wellConfig.pvtData && (
                          <tr>
                            <td className="px-6 py-4 text-purple-800 whitespace-nowrap">
                              PVT config
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              well's PVT config
                            </td>
                            <td className="items-center justify-center px-6 py-4 text-center whitespace-nowrap">
                              <Tooltip title={"Update"} color={"#662D91"}>
                                <EditOutlined
                                  key="edit"
                                  onClick={() =>
                                    handleClickButtonEdit(
                                      "pvtData",
                                      item.well_id
                                    )
                                  }
                                  style={{ color: "#707070" }}
                                />
                              </Tooltip>
                              <span className="mx-6"></span>
                              <Tooltip title={"Delete"} color={"#662D91"}>
                                <DeleteOutlined
                                  key="delete"
                                  onClick={() =>
                                    handleClickDelete("pvtData", item.well_id)
                                  }
                                  style={{ color: "#707070" }}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        )}

                        {item.wellConfig.reservoir && (
                          <tr>
                            <td className="px-6 py-4 text-purple-800 whitespace-nowrap">
                              Reservoir
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              well's reservoir data
                            </td>
                            <td className="items-center justify-center px-6 py-4 text-center whitespace-nowrap">
                              <Tooltip title={"Update"} color={"#662D91"}>
                                <EditOutlined
                                  key="edit"
                                  onClick={() =>
                                    handleClickButtonEdit(
                                      "reservoir",
                                      item.well_id
                                    )
                                  }
                                  style={{ color: "#707070" }}
                                />
                              </Tooltip>
                              <span className="mx-6"></span>
                              <Tooltip title={"Delete"} color={"#662D91"}>
                                <DeleteOutlined
                                  key="delete"
                                  onClick={() =>
                                    handleClickDelete("reservoir", item.well_id)
                                  }
                                  style={{ color: "#707070" }}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        )}
                        {item.wellConfig.plungerLift && (
                          <tr>
                            <td className="px-6 py-4 text-purple-800 whitespace-nowrap">
                              Plunger Lift
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              well's Plunger Lift data
                            </td>
                            <td className="items-center justify-center px-6 py-4 text-center whitespace-nowrap">
                              <Tooltip title={"Update"} color={"#662D91"}>
                                <EditOutlined
                                  key="edit"
                                  onClick={() =>
                                    handleClickButtonEdit(
                                      "plunger_lift",
                                      item.well_id
                                    )
                                  }
                                  style={{ color: "#707070" }}
                                />
                              </Tooltip>
                              <span className="mx-6"></span>
                              <Tooltip title={"Delete"} color={"#662D91"}>
                                <DeleteOutlined
                                  key="delete"
                                  onClick={() =>
                                    handleClickDelete(
                                      "plunger_lift",
                                      item.well_id
                                    )
                                  }
                                  style={{ color: "#707070" }}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
