export const ALL_TABLES_HISTORY = {
	sensitivity_1: [
		{
			period_1: "df_cp_hist_1000_1",
		},
		{
			period_2: "df_cp_hist_1000_2",
		},
		{
			period_3: "df_cp_hist_1000_3",
		},
		{
			period_4: "df_cp_hist_1000_4",
		},
	],
	sensitivity_2: [
		{
			period_1: "df_cp_hist_500_1",
		},
		{
			period_2: "df_cp_hist_500_2",
		},
		{
			period_3: "df_cp_hist_500_3",
		},
		{
			period_4: "df_cp_hist_500_4",
		},
	],
	sensitivity_3: [
		{
			period_1: "df_cp_hist_100_1",
		},
		{
			period_2: "df_cp_hist_100_2",
		},
		{
			period_3: "df_cp_hist_100_3",
		},
		{
			period_4: "df_cp_hist_100_4",
		},
	],
};

export const ALL_TABLES_FORECAST = {
	semiCustom: [
		{
			semiCustom_1: "df_cp_comb_semi_custom",
		},
		{
			semiCustom_2: "df_cp_summary_semi_custom",
		},
		{
			semiCustom_3: "df_cp_forecast_semi_custom",
		},
	],
	fullCustom: [
		{
			fullCustom_1: "df_cp_comb_full_custom",
		},
		{
			fullCustom_2: "df_cp_summary_full_custom",
		},
		{
			fullCustom_3: "df_cp_forecast_full_custom",
		},
	],
};

export const DATA_CREATE_WELL_DCA = {
	well_id: "",
	well_name: "",
	discretized_well: false,
	well_platforms: "",
	fluid_type: "",
	region: "",
	county: "",
	field: "",
	latitude: ``,
	longitude: ``,
	productForescast: "",
	iprModel: "",
	generateForeGas: "",
	generateForeOil: "",
	generateForeOilWater: "",
	reporting_frequency: "",
	sensitivities: null,
	periods: null,
	standars_deviation: "",
	forecastTime: "",
	production_start_Date: "",
	abandonment_Rate: "",
	ultimate_recoverable: "",
	customScenario: "default",
	startMonth: null,
	initialFluidRate: null,
	start_select: null,
	end_select: null,
	forecastHorizont: null,
	type_graph_dashboard: [],
	data_alert: {
		wc_pressure_min: null,
		wc_temp_min: null,
		bhc_pressure_min: null,
		bhc_temp_min: null,
		pp_oil_rate_min: null,
		pp_gas_rate_min: null,
		pp_water_cute_min: null,
		pp_water_rate_min: null,
		pp_wor_min: null,
		pp_gor_min: null,
		alm_injection_min: null,
		alm_inj_gas_min: null,
		alm_inj_GLR_min: null,
		alm_formation_min: null,
		alm_water_cut_min: null,
		wc_pressure_max: null,
		wc_temp_max: null,
		bhc_pressure_max: null,
		bhc_temp_max: null,
		pp_oil_rate_max: null,
		pp_gas_rate_max: null,
		pp_water_cute_max: null,
		pp_water_rate_max: null,
		pp_wor_max: null,
		pp_gor_max: null,
		alm_injection_max: null,
		alm_inj_gas_max: null,
		alm_inj_GLR_max: null,
		alm_formation_max: null,
		alm_water_cut_max: null,
	},
	data_check: {
		valuesAlert: {
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			wc_pressure: false,
			wc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pp_oil_rate: false,
			pp_water_rate: false,
			pp_gas_rate: false,
			pp_wor: false,
			pp_water_cute: false,
			pp_gor: false,
		},
		valuesCheck: {
			alm_injection: false,
			alm_inj_gas: false,
			alm_inj_GLR: false,
			alm_formation: false,
			alm_water_cut: false,
			wc_pressure: false,
			wc_temp: false,
			bhc_pressure: false,
			bhc_temp: false,
			pp_oil_rate: false,
			pp_water_rate: false,
			pp_gas_rate: false,
			pp_wor: false,
			pp_water_cute: false,
			pp_gor: false,
		},
		countAlert: 0,
		countCheck: 0,
	},
	data_domain_graph: {
		prodrate_max_aidca: null,
		prodrate_min_aidca: null,
		cumprod_max_aidca: null,
		cumprod_min_aidca: null,
		pressure_max_per: null,
		pressure_min_per: null,
		temperature_max_per: null,
		temperature_min_per: null,
		liquidrate_max_ph: null,
		liquidrate_min_ph: null,
		gasrate_max_ph: null,
		gasrate_min_ph: null,
	},
	check_table_results : {
		graph: [],
		data: []
	}
};

export const STATE_ALERTS = {
	alert_wc_pressure: "wc_pressure",
	alert_wc_temp: "wc_temp",
	alert_pp_oil_rate: "pp_oil_rate",
	alert_pp_gas_rate: "pp_gas_rate",
	alert_pp_water_rate: "pp_water_rate",
};

export const INITIAL_DATA_ALERTS_DCA = {
	wc_pressure_min: null,
	wc_temp_min: null,
	bhc_pressure_min: null,
	bhc_temp_min: null,
	pp_oil_rate_min: null,
	pp_gas_rate_min: null,
	pp_water_cute_min: null,
	pp_water_rate_min: null,
	pp_wor_min: null,
	pp_gor_min: null,
	alm_injection_min: null,
	alm_inj_gas_min: null,
	alm_inj_GLR_min: null,
	alm_formation_min: null,
	alm_water_cut_min: null,
	wc_pressure_max: null,
	wc_temp_max: null,
	bhc_pressure_max: null,
	bhc_temp_max: null,
	pp_oil_rate_max: null,
	pp_gas_rate_max: null,
	pp_water_cute_max: null,
	pp_water_rate_max: null,
	pp_wor_max: null,
	pp_gor_max: null,
	alm_injection_max: null,
	alm_inj_gas_max: null,
	alm_inj_GLR_max: null,
	alm_formation_max: null,
	alm_water_cut_max: null,
};

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
	"date",
	"oil_rate",
	"gas_rate",
	"water_rate",
	"cumulative_oil",
	"cumulative_gas",
	"cumulative_water",
	"gor",
	"w_cut",
];

export const TYPE_OPTIONS_CHARTS = {
	gas: "Gas",
	oil: "Oil",
};

export const TYPE_CHARTS = {
	prod_hist: "production_history",
	ai_DCA: "AI_DCA",
	performance: "performance",
};
export const TYPE_DEVICES = {
	separator: "Separator",
};
export const INITIAL_COORDINATES = {
	latitudeDegrees: "",
	latitudeMinutes: "",
	latitudeSeconds: "",
	latitudeDirection: "",
	longitudeDegrees: "",
	longitudeMinutes: "",
	longitudeSeconds: "",
	longitudeDirection: "",
};

export const MODELS = {
	tableForecast: false,
	periodSelection: false,
};

export const DATA_DOMAIN_GRAPH_DCA = {
	prodrate_max_aidca: null,
	prodrate_min_aidca: null,
	cumprod_max_aidca: null,
	cumprod_min_aidca: null,
	pressure_max_per: null,
	pressure_min_per: null,
	temperature_max_per: null,
	temperature_min_per: null,
	liquidrate_max_ph: null,
	liquidrate_min_ph: null,
	gasrate_max_ph: null,
	gasrate_min_ph: null,
};

export const REQUIRED_FIELDS_VALIDATIONS = [
	"forecastHorizont",
	"periods",
	"sensitivities",
	"startMonth",
	"start_select",
	"end_select",
	"initialFluidRate",
];


export const INITIAL_DATA_CARDS_DCA = {
	wellhead_conditions_press: "",
	wellhead_conditions_temp: "",
	production_performance_gas_rate: "",
	production_performance_oil_rate: "",
	production_performance_water_rate: "",
	production_performance_gor: "",
	production_performance_wor: "",
}

export const INITIAL_INPUT_VOLUME_TRACKER = {
	production_history: {
		fcat: null,
		fcat_gas: null,
		water_cut: null,
		separator_type: "",
	},
};
