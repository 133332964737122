import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const formatNumber = (value) => {
// 2 decimal numbers format, except integers
  if (!isNaN(value) && value !== "") {
    const num = parseFloat(value);
    return num % 1 === 0 ? num : num.toFixed(2);
  }
  return value;
};

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
        <p style={{ color: '#000000' }}>{`Liquid Rate: ` + formatNumber(data.ql_max) + ` (BBL/D)`}</p>
        <p style={{ color: '#873d01' }}>{`Optimum Performance: ` + formatNumber(data.press_csg_avg) + ` (psia)`}</p>
        <p style={{ color: '#de1919' }}>{`Surface Casing Pressure @ BHP: ` + formatNumber(data.final_press) + ` (psia)`}</p>
        <p style={{ color: '#8884d8' }}>{`Inflow Performance Relationship: ` + formatNumber(data.data_inter) + ` (psia)`}</p>
        <p style={{ color: '#00bfff' }}>{`Current performance: ` + formatNumber(data.pc_current_plot) + ` (psia)`}</p>
        <p style={{ color: '#ff7f50' }}>{`Estimated performance by GLR: ` + formatNumber(data.pc_estim_rate_glr_plot) + ` (psia)`}</p>
        <p style={{ color: '#32cd32' }}>{`Estimated Performance by Casing Pressure: ` + formatNumber(data.pc_estim_rate_pc_plot) + ` (psia)`}</p>
      </div>
    );
  }
  return null;
}

export const ChartPlungerLift = ({ dataCasingChart, dataPressChart, dataInterpolation }) => {
  const [dataGraph, setDataGraph] = useState([]);

  useEffect(() => {
    if (dataPressChart && dataCasingChart && dataInterpolation && dataInterpolation.pc_current_plot) {
      const { pc_current_plot, pc_estim_rate_glr_plot, pc_estim_rate_pc_plot } = dataInterpolation;

      if (Object.keys(dataPressChart).length && Object.keys(dataCasingChart).length) {
        const combinedData = {
          data_inter: dataPressChart.data_interpolation,
          final_press: dataPressChart.final_pressures,
          press_csg_avg: dataCasingChart.table_casing.map(item => item.press_csg_avg),
          ql_max: dataCasingChart.table_casing.map(item => item.ql_max),
          pc_current_plot,
          pc_estim_rate_glr_plot,
          pc_estim_rate_pc_plot
        };

        const data = combinedData.data_inter.map((value, index) => ({
          id: index + 1,
          data_inter: combinedData.data_inter[index],
          final_press: combinedData.final_press[index],
          press_csg_avg: combinedData.press_csg_avg[index],
          ql_max: combinedData.ql_max[index],
          pc_current_plot: combinedData.pc_current_plot[index] || null,
          pc_estim_rate_glr_plot: combinedData.pc_estim_rate_glr_plot[index] || null,
          pc_estim_rate_pc_plot: combinedData.pc_estim_rate_pc_plot[index] || null,
        }));
        setDataGraph(data);
      }
    } else {
      setDataGraph([]);
    }
  }, [dataPressChart, dataCasingChart, dataInterpolation]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={dataGraph}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          dataKey="ql_max"
          label={{
            value: `Liquid Rate (STB/D)`,
            position: 'insideBottom ',
            style: { textAnchor: "middle" },
            dy: 20,
          }}
          tickCount={15}
        />
        <YAxis
          type="number"
          label={{
            value: `Pressure (psia) at Mid-perf Depth, TVD`,
            angle: -90,
            position: 'insideLeft',
            offset: -15,
            dy: 140,
          }}
          tickCount={15}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line type="monotone" dataKey="press_csg_avg" stroke="#873d01" />
        <Line type="monotone" dataKey="final_press" stroke="#de1919" />
        <Line type="monotone" dataKey="data_inter" stroke="#8884d8" />
        <Line type="monotone" dataKey="pc_current_plot" stroke="#00bfff" dot />
        <Line type="monotone" dataKey="pc_estim_rate_glr_plot" stroke="#ff7f50" dot />
        <Line type="monotone" dataKey="pc_estim_rate_pc_plot" stroke="#32cd32" dot />
      </LineChart>
    </ResponsiveContainer>
  );
};
