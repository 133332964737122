import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_IOT = config.API.IOT.BASE_URL_IOT;
const DB_URL_DEVICES_DCA = config.API.DCA.BASE_URL_DEVICE;
const DB_URL_DEVICES_GASLIFT = config.API.GASLIFT.BASE_URL_DEVICE;
const TOKEN_IOT = config.AUTH.TOKEN_IOT;

export const getJsonIOT = async (operator, lease, region, wellplatform) => {
	if (!validateParams(operator, lease, region, wellplatform)) return;

	try {
		const response = await fetch(
			`${DB_URL_IOT}/iotwelldata/${operator}/${lease}/${region}/${wellplatform}/200`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the IoT data");
	}
};

export const getListPlatforms = async (operator, lease, region) => {
	if (!validateParams(operator, lease, region)) return;

	try {
		const response = await fetch(
			`${DB_URL_IOT}/platforms_list_plotting/${operator}/${lease}/${region}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the platforms list");
	}
};

export const getListPlatformsWells = async (
	operator,
	lease,
	region,
	wellplatform,
) => {
	if (!validateParams(operator, lease, region, wellplatform)) return;

	try {
		const response = await fetch(
			`${DB_URL_IOT}/iot_data/list_wells/${operator}/${lease}/${region}/${wellplatform}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the platform wells list");
	}
};

export const getListDevicesDCA = async (operator, lease, region) => {
	if (!validateParams(operator, lease, region)) return;

	try {
		const response = await fetch(
			`${DB_URL_DEVICES_DCA}/platform_devices_plotting/${operator}/${lease}/${region}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the devices list for DCA");
	}
};

export const getListDevicesGaslift = async (operator, lease, region) => {
	if (!validateParams(operator, lease, region)) return;

	try {
		const response = await fetch(
			`${DB_URL_DEVICES_GASLIFT}/platform_devices_plotting/${operator}/${lease}/${region}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the devices list for Gaslift");
	}
};

export const getListDevicesPlungerlift = async (operator, lease, region) => {
	if (!validateParams(operator, lease, region)) return;

	try {
		const response = await fetch(
			`${DB_URL_DEVICES_GASLIFT}/platform_devices_plotting/${operator}/${lease}/${region}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the devices list for Gaslift");
	}
};
