import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as GasLiftSidebar } from "../../assets/img/icons/gas_lift.svg";
import { ReactComponent as MapSidebar } from "../../assets/img/icons/map.svg";
import { ReactComponent as GraphSidebar } from "../../assets/img/icons/graph.svg";
import { ReactComponent as AddUserSidebar } from "../../assets/img/icons/addUsers.svg";
import { ReactComponent as UsersExtSidebar } from "../../assets/img/icons/userExternal.svg";
import { ReactComponent as UsersIntSidebar } from "../../assets/img/icons/userInternal.svg";
import { ReactComponent as ScreeningToolSidebar } from "../../assets/img/icons/display.svg";
import { ReactComponent as PlungerLift } from "../../assets/img/icons/plunger_lift.svg";
import { ReactComponent as PlottingSidebar } from "../../assets/img/icons/plotting.svg";
import { ReactComponent as ControlSidebar } from "../../assets/img/icons/control_filter.svg";
import { Sidenav, Collapse, initTE, Dropdown } from "tw-elements";
import { Tooltip, Modal } from "antd";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { AuthContext } from "../../auth/context/AuthContext";
import { useFilters } from "../../hooks/useFilters";
import Img_Logo from "../../assets/img/Logo-Enovate.png";
import Emerson from "../../assets/img/Emerson.png";
import LogoBI from "../../assets/img/ADA_BI_Drilling.svg";
import LogoB4 from "../../assets/img/B4e_Logo.svg";
import LogoB4E from "../../assets/img/logoB4E.svg"
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabDCA } from "../../hooks/useTabDCA";
import { STATUS_DATA_GAS_LIFT, STATUS_DATA_DCA} from "../elements/types/type_response_API";
import { NAME_PAGES } from "../elements/types/type_pages";

export const Sidebar = () => {
  const { dataPage, someAlert, getSomeAlert, selectedWell, dataAlertGasLift, alertDCAData } = useContext(DashboardContext);
  const { User } = useContext(AuthContext)
  const { dataFilter } = useFilters();
  const [ widthSidebar, setWidthSidebar ] = useState(true);
  const [ countAlertGasLif, setCountAlertGasLif ] = useState(0);
  const [ countAlerDCA, setCountAlertDCA ] = useState(0);
  const { dataWellGasLift, mutationUpdateDataGasLift } = useTabGaslift();
  const { dataGetWellDCA, mutationUpdateJsonWellDCA } = useTabDCA();
  const [ dataWellGL, setDataWellGL ] = useState()
  const [ dataWellDCA, setDataWellDCA ] = useState()
  const [ seletedFilter, setSeletedFilter] = useState();
  const { confirm } = Modal;
  const Navigate = useNavigate();

  useEffect(() => {
    const filter = dataFilter ? dataFilter?.data : null;
    setSeletedFilter(filter)
  }, [])

  useEffect(() => {
    const valuesTrueDCA = someAlert?.forecasting?.countAlert;
    setCountAlertDCA(valuesTrueDCA);

    const valuesTrue = someAlert?.gas_lift?.countAlert;
    setCountAlertGasLif(valuesTrue);

  },[someAlert]);

  useEffect(() => {
    setTimeout(() => {
      if (dataFilter != null) {
        if(dataWellGasLift?.statusCode === STATUS_DATA_GAS_LIFT.SUCCESS){
          setDataWellGL(dataWellGasLift.data)
        }
      }
    }, 500);
  },[dataWellGasLift]);

  useEffect(() => {
    setTimeout(() => {
      if (dataFilter != null) {
        if(dataGetWellDCA?.statusCode === STATUS_DATA_DCA.SUCCESS){
          setDataWellDCA(dataGetWellDCA.data)
        }
      }
    }, 500);
  },[dataGetWellDCA]);
  
  useEffect(() => {
    initTE({ Collapse, Dropdown, Sidenav });
  }, []);
    
  const changeSlim = () => {
    setWidthSidebar(!widthSidebar);
    
    const instance = Sidenav.getInstance(document.getElementById("sidenav-4"));
    if (instance) {
      instance.toggleSlim();
    }
  };

  let productElement = [
    
  {
    path: 'https://www.zediaccess.com/Logon', tooltipContent: 'Emerson', icon:
      <div className="flex flex-row items-center justify-start w-full mt-4 mb-4 cursor-pointer gap-3">
        <img
          src={widthSidebar ? Emerson : Emerson}
          //style={!widthSidebar ? { padding: '0 1.5em' } : {}}
          className={`transition-all duration-300 ease-in-out ${widthSidebar ? 'w-auto h-[60%]' : 'w-[23%]'}`}
          alt=""
          loading="lazy"
        />
        {!widthSidebar && <label className="font-bold text-[16px] text-white font-sans">EMERSON</label>}
      </div>
  },
  {
    path: 'https://drillbi-test.enovate.app/Login', tooltipContent: 'ADA BI', icon:
    <div className="flex flex-row items-center justify-center w-full mt-4 mb-4 cursor-pointer gap-3 bg">
      <img
        src={widthSidebar ? Img_Logo : Img_Logo}
        //style={!widthSidebar ? { padding: '0 1.5em' } : {}}
        className={`transition-all duration-300 ease-in-out ${widthSidebar ? 'w-auto h-[50%]' : 'w-[17%]'}`}
        alt=""
        loading="lazy"
      />
      {!widthSidebar && <label className="font-bold text-[16px] text-white font-sans">ADA BI Drilling</label>}
    </div>
  },
  {
    path: 'https://drilloptim-rollout.enovate.app', tooltipContent: 'ADA DO', icon:
      <div className="flex flex-row items-center justify-center w-full mt-4 mb-4 cursor-pointer gap-3">
        <img
          src={widthSidebar ? Img_Logo : Img_Logo}
          //style={!widthSidebar ? { padding: '0 1.5em' } : {}}
          className={`transition-all duration-300 ease-in-out ${widthSidebar ? 'w-auto h-[50%]' : 'w-[17%]'}`}
          alt=""
          loading="lazy"
        />
        {!widthSidebar && <label className="font-bold text-[16px] text-white font-sans">ADA DO Optim</label>}
      </div>
  },
  {
    path: 'https://b4ecarbon-test.enovate.app/', tooltipContent: 'B4ECarbon', icon:
      <div className="flex items-center justify-center w-full mt-4 mb-4 cursor-pointer">
        <img
          src={widthSidebar ? LogoB4E : LogoB4}
          //style={!widthSidebar ? { padding: '0 1.5em' } : {}}
          className={`transition-all duration-300 ease-in-out ${widthSidebar ? 'w-auto h-auto' : 'w-full mr-15'}`}
          alt=""
          loading="lazy"
        />
      </div>
  }
];

  const handleConfirm = (link) => {
    if (selectedWell !== "null" && seletedFilter) {
      confirm({
        title: `Do you want to save the changes of the alarm's thresholds?`,
        icon: <ExclamationCircleFilled />,
        //content: 'Remember that if you eliminate the well you will not be able to recover it',
        okText: 'Save Changes',
        okButtonProps:{
          className: "bg-[#662D91]"
        },
        cancelText: 'Exit',
        onOk() {
          switch (dataPage) {
            case "DashboardGasLift":

              const data_alert = dataAlertGasLift;
              const data_check = someAlert.gas_lift;
              const dataFinalWell = {...dataWellGL, data_alert: data_alert, data_check: data_check};
              setTimeout(() => {
                mutationUpdateDataGasLift.mutate({ 
                  operator: seletedFilter?.operator, 
                  lease: seletedFilter?.lease, 
                  region: seletedFilter?.region, 
                  county: dataWellGL?.county,
                  field: dataWellGL?.field,
                  well: dataWellGL?.well_id,
                  username: User?.user_name, 
                  data: dataFinalWell
                })
              }, 500);

              getSomeAlert({...someAlert, messageChange: false})
              Navigate(link);

              break;
            case "DashboardDCA":

              const data_alert_dca = alertDCAData;
              const data_check_dca = someAlert.forecasting;
              const dataFinalWellDCA = {...dataWellDCA, data_alert: data_alert_dca, check_Alert: data_check_dca};

              setTimeout(() => {
                mutationUpdateJsonWellDCA.mutate({ 
                  operator: seletedFilter?.operator, 
                  lease: seletedFilter?.lease, 
                  region: seletedFilter?.region, 
                  county: dataWellDCA?.county,
                  field: dataWellDCA?.field,
                  wellid: dataWellDCA?.well_id,
                  userName: User?.user_name, 
                  data: dataFinalWellDCA
                })
              }, 500);

              getSomeAlert({...someAlert, messageChange: false})
              Navigate(link);
              break;

            default:
              break;
          }
        },
        onCancel() {
          getSomeAlert({...someAlert, messageChange: false});
          Navigate(link);
        },
      });
    }
  }
  
  return (
    <>
      <nav
        id="sidenav-4"
        className="group fixed left-0 top-0 z-[1035] h-full w-[225px] -translate-x-full overflow-hidden bg-pa-background shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-slim='true']:hidden data-[te-sidenav-slim-collapsed='true']:w-[80px] data-[te-sidenav-slim='true']:w-[80px] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800 [&[data-te-sidenav-slim-collapsed='true'][data-te-sidenav-slim='false']]:hidden [&[data-te-sidenav-slim-collapsed='true'][data-te-sidenav-slim='true']]:[display:unset]"
        data-te-sidenav-init
        data-te-sidenav-hidden={false}
        data-te-sidenav-mode="side"
        data-te-sidenav-slim={widthSidebar}
        data-te-sidenav-content="#slim-content"
        data-te-sidenav-slim-collapsed={widthSidebar}
        data-te-sidenav-slim-width={80}
      >
        <div>
          <ul
            className="relative m-0 list-none px-[0.2rem]"
            data-te-sidenav-menu-ref
          >
            <li className="relative">
              <div
                onClick={changeSlim}
                className="flex h-[90px] cursor-pointer items-start  truncate rounded-[5px] px-5 py-4 text-[0.875rem] outline-none transition duration-300 ease-linear  hover:text-inherit hover:outline-none  focus:text-inherit focus:outline-none active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none"
                data-te-sidenav-link-ref
              >
                <span className="mr-[10px] [&>svg]:h-4 [&>svg]:w-4 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300 ">
                  <img
                    src={Img_Logo}
                    className="w-6 lg:w-9 lg:absolute"
                    alt=""
                    loading="lazy"
                  />
                </span>
                <span
                  className="pl-9 group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden text-2xl text-white focus:text-pa-purple hover:text-pa-purple"
                  data-te-sidenav-slim="false"
                >
                  Enovate Ai
                </span>
              </div>
            </li>
            <Tooltip placement="right" title={"Map"} color={"#662D91"}>
              <NavLink 
                to={!someAlert?.messageChange ? `/${NAME_PAGES.map}` : "#" }
                onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.map}`) : null}>
                <li className="relative">
                  <div
                    className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                      dataPage === NAME_PAGES.map
                        ? "bg-white/10"
                        : ""
                    }`}
                    data-te-sidenav-link-ref
                  >
                    <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300 ">
                        <MapSidebar className="w-6 h-6 fill-neutral-500" />
                    </span>
                    <span
                      className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                      data-te-sidenav-slim="false"
                      >
                      Map
                    </span>
                  </div>
                </li>
              </NavLink>
            </Tooltip>
            <Tooltip placement="right" title={"Forecasting"} color={"#662D91"}>
              <NavLink 
                to={!someAlert?.messageChange ? `/${NAME_PAGES.DCA.dashboard}` : "#" } 
                onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.DCA.dashboard}`) : null}>
                <li className="relative">
                  <div
                    className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                      dataPage === NAME_PAGES.DCA.dashboard || dataPage === NAME_PAGES.DCA.list_well
                        ? "bg-white/10"
                        : ""
                    }`}
                    data-te-sidenav-link-ref
                  >
                    <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                        <GraphSidebar className="w-6 h-6 fill-neutral-500" />
                    </span>
                    <span
                      className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden hover:text-pa-purple"
                      data-te-sidenav-slim="false"
                      >
                      Forecasting
                    </span>
                    { (countAlerDCA != 0) && (
                       <>
                       {widthSidebar &&(
                         <div  className="absolute left-0 top-0 ml-[18px] w-[14px] h-[14px] bg-red-500 items-center text-center rounded-full" >
                           <span className="flex justify-center text-[10px] text-white">{countAlerDCA}</span>
                         </div>
                       )}
                       <div className="relative w-5 h-5 z-10 bg-red-500 px-0 py-0 rounded-full items-end text-center ml-3" data-te-sidenav-slim="false">{countAlerDCA}</div>
                     </>
                  )}
                  </div>
                </li>
              </NavLink>
            </Tooltip>
            <Tooltip placement="right" title={"Gas Lift"} color={"#662D91"}>
              <NavLink 
                to={!someAlert?.messageChange ? `/${NAME_PAGES.gas_lift.dashboard}` : "#" }
                onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.gas_lift.dashboard}`) : null}
                >
                <li className="relative">
                  <div
                    className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-5 py-5 text-[0.875rem] text-pa-white1 outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                      (dataPage === NAME_PAGES.gas_lift.dashboard || dataPage === NAME_PAGES.gas_lift.list_well)
                        ? "bg-white/10"
                        : ""
                    }`}
                    data-te-sidenav-link-ref
                  > 
                    <span className="mr-3 [&>svg]:h-8 [&>svg]:w-8 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                        <GasLiftSidebar className="w-8 h-8 fill-neutral-500" />
                    </span>
                    <span
                      className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                      data-te-sidenav-slim="false"
                      >
                      Gas Lift
                    </span>
                    {

                    }
                    {(countAlertGasLif != 0) &&(
                        <>
                          {widthSidebar &&(
                            <div  className="absolute left-0 top-0 ml-[18px] w-[14px] h-[14px] bg-red-500 items-center text-center rounded-full" >
                              <span className="flex justify-center text-[10px] text-white">{countAlertGasLif}</span>
                            </div>
                          )}
                          <div className="relative w-5 h-5 z-10 bg-red-500 px-0 py-0 rounded-full items-end text-center ml-9" data-te-sidenav-slim="false">{countAlertGasLif}</div>
                        </>
                    )
                    }
                  </div>
                </li>
              </NavLink>
            </Tooltip>
            <Tooltip placement="right" title={"Plotting"} color={"#662D91"}>
              <NavLink 
                to={!someAlert?.messageChange ? `/${NAME_PAGES.plotting.dashboard}` : "#" }
                onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.plotting.dashboard}`) : null}
                >
                <li className="relative">
                  <div
                    className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                      dataPage === NAME_PAGES.plotting.dashboard
                        ? "bg-white/10"
                        : ""
                    }`}
                    data-te-sidenav-link-ref
                  >
                    <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                        <PlottingSidebar className="w-6 h-6 fill-neutral-500"/>
                    </span>
                    <span
                      className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                      data-te-sidenav-slim="false"
                      >
                      Plotting
                    </span>
                  </div>
                </li>
              </NavLink>
            </Tooltip>
            <Tooltip placement="right" title={"Screening Tool"} color={"#662D91"}>
              <NavLink 
                to={!someAlert?.messageChange ? `/${NAME_PAGES.screening_tool.dashboard}` : "#" }
                onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.screening_tool.dashboard}`) : null}>
                <li className="relative">
                  <div
                    className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                      dataPage === NAME_PAGES.screening_tool.dashboard || dataPage === NAME_PAGES.screening_tool.list_well
                        ? "bg-white/10"
                        : ""
                    }`}
                    data-te-sidenav-link-ref
                  >
                    <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300 ">
                        <ScreeningToolSidebar className="w-6 h-6 fill-neutral-500" />
                    </span>
                    <span
                      className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                      data-te-sidenav-slim="false"
                      >
                      Screening Tool
                    </span>
                  </div>
                </li>
              </NavLink>
            </Tooltip>
            <Tooltip placement="right" title={"Plunger Lift"} color={"#662D91"}>
              <NavLink 
                to={!someAlert?.messageChange ? `/${NAME_PAGES.plunger_lift.list_well}` : "#" }
                onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.plunger_lift.list_well}`) : null}>
                <li className="relative">
                  <div
                    className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                      dataPage === NAME_PAGES.plunger_lift.dashboard || dataPage === NAME_PAGES.plunger_lift.list_well
                        ? "bg-white/10"
                        : ""
                    }`}
                    data-te-sidenav-link-ref
                  >
                    <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300 ">
                        <PlungerLift className="w-6 h-6 fill-neutral-500" />
                    </span>
                    <span
                      className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                      data-te-sidenav-slim="false"
                      >
                      Plunger Lift
                    </span>
                  </div>
                </li>
              </NavLink>
            </Tooltip>
            {User?.role !== "Simple User" &&    
              <Tooltip placement="right" title={"Register"} color={"#662D91"}>
                <NavLink 
                  to={!someAlert?.messageChange ? `/${NAME_PAGES.user.register.create}` : "#" }
                  onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.user.register.create}`) : null}
                  >
                  <li className="relative">
                    <div
                      className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                        dataPage === NAME_PAGES.user.register.create
                          ? "bg-white/10"
                          : ""
                      }`}
                      data-te-sidenav-link-ref
                    >
                      <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                          <AddUserSidebar className="w-6 h-6 fill-neutral-500" />
                      </span>
                      <span
                        className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                        data-te-sidenav-slim="false"
                        >
                        Register
                      </span>
                    </div>
                  </li>
                </NavLink>
              </Tooltip>
            } 
            {User?.role !== "Simple User" &&   
              <Tooltip placement="right" title={"External"} color={"#662D91"}>
                <NavLink 
                  to={!someAlert?.messageChange ? `/${NAME_PAGES.user.external.list_users}` : "#" }
                  onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.user.external.list_users}`) : null}
                  >
                  <li className="relative">
                    <div
                      className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                        dataPage === NAME_PAGES.user.external.list_users
                          ? "bg-white/10"
                          : ""
                      }`}
                      data-te-sidenav-link-ref
                    >
                      <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                          <UsersExtSidebar className="w-6 h-6 fill-neutral-500" />
                      </span>
                      <span
                        className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                        data-te-sidenav-slim="false"
                        >
                        External
                      </span>
                    </div>
                  </li>
                </NavLink>
              </Tooltip>
            }
            {User?.role !== "Simple User" &&   
              <Tooltip placement="right" title={"Internal"} color={"#662D91"}>
                <NavLink 
                  to={!someAlert?.messageChange ? `/${NAME_PAGES.user.internal.list_users}` : "#" }
                  onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.user.internal.list_users}`) : null}
                  >
                  <li className="relative">
                    <div
                      className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                        dataPage === NAME_PAGES.user.internal.list_users
                          ? "bg-white/10"
                          : ""
                      }`}
                      data-te-sidenav-link-ref
                    >
                      <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                          <UsersIntSidebar className="w-6 h-6 fill-neutral-500" />
                      </span>
                      <span
                        className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                        data-te-sidenav-slim="false"
                        >
                        Internal
                      </span>
                    </div>
                  </li>
                </NavLink>
              </Tooltip>
            }   
                        {User?.role !== "Simple User" &&   
              <Tooltip placement="right" title={"Control"} color={"#662D91"}>
                <NavLink 
                  to={!someAlert?.messageChange ? `/${NAME_PAGES.user.control.create}` : "#" }
                  onClick={someAlert?.messageChange ? () => handleConfirm(`/${NAME_PAGES.user.control.create}`) : null}
                  >
                  <li className="relative">
                    <div
                      className={`flex h-10 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-pa-white1  outline-none transition duration-300 ease-linear hover:text-white hover:outline-none focus:bg-pa-purple focus:text-inherit focus:outline-none active:bg-pa-purple active:text-white active:outline-none data-[te-sidenav-state-active]:text-white data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10 ${
                        dataPage === NAME_PAGES.user.internal.list_users
                          ? "bg-white/10"
                          : ""
                      }`}
                      data-te-sidenav-link-ref
                    >
                      <span className="mr-4 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-gray-400 dark:[&>svg]:text-gray-300">
                          <ControlSidebar className="w-6 h-6 fill-neutral-500" />
                      </span>
                      <span
                        className="group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden"
                        data-te-sidenav-slim="false"
                        >
                        Internal
                      </span>
                    </div>
                  </li>
                </NavLink>
              </Tooltip>
            } 
          </ul>
        </div>
        <div className="flex flex-col items-start absolute bottom-[1em] mb-auto w-full">
            <ul className='mt-auto px-[0.2rem]'>
              {productElement.map((element, index) => (
                <Tooltip key={index} color={"#662D91"} title={element.tooltipContent} placement="right">
                  <li>
                      <span className="flex w-full">
                        <NavLink to={element.path} target='_blank' className={`hover:text-white focus:text-pc-purple flex items-center h-12 cursor-pointer truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-gray-300 border-none transition duration-300 ease-linear hover:bg-slate-50 hover:border-none focus:bg-slate-50 focus:outline-none active:bg-slate-50 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none hover:bg-white/10 focus:bg-[white]/10 active:bg-[white]/10`}
                          data-te-sidenav-link-ref
                        >
                          {element.icon}
                        </NavLink>
                      </span>
                  </li>
                </Tooltip>
              ))
              }
            </ul>
        </div>
      </nav>
    </>
  );
};