import React, { useEffect, useState } from "react";
import styleChartVlp from "./ChartPlungerL.module.css";
import moment from "moment";
import { ReactComponent as ExpandIcon } from "../../../../assets/icon/expand1.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Select, Switch } from "antd";
const {
  containerPrincipal,
  containerGraph,
  containerLegend,
  styleRefenence1,
  styleRefenence2,
  styleRefenence3,
  styleRefenence4,
  styleRefenence5,
  sizeReference,
  container1,
  contenedor2,
  contenedor3,
  contenedor4,
  contenedor5,
} = styleChartVlp;
const data = [
  { liquidRate: 10, ref1: 50, ref2: 120, ref3: 70, ref4: 110, ref5: 90 },
  { liquidRate: 20, ref1: 60, ref2: 115, ref3: 80, ref4: 100, ref5: 85 },
  { liquidRate: 30, ref1: 70, ref2: 110, ref3: 85, ref4: 90, ref5: 80 },
  { liquidRate: 40, ref1: 85, ref2: 100, ref3: 90, ref4: 85, ref5: 75 },
  { liquidRate: 50, ref1: 100, ref2: 90, ref3: 95, ref4: 80, ref5: 70 },
  { liquidRate: 60, ref1: 110, ref2: 80, ref3: 100, ref4: 75, ref5: 65 },
  { liquidRate: 70, ref1: 120, ref2: 70, ref3: 110, ref4: 70, ref5: 60 },
  { liquidRate: 80, ref1: 130, ref2: 60, ref3: 120, ref4: 65, ref5: 55 },
];
const formatNumber = (value) => {
  // 2 decimal numbers format, except integers
  if (!isNaN(value) && value !== "") {
    const num = parseFloat(value);
    return num % 1 === 0 ? num : num.toFixed(2);
  }
  return value;
};

function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderColor: "#662D91",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <p style={{ color: "#000000", fontSize: "11px" }}>
          {formatNumber(data.ql_max) + ` (BBL/D)`}
        </p>
        <p style={{ color: "#873d01", fontSize: "11px" }}>
          {formatNumber(data.press_csg_avg) + ` (psia)`}
        </p>
        <p style={{ color: "#de1919", fontSize: "11px" }}>
          {formatNumber(data.final_press) + ` (psia)`}
        </p>
        <p style={{ color: "#8884d8", fontSize: "11px" }}>
          {formatNumber(data.data_inter) + ` (psia)`}
        </p>
        <p style={{ color: "#00bfff", fontSize: "11px" }}>
          {formatNumber(data.pc_current_plot) + ` (psia)`}
        </p>
        <p style={{ color: "#ff7f50", fontSize: "11px" }}>
          {formatNumber(data.pc_estim_rate_glr_plot) + ` (psia)`}
        </p>
        <p style={{ color: "#32cd32", fontSize: "11px" }}>
          {formatNumber(data.pc_estim_rate_pc_plot) + ` (psia)`}
        </p>
      </div>
    );
  }
  return null;
}


const ChartPlungerL = ({ dataCasingChart, dataPressChart, dataInterpolation }) => {
  const [dataGraph, setDataGraph] = useState([]);

  useEffect(() => {
    if (
      dataPressChart &&
      dataCasingChart &&
      dataInterpolation &&
      dataInterpolation.pc_current_plot
    ) {
      const { pc_current_plot, pc_estim_rate_glr_plot, pc_estim_rate_pc_plot } =
        dataInterpolation;

      if (
        Object.keys(dataPressChart).length &&
        Object.keys(dataCasingChart).length
      ) {
        const combinedData = {
          data_inter: dataPressChart.data_interpolation,
          final_press: dataPressChart.final_pressures,
          press_csg_avg: dataCasingChart.table_casing.map(
            (item) => item.press_csg_avg
          ),
          ql_max: dataCasingChart.table_casing.map((item) => item.ql_max),
          pc_current_plot,
          pc_estim_rate_glr_plot,
          pc_estim_rate_pc_plot,
        };

        const data = combinedData.data_inter.map((value, index) => ({
          id: index + 1,
          data_inter: combinedData.data_inter[index],
          final_press: combinedData.final_press[index],
          press_csg_avg: combinedData.press_csg_avg[index],
          ql_max: combinedData.ql_max[index],
          pc_current_plot: combinedData.pc_current_plot[index] || null,
          pc_estim_rate_glr_plot:
            combinedData.pc_estim_rate_glr_plot[index] || null,
          pc_estim_rate_pc_plot:
            combinedData.pc_estim_rate_pc_plot[index] || null,
        }));
        setDataGraph(data);
      }
    } else {
      setDataGraph([]);
    }
  }, [dataPressChart, dataCasingChart, dataInterpolation]);
  return (
    <div className={containerPrincipal}>
      <div className={containerGraph}>
        <ResponsiveContainer width="100%">
          <LineChart data={dataGraph}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              dataKey="ql_max"
              label={{
                value: `Liquid Rate (STB/D)`,
                position: "insideBottom ",
                style: { textAnchor: "middle" },
                dy: 10,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tickCount={15}
              tick={{
                fontSize: 12,
              }}
            />
            <YAxis
              label={{
                value: "Pressure (psia) at Mid-perf Depth, TVD",
                angle: -90,
                position: "insideLeft",
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
            />
            <Tooltip content={<CustomTooltip />} />

            <Line type="monotone" dataKey="press_csg_avg" stroke="#873d01" />
            <Line type="monotone" dataKey="final_press" stroke="#de1919" />
            <Line type="monotone" dataKey="data_inter" stroke="#8884d8" />
            <Line
              type="monotone"
              dataKey="pc_current_plot"
              stroke="#00bfff"
              dot
            />
            <Line
              type="monotone"
              dataKey="pc_estim_rate_glr_plot"
              stroke="#ff7f50"
              dot
            />
            <Line
              type="monotone"
              dataKey="pc_estim_rate_pc_plot"
              stroke="#32cd32"
              dot
            />
          </LineChart>
        </ResponsiveContainer>
        <div className={containerLegend}>
          <div className={container1}>
            <span className={styleRefenence1}>●</span> Optimum Performance
            (STB/D)
          </div>
          <span className={contenedor2}>
            <span className={styleRefenence2}>●</span> Surface Casing Pressure @
            BHP
          </span>
          <span className={contenedor3}>
            <span className={styleRefenence3}>●</span> Inflow Performance
            Relationship
          </span>
          <span className={contenedor4}>
            <span className={styleRefenence4}>●</span>Current performance
          </span>
          <div className={contenedor5}>
            <span className={styleRefenence5}>●</span> Estimated Performance by
            Casing Pressure
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartPlungerL;
