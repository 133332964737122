import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useFilters } from "../../../hooks/useFilters";
import { Loading } from "../../Loading";
// import { AuthContext } from "../../../auth/context/AuthContext";
// import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";
import { USER_FILTER } from "../../elements/types/type_dashboard";
import { FILTER_USER } from "../../elements/types/type_response_API";

export const FilterUser = ({ closeModal }) => {
	const [tempFilterValues, setTempFilterValues] = useState(USER_FILTER);
const {
	dataFilter,
	ErrorGetDataUser,
	getUserDataInfo,
	leases,
	loadGetDataUser,
	mutationCreateFilter,
	mutationUpdateFilter,
	operators,
	operatorsId,
	refetchGetDataFilter,
	refetchGetDataLeaase,
	refetchGetDataRegion,
	refetchGetDataUser,
	regions,
	setLeases,
	setOperatorsId,
	setRegions,
	User,
	userDataInfo,
} = useFilters();
	// const {
	// 	selectedWell,
	// 	selectedWellDCA,
	// 	selectedReservoirSCT,
	// 	selectedWellPL,
	// 	selectedWellInjector,
	// } = useContext(DashboardContext);// !esto queda para cuando se modifique el proceso de checks
	const { Option } = Select;

	const dataFilterUser =
		dataFilter?.statusType !== FILTER_USER?.TYPE?.ERROR ? dataFilter?.data : null;

	useEffect(() => {
		refetchGetDataUser();
		refetchGetDataFilter();
	}, []);
	useEffect(() => {
		setTempFilterValues({
			operator: userDataInfo?.operator || "",
			lease: userDataInfo?.lease || "",
			region: userDataInfo?.region || "",
		});
	}, [userDataInfo]);

	const handleChangeSelect = (nameData, valueData) => {
		if (nameData === "operator" && valueData !== "" && valueData !== undefined) {
			const OperatorSelected = operators.find(
				(item) => item.idOperator === valueData,
			);
			if (OperatorSelected.Operator !== userDataInfo.operator) {
				setRegions([]);
				setLeases([]);
				setOperatorsId(valueData);
				setTimeout(() => {
					refetchGetDataUser();
					refetchGetDataLeaase();
					refetchGetDataRegion();
				}, 1000);
				setTempFilterValues({
					operator: OperatorSelected.Operator,
					lease: "",
					region: "",
				});
			}
		} else if (
			nameData === "lease" &&
			valueData !== "" &&
			valueData !== undefined
		) {
			const LeaseSelected = leases.find((item) => item.idproyect === valueData);
			setTempFilterValues((prev) => ({
				...prev,
				lease: LeaseSelected.proyect,
			}));
		} else if (
			nameData === "region" &&
			valueData !== "" &&
			valueData !== undefined
		) {
			const RegionSelected = regions.find((item) => item.idplatform === valueData);
			setTempFilterValues((prev) => ({
				...prev,
				region: RegionSelected.platform,
			}));
		}
	};
	const handleSaveFilter = () => {
		if (!isFilterEmpty(tempFilterValues)) {
			getUserDataInfo(tempFilterValues);
			const baseFilterData = {
				operator: tempFilterValues.operator,
				lease: tempFilterValues.lease,
				region: tempFilterValues.region,
			};

			if (isFilterEmpty(dataFilterUser)) {
				//agregar filtros
				const dataFilterCreate = {
					...baseFilterData,
					// selectedWellDCA: "",// !esto queda para cuando se modifique el proceso de checks
					// selectedWellGaslift: "",
					// selectedWellScreeningTool: "",
					// selectedWellPlungerLift: "",
					// checkedWellInjector: "",
				};
				setTimeout(() => {
					mutationCreateFilter.mutate({
						user: User.user_name,
						data: dataFilterCreate,
					});
				}, 1000);
				getUserDataInfo(dataFilterCreate);
			} else {
				//actualizar filtro
				const dataFilterUpdate = {
					...baseFilterData,
					// checkedWellDCA: selectedWellDCA || "",// !esto queda para cuando se modifique el proceso de checks
					// checkedWellGaslift: selectedWell || "",
					// checkedWellScreeningTool: selectedReservoirSCT || "",
					// checkedWellPlungerLift: selectedWellPL || "",
					// checkedWellInjector: selectedWellInjector || "",
				};
				setTimeout(() => {
					mutationUpdateFilter.mutate({
						user: User.user_name,
						data: dataFilterUpdate,
					});
				}, 1000);
				getUserDataInfo(dataFilterUpdate);
			}
		}
	};
	const handleCancel = () => {
		const { operator, lease, region } = dataFilterUser ?? {};

		const isComplete =
			operator &&
			operator !== "" &&
			lease &&
			lease !== "" &&
			region &&
			region !== "";

		const isDifferent =
			operator !== userDataInfo.operator ||
			lease !== userDataInfo.lease ||
			region !== userDataInfo.region;

		if (isComplete && isDifferent) {
			getUserDataInfo({ operator, lease, region });
		}

		if (closeModal) closeModal();
	};
	if (loadGetDataUser) {
		return (
			<div className="absolute w-full h-full">
				<Loading></Loading>
			</div>
		);
	}
	const isFilterEmpty = (filters) => {
		return (
			!filters?.operator ||
			filters.operator.trim() === "" ||
			!filters?.lease ||
			filters.lease.trim() === "" ||
			!filters?.region ||
			filters.region.trim() === ""
		);
	};
	if (ErrorGetDataUser) {
		return <div>Error</div>;
	}
	return (
		<div className="flex flex-col w-full h-full">
			<div className="flex flex-col items-center justify-center w-full h-full gap-4 text-center">
				<div className="w-full max-w-[592px]">
					<label
						htmlFor=""
						className="flex items-start justify-start mb-2 font-sans text-lg text-left text-pa-purple"
					>
						Operator
					</label>
					<Select
						className="w-full h-[47px] text-left"
						name="operator"
						value={tempFilterValues.operator}
						onChange={(value) => handleChangeSelect("operator", value)}
						defaultValue={""}
						defaultOpen={""}
					>
						{operators?.map((item, index) => (
							<Option key={index} value={item.idOperator}>
								{item.Operator}
							</Option>
						))}
					</Select>
				</div>
				<div className="w-full max-w-[592px]">
					<label
						htmlFor=""
						className="flex items-start justify-start mb-2 font-sans text-lg text-left text-pa-purple"
					>
						Lease
					</label>
					<Select
						disabled={operatorsId === null ? true : false}
						className="w-full max-w-[592px] h-[47px] text-left"
						name="lease"
						value={tempFilterValues.lease}
						onChange={(value) => handleChangeSelect("lease", value)}
					>
						{leases?.map((item, index) => (
							<Option key={index} value={item.idproyect}>
								{item.proyect}
							</Option>
						))}
					</Select>
				</div>
				<div className="w-full max-w-[592px]">
					<label
						htmlFor=""
						className="flex items-start justify-start mb-2 font-sans text-lg text-left text-pa-purple"
					>
						Region
					</label>
					<Select
						disabled={operatorsId === null ? true : false}
						className="w-full max-w-[592px] h-[47px] text-left"
						name="region"
						value={tempFilterValues.region}
						onChange={(value) => handleChangeSelect("region", value)}
					>
						{regions?.map((item, index) => (
							<Option key={index} value={item.idplatform}>
								{item.platform}
							</Option>
						))}
					</Select>
				</div>
			</div>
			<div className="flex justify-end w-full mt-4 ">
				<button
					onClick={handleCancel}
					className="h-8 px-4 py-1 text-black border border-gray-300 rounded-md hover:bg-gray-100"
				>
					Cancel
				</button>
				<button
					onClick={() => {
						handleSaveFilter();
						if (closeModal) closeModal();
					}}
					disabled={isFilterEmpty(tempFilterValues)}
					className={`px-4 py-1 rounded-md ml-2 h-8 ${
						isFilterEmpty(tempFilterValues)
							? "bg-[#C3A1DC] text-white cursor-not-allowed"
							: "bg-[#662D91] text-white hover:bg-[#4096FF]"
					}`}
				>
					OK
				</button>
			</div>
		</div>
	);
};
