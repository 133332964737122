import React, { useEffect, useState } from "react";
import { INITIAL_STATE_PROCESS } from "../components/elements/types/type_gas_lift";
import { WELL_FILTER } from "../components/elements/types/type_dashboard";

const initialViewLoadingGasLift = {
	loadSaveParameters: false,
	loadDataInOutFlow: false,
	loadDataMultiWell: false,
};

const initialValidateAlertGasLift = {
	alertCard: false,
	alertDomain: false,
};

export const useDataGasLift = () => {
	const [dataCreated, setDataCreated] = useState([]);
	const [dataFilterUserGasLift, setDataFilterUserGasLift] =
		useState(WELL_FILTER);
	const [enableCheckLab, setEnableCheckLab] = useState(false);
	const [lastSelectDataIPR, setLastSelectDataIPR] = useState();
	const [viewLoadingGasLift, setViewLoadingGasLift] = useState(
		initialViewLoadingGasLift,
	);
	const [validateAlertGasLift, setValidateAlertGasLift] = useState(
		initialValidateAlertGasLift,
	);
	const [stateMessageApis, setStateMessageApi] = useState(INITIAL_STATE_PROCESS);
	const [stateCheckMultiWell, setStateCheckMultiWell] = useState([]);
	const [dataChartReservoir, setDataChartReservoir] = useState(null);
	const [dataChartVLP, setDataChartVLP] = useState(null);

	useEffect(() => {
		const getDataCreated = JSON.parse(sessionStorage.getItem("dataCreated"));
		const getDataFilterUserGaslift = JSON.parse(
			sessionStorage.getItem("dataFilterUserGaslift"),
		);
		const getEnableCheckLab = sessionStorage.getItem("enableCheckLab");
		const getSelectDataIPR = sessionStorage.getItem("lastSelectDataIPR");

		if (getDataCreated) {
			setDataCreated(getDataCreated);
		}
		if (getDataFilterUserGaslift) {
			setDataFilterUserGasLift(getDataFilterUserGaslift);
		}
		if (getEnableCheckLab) {
			setEnableCheckLab(getEnableCheckLab);
		}
		if (getSelectDataIPR) {
			setLastSelectDataIPR(getSelectDataIPR);
		}
	}, []);

	const getDataCreated = (options) => {
		setDataCreated(options);
		sessionStorage.setItem("dataCreated", JSON.stringify(options));
	};

	const getDataFilterUserGaslift = (options) => {
		setDataFilterUserGasLift(options);
		sessionStorage.setItem("dataFilterUserGaslift", JSON.stringify(options));
	};

	const handleSelectWellGaslift = (wellId) => {
		setDataFilterUserGasLift(wellId);
		sessionStorage.setItem("dataFilterUserGaslift", wellId);
	};

	const getEnableCheckLab = (options) => {
		setEnableCheckLab(options);
		sessionStorage.setItem("enableCheckLab", options);
	};

	const getSelectDataIPR = (options) => {
		setLastSelectDataIPR(options);
		sessionStorage.setItem("lastSelectDataIPR", JSON.stringify(options));
	};

	const getViewLoadingGasLift = (options) => {
		setViewLoadingGasLift(options);
	};

	const getValidateAlertGasLift = (options) => {
		setValidateAlertGasLift(options);
	};

	const getStateMessageAlert = (options) => {
		setStateMessageApi(options);
	};

	const getStateCheckMultiWell = (options) => {
		setStateCheckMultiWell(options);
	};

	const getDataChartReservoir = (options) => {
		setDataChartReservoir(options);
	};

	const getDataChartVLP = (options) => {
		setDataChartVLP(options);
	};

	return {
		dataCreated,
		dataFilterUserGasLift,
		lastSelectDataIPR,
		viewLoadingGasLift,
		enableCheckLab,
		validateAlertGasLift,
		stateMessageApis,
		stateCheckMultiWell,
		dataChartReservoir,
		dataChartVLP,
		getDataCreated,
		getDataFilterUserGaslift,
		handleSelectWellGaslift,
		getEnableCheckLab,
		getSelectDataIPR,
		getViewLoadingGasLift,
		getValidateAlertGasLift,
		getStateMessageAlert,
		getStateCheckMultiWell,
		getDataChartReservoir,
		getDataChartVLP,
	};
};
