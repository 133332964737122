import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_MULTI_WELL = config.API.MULTI_WELL.URL_MULTI_WELL;
const TOKEN_MULTI_WELL = config.AUTH.TOKEN_MULTI_WELL;

export const createWellTranding = async (dataJson) => {
	const { operator, lease, region, username, data } = dataJson;
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/upload_test_information/${operator}/${lease}/${region}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_MULTI_WELL,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem creating parameters multi well trending");
	}
};

export const updateWellTranding = async (dataJson) => {
	const { operator, lease, region, username, data } = dataJson;
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/update_test_information/${operator}/${lease}/${region}/${username}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_MULTI_WELL,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating parameters multi well trending");
	}
};

export const getPressureChanges = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/press_changes/${operator}/${lease}/${region}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_MULTI_WELL,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting parameters gas lift");
	}
};

export const getPressureDrawDown = async (
	operator,
	lease,
	region,
	username,
) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/press_drow_down/${operator}/${lease}/${region}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_MULTI_WELL,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting parameters gas lift");
	}
};

export const getProductivityIndex = async (
	operator,
	lease,
	region,
	username,
) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/productivity_index/${operator}/${lease}/${region}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_MULTI_WELL,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting parameters gas lift");
	}
};

export const getFluidType = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/fluid_type/${operator}/${lease}/${region}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_MULTI_WELL,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting parameters gas lift");
	}
};

export const getListMultiWell = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_MULTI_WELL}/data_test_information/${operator}/${lease}/${region}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_MULTI_WELL,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting parameters gas lift");
	}
};
