import { useId, useState } from "react";
import { ReactComponent as IconAdd } from "../../../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../../../assets/img/icons/delete.svg";
import { Select, Tooltip } from "antd";

import classes from "./Table.module.css";
import { InfoCircleOutlined } from "@ant-design/icons";
const {
  table,
  table__container,
  table__wrapper,
  table__content,
  table__header,
  "table__header-cell": table__headerCell,
  table__filter,
  "table__filter-icon": table__filterIcon,
  table__body,
  table__row,
  table__cell,
  table__input,
  table__select,
  option,
  customSelect,
  "table__cell--empty": table__cell_empty,
  table__actions,
  table__button,
  table__headerId,
  table__headerButtonCheckbox,
  table__headerButtons,
  table__Numeration,
  "table__input--error": table__input_error,
  "table__input--normal": table__input_normal,
  "table__select--error": table__select_error,
  "table__select--normal": table__select_normal,
  "table__cell--checkbox": table__cell_checkbox,
  table__checkbox,
} = classes;
const { Option } = Select;
const Table = ({
  headers = [
    { name: "header 1", filter: { type: "number", name: "col1" } },
    { name: "header 2", filter: { type: "text", name: "col2" } },
  ],
  data = [
    { "header 1": 1, "header 2": 2 },
    { "header 1": "a", "header 2": "b" },
  ],
  hiddenColumns = [],
  minRows = 3,
  maxRows = null,
  selectableRow = false,
  onSelectRow = undefined,
  onChange = (e) => {},
  onBlur = undefined,
  onSubmit = (e) => e.preventDefault(),
  onAddRow = undefined,
  onDeleteRow = () => {},
  numeracion = true,
  validationError = false,
  filtro = true,
}) => {
  const idForm = useId();
  const [showFilters, setShowFilters] = useState(false);

  const handleCellChange = (rowIndex, key, newValue) => {
    if (onChange) {
      const fakeEvent = {
        target: { name: key, value: newValue },
      };
      onChange(fakeEvent, data[rowIndex].id);
    }
  };

  return (
			<form className={table} onSubmit={onSubmit} onChange={onChange}>
				<div className={table__container}>
					<div className={table__wrapper}>
						<table className={table__content}>
							<thead className={table__header}>
								<tr>
									{selectableRow && <th className={table__headerButtonCheckbox}></th>}

									<th className={table__headerId}></th>

									{headers.map(({ name, tooltip, width }, idx) => (
										<th key={idx} className={table__headerCell} style={{ width }}>
											{name}
											{tooltip && (
												<Tooltip title={tooltip} >
													<InfoCircleOutlined
														// className="ml-1"
														style={{ color: "rgba(0, 0, 0, 0.45)" }}
													/>
												</Tooltip>
											)}
										</th>
									))}
									<th className={table__headerButtons}></th>
								</tr>
							</thead>
							<tbody className={table__body}>
								{!data.length ? (
									<tr className={table__row}>
										<td
											colSpan={headers.length + 1}
											className={`${table__cell} ${table__cell_empty}`}
										>
											No Data
										</td>
									</tr>
								) : (
									data.map((row, idxRow) => (
										<tr
											key={idxRow}
											className={table__row}
											// onClick={(e) => onSelectRow?.(idxRow, e)} por si se quiere ver el onselect cuando se oprima en cualquier lado de la tabla
										>
											{selectableRow && (
												<td className={`${table__cell} ${table__cell_checkbox}`}>
													<input
														type="checkbox"
														onChange={() => onSelectRow?.(row.id, row)}
														className={table__checkbox}
													/>
												</td>
											)}
											<td>
												{numeracion ? (
													<p className={table__Numeration}>{idxRow + 1}</p>
												) : (
													""
												)}
											</td>

											{headers
												.filter(({ key }) => !hiddenColumns.includes(key || ""))
												.map(({ name, key, inputType, type, options, width }, inxDV) => {
													const dataValue = row[key] ?? "";

													return (
														<td
															key={inxDV}
															className={table__cell}
															data-label={name}
															// style={{ width }}
														>
															{inputType === "select" ? (
																<>
																	<Select
																		className={table__select}
																		value={dataValue}
																		onChange={(value) => handleCellChange(idxRow, key, value)}
																	>
																		{options.map((option, index) => (
																			<Option key={index} value={option.toLowerCase()}>
																				{option.charAt(0).toUpperCase() +
																					option.slice(1).toLowerCase()}
																			</Option>
																		))}
																	</Select>
																</>
															) : (
																<input
																	type={type || "text"}
																	className={`${table__input} ${
																		validationError && (dataValue === "" || dataValue === null)
																			? table__input_error
																			: table__input_normal
																	}`}
																	value={dataValue}
																	onChange={(e) => handleCellChange(idxRow, key, e.target.value)}
																	onBlur={(e) => onBlur?.(e, row.id)}
																/>
															)}
														</td>
													);
												})}
											<td className={`${table__cell} ${table__actions}`}>
												{data.length > minRows && (
													<IconDelete
														className={table__button}
														onClick={() => onDeleteRow?.(row.id)}
													/>
												)}

												{idxRow === data.length - 1 &&
													(maxRows === null || data.length < maxRows) && (
														<IconAdd className={table__button} onClick={onAddRow} />
													)}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
				</div>
			</form>
		);
};

export default Table;
