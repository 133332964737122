import React, { useContext, useEffect, useState } from "react";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { FormUser } from "../../components/User_Control/FormUser";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useUsers } from "../../hooks/useUsers";

export const Register = () => {
  const { getDataPage } = useContext(DashboardContext);
  const {
    idDataClient,
    refetchClientsProjects,
    refetchClientsPlatforms,
    refetchDataOptionRegions,
    refetchDataOptionLeases,
    refetchDataOptionOperators,
  } = useUsers();
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  useEffect(() => {
    getDataPage(NAME_PAGES.user.register.create);
    refetchDataOptionRegions();
    refetchDataOptionLeases();
    refetchDataOptionOperators(); 
  }, []);

  useEffect(() => {
    if (idDataClient) {
      refetchClientsProjects();
      refetchClientsPlatforms();
    }
  }, [idDataClient]);

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="absolute flex h-full "></Sidebar>
      </div>
      <div className="flex flex-row w-full h-full ">
        <div className="flex w-1/3 h-full pl-[88px] pb-10">
          <FormUser type={"register"} ></FormUser>
        </div>
      </div>
    </div>
  );
};
