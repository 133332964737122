import { InfoCircleOutlined } from '@ant-design/icons';
import { message, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertModal } from "../../components/AlertModal";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { useTabPlungerLift } from '../../hooks/useTabPlungerLift';
import ChartVogelPL from '../../components/PlungerLift/GraphicsReservoir/ChartVogelPL';
import ChartCompositePL from '../../components/PlungerLift/GraphicsReservoir/ChartCompositePL';
import { DATA_RESERVOIR_PLUNGER_LIFT } from '../../components/elements/types/type_plunger_Lift';
import { STATUS_CODE_PLUNGER_LIFT } from '../../components/elements/types/type_response_API';
import { NAME_PAGES } from '../../components/elements/types/type_pages';

export const StepMenuEditReservoirPlungerLift = () => {
  const navigate = useNavigate();
  const {
    User,
    userDataInfo,
    dataFilterUserPL,
    setViewLoading,
    mutationSaveDataIPR,
    dataTableIPRPL, 
    inputReservoir,
    setInputReservoir,
    listqIPR,
    refetchDataWellPL,
    dataWellPL,
    mutationUpdateWellPlungerLift
  } = useTabPlungerLift();
  const [ wellboreOptionState, setWellboreOptionState ] = useState(false);
  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };
  const { Option } = Select;
  const [ selectedValue, setSelectedValue ] = useState("");
  const [ showAlert, setShowAlert ] = useState(false);
  const [ messageAlert, setMessageAlert ] = useState(false);
  const [ typeAlert, setTypeAlert ] = useState(false);
  const [ status, setStatus ] = useState(false);
  const [ inputEmpty, setInputEmpty ] = useState(false);
  const [ dataInformationEnd, setDataInformationEnd ] = useState();
  const [ bodyIPR, setBodyIPR ] = useState([])
  const [ disabledCheck, setDisabledCheck ] = useState([false,false,false, false]);

  useEffect(() => {
    refetchDataWellPL();
    if (inputReservoir.state_reservoir === 'saturated') {
      setSelectedValue('saturated')
    } else {
      setSelectedValue('undersaturated')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (dataWellPL?.statusCode === STATUS_CODE_PLUNGER_LIFT.SUCCESS && dataWellPL?.data) {
        setViewLoading(true);
        const filterDataCreate = dataWellPL?.data;
        const filterDataEnd = DATA_RESERVOIR_PLUNGER_LIFT.initialInputReservoir
        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
        );

        setDataInformationEnd(filteredJson);

        setInputReservoir((prevState) => ({
          ...prevState,
          layer_pressure: filterDataCreate?.changeData?.res_pressure ? Number(filterDataCreate?.res_pressure) : filterDataCreate?.layer_pressure,
          layer_temperature: filterDataCreate?.changeData?.res_temperature ? Number(filterDataCreate?.res_temperature) : filterDataCreate?.layer_temperature,
          md_perf_depth: filterDataCreate?.md_perf_depth,
          water_cut_fraction: filterDataCreate?.water_cut_fraction,
          state_reservoir: filterDataCreate?.state_reservoir,
          ipr_model: filterDataCreate?.ipr_model,
          test_pressure_1_s: filterDataCreate?.test_pressure_1_s,
          test_flow_rate_1_s: filterDataCreate?.test_flow_rate_1_s,
          test_pressure_2_s: filterDataCreate?.test_pressure_2_s,
          test_flow_rate_2_s: filterDataCreate?.test_flow_rate_2_s,
          productivity_index_j_s: filterDataCreate?.productivity_index_j_s,
          liquid_flow_rate_s: filterDataCreate?.liquid_flow_rate_s,
          test_pressure_1_us: filterDataCreate?.test_pressure_1_us,
          test_flow_rate_1_us: filterDataCreate?.test_flow_rate_1_us,
          test_pressure_2_us: filterDataCreate?.test_pressure_2_us,
          test_flow_rate_2_us: filterDataCreate?.test_flow_rate_2_us,
          productivity_index_j_us: filterDataCreate?.productivity_index_j_us,
          liquid_flow_rate_us: filterDataCreate?.liquid_flow_rate_us,
          check_phases: filterDataCreate?.check_phases,
          pwf_ipr: filterDataCreate?.pwf_ipr,
          straight_line: filterDataCreate?.straight_line,
          bubblePointPressure: filterDataCreate?.changeData?.bubble_point_pressure ? Number(filterDataCreate?.bubble_point_pressure) : Number(filterDataCreate?.bubblePointPressure)
        }));

        setSelectedValue(filterDataCreate?.state_reservoir);

        filterDataCreate?.pwf_ipr && filterDataCreate?.straight_line ? setActiveChecks([0, 1]) : filterDataCreate?.pwf_ipr ? setActiveChecks([...ActiveChecks, 0]) : filterDataCreate?.straight_line ? setActiveChecks([...ActiveChecks, 1]) : setActiveChecks([]);
        setTimeout(() => {
          setViewLoading(false)
        }, 200);
      }
    }, 500);
  }, [dataWellPL]);

  const handleChangeSelect = (name, valueData) => {
    setSelectedValue(valueData);
    const customEvent = {
      target: {
        name: name,
        value: valueData,
      }
    };
    handleChangeDataReservoir(customEvent);

    let updatedLayerPressure = '';
    if ((valueData === 'saturated' && Number(inputReservoir.layer_pressure) < Number(inputReservoir.bubblePointPressure)) ||
      (valueData === 'undersaturated' && Number(inputReservoir.layer_pressure) > Number(inputReservoir.bubblePointPressure))) {
      updatedLayerPressure = inputReservoir.layer_pressure;
    }

    setInputReservoir((prevState) => ({
      ...prevState,
      test_pressure_1_s: '',
      test_flow_rate_1_s: '',
      test_pressure_2_s: '',
      test_flow_rate_2_s: '',
      test_pressure_1_us: '',
      test_flow_rate_1_us: '',
      test_pressure_2_us: '',
      test_flow_rate_2_us: '',
      productivity_index_j_us: '',
      productivity_index_j_s: '',
      layer_pressure: updatedLayerPressure,
      ipr_model: '',
      check_phases: false,
    }))
  };

  const handleChangeSelect2 = (name, valueData) => {
    const customEvent = {
      target: {
        name: name,
        value: valueData,
      },
    };
    handleChangeDataReservoir(customEvent);
  };
  const handleCalculate = () => {

    const validateState  = inputReservoir.state_reservoir === "saturated" && inputReservoir.layer_pressure <= inputReservoir.bubblePointPressure ? true : inputReservoir.state_reservoir === "undersaturated" && (inputReservoir.layer_pressure >= inputReservoir.bubblePointPressure) ? true : false 

    if (((inputReservoir.productivity_index_j_s === '' || inputReservoir.productivity_index_j_us === '') 
      && (!!inputReservoir.check_phases)
      && inputReservoir.ipr_model != '' 
      && (!!inputReservoir.layer_pressure ) 
      && inputReservoir.state_reservoir != '') 
      && inputReservoir.bubblePointPressure != null) {
      if (bodyIPR && Object.keys(bodyIPR).length > 0 ) {
        
        setTimeout(() => {
          mutationSaveDataIPR.mutate({
            operator: userDataInfo.operator,
            lease: userDataInfo.lease,
            region: userDataInfo.region,
            county: dataFilterUserPL?.county,
            field: dataFilterUserPL?.field,
            well: dataFilterUserPL?.well_id,
            username: User.user_name,
            data: bodyIPR,
            type: "parametersplungerlift"
          });
        }, 100);
        setInputEmpty(false)
      }else {
        setInputEmpty(true)
        message.warning('Sorry, verify the form and try again')
      }
    } else {
      setInputEmpty(true)
      message.warning('Sorry, verify the form and try again')
    }

  }
  
  const handleClickDone = () => {
    let validateInitial = false;
    if (
      inputReservoir.layer_pressure === "" ||
      inputReservoir.layer_temperature === "" ||
      inputReservoir.md_perf_depth === "" ||
      inputReservoir.water_cut_fraction === ""
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "There are empty fields please validate that the information is complete"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    } else {
      validateInitial = true;
    }

    if (inputReservoir.state_reservoir === "saturated") {
      if (inputReservoir.productivity_index_j_s != '' && 
        inputReservoir.test_pressure_1_s === '' &&
        inputReservoir.test_pressure_2_s === '' && 
        inputReservoir.ipr_model != '' &&
        (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined) &&
        inputReservoir.test_flow_rate_1_s === '' &&
        inputReservoir.test_flow_rate_2_s === '') {
        validateInitial = true
      } 
      else if (
        (inputReservoir.productivity_index_j_s === '' && (inputReservoir.test_pressure_1_s != '' && inputReservoir.test_pressure_1_s != '')) 
        || 
        (inputReservoir.productivity_index_j_s === '' && (inputReservoir.test_pressure_1_s != '' && inputReservoir.test_pressure_1_s != '') && inputReservoir.test_pressure_2_s != '' && inputReservoir.test_flow_rate_2_s != '') &&
        inputReservoir.ipr_model != '' && 
        (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined)
      ) {
        validateInitial = true
      }
      else {
        handleOpenModal();
        setTypeAlert("Warning");
        setMessageAlert(
          "There are empty fields please validate that the information is complete"
        );
        setInputEmpty(true);
        handleStatusChange();
        validateInitial = false;
      }
    }
    if (inputReservoir.state_reservoir === "undersaturated") {
        if (inputReservoir.productivity_index_j_us !== '' &&
          inputReservoir.test_pressure_1_us === '' &&
          inputReservoir.test_pressure_2_us === '' &&
          inputReservoir.ipr_model !== '' &&
          (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined) &&
          inputReservoir.test_flow_rate_1_us === '' &&
          inputReservoir.test_flow_rate_2_us === '') {
          validateInitial = true;
        } 
        else if (
          (inputReservoir.productivity_index_j_us === '' && (inputReservoir.test_pressure_1_us != '' && inputReservoir.test_pressure_1_us != '')) 
          || 
          (inputReservoir.productivity_index_j_us === '' && (inputReservoir.test_pressure_1_us != '' && inputReservoir.test_pressure_1_us != '') && inputReservoir.test_pressure_2_us != '' && inputReservoir.test_flow_rate_2_us != '') &&
          inputReservoir.ipr_model != '' && 
          (inputReservoir.check_phases != '' ||  inputReservoir.check_phases !== undefined)
        ) {
          validateInitial = true;
        } 
        else {
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert("There are empty fields please validate that the information is complete");
          setInputEmpty(true);
          handleStatusChange();
          validateInitial = false;
        }
      
    }
    // if (inputReservoir.bubblePointPressure != dataWellGasLift?.data?.bubble_point_pressure_lab) {
    if (!inputReservoir.bubblePointPressure) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The bubble point can't be diferent t the Fluid bubble point value"
      );
      setInputEmpty(true);
      setInputReservoir({...inputReservoir, bubblePointPressure: null})
      handleStatusChange();
      validateInitial = false;

    } else if (inputReservoir.state_reservoir === 'saturated' && (Number(inputReservoir.layer_pressure) > Number(inputReservoir.bubblePointPressure))) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The layer pressure can't be greater than bubble point"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    } else if (inputReservoir.state_reservoir === 'undersaturated' && (Number(inputReservoir.layer_pressure) < Number(inputReservoir.bubblePointPressure))) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert(
        "The layer pressure can't be less than bubble point"
      );
      setInputEmpty(true);
      handleStatusChange();
      validateInitial = false;
    }
    if (validateInitial === true) {
      setInputEmpty(false);
      const filterDataEnd = Object.assign({}, inputReservoir, dataInformationEnd);
      setTimeout(() => {
        mutationUpdateWellPlungerLift.mutate({ operator: userDataInfo.operator, 
          lease: userDataInfo.lease, 
          region: userDataInfo.region, 
          county: dataFilterUserPL.county, 
          field: dataFilterUserPL.field, 
          well: dataFilterUserPL.well_id, 
          username: User.user_name, 
          data: filterDataEnd,
          type: "parametersplungerlift" 
        })
      }, 100);
      navigate(`/${NAME_PAGES.plunger_lift.list_well}`);
      message.success("Processing complete!");
    }
  };

  useEffect(() => {
    const buildDataReservoirBody = () => {
      const reservoirType = inputReservoir.state_reservoir;
      const isSaturated = reservoirType === 'saturated';
      const Pb = Number(inputReservoir.bubblePointPressure)
      if ((inputReservoir.productivity_index_j_s === '' && isSaturated) || (inputReservoir.productivity_index_j_us === '' && !isSaturated)) {
        const pr = Number(inputReservoir.layer_pressure) || 0; // Asumiendo que también quieres capturar si este campo está vacío
        const w_cut = Number(inputReservoir.water_cut_fraction) || 0;
        let qliq_test = null;
        const pwfTest = isSaturated
          ? [
            Number(inputReservoir.test_pressure_1_s) || 0,
            Number(inputReservoir.test_pressure_2_s) ? Number(inputReservoir.test_pressure_2_s) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0)
          : [
            Number(inputReservoir.test_pressure_1_us) || 0,
            Number(inputReservoir.test_pressure_2_us) ? Number(inputReservoir.test_pressure_2_us) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0);
        const qoTest = isSaturated
          ? [
            Number(inputReservoir.test_flow_rate_1_s) || 0,
            Number(inputReservoir.test_flow_rate_2_s) ? Number(inputReservoir.test_flow_rate_2_s) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0)
          : [
            Number(inputReservoir.test_flow_rate_1_us) || 0,
            Number(inputReservoir.test_flow_rate_2_us) ? Number(inputReservoir.test_flow_rate_2_us) : 0 // Si está vacío, asigna 0
          ].filter(value => value !== 0);

          if(isSaturated){
            if(inputReservoir.check_phases === "three" ){
              qliq_test= Number(inputReservoir.liquid_flow_rate_s)
            }
          }
          else{
            if(inputReservoir.check_phases === "three" ){
              qliq_test= Number(inputReservoir.liquid_flow_rate_us)
            }
          }

        const body = !isSaturated ? {
          pr,
          pb: Pb,
          j_index: null,
          pwf_test: pwfTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          qo_test: qoTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          reservoir_type: reservoirType,
          phase:inputReservoir.check_phases,
          w_cut: w_cut,
          qliq_test
        } : {
          pr,
          pb: Pb,
          j_index: null,
          pwf_test: pwfTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          qo_test: qoTest.map(value => value || 0), // Asegura que cualquier valor nulo o vacío se convierta en 0
          reservoir_type: reservoirType,
          phase:inputReservoir.check_phases,
          w_cut: w_cut,
          qliq_test 
        };
        setBodyIPR(body)
      } else {
        const Pr = Number(inputReservoir.layer_pressure) || 0;
        const w_cut = Number(inputReservoir.water_cut_fraction) || 0;
        const J = isSaturated
          ? Number(inputReservoir.productivity_index_j_s)
          : Number(inputReservoir.productivity_index_j_us);
        let pwfTest, qoTest, qliqtest;
        if ((isSaturated && J) || (!isSaturated && J)) {
          if (isSaturated) {
            const Pwf = Pr - 100;
            const q = (J * (Pr / 1.8)) * (1 - 0.2 * (Pwf / Pr) - 0.8 * ((Pwf / Pr) ** 2))
            const fw = w_cut / 100;
            const fo = 1 - fw;
            const A = (1 - 0.2 * (Pwf / Pb) - 0.8 * (Pwf / Pb)^2)
            const qlistValue = J * (fo * (Pr - Pb + (Pb * A / 1.8)) + fw * (Pr - Pwf))

            qliqtest = qlistValue;
            pwfTest = [Pwf];
            qoTest = [q];

            if (inputReservoir.check_phases === "three") {
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_s),
                pwf_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            } else {
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_s),
                pwf_test: pwfTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: qoTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase: inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            }

          } 
          else { // Reservorio subsaturado
            if (inputReservoir.check_phases === "one") {
              const Pwf = Pr - 100;
              const q = J * 100;
              pwfTest = [Pwf];
              qoTest = [q];
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: pwfTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: qoTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase:inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            } else if (inputReservoir.check_phases === "two") { // two-phase
              const Pwf = Number(Pb);
              const q = J * (Pr - Pwf);
              pwfTest = [Pwf];
              qoTest = [q];

              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: pwfTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: qoTest, // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase:inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            } else {
              const Pwf = Number(Pb);
              const q = J * (Pr - Pwf);
              pwfTest = [Pwf];
              qoTest = [q];
              qliqtest = J * (Pr - Pb);
              
              const body = {
                pr: Pr,
                pb: Pb,
                j_index: Number(inputReservoir.productivity_index_j_us),
                pwf_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                qo_test: [], // Asegura que cualquier valor nulo o vacío se convierta en 0
                reservoir_type: reservoirType,
                phase:inputReservoir.check_phases,
                w_cut: w_cut,
                qliq_test: null
              };
              setBodyIPR(body)
            }
          }
        }
      }
    }

    buildDataReservoirBody();
  }, [inputReservoir, inputReservoir.bubblePointPressure])

  const handleChangeDataReservoir = (e) => {
    const { name, value, checked } = e.target;
    if (inputReservoir.state_reservoir === 'saturated') {
      evaluateConditions("saturated", value);
      if (name === 'check_phases') {
        if (value != '' && value != undefined ) {
          setInputReservoir((prevState) => ({
            ...prevState,
            check_phases: value,
            test_pressure_1_s: '',
            test_pressure_2_s: '',
            test_flow_rate_1_s: '',
            test_flow_rate_2_s: '',
            ipr_model: '',
            productivity_index_j_s: '',
          }))
        }
      }
      else if (name === 'productivity_index_j_s') {
        // Cuando 'productivity_index_j_s' es modificado directamente
        if (value !== '') {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_s: value,
            test_pressure_1_s: '',
            test_pressure_2_s: '',
            test_flow_rate_1_s: '',
            test_flow_rate_2_s: '',
            ipr_model: '',
            liquid_flow_rate_s: ''
          }));
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_s: '',
            // No vaciamos los otros campos aquí porque el usuario está vaciando este campo específicamente
          }));
        }
      } else if ( name === 'layer_pressure' && inputReservoir.test_pressure_1_s > (Number(value))) {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        setTimeout(() => {
          setInputReservoir((prevState) => ({
            ...prevState,
            test_pressure_1_s: "",
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
          setInputEmpty(true)
          message.warning(`Sorry, the test can't be greater than layer pressure`)
        }, 1500);
      } else if (name === 'water_cut_fraction' && (Number(value) < 0 || Number(value) > 100)) {
        setInputReservoir((prevState) => ({
          ...prevState,
          water_cut_fraction: null,
        }));
        setInputEmpty(true)
        message.warning(`Sorry, the value can't be ${Number(value) < 0 ? 'less than 0' : 'greatter than 100'} `)
      }
      else if (
        name === 'test_pressure_1_s' ||
        name === 'test_pressure_2_s' ||
        name === 'test_flow_rate_1_s' ||
        name === 'test_flow_rate_2_s'
      ) {
        if (name === 'test_pressure_1_s' && (Number(value) > inputReservoir.layer_pressure)) {
          // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: "",
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
          setInputEmpty(true)
          message.warning(`Sorry, the test can't be greater than layer pressure`)
        } else if (name === 'test_pressure_2_s' && (Number(value) > inputReservoir.test_pressure_1_s)) {
          // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: "",
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
          message.warning(`Sorry, the test can't be greater than test 1`)
          setInputEmpty(true)
        } else {
          // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' && { productivity_index_j_s: '', ipr_model: '' }),
          }));
        }
      } else if (name === 'liquid_flow_rate_s'){
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value
        }))
      } else if (name === 'bubblePointPressure') {
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
          }));
      } else {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } 
    else if (inputReservoir.state_reservoir === 'undersaturated') {
      evaluateConditions("undersaturated", value);
      if (name === 'check_phases') {
        if (value != '' && value != undefined) {
          setInputReservoir((prevState) => ({
            ...prevState,
            check_phases: value,
            test_pressure_1_us: '',
            test_pressure_2_us: '',
            test_flow_rate_1_us: '',
            test_flow_rate_2_us: '',
            ipr_model: '',
            productivity_index_j_us: '',
          }))
        } 
      } 
      else if (name === 'productivity_index_j_us') {
        // Cuando 'productivity_index_j_s' es modificado directamente
        if (value === '') {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_us: '',
            // No vaciamos los otros campos aquí porque el usuario está vaciando este campo específicamente
          }));
        } else {
          setInputReservoir((prevState) => ({
            ...prevState,
            productivity_index_j_us: value,
            test_pressure_1_us: '',
            test_pressure_2_us: '',
            test_flow_rate_1_us: '',
            test_flow_rate_2_us: '',
            ipr_model: '',
            liquid_flow_rate_us: ''
          }));
        }
      } else if (
        name === 'test_pressure_1_us' ||
        name === 'test_pressure_2_us' ||
        name === 'test_flow_rate_1_us' ||
        name === 'test_flow_rate_2_us'
      ) {
        if (name === 'test_pressure_1_us' && (Number(value) > inputReservoir.layer_pressure)) {
          // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: "",
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
          }));
          message.warning(`Sorry, the test can't be greater than layer pressure`)
          setInputEmpty(true)
        } else if (name === 'test_pressure_2_us' && (Number(value) > inputReservoir.test_pressure_1_us)) {
          // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: "",
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
          }));
          message.warning(`Sorry, the test can't be greater than test 1`)
          setInputEmpty(true)
        }else {
          // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
          setInputReservoir((prevState) => ({
            ...prevState,
            [name]: value,
            // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
            ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
          }));
        }

      } else if (name === 'water_cut_fraction' && (Number(value) < 0 || Number(value) > 100)) {

        setInputReservoir((prevState) => ({
          ...prevState,
          water_cut_fraction: null,
        }));
        setInputEmpty(true)
        message.warning(`Sorry, the value can't be ${Number(value) < 0 ? 'less than 0' : 'greatter than 100'} `)
      } else if (name === 'liquid_flow_rate_us'){
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else if (name === 'bubblePointPressure') {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
      else {
        setInputReservoir((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  }

  const handleInputBlurTest = (e) => {
    const { name, value } = e.target;
    if (name === 'test_flow_rate_2_s' && (Number(value) < inputReservoir.test_flow_rate_1_s)) {
      // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
      setInputReservoir((prevState) => ({
        ...prevState,
        [name]: "",
        // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
        ...(value === '' || value != '' && { productivity_index_j_s: '', ipr_model: '' }),
      }));
      message.warning(`Sorry, the test can't be less than test 1`)
      setInputEmpty(true)
    }else if (name === 'test_flow_rate_2_us' && (Number(value) < inputReservoir.test_flow_rate_1_us)) {
      // Permitir que estos campos sean vaciados sin sobrescribirlos automáticamente
      setInputReservoir((prevState) => ({
        ...prevState,
        [name]: "",
        // Solo limpiamos 'productivity_index_j_s' e 'ipr_model' si el valor está siendo vaciado
        ...(value === '' || value != '' && { productivity_index_j_us: '', ipr_model: '' }),
      }));
      message.warning(`Sorry, the test can't be less than test 1`)
      setInputEmpty(true)
    } else if(inputReservoir.state_reservoir === 'saturated'){
      if (name === 'layer_pressure' && (Number(value) > Number(inputReservoir.bubblePointPressure))) {
        setInputReservoir((prevState) => ({
          ...prevState,
          layer_pressure: "",
        }));
        setInputEmpty(true)
        message.warning(`Sorry, the layer pressure can't be greatter than bubble point value`)
      } 
    } else if(inputReservoir.state_reservoir === 'undersaturated'){
      if (name === 'layer_pressure' && (Number(value) < Number(inputReservoir.bubblePointPressure))) {
        setInputReservoir((prevState) => ({
          ...prevState,
          layer_pressure: "",
        }));
        setInputEmpty(true)
        message.warning(`Sorry, the layer pressure can't be less than bubble point value`)
      } 
    }
  };
  
  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const [ActiveChecks, setActiveChecks] = useState([]);
  const [ActiveChecksStraight, setActiveChecksStraight] = useState([]);

  const transformData = (dataTableIPRPL) => {
    
    return inputReservoir?.ipr_model === 'vogel' ? dataTableIPRPL?.qo?.vogel?.map((qoValue, index) => ({
      qo: qoValue,
      steps: (dataTableIPRPL.steps[index]).toFixed(2),
    })) : inputReservoir?.ipr_model === 'fetko' ? dataTableIPRPL?.qo?.fetko?.map((qoValue, index) => ({
      qo: qoValue,
      steps: (dataTableIPRPL.steps[index]).toFixed(2),
    })) : inputReservoir?.ipr_model === 'straight' ? dataTableIPRPL?.qo?.straight?.map((qoValue, index) => ({
      qo: qoValue,
      steps: (dataTableIPRPL.steps[index]).toFixed(2),
    })) : inputReservoir?.ipr_model === 'composite' ? dataTableIPRPL?.qo?.qliq_pet?.map((_, index) => ({
      qliq_pet: dataTableIPRPL.qo.qliq_pet[index],
      qo_pet: dataTableIPRPL.qo.qo_pet[index],
      qw_pet: dataTableIPRPL.qo.qw_pet[index],
      steps: dataTableIPRPL.steps[index].toFixed(2),
    })) : (dataTableIPRPL?.data || dataTableIPRPL?.qo) ? Object.keys(dataTableIPRPL?.qo).flatMap(model =>
      dataTableIPRPL?.qo[model].map((qoValue, index) => ({
        qo: qoValue,
        steps: listqIPR?.steps[index],
        model: model,
      }))
    ) : [];
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  const evaluateConditions = (state, value) => {
    const conditions = [
      {
        condition: inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.test_pressure_1_s === "" && inputReservoir.test_flow_rate_1_s === "" && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, false, true, true]
      },
      {
        condition:  inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.productivity_index_j_s === "" && (inputReservoir.test_pressure_1_s !== "" || inputReservoir.test_flow_rate_1_s !== "") && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, false, true, true]
      },
      {
        condition:  inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.productivity_index_j_s === "" && inputReservoir.test_pressure_1_s !== "" && inputReservoir.test_flow_rate_1_s !== "" && (inputReservoir.test_pressure_2_s === "" || inputReservoir.test_flow_rate_2_s === "") && value === "",
        updates: [true, false, true, true]
      },
      {
        condition:  inputReservoir.check_phases !== "three" && state === "saturated" && inputReservoir.productivity_index_j_s === "" && inputReservoir.test_pressure_1_s !== "" && inputReservoir.test_flow_rate_1_s !== "" && (inputReservoir.test_pressure_2_s !== "" || inputReservoir.test_flow_rate_2_s !== "") && inputReservoir.check_phases === "two" && value !== "",
        updates: [true, false, false, true]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "saturated" && inputReservoir.test_pressure_1_s === "" && inputReservoir.test_flow_rate_1_s === "" && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, true, true, false]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "saturated" && inputReservoir.productivity_index_j_s === ""  && inputReservoir.test_flow_rate_1_s === "" && inputReservoir.test_pressure_2_s === "" && inputReservoir.test_flow_rate_2_s === "" && value !== "",
        updates: [true, true, true, false]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && inputReservoir.check_phases === "one" && value !== "",
        updates: [false, true, true, true] 
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && inputReservoir.check_phases === "two" && value !== "",
        updates: [true, false, true, true]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === "" && (inputReservoir.test_pressure_1_us !== "" || inputReservoir.test_flow_rate_1_us !== "") && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && inputReservoir.check_phases === "one" && value !== "",
        updates: [false, true, true, true]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === "" && inputReservoir.test_pressure_1_us !== "" && inputReservoir.test_flow_rate_1_us !== "" && (inputReservoir.test_pressure_2_us === "" || inputReservoir.test_flow_rate_2_us === "") &&  inputReservoir.check_phases === "two" && value === "",
        updates: [true, false, true, true]
      },
      {
        condition: inputReservoir.check_phases !== "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === "" && inputReservoir.test_pressure_1_us !== "" && inputReservoir.test_flow_rate_1_us !== "" && (inputReservoir.test_pressure_2_us !== "" || inputReservoir.test_flow_rate_2_us !== "") && inputReservoir.check_phases === "two" && value !== "",
        updates: [true, false, false, true]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "undersaturated" && inputReservoir.test_pressure_1_us === "" && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && value !== "",
        updates: [true, true, true, false]
      },
      {
        condition: inputReservoir.check_phases === "three" && state === "undersaturated" && inputReservoir.productivity_index_j_us === ""  && inputReservoir.test_flow_rate_1_us === "" && inputReservoir.test_pressure_2_us === "" && inputReservoir.test_flow_rate_2_us === "" && value !== "",
        updates: [true, true, true, false]
      },
    ];
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].condition) {
        setDisabledCheck(prevArray => {
          const newArray = [...prevArray];
          newArray[1] = conditions[i].updates[0];
          newArray[2] = conditions[i].updates[1];
          newArray[3] = conditions[i].updates[2];
          newArray[4] = conditions[i].updates[3];
          return newArray;
        });
        break;
      }
    }
  };
  
  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center mt-[30px]">
        <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px]">
          <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
            <h1 className="flex flex-row font-bold text-[20px]">Layer Information</h1>
          </div>
        </div>
        <div className="flex flex-row gap-10">
          <div className="flex flex-col w-1/2 items-end">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Layer pressure (psia)
              <Tooltip title={`${inputReservoir.state_reservoir === 'undersaturated' ? 'The value must be greatter than bubble point value' : inputReservoir.state_reservoir === 'saturated' ? 'The value must be less than bubble point value' : 'Please validate the state reservoir'}`}>
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
            <input
              type="number"
              name="layer_pressure"
              min="0"
              disabled={inputReservoir.bubblePointPressure === null || inputReservoir.bubblePointPressure === ''}
              onKeyDown={handleKeyDown}
              value={inputReservoir.layer_pressure}
              onChange={handleChangeDataReservoir}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.layer_pressure === null || inputReservoir.layer_pressure === '')
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
              onBlur={(e) => handleInputBlurTest(e)}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Bubble point pressure (psia)
            </label>
            <input
              type="number"
              name="bubblePointPressure"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.bubblePointPressure}
              onChange={handleChangeDataReservoir}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.bubblePointPressure === null || inputReservoir.bubblePointPressure === '')
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Mid-perf Measured Depth (feet)
            </label>
            <input
              type="number"
              name="md_perf_depth"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.md_perf_depth}
              onChange={handleChangeDataReservoir}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && inputReservoir.md_perf_depth === ""
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] font bold text-[15px] mb-[5px] justify-left"
            >
              Phase
            </label>
            <Select
              className="flex w-full h-[47px] max-w-[592px] text-start mb-[7px]"
              name="check_phases"
              value={inputReservoir.check_phases || "Selected option"}
              onChange={(e) => handleChangeSelect2('check_phases', e)}
            >
              <Option value="one" disabled={inputReservoir.state_reservoir === 'saturated'}>One (Oil)</Option>
              <Option value="two">Two (Oil + Gas)</Option>
              <Option value="three">Three (Oil + Gas + Water)</Option>
            </Select>
          </div>
          <div className="flex flex-col w-1/2 text-left items-start justify-start">
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Layer temperature (deg F)
            </label>
            <input
              type="number"
              name="layer_temperature"
              min="0"
              onKeyDown={handleKeyDown}
              value={inputReservoir.layer_temperature}
              onChange={handleChangeDataReservoir}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && inputReservoir.layer_temperature === "" 
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Water cut (%)
            </label>
            <input
              type="number"
              name="water_cut_fraction"
              min="0"
              max={100}
              onKeyDown={handleKeyDown}
              value={inputReservoir.water_cut_fraction}
              onChange={handleChangeDataReservoir}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.water_cut_fraction === "" || inputReservoir.water_cut_fraction === null)
                ? " border-red-700 bg-yellow-100"
                : " border-[#BCBCCC]"
                } `}
            />
            <label
              htmlFor=""
              className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir State {/* IPR Model */}
            </label>
            <Select
              className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
              name="state_reservoir"
              value={inputReservoir.state_reservoir || "Selected Option"}
              onChange={(e) => handleChangeSelect('state_reservoir', e)}
            >
              <Option value="saturated">Saturated</Option>
              <Option value="undersaturated">Undersaturated</Option>
            </Select>
            {selectedValue === "saturated" && (
              <>
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Productivity index J (STB/D/psi)
                </label>
                <input
                  type="number"
                  name="productivity_index_j_s"
                  min="0"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.productivity_index_j_s}
                  onChange={handleChangeDataReservoir}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${inputEmpty && (inputReservoir.test_pressure_1_s == "" && inputReservoir.test_flow_rate_1_s == "") && inputReservoir.productivity_index_j_s === "" ? "border-red-700 bg-yellow-100" : "border-[#BCBCCC]"} `}
                  disabled={inputReservoir.test_pressure_1_s !== "" || inputReservoir.test_pressure_2_s !== "" || inputReservoir.test_flow_rate_1_s !== "" || inputReservoir.test_flow_rate_2_s !== ""} // Deshabilita si cualquier campo de test tiene un valor
                />
              </>
            )}
            {selectedValue === "undersaturated" && (
              <> 
                <label
                htmlFor=""
                className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Productivity Index J (STB/D/psi)
                </label>
                <input
                  type="number"
                  name="productivity_index_j_us"
                  min="0"
                  onKeyDown={handleKeyDown}
                  value={inputReservoir.productivity_index_j_us}
                  onChange={handleChangeDataReservoir}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_pressure_1_us == "" && inputReservoir.test_flow_rate_1_us == "") && inputReservoir.productivity_index_j_us === ""
                    ? " border-red-700 bg-yellow-100"
                    : " border-[#BCBCCC]"
                    } `}
                  disabled={inputReservoir.test_pressure_1_us !== "" || inputReservoir.test_pressure_2_us !== "" || inputReservoir.test_flow_rate_1_us !== "" || inputReservoir.test_flow_rate_2_us !== ""} // Deshabilita si cualquier campo de test tiene un valor
                />
              </>
            )}
          </div>
        </div>
        {selectedValue === "saturated" && (
          <>
            <div className="flex flex-row gap-10 mt-[10px]">
              <div className="flex flex-col w-1/2 items-end">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">Oil Flow Rate (STB/D)</label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">  
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_1_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_1_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_flow_rate_1_s === "" || inputReservoir.test_flow_rate_1_s === null) && inputReservoir.productivity_index_j_s === '' && inputReservoir.check_phases !== "three"
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                      disabled={inputReservoir.productivity_index_j_s !== "" || inputReservoir.check_phases === "three"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                      >
                      Test 2
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_2_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_2_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_flow_rate_2_s === "" || inputReservoir.test_flow_rate_2_s === null) && inputReservoir.productivity_index_j_s === '' && inputReservoir.check_phases !== "three"
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                      disabled={inputReservoir.productivity_index_j_s !== "" || inputReservoir.test_flow_rate_1_s === '' || inputReservoir.check_phases === "three"}
                      onBlur={(e) => handleInputBlurTest(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">
                  Bottomhole Pressure (psia)
                  <Tooltip title={`To use Fetkovich model pressure test 1 and pressure test 2 must be below bubble point pressure`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                    </label>
                    <input
                      type="number"
                      name="test_pressure_1_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_1_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${inputEmpty && (inputReservoir.test_pressure_1_s === "" || inputReservoir.test_pressure_1_s === null) && inputReservoir.productivity_index_j_s === "" ? "border-red-700 bg-yellow-100" : "border-[#BCBCCC]"}${inputReservoir.productivity_index_j_s !== '' ? ' disabled' : ''} `}
                      disabled={inputReservoir.productivity_index_j_s !== ""} // Deshabilita si el campo productivity_index_j_vg tiene un valor
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                    </label>
                    <input
                      type="number"
                      name="test_pressure_2_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_2_s}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_pressure_2_s === "" || inputReservoir.test_pressure_2_s === null) && inputReservoir.productivity_index_j_s === "" && inputReservoir.check_phases !== "three" ? " border-red-700 bg-yellow-100" : " border-[#BCBCCC]"} `}
                      disabled={inputReservoir.productivity_index_j_s !== "" || inputReservoir.test_pressure_1_s === ''|| inputReservoir.check_phases === "three"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputReservoir.check_phases === "three" && (
              <>
                <div className="flex flex-row w-full  gap-10">
                  <div className="flex flex-col w-1/2  items-end">
                    <label
                    htmlFor=""
                    className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Liquid Flow Rate (STB/D)
                    </label>
                    <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] mt-[6px]"
                      >
                        Test 1
                      </label>
                    <input
                      type="number"
                      name="liquid_flow_rate_s"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.liquid_flow_rate_s}
                      onChange={handleChangeDataReservoir}
                      disabled={inputReservoir.productivity_index_j_s !== ""}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && inputReservoir.liquid_flow_rate_s === "" && inputReservoir.productivity_index_j_s === ""
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 items-start"></div>
                </div>
              </>
            )}
            <div className="flex flex-row w-full  gap-10 ">
              <div className="flex flex-col w-1/2  items-end">
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  IPR model
                </label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputReservoir.ipr_model === '' ? '#FEF9C3' : '', border: inputEmpty && inputReservoir.ipr_model === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full h-[47px] max-w-[592px] text-start mb-[7px] "
                  name="ipr_model"
                  value={inputReservoir.ipr_model || "Selected option"}
                  onChange={(e) => handleChangeSelect2('ipr_model', e)}
                >
                  <Option value="straight" disabled={disabledCheck[1]}>Straight line</Option>
                  <Option value="vogel" disabled={disabledCheck[2]}>Vogel</Option>
                  <Option value="fetko" disabled={disabledCheck[3]}>Fetkovich</Option>
                  <Option value="composite" disabled={disabledCheck[4]}>Composite</Option>
                </Select>
              </div>
              <div className="flex flex-col w-1/2 items-start"></div>
            </div>
            <div className="flex flex-col w-full text-center items-center justify-start gap-y-[2em] mt-[17px] mb-[17px]">
                <button
                  className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                  onClick={handleCalculate}
                >
                  Run
                </button>
            </div>
            <div className="flex w-full h-full text-center items-center justify-center">
              {inputReservoir.check_phases != "three" && (
                <ChartVogelPL 
                  ActiveChecks={ActiveChecks}
                  data={dataTableIPRPL}
                >
                </ChartVogelPL>
              )}
              {inputReservoir.check_phases === "three" && (
                <ChartCompositePL
                  ActiveChecks={ActiveChecks}
                  data={dataTableIPRPL}
                >
                </ChartCompositePL>
              )}
            </div>
            <div className="flex w-full text-center justify-center items-center mb-[90px]">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
        {selectedValue === "undersaturated" && (
          <>
            <div className="flex flex-row gap-10 mt-[10px]">
              <div className="flex flex-col w-1/2 items-end">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">Oil Flow Rate (STB/D)</label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_1_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_1_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_flow_rate_1_us === "" || inputReservoir.test_flow_rate_1_us === null) && inputReservoir.productivity_index_j_us === '' && inputReservoir.check_phases !== "three"
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}

                      disabled={inputReservoir.productivity_index_j_us !== "" || inputReservoir.check_phases === "three"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                    </label>
                    <input
                      type="number"
                      name="test_flow_rate_2_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_flow_rate_2_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_flow_rate_2_us === "" || inputReservoir.test_flow_rate_2_us === null) && inputReservoir.productivity_index_j_us === '' && inputReservoir.check_phases !== "three"
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}

                      disabled={(inputReservoir.productivity_index_j_us !== "" || inputReservoir.test_flow_rate_1_us === '') || (inputReservoir.test_flow_rate_1_us !== '' && inputReservoir.check_phases !== "two") || inputReservoir.check_phases === "three"}
                      onBlur={(e) => handleInputBlurTest(e)} // Pasa el evento completo
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]" htmlFor="">
                  Bottomhole Pressure (psia)
                  <Tooltip title={`To use Fetkovich model pressure test 1 and pressure test 2 must be below bubble point pressure`}>
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <div className="grid grid-cols-2 w-full max-w-[592px] text-start font bold textt-[15px] mb-[7px]  gap-x-[1em]">
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 1
                    </label>
                    <input
                      type="number"
                      min="0"
                      onKeyDown={handleKeyDown}
                      name="test_pressure_1_us"
                      value={inputReservoir.test_pressure_1_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] ${inputEmpty && (inputReservoir.test_pressure_1_us === "" || inputReservoir.test_pressure_1_us === null) && inputReservoir.productivity_index_j_us === "" ? "border-red-700 bg-yellow-100" : "border-[#BCBCCC]"}${inputReservoir.productivity_index_j_s !== '' ? ' disabled' : ''} `}
                      disabled={inputReservoir.productivity_index_j_us !== ""} // Deshabilita si el campo productivity_index_j_vg tiene un valor
                    />
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Test 2
                    </label>
                    <input
                      type="number"
                      name="test_pressure_2_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.test_pressure_2_us}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && (inputReservoir.test_pressure_2_us === "" || inputReservoir.test_pressure_2_us === null) && inputReservoir.productivity_index_j_us === "" && inputReservoir.check_phases !== "three" ? " border-red-700 bg-yellow-100" : " border-[#BCBCCC]"} `}
                      disabled={(inputReservoir.productivity_index_j_us !== "" || inputReservoir.test_pressure_1_us === '') || (inputReservoir.test_flow_rate_1_us !== '' && inputReservoir.check_phases !== "two") || inputReservoir.check_phases === "three"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputReservoir.check_phases === "three" && (
              <>
                <div className="flex flex-row w-full  gap-10">
                  <div className="flex flex-col w-1/2  items-end">
                    <label
                    htmlFor=""
                    className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Liquid Flow Rate (STB/D)
                    </label>
                    <label
                        htmlFor=""
                        className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px] mt-[6px]"
                      >
                        Test 1
                      </label>
                    <input
                      type="number"
                      name="liquid_flow_rate_us"
                      min="0"
                      onKeyDown={handleKeyDown}
                      value={inputReservoir.liquid_flow_rate_us}
                      disabled={inputReservoir.productivity_index_j_us !== ""}
                      onChange={handleChangeDataReservoir}
                      className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]  ${inputEmpty && inputReservoir.liquid_flow_rate_us === "" && inputReservoir.productivity_index_j_us === ""
                        ? " border-red-700 bg-yellow-100"
                        : " border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 items-start"></div>
                </div>
              </>
            )}
            <div className="flex flex-row w-full  gap-10">
              <div className="flex flex-col w-1/2  items-end">
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  IPR model
                </label>
                <Select
                  style={{ backgroundColor: inputEmpty && inputReservoir.ipr_model === '' ? '#FEF9C3' : '', border: inputEmpty && inputReservoir.ipr_model === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                  className="w-full h-[47px] max-w-[592px] text-start mb-[7px]"
                  name="ipr_model"
                  value={inputReservoir.ipr_model || "Selected option"}
                  onChange={(e) => handleChangeSelect2('ipr_model', e)}
                >
                  <Option value="straight" disabled={disabledCheck[1]}>Straight line</Option>
                  <Option value="vogel" disabled={disabledCheck[2]}>Vogel</Option>
                  <Option value="fetko" disabled={disabledCheck[3]}>Fetkovich</Option>
                  <Option value="composite" disabled={disabledCheck[4]}>Composite</Option>
                </Select>
              </div>
              <div className="flex flex-col w-1/2   items-start"></div>
            </div>
            <div className="flex flex-col w-full text-center items-center justify-start gap-y-[2em] mt-[17px] mb-[17px]">
              <button
                className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                onClick={handleCalculate}
              >
                Run
              </button>
            </div>
            <div className="flex w-full h-full text-center items-center justify-center">
              {inputReservoir.check_phases != "three" && (
                <ChartVogelPL
                ActiveChecksStraight={ActiveChecksStraight}
                data={dataTableIPRPL}
                ></ChartVogelPL>
              )}
              {inputReservoir.check_phases === "three" && (
                <ChartCompositePL
                  ActiveChecks={ActiveChecks}
                  data={dataTableIPRPL}
                >
                </ChartCompositePL>
              )}
            </div>
            <div className="flex w-full text-center justify-center items-center mb-[90px]">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Done
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  );
};
