import React, { useEffect, useState } from "react";
import {
	DATA_ALERTS,
	INITIAL_TYPE_GRAPH,
	PLATFORM_REAL_TIME,
	USER_FILTER,
	WELL_SENSOR_DEVICE,
} from "../components/elements/types/type_dashboard";
import { DATA_CREATE_WELL_DCA } from "../components/elements/types/type_dca";
import { DATA_END_PARAMETERS as DATA_END_PARAMETERS_GL } from "../components/elements/types/type_gas_lift";
import { DATA_END_PARAMETERS as DATA_END_PARAMETERS_PL } from "../components/elements/types/type_plunger_Lift";
import { DATA_CREATE_WELL_INJECTOR } from "../components/elements/types/type_injector";

export const useDashboard = () => {
	const initialDeviceRealTime = {
		list_device_gaslift: WELL_SENSOR_DEVICE.data,
		list_device_dca: WELL_SENSOR_DEVICE.data,
		device_dca: PLATFORM_REAL_TIME.forecasting,
		device_gaslift: PLATFORM_REAL_TIME.gas_lift,
		device_plungerlift: PLATFORM_REAL_TIME.plunger_lift,
	};

	const initialFirstModal = [
		{ modal: "filters", enabled: false },
		{ modal: "parameters", enabled: false },
	];

	const initialViewLoadingGeneral = {
		loadSaveFilters: false,
	};

	const initialSlideGraph = {
		gas_lift: 1,
		plunger_lift: 1,
	};

	const initialProcessExecute = {
		openModalAllocation: false,
	};

	const [userDataInfo, setUserDataInfo] = useState(USER_FILTER);
	const [selectedWell, setSelectedWell] = useState(null);
	const [selectedWellDCA, setSelectedWellDCA] = useState(null);
	const [selectedReservoirSCT, setSelectedReservoirSCT] = useState(null);
	const [selectedWellPL, setSelectedWellPL] = useState(null);
	const [selectedWellInjector, setSelectedWellInjector] = useState(null);
	const [dataPage, setDataPage] = useState("");
	const [openFirstModal, setOpenFirstModal] = useState(initialFirstModal);
	const [dataDomainGraph, setDataDomainGraph] = useState(
		DATA_END_PARAMETERS_GL.initialMandatoryData.data_domain_graph,
	);
	const [dataDomainGraphPL, setDataDomainGraphPL] = useState(
		DATA_END_PARAMETERS_PL.initialMandatoryData.data_domain_graph,
	); // TODO: inicializar estos datos con la misma estructura que tiene gas lift
	const [dataDomainGraphDCA, setDataDomainGraphDCA] = useState(
		DATA_CREATE_WELL_DCA.data_domain_graph,
	);
	const [dataDomainGraphInjector, setDataDomainGraphInjector] = useState(
		DATA_CREATE_WELL_INJECTOR.data_domain_graph,
	);
	const [dataAlertGasLift, setDataAlertGasLift] = useState(
		DATA_END_PARAMETERS_GL.initialMandatoryData.data_alert,
	);
	const [dataAlertDCA, setDataAlertDCA] = useState(
		DATA_CREATE_WELL_DCA.data_alert,
	);
	const [dataAlertInjector, setDataAlertInjector] = useState(
		DATA_CREATE_WELL_INJECTOR.data_alert,
	);
	const [dataAlertPlungerLift, setDataAlertPlungerLift] = useState(
		DATA_END_PARAMETERS_PL.initialMandatoryData.data_alert,
	);
	const [someAlert, setSomeAlert] = useState(DATA_ALERTS);
	const [checkTypeGraph, setCheckTypeGraph] = useState(INITIAL_TYPE_GRAPH);
	const [deviceRealTime, setDeviceRealTime] = useState(initialDeviceRealTime);
	const [selectIntervalVLP, setSelectIntervalVLP] = useState();
	const [viewLoadingGeneral, setViewLoadingGeneral] = useState(
		initialViewLoadingGeneral,
	);
	const [slideGraph, setSlideGraph] = useState(initialSlideGraph);
	const [processExecute, setProcessExecute] = useState(initialProcessExecute);

	useEffect(() => {
		const getDataPage = sessionStorage.getItem("dataPage");
		const getUserDataInfo = JSON.parse(sessionStorage.getItem("userDataInfo"));
		const getSelectedWell = sessionStorage.getItem("selectedWell");
		const getSelectedWellDCA = sessionStorage.getItem("selectedWellDCA");
		const getSelectedReservoirSCT = sessionStorage.getItem(
			"selectedReservoirSCT",
		);
		const getSelectedWellPL = sessionStorage.getItem("selectedWellPL");
		const getSelectedWellInjector = sessionStorage.getItem(
			"selectedWellInjector",
		);
		const getDataDomainGraph = JSON.parse(
			sessionStorage.getItem("dataDomainGraph"),
		);
		const getDataDomainGraphPL = JSON.parse(
			sessionStorage.getItem("dataDomainGraphPL"),
		);
		const getDataDomainGraphDCA = JSON.parse(
			sessionStorage.getItem("dataDomainGraphDCA"),
		);
		const getDataDomainGraphInjector = JSON.parse(
			sessionStorage.getItem("dataDomainGraphInjector"),
		);
		const getDataAlertGasLift = JSON.parse(
			sessionStorage.getItem("dataAlertGasLift"),
		);
		const getDataAlertPlungerLift = JSON.parse(
			sessionStorage.getItem("dataAlertPlungerLift"),
		);
		const getDataAlertDCA = JSON.parse(sessionStorage.getItem("dataAlertDCA"));
		const getDataAlertInjector = JSON.parse(
			sessionStorage.getItem("dataAlertInjector"),
		);
		const getSomeAlert = JSON.parse(sessionStorage.getItem("someAlert"));
		const getCheckTypeGraph = JSON.parse(
			sessionStorage.getItem("checkTypeGraph"),
		);
		const getDeviceRealTime = JSON.parse(
			sessionStorage.getItem("deviceRealTime"),
		);
		const getSelectIntervalVLP = JSON.parse(
			sessionStorage.getItem("selectIntervalVLP"),
		);
		const getSlideGraph = JSON.parse(sessionStorage.getItem("slideGraph"));

		if (getDataPage) {
			setDataPage(getDataPage);
		}
		if (getUserDataInfo) {
			setUserDataInfo(getUserDataInfo);
		}
		if (getSelectedWell) {
			setSelectedWell(getSelectedWell);
		}
		if (getSelectedWellDCA) {
			setSelectedWellDCA(getSelectedWellDCA);
		}
		if (getSelectedReservoirSCT) {
			setSelectedReservoirSCT(getSelectedReservoirSCT);
		}
		if (getSelectedWellPL) {
			setSelectedWellPL(getSelectedWellPL);
		}
		if (getSelectedWellInjector) {
			setSelectedWellInjector(getSelectedWellInjector);
		}
		if (getDataDomainGraph) {
			setDataDomainGraph(getDataDomainGraph);
		}
		if (getDataDomainGraphPL) {
			setDataDomainGraphPL(getDataDomainGraphPL);
		}
		if (getDataDomainGraphDCA) {
			setDataDomainGraphDCA(getDataDomainGraphDCA);
		}
		if (getDataDomainGraphInjector) {
			setDataDomainGraphInjector(getDataDomainGraphInjector);
		}
		if (getDataAlertGasLift) {
			setDataAlertGasLift(getDataAlertGasLift);
		}
		if (getDataAlertPlungerLift) {
			setDataAlertPlungerLift(getDataAlertPlungerLift);
		}
		if (getDataAlertDCA) {
			setDataAlertDCA(getDataAlertDCA);
		}
		if (getDataAlertInjector) {
			setDataAlertInjector(getDataAlertInjector);
		}
		if (getSomeAlert) {
			setSomeAlert(getSomeAlert);
		}
		if (getCheckTypeGraph) {
			setCheckTypeGraph(getCheckTypeGraph);
		}
		if (getDeviceRealTime) {
			setDeviceRealTime(getDeviceRealTime);
		}
		if (getSelectIntervalVLP) {
			setSelectIntervalVLP(getSelectIntervalVLP);
		}
		if (getSlideGraph) {
			setSlideGraph(getSlideGraph);
		}
	}, []);

	const toggleOpenFirstModal = (modalName) => {
		setOpenFirstModal((prev) =>
			prev.map((item) =>
				item.modal === modalName ? { ...item, enabled: !item.enabled } : item,
			),
		);
	};

	const getDataPage = (options) => {
		setDataPage(options);
		sessionStorage.setItem("dataPage", options);
	};

	const getUserDataInfo = (options) => {
		setUserDataInfo(options);
		sessionStorage.setItem("userDataInfo", JSON.stringify(options));
	};

	const getSelectedWell = (options) => {
		setSelectedWell(options);
		sessionStorage.setItem("selectedWell", options);
	};

	const getSelectedWellDCA = (options) => {
		setSelectedWellDCA(options);
		sessionStorage.setItem("selectedWellDCA", options);
	};

	const getSelectedReservoirSCT = (options) => {
		setSelectedReservoirSCT(options);
		sessionStorage.setItem("selectedReservoirSCT", options);
	};

	const getSelectedWellPL = (options) => {
		setSelectedWellPL(options);
		sessionStorage.setItem("selectedWellPL", options);
	};

	const getSelectedWellInjector = (options) => {
		setSelectedWellInjector(options);
		sessionStorage.setItem("selectedWellInjector", options);
	};

	const getDataDomainGraph = (options) => {
		setDataDomainGraph(options);
		sessionStorage.setItem("dataDomainGraph", JSON.stringify(options));
	};

	const getDataDomainGraphPL = (options) => {
		setDataDomainGraphPL(options);
		sessionStorage.setItem("dataDomainGraphPL", JSON.stringify(options));
	};

	const getDataDomainGraphDCA = (options) => {
		setDataDomainGraphDCA(options);
		sessionStorage.setItem("dataDomainGraphDCA", JSON.stringify(options));
	};

	const getDataDomainGraphInjector = (options) => {
		setDataDomainGraphInjector(options);
		sessionStorage.setItem("dataDomainGraphInjector", JSON.stringify(options));
	};

	const getDataAlertGasLift = (options) => {
		setDataAlertGasLift(options);
		sessionStorage.setItem("dataAlertGasLift", JSON.stringify(options));
	};

	const getDataAlertPlungerLift = (options) => {
		setDataAlertPlungerLift(options);
		sessionStorage.setItem("dataAlertPlungerLift", JSON.stringify(options));
	};

	const getDataAlertDCA = (options) => {
		setDataAlertDCA(options);
		sessionStorage.setItem("dataAlertDCA", JSON.stringify(options));
	};

	const getDataAlertInjector = (options) => {
		setDataAlertInjector(options);
		sessionStorage.setItem("dataAlertInjector", JSON.stringify(options));
	};

	const getSomeAlert = (options) => {
		setSomeAlert(options);
		sessionStorage.setItem("someAlert", JSON.stringify(options));
	};

	const getCheckTypeGraph = (options) => {
		setCheckTypeGraph(options);
		sessionStorage.setItem("checkTypeGraph", JSON.stringify(options));
	};

	const getDeviceRealTime = (dataDevice) => {
		setDeviceRealTime(dataDevice);
		sessionStorage.setItem("deviceRealTime", JSON.stringify(dataDevice));
	};

	const getSelectIntervalVLP = (options) => {
		setSelectIntervalVLP(options);
		sessionStorage.setItem("selectIntervalVLP", JSON.stringify(options));
	};

	const getViewLoadingGeneral = (options) => {
		setViewLoadingGeneral(options);
	};

	const getSlideGraph = (options) => {
		setSlideGraph(options);
		sessionStorage.setItem("slideGraph", JSON.stringify(options));
	};

	const getProcessExecute = (options) => {
		setProcessExecute(options);
	};

	return {
		dataPage,
		userDataInfo,
		selectedWell,
		selectedWellDCA,
		selectedReservoirSCT,
		selectedWellPL,
		selectedWellInjector,
		dataDomainGraph,
		dataDomainGraphPL,
		dataDomainGraphDCA,
		dataDomainGraphInjector,
		dataAlertGasLift,
		dataAlertPlungerLift,
		dataAlertDCA,
		dataAlertInjector,
		someAlert,
		checkTypeGraph,
		deviceRealTime,
		selectIntervalVLP,
		viewLoadingGeneral,
		slideGraph,
		processExecute,
		getDataPage,
		getUserDataInfo,
		getSelectedWell,
		getSelectedWellDCA,
		getSelectedReservoirSCT,
		getSelectedWellPL,
		getSelectedWellInjector,
		getDataDomainGraph,
		getDataDomainGraphPL,
		getDataDomainGraphDCA,
		getDataDomainGraphInjector,
		getDataAlertGasLift,
		getDataAlertPlungerLift,
		getDataAlertDCA,
		getDataAlertInjector,
		getSomeAlert,
		openFirstModal,
		setOpenFirstModal,
		toggleOpenFirstModal,
		getCheckTypeGraph,
		getDeviceRealTime,
		getSelectIntervalVLP,
		getViewLoadingGeneral,
		getSlideGraph,
		getProcessExecute,
	};
};
