import React from "react";
import { DashboardDCAContext } from "./DashboardDCAContext";
import { useDashboardDCA } from "../../hooks/useDashboardDCA";

export const DashboardDCAProvider = ({ children }) => {
	const {
		dataFilterUserDCA,
		checkedValuesFirstCol,
		checkedValuesLastCol,
		dataCardsDCA,
		validateAlertDCA,
		viewLoadingDCA,
		getDataFilterUserDCA,
		getCheckedValuesFirstCol,
		getCheckedValuesLastCol,
		getDataCardsDCA,
		getViewLoadingDCA,
		getValidateAlertDCA,
	} = useDashboardDCA();
	return (
		<DashboardDCAContext.Provider value={{
			dataFilterUserDCA,
			checkedValuesFirstCol,
			checkedValuesLastCol,
			dataCardsDCA,
			validateAlertDCA,
			viewLoadingDCA,
			getDataFilterUserDCA,
			getCheckedValuesFirstCol,
			getCheckedValuesLastCol,
			getDataCardsDCA,
			getViewLoadingDCA,
			getValidateAlertDCA,
		}}>
			{children}
		</DashboardDCAContext.Provider>
	)
}
