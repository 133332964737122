import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart
} from "recharts";

const ChartReservoirLog = ({dataLog}) => {
  
  const [data, setData] = useState([]);
  const [gasRate, serGasRate] = useState([]);
  const [deltaPP, setDeltaPP] = useState([]);

  useEffect(() => {
    if(dataLog){
      if(dataLog?.delta_pseudopress_FAF_test && dataLog?.gas_rate_test_faf){
  
        setDeltaPP(dataLog?.delta_pseudopress_FAF_test);
        serGasRate(dataLog?.gas_rate_test_faf);
  
        const dataTranform = dataLog?.gas_rate_test_faf.map((rate, index) => ({
          gasRate: rate,
          deltaPP: dataLog?.delta_pseudopress_FAF_test[index],
        }));
  
        setData(dataTranform);
        
      }
    }else {
      setData([]);
      setDeltaPP([]);
      serGasRate([]);
    }
  }, [dataLog]);


  const calculateLogDomain = (values) => {
    const min = Math.min(...values);
    const max = Math.max(...values);
    return [10 ** Math.floor(Math.log10(min)), 10 ** Math.ceil(Math.log10(max))];
  };

  const generateLogTicks = (start, end) => {
    const ticks = [];
    for (let base = start; base <= end; base *= 10) {
      ticks.push(base);
      for (let i = 2; i <= 9; i++) {
        if (base * i <= end) ticks.push(base * i);
      }
    }
    return ticks;
  };

  const xDomain = calculateLogDomain(gasRate);
  const yDomain = calculateLogDomain(deltaPP);

  const xTicks = generateLogTicks(xDomain[0], xDomain[1]);
  const yTicks = generateLogTicks(yDomain[0], yDomain[1]);

  const tickFormatter = (tick) => {
    const exponent = Math.floor(Math.log10(tick));
    const base = 10 ** exponent;
    const digit = tick / base;
    return digit === 1 ? `10^${exponent}` : digit.toFixed(0);
  };

  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p style={{ color: '#000000' }}>{`Gas rate: ` + (data?.gasRate).toFixed(2) + ` (Mscf/d)`}</p>
          <p style={{ color: '#000000' }}>{`∆Pp: ` + (data?.deltaPP).toFixed(2) + ` (psia²/cp)`}</p>
        </div>
      )
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 30,
        }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          type="number"
          dataKey="gasRate"
          scale="log"
          domain={xDomain}
          ticks={xTicks}
          tickFormatter={tickFormatter}
          allowDecimals={false}
          label={{
            value: "Gas rate (MMscf/D)",
            position: "insideBottom",
            offset: -10,
          }}
        />

        <YAxis
          type="number"
          dataKey="deltaPP"
          scale="log"
          domain={yDomain}
          ticks={yTicks}
          tickFormatter={tickFormatter}
          label={{
            value: "∆Pp (psia²/cp)",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Line
          type="monotone"
          dataKey="deltaPP"
          stroke="#9c231c"
          strokeWidth={2}
          dot={{ r: 3, fill: "#9c231c" }}
        />
        
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartReservoirLog;