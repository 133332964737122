export const VALUE_SELECT_PARAMETERS = {
  lift_gas_injection_rate: "lift_gas_injection_rate",
  water_cut: "water_cut",
  formation_gor: "formation_gor"
}

export const COLOR_GRAPH = {
  1: "cf1322",
  2: "fadb14",
  3: "73d13d",
  4: "36cfc9",
  5: "ff85c0",
  6: "871400",
  7: "002c8c",
  8: "820014"
}

export const INITIAL_DATA_INPUT = {
  oil_flow_rate_min: "",
  oil_flow_rate_max: "",
  number_rates_flow: "",
  check_import_model_ipr: false,
  select_parameters: "",
  min_gor_flow: "",
  max_gor_flow: "",
  number_cases_gor_flow: "",
  min_wt_flow: "",
  max_wt_flow: "",
  number_cases_wt_flow: "",
  min_inj_flow: "",
  max_inj_flow: "",
  number_cases_inj_flow: "",
  table_inflow_outflow: [],
  table_performance_flow: [],
  minChartFlow: "",
  maxChartFlow: ""
}

export const INITIAL_DATA_TABLE_INOUTFLOW = [{
  operating_pressure: 0,
  oil_rate: 0,
  gas_rate: 0,
  water_rate: 0,
  gor: 0,
  water_cut: 0
}]

export const INITIAL_DATA_TABLE_PERFLOW = [{
  sensitivity: "",
  oil_rate: 0,
  lift_gas_injection_rate: 0
}]

