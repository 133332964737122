import React, { useContext, useEffect, useState } from 'react'
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { Navbar } from '../../components/layout/Navbar';
import { Sidebar } from '../../components/layout/Sidebar';
import { Menu, Select, Modal, Button, Alert, message, Col, Row, Slider, InputNumber } from 'antd';
import { ChartCrossOilGor } from '../../components/ScreeningTool/GraphicsDashboard/StatustucakAnalytics/ChartCrossOilGor';
import { ChartPieStatus } from '../../components/ScreeningTool/GraphicsDashboard/StatustucakAnalytics/ChartPieStatus';
import { ChartMultipleScatter } from '../../components/ScreeningTool/GraphicsDashboard/StatustucakAnalytics/ChartMultipleScatter';
import { ChartPieStage } from '../../components/ScreeningTool/GraphicsDashboard/StatustucakAnalytics/ChartPieStage';
import { ChartTableAnalytics } from '../../components/ScreeningTool/GraphicsDashboard/StatustucakAnalytics/ChartTableAnalytics';
import { useTabScreening } from '../../hooks/useTabScreening';
import { useUsers } from '../../hooks/useUsers'
import { Loading } from "../../components/Loading";
import { DATA_SCREENING_TOOL, INITIAL_DATA_SLIDER, INITIAL_ITEMS, PARAMETER_PERCENTAGE } from '../../components/elements/types/type_screening_tool';
import { NAME_PAGES } from '../../components/elements/types/type_pages';
import { STATUS_CODE_SCREENING_TOOL } from '../../components/elements/types/type_response_API';

export const DashboardScreeningTool = () => { 

  const { Option } = Select;
  const { getDataPage, selectedReservoirSCT } = useContext(DashboardContext);
  const { User } = useUsers();
  const { 
    userDataInfo,
    queryRankStats, 
    queryScreeningDonut,
    queryScreeningDonutDepth,
    queryScreening3DRank,
    queryScreening3DRankDepth,
    queryMultipleScatter,
    queryFileScreening,
    queryListReservoir,
    refetchQueryRankStats,
    refetchScreeningDonut,
    refetchScreeningDonutDepth,
    refetchScreening3DRank, 
    refetchQueryMultipleScatter,
    refetchScreening3DRankDepth,
    refetchQueryFileScreening,
    refetchQueryListReservoir,
    loadDataScreening3DRank,
    loadDataScreening3DRankDepth,
    mutationUpdateScreening,
  } = useTabScreening();
  const [ current, setCurrent ] = useState('tab1');
  const [ openModalCroosPlot, setOpenModalCroosPlot ] = useState(false);
  const [ openModal3D, setOpenModal3D ] = useState(false);
  const [ typeGraphMultipleScatter, setTypeGraphMultipleScatter] = useState();
  const [ dataGraphMultipleScatter, setDataGraphMultipleScatter] = useState([]);
  const [ dataSlider, setDataSlider ] = useState(INITIAL_DATA_SLIDER)
  const [ selectDonut, setSelectDonut ] = useState("Donut");
  const [ select3D, setSelect3d ] = useState("3drank");;
  const [ selectRank, setSelectRank ] = useState("");
  const [ selectTypeRank, setSelectTyperank ] = useState("Oil Rate (STB/D)")  
  const [ dataFile, setDataFile ] = useState();
  const [ queryRankPie, setQueryRankPie] = useState("");
  const [ typeRankpie, setTypeRankpie] = useState("");
  const [ rankIndex, setRankIndex ] = useState();
  const [ parametersRange, setParametersRange ] = useState(PARAMETER_PERCENTAGE);
  const [ inputDataScreeningTool, setInputDataScreeningTool ] = useState(DATA_SCREENING_TOOL.initialDataScreeningTool);
  const [ loadPercentage, setLoadPercentage ] = useState(false);

  useEffect(() => {
    getDataPage(NAME_PAGES.screening_tool.dashboard);
    if(!!selectedReservoirSCT){
      refetchQueryRankStats();
      refetchQueryMultipleScatter();
      refetchScreeningDonut();
      refetchScreeningDonutDepth();
      refetchScreening3DRank();
      refetchScreening3DRankDepth();
      refetchQueryFileScreening();
      refetchQueryListReservoir();
    };
  }, []);

  useEffect(() => {
    if (queryRankStats?.statusCode === STATUS_CODE_SCREENING_TOOL.SUCCESS && !!queryRankStats?.data[0]) {
      const dataRanks = queryRankStats?.data[0];
      const rankValue = Object.keys(dataRanks)[0];
      
      const type = dataRanks[rankValue];
      
      setSelectRank(rankValue)
      setQueryRankPie(dataRanks);
      setTypeRankpie(type);
    }else{
      setQueryRankPie([]);
      setTypeRankpie("");
      setSelectRank("")
    }
  }, [queryRankStats]);

  useEffect(() => {
    if (!!queryFileScreening) {
      
      const csvBlob = new Blob([queryFileScreening], { type: "text/csv" });
      
      const csvFile = new File([csvBlob], "Gas_lift_screening_data.csv", {
        type: "text/csv",
        lastModified: new Date(),
      });
      
      const formData = new FormData();
      formData.append('file', csvFile);
      
      const dataJson = convertCsvToJson(queryFileScreening);

      const validaDataJson = dataJson.map(item => ({
        "Well Name": item["Well Name"],
        "Oil Rate (STB/D)": parseFloat(item["Oil Rate (STB/D)"]),
        "Gas rate (Mscf/D)": parseFloat(item["Gas rate (Mscf/D)"]),
        "Water rate (STB/D)": parseFloat(item["Water rate (STB/D)"]),
        "Depth (ft)": parseInt(item["Depth (ft)"])
      }));
      
      if(Object.keys(validaDataJson).length){
        setDataFile(validaDataJson);
      }else{
        setDataFile([]);
      }
    }
  }, [queryFileScreening]);
  
  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    if (Object.keys(queryRankPie).length && selectRank) {
      
      const rankValue = Object.keys(queryRankPie);
      const rankIndex = rankValue.indexOf(selectRank);
      
      setRankIndex(rankIndex);
    };
  }, [queryRankPie, selectRank]);

  useEffect(() => {
    if(queryListReservoir?.statusCode === STATUS_CODE_SCREENING_TOOL.SUCCESS && !!queryListReservoir.data[0]) {
      const dataList = !!Object.keys(queryListReservoir?.data[0]).length ? queryListReservoir?.data[0] : [];
      const reservoir = dataList?.find(item => item.reservoir_id === selectedReservoirSCT);

      setInputDataScreeningTool({
        ...inputDataScreeningTool,
        reservoir_id: reservoir?.reservoir_id,
        reservoir_name: reservoir?.reservoir_name,
        reservoir_type: reservoir?.reservoir_type,
        country: reservoir?.country,
        state: reservoir?.state,
        field: reservoir?.field,
        gor: reservoir?.gor || 75,
        water_cut: reservoir?.water_cut || 75,
        oil_rate: reservoir?.oil_rate || 75,
      });

      setDataSlider({
        ...dataSlider,
        percentage:{
          slider_gor_percentage: reservoir?.gor || 75,
          slider_water_cut_percentage: reservoir?.water_cut || 75,
          slider_oil_rate_percentage: reservoir?.oil_rate || 75,
        }
      });

    }else{
      setInputDataScreeningTool(DATA_SCREENING_TOOL.initialDataScreeningTool)
    }
  }, [queryListReservoir]);

  const handleModal = (state, type) => {
    if (type === "crossplot") {
      setOpenModalCroosPlot(state);
    }
    setOpenModal3D(type === "3DChart" ? state : !type ? state : false);
  }

  const handleChangeSelect = (name, valueData) => {
    switch (name) {
      case "typeDonut":
        setSelectDonut(valueData);
        break;
      case "select_rank":
        setSelectRank(valueData);
        const rankValue = Object.keys(queryRankPie);
        const rankIndex = rankValue.indexOf(selectRank);
        
        setRankIndex(rankIndex);
        break;
      case "typeRank3D":
        setSelect3d(valueData);
        break;
      case "type_rank":
        setSelectTyperank(valueData);
      break;
      case "typeChartCrossplot":
        setTypeGraphMultipleScatter(valueData);
        const dataGraphQuery = queryMultipleScatter?.data[0];
        let combinedArray = [];

        switch (valueData) {
          case "oil_vs_water_cut":
            combinedArray = verifyTypeGraph(dataGraphQuery?.oil_rate_vs_water_cut, "oil_rate", "water_cut");
            setDataGraphMultipleScatter(combinedArray);
            break;
          case "gor_vs_water_cut":
            combinedArray = verifyTypeGraph(dataGraphQuery?.gor_vs_water_cut, "gor", "water_cut");
            setDataGraphMultipleScatter(combinedArray);
          break;
          case "oil_rate_vs_gor":
            combinedArray = verifyTypeGraph(dataGraphQuery?.oil_rate_vs_gor, "oil_rate", "gor");
            setDataGraphMultipleScatter(combinedArray);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleSavePercentage = () => {
    
    if(
      !inputDataScreeningTool.reservoir_name ||
      !inputDataScreeningTool.reservoir_type ||
      !inputDataScreeningTool.reservoir_id ||
      !inputDataScreeningTool.country ||
      !inputDataScreeningTool.state ||
      !inputDataScreeningTool.field 
      ||
      !Object.keys(dataFile).length ||
      !typeGraphMultipleScatter
    ){
      message.error("There are empty fields please validate that the information is complete.");
    }
    else{
      setLoadPercentage(true);
      const { reservoir_id, field, country, ...dataEditReservoir } = inputDataScreeningTool;
      const dataFinalEditReservoir = {
        ...dataEditReservoir, 
        data: [...dataFile],
        gor: dataSlider.percentage.slider_gor_percentage,
        water_cut: dataSlider.percentage.slider_water_cut_percentage,
        oil_rate: dataSlider.percentage.slider_oil_rate_percentage,
      }
      
      async function executeFunction() {
        await mutationUpdateScreening.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputDataScreeningTool.country,
          field: inputDataScreeningTool.field,
          reservoir: inputDataScreeningTool.reservoir_id,
          userName: User.user_name,
          data: dataFinalEditReservoir
        });

        await new Promise(resolve => setTimeout(resolve, 500));

        await Promise.all([
          refetchQueryRankStats(),
          refetchQueryMultipleScatter(),
          refetchScreeningDonut(),
          refetchScreeningDonutDepth(),
          refetchScreening3DRank(),
          refetchScreening3DRankDepth(),
          refetchQueryFileScreening(),
          refetchQueryListReservoir()
        ]);
        
        await new Promise(resolve => setTimeout(resolve, 500));
        
        handleModal(false, "crossplot");
        setLoadPercentage(false);
        message.success("Processing complete!");
      }
      executeFunction();
    }
  }

  const handleChangeSlider = (name, valueData) => {

    const { min, max } = parametersRange[name] || {};

    if (min !== undefined && max !== undefined) {
      const valueCalculate = calculateValue(min, max, valueData);
  
      setDataSlider((prevState) => ({
        ...prevState,
        percentage: {
          ...prevState.percentage,
          [`${name}_percentage`]: valueData,
        },
        value: {
          ...prevState.value,
          [name]: valueCalculate,
        },
      }));
    }
  };
  
  function verifyTypeGraph(data, value1, value2) {
    
    if(data && Object.keys(data).length){
      const dataGraph = data;
      const combinedArray = dataGraph[value1]?.map((value, index) => ({
        [value1]: value,
        [value2]: data[value2][index],
        cluster: `Cluster ${data.rank[index] || 'N/A'}`
      }));

      const parametersPercentage = {
        ...parametersRange,
        [`slider_${value1}`]: {
          min: Math.min(...data[value1]),
          max: Math.max(...data[value1])
        },
        [`slider_${value2}`]: {
          min: Math.min(...data[value2]),
          max: Math.max(...data[value2])
        }
      }

      setParametersRange(parametersPercentage);
      
      if (!dataSlider.value[`slider_${value1}`] || !dataSlider.value[`slider_${value2}`]) {
        const dataV = {
          ...dataSlider,
          value: {
            ...dataSlider.value,
            [`slider_${value1}`] : calculateValue(parametersPercentage[`slider_${value1}`].min, parametersPercentage[`slider_${value1}`].max, dataSlider.percentage[`slider_${value1}_percentage`]),
            [`slider_${value2}`] : calculateValue(parametersPercentage[`slider_${value2}`].min, parametersPercentage[`slider_${value2}`].max, dataSlider.percentage[`slider_${value2}_percentage`])
          }
        }
        
        setDataSlider(dataV);
      }

      return combinedArray;
    }{
      return [];
    }
  }
  
  function calculateValue(min, max, percentage) {
    
    return percentage ? Math.round(min + (percentage * (max - min)) / 100) : 0;
  }

  function convertCsvToJson(data) {
    if (!!data) {
      const lines = data.trim().split('\n');
      const headers = lines[0].split(',');
      const jsonData = lines.slice(1).map(line => {
        const values = line.split(',');
        const obj = {};
        headers.forEach((header, index) => {
          obj[header.trim()] = values[index].trim();
        });
        return obj;
      });
      return jsonData;
    }else{
      return [];
    }
  }
  
  const ButtonDowload = () => {
    
    if (!!Object.keys(dataFile).length) {
      const blob = new Blob([queryFileScreening], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Gas lift screening data.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(link.href);
    } else {
      message.info("The file could not be downloaded. Please check the file content.");
    }
  }
  
  return (
    <div className='flex flex-col w-full h-screen bg-white'>
      <Modal
        title={
          <span className="text-2xl font-bold">
            Crossplot
            {
              typeGraphMultipleScatter === "oil_vs_water_cut" 
              ? " Oil Rate vs Water Cut" 
              : typeGraphMultipleScatter === "gor_vs_water_cut" 
              ? " GOR vs Water Cut" 
              : typeGraphMultipleScatter === "oil_rate_vs_gor" 
              ? " Oil Rate vs GOR" 
              : ""}
          </span>
        }
        centered
        open={openModalCroosPlot}
        onOk={() => {handleModal(false, "crossplot")}}
        onCancel={() => { if (!loadPercentage) handleModal(false, "crossplot"); }}
        width="90%"
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]"
        }}
        footer={[
          <>
            <Button 
              type="primary"
              key={"button_crossplot"}
              className="bg-[#662D91] w-[100px] hover:bg-[#662D91]" 
              onClick={() => handleSavePercentage()}
            >
              Run
            </Button>
          </>
        ]}
        key={"crossplot"}
        maskClosable={!loadPercentage}
        closable={!loadPercentage}
      >
        {loadPercentage && (
          <div className="flex flex-col w-full h-[1000px] text-center mt-8">
            <Loading/>
          </div>
        )}
        {!loadPercentage && (

          <div className="flex flex-col w-full h-[1000px] text-center mt-8">
            <Select
              name="typeChartCrossplot"
              value={typeGraphMultipleScatter || "Selected option"}
              className="w-[200px] h-[30px] text-start mb-[17px]"
              onChange={(value) => handleChangeSelect("typeChartCrossplot", value)}
            >
              <Option value="oil_vs_water_cut">Oil Rate vs Water Cut</Option>
              <Option value="gor_vs_water_cut">GOR vs Water Cut</Option>
              <Option value="oil_rate_vs_gor">Oil Rate vs GOR</Option>
            </Select>
            <div 
              className={`flex w-full justify-center text-center mb-[0] h-[1350px]`}
            >
              <ChartMultipleScatter 
                dataGraph={dataGraphMultipleScatter} 
                type={typeGraphMultipleScatter}
              >
              </ChartMultipleScatter>
            </div>
            <div className='h-[160px] flex flex-wrap justify-center gap-4 ml-[100px]'>
              {(typeGraphMultipleScatter === "gor_vs_water_cut" || typeGraphMultipleScatter === "oil_rate_vs_gor") && (
                <div 
                  className={`flex w-auto h-[70px] items-center mt-[15px]`}
                >
                  <h1 className='justify-start w-[80px] font-bold'>Cut-off for GOR: </h1>
                  <Row>
                    <Col 
                      className="w-[500px] ml-2"
                      span={12}>
                      <Slider
                        min={0}
                        max={100}
                        onChange={(value) => handleChangeSlider("slider_gor", value)}
                        value={typeof dataSlider.percentage.slider_gor_percentage === 'number' ? dataSlider.percentage.slider_gor_percentage : 75}
                        tooltip={{
                          formatter: (value) => `${value}%`,
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={0}
                        style={{
                          margin: '0 16px',
                        }}
                        value={dataSlider.value.slider_gor}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {(typeGraphMultipleScatter === "oil_vs_water_cut" || typeGraphMultipleScatter === "gor_vs_water_cut") && (
                <div 
                  className={`flex w-auto h-[70px] items-center mt-[15px]`}
                >
                  <h1 className='justify-start w-[80px] font-bold'>Cut-off for Water Cut: </h1>
                  <Row>
                    <Col 
                      className="w-[500px] ml-2"
                      span={12}>
                      <Slider
                        min={0}
                        max={100}
                        onChange={(value) => handleChangeSlider("slider_water_cut", value)}
                        value={typeof dataSlider.percentage.slider_water_cut_percentage === 'number' ? dataSlider.percentage.slider_water_cut_percentage : 75}
                        tooltip={{
                          formatter: (value) => `${value}%`,
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={0}
                        style={{
                          margin: '0 16px',
                        }}
                        value={dataSlider.value.slider_water_cut}
                        readOnly  
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {(typeGraphMultipleScatter === "oil_vs_water_cut" || typeGraphMultipleScatter === "oil_rate_vs_gor") && (
                <div 
                  className={`flex w-auto h-[70px] items-center mt-[15px]`}
                >
                  <h1 className='justify-start w-[80px] font-bold'>Cut-off for Oil Rate: </h1>
                  <Row>
                    <Col 
                      className="w-[500px] ml-2"
                      span={12}>
                      <Slider
                        min={0}
                        max={100}
                        onChange={(value) => handleChangeSlider("slider_oil_rate", value)}
                        value={typeof dataSlider.percentage.slider_oil_rate_percentage === 'number' ? dataSlider.percentage.slider_oil_rate_percentage : 75}
                        tooltip={{
                          formatter: (value) => `${value}%`,
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        min={0}
                        style={{
                          margin: '0 16px',
                        }}
                        value={dataSlider.value.slider_oil_rate}
                        readOnly
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title={"3D Rank"}
        centered
        open={openModal3D}
        onOk={() => {handleModal(false)}}
        onCancel={() => {handleModal(false)}}
        width="70%"
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]"
        }}
        footer={[
          <Button type="primary" className="bg-[#662D91] hover:bg-[#662D91]" onClick={() => handleModal(false)}>
            OK
          </Button>
        ]}
      >
        <div className="flex flex-col w-full h-full text-center justify-center mt-8">
          <div 
            className={`flex w-full justify-center text-center mb-[0] h-full`}
          >

            {((!loadDataScreening3DRank || !loadDataScreening3DRankDepth) && (!!queryScreening3DRank || !!queryScreening3DRankDepth)) 
              ?
                <>
                  <div>
                    <ChartCrossOilGor 
                      graphicRank={queryScreening3DRank} 
                      graphicDepth={queryScreening3DRankDepth} 
                      enable={select3D}
                      className="flex flex-col w-[1200px] h-[600px] text-center justify-center mt-8"
                    />
                  </div>
                </>
              : (loadDataScreening3DRank || loadDataScreening3DRankDepth) ? 
                <div 
                  className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                >
                  <Loading/>
                </div>
              :
                <div>
                  <ChartCrossOilGor 
                    graphicRank={[]} 
                    graphicDepth={[]} 
                    enable={select3D}
                    className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                  />
                <label htmlFor=""></label>
                </div>
            }
          </div>
        </div>
      </Modal>
      <div className='flex'>
          <Navbar/>
          <Sidebar className="flex abosolute h-full"/>
      </div>
      {!!selectedReservoirSCT ?
      <>
        <div className='flex flex-col w-full pt-[40px] pr-[60px] pl-[140px]'>
          <h1>Reservoir</h1>
          <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={INITIAL_ITEMS} className=''/>
          {current === "tab1" &&
          <>
            <div className="flex flex-row w-[66.5%] gap-4 pt-5 justify-center items-center">
              <div className="flex w-[190px] h-[30px]">
                <button
                  className="flex w-[190px] h-[35px] justify-center text-center items-center text-[12px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1"
                  onClick={() => handleModal(true, "crossplot")}
                >
                  Crossplot
                </button>
              </div>
            </div>
            <div className='flex flex-row w-full h-full gap-6 pt-5 pb-10'>
              <div className='flex flex-col w-[70%] h-full gap-4'>
                <div className='w-full h-3/4 border border-solid border-gray-600'>
                  <div className='mt-[10px] ml-[10px] w-[350px] h-[30px] gap-2 flex items-start'>
                    <Select
                      name="typeRank3D"
                      value={select3D || "Selected option"}
                      className="w-[150px] h-[30px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("typeRank3D", value)}
                    >
                      <Option value="3drank">3D Graph</Option>
                      <Option value="3drankdepth">3D Graph Depth</Option>
                    </Select>
                  </div>
                  {((!loadDataScreening3DRank || !loadDataScreening3DRankDepth) && (!!queryScreening3DRank || !!queryScreening3DRankDepth)) 
                    ?
                      <>
                        <div>
                          <ChartCrossOilGor 
                            graphicRank={queryScreening3DRank} 
                            graphicDepth={queryScreening3DRankDepth} 
                            enable={select3D}
                            className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                          />
                        </div>
                      </>
                    : (loadDataScreening3DRank || loadDataScreening3DRankDepth) ? 
                      <div 
                        className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                      >
                        <Loading/>
                      </div>
                    :
                      <div>
                        <ChartCrossOilGor 
                          graphicRank={[]} 
                          graphicDepth={[]} 
                          enable={select3D}
                          className="flex flex-col w-full h-[28rem] text-center justify-center mt-8"
                        />
                      </div>
                  }
                </div>
                <div className='mt-[5px] '>
                  <label>{rankIndex !== -1 ? `Cluster ${rankIndex + 1}` : "Cluster"}</label>
                </div>
                <div className=' flex w-full h-1/3 border border-solid border-gray-600'>
                  <ChartTableAnalytics 
                    dataTable={queryRankPie} 
                    rank={selectRank}
                  >
                  </ChartTableAnalytics>
                </div>
              </div>
              <div className='flex flex-col w-1/3 h-[760px] gap-4'>
                <div className='w-full h-1/2 border border-solid border-gray-600'>
                  <div className='mt-[1px] items-center text-center '>
                    <label >Well distribution by cluster</label>
                  </div>
                  <div className='mt-[2px] ml-[10px] w-[300px]  h-[30px]'>
                    <Select
                      name="typeDonut"
                      value={selectDonut || "Selected option"}
                      className="w-full max-w-[200px] h-[25px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("typeDonut", value)}
                    >
                      <Option value="Donut">Clustering without depth</Option>
                      <Option value="DonutDepth">Clustering with depth</Option>
                    </Select>
                  </div>
                  <div className='h-[50px]'>
                    <ChartPieStatus 
                      dataDonut={queryScreeningDonut?.data} 
                      dataDonutDepth={queryScreeningDonutDepth?.data} 
                      status={selectDonut}
                    >
                    </ChartPieStatus>
                  </div>
                </div>
                <div className='w-full h-1/2 border border-solid border-gray-600'>
                  <div className=' items-center text-center '>
                    <label>Optimization Window {rankIndex !== -1 ? `Cluster ${rankIndex + 1}` : ""}</label>
                  </div>
                  <div className='flex mt-[1px] ml-[10px] w-[500px] h-[30px]'>
                    <Select
                      name="select_rank"
                      value={selectRank || "Selected option"}
                      className="w-full max-w-[200px] h-[30px] text-start mb-[17px]"
                      onChange={(value) => handleChangeSelect("select_rank", value)}
                    >
                      {Object.keys(queryRankPie)?.map((item, index)=> (
                        <Option 
                          key={index} 
                          value={item}
                        >
                          {`Cluster ${index + 1}`}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      name="type_rank"
                      value={selectTypeRank || "Selected option"}
                      className="w-full max-w-[200px] h-[30px] text-start mb-[17px] ml-[15px] "
                      onChange={(value) => handleChangeSelect("type_rank", value)}
                    >
                      {Object.keys(typeRankpie)?.filter(item => item !== "total_wells").map((item, index)=> (
                        <Option
                          key={index}
                          value={item}
                        >
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className='h-[310px]'>
                    <ChartPieStage 
                      dataRank={queryRankPie} 
                      rank={selectRank} 
                      typeRank={selectTypeRank} 
                    ></ChartPieStage>
                  </div>
                </div>
              </div>
            </div>
          </>
          }
          {current === "tab2" &&
            <div className='flex flex-col w-full h-full gap-6 pt-10 pb-10'>
            </div>
          }
          {current === "tab3" &&
            <div className='flex flex-row w-full h-full gap-6 pt-10 pb-10'>
              <div className='flex flex-col w-full h-[410px]  gap-4'>
                <div className='flex w-full h-full border border-solid border-gray-600'>
                <button 
                  className='ml-[15px] mt-[15px] flex w-[257px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1'
                  onClick={ButtonDowload}
                > Download file</button>
                </div>
              </div>
            </div>
          }
        </div>
      </>
      : 
        <div className="flex w-full h-full text-center items-center justify-center">
          <Alert
            description="Click on the plus sign button at the top left to select a reservoir"
            type="error"
            style={{fontSize: 20}}
          />
        </div>
      }
    </div>
  )
}
