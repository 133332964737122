import React, { useEffect, useState } from 'react'

const initialDataCreated = [{
    id:0,
    wellConfig:{
        fluidConfig:false,
        reservoir:false,
        tuning:false        
    },
    wellId:"",
    wellName:"",
    wellRegion:"",
    wellFiled:"",
    wellCountry:"",
}]

const initialDataWellTables = {
    wellDeviation:[],
    wellEquipment:[],
}

const initialDataFilterUserGasLift = {
    county:"",
    field:"",
    well_id:"",

}

export const useDataGasLift = () => {
    const [dataCreated, setDataCreated] = useState([]);
    const [dataFilterUserGasLift, setDataFilterUserGasLift] = useState(initialDataFilterUserGasLift);
    const [dataWell, setDataWell] = useState([]);
    const [dataWellTables, setDataWellTables] = useState(initialDataWellTables);
    const [dataFluid, setDataFluid] = useState([]);
    const [datReservoir, setReservoir ] = useState([]);
    const [enableCheckLab, setEnableCheckLab] = useState(false);
    const [lastSelectDataIPR, setLastSelectDataIPR] = useState()

    useEffect(()=>{
        const getDataCreated = JSON.parse(sessionStorage.getItem('dataCreated'));
        const getDataFilterUserGaslift = JSON.parse(sessionStorage.getItem('dataFilterUserGaslift'));
        const getDataWellTables = JSON.parse(sessionStorage.getItem('dataWellTables'));
        const getDataWell =JSON.parse(sessionStorage.getItem('dataWell'));
        const getDataFluid =JSON.parse(sessionStorage.getItem('dataFluid'));
        const getDataReservoir =JSON.parse(sessionStorage.getItem('dataReservoir'));
        const getEnableCheckLab = sessionStorage.getItem('enableCheckLab');
        const getSelectDataIPR = sessionStorage.getItem('lastSelectDataIPR');

        if (getDataCreated) {
            setDataCreated(getDataCreated);
        } 
        if (getDataFilterUserGaslift) {
            setDataFilterUserGasLift(getDataFilterUserGaslift);
        } 
        if (getDataWellTables) {
            setDataWellTables(getDataWellTables);
        } 
        if (getDataWell) {
            setDataWell(getDataWell);
        } 
        if (getDataFluid) {
            setDataFluid(getDataFluid);
        } 
        if (getDataReservoir) {
            setReservoir(getDataReservoir);
        } 
        if (getEnableCheckLab) {
            setEnableCheckLab(getEnableCheckLab)
        }
        if (getSelectDataIPR) {
            setLastSelectDataIPR(getSelectDataIPR)
        }
    },[])

    const getDataCreated = (options) => {
        setDataCreated(options);
        sessionStorage.setItem('dataCreated', JSON.stringify(options));
    }
    const getDataFilterUserGaslift = (options) => {
        setDataFilterUserGasLift(options);
        sessionStorage.setItem('dataFilterUserGaslift', JSON.stringify(options));
    }
    const getDataWellTables = (options) => {
        setDataWellTables(options);
        sessionStorage.setItem('dataWellTables', JSON.stringify(options));
    }
    const getDataWell = (options) => {
        setDataWell(options);
        sessionStorage.setItem('dataWell', JSON.stringify(options));
    }
    const getDataFluid = (options) => {
        setDataFluid(options);
        sessionStorage.setItem('dataFluid', JSON.stringify(options));
    }
    const getDataReservoir = (options) => {
        setReservoir(options);
        sessionStorage.setItem('dataReservoir', JSON.stringify(options));
    }
    const handleSelectWellGaslift = (wellId) => {
        setDataFilterUserGasLift(wellId);
        sessionStorage.setItem('dataFilterUserGaslift', wellId);
    }

    const getEnableCheckLab = (options) => {
        setEnableCheckLab(options);
        sessionStorage.setItem('enableCheckLab', options);
    }

    const getSelectDataIPR = (options) => {
        setLastSelectDataIPR(options);
        sessionStorage.setItem('lastSelectDataIPR', JSON.stringify(options))
    }

    // const getDataHistoryGasLift = (wellId) => {
    //     setDataHistoryGasLift(wellId);
    //   sessionStorage.setItem('dataFilterUserGaslift', wellId);
    // }

  return {
    dataCreated,
    dataFilterUserGasLift,
    dataWellTables,
    dataWell,
    dataFluid,
    datReservoir,
    lastSelectDataIPR,
    getDataCreated,
    getDataFilterUserGaslift,
    getDataWellTables,
    getDataWell,
    getDataFluid,
    getDataReservoir,
    handleSelectWellGaslift,
    getEnableCheckLab,
    enableCheckLab,
    getSelectDataIPR
  }
}
