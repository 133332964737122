import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_TUNING = config.API.TUNING.BASE_URL_TUNING;
const BASE_URL_TUNING_VLP = config.API.TUNING.BASE_URL_TUNING_VLP + "/VLP";
const TOKEN_TUNING = config.AUTH.TOKEN_TUNING;
const TOKEN_VLP_GAS_LIFT = config.AUTH.TOKEN_VLP_GAS_LIFT;
const TOKEN_SCENARIOS_VLP_GAS_LIFT = config.AUTH.TOKEN_SCENARIOS_VLP_GAS_LIFT;

export const getEspecificTuning = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_TUNING,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data tuning");
	}
};
export const getEspecificWell = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parametersgaslift`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_TUNING,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data tuning");
	}
};

export const postTuningAdd = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_TUNING,
				},
				body: JSON.stringify(data),
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data tuning");
	}
};

export const putTuningUpdate = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_TUNING,
				},
				body: JSON.stringify(data),
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data tuning");
	}
};

export const deleteTuning = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username } = dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_TUNING,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data tuning");
	}
};

export const emptyTuningUpdate = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/parameterstuning`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_TUNING,
				},
				body: JSON.stringify(data),
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data tuning");
	}
};

export const getListTuning = async (operator, lease, region, username) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING}/parameterslist/${operator}/${lease}/${region}/${username}/parameterstuning`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_TUNING,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data tuning");
	}
};

export const postTuningCalculateVLP = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/upload_json_VLP/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_VLP_GAS_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data tuning");
	}
};

export const putTuningCalculateVLP = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/update_json_VLP/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_VLP_GAS_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data tuning");
	}
};

export const getTuningCalculateVLP = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/calculate_vlp/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data tuning");
	}
};

export const getUnTunedCalculateVLP = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/calculate_vlp_untuned/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data tuning");
	}
};

export const getPressDrowDown = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/press_drow_down/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data tuning");
	}
};

export const getListDataChartVLP = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, username))
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/list_vlp/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_SCENARIOS_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data list chart VLP");
	}
};

export const getDataChartVLP = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	username,
	nameChart,
) => {
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			wellid,
			username,
			nameChart,
		)
	)
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/vlp_graph/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/${nameChart}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_SCENARIOS_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem in data chart VLP");
	}
};

export const postDataChartVLP = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, data } =
		dataJson;
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			wellid,
			username,
			data,
		)
	)
		return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/upload_json_graphic/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_SCENARIOS_VLP_GAS_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem save data chart VLP");
	}
};

export const deleteScenarioChartVLP = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username, nameChart } =
		dataJson;
	if (!validateParams(dataJson)) return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/deleted_json_graphic/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}/${nameChart}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_SCENARIOS_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem delete data chart VLP");
	}
};

export const deleteScenariosChartVLP = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, username } = dataJson;
	if (!validateParams(dataJson)) return;
	try {
		const reponse = await fetch(
			`${BASE_URL_TUNING_VLP}/delete_all_graphic/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_SCENARIOS_VLP_GAS_LIFT,
				},
			},
		);
		return reponse.json();
	} catch (error) {
		throw new Error("Problem delete data chart VLP");
	}
};
