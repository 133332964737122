import React, { useState, useEffect, useRef } from 'react';
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ComposedChart,
  Line,
} from 'recharts';
import { WELL_SENSOR_DEVICE } from '../../elements/types/type_dashboard';

export const ChartProdHistory = (props) => {

  const { dataDomainGraph, typeGraph, y, bottom, dataGrapIOT, typeDevicePH, dataHistory, statusGraph, dataAverage, dataAverageRT, wellId} = props
  const chartRef = useRef(); // Referencia para el contenedor del gráfico
  const [dataIOT, setDataIOT] = useState();
  const averageData = !!dataAverage ? dataAverage : null
  const averageDataRT = !!dataAverageRT ? dataAverageRT : null
  const validateDataProp = !!dataIOT || !!dataHistory;

  const rateHisMax = !!dataDomainGraph?.rate_max_ph && validateDataProp ? dataDomainGraph?.rate_max_ph : 0;
  const rateHisMin = !!dataDomainGraph?.rate_min_ph && validateDataProp ? dataDomainGraph?.rate_min_ph : 0;
  const cumMax = !!dataDomainGraph?.cum_max_ph && validateDataProp ? dataDomainGraph?.cum_max_ph : 0;
  const cumMin = !!dataDomainGraph?.cum_min_ph && validateDataProp ? dataDomainGraph?.cum_min_ph : 0;
  
  const [yAxisDomain1, setYAxisDomain1] = useState([rateHisMin, rateHisMax]);
  const [yAxisDomain2, setYAxisDomain2] = useState([cumMin, cumMax]);
  let [valueOffset, setValueOffset] = useState();
  const [dataFinalGraph, setDataFinalGraph] = useState([]);
  const dataDeviceReplace = typeDevicePH === WELL_SENSOR_DEVICE?.data[6].sensor ? WELL_SENSOR_DEVICE?.data[5].sensor : typeDevicePH; 

  useEffect(() => {
    if(!!dataGrapIOT){
      
      const dataIOT = dataGrapIOT?.filter((item) => {
        return item["LSD"] === typeDevicePH &&
        item["Oil Rate"] !== -999.25 &&
        item["Gas Rate"] !== -999.25 &&
        item["Water Rate"] !== -999.25 
      });
      setDataIOT(dataIOT);
      
    }
  }, [dataGrapIOT, typeDevicePH]);

  useEffect(() => {
    // Actualizar el dominio de los ejes Y según los nuevos valores de los inputs
    setYAxisDomain1([rateHisMin, rateHisMax]);
    setYAxisDomain2([cumMin, cumMax]);
  }, [rateHisMin, rateHisMax, cumMin, cumMax]);
  
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chartNode = chartRef.current;
        const offsetTop = chartNode.offsetTop;
        // Obtener el tamaño de la pantalla
        const screenHeight = window.innerHeight;
                
        setValueOffset(screenHeight - offsetTop + bottom);

      }
    };
    // Agregar el listener de cambio de tamaño de ventana
    window.addEventListener('resize', handleResize);
    // Llamar a handleResize al montar el componente para obtener el valor inicial
    handleResize();
    // Limpiar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const formattedData = dataIOT?.map((item, index) => ({
      date: String(item["Date (MM/DD/YYYY)"]).split(" ")[0],
      "oil_rate": item["Oil Rate"],
      "gas_rate": item["Gas Rate"],
      "water_rate": item["Water Rate"]
    }));
    
    if(!!dataHistory && statusGraph === false){

      const dataHistoryMod = dataHistory?.map((item, index) => ({
        "date": String(item["date"])?.split(" ")[0],
        "water_rate": item["water_rate"]?.toFixed(2),
        "gas_rate": item["gas_rate"]?.toFixed(2),
        "oil_rate": item["oil_rate"]?.toFixed(2)
      })).filter(item => 
        item.water_rate !== undefined && 
        item.gas_rate !== undefined && 
        item.oil_rate !== undefined
      );
      let filteredData = null;
      let filteredDataRT = null;
      const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
      
        return `${parseInt(month)}/${parseInt(day)}/${year}`;
      };

      if (!!averageData) {
        Object.keys(averageData)?.forEach(key => {
          if (key === wellId) {
      
            const dateKey = Object.keys(averageData[key]); 

            const everydate = dateKey?.map(date => {

              const rateData = averageData[key][date];
              
              return {
                date: formatDate(date),
                gas_rate: rateData?.gas_rate_avg?.toFixed(2),
                oil_rate: rateData?.oil_rate_avg?.toFixed(2),
                water_rate: rateData?.water_rate_avg?.toFixed(2)
              };
            })
            filteredData = everydate
          }
        });
      }
      if (!!averageDataRT && !!dataDeviceReplace) {
        const result = averageDataRT?.find(item => item.LSD === dataDeviceReplace);
        filteredDataRT = {
          "date": (result["Date (MM/DD/YYYY)"]),
          "Gas Rate": result["Gas Rate"],
          "Water Rate": result["Water Rate"],
          "Oil Rate": result["Oil Rate"]
        };
        
      }

      const combineddata = dataHistoryMod.concat(filteredData,filteredDataRT)
      setDataFinalGraph(combineddata);
      
    } else{
      setDataFinalGraph(formattedData);
    }
    
  }, [dataIOT, dataHistory, statusGraph, averageData, averageDataRT,dataDeviceReplace])


  const CustomDot = ({ cx, cy, payload, index, color }) => {
    // Solo muestra el punto si el índice es un múltiplo de 5
    if ((index + 1) % 1 === 0) {
      return (
        <circle cx={cx} cy={cy} r={2} stroke={color} fill={color} />
      );
    }
    return null; // No muestra el punto
  };
  const formatNumber = (value) => {
    if (!isNaN(value) && value !== "") {
      const num = parseFloat(value);
      return num % 1 === 0 ? num : num.toFixed(2);
    }
    return value;
  };
  function customTooltip({ active, payload, option }) {
    if (active && payload && payload.length) {
      const valuesData = payload[0]?.payload;

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "5px", textAlign: "left" }}
        >
          <p>
            {`Date: `}
            <span>{valuesData?.date} </span>
          </p>
          <p style={{ color: "#2bca41"}}>
            {`Oil: `}
            <span>{formatNumber(valuesData?.oil_rate)} (STB/D)</span>
          </p>
          <p style={{ color: "#194bff"}}>
            {`Water: `}
            <span>{formatNumber(valuesData?.water_rate)}(STB/D)</span>
          </p>
          <p style={{ color: "#ce0f41"}}>
                {`Gas: `}
            <span>{formatNumber(valuesData?.gas_rate)}  (MSCF/D)</span>
          </p>
          
        </div>
      );
    }
  
    return null;
  }
  
  return (
    <div className="w-full h-full" ref={chartRef}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={200}
          data={dataFinalGraph}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: bottom,
          }}
        >
          <Brush dataKey="Time (days)" height={10} stroke="#8884d8" y={valueOffset} />
          <CartesianGrid/>
          <XAxis
            dataKey="date"
            label={{
              value: 'Date', position: 'bottom',
              fill: 'black'
            }}
            tickCount={10} // Número de ticks en el eje X
            //tick={{ fill: 'black' }}
            fill='black'
            //tick={<CustomizedAxisTick />}
            padding={{ button: 30 }}
          />
          <YAxis
            yAxisId={1}
            domain={yAxisDomain1}
            allowDataOverflow={true}
            label={{
              value: 'Liquid Rates (STB/D)', 
              style: { textAnchor: "middle" },
              angle: -90, 
              position: 'left',
              offset: 0,
            }}
            tickCount={10} // Número de ticks en el eje Y de RATE
            tick={{ fill: 'black' }}
            fill='black'
            padding={{ bottom: 10 }}
          />
          <YAxis
            yAxisId={2}
            orientation="right"
            domain={yAxisDomain2}
            allowDataOverflow={true}
            label={{
              value: 'Gas Rate (MSCF/D)', 
              angle: -90, 
              position: 'right',
              style: { textAnchor: "middle" }
            }}
            fill='black'
            padding={{ bottom: 10 }}
          />
          <Tooltip content={customTooltip}/>
          <ReferenceLine y={0} stroke="#000" yAxisId={1} />
          {typeGraph === 'Bar' ? (
            <>
              <Bar dataKey="oil_rate" name="Oil" fill="#2bca41" yAxisId={1} />
              <Bar dataKey="water_rate" name="Water" fill="#194bff" yAxisId={1} /> 
              <Bar dataKey="gas_rate" name="Gas" fill="#ce0f41" yAxisId={2} />
            </>
          ) : (
            <>
              
              <Line dataKey="oil_rate" name="Oil" fill="#2bca41" stroke='#2bca41' yAxisId={1} dot={<CustomDot color="#2bca41" />}/>
              <Line dataKey="water_rate" name="Water" fill="#194bff" stroke='#194bff' yAxisId={1} dot={<CustomDot color="#194bff" />}/>
              <Line dataKey="gas_rate" name="Gas" fill="#ce0f41" stroke='#ce0f41' yAxisId={2} dot={<CustomDot color="#ce0f41" />}/>
            </>)}
            
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};