export const template_params_history_plunger_lift = {
  columns: [
    { header: "date", key: "date" },
    { header: "gas_rate (MSCF/D)", key: "gas_rate (MSCF/D)" },
    { header: "liquid_rate (STB/D)", key: "liquid_rate (STB/D)" },
    { header: "casing_pressure (psia)", key: "casing_pressure (psia)" },
    { header: "tubing_pressure (psia)", key: "tubing_pressure (psia)" },
    { header: "pipeline_pressure (psia)", key: "pipeline_pressure (psia)" },
  ],

  rows: [],
};
export const template_params_alarm_plunger_lift = {
  columns: [
    { header: "item", key: "item" },
    { header: "Description", key: "Description" },
    { header: "min", key: "min" },
    { header: "max", key: "max" },
  ],
  rows: [
    ["alm_GLRrequired", "Artificial Lift Metrics GLR required", 0, 0],
    ["alm_GLRproduced", "Artificial Lift Metrics GLR produced", 0, 0],
    [
      "alm_casing_pressure_required",
      "Artificial Lift Metrics Casing pressure required ",
      0,
      0,
    ],
    [
      "alm_casing_pressure_available",
      "Artificial Lift Metrics Casing pressure available",
      0,
      0,
    ],
    ["alm_cycle_day", "Artificial Lift Metrics Cycles per day", 0, 0],
    ["twc_Pressure", "Tubing Wellhead Conditions Pressure", 0, 0],
    ["twc_temperature", "Tubing Wellhead Conditions Temperature", 0, 0],
    ["em_npv", "Economics Metrics NPV", 0, 0],
    ["em_roi", "Economics Metrics ROI", 0, 0],
    ["cwc_Pressure", "Casing Wellhead Conditions Pressure ", 0, 0],
    ["cwc_temperature", "Casing Wellhead Conditions Temperature", 0, 0],
    ["pic_Pressure", "Pipeline Conditions Pressure", 0, 0],
    ["pic_temperature", "Pipeline Conditions Temperature", 0, 0],
    ["prc_GasRate", "Production Conditions Gas Rate", 0, 0],
    ["prc_LiquidRate", "Production Conditions Liquid Rate", 0, 0],
    ["prc_Glr", "Production Conditions GLR", 0, 0],
    ["prc_GasVolumePer", "Production Conditions Gas Volume per Cycle", 0, 0],
    [
      "prc_LiquidVolumPer",
      "Production Conditions Liquid volume per Cycle",
      0,
      0,
    ],
  ],
};
export const template_params_alarm_gas_lift = {
  columns: [
    { header: "item", key: "item" },
    { header: "min", key: "min" },
    { header: "max", key: "max" },
  ],
  rows: [
    ["twc_pressure", 0, 0],
    ["twc_temp", 0, 0],
    ["awc_pressure", 0, 0],
    ["awc_temp", 0, 0],
    ["pc_oil_rate", 0, 0],
    ["pc_gas_rate", 0, 0],
    ["pc_water_rate", 0, 0],
    ["pc_gor", 0, 0],
    ["pc_wor", 0, 0],
    ["alm_injection", 0, 0],
    ["alm_inj_gas", 0, 0],
    ["alm_inj_GLR", 0, 0],
    ["alm_formation", 0, 0],
    ["alm_water_cut", 0, 0],
    ["em_eur", 0, 0],
    ["em_npv", 0, 0],
    ["bhc_pressure", 0, 0],
    ["bhc_temp", 0, 0],
  ],
};
