import React from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const CustomTooltip = ({ active, payload}) => {
  if (active && payload && payload.length) {

    return (
      <div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Pwf = ${Math.round(payload[0].payload?.steps)} psia`}</p>
        <p className="label text-[#873d01]">{`Liquid Rate = ${Math.round(payload[0].payload.qliq_pet)} STB/D`}</p>
        <p className="label text-[#008000]">{`Oil Rate = ${Math.round(payload[0].payload.qo_pet)} STB/D`}</p>
        <p className="label text-[#0000ff]">{`Water Rate = ${Math.round(payload[0].payload.qw_pet)} STB/D`}</p>
      </div>
    );
  }

  return null;
};
const ChartCompositePL = ({ data }) => {
  const isValidData = Array.isArray(data) && data.length > 0;

// Maxvaluey initialize safely
  const maxValueY = isValidData ? Math.max(...data.map(d => d.steps)) : 0;

// logic to determine Tickstepy based on Maxvaluey
  let tickStepY = 100; // Predetermined value
  if (maxValueY > 100000) {
    tickStepY = 10000;
  } else if (maxValueY > 10000) {
    tickStepY = 1000;
  } else if (maxValueY > 1000) {
    tickStepY = 500;
  } else if (maxValueY > 100) {
    tickStepY = 100;
  } else if (maxValueY > 10) {
    tickStepY = 10;
  } else if (maxValueY > 0) {
    tickStepY = 1;
  }

// Calculate Roundedmaxy and generates ticksy safely
  const roundedMaxY = isValidData ? Math.ceil(maxValueY / tickStepY) * tickStepY : 0;
  const ticksY = isValidData ? Array.from({ length: Math.ceil(roundedMaxY / tickStepY) + 1 }, (_, i) => i * tickStepY) : [];

// Find the maximum value of QO
  const maxValue =  isValidData ?Math.max(...data?.map(d => d.qo)): 0;

  //Determine the tick passage based on the maximum value
  let tickStep;
  if (maxValue <= 10) {
    tickStep = 1; // Adjust for very small values, every 1 unit
  } else if (maxValue <= 100) {
    tickStep = 10; // For values ​​up to 100, every 10 units
  } else if (maxValue <= 1000) {
    tickStep = 100; // For values ​​up to 1000, every 100 units
  } else {
    tickStep = 1000; // For larger values, every 1000 units
  }
// Adjust for your specific case, where ticks must be every 5 if the maximum is 23
  if (maxValue <= 25) {
    tickStep = 5;// every 5 units for values ​​up to 25
  }
// Calculate the maximum value adjusted to the Tick step and generates the tickets
  const roundedMax =  isValidData ?Math.ceil(maxValue / tickStep) * tickStep: 0;
  const ticks =  isValidData ? Array.from({ length: Math.ceil(roundedMax / tickStep) + 1 }, (_, i) => i * tickStep): [];

  return (
    <div className="flex w-[700px] h-[510px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={500}
          data={data}
          margin={{
            left: 5,
            bottom: 30,
            top: 30
          }}
          layout="vertical"
        >
          <XAxis
            tickCount={10}
            label={{
              value: `Liquid Rate (STB/D)`,
              position: 'insideBottom ',
              style: { textAnchor: "middle" },
              dy: 20,
            }}
            allowDataOverflow={true} // allows the data to exceed the limits established by Domain
            type='number'
          />
          <YAxis
            dataKey="steps"
            domain={[0, roundedMaxY]}
            tickCount={10} // Manually specify ticks
            label={{
              value: `Pwf (psia)`,
              angle: -90,
              position: 'insideLeft',
              offset: 0,
              dy: 20,
            }}
            type="number"
            
            margin={{ left: 10 }}
            allowDataOverflow={true}
            reversed
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            layout="horizontal"     // Options: "Vertical", "Horizontal"
            align="center"        // Opciones: "left", "center", "right"
            wrapperStyle={{ bottom: '5px', right: 0, lineHeight: 0 }} // Adjust the style to move the legend
            payload={[
              { value: 'Liquid Rate', type: 'line', color: '#873d01' },
              { value: 'Oil Rate', type: 'line', color: '#008000' },
              { value: 'Water Rate', type: 'line', color: '#0000ff' },
            ]}
          />
          <Line type="monotone" dataKey="qliq_pet" stroke="#873d01" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="qo_pet" stroke="#008000" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="qw_pet" stroke="#0000ff" activeDot={{ r: 8 }} />        
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartCompositePL;
