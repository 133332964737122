import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_PRODUCTION_TEST = config.API.PLOTTING.URL_PRODUCTION_TEST;
const TOKEN_PRODUCTION_TEST = config.AUTH.TOKEN_PRODUCTION_TEST;

export const uploadProductionTest = async (dataJson) => {
	const { operator, lease, region, userName, data } = dataJson;
	if (!validateParams(operator, lease, region, userName)) return;

	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/upload_test/${operator}/${lease}/${region}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data of production test");
	}
};

export const updateProductionTest = async (dataJson) => {
	const { operator, lease, region, userName, testName, data } = dataJson;
	if (!validateParams(operator, lease, region, userName, testName)) return;

	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/update_test/${operator}/${lease}/${region}/${userName}/${testName}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data of production test");
	}
};

export const getListProductionTest = async (
	operator,
	lease,
	region,
	userName,
) => {
	if (!validateParams(operator, lease, region, userName))
		return;
	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/list_test/${operator}/${lease}/${region}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the data");
	}
};

export const getDataProductionTest = async (
	operator,
	lease,
	region,
	userName,
	testName,
) => {
	if (!validateParams(operator, lease, region, userName,testName))
		return;

	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/production_test/${operator}/${lease}/${region}/${userName}/${testName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the data");
	}
};

export const deleteProductionTest = async (dataJson) => {
	const { operator, lease, region, userName, data, testName, } = dataJson;
	if (!validateParams(operator, lease, region, userName, testName)) return;

	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/delete_test/${operator}/${lease}/${region}/${userName}/${testName}`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem deleting the data of production test");
	}
};

export const uploadData = async (dataJson) => {
	const { process, operator, lease, region, userName, data, testName } = dataJson;
	if (!validateParams(process, operator, lease, region, userName, testName)) return;

	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/${process}/${operator}/${lease}/${region}/${userName}/${testName}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating the data of production test");
	}
};

export const getDataProductionTestAnalysis = async (
	process,
	operator,
	lease,
	region,
	userName,
	testName,
) => {
	if (!validateParams(process,operator, lease, region, userName,testName))
		return;

	try {
		const response = await fetch(
			`${DB_URL_PRODUCTION_TEST}/${process}/${operator}/${lease}/${region}/${userName}/${testName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PRODUCTION_TEST,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the data");
	}
};
