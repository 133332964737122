import React, { useEffect, useState } from "react";
import { WELL_FILTER } from "../components/elements/types/type_dashboard";

const initialViewLoadingSC = {
	loadSaveParameters: false,
	loadFetchDashboard: false,
};

export const useDashboardSCT = () => {
	const [dataFilterUserSCT, setDataFilterUserSCT] = useState(WELL_FILTER);
	const [viewLoadingSC, setViewLoadingSC] = useState(initialViewLoadingSC);

	useEffect(() => {
		const getDataFilterUserSCT = JSON.parse(
			sessionStorage.getItem("dataFilterUserSCT"),
		);
		if (getDataFilterUserSCT) {
			setDataFilterUserSCT(getDataFilterUserSCT);
		}
	}, []);

	const getDataFilterUserSCT = (options) => {
		setDataFilterUserSCT(options);
		sessionStorage.setItem("dataFilterUserSCT", JSON.stringify(options));
	};

	const getViewLoadingSC = (options) => {
		setViewLoadingSC(options);
	};

	return {
		dataFilterUserSCT,
		viewLoadingSC,
		getDataFilterUserSCT,
		getViewLoadingSC,
	};
};
