import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import {
	createParametersPlotting,
	deleteParametersPlotting,
	getParametersPlotting,
	updateParametersPlotting,
	getPlatformsListPlotting,
	getDevicesListPlotting,
	getParametersListPlotting,
	getIOTWellData,
} from "../services/api/plotting";
import { DashboardPlottingContext } from "../context/DashboardPlotting/DashboardPlottingContext";
import { INITIAL_DATA_PLOTTING } from "../components/elements/types/type_plotting";
import { AuthContext } from "../auth/context/AuthContext";
import { message } from "antd";
import { STATUS_TYPE_PLOTTING } from "../components/elements/types/type_response_API";
import {
	deleteProductionTest,
	getDataProductionTest,
	getListProductionTest,
	uploadProductionTest,
	uploadData,
	updateProductionTest,
	getDataProductionTestAnalysis,
} from "../services/api/testProductionAPI";

export const useTabPlotting = () => {
	const { userDataInfo } = useContext(DashboardContext);
	const {
		dataPlotting,
		getDataPlotting,
		getViewLoadingPlotting,
		viewLoadingPlotting,
		productionTest
	} = useContext(DashboardPlottingContext);
	const { User } = useContext(AuthContext);
	const [concatenatedData, setConcatenatedData] = useState([]);
	const [inputDataPlot, setInputDataPlot] = useState(INITIAL_DATA_PLOTTING);
	const [optionsAllIOT, setOptionsAllIOT] = useState([]);
	const [startDateIOT, setStartDateIOT] = useState();
	const [endDateIOT, setEndDateIOT] = useState();

	const {
		data: dataListPlatforms,
		isLoading: loadDataListPlatforms,
		isError: ErrorDataListPlatforms,
		refetch: refetchDataListPlatforms,
	} = useQuery({
		queryKey: ["dataListPlatforms"],
		queryFn: () =>
			getPlatformsListPlotting(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataListDevices,
		isLoading: loadDataListDevices,
		isError: ErrorDataListDevices,
		refetch: refetchDataListDevices,
		isSuccess,
	} = useQuery({
		queryKey: ["dataListDevices", inputDataPlot.selected_platform],
		queryFn: () =>
			getDevicesListPlotting(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataPlotting.selected_platform.length > 0
					? dataPlotting.selected_platform
					: [],
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataListParameters,
		isFetching: isFetchingListParameters,
		isLoading: loadDataListParameters,
		refetch: refetchDataListParameters,
	} = useQuery({
		queryKey: ["dataListParameters"],
		queryFn: () =>
			getParametersListPlotting(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataPlotting?.selected_device.length > 0
					? JSON.stringify(dataPlotting?.selected_device)
					: [],
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			const optionsSelect =
				data?.data.map((item, index) => ({
					label: item,
					value: item,
				})) || [];
			setOptionsAllIOT(
				optionsSelect?.sort((a, b) => a.label.localeCompare(b.label)),
			);
		},
		onError: (error) => {
			console.error("Error al obtener parámetros:", error);
		},
	});

	const {
		data: dataParamsPlotting,
		isLoading: loadDataParamsPlotting,
		isError: ErrorDataParamsPlotting,
		refetch: refetchParamsPlotting,
	} = useQuery({
		queryKey: ["dataParamsPlotting"],
		queryFn: () =>
			getParametersPlotting(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (responseData) => {
			const response = responseData?.data;
			// Verifica si data?.data[0] es null
			if (!response) {
				getDataPlotting(INITIAL_DATA_PLOTTING);
			} else {
				// Solo ejecuta getDataPlotting si data?.data[0] es diferente de null
				getDataPlotting({
					selected_platform: response?.data?.selected_platform,
					selected_device: response?.data?.selected_device,
					selected_parameter: response?.data?.selected_parameter,
					dataPlotting: response?.data?.dataPlotting,
					startDateIOT: response?.data?.startDateIOT,
					endDateIOT: response?.data?.endDateIOT,
				});
			}
		},
		onError: (error) => {
			// Manejo de errores si es necesario
		},
	});

	const addParameters = useMutation({
		mutationFn: createParametersPlotting,
		retry: 1,
		onMutate: () => {
			getViewLoadingPlotting({
				...viewLoadingPlotting,
				loadSaveParameters: true,
			});
		},
		onSuccess: () => {
			message.success("Data save successfull");
			getViewLoadingPlotting({
				...viewLoadingPlotting,
				loadSaveParameters: false,
			});
		},
		onError: () => {
			getViewLoadingPlotting({
				...viewLoadingPlotting,
				loadSaveParameters: false,
			});
		},
	});

	const cleanParameters = useMutation({
		mutationFn: deleteParametersPlotting,
		onSuccess: () => {},
		onError: () => {},
	});

	const modifyParameters = useMutation({
		mutationFn: updateParametersPlotting,
		onMutate: () => {
			getViewLoadingPlotting({
				...viewLoadingPlotting,
				loadSaveParameters: true,
			});
		},
		onSuccess: () => {
			getViewLoadingPlotting({
				...viewLoadingPlotting,
				loadSaveParameters: false,
			});
			message.success("Data update successfull");
		},
		onError: () => {
			getViewLoadingPlotting({
				...viewLoadingPlotting,
				loadSaveParameters: false,
			});
		},
	});

	// Función para obtener el color específico desde el archivo de configuración
	const getSpecificColor = (parameter) => {
		const colorMapBySubstring = {
			device_temperature: "#c8a600", // Yellow
			device_differential_pressure: "#A349A4", // Pink
			device_pressure: "#800080", // Purple
			" pressure": "#000000", // Black
			casing_pressure: "#800080", // Purple
			liquid_accumulated_volume: "#8B4513", // Dark Brown
			liquid_current_day_volume: "#8B4513", // Dark Brown
			turbine_total_volume: "#00008B", // Dark Blue
			fresh_water_tank_level: "#00008B", // Dark Blue
			gun_barrel_tank_level: "#8B4513", // Dark Brown
			oil_tank_level: "#90EE90", // Light Green
			open_air_tank_level: "#8B4513", // Dark Brown
			swd_tank_level: "#00008B", // Dark Blue
			water_tank_level: "#00008B", // Dark Blue
			oil_flow_rate: "#90EE90", // Light Green
			oil_previous_day_volume: "#006400", // Dark Green
			oil_current_day_volume: "#006400", // Dark Green
			oil_net_accumulated_volume: "#006400", // Dark Green
			gas_previous_day_volume: "#8B0000", // Dark Red
			gas_flow_rate: "#FF0202", // Light Red
			gas_current_day_volume: "#8B0000", // Dark Red
			gas_accumulated_volume: "#8B0000", // Dark Red
			liquid_flow_rate: "#BF6B21", // Light Brown
			liquid_previous_day_volume: "#8B4513", // Dark Brown
			Water_rate: "#ADD8E6", // Light Blue
			Water_volume: "#00008B", // Dark Blue
		};

		const fullParameterExceptions = {
			"V-191 Well Head Pressure C6": "#000000", // Black
			"V-191 Well Head Pressure C9": "#000000", // Black
			"V-191 C6 Well Head  pressure": "#000000", // Black
			"V-191 C9 Well Head  pressure": "#000000", // Black
			"V-191 C6 Well Head temperature": "#c8a600", // Yellow
			"V-191 C9 Well Head temperature": "#c8a600", // Yellow
		};

		if (fullParameterExceptions.hasOwnProperty(parameter)) {
			return fullParameterExceptions[parameter];
		}

		for (const [substring, color] of Object.entries(colorMapBySubstring)) {
			if (parameter.includes(substring)) {
				return color;
			}
		}

		return "#e8b7e4"; // Rosa claro
	};

	const {
		data: dataIOT,
		isFetching: loadDataIOT,
		isError: ErrorDataIOT,
		refetch: refetchDataIOT,
	} = useQuery({
		queryKey: ["DataIOT"],
		queryFn: () =>
			getIOTWellData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataPlotting?.selected_parameter.length > 0
					? JSON.stringify(dataPlotting?.selected_parameter)
					: [],
				startDateIOT,
				endDateIOT,
			),
		retry: 1,
		enabled: false,
		onSuccess: (response) => {},
		onError: (error) => {},
	});

	const mutationCreateProductionTest = useMutation({
		mutationFn: uploadProductionTest,
		onMutate: () => {},
		onSuccess: () => {
			refetchListDataTest();
			message.success(`Production test data added successfully`);
		},
		onError: (error) => {
			message.error(`An error has occurred when create the information of production test`);
		},
	});

	const mutationUpdateProductionTest = useMutation({
		mutationFn: updateProductionTest,
		onMutate: () => {},
		onSuccess: () => {
			refetchListDataTest();
			message.success(`Production test data successfully updated`);
		},
		onError: (error) => {
			message.error(`An error has occurred when create the information of production test`);
		},
	});

	const {
		data: listDataTest,
		isLoading: loadListDataTest,
		isError: ErrorListDataTest,
		refetch: refetchListDataTest,
	} = useQuery({
		queryKey: ["dataListProductionTest"],
		queryFn: () =>
			getListProductionTest(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			// setViewLoading(false);
		},
		onError: (error) => {},
	});

	const {
		data: dataTest,
		isLoading: loadDataTest,
		isError: ErrorDataTest,
		refetch: refetchDataTest,
	} = useQuery({
		queryKey: ["dataProductionTest"],
		queryFn: () =>
			getDataProductionTest(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
				productionTest,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if(data?.statusType === STATUS_TYPE_PLOTTING.SUCCESS){

				refetchDataIotTest();
			}
		},
		onError: (error) => {},
	});

	const mutationDeleteProductionTest = useMutation({
		mutationFn: deleteProductionTest,
		onMutate: () => {},
		onSuccess: (data) => {
			if (data?.statusType === STATUS_TYPE_PLOTTING.SUCCESS) {
				refetchListDataTest();
				message.success(`Data the production test Successfull`);
			} else {
				message.error(`An error has occurred when delete the information of production test`);
			}
		},
		onError: (error) => {
			message.error(`An error has occurred when delete the information of production test`);
		},
	});

	const {
		data: dataIotTest,
		isFetching: loadDataIotTest,
		isError: ErrorDataIotTest,
		refetch: refetchDataIotTest,
	} = useQuery({
		queryKey: ["DataIOTTest"],
		queryFn: () =>
			getIOTWellData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataTest?.data?.parameters.length > 0
					? JSON.stringify(dataTest?.data?.parameters)
					: [],
				dataTest?.data?.start_date,
				dataTest?.data?.end_date,
			),
		retry: 1,
		enabled: false,
		onSuccess: (response) => {},
		onError: (error) => {},
	});

	const mutationUpdateDataProduction = useMutation({
		mutationFn: uploadData,
		onMutate: () => {},
		onSuccess: () => {
			message.success(`Data upload Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when upload the information`);
		},
	});

	const {
		data: dataTestPVT,
		isLoading: loadDataTestPVT,
		isError: ErrorDataTestPVT,
		refetch: refetchDataTestPVT,
	} = useQuery({
		queryKey: ["dataProductionTestPVT"],
		queryFn: () =>
			getDataProductionTestAnalysis(
				"get_data_pvt",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
				productionTest,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if(data?.statusType === STATUS_TYPE_PLOTTING.SUCCESS){

				refetchDataIotTest();
			}
		},
		onError: (error) => {},
	});

	const {
		data: dataTestLiquid,
		isLoading: loadDataTestLiquid,
		isError: ErrorDataTestLiquid,
		refetch: refetchDataTestLiquid,
	} = useQuery({
		queryKey: ["dataProductionTestLiquid"],
		queryFn: () =>
			getDataProductionTestAnalysis(
				"get_data_liquid",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
				productionTest,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if(data?.statusType === STATUS_TYPE_PLOTTING.SUCCESS){

				refetchDataIotTest();
			}
		},
		onError: (error) => {},
	});

	const {
		data: dataTestPressure,
		isLoading: loadDataTestPressure,
		isError: ErrorDataTestPressure,
		refetch: refetchDataTestPressure,
	} = useQuery({
		queryKey: ["dataProductionTestPressure"],
		queryFn: () =>
			getDataProductionTestAnalysis(
				"get_data_pressure",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
				productionTest,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if(data?.statusType === STATUS_TYPE_PLOTTING.SUCCESS){

				refetchDataIotTest();
			}
		},
		onError: (error) => {},
	});

	const {
		data: dataTestIPR,
		isLoading: loadDataTestIPR,
		isError: ErrorDataTestIPR,
		refetch: refetchDataTestIPR,
	} = useQuery({
		queryKey: ["dataProductionTestIPR"],
		queryFn: () =>
			getDataProductionTestAnalysis(
				"get_data_ipr",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
				productionTest,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if(data?.statusType === STATUS_TYPE_PLOTTING.SUCCESS){

				refetchDataIotTest();
			}
		},
		onError: (error) => {},
	});

	return {
		User,
		optionsAllIOT,
		dataParamsPlotting,
		ErrorDataParamsPlotting,
		addParameters,
		cleanParameters,
		modifyParameters,
		dataIOT,
		isFetchingListParameters,
		userDataInfo,
		dataTest,
		dataListPlatforms,
		dataListDevices,
		concatenatedData,
		inputDataPlot,
		listDataTest,
		loadDataIOT,
		loadDataTest,
		loadDataParamsPlotting,
		loadDataListParameters,
		mutationCreateProductionTest,
		mutationUpdateProductionTest,
		mutationDeleteProductionTest,
		mutationUpdateDataProduction,
		dataIotTest,
		loadDataIotTest,
		dataTestPVT,
		dataTestLiquid,
		dataTestPressure,
		dataTestIPR,
		setStartDateIOT,
		setEndDateIOT,
		setConcatenatedData,
		setInputDataPlot,
		setOptionsAllIOT,
		getSpecificColor,
		refetchDataListPlatforms,
		refetchDataListDevices,
		refetchParamsPlotting,
		refetchDataListParameters,
		refetchDataIOT,
		refetchListDataTest,
		refetchDataTest,
		refetchDataIotTest,
		refetchDataTestPVT,
		refetchDataTestLiquid,
		refetchDataTestPressure,
		refetchDataTestIPR,
	};
};
