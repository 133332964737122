import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_PVT = config.API.PVT.BASE_URL_PVT + "/PVT";
const BASE_URL_PVT_DRY_GAS = config.API.PVT.BASE_URL_PVT + "/pvt_gas";
const TOKEN_PVT_GAS_LIFT = config.AUTH.TOKEN_PVT_GAS_LIFT;

export const uploadJsonPVT = async (dataJson) => {
	try {
		const operator = dataJson.operator;
		const lease = dataJson.lease;
		const region = dataJson.region;
		const county = dataJson.county;
		const field = dataJson.field;
		const well = dataJson.well;
		const username = dataJson.username;
		const response = await fetch(
			`${BASE_URL_PVT}/upload_json_PVT/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(dataJson.data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const uploadJsonDataPVT = async (dataJson) => {
	try {
		const operator = dataJson.operator;
		const lease = dataJson.lease;
		const region = dataJson.region;
		const county = dataJson.county;
		const field = dataJson.field;
		const well = dataJson.well;
		const username = dataJson.username;
		const response = await fetch(
			`${BASE_URL_PVT}/upload_json_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(dataJson.data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const udpateJsonPVT = async (dataJson) => {
	try {
		const operator = dataJson.operator;
		const lease = dataJson.lease;
		const region = dataJson.region;
		const county = dataJson.county;
		const field = dataJson.field;
		const well = dataJson.well;
		const username = dataJson.username;
		const response = await fetch(
			`${BASE_URL_PVT}/update_json_PVT/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(dataJson.data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const updateJsonDataPVT = async (dataJson) => {
	try {
		const operator = dataJson.operator;
		const lease = dataJson.lease;
		const region = dataJson.region;
		const county = dataJson.county;
		const field = dataJson.field;
		const well = dataJson.well;
		const username = dataJson.username;
		const response = await fetch(
			`${BASE_URL_PVT}/update_json_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(dataJson.data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

//APIS QUE ACTUALMENTE SE ENCUENTRAN FUNCIONANDO EN EL PROCESOS DE PVT
export const uploadPVT = async (dataJson) => {
	try {
		const operator = dataJson.operator;
		const lease = dataJson.lease;
		const region = dataJson.region;
		const county = dataJson.county;
		const field = dataJson.field;
		const well = dataJson.well;
		const username = dataJson.username;
		const response = await fetch(
			`${BASE_URL_PVT}/upload_json_PVT/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(dataJson.data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const uploadJsonTablePVT = async (dataJson) => {
	try {
		const operator = dataJson.operator;
		const lease = dataJson.lease;
		const region = dataJson.region;
		const county = dataJson.county;
		const field = dataJson.field;
		const well = dataJson.well;
		const username = dataJson.username;
		const response = await fetch(
			`${BASE_URL_PVT}/upload_json_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(dataJson.data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getRS = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
	lab_calc,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_rs${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getBo = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
	lab_calc,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_bo${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getViso = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
	lab_calc,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_viso${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getCo = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
	lab_calc,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_co${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getCalculationPb = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
	lab_calc,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_pb${lab_calc}/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getCalculationsParameters = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_p1_p2/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getCalculationsDeviatonPB = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_standard_deviation_pb/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getCalculationsDeviaton = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_standard_deviation/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getRSTuned = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_rs_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getBOTuned = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_bo_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getVisoTuned = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_viso_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getPBTuned = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_pb_tuned/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getSepPress = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_press/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const getSepCalculate = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
	lab_calc,
) => {
	try {
		const response = await fetch(
			`${BASE_URL_PVT}/calculate_sep${lab_calc}/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

// API's PVT Dry Gas

export const getPVTCalcDryGas = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_PVT_DRY_GAS}/calculate/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting users");
	}
};

export const postJsonPVTCalcDryGas = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (!validateParams(operator, lease, region, username)) return;
	try {
		const response = await fetch(
			`${BASE_URL_PVT_DRY_GAS}/upload_data/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_GAS_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem posted data PVT calc");
	}
};
