import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { DashboardDCAContext } from "../context/DashboardDCA/DashboardDCAContext";
import { DataGasLiftContext } from "../context/DataGasLift/DataGasLiftContext";
import {
	getJsonIOT,
	getListDevicesDCA,
	getListDevicesGaslift,
	getListDevicesPlungerlift,
	getListPlatforms,
	getListPlatformsWells,
} from "../services/api/IOTAPI";

export const useTabIOT = () => {
	const { getUserDataInfo, userDataInfo, deviceRealTime, selectedWell } =
		useContext(DashboardContext);
	const { getDataFilterUserDCA, dataFilterUserDCA } =
		useContext(DashboardDCAContext);
	const [dataIOT, setDataIOT] = useState([]);
	const { getDataFilterUserGaslift, dataFilterUserGasLift } =
		useContext(DataGasLiftContext);
	const [dataIOTGasLift, setDataIOTGaslift] = useState([]);
	const [viewLoading, setViewLoading] = useState(true);
	const [typeProcessPlatform, setTypeProcessPlatform] = useState("");

	const {
		data: datGetIOT,
		isLoading: loadDataIOT,
		isError: ErrorDataIOT,
		refetch: refetchDataIOT,
	} = useQuery({
		queryKey: ["dataIOTDCA"],
		queryFn: () =>
			getJsonIOT(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserDCA.well_platforms,
			),
		retry: 1,
		enabled: false,
		refetchInterval: 15 * 60 * 1000,
		onSuccess: (data) => {
			const datagetIOT = data?.data;
			setDataIOT(datagetIOT);
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const {
		data: dataGetIOTGasLift,
		isLoading: loadDataIOTGasLift,
		isError: ErrorDataIOTGasLift,
		refetch: refetchDataIOTGasLift,
	} = useQuery({
		queryKey: ["dataIOTGasLift"],
		queryFn: () =>
			getJsonIOT(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.well_platforms,
			),
		retry: 1,
		enabled: false,
		refetchInterval: 15 * 60 * 1000,
		onSuccess: (data) => {
			setDataIOTGaslift(data.data);
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const {
		data: dataListPlatforms,
		isLoading: loadDataListPlatforms,
		isError: ErrorDataListPlatforms,
		refetch: refetchDataListPlatforms,
	} = useQuery({
		queryKey: ["dataListPlatforms"],
		queryFn: () =>
			getListPlatforms(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataListPlatformsWells,
		isLoading: loadDataListPlatformsWells,
		isError: ErrorDataListPlatformsWells,
		refetch: refetchDataListPlatformsWells,
	} = useQuery({
		queryKey: ["dataListPlatformsWells", typeProcessPlatform],
		queryFn: () =>
			getListPlatformsWells(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				typeProcessPlatform,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataListDevicesGaslift,
		isLoading: loadDataListDevicesGaslift,
		isError: ErrorDataListDevicesGaslift,
		refetch: refetchDataListDevicesGaslift,
	} = useQuery({
		queryKey: ["dataListDevicesGaslift", typeProcessPlatform],
		queryFn: () =>
			getListDevicesGaslift(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataListDevicesDCA,
		isLoading: loadDataListDevicesDCA,
		isError: ErrorDataListDevicesDCA,
		refetch: refetchDataListDevicesDCA,
	} = useQuery({
		queryKey: ["dataListDevicesDCA", typeProcessPlatform],
		queryFn: () =>
			getListDevicesDCA(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: dataListDevicesPlungerlift,
		isLoading: loadDataListDevicesPlungerlift,
		isError: ErrorDataListDevicesPlungerlift,
		refetch: refetchDataListDevicesPlungerlift,
	} = useQuery({
		queryKey: ["getListDevicesPlungerlift", typeProcessPlatform],
		queryFn: () =>
			getListDevicesPlungerlift(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});
	return {
		getUserDataInfo,
		userDataInfo,
		getDataFilterUserDCA,
		dataFilterUserDCA,
		datGetIOT,
		dataIOT,
		refetchDataIOT,
		loadDataIOT,
		ErrorDataIOT,
		ErrorDataIOTGasLift,
		getDataFilterUserGaslift,
		dataFilterUserGasLift,
		dataGetIOTGasLift,
		dataIOTGasLift,
		refetchDataIOTGasLift,
		loadDataIOTGasLift,
		setViewLoading,
		viewLoading,
		dataListPlatforms,
		dataListPlatformsWells,
		dataListDevicesDCA,
		dataListDevicesGaslift,
		typeProcessPlatform,
		refetchDataListPlatforms,
		refetchDataListPlatformsWells,
		setTypeProcessPlatform,
		refetchDataListDevicesDCA,
		refetchDataListDevicesGaslift,
		dataListDevicesPlungerlift,
		loadDataListDevicesPlungerlift,
		ErrorDataListDevicesPlungerlift,
		refetchDataListDevicesPlungerlift,
	};
};
