// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartWellHead_containerPrincipal__tvg3M {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.ChartWellHead_containerLegend__VhjP- {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.ChartWellHead_styleRefenence1__EH4jL {
  color: #ff6347;
}
.ChartWellHead_styleRefenence2__FOHG3 {
  color: #32cd32;
}
.ChartWellHead_styleRefenence3__KhZ9k {
  color: #1e90ff;
}
.ChartWellHead_styleRefenence4__8nXTV {
  color: #ffd700;
}
.ChartWellHead_styleRefenence5__5RY8E {
  color: #dc143c;
}
.ChartWellHead_sizeReference__oRex0 {
  font-size: 10px;
}
.ChartWellHead_separatorGraph__HSV1b {
  width: 100%;
  height: 5px;
}
@media (min-width: 767px) {
  .ChartWellHead_containerLegend__VhjP- {
    gap: 10px;
    margin-left: 50px;
  }
  .ChartWellHead_sizeReference__oRex0 {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PlungerLift/GraphicsDashboard/ChartWellHead/ChartWellHead.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;AACA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,WAAW;AACb;AACA;EACE;IACE,SAAS;IACT,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".containerPrincipal {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  width: 100%;\n}\n.containerLegend {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin-top: 20px;\n}\n.styleRefenence1 {\n  color: #ff6347;\n}\n.styleRefenence2 {\n  color: #32cd32;\n}\n.styleRefenence3 {\n  color: #1e90ff;\n}\n.styleRefenence4 {\n  color: #ffd700;\n}\n.styleRefenence5 {\n  color: #dc143c;\n}\n.sizeReference {\n  font-size: 10px;\n}\n.separatorGraph {\n  width: 100%;\n  height: 5px;\n}\n@media (min-width: 767px) {\n  .containerLegend {\n    gap: 10px;\n    margin-left: 50px;\n  }\n  .sizeReference {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPrincipal": `ChartWellHead_containerPrincipal__tvg3M`,
	"containerLegend": `ChartWellHead_containerLegend__VhjP-`,
	"styleRefenence1": `ChartWellHead_styleRefenence1__EH4jL`,
	"styleRefenence2": `ChartWellHead_styleRefenence2__FOHG3`,
	"styleRefenence3": `ChartWellHead_styleRefenence3__KhZ9k`,
	"styleRefenence4": `ChartWellHead_styleRefenence4__8nXTV`,
	"styleRefenence5": `ChartWellHead_styleRefenence5__5RY8E`,
	"sizeReference": `ChartWellHead_sizeReference__oRex0`,
	"separatorGraph": `ChartWellHead_separatorGraph__HSV1b`
};
export default ___CSS_LOADER_EXPORT___;
