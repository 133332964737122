import { Select } from "antd";
import React, { useState } from "react";

export const ParametersGeneral = ({
	dataListParameters,
	handleChangeSelect,
	selectedParameters,
}) => {
	const { Option } = Select;

	return (
		<div className="flex flex-row w-full h-full">
			<div className="flex flex-col w-1/2 h-full p-5">
				<h1 className="font-bold text-pa-purple text-[16px] mb-[10px]">
					Standar Paramters
				</h1>
				<div className="flex flex-col w-full h-full gap-5">
					<div className="flex w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							Date
						</label>
					</div>
					<div className="flex w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							Static Pressure
						</label>
					</div>
					<div className="fle w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							Flowing Temperature
						</label>
					</div>
					<div className="flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							Oil Rate
						</label>
					</div>
					<div className="flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							Gas Rate
						</label>
					</div>
					<div className="flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							Water Rate
						</label>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-1/2 h-full p-4">
				<h1 className="font-bold text-pa-purple text-[16px] mb-[10px]">
					Parameters Real Time
				</h1>
				<div className="flex flex-col gap-5">
					<div className="w-full h-[50px] rounded-sm ">
						<Select
							style={{ border: "1px solid #6b7280", borderRadius: "2px" }}
							notFoundContent={"Loading..."} // Show loading message
							name="date"
							value={selectedParameters.date || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("date", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
					<div className="w-full h-[50px] rounded-sm">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="static_pressure"
							value={selectedParameters.static_pressure || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("static_pressure", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
					<div className="w-full h-[50px] rounded-sm">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="flowing_temperature"
							value={selectedParameters.flowing_temperature || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("flowing_temperature", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
					<div className="w-full h-[50px] rounded-sm">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="oil_rate"
							value={selectedParameters.oil_rate || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("oil_rate", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
					<div className="w-full h-[50px] rounded-sm">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="gas_rate"
							value={selectedParameters.gas_rate || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("gas_rate", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
					<div className="w-full h-[50px] rounded-sm">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="water_rate"
							value={selectedParameters.water_rate || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("water_rate", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
				</div>
			</div>
		</div>
	);
};
