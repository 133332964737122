import React, { useState } from "react";
import { ReactComponent as IconDownload } from "../../assets/img/icons/downloadTemplate.svg";
import classes from "./DownloadData.module.css";
import * as XLSX from "xlsx";
import { Modal } from "antd";

const { containerBtn, downloadTemplate } = classes;

const DownloadData = ({ data, fileName }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleDownload = () => {
		const dataWithoutId = data.map(({ id, ...rest }) => rest);

		const worksheet = XLSX.utils.json_to_sheet(dataWithoutId);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

		// Generar el archivo Excel y descargarlo
		XLSX.writeFile(workbook, fileName);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		handleDownload();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			<button
				onClick={showModal}
				className={containerBtn}
				aria-label="Download Template"
			>
				<IconDownload size={32} className={`flex w-4 h-4 ${downloadTemplate}`} />
			</button>

			<Modal
				centered
				title="Alert"
				open={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				okButtonProps={{
					className: "bg-[#662D91]",
				}}
			>
				<p>
					Please do not modify the column names (headers) in the downloaded file.
					This may cause errors when loading the file.
				</p>
			</Modal>
		</>
	);
};

export default DownloadData;
