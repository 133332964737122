import React from "react";
import { DashboardPlottingContext } from "./DashboardPlottingContext";
import { useDashboardPlotting } from "../../hooks/useDashboardPlotting";

export const DashboardPlottingProvider = ({ children }) => {
	const {
		dataPlotting,
		viewLoadingPlotting,
		productionTest,
		getViewLoadingPlotting,
		getDataPlotting,
		getProductiontest
	} = useDashboardPlotting()
	return (
		<DashboardPlottingContext.Provider
			value={{
				dataPlotting,
				viewLoadingPlotting,
				productionTest,
				getViewLoadingPlotting,
				getDataPlotting,
				getProductiontest
			}}>
			{children}
		</DashboardPlottingContext.Provider>
	)
}
