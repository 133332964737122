import React, { useEffect, useState, useContext } from "react";
import { Sidebar } from "../../../components/layout/Sidebar";
import { Navbar } from "../../../components/layout/Navbar";
import { Alert, Checkbox, Select, Steps, Switch, Tooltip, message } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { ChartFluidData } from "../../../components/GasLift/GraphicsFluidConfig/ChartFluidData";
import { ChartCalculation } from "../../../components/GasLift/GraphicsFluidConfig/ChartCalculation";
import { TableRegression } from "../../../components/GasLift/TablePVT/TableRegression";
import { TablePVTCalculate } from "../../../components/GasLift/TablePVT/TablePVTCalculate";
import { ChartEndStep } from "../../../components/GasLift/GraphicsFluidConfig/ChartEndStep";
import { AlertModal } from "../../../components/AlertModal";
import { ReactComponent as IconDelete } from "../../../assets/img/icons/delete.svg";
import { ReactComponent as IconAdd } from "../../../assets/img/icons/add.svg";
import { ImportFile } from "../../../components/GasLift/Complements/ImportFile";
import { useNavigate } from "react-router-dom";
import { useTabGaslift } from "../../../hooks/useTabGaslift";
import { DATA_FLUID, INITIAL_POST_DATA_PVT, INITIAL_POST_PVT_CALC, dataCheckPVT } from "../../../components/elements/types/type_gas_lift";
import { DataGasLiftContext } from "../../../context/DataGasLift/DataGasLiftContext";
import { NAME_PAGES } from "../../../components/elements/types/type_pages";
import { STATUS_CODE_WELL_GASLIFT, STATUS_DATA_GAS_LIFT } from "../../../components/elements/types/type_response_API";

const steps = [
  {
    title: "Reservoir and Separator",
  },
  {
    title: "Fluid lab information",
  },
  {
    title: "Regression",
  },
  {
    title: "PVT Calculations",
  }
];

const customStepNumbers = [1, 2, 2.1, 3];

const typeGraphFluid = [
  {
    name: "Solution GOR",
    value: "solution_gor",
  },
  {
    name: "Oil FVF",
    value: "oil_fvf",
  },
  {
    name: "Oil viscosity",
    value: "oil_viscosity",
  },
  {
    name: "Oil compressibility",
    value: "oil_compressibility",
  }
];

export const StepMenuEditFluidConfigDryGas = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const {
    User,
    userDataInfo,
    dataFilterUserGasLift,
    mutationUpdateDataGasLift,
    mutationDataTablePVT,
    mutationUploadPVT,
    dataWellGasLift,
    refetchDataWellGasLift,
  } = useTabGaslift();
  const {getEnableCheckLab, enableCheckLab} = useContext(DataGasLiftContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [status, setStatus] = useState(false);
  const [inputFluidInformation, setInputFluidInformation] = useState(DATA_FLUID.initial_data_fluid); 
  const [inputFluidLabCheck, setInputFluidLabCheck] = useState(false);
  const [inputFluidLab, setInputFluidLab] = useState(DATA_FLUID.initialInputFluidLab);
  const [dataTableFluidLab1, setDataTableFluidLab1] = useState(DATA_FLUID.initialDataTableFluidLab1);
  const [dataTableAutomic, setDataTableAutomic] = useState(DATA_FLUID.initialAutomaticData);
  const [dataTableUserSelection, setDataTableUserSelection] = useState(DATA_FLUID.initialUserSelectionData);
  const [inputDataCalculation, setInputDataCalculation] = useState(DATA_FLUID.initialDataCalculation);
  const [dataTablePVT, setDataTablePVT]= useState(DATA_FLUID.initialDataTablePVT);
  const [ActiveChecks, setActiveChecks] = useState(DATA_FLUID.stateChecks);
  const [checkPVT, setCheckPVT] = useState(dataCheckPVT);
  const [current, setCurrent] = useState(0);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [dataInformationEnd, setDataInformationEnd] = useState();
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectCorrelation, setSelectCorrelation] = useState([]);
  const [dataPoint, setDataPont] = useState([]);
  const [dataTablaRegression, setDataTableRegression] = useState([]);

  useEffect(() => {
    refetchDataWellGasLift();
  }, []);

  useEffect(() => {
    if (dataWellGasLift) {
      if (dataWellGasLift?.statusCode === STATUS_CODE_WELL_GASLIFT.SUCCESS) {
        const filterDataCreate = dataWellGasLift?.data;
        const filterDataEnd = Object.assign({}, inputFluidInformation, inputFluidLab, { table_lab_real: [] }, { table_lab_predicted: [] }, inputFluidLabCheck);

        setInputFluidInformation({
          res_pressure: filterDataCreate?.res_pressure,
          res_temperature: filterDataCreate?.res_temperature,
          res_oil_gravity: filterDataCreate?.res_oil_gravity,
          res_gas_gravity: filterDataCreate?.res_gas_gravity,
          res_initial_gor: filterDataCreate?.res_initial_gor,
          res_H2S: filterDataCreate?.res_H2S,
          res_CO2: filterDataCreate?.res_CO2,
          res_N2: filterDataCreate?.res_N2,
          res_water_salinity: filterDataCreate?.res_water_salinity,
          sep_pressure: filterDataCreate?.sep_pressure,
          sep_temperature: filterDataCreate?.sep_temperature,
          sep_oil_gravity: filterDataCreate?.sep_oil_gravity,
          sep_gas_gravity: filterDataCreate?.sep_gas_gravity,
          sep_solution_gor: filterDataCreate?.sep_solution_gor,
          sep_oil_volume_factor: filterDataCreate?.sep_oil_volume_factor
        });

        setInputFluidLabCheck(filterDataCreate?.fluidLab);
        getEnableCheckLab(filterDataCreate?.fluidLab);

        setInputFluidLab({
          temperature_lab: filterDataCreate?.temperature_lab,
          solution_gor_pb: filterDataCreate?.solution_gor_pb,
          bubble_point_pressure_lab: filterDataCreate?.bubble_point_pressure_lab,
          oil_fvf: filterDataCreate?.oil_fvf,
        })

        setDataTableFluidLab1(filterDataCreate?.table_fluid_lab);

        setInputDataCalculation({
          option_data_point: filterDataCreate?.option_data_point,
          calculate_correlation: filterDataCreate?.calculate_correlation,
          calculate_correlation_oil: filterDataCreate?.calculate_correlation_oil,
          bubble_point_pressure: filterDataCreate?.bubble_point_pressure 
        });

        if(filterDataCreate?.option_data_point === "automatic"){
          setDataTableAutomic(filterDataCreate?.data_point_table);
          setDataTableUserSelection(prevState => [
            {
              ...prevState[0],
              temperature: filterDataCreate?.res_temperature,
            },
            ...prevState.slice(1)
          ]);
        }else if (filterDataCreate?.option_data_point === "user_selection"){
          setDataTableUserSelection(filterDataCreate?.data_point_table);
          setDataTableAutomic(prevState => [
            {
              ...prevState[0],
              from_temperature: filterDataCreate?.res_temperature,
            },
            {
              ...prevState[1],
              to_temperature: filterDataCreate?.res_temperature,
            },
            ...prevState.slice(2)
          ]);
        }

        const filteredJson = Object.fromEntries(
          Object.entries(filterDataCreate).filter(([key]) => !(key in filterDataEnd))
        );

        setDataInformationEnd(filteredJson);
      }
    }
  }, [dataWellGasLift]);

  useEffect(() => {
    if (current === 3) {
      const valueTemp = inputDataCalculation?.option_data_point === "automatic" ? dataTableAutomic[1]?.to_temperature : dataTableUserSelection[0]?.temperature;
      
      if(enableCheckLab && (valueTemp != inputFluidLab.temperature_lab) && (valueTemp && inputFluidLab.temperature_lab)){
        handleOpenModal();
        setTypeAlert("Warning")
        setMessageAlert('Temperature value is different from the temperature value recorded in fluid lab');
      }
    }
  }, [current, inputDataCalculation.option_data_point]);

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleClickNext = () => {
    let newUpdateJsonPVT = [];

    switch (current) {
      case 0:
        const reservoirKeys = validateKeys("res")
        const separatorKeys = validateKeys("sep")
        if (reservoirKeys || separatorKeys) {
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert("There are empty fields please or not validate");
          setInputEmpty(true);
          handleStatusChange();
        } else {
          newUpdateJsonPVT = {
            ...INITIAL_POST_DATA_PVT,
            API_gravity_of_oil: inputFluidInformation.res_oil_gravity,
            specific_gravity_of_gas: inputFluidInformation.res_gas_gravity,
            temp_res: inputFluidInformation.res_temperature,
            rsb_init: inputFluidInformation.res_initial_gor,
            bob_sep: inputFluidInformation.sep_oil_volume_factor,
            rsb_sep: inputFluidInformation.sep_solution_gor,
            sGg_sep: inputFluidInformation.sep_gas_gravity,
            p_sep: inputFluidInformation.sep_pressure,
            t_sep: inputFluidInformation.sep_temperature,
            API_sep: inputFluidInformation.sep_oil_gravity,
          }

          setTimeout(() => {
            mutationUploadPVT.mutate({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserGasLift.county,
              field: dataFilterUserGasLift.field,
              well: dataFilterUserGasLift.well_id,
              username: User.user_name,
              data: newUpdateJsonPVT
            })
          }, 500);
          setCurrent(current + 1);
          setInputEmpty(false);
        }
        break;
      case 1:
        if (enableCheckLab) {
          const labKeys = validateKeys("lab");
          if (labKeys[0] || !labKeys[1]) {
            handleOpenModal();
            setTypeAlert("Warning");
            setMessageAlert("There are empty fields please or not validate");
            setInputEmpty(true);
            handleStatusChange();
          }else{
            newUpdateJsonPVT = {
              ...INITIAL_POST_DATA_PVT,
              API_gravity_of_oil: inputFluidInformation.res_oil_gravity,
              specific_gravity_of_gas: inputFluidInformation.res_gas_gravity,
              temp_res: inputFluidInformation.res_temperature,
              rsb_init: inputFluidInformation.res_initial_gor,
              bob_sep: inputFluidInformation.sep_oil_volume_factor,
              rsb_sep: inputFluidInformation.sep_solution_gor,
              sGg_sep: inputFluidInformation.sep_gas_gravity,
              p_sep: inputFluidInformation.sep_pressure,
              t_sep: inputFluidInformation.sep_temperature,
              API_sep: inputFluidInformation.sep_oil_gravity,
              lab_temp: inputFluidLab.temperature_lab,
              pb_lab: inputFluidLab.bubble_point_pressure_lab,
              rsb_lab: inputFluidLab.solution_gor_pb,
              rsdb_lab: inputFluidLab.solution_gor_pb,
              bodb_lab: inputFluidLab.oil_fvf,
            }
            
            const transformedData = {
              ...INITIAL_POST_PVT_CALC,
              p_lab: dataTableFluidLab1.map(item => item.pressureTable1),
              rs_lab: dataTableFluidLab1.map(item => item.gas_oil_ratio),
              co_lab: dataTableFluidLab1.map(item => !!item.oil_compressibility ? parseFloat(item.oil_compressibility) : 0),
              bo_lab: dataTableFluidLab1.map(item => !!item.oil_fvf ? parseFloat(item.oil_fvf) : 0),
              visc_oil_lab: dataTableFluidLab1.map(item => item.oil_viscosity),
            };

            setInputEmpty(false);
            setCurrent(current + 1);
          }
        } else {
          setInputEmpty(false);
          setCurrent(current + 1);
        }
        break;
      case 2:
        if (enableCheckLab) {
          if (!selectCorrelation.calculate_correlation) {
            handleOpenModal();
            setTypeAlert("Warning");
            setMessageAlert("There are empty fields or not selected options");
            setInputEmpty(true);
            handleStatusChange();
          } else {
            setInputEmpty(false);
            setCurrent(current + 1);
          }
        } else {
          setInputEmpty(false);
          setCurrent(current + 1);
        }
        break;
      case 3:        
        let validateDataNull = false;
        let dataPointSelect = {
          press_calc: [],
          numbers_press_rows: null,
        }

        switch (inputDataCalculation.option_data_point) {
          case "automatic":
            validateDataNull = VerifyingNulls(dataTableAutomic)
            dataPointSelect = {
              press_calc: [dataTableAutomic[0]?.from_pressure, dataTableAutomic[1]?.to_pressure],
              numbers_press_rows: dataTableAutomic[2]?.values_pressure,
            }
            setDataPont(dataPointSelect)
            break;
          case "user_selection":
            const validatedDataPress = VerifyingNulls(dataTableUserSelection);
            const validateDataTemp = dataTableUserSelection[0].temperature === "" || dataTableUserSelection[0].temperature === null
            validateDataNull= validatedDataPress && !validateDataTemp
            const pressure = dataTableUserSelection.map(item => item.pressure);
            dataPointSelect = {
              press_calc: pressure,
              numbers_press_rows: null,
            }
            setDataPont(dataPointSelect)
            break
          default:
            break;
        }
        if((inputDataCalculation.option_data_point && inputDataCalculation.calculate_correlation && inputDataCalculation.calculate_correlation_oil && validateDataNull && inputDataCalculation.bubble_point_pressure)){
          setInputEmpty(false);
          setCurrent(current + 1);
        }else{
          handleOpenModal();
          setTypeAlert("Warning");
          setMessageAlert("There are empty fields please or not validate");
          setInputEmpty(true);
          handleStatusChange();
        }
        break;
      default:
        break;
    }
  };

  
  const handleCalculation = () => {

    setDataTablePVT(DATA_FLUID.initialDataTablePVT);
    
    let validateDataNull = false;
    let dataPointSelect = {
      press_calc: [],
      numbers_press_rows: null,
    };
    let temperatureVal = null;
    let valDataRSLab = false;
    
    switch (inputDataCalculation.option_data_point) {
      case "automatic":
        validateDataNull = VerifyingNulls(dataTableAutomic)
        dataPointSelect = {
          press_calc: [dataTableAutomic[0]?.from_pressure, dataTableAutomic[1]?.to_pressure],
          numbers_press_rows: dataTableAutomic[2]?.values_pressure,
        }
        setDataPont(dataPointSelect);
        temperatureVal = dataTableAutomic[0].from_temperature;
        break;
        case "user_selection":
          const validatedDataPress = VerifyingNulls(dataTableUserSelection);
        const validateDataTemp = dataTableUserSelection[0].temperature === "" || dataTableUserSelection[0].temperature === null
        validateDataNull= validatedDataPress && !validateDataTemp
        const pressure = dataTableUserSelection.map(item => item.pressure);
        dataPointSelect = {
          press_calc: pressure,
          numbers_press_rows: null,
        }
        setDataPont(dataPointSelect);
        temperatureVal = dataTableUserSelection[0].temperature;
        break
      default:
        break;
    }
        
    if ((inputDataCalculation.option_data_point && inputDataCalculation.calculate_correlation && inputDataCalculation.calculate_correlation_oil && validateDataNull && valDataRSLab)) {

      const newUpdateJsonPVT = {
        ...INITIAL_POST_DATA_PVT,
        API_gravity_of_oil: inputFluidInformation.res_oil_gravity,
        specific_gravity_of_gas: inputFluidInformation.res_gas_gravity,
        temp_res: temperatureVal,
        rsb_init: inputFluidInformation.res_initial_gor,
        bob_sep: inputFluidInformation.sep_oil_volume_factor,
        rsb_sep: inputFluidInformation.sep_solution_gor,
        sGg_sep: inputFluidInformation.sep_gas_gravity,
        p_sep: inputFluidInformation.sep_pressure,
        t_sep: inputFluidInformation.sep_temperature,
        API_sep: inputFluidInformation.sep_oil_gravity,
        lab_temp: enableCheckLab ? inputFluidLab.temperature_lab : null,
        pb_lab: enableCheckLab ? inputFluidLab.bubble_point_pressure_lab : null,
        rsb_lab: enableCheckLab ? inputFluidLab.solution_gor_pb : null,
        rsdb_lab: enableCheckLab ? inputFluidLab.solution_gor_pb : null,
        bodb_lab: enableCheckLab ? inputFluidLab.oil_fvf : null,
      }
      
      const transformedData = {
        ...INITIAL_POST_PVT_CALC,
        press_calc: dataPointSelect.press_calc,
        numbers_press_rows: dataPointSelect.numbers_press_rows,
        p_lab: enableCheckLab ? dataTableFluidLab1.map(item => item.pressureTable1) : null,
        rs_lab: enableCheckLab ? dataTableFluidLab1.map(item => item.gas_oil_ratio) : null,
        co_lab: enableCheckLab ? dataTableFluidLab1.map(item => !!item.oil_compressibility ? parseFloat(item.oil_compressibility) : 0) : null,
        bo_lab: enableCheckLab ? dataTableFluidLab1.map(item => !!item.oil_fvf ? parseFloat(item.oil_fvf) : 0) : null,
        visc_oil_lab: enableCheckLab ? dataTableFluidLab1.map(item => item.oil_viscosity) : null,
      };

      
      setInputEmpty(false);

    } else if ((!inputDataCalculation.option_data_point && !inputDataCalculation.calculate_correlation && !inputDataCalculation.calculate_correlation_oil && !validateDataNull)){
      message.error("Please complete information");
      setInputEmpty(true)
    }
  }
  
  const handleClickPrev = () => {
    setCurrent(current - 1);
  };
  
  const handleClickDone = () => {
    
    const isDataDifferent = {
      res_pressure: inputFluidInformation.res_pressure !== dataWellGasLift?.data.res_pressure, 
      res_temperature: inputFluidInformation.res_temperature !== dataWellGasLift?.data.res_temperature,
      bubble_point_pressure: inputDataCalculation.bubble_point_pressure !== dataWellGasLift?.data.bubble_point_pressure
    };
    
    const dataPointSelected = inputDataCalculation.option_data_point === "automatic" ? dataTableAutomic : dataTableUserSelection;
    const dataCreateFluid = Object.assign({}, { fluidLab: enableCheckLab }, inputFluidInformation, inputFluidLab, { table_fluid_lab: [...dataTableFluidLab1] }, selectCorrelation, inputDataCalculation, {data_point_table: [...dataPointSelected]}, dataInformationEnd, {changeData: isDataDifferent});
    
    setTimeout(() => {
      mutationUpdateDataGasLift.mutate({ 
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field, 
        well: dataFilterUserGasLift.well_id, 
        username: User.user_name, 
        data: dataCreateFluid })
    }, 100);  
    
    setInputEmpty(false);
    navigate(`/${NAME_PAGES.gas_lift.list_well}`);
    message.success("Processing complete!");
  };
  
  const handleClickParcialDone = () => {
    const dataCreateFluid = Object.assign({}, { fluidLab: enableCheckLab }, inputFluidInformation, inputFluidLab, { table_fluid_lab: [...dataTableFluidLab1] }, selectCorrelation, inputDataCalculation, {data_point_table: [...dataTableAutomic]}, dataInformationEnd);
    
    setTimeout(() => {
      mutationUpdateDataGasLift.mutate({ 
        operator: userDataInfo.operator, 
        lease: userDataInfo.lease, 
        region: userDataInfo.region, 
        county: dataFilterUserGasLift.county, 
        field: dataFilterUserGasLift.field, 
        well: dataFilterUserGasLift.well_id, 
        username: User.user_name, 
        data: dataCreateFluid 
      })
    }, 200);

    navigate(`/${NAME_PAGES.gas_lift.list_well}`);
    message.success("Partial Save Processing complete!");
  };
  
  const handleFluidLab = (checked) => {
    setInputFluidLabCheck(checked);
    getEnableCheckLab(checked);
  };

  const handleChangeFluidInformation = (e) => {
    const { name, value } = e.target;
    const valueAsNumber = value === "" ? "" : parseFloat(value);
    switch (name) {
      case "res_oil_gravity":
        case "sep_oil_gravity":
        if (valueAsNumber > 150) {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: null,
          });
          message.error("The value can't be greater than 150");
        } else {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: valueAsNumber,
          });
        }
        break;
      case "res_gas_gravity":
      case "sep_gas_gravity":
        if (valueAsNumber > 5.0) {
          message.error("The value can't be greater than 5.0");
          setInputFluidInformation((prevState) => ({
            ...prevState,
            [name]: null,
          }));
        } else {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: valueAsNumber,
          });
        }
        break;
      case "res_temperature":
        setInputFluidInformation({
          ...inputFluidInformation,
          [name]: valueAsNumber,
        });
        setDataTableAutomic(prevState => [
          {
            ...prevState[0],
            from_temperature: valueAsNumber,
          },
          {
            ...prevState[1],
            to_temperature: valueAsNumber,
          },
          ...prevState.slice(2)
        ]);
        break;
      case "res_H2S":
        case "res_N2":
          case "res_CO2":
            if (valueAsNumber > 1) {
              message.error("The value can't be greater than 1");
              setInputFluidInformation({
            ...inputFluidInformation,
            [name]: null,
          });
        } else {
          setInputFluidInformation({
            ...inputFluidInformation,
            [name]: valueAsNumber,
          });
        }
        break;
        default:
        setInputFluidInformation({
          ...inputFluidInformation,
          [name]: valueAsNumber,
        });
        break;
    }
  };

  const handleChangeSelectCalculation = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeDataCalculation(customEvent);
  };

  const handleChangeDataRegression = (e, id) => {
    const { name, value } = e.target;
    
    switch (name) {
      case "calculate_correlation":
      case "calculate_correlation_oil":
        setSelectCorrelation({
          ...selectCorrelation,
          [name]: value
        })
        setInputDataCalculation({
          ...inputDataCalculation,
          [name]: value
        })
        break
      default:
        setSelectCorrelation({
          ...selectCorrelation,
          [name]: value
        })
        break;
    }
  }

  const handleChangeSelectRegression = (nameData, valueData) => {
    const customEvent = {
      target: {
        name: nameData,
        value: valueData,
      },
    };
    handleChangeDataRegression(customEvent);
  };
  
  const handleChangeFluidLab = (e) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : "";
    
    if (name === "temperature_lab" && (dataValue < 0 || dataValue > 450)) {
      message.error("Temperature (degF) value can't be greater than 450 and less than 0");
      setInputFluidLab((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else if (name === "bubble_point_pressure_lab" && (dataValue < 0 || dataValue > 25000)) {
      message.error("Bubble Point Pressure *Lab (psia) value can't be greater than 25000 and less than 0");
      setInputFluidLab((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else if ((name === "tuning_factor_FO2" || name === "tuning_factor_FO3" || name === "tuning_factor_FO4" || name === "tuning_factor_FO5" || name === "tuning_factor_FO6" || name === "tuning_factor_FO7" || name == "tuning_factor_FO11") && (dataValue < 0 || dataValue >= 5)) {
      message.error("Tuning Factors value can't be greater or equal than 5 and less than 0");
      setInputFluidLab((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
    else if (name === "solution_gor_pb" && (dataValue < 0 || dataValue > 100000)) {
      message.error("Solution GOR value can't be greater than 100000 and less than 0");
      setInputFluidLab((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
    else {
      setInputFluidLab((prevState) => ({
        ...prevState,
        [name]: dataValue,
      }));
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleStatusChange = () => {
    setStatus(!status);
  };

  const handleAddDataTable = (name_data) => {

    let maxId = 0;
    let newRow = [];
    const validateMaxID = (data) => {
      const uniqueIds = [...new Set(data.map((obj) => obj.id))];
      const maxId = Math.max(...uniqueIds);
      return maxId;
    }

    switch (name_data) {
      case "user_selection":
        maxId = validateMaxID(dataTableUserSelection);
        newRow = {
          ...DATA_FLUID.initialUserSelectionData[0],
          id: maxId + 1
        }
        setDataTableUserSelection([...dataTableUserSelection, newRow])
        break;
      case "dataFluidLab1":
        maxId = validateMaxID(dataTableFluidLab1);
        newRow = {
          ...DATA_FLUID.initialDataTableFluidLab1[0],
          id: maxId + 1
        }
        setDataTableFluidLab1([...dataTableFluidLab1, newRow])
        break;
      default:
        break;
    }
  };

  const handleDeleteTable = (id, name_data) => {

    let newData = [];
    const validateFilter = (data) => {
      const newData = data.filter((item) => item.id !== id);
      return newData;
    }

    switch (name_data) {
      case "user_selection":
        newData = validateFilter(dataTableUserSelection);
        setDataTableUserSelection(newData);
        break;
        case "dataFluidLab1":
        newData = validateFilter(dataTableFluidLab1);
        setDataTableFluidLab1(newData);
        break;
        default:
          break;
    }
  };

  const handleChangeTableFluidLab1 = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : "";
    
    if (name === "gas_oil_ratio" && (dataValue < 0 || dataValue > 10000)) {
      message.error("Solution GOR value can't be less than 0 or greater than 10000");
      setDataTableFluidLab1((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: "" } : item
    ));
  } else if (name === "oil_fvf" && (dataValue < 1 || dataValue > 5)) {
      message.error("Oil FVF value can't be less than 1 or greater than 5");
      setDataTableFluidLab1((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: "" } : item
    ));
  } else {
    setDataTableFluidLab1((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : "";
    const selectedIndex = dataTableFluidLab1.findIndex(
      (data) => data.id === id
    );
    const lastIndex = selectedIndex != 0 ? (selectedIndex - 1) : selectedIndex;
    
    if (selectedIndex > 0 && name === 'pressureTable1' && dataValue >= dataTableFluidLab1[lastIndex].pressureTable1 || (dataValue <= 0 || dataValue > 25000)) {
      message.error(dataValue <= 0 ? "Pressure value must be greater than zero (0)" : "Pressure (psia) cannot be less than the previous value");
      setDataTableFluidLab1((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: "" } : item
    )
      );
    }else if(name === "oil_compressibility" && (dataValue < 0.0000001 || dataValue > 0.0001)) {
      message.error("Oil Viscosity value can't be less than 0.0000001 or greater than 0.0001 or empty");
      setDataTableFluidLab1((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, "oil_compressibility": "" } : item
    ));
    }else if (name === "oil_viscosity" && (dataValue < 0.1 || dataValue > 120000)) {
      message.error("Oil Viscosity value can't be less than 0.1 or greater than 120000");
      setDataTableFluidLab1((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: "" } : item
        ));
    }else {
      setDataTableFluidLab1((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    }
  };
  
  const handleChangeDataCalculation = (e, id) => {
    const { name, value } = e.target;
    const dataValue = value != "" ? Number(value) : "";
    switch (name) {
      case "bubble_point_pressure":
        setInputDataCalculation({
          ...inputDataCalculation,
          [name]: Number(value)
        })
        break
      case "option_data_point":
        case "calculate_correlation":
          case "calculate_correlation_oil":
        setInputDataCalculation({
          ...inputDataCalculation,
          [name]: value
        })
        break
        default:
          setInputDataCalculation({
            ...inputDataCalculation,
          [name]: value
        });
        break;
    }
    
    switch (inputDataCalculation.option_data_point) {
      case "automatic":
        if (name === "from_temperature") {
          setDataTableAutomic(prevState =>
            prevState.map(item =>
              item.id === id
              ? {
                ...item,
                  [name]: dataValue
                }
                : item
            )
          );
          setDataTableAutomic(prevState =>
            prevState.map(item =>
              item.id === 1
                ? {
                  ...item,
                  to_temperature: dataValue
                }
                : item
            )
          );
        }else{
          setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: dataValue } : item
            ));
        }
        break;
      case "user_selection":
        setDataTableUserSelection((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: dataValue } : item
          ));
        break
      default:
        break;
    }
  }

  const handleChangeBlurDataPoint = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value !== "" ? Number(value) : "";
    
    switch (inputDataCalculation.option_data_point) {
      case "automatic":
        if((name === "from_pressure" || name === "to_pressure") && dataValue <= 0){
          setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
        ));
            message.error("Pressure value must be greater than zero (0)");
          }else if(name === "to_pressure" && dataValue <= dataTableAutomic[0].from_pressure){
            setDataTableAutomic((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
            message.error("Pressure value must be greater than previous row value");
          }else{
            setDataTableAutomic((prevState) =>
              prevState.map((item) =>
                item.id === id ? { ...item, [name]: dataValue } : item
            ));
          }
        break;
        case "user_selection":
          const selectedIndex = dataTableUserSelection.findIndex(
          (data) => data.id === id
        );
    
        const lastIndex = selectedIndex != 0 ? (selectedIndex - 1) : selectedIndex;
        if(name === "pressure" && (dataValue <= 0)){
          message.error("Pressure value must be greater than zero (0)");
          setDataTableUserSelection((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
          }else if (name === "pressure" && (!!dataValue) && (dataValue <= dataTableUserSelection[lastIndex].pressure && selectedIndex != lastIndex) ) {
          message.error("Pressure value must be greater than previous row value");
          setDataTableUserSelection((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: null } : item
            ));
        }else{
          setDataTableUserSelection((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, [name]: dataValue } : item
            ));
        }
        break;
      default:
        break;
    }
  };

  const handleUploadFile1 = (dataFile) => {
    const dataTableProcessed = dataTableFluidLab1;
    const uniqueIds =  [...new Set(dataTableFluidLab1.map((obj) => obj.id))] 
    
    const maxId = (Math.max(...uniqueIds)) + 1;
    
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });

    const dataFileUp = dataFileEnd.map((item, index) => {
      return {
        ...item,
        gas_oil_ratio: (item.gas_oil_ratio < 0 || item.gas_oil_ratio > 10000) ? null : Number(item.gas_oil_ratio),
        oil_fvf: (item.oil_fvf < 1 || item.oil_fvf > 5) ? null : Number(item.oil_fvf),
        oil_viscosity: (item.oil_viscosity < 0.1 || item.oil_viscosity > 120000) ? null : Number(item.oil_viscosity),
        oil_compressibility: (item.oil_compressibility < 0.0000001 || item.oil_compressibility > 0.0001) || !item.oil_compressibility ? null : Number(item.oil_compressibility),
      };
    });
    const dataConcat =   dataTableProcessed.length ? [...dataTableProcessed,...dataFileUp] : dataFileUp;
    setDataTableFluidLab1(dataConcat);
  };

  const handleSelectedCheck = (check) => {
    setCheckPVT(check);
  }

  const handleCheckChange = (valueCheck, name) => {
    const updatedChecks = [...ActiveChecks]
    if (current === 1) {
      updatedChecks[0][name] = valueCheck;
    } else if (current === 2) {
      updatedChecks[1][selectedCheckbox][name] = valueCheck
    } else if (current === 3) {
      updatedChecks[2][name] = valueCheck;
    } else if (current === 4) {
      updatedChecks[3][name] = valueCheck;
    }
    setActiveChecks(updatedChecks);
  };;

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };
  
  function VerifyingNulls(array) {
    let validateValuesNull = true;

    array.forEach(obj => {
      Object.entries(obj).forEach(([key, value]) => {
        if ((value === null || value === '') && key !== 'oil_compressibility' && key !== 'temperature') {
          validateValuesNull = false;
        }
      });
    });
    return validateValuesNull;
  }

  function validateKeys(name) {
    const keys = Object.keys(inputFluidInformation).filter(key => key.startsWith(name));
    switch (name) {
      case "res":
        const invalValuesRes = keys.some(key => inputFluidInformation[key] === null || inputFluidInformation[key] === "");
        return invalValuesRes
      case "sep":
        const invalValuesSep = keys.some(key => inputFluidInformation[key] === null)
        return invalValuesSep;
      case "lab":
        const invalValuesLab = Object.keys(inputFluidLab).some(key => inputFluidLab[key] === "" || inputFluidLab[key] === null)
        const invalValuesDataLab = VerifyingNulls(dataTableFluidLab1);
        return [invalValuesLab, invalValuesDataLab];
      default:
        return false;
    }
  };

  return (
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="flex abosolute h-full"></Sidebar>
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <div className="flex w-full pl-[88px] text-center justify-center mt-[30px]">
        <Steps
            size="default"
            current={current}
            onChange={onChange}
            className="site-navigation-steps flex w-[1100px] custom-steps"
          >
            {steps
              .filter((_, index) => !(dataInformationEnd?.fluid_type === "dry_gas" && index === 4))
              .map((step, index) => (
                <Steps.Step
                  key={index}
                  title={step.title}
                  description={step.description}
                  className={`custom-step ${index > current ? 'custom-step-disabled' : index < current ? 'custom-step-checked' : ''}`}
                  icon={
                    <div className="custom-step-icon font-bold">
                      {customStepNumbers[index]}
                    </div>
                  }
                />
              ))}
          </Steps>
      </div>
      <div className="flex flex-col w-full h-full pl-[104px] text-center justify-center  pr-4 pt-4 pb-4">
        {current === 0 && (
          <>
            <div className="flex flex-row gap-10">
              <div className="flex flex-col w-1/2 text-right items-end justify-end">
                <div className="flex flex-row w-full max-w-[592px] text-start justify-start text-[20px] mb-[27px] mt-[27px]">
                  <h1 className="flex flex-row font-bold text-[20px]"> Reservoir data </h1>
                </div>
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Pressure (psia)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_pressure"
                  value={inputFluidInformation.res_pressure}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_pressure === "") || inputFluidInformation.res_pressure === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Temperature (deg F)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_temperature"
                  value={inputFluidInformation.res_temperature}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_temperature === "") || inputFluidInformation.res_temperature === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Oil gravity (API)
                  <Tooltip title="Maximum allowable value: 150">
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number" min="0" max="150" style={{ WebkitAppearance: 'none' }}
                  name="res_oil_gravity"
                  value={inputFluidInformation.res_oil_gravity}
                  onChange={handleChangeFluidInformation}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_oil_gravity === "") || inputFluidInformation.res_oil_gravity === null
                      ? " border-red-700 bg-yellow-100"
                      : " border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Gas gravity (Air = 1.000)
                  <Tooltip title="Maximum allowable value: 5.0">
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number" min="0" max="5" style={{ WebkitAppearance: 'none' }}
                  name="res_gas_gravity"
                  value={inputFluidInformation.res_gas_gravity}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_gas_gravity === "") || inputFluidInformation.res_gas_gravity === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Initial Solucion Gas-Oil Ratio (SCF/STB)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_initial_gor"
                  value={inputFluidInformation.res_initial_gor}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${inputEmpty && inputFluidInformation.res_initial_gor === ""
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  H2S (mole fraction)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_H2S"
                  value={inputFluidInformation.res_H2S}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_H2S === "") || inputFluidInformation.res_H2S === null
                      ? " border-red-700 bg-yellow-100"
                      : " border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  CO2 (mole fraction)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_CO2"
                  value={inputFluidInformation.res_CO2}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_CO2 === "") || inputFluidInformation.res_CO2 === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  N2 (mole fraction)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_N2"
                  value={inputFluidInformation.res_N2}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_N2 === "") || inputFluidInformation.res_N2 === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Water salinity (ppm)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="res_water_salinity"
                  value={inputFluidInformation.res_water_salinity}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${(inputEmpty && inputFluidInformation.res_water_salinity === "") || inputFluidInformation.res_water_salinity === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
              </div>
              {/* Separator data */}
              <div className="flex flex-col w-1/2 text-left items-start justify-start">
                <div className="flex flex-row w-full max-w-[592px] text-start justify-start text-[20px] mb-[27px] mt-[27px]">
                  <h1 className="font-bold">PVT Laboratory Separator Data</h1>
                </div>
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Pressure (psia)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="sep_pressure"
                  value={inputFluidInformation.sep_pressure}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px] 
                    ${inputFluidInformation.sep_pressure === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Temperature (deg F)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="sep_temperature"
                  value={inputFluidInformation.sep_temperature}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${inputFluidInformation.sep_temperature === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Oil gravity (API)
                  <Tooltip title="Maximum allowable value: 150">
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="sep_oil_gravity"
                  value={inputFluidInformation.sep_oil_gravity}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${inputFluidInformation.sep_oil_gravity === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Gas gravity (Air = 1.000)
                  <Tooltip title="Maximum allowable value: 5.0">
                    <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                  </Tooltip>
                </label>
                <input
                  type="number" min="0" max="5" style={{ WebkitAppearance: 'none' }}
                  name="sep_gas_gravity"
                  value={inputFluidInformation.sep_gas_gravity}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${inputFluidInformation.sep_gas_gravity === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Solution Gas-Oil Ratio (SCF/STB)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="sep_solution_gor"
                  value={inputFluidInformation.sep_solution_gor}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${inputFluidInformation.sep_solution_gor === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
                <label
                  htmlFor=""
                  className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                >
                  Oil Formation Volume Factor (RB/STB)
                </label>
                <input
                  type="number" min="0" style={{ WebkitAppearance: 'none' }}
                  name="sep_oil_volume_factor"
                  value={inputFluidInformation.sep_oil_volume_factor}
                  onChange={handleChangeFluidInformation}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                  className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 border border-solid mb-[17px]
                    ${inputFluidInformation.sep_oil_volume_factor === null
                      ? " border-red-700 bg-yellow-100"
                      : "border-[#BCBCCC]"
                    } `}
                />
              </div>
            </div>
          </>
        )}
        {current === 1 && (
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[20px] mt-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">
                  Fluid lab data
                </h1>
              </div>
            </div>
            <div className="flex flex-row w-full h-full justify-center text-center items-center gap-10">
              <div className="flex flex-col w-1/2 ">
                <div className="flex flex-row w-full text-right items-end justify-end mb-[1rem]">
                  <div className="flex w-full max-w-[592px] text-start font bold text-[15px] gap-2">
                    <Switch
                      style={{
                        backgroundColor: enableCheckLab ? "#662D91" : "#707070",
                        borderColor: enableCheckLab ? "#662D91" : "#707070",
                      }}
                      checked={enableCheckLab}
                      onChange={handleFluidLab}
                    ></Switch>
                    <Tooltip title="This button is used to enable fluid laboratory processes">
                      <InfoCircleOutlined className="" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2 h-[47px] text-right items-end justify-end"></div>
            </div>
            {enableCheckLab && (
              <div>
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col w-1/2 text-right items-end justify-end ">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Temperature (deg F)
                      <Tooltip title="Please enter the reservoir temperature or laboratory temperature">
                        <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                      </Tooltip>
                    </label>
                    <input
                      type="number" min="0" style={{ WebkitAppearance: 'none' }}
                      name="temperature_lab"
                      value={inputFluidLab.temperature_lab}
                      onChange={handleChangeFluidLab}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]  ${enableCheckLab && inputEmpty && !inputFluidLab.temperature_lab
                        ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 text-left items-start justify-start">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Solution GOR at Pb (SCF/STB)
                        <Tooltip title="Value can't be greater than 100000 and less than 0">
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                    </label>
                    <input
                      type="number" min="0" style={{ WebkitAppearance: 'none' }}
                      name="solution_gor_pb"
                      value={inputFluidLab.solution_gor_pb}
                      onChange={handleChangeFluidLab}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]  ${enableCheckLab && inputEmpty && !inputFluidLab.solution_gor_pb
                        ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-10 mb-[0.5rem]">
                  <div className="flex flex-col w-1/2 text-right items-end justify-end ">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Bubble Point Pressure (psia)
                        <Tooltip title="Value can't be greater than 25000 and less than 0">
                          <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                        </Tooltip>
                    </label>
                    <input
                      type="number" min="0" style={{ WebkitAppearance: 'none' }}
                      name="bubble_point_pressure_lab"
                      value={inputFluidLab.bubble_point_pressure_lab}
                      onChange={handleChangeFluidLab}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]  ${enableCheckLab && inputEmpty && !inputFluidLab.bubble_point_pressure_lab
                        ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC] bg-white-100"
                        } `}
                    />
                  </div>
                  <div className="flex flex-col w-1/2 text-left items-start justify-start">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                    >
                      Oil FVF at Pb (RB/STB)
                    </label>
                    <input
                      type="number" min="0" style={{ WebkitAppearance: 'none' }}
                      name="oil_fvf"
                      value={inputFluidLab.oil_fvf}
                      onChange={handleChangeFluidLab}
                      onWheel={(e) => e.target.blur()}
                      className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]  ${enableCheckLab && inputEmpty && !inputFluidLab.oil_fvf
                        ? " border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                        } `}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full items-center">
                  <div className="flex flex-row w-full text-center justify-center items-center">
                    <ul className="flex flex-col w-full items-center">
                      <div className="w-[76.5rem] ml-[0] items-center">
                        <div className="flex flex-row w-[100%] bg-[#E2D9E5] text-center justify-center items-center gap-5">
                          <label
                            htmlFor=""
                            className="flex w-full max-w-[260px] mr-[10px] text-start font-bold text-[15px]"
                          >
                            Pressure (psia)
                            <Tooltip title="Value can't be greater than 25000 and less than 0">
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </label>
                          <label
                            htmlFor=""
                            className="flex w-full max-w-[260px] mr-[10px] text-start font-bold text-[15px]"
                          >
                            Condensate Gas Ratio (STB/Mscf)
                            <Tooltip title="Value can't be greater than 10000 and less than 0">
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </label>
                          <label
                            htmlFor=""
                            className="flex w-full max-w-[260px] mr-[10px] text-start font-bold text-[15px]"
                          >
                            Z Compressibility factor
                            <Tooltip title="Value can't be greater than 5 and less than 1">
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </label>
                          <label
                            htmlFor=""
                            className="flex w-full max-w-[260px] mr-[10px] text-start font-bold text-[15px]"
                          >
                            Gas viscosity (cps)
                            <Tooltip title="Value can't be greater than 120000 and less than 0.1">
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                          </label>
                          <label
                            htmlFor=""
                            className="flex w-full max-w-[260px] mr-[10px] text-start font-bold text-[15px]"
                          >
                            Gas FVF (ft3/scf)
                            <Tooltip title="Value can't be greater than 0.0001 and less than 0.0000001">
                              <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                            </Tooltip>
                            <div className="relative left-[3.7rem] top-1">
                              <ImportFile
                                uploadfile={handleUploadFile1}
                                className="fill-neutral-500"
                              ></ImportFile>
                            </div>
                          </label>
                        </div>
                      </div>
                      {dataTableFluidLab1?.map((item, index) => (
                        <li
                          key={item.id}
                          className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center pl-5 ${dataTableFluidLab1.length - 1 === index && index === 3 ? "" : dataTableFluidLab1.length > 4 && (index === dataTableFluidLab1.length - 1) && (index != 3) ? "ml-8" : index < 3 && dataTableFluidLab1.length <= 4 ? "mr-6" : ""}`}
                        >
                          <input
                            type="number" min="0" style={{ WebkitAppearance: 'none' }}
                            name={`pressureTable1`}
                            value={item.pressureTable1}
                            onChange={(e) =>
                              handleChangeTableFluidLab1(e, item.id)
                            }
                            onBlur={(e) => handleChangeBlur(e, item.id)}
                            onWheel={(e) => e.target.blur()}
                            className={`w-full max-w-[220px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[30px] mr-[30px] ${(enableCheckLab && inputEmpty) && !item.pressureTable1
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC] bg-white-100"
                              } `}
                          />
                          <input
                            type="number" min="0" style={{ WebkitAppearance: 'none' }}
                            name={`gas_oil_ratio`}
                            value={item.gas_oil_ratio}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              handleChangeTableFluidLab1(e, item.id)
                            }

                            className={`w-full max-w-[220px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] ${enableCheckLab && inputEmpty && (item.gas_oil_ratio === null || item.gas_oil_ratio === "")
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC] bg-white-100"
                              } `}
                          />
                          <input
                            type="number" min="0" style={{ WebkitAppearance: 'none' }}
                            name={`oil_fvf`}
                            value={item.oil_fvf}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              handleChangeTableFluidLab1(e, item.id)
                            }
                            className={`w-full max-w-[220px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] ${enableCheckLab && inputEmpty && (item.oil_fvf === null || item.oil_fvf === "")
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC] bg-white-100"
                              } `}
                          />
                          <input
                            type="number" min="0" style={{ WebkitAppearance: 'none' }}
                            name={`oil_viscosity`}
                            value={item.oil_viscosity}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              handleChangeTableFluidLab1(e, item.id)
                            }
                            onBlur={(e) => handleChangeBlur(e, item.id)}
                            className={`w-full max-w-[220px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] ${enableCheckLab && inputEmpty && !item.oil_viscosity
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC] bg-white-100"
                              } `}
                          />
                          <input
                            type="number" min="0.0000001" max="0.00001" style={{ WebkitAppearance: 'none' }}
                            name={`oil_compressibility`}
                            value={item.oil_compressibility}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              handleChangeTableFluidLab1(e, item.id)
                            }
                            onBlur={(e) => handleChangeBlur(e, item.id)}
                            className={`w-full max-w-[220px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px]`}
                          />
                          <div className={`flex flex-row  text-right items-start gap-2`}>
                            {dataTableFluidLab1.length - 1 === index && (
                              <IconAdd
                                className="w-6 h-6 fill-neutral-500"
                                onClick={() => handleAddDataTable("dataFluidLab1")}
                              />
                            )}
                            {dataTableFluidLab1.length >= 5 && (
                              <IconDelete
                                className="w-6 h-6 fill-neutral-500"
                                onClick={() =>
                                  handleDeleteTable(item.id, "dataFluidLab1")
                                } 
                              />
                            )}
                          </div>
                        </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="flex flex-row w-full items-center justify-center text-center mt-[3rem]">
                  {(typeGraphFluid).map((item, index) => (
                    <div key={index}>
                      <Checkbox
                        key={index}
                        className="my-checkbox"
                        checked={ActiveChecks[0][item.value]}
                        onChange={(e) => handleCheckChange(e.target.checked, item.value)}
                      >
                        {item.name}
                      </Checkbox>
                    </div>
                  ))}
                </div>

                <div className="flex flex-row">
                  <div className="flex flex-col w-full h-full items-center justify-center text-center">
                    <ChartFluidData
                      dataTable={dataTableFluidLab1}
                      stateCheck={ActiveChecks[0]}
                    ></ChartFluidData>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {current === 2 && (
          <>
            {!enableCheckLab
              ?
              <div className="flex w-full h-[400px] text-center items-center justify-center">
                <Alert
                  description="This process is activated when PVT Laboratory information is available"
                  type="info"
                />
              </div>
              :
              <>
                <div className="flex w-full h-[24px] justify-center text-center items-center mb-[20px] mt-[27px]">
                  <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Regression process
                    </h1>
                  </div>
                </div>
                <div className="flex flex-col w-full h-full items-center justify-center text-center mt-[0.5rem]">
                  <TableRegression 
                    selectedCheck={handleSelectedCheck} 
                    selected={checkPVT} 
                    dataPVT={dataTablaRegression}
                  />
                </div>
                <div className="flex flex-row w-full gap-10 mt-[1.5rem]">
                  <div className="flex flex-col w-1/2 text-right items-end justify-end">
                    <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Pb, Rs, Bo</label>
                    <Select
                      style={{ backgroundColor: inputEmpty && !selectCorrelation.calculate_correlation ? '#FEF9C3' : '', border: inputEmpty && !selectCorrelation.calculate_correlation ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                      className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                      name="calculate_correlation"
                      value={selectCorrelation.calculate_correlation || "Selected option"}
                      onChange={(value) => handleChangeSelectRegression("calculate_correlation", value)}
                    >
                      <Option value="glaso">Glaso</Option>
                      <Option value="standing">Standing</Option>
                      <Option value="petrosky">Petrosky and Farshad</Option>
                    </Select>
                  </div>
                  <div className="flex flex-col w-1/2 text-left items-start justify-start">
                    <label htmlFor="" className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]">Oil Viscosity</label>
                    <Select
                      style={{ backgroundColor: inputEmpty && !selectCorrelation.calculate_correlation_oil ? '#FEF9C3' : '', border: inputEmpty && !selectCorrelation.calculate_correlation_oil ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                      className="w-full max-w-[592px] h-[47px] text-left mb-[15px]"
                      name="name"
                      value={selectCorrelation.calculate_correlation_oil || "Selected option"}
                      onChange={(value) => handleChangeSelectRegression("calculate_correlation_oil", value)}
                      // disabled={checkPVT[3].check ? false : true}
                    >
                      <Option value="beggs_beal">Beggs et al., Beal</Option>
                      <Option value="glaso_beggs">Glaso, Beggs et al., Vazquez et al</Option>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-row w-full items-center justify-center text-center mt-[30px] gap-5">
                  {Object.keys(ActiveChecks[1][selectedCheckbox] != null && (true) ? ActiveChecks[1][selectedCheckbox] : 0).map((id) => (
                    <>
                      <Checkbox
                        className="custom-checkbox"
                        checked={ActiveChecks[1][selectedCheckbox][id]}
                        onChange={(e) => handleCheckChange(e.target.checked, id)}
                        
                      >
                        {id}
                      </Checkbox>
                    </>
                  ))}
                </div>
                <div className="flex flex-col w-full h-full items-center justify-center text-center">
                  <ChartEndStep
                    dataPB={[]}
                    dataRS={[]}
                    dataBO={[]}
                    dataViso={[]}
                    stateCheck={ActiveChecks[1]}
                    nameCheck={selectedCheckbox}
                    dataPressure={dataTableFluidLab1}
                    bubblePoint={inputFluidLab}
                    pb={inputFluidLab.bubble_point_pressure_lab}
                  ></ChartEndStep>
                </div>
              </>
            }
          </>
        )}
        {current === 3 && (
          <>
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">
                  Data points
                </h1>
              </div>
            </div>
            <div className="flex flex-row w-full h-full justify-center text-center items-center mb-[20px] gap-10">
              <div className="flex flex-col w-1/2 ">
                <div className="flex flex-col w-full text-right items-end justify-end">
                  <Select
                    name="option_data_point"
                    style={{ backgroundColor: inputEmpty && inputDataCalculation.option_data_point === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.option_data_point === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                    className={`w-full max-w-[592px] h-[47px] text-left mb-[15px]`}
                    value={inputDataCalculation.option_data_point || "Selected option"}
                    onChange={(value) => handleChangeSelectCalculation("option_data_point", value)}
                  >
                    <Option value="automatic">Automatic</Option>
                    <Option value="user_selection">User selection</Option>
                  </Select>
                </div>
              </div>
              <div className="flex flex-col w-1/2 h-[47px] text-right items-end justify-end"></div>
            </div>
            {inputDataCalculation.option_data_point === "automatic" && (
              <div className="flex flex-col w-full items-center">
                <div className="flex flex-row w-full text-center justify-center items-center">
                  <ul className="flex flex-col w-full items-center">
                    <div className="w-[76.5rem] ml-[0] items-center">
                      <div className="flex flex-row w-[100%] bg-[#E2D9E5] text-center justify-center items-center gap-5">
                        <label
                          htmlFor=""
                          className="flex w-full max-w-[388px] mr-[10px] text-start font-bold text-[15px]"
                        >
                          Range/Values
                        </label>
                        <label
                          htmlFor=""
                          className="flex w-full max-w-[388px] mr-[10px] text-start font-bold text-[15px]"
                        >
                          Temperature (deg F)
                          <Tooltip title="Value can't be greater than 10000 and less than 0">
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                        <label
                          htmlFor=""
                          className="flex w-full max-w-[388px] text-start font-bold text-[15px]"
                        >
                          Pressure (psia)
                          <Tooltip title="Value can't be greater than 5 and less than 1">
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                      </div>
                    </div>
                    {dataTableAutomic.map((item, index) => (
                      <li
                        key={item.id}
                        className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center`}
                      >
                        <input
                          style={{ WebkitAppearance: 'none' }}
                          value={item.name}
                          readOnly
                          className={`w-full max-w-[388px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[30px] mr-[30px]`}
                        />
                        <input
                          type="number" min="0" style={{ WebkitAppearance: 'none' }}
                          name={`${item.name === 'Values' ? 'values' : (item.name === 'To' ? 'to' : 'from')}_temperature`}
                          value={item.name === 'Values' ? item.values_temperature : (item.name === 'To' ? item.to_temperature : item.from_temperature)}
                          onChange={(e) =>
                            handleChangeDataCalculation(e, item.id)
                          }
                          readOnly={item.name === "Values" || item.name === "To"}
                          onWheel={(e) => e.target.blur()}
                          className={`w-full max-w-[388px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] 
                            ${(inputEmpty && (item.name === 'Values' ? item.values_temperature : (item.name === 'To' ? item.to_temperature : item.from_temperature)) === "") || (item.name === 'Values' ? item.values_temperature : (item.name === 'To' ? item.to_temperature : item.from_temperature)) === null
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC]"
                            } `}
                        />
                        <input
                          type="number" min="0" style={{ WebkitAppearance: 'none' }}
                          name={`${item.name === 'Values' ? 'values' : (item.name === 'To' ? 'to' : 'from')}_pressure`}
                          value={item.name === 'Values' ? item.values_pressure : (item.name === 'To' ? item.to_pressure : item.from_pressure)}
                          onChange={(e) =>
                            handleChangeDataCalculation(e, item.id)
                          }
                          onBlur={(e) => handleChangeBlurDataPoint(e, item.id)}
                          onWheel={(e) => e.target.blur()}
                          className={`w-full max-w-[388px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] 
                            ${(inputEmpty && (item.name === 'Values' ? item.values_pressure : (item.name === 'To' ? item.to_pressure : item.from_pressure)) === "") || (item.name === 'Values' ? item.values_pressure : (item.name === 'To' ? item.to_pressure : item.from_pressure)) === null
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC]"
                            } `}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {inputDataCalculation.option_data_point === "user_selection" && (
              <div className="flex flex-col w-full items-center">
                <div className="flex flex-row w-full text-center justify-center items-center">
                  <ul className="flex flex-col w-full items-center">
                    <div className="w-[76.5rem] ml-[0] items-center">
                      <div className="flex flex-row w-[100%] bg-[#E2D9E5] text-center justify-center items-center gap-5">
                        <label
                          htmlFor=""
                          className="flex w-full max-w-[592px] mr-[15px] text-start font-bold text-[15px]"
                        >
                          Temperature (deg F)
                          <Tooltip title="Value can't be greater than 10000 and less than 0">
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                        </label>
                        <label
                          htmlFor=""
                          className="flex w-full max-w-[592px] text-start font-bold text-[15px]"
                        >
                          Pressure (psia)
                          <Tooltip title="Value can't be greater than 5 and less than 1">
                            <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                          </Tooltip>
                          <div className="relative left-[31.4rem] top-1">
                            <ImportFile
                              uploadfile={handleUploadFile1}
                              className="fill-neutral-500"
                            ></ImportFile>
                          </div>
                        </label>
                      </div>
                    </div>
                    {dataTableUserSelection.map((item, index) => (
                      <li
                        key={item.id}
                        className={`flex flex-row w-[100%] overflow-y-auto text-center justify-center items-center pl-5 ${dataTableUserSelection.length - 1 === index && index === 2 ? "" : index < 2 && dataTableUserSelection.length <= 3 ? "mr-6" : dataTableUserSelection.length > 3 && (index === dataTableUserSelection.length - 1) && (index != 2) ? "ml-8" : ""}`}
                      >
                        <input
                          type="number" min="0" style={{ WebkitAppearance: 'none' }}
                          name="temperature"
                          value={item.temperature}
                          onChange={(e) =>
                            handleChangeDataCalculation(e, item.id)
                          }
                          readOnly={index != 0}
                          onWheel={(e) => e.target.blur()}
                          className={`w-full max-w-[597px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] ml-[35px] mr-[30px]
                            ${(inputEmpty && item.temperature === "" && index === 0) || item.temperature === null
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC]"
                            } `}
                        />
                        <input
                          type="number" min="0" style={{ WebkitAppearance: 'none' }}
                          name="pressure"
                          value={item.pressure}
                          onChange={(e) =>
                            handleChangeDataCalculation(e, item.id)
                          }
                          onBlur={(e) => handleChangeBlurDataPoint(e, item.id)}
                          onWheel={(e) => e.target.blur()}
                          className={`w-full max-w-[597px] h-[47px] px-3 mt-[10px] rounded-[4px] border border-solid border-[#BCBCCC] mb-[17px] mr-[30px] 
                            ${(inputEmpty && item.pressure === "") || item.pressure === null
                              ? " border-red-700 bg-yellow-100"
                              : "border-[#BCBCCC]"
                            } `}
                        />
                        <div className={`flex flex-row  text-right items-start gap-2`}>
                          {dataTableUserSelection.length - 1 === index && (
                            <IconAdd
                              className="w-6 h-6 fill-neutral-500"
                              onClick={() => handleAddDataTable(inputDataCalculation.option_data_point)}
                            />
                          )}
                          {dataTableUserSelection.length >= 4 && (
                            <IconDelete
                              className="w-6 h-6 fill-neutral-500"
                              onClick={() =>
                                handleDeleteTable(item.id, inputDataCalculation.option_data_point)
                              }
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[1rem]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">
                  Correlations
                </h1>
              </div>
            </div>
            {dataInformationEnd?.fluid_type === "black_oil" && (
              <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[1rem]">
                <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                  <div className="flex flex-col w-[320px]">
                    <label htmlFor="" className="w-full text-start font bold text-[15px] mb-[7px]">Pb, Rs, Bo</label>
                    <Select
                      name="calculate_correlation"
                      style={{ backgroundColor: inputEmpty && inputDataCalculation.calculate_correlation === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.calculate_correlation === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                      value={inputDataCalculation.calculate_correlation || "Selected option"}
                      className="w-full h-[47px] text-left mb-[15px]"
                      onChange={(value) => handleChangeSelectCalculation("calculate_correlation", value)}
                    >
                      <Option value="glaso">Glaso</Option>
                      <Option value="standing">Standing</Option>
                      <Option value="petrosky">Petrosky and Farshad</Option>
                    </Select>
                  </div>
                  <div className="flex flex-col w-[320px]">
                    <label htmlFor="" className="w-full text-start font bold text-[15px] mb-[7px]">Oil Viscosity</label>
                    <Select
                      name="calculate_correlation_oil"
                      value={inputDataCalculation.calculate_correlation_oil || "Selected option"}
                      className="w-full h-[47px] text-left mb-[15px]"
                      style={{ backgroundColor: inputEmpty && inputDataCalculation.calculate_correlation_oil === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.calculate_correlation_oil === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                      onChange={(value) => handleChangeSelectCalculation("calculate_correlation_oil", value)}
                    >
                      <Option value="beggs_beal">Beggs et al., Beal</Option>
                      <Option value="glaso_beggs">Glaso, Beggs et al., Vazquez et al</Option>
                    </Select>
                  </div>
                  <div className="flex flex-col mt-[0.8rem]">
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      onClick={() => handleCalculation()}
                    >
                      Calculate
                    </button>
                  </div>
                </div>
              </div>
            )}
            {dataInformationEnd?.fluid_type === "dry_gas" && (
              <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[1rem]">
                <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                  <div className="flex flex-col w-[320px]">
                    <label htmlFor="" className="w-full text-start font bold text-[15px] mb-[7px]">Z-Gas compressibility factor</label>
                    <Select
                      name="calculate_correlation"
                      style={{ backgroundColor: inputEmpty && inputDataCalculation.calculate_correlation === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.calculate_correlation === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                      value={inputDataCalculation.calculate_correlation || "Selected option"}
                      className="w-full h-[47px] text-left mb-[15px]"
                      onChange={(value) => handleChangeSelectCalculation("calculate_correlation", value)}
                    >
                      <Option value="brill_and_beggs">Brill and Beggs</Option>
                    </Select>
                  </div>
                  <div className="flex flex-col w-[320px]">
                    <label htmlFor="" className="w-full text-start font bold text-[15px] mb-[7px]">Gas Viscosity</label>
                    <Select
                      name="calculate_correlation_oil"
                      value={inputDataCalculation.calculate_correlation_oil || "Selected option"}
                      className="w-full h-[47px] text-left mb-[15px]"
                      style={{ backgroundColor: inputEmpty && inputDataCalculation.calculate_correlation_oil === '' ? '#FEF9C3' : '', border: inputEmpty && inputDataCalculation.calculate_correlation_oil === '' ? '1px solid #B91C1C' : '', borderRadius: '8px', textAlignalign: "left" }}
                      onChange={(value) => handleChangeSelectCalculation("calculate_correlation_oil", value)}
                    >
                      <Option value="beggs_beal0">Option 1</Option>
                      <Option value="glaso_beggs1">Option 2</Option>
                      <Option value="glaso_beggs2">Option 3</Option>
                      <Option value="glaso_beggs3">Option 4</Option>
                    </Select>
                  </div>
                  <div className="flex flex-col mt-[0.8rem]">
                    <button
                      className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                      // onClick={() => handleCalculation()}
                    >
                      Calculate
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px]">
              <div className="flex flex-row w-[1220px] h-[24px] gap-5 justify-start text-center items-center ">
                <h1 className="flex flex-row font-bold text-[20px]">
                  PVT Calculations
                </h1>
              </div>
            </div>
            <div className="flex flex-row w-full h-full justify-center text-center items-center mb-[0.5rem] gap-10">
              <div className="flex flex-col w-1/2 ">
                <div className="flex flex-col w-full text-right items-end justify-end">
                  <label
                    htmlFor=""
                    className="flex w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
                  >
                    Bubble Point Pressure (psia)
                  </label>
                  <input
                    type="number" min="0" style={{ WebkitAppearance: 'none' }}
                    name="bubble_point_pressure"
                    readOnly
                    onChange={handleChangeDataCalculation}
                    value={inputDataCalculation.bubble_point_pressure}
                    onWheel={(e) => e.target.blur()}
                    className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] border border-solid mb-[17px]`}
                  />
                </div>
              </div>
              <div className="flex flex-col w-1/2 h-[47px] text-right items-end justify-end"></div>
            </div>
            <div className="flex flex-col w-full h-full items-center justify-center text-center">
              <TablePVTCalculate dataTable={dataTablePVT}/>
            </div>

            <div className="flex flex-row w-full items-center justify-center text-center mt-[3rem]">
              {(typeGraphFluid).map((item, index) => (
                <div key={index}>
                  <Checkbox
                    key={index}
                    className="my-checkbox"
                    checked={ActiveChecks[2][item.value]}
                    onChange={(e) => handleCheckChange(e.target.checked, item.value)}
                  >
                    {item.name}
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className="flex flex-col w-full h-full items-center justify-center text-center">
              <ChartCalculation
                stateCheck={ActiveChecks[2]}
                dataTable={dataTablePVT}
              ></ChartCalculation>
            </div>
          </>
        )}       

        <div className="flex w-full text-center justify-center items-center">
          <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
            {current > 0 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickPrev()}
              >
                Previous
              </button>
            )}
            {((current === (1 || 2) && enableCheckLab) || current === 3) && (
              <>
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  // onClick={() => handleClickParcialDone()}
                >
                  Save & Exit
                </button>
              </>
            )}
            {current < steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickNext()}
              >
                Continue
              </button>
            )}
            {current === steps.length - 1 && (
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickDone()}
              >
                Done
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
