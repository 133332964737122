import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as IconAdd } from "../../../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../../../assets/img/icons/delete.svg";
import { ImportFileInjector } from "../../../../components/Injector/complements/ImportFileInjector";
import DownloadData from "../../../../components/DownloadData/DownloadData";
import classes from "./WellDeviation.module.css";
import Table from "./Table";
const {
	wellDeviationInjector,
	wellDeviationInjector__headerWrapper,
	wellDeviationInjector__title,
	wellDeviationInjector__actions,
	"wellDeviationInjector__list--tooltip": wellDeviationInjector__list_tooltip,
} = classes;
const WellDeviation = ({
	dataDeviation,
	handleChangeDeviation,
	handleBlurDeviation,
	handleAddDeviation,
	handleDeleteDeviation,
	handleKeyDown,
	handleUploadFile,
	validationTable,
	isEditing,
}) => {
	const headers = [
		{
			name: "Measured Depth (ft)",
			key: "measured_depth",
			tooltip: "Depth values must be between 0 and 50000 feet",
			type: "number",
			width: "100%",
		},
		{
			name: "True Vertical Depth (ft)",
			key: "tvd",
			tooltip: (
				<ul className={wellDeviationInjector__list_tooltip}>
					<li>True vertical depth values must be between 0 and 50000 feet</li>
					<li>
						True vertical depth values less than or equal to Measured depth (ft)
					</li>
				</ul>
			),
			type: "number",
			width: "100%",
		},
		{
			name: "Inclination (°)",
			key: "inc",
			tooltip: "Inclination values must be between -25 and 125 degrees",
			type: "number",
			width: "100%",
		},
	];
	return (
		<div className={wellDeviationInjector}>
			{/* Encabezado */}
			<div className={wellDeviationInjector__headerWrapper}>
				<h1 className={wellDeviationInjector__title}>Well Trajectory</h1>
				<div className={wellDeviationInjector__actions}>
					<Tooltip placement="bottom" title="UPLOAD TABLE" color="#662D91">
						<span>
							<ImportFileInjector
								uploadfile={handleUploadFile}
								tableType="well_deviation"
							/>
						</span>
					</Tooltip>
					<Tooltip placement="bottom" title="DOWNLOAD TABLE" color="#662D91">
						<span>
							<DownloadData
								data={dataDeviation}
								fileName="data Well trajectory.xlsx"
							/>
						</span>
					</Tooltip>
				</div>
			</div>
			{/* Tabla */}
			<Table
				headers={headers}
				data={dataDeviation}
				hiddenColumns={["id"]}
				onChange={handleChangeDeviation}
				onBlur={handleBlurDeviation}
				// onSelectRow={(id, rowData) =>
				//   console.log("Fila seleccionada:", id, rowData)
				// }
				selectableRow={false}
				numeracion={true}
				onAddRow={handleAddDeviation}
				onDeleteRow={handleDeleteDeviation}
				validationError={validationTable?.tableDeviation}
			/>
		</div>
	);
};

export default WellDeviation;
