import { REQUIRED_FIELDS_VALIDATIONS } from "../elements/types/type_dca";

//Funcion Para establecer formato de fecha string del straming
export const formatDateIOT = (dateString) => {
	if (!dateString || typeof dateString !== "string") {
		console.error("Invalid dateString:", dateString);
		return null;
	}
	let formattedDate;
	if (dateString?.includes(" ")) {
		const [datePart, timePart] = dateString.split(" ");
		const [year, month, day] = datePart.split("-");
		formattedDate = `${parseInt(month)}/${parseInt(day)}/${year} ${timePart}`;
	} else {
		const [year, month, day] = dateString.split("-");
		formattedDate = `${parseInt(month)}/${parseInt(day)}/${year} 00:00:00`;
	}
	return isNaN(new Date(formattedDate).getTime()) ? null : formattedDate;
};

//Funcion Para establecer formato de fecha numerica desde excel
export const formatDateHist = (excelDate) => {
	const dateEnd = Number(excelDate);
	// Validar si es un número válido
	if (isNaN(dateEnd)) {
		return null;
	}
	// Base date de Excel (1 de enero de 1900)
	const baseDate = new Date(1900, 0, 1);
	// Calcular los milisegundos desde el 1 de enero de 1970
	const jsTimestamp = baseDate.getTime() + (dateEnd - 2) * 24 * 60 * 60 * 1000;
	return jsTimestamp;
};

export const formatTimestampToDateString = (timestamp) => {
	if (!timestamp || isNaN(timestamp)) return null;

	const dateObj = new Date(timestamp);

	const day = String(dateObj.getDate()).padStart(2, "0");
	const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // +1 porque los meses en JS van de 0 a 11
	const year = dateObj.getFullYear();
	const hours = String(dateObj.getHours()).padStart(2, "0");
	const minutes = String(dateObj.getMinutes()).padStart(2, "0");
	const seconds = String(dateObj.getSeconds()).padStart(2, "0");

	return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

//Funcion Para establecer formato de fecha numerica de excel a fecha string
export const excelDateToFullString = (excelSerial, is1904 = false) => {
	if (!excelSerial || isNaN(excelSerial)) return excelSerial; // Evita modificar valores no numéricos

	// Ajuste según sistema de fechas
	const excelBaseDate = is1904
		? new Date(Date.UTC(1904, 0, 1))
		: new Date(Date.UTC(1899, 11, 30));
	const date = new Date(excelBaseDate.getTime() + excelSerial * 86400000);
	// const baseDate = is1904 ? Date.UTC(1904, 0, 1) : Date.UTC(1899, 11, 30);
	// const date = new Date((excelSerial - (is1904 ? 0 : 2)) * 24 * 60 * 60 * 1000 + baseDate);

	// Formatear fecha
	const year = date.getUTCFullYear();
	const month = String(date.getUTCMonth() + 1).padStart(2, "0");
	const day = String(date.getUTCDate()).padStart(2, "0");
	const hours = String(date.getUTCHours()).padStart(2, "0");
	const minutes = String(date.getUTCMinutes()).padStart(2, "0");
	const seconds = String(date.getUTCSeconds()).padStart(2, "0");

	return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const processValue = (name, value) => {
	if (typeof value === "boolean") {
		return value;
	}

	if (value == null) return "";
	let cleanCharacters = String(value);

	if (value !== "" && REQUIRED_FIELDS_VALIDATIONS.includes(name)) {
		return Number(value);
	}

	switch (name) {
		case "company":
			cleanCharacters = cleanCharacters.replace(/[^a-zA-Z0-9 &]/g, ""); // SOLO permite letras, números, espacios y "&"
			break;
		case "field":
		case "county":
			cleanCharacters = cleanCharacters
				.replace(/[^a-zA-Z0-9 _]/g, "")
				.replace(/\s+/g, "_"); // Permite solo letras, números y "_"
			break;
		case "well_platforms":
			cleanCharacters = cleanCharacters
				.replace(/[^a-zA-Z0-9 _-]/g, "") // Permite letras, números, espacio, "_" y "-"
				.replace(/\s+/g, "_"); // Reemplaza espacios por "_"
			break;
		case "well_name":
			cleanCharacters = cleanCharacters
				.replace(/[^a-zA-Z0-9 _#]/g, ""); // Permite solo letras, números, espacios y numeral
			break;
		default:
			cleanCharacters = cleanCharacters.replace(/[^a-zA-Z0-9 _]/g, ""); // Permite solo letras, números y espacios
	}

	return cleanCharacters;
};
// Reemplaza "_" por espacios
export const formatFieldName = (field) => {
	return field?.replace(/_/g, " ") || "";
};

export const validateMeasuredDepthSequence = (data) => {
	let validatedData = [...data];

	for (let i = 0; i < validatedData.length; i++) {
		for (let j = i + 1; j < validatedData.length; j++) {
			if (
				validatedData[i].measured_depth !== "" &&
				validatedData[j].measured_depth !== "" &&
				validatedData[i].measured_depth >= validatedData[j].measured_depth
			) {
				validatedData[j].measured_depth = null;
			}
		}
	}

	return validatedData;
};
export const calculateTicks = (minValue, maxValue) => {
	minValue = Number(minValue);
	maxValue = Number(maxValue);
	if (minValue === 0 && maxValue === 0) {
		maxValue = 1;
	}

	if (minValue === maxValue) {
		return [maxValue];
	}

	const step = (maxValue - minValue) / 5;
	const ticks = Array.from(
		{
			length: 6,
		},
		(_, i) => parseFloat(minValue + i * step),
	);

	return [...new Set(ticks)].sort((a, b) => a - b);
};

export const customTickFormatter = (value) => {
	if (value === 0) return "0";
	if (value > -1 && value < 1) return Number(value).toFixed(2);
	return Math.round(value);
};


	// Función para aplanar objetos anidados
	export const flattenObject = (obj) => {
		let result = {};

		Object.keys(obj).forEach((key) => {

			if (typeof obj[key] === "object" && obj[key] !== null) {
				// Verificar si la clave contiene "initial"
				if (key.includes("initial")) {
					// Aplanar solo si la clave contiene "initial"
					Object.assign(result, flattenObject(obj[key], key));
				} else {
					// Mantener la estructura para claves que no contienen "initial"
					result[key] = obj[key];
				}
			} else {
				result[key] = obj[key];
			}
		});

		return result;
	};
