import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_INJECTOR = config.API.INJECTOR.BASE_URL_INJECTOR;
const BASE_DATA_HISTORY = config.API.INJECTOR.BASE_URL_DATA_HISTORY;
const BASE_URL_VOLUMETRACKER = config.API.INJECTOR.BASE_URL_VOLUMETRACKER;
const BASE_URL_VOLUMETRACKER_RT = config.API.INJECTOR.BASE_URL_VOLUMETRACKER_RT;
const BASE_URL_IOT = config.API.IOT.BASE_URL_IOT;
const TOKEN_INJECTOR = config.AUTH.TOKEN_INJECTOR;
const TOKEN_HISTORY = config.AUTH.TOKEN_HISTORIC;
const TOKEN_VOLUMETRACKER = config.AUTH.TOKEN_VOLUMETRACKER;
const TOKEN_IOT = config.AUTH.TOKEN_IOT;

export const uploadDataInjector = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;

	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_INJECTOR}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersinjector`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_INJECTOR,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const updateDataInjector = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;

	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_INJECTOR}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersinjector`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_INJECTOR,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the information of the well");
	}
};

export const deleteDataInjector = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_INJECTOR}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersinjector`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_INJECTOR,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting the information of the well");
	}
};

export const getDatainjector = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;
	try {
		const response = await fetch(
			`${BASE_URL_INJECTOR}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersinjector`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_INJECTOR,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getListWellsInjector = async (
	operator,
	lease,
	region,
	userName,
) => {
	if (!validateParams(operator, lease, region, userName)) return;
	try {
		const response = await fetch(
			`${BASE_URL_INJECTOR}/parameterslist/${operator}/${lease}/${region}/${userName}/parametersinjector`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_INJECTOR,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const uploadDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_DATA_HISTORY}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORY,
				},
				body: JSON.stringify({
					data,
				}),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the data history");
	}
};

export const updateDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_DATA_HISTORY}/update-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORY,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the information of the data history");
	}
};

export const deleteDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${BASE_DATA_HISTORY}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_HISTORY,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting the information of the well");
	}
};

export const getDataHistory = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;
	try {
		const response = await fetch(
			`${BASE_DATA_HISTORY}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORY,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the data history");
	}
};

export const getListDataHistory = async (operator, lease, region, userName) => {
	if (!validateParams(operator, lease, region, userName)) return;
	try {
		const response = await fetch(
			`${BASE_DATA_HISTORY}/list-wells/${operator}/${lease}/${region}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORY,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const uploadJsonVolumeTracker = async (dataJson) => {
	const { operator, lease, region, county, field, userName, data } = dataJson;
	if (!validateParams(operator, lease, region, county, field, userName)) return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/upload_allocation/${operator}/${lease}/${region}/${county}/${field}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getCumulative_allData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, userName)) return;
	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/acumulative_all_month/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getCumulative_lastData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, userName)) return;
	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/acumulative_last_month/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getChartInjectorHistoryData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
	wellName
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, user,wellName))
		return;
	const encodedWellName = encodeURIComponent(wellName);
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/injector_liquid/500/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}?well_name=${encodedWellName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getLastChartInjectorHistoryData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
	wellName
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, user,wellName))
		return;
	const encodedWellName = encodeURIComponent(wellName);
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/injector_liquid/1/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}?well_name=${encodedWellName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getChartInjectorPerformanceData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
	wellName,
) => {
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			wellid,
			user,
			wellName,
		)
	)
		return;
	const encodedWellName = encodeURIComponent(wellName);
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/injector/500/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}?well_name=${encodedWellName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};
export const getLastChartInjectorPerformanceData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
	wellName,
) => {
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			wellid,
			user,
			wellName,
		)
	)
		return;
	const encodedWellName = encodeURIComponent(wellName);
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/injector/1/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}?well_name=${encodedWellName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

