import React, { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { useAuth } from "../hooks/useAuth";

export const AuthProvider = ({ children }) => {
	const { Auth, User, signin, signout } = useAuth();

	return (
		<AuthContext.Provider
			value={{
				Auth,
				User,
				signin,
				signout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
