import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const BASE_URL_IPR = config.API.IPR.BASE_URL_IPR;
const BASE_URL_VOLUMETRACKER = config.API.GASLIFT.BASE_URL_VOLUMETRACKER;
const BASE_URL_PLUNGER_LIFT = config.API.PLUNGER_LIFT.URL_PLUNGER_LIFT;
const BASE_URL_WELL = config.API.GASLIFT.BASE_URL_WELL;
const DB_DATA_HISTORY = config.API.PLUNGER_LIFT.BASE_URL_DATA_HISTORY;
const TOKEN_PLUNGER_LIFT = config.AUTH.TOKEN_PLUNGER_LIFT;
const TOKEN_IPR_PLUNGER_LIFT = config.AUTH.TOKEN_IPR_PLUNGER_LIFT;
const TOKEN_PVT_PLUNGER_LIFT = config.AUTH.TOKEN_PVT_PLUNGER_LIFT;
const TOKEN_PROCESS_PLUNGER_LIFT = config.AUTH.TOKEN_PROCESS_PLUNGER_LIFT;
const TOKEN_SCENARIOS_IPR_PLUNGER_LIFT =
	config.AUTH.TOKEN_SCENARIOS_IPR_PLUNGER_LIFT;
const TOKEN_GRAPH_EXCEL_PLUNGER_LIFT =
	config.AUTH.TOKEN_GRAPH_EXCEL_PLUNGER_LIFT;
const TOKEN_HISTORIC = config.AUTH.TOKEN_HISTORIC;
const TOKEN_VOLUMETRACKER = config.AUTH.TOKEN_VOLUMETRACKER;

export const uploadJsonIPRPL = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_IPR_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload_json service operation:",
			error,
		);
	}
};

export const calculateQIPRPL = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/calculate_q/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IPR_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_q service operation:",
			error,
		);
	}
};

export const calculatePwfIPR = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
) => {
	if (!validateParams(operator, lease, region, county, field, well)) return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/calculate_pwf/${operator}/${lease}/${region}/${county}/${field}/${well}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IPR_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_pwf service operation:",
			error,
		);
	}
};

export const calculateCasing = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/calculate_table/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PROCESS_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_table service operation:",
			error,
		);
	}
};

export const uploadJsonCasing = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PROCESS_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload_json service operation:",
			error,
		);
	}
};

export const uploadJsonVolumeTracker = async (dataJson) => {
	const { operator, lease, region, county, field, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, userName, data))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/upload_allocation_plunger/${operator}/${lease}/${region}/${county}/${field}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem uploading volume tracker data");
	}
};

export const getDataAllocation = async (operator, lease, region, county, field, wellid, username) => {
	if (!validateParams(operator, lease, region, county, field, wellid,username)) return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/get_allocation_plunger/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem uploading volume tracker data");
	}
};

export const getJsonVolumeTracker = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_VOLUMETRACKER}/average_plunger_realtime/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_press service operation:",
			error,
		);
	}
};

export const calculatePressure = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift/calculate_press/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PROCESS_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_press service operation:",
			error,
		);
	}
};

export const uploadJsonPressure = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift/upload_json_calc_press/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PROCESS_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload_json_calc_press service operation:",
			error,
		);
	}
};

export const calculateInterpolation = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/calculate_interpolation/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PROCESS_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_interpolation service operation:",
			error,
		);
	}
};

export const dataWellPlungerLift = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, country, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/parametersplungerlift`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during parameters service operation:",
			error,
		);
	}
};

export const uploadJsonWellPL = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersplungerlift`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload parameters service operation:",
			error,
		);
	}
};

export const updateJsonWellPL = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersplungerlift`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during update parameters service operation:",
			error,
		);
	}
};

export const deleteWellPL = async (dataJson) => {
	const { operator, lease, region, county, field, well, username } = dataJson;
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersplungerlift`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during delete parameters service operation:",
			error,
		);
	}
};

export const listWellPlungerLift = async (
	operator,
	lease,
	region,
	username,
) => {
	if (!validateParams(operator, lease, region, username)) return;

	try {
		const response = await fetch(
			`${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/parametersplungerlift`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during parameterslist service operation:",
			error,
		);
	}
};

export const calculatePVT = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, country, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_pvt/calculate_pvt/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PVT_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during calculate_pvt service operation:",
			error,
		);
	}
};

export const uploadJsonPVT = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_pvt/upload_json_pvt/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PVT_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload_json_pvt service operation:",
			error,
		);
	}
};

export const uploadExcelFile = async (fileData, dataParams) => {
	const { operator, lease, region, county, field, well, username } = dataParams;
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			well,
			username,
			fileData,
		)
	)
		return;

	const formData = new FormData();
	formData.append("file", fileData);

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift/upload_excel/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"x-api-key": TOKEN_GRAPH_EXCEL_PLUNGER_LIFT,
				},
				body: formData,
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload_excel service operation:",
			error,
		);
	}
};

export const getProcessedData = async (
	operator,
	lease,
	region,
	county,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_PLUNGER_LIFT}/plunger_lift/get_excel/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_GRAPH_EXCEL_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during get_excel service operation:",
			error,
		);
	}
};

export const uploadDataGraphics = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, data } =
		dataJson;
	if (
		!validateParams(operator, lease, region, county, field, well, username, data)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/upload_graph/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_SCENARIOS_IPR_PLUNGER_LIFT,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during upload_graph service operation:",
			error,
		);
	}
};

export const dataGraphic = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
	nameIpr,
) => {
	if (
		!validateParams(
			operator,
			lease,
			region,
			country,
			field,
			well,
			username,
			nameIpr,
		)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/get_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${nameIpr}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_SCENARIOS_IPR_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during get_graph service operation:",
			error,
		);
	}
};

export const listDataGraphic = async (
	operator,
	lease,
	region,
	country,
	field,
	well,
	username,
) => {
	if (!validateParams(operator, lease, region, country, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/list_graph/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_SCENARIOS_IPR_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during list_graph service operation:",
			error,
		);
	}
};

export const deleteScenarioPL = async (dataJson) => {
	const { operator, lease, region, county, field, well, username, nameIPRPL } =
		dataJson;
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			well,
			username,
			nameIPRPL,
		)
	)
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/delete_graph/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${nameIPRPL}`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_SCENARIOS_IPR_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during delete_graph service operation:",
			error,
		);
	}
};

export const deleteListDataGraphic = async (dataJson) => {
	const { operator, lease, region, county, field, well, username } = dataJson;
	if (!validateParams(operator, lease, region, county, field, well, username))
		return;

	try {
		const response = await fetch(
			`${BASE_URL_IPR}/plunger_lift/delete_all_graph/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_SCENARIOS_IPR_PLUNGER_LIFT,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error(
			"PlungerLift: Error during delete_all_graph service operation:",
			error,
		);
	}
};

export const uploadDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify({
					data,
				}),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the data history");
	}
};

export const updateDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/update-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the information of the data history");
	}
};

export const deleteDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting the information of the well");
	}
};

export const getDataHistory = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;
	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the data history");
	}
};

export const getListDataHistory = async (operator, lease, region, userName) => {
	if (!validateParams(operator, lease, region, userName)) return;
	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/list-wells/${operator}/${lease}/${region}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};
