import React from 'react'
import { 
  ScatterChart, 
  Scatter, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer, 
  Cell, 
  Legend 
} from 'recharts';
import { SPECIFIC_VALUE_MULTIPLE_SCATTER } from '../../../elements/types/type_screening_tool';

export const ChartMultipleScatter = (props) => {
  const { dataGraph, type } = props
  
  const axisX = type === "oil_vs_water_cut" || type === "oil_rate_vs_gor" ? SPECIFIC_VALUE_MULTIPLE_SCATTER.oil_rate : SPECIFIC_VALUE_MULTIPLE_SCATTER.gor;
  const axisY = type === "oil_vs_water_cut" || type === "gor_vs_water_cut" ? SPECIFIC_VALUE_MULTIPLE_SCATTER.water_cut : SPECIFIC_VALUE_MULTIPLE_SCATTER.gor;

  const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A8", "#33FFF5"];
  const clusterColors = {};
  
  const dataWithColor = dataGraph.map(item => {
    if (!clusterColors[item.cluster]) {
      clusterColors[item.cluster] = colors[Object.keys(clusterColors).length % colors.length];
    }
    return {
      ...item,
      fill: clusterColors[item.cluster]
    };
  });

  function customTooltip({ active, payload, option }) {
    if (active && payload && payload.length) {
      const valuesData = payload[0]?.payload;

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "6px", textAlign: "left" }}
        >
          <p>
            {axisX.name+`: `}
            <span>{(valuesData[axisX.value])?.toFixed(2)} {axisX.unit}</span>
          </p>
          <p>
            {axisY.name+`: `}
            <span>{(valuesData[axisY.value])?.toFixed(2)} {axisY.unit}</span>
          </p>
        </div>
      );
    }
    return null;
  }

  const renderCustomLegend = () => (
    <div className="flex justify-center gap-5 mt-8 pl-[60px]">
      {Object.keys(clusterColors).sort().map((cluster, index) => (
        <div key={index} className="flex items-center">
          <div
            className="w-2 h-2 mr-2"
            style={{
              backgroundColor: clusterColors[cluster],
            }}
          />
          <span className='text-[14px]'>{cluster}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className='flex w-full h-full'>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{
            top: 20,
            right: 25,
            bottom: 20,
          }}
        >
          <CartesianGrid />
          <XAxis 
            type="number" 
            dataKey={axisX.value}
            name="Oil rate"
            label={{
              value: type === "oil_vs_water_cut" || type === "oil_rate_vs_gor" ? "Oil Rate (STB/D)" : "GOR (SCF/STB)",
              position: "insideBottom",
              offset: -15,
            }}
        />
          <YAxis 
            type="number" 
            dataKey={axisY.value} 
            name="Water cut"
            label={{
              value: type === "oil_vs_water_cut" || type === "gor_vs_water_cut" ? "Water Cut (%)" : "GOR (SCF/STB)",
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
              dx: 12
            }}
          />
          <Tooltip content={customTooltip}/>
          <Scatter data={dataWithColor} />
          <Legend content={renderCustomLegend} />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

