import { Menu } from 'antd';
import React, { useContext, useState } from 'react'
import { ReactComponent as OperatorIcon } from '../../assets/img/icons/operator.svg';
import { ReactComponent as LeaseIcon } from '../../assets/img/icons/lease.svg';
import { ReactComponent as RegionIcon } from '../../assets/img/icons/region.svg';
import { Navbar } from '../../components/layout/Navbar';
import { Sidebar } from '../../components/layout/Sidebar';
import { CrudLease } from '../../components/User/ControlParameters/CrudLease';
import { CrudRegion } from '../../components/User/ControlParameters/CrudRegion';
import { CrudOperator } from '../../components/User/ControlParameters/CrudOperator';
import { useUsers } from '../../hooks/useUsers';
import { useEffect } from 'react';
import { Loading } from '../../components/Loading';
import { NAME_PAGES } from '../../components/elements/types/type_pages';
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
const items = [
    {
      label: 'Operator',
      key: 'operator',
      icon: <OperatorIcon />
    },
    {
      label: 'Lease',
      key: 'lease',
      icon: <LeaseIcon />,
    },
    {
      label: 'Region',
      key: 'region',
      icon: <RegionIcon />,
    },
  ];
  
export const UsersControlParameters = () => {
    const {
      refetchDataOptionRegions,
      refetchDataOptionLeases,
      refetchDataOptionOperators,
      dataOptionsOperators,
      dataOptionsLeases,
      dataOptionsRegions,
      loadOptionRegions,
      loadOptionLeases,
      loadOptionOperators
    } = useUsers();
    const { getDataPage } = useContext(DashboardContext);
    const [current, setCurrent] = useState('operator');
    const [wellboreOptionState, setWellboreOptionState] = useState(false);

    const wellboreOption = () => {
        setWellboreOptionState(!wellboreOptionState);
    };

    useEffect(() => {
      getDataPage(NAME_PAGES.user.control.create);
    }, []);

    useEffect(()=>{
      if(current === "operator"){
        refetchDataOptionOperators();
      }
      else if(current === "lease"){
        refetchDataOptionLeases();
      }
      else if(current === "region"){
        refetchDataOptionRegions();
      }
    },[current])

    const onClick = (e) => {
      setCurrent(e.key);
    };
  
    return (
        <div className="flex flex-col w-full h-full bg-white ">
          <div className="flex">
            <Navbar wellboreOption={wellboreOption}></Navbar>
            <Sidebar className="absolute flex h-full "></Sidebar>
          </div>
          <div className="flex flex-col w-full h-full pl-[80px]">
            <div >
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            </div>
            {(loadOptionRegions || loadOptionLeases || loadOptionOperators)
              ?
              <Loading></Loading>
              :
              <div className='flex w-full h-full'>
                { current === "operator" &&
                  <CrudOperator dataList={dataOptionsOperators ? dataOptionsOperators : []}></CrudOperator>
                }
                { current === "lease" &&
                  <CrudLease dataList={dataOptionsLeases ? dataOptionsLeases : []}></CrudLease>
                }
                { current === "region" &&
                  <CrudRegion dataList={dataOptionsRegions ? dataOptionsRegions : []}></CrudRegion>
                }
              </div>
            }
          </div>
        </div>
      );
}
