import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_DCA = config.API.DCA.BASE_URL_DCA;
const DB_DATA_HISTORY = config.API.DCA.BASE_URL_DATA_HISTORY;
const DB_URL_VOLUMETRACKER = config.API.DCA.BASE_URL_VOLUMETRACKER;
const BASE_URL_VOLUMETRACKER_RT = config.API.DCA.BASE_URL_VOLUMETRACKER_RT;
const BASE_URL_IOT = config.API.IOT.BASE_URL_IOT;
const TOKEN_HISTORIC = config.AUTH.TOKEN_HISTORIC;
const TOKEN_VOLUMETRACKER = config.AUTH.TOKEN_VOLUMETRACKER;
const TOKEN_DCA = config.AUTH.TOKEN_DCA;
const TOKEN_IOT = config.AUTH.TOKEN_IOT;

export const uploadJsonWellDCA = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_DCA,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const updateJsonWellDCA = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;

	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_DCA,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the information of the well");
	}
};

export const deleteJsonWellDCA = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_DCA,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting the information of the well");
	}
};

export const getJsonWellDCA = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;
	try {
		const response = await fetch(
			`${DB_URL_DCA}/parameters/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_DCA,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getJsonADDWellDCA = async (operator, lease, region, userName) => {
	if (!validateParams(operator, lease, region, userName)) return;
	try {
		const response = await fetch(
			`${DB_URL_DCA}/parameterslist/${operator}/${lease}/${region}/${userName}/parametersdca`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_DCA,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const uploadDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/upload-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify({
					data,
				}),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the data history");
	}
};

export const updateDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/update-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}/parametersdca`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem updating the information of the data history");
	}
};

export const deleteDataHistory = async (dataJson) => {
	const { operator, lease, region, county, field, wellid, userName, data } =
		dataJson;
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;

	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/delete-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "DELETE",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem deleting the information of the well");
	}
};

export const getDataHistory = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, userName))
		return;
	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/get-data/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the data history");
	}
};

export const getListDataHistory = async (operator, lease, region, userName) => {
	if (!validateParams(operator, lease, region, userName)) return;
	try {
		const response = await fetch(
			`${DB_DATA_HISTORY}/list-wells/${operator}/${lease}/${region}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_HISTORIC,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const uploadJsonVolumeTracker = async (dataJson) => {
	const { operator, lease, region, county, field, userName, data } = dataJson;
	if (!validateParams(operator, lease, region, county, field, userName)) return;

	try {
		const response = await fetch(
			`${DB_URL_VOLUMETRACKER}/upload_allocation/${operator}/${lease}/${region}/${county}/${field}/${userName}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getDataAllocation = async (operator, lease, region, county, field, wellid ,userName) => {
	if (!validateParams(operator, lease, region, county, field, wellid, userName)) return;

	try {
		const response = await fetch(
			`${DB_URL_VOLUMETRACKER}/get_allocation_dca/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem uploading volume tracker data");
	}
};

export const getJsonVolumeTracker = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, userName)) return;
	try {
		const response = await fetch(
			`${DB_URL_VOLUMETRACKER}/average_dca_realtime/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getCumulative_allData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, userName)) return;
	try {
		const response = await fetch(
			`${DB_URL_VOLUMETRACKER}/acumulative_all_month/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getCumulative_lastData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	userName,
) => {
	if (!validateParams(operator, lease, region, county, field, userName)) return;
	try {
		const response = await fetch(
			`${DB_URL_VOLUMETRACKER}/acumulative_last_month/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${userName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_VOLUMETRACKER,
				},
			},
		);
		return response.json();
	} catch (error) {
		console.error("Problem getting the well");
	}
};

export const getChartDCAProductionData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, user))
		return;
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/chart_prod_hist/500/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getChartDCAPerformanceData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
	wellName,
) => {
	if (
		!validateParams(
			operator,
			lease,
			region,
			county,
			field,
			wellid,
			user,
			wellName,
		)
	)
		return;
	const encodedWellName = encodeURIComponent(wellName); 
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/chart_performance/500/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}?well_name=${encodedWellName}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getChart_ai_dcaData = async (
	operator,
	lease,
	region,
	county,
	field,
	wellid,
	user,
) => {
	if (!validateParams(operator, lease, region, county, field, wellid, user))
		return;
	try {
		const response = await fetch(
			`${BASE_URL_IOT}/chart_ai_dca/500/${operator}/${lease}/${region}/${county}/${field}/${wellid}/${user}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_IOT,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};
