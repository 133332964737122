import { CaretDownOutlined, CaretLeftOutlined, DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Checkbox, Menu, Modal, message, Switch, Tooltip, Select, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExpandIcon1 } from "../../assets/icon/expand1.svg";
import { ReactComponent as IconAdd } from "../../assets/img/icons/add.svg";
import { ReactComponent as IconDelete } from "../../assets/img/icons/delete.svg";
import { AlertModal } from "../../components/AlertModal";
import { ImportFile } from "../../components/GasLift/Complements/ImportFile";
import ChartVLP from "../../components/GasLift/GraphicsTuning/ChartVLP";
import { Navbar } from "../../components/layout/Navbar";
import { Sidebar } from "../../components/layout/Sidebar";
import { Loading } from "../../components/Loading";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabTuning } from "../../hooks/useTabTuning";
import { useUsers } from "../../hooks/useUsers";
import { useTabIOT } from "../../hooks/useTabIOT";
import { STATUS_DATA_GAS_LIFT, STATUS_DATA_CHART_VLP } from "../../components/elements/types/type_response_API";
import { InfoCircleOutlined } from "@ant-design/icons";
import { TYPE_LINE_TUNNING_GRAPH } from "../../components/elements/types/type_gas_lift";
import { NAME_PAGES } from "../../components/elements/types/type_pages";
import { ReactComponent as SaveNavbar } from '../../assets/img/icons/save.svg';
import { TUNNING_GAS_LIFT_PARAMETERS_TYPE, INITIAL_DATA_SCENARIO_CHART, menuItems, DATA_VLP } from '../../components/elements/types/type_tunningGasLift';
const { WELL_HEAD_PARAMETERS, GAS_LIFT_PARAMETERS, PRODUCTION_PARAMETERS } = TUNNING_GAS_LIFT_PARAMETERS_TYPE;

export const TuningGasLift = () => {
  const { confirm } = Modal;
  const { Option } = Select;
  const navigate = useNavigate();
  const { User } = useUsers();
  const {
    queryCalculateTuningVLP,
    queryCalculateUntunedVLP,
    dataFilterUserGasLift,
    mutationCreateDataTuning,
    mutationUpdateDataTuning,
    queryDataTuning,
    loadDataTuning,
    queryPressDrowDown,
    refetchTuning,
    refetchCalculateTuningVLP,
    refetchCalculateUntunedVLP,
    refetchPressDrowDown,
    mutationCreateCalTuningVLP,
    mutationUpdateCalTuningVLP,
    maxDepth,
    minDepth,
    maxPressure,
    minPressure,
    calculateRangesGraphVLP,
    enableCalculatedMse,
    setEnableCalculatedMse,
    queryDataChartVLP,
    refetchQueryDataChartVLP,
    queryListChartVLP,
    refetchQueryListChartVLP,
    mutationDataChartVLP,
    setOptionSelectedScenario,
    mutationDeleteScenerioChartVLP
  } = useTabTuning();
  const {
    dataListDevicesGaslift,
    refetchDataListDevicesGaslift,
  } = useTabIOT();
  const {
    userDataInfo,
    selectedWell,
    dataIOTAllPlatforms,
    dataWellGasLift,
    mutationUpdateDataGasLift,
    refetchDataIOTAllPlatforms,
    refetchDataWellGasLift,
  } = useTabGaslift();

  const { getDataPage, deviceRealTime, getDeviceRealTime } = useContext(DashboardContext);
  const [wellboreOptionState, setWellboreOptionState] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0");
  const [inputDataTuning, setInputDataTuning] = useState(DATA_VLP.initialData);
  const [dataTableTubing, setDataTableTubing] = useState(DATA_VLP.initialDataTubing);
  const [dataTableGauge, setDataTableGauge] = useState(DATA_VLP.initialDataGauge);
  const [dataTableTestVLP, setDataTableTestVLP] = useState(DATA_VLP.initialDataTestVLP);
  const [inputEmpty, setInputEmpty] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSave, setOpenSave] = useState(false);

  const inputGetDataTuning = queryDataTuning && selectedWell ? queryDataTuning.data : null;
  const inputGetDataWell = dataWellGasLift && selectedWell ? dataWellGasLift?.data : null;

  const [activeChecks, setActiveChecks] = useState(inputGetDataTuning != null ? inputGetDataTuning?.active_checks : []);
  const [hasChanges, setHasChanges] = useState(false);

  const [enableInputGOR, setEnableInputGOR] = useState(false);
  const [enableInputGasRate, setEnableInputGasRate] = useState(false);
  const [dataTableGraphVLP, setDataTableGraphVLP] = useState();
  const [dataTableGraphUntVLP, setDataTableGraphUntVLP] = useState();
  
  const [inputVolumeTrackerPH, setInputVolumeTrackerPH] = useState(DATA_VLP.initialDataAverage);
  const [inputVolumeTrackerWIC, setInputVolumeTrackerWIC] = useState(DATA_VLP.initialDataAverage);
  const [inputVolumeTrackerWP, setInputVolumeTrackerWP] = useState(DATA_VLP.initialDataAverage);
  const [inputVolumeTrackerRT, setInputVolumeTrackerRT] = useState(DATA_VLP.initialDataAverageRT);
  
  const [selectOptionScenario, setSelectOptionScenario] = useState([]);
  const [inputScenarioInformation, setInputScenarioInformation] = useState(DATA_VLP.initial_data_scenario_chart);
  const [dataScenarioVLP, setDataScenarioVLP] = useState([]);
  const [optionOpen, setOptionOpen] = useState(false);
  const [dataOptionScenario, setDataOptionScenario] = useState([]);
  const [emptyTuningData, setEmptyTuningData] = useState(true);
    
  useEffect(() => {
    getDataPage("AddWellGasLift");
    refetchTuning();
    refetchDataWellGasLift();
    refetchDataListDevicesGaslift();
    refetchQueryListChartVLP();
    refetchDataIOTAllPlatforms();
    if(!emptyTuningData){
      refetchCalculateTuningVLP();
      refetchCalculateUntunedVLP();
    }
  }, []);

  useEffect(() => {
    if(inputGetDataTuning !== null){
      if(    
        inputGetDataTuning?.active_checks?.length != 0 &&
        inputGetDataTuning?.mid_perf_tvd_depth != "" &&
        inputGetDataTuning?.reservoir_temperature != "" &&
        inputGetDataTuning?.reservoir_pressure != "" &&
        inputGetDataTuning?.paramter1_vlp != "" &&
        inputGetDataTuning?.paramter2_vlp != "" &&
        inputGetDataTuning?.number_of_intervals != "" &&
        inputGetDataTuning?.depth_tvd != "" &&
        inputGetDataTuning?.measured_depth != ""
      ){
        setEmptyTuningData(false);
      }
      else{
        setEmptyTuningData(true);
      }
    }
  }, [queryDataTuning]);

  useEffect(() => {
    if (!!dataFilterUserGasLift &&  dataWellGasLift?.statusCode === STATUS_DATA_GAS_LIFT.SUCCESS) {

      const { selected_platform_graph, selected_device_graph } = dataWellGasLift?.data || {};

      setInputVolumeTrackerPH((prevState) => ({
        ...prevState,
        separator_type: selected_device_graph?.chartProdHistory,
      }));
      setInputVolumeTrackerWIC((prevState) => ({
        ...prevState,
        separator_type: selected_device_graph?.chartWellCond,
      }));
      setInputVolumeTrackerWP((prevState) => ({
        ...prevState,
        separator_type: selected_device_graph?.chartHole,
      }));
      setInputVolumeTrackerRT(selected_platform_graph);

      getDeviceRealTime({
        ...deviceRealTime,
        device_gaslift: {
          ...deviceRealTime.device_gaslift,
          selected_platform_graph: {
            chartHole: selected_platform_graph?.chartHole,
            chartWellCond: selected_platform_graph?.chartWellCond,
            chartProdHistory: selected_platform_graph?.chartProdHistory,
          },
          selected_device_graph: {
            chartHole: selected_device_graph?.chartHole,
            chartWellCond: selected_device_graph?.chartWellCond,
            chartProdHistory: selected_device_graph?.chartProdHistory,
          },
        },
      });
      // Este codigo se utiliza para traer la tabla de well equipment del pozo y setearla en tubing   
      const filterDataWellEquipment = dataWellGasLift.data.dataEquipment;
      const arrayModify = filterDataWellEquipment.map((item) => ({
        id: item.id,
        nameTubing: item.name,
        measured_depth: Number(item.measured_depth),
        internal_diameter: Number(item.internal_diameter),
        absolute_roughness: Number(item.absolute_roughness),
      }));
      setDataTableTubing(arrayModify);

      // setTimeout(() => {
      //   refetchTuning();
      //   refetchCalculateTuningVLP();
      //   refetchCalculateUntunedVLP();
      //   refetchPressDrowDown();
      //   refetchDataIOTAllPlatforms();
      // }, 400);
    }
  }, [dataWellGasLift]);
  useEffect(() => {
    if (queryCalculateUntunedVLP) {
      if (queryCalculateUntunedVLP?.statusType === "SUCCESS") {
        setTimeout(() => {
          if (enableCalculatedMse ) {
            const searchValuePressure = queryCalculateTuningVLP?.data
              ? queryCalculateTuningVLP?.data[1]?.calc_press_tuned[0]
              : null;
            const ultimateValuePressure = !!searchValuePressure
              ? searchValuePressure?.toFixed(2)
              : "";
            const mseCalculated =
              queryCalculateUntunedVLP?.statusType === "SUCCESS"
                ? validateDataRMS()
                : null;
            setInputDataTuning((prevState) => ({
              ...prevState,
              calculated_pressure: ultimateValuePressure,
              mse_vlp: mseCalculated,
            }));
            setEnableCalculatedMse(false);
          }
        }, 1000);
      }
    }
  }, [enableCalculatedMse,queryCalculateUntunedVLP]);

  useEffect(() => {
    if (queryPressDrowDown) {
      if (queryPressDrowDown?.statusType === "SUCCESS") {
        setInputDataTuning((prevState) => ({
          ...prevState,
          calculatedPressRD: queryPressDrowDown?.data?.pwf_tuned,
          calculatedDrawdown: queryPressDrowDown?.data?.p_drowd,
        }));
      }
    }
  }, [queryPressDrowDown]);
  /*
   Función para traer datos de Production Parameters en tiempo real.
   Este método se conecta a la API de datos en tiempo real,
   procesa la respuesta y la guarda en el estado de la aplicación.
 */
  useEffect(() => {
    if (
      inputDataTuning?.ppCheck &&
      inputVolumeTrackerPH.separator_type &&
      dataIOTAllPlatforms?.requestData
    ) {
      const dataFiltradaPP = [];
      const plataformaPP = inputVolumeTrackerRT?.chartProdHistory; // Plataforma para Product parameters
      const requestData = dataIOTAllPlatforms?.requestData;

      // Verifica si requestData es un objeto con los arrays esperados
      if (
        requestData &&
        Array.isArray(requestData["SMI-27"]) &&
        Array.isArray(requestData["V-191"])
      ) {
        const platformKeys = Object.keys(requestData); // Saca las llaves del array para poder filtrar por dispositivo
        platformKeys?.forEach((key) => {
          const platformData = requestData[key]; // Guarda la data del array dependiendo de la key ["SMI-27"] ó ["V-191"]

          if (plataformaPP === key) {
            // compara la plataforma segun la opcion del select con la key evaluada

            platformData?.forEach((entry) => {
              if (
                key === "SMI-27" &&
                (entry.LSD === "SMI-27 LP Separator" ||
                  entry.LSD === "SMI-27 Fuel Gas Meter" ||
                  entry.LSD === "SMI-27 IP Separator" ||
                  entry.LSD === "SMI-27 Sales Gas Meter" ||
                  entry.LSD === "SMI-27 LACT")
              ) {
                dataFiltradaPP.push(entry); // Data filtrada por tipo de parametro y dispositivo ["SMI-27"]
              } else if (
                key === "V-191" &&
                (entry.LSD === "V-191 Test Separator" ||
                  entry.LSD === "V-191 Well Head Pressure")
              ) {
                dataFiltradaPP.push(entry); // Data filtrada por tipo de parametro y dispositivo ["V-191"]
              }
            });
          }
        });
      } else {
        console.error( "El formato de requestData no es el esperado",  requestData );
      }

      const dataIOT = dataFiltradaPP?.filter((item) => {
        return item["LSD"] === inputVolumeTrackerPH?.separator_type;
      });

      const lastDataIOT = !!dataIOT ? [dataIOT[dataIOT?.length - 1]] : null;

      if (
        inputDataTuning?.ppCheck &&
        lastDataIOT &&
        lastDataIOT.length > 0 &&
        lastDataIOT[0] != undefined
      ) {
        const [dateIOT] = lastDataIOT[0]["Date (MM/DD/YYYY)"].split(" ");

        const formatDate = (dateStr) => {
          const [month, day, year] = dateStr.split("/");
          return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        };

        const valFcat = dataWellGasLift?.data?.["volumeTracker"]?.["fcat"] / 100 ?? 1;
        const valWaterCut = dataWellGasLift?.data?.["volumeTracker"]?.["water_cut"] ?? 0;

        const calculateOilRate = ((lastDataIOT[0]["Liquid Flow Rate"] * valFcat * (100 - valWaterCut)) / 100 ).toFixed(1);
        const formattedOilRate = calculateOilRate === "0.0" ? "0" : calculateOilRate;
        const calculateGasRate = (  lastDataIOT[0]["Corrected Flow Rate"] * valFcat ).toFixed(3);

        setInputDataTuning((prevState) => ({
          ...prevState,
          test_date_pp: formatDate(dateIOT),
          oil_rate: formattedOilRate,
          gas_rate: calculateGasRate,
          water_cut: dataWellGasLift?.data?.["volumeTracker"]?.["water_cut"],
        }));
      }
    } else if (!inputDataTuning?.ppCheck && !emptyTuningData) {
      setInputDataTuning((prevState) => ({
        ...prevState,
        test_date_pp: inputGetDataTuning?.test_date_pp,
        oil_rate: inputGetDataTuning?.oil_rate,
        gas_rate: inputGetDataTuning?.gas_rate,
        water_cut: inputGetDataTuning?.water_cut,
      }));
    }
  }, [ inputDataTuning?.ppCheck,  inputVolumeTrackerPH?.separator_type, dataIOTAllPlatforms ]);

  /*
 Función para traer datos de Gas Lift Parameters en tiempo real.
 Este método se conecta a la API de datos en tiempo real,
 procesa la respuesta y la guarda en el estado de la aplicación.
*/
  useEffect(() => {
    if (inputDataTuning?.glpCheck &&
      inputVolumeTrackerWIC.separator_type &&
      dataIOTAllPlatforms?.requestData) {
      const dataFiltradaGlp = [];
      const plataformaGlp = inputVolumeTrackerRT?.chartWellCond; // Plataforma segun el select ojooo
      const requestData = dataIOTAllPlatforms?.requestData;

      if (
        requestData &&
        Array.isArray(requestData["SMI-27"]) &&
        Array.isArray(requestData["V-191"])
      ) {
        // Verifica si requestData es un objeto con los arrays esperados

        const platformKeys = Object.keys(requestData); // Saca las llaves del array para poder filtrar por dispositivo
        platformKeys.forEach((key) => {
          const platformData = requestData[key]; // Guarda la data del array dependiendo de la key ["SMI-27"] ó ["V-191"]
          if (plataformaGlp === key) {
            // compara la plataforma segun la opcion del select con la key evaluada
            platformData.forEach((entry) => {
              if (
                key === "SMI-27" &&
                (entry.LSD === "SMI-27 LP Separator" ||
                  entry.LSD === "SMI-27 Fuel Gas Meter" ||
                  entry.LSD === "SMI-27 IP Separator" ||
                  entry.LSD === "SMI-27 Sales Gas Meter" ||
                  entry.LSD === "SMI-27 LACT")
              ) {
                dataFiltradaGlp.push(entry); // Data filtrada por tipo de parametro y dispositivo ["SMI-27"]
              } else if (
                key === "V-191" &&
                (entry.LSD === "V-191 Test Separator" ||
                  entry.LSD === "V-191 Well Head Pressure")
              ) {
                dataFiltradaGlp.push(entry); // Data filtrada por tipo de parametro y dispositivo ["V-191"]
              }
            });
          }
        });
      } else {
        console.error( "El formato de requestData no es el esperado", requestData );
      }

      const dataIOT = dataFiltradaGlp?.filter((item) => {
        return item["LSD"] === inputVolumeTrackerWIC?.separator_type; // Filtra los dispositivos segun el select SMI-27 IP Separator
      });

      const lastDataIOT = !!dataIOT ? [dataIOT[dataIOT?.length - 1]] : null; // Deja el ultimo registro del dispositivo segun el select

      if (
        lastDataIOT &&
        lastDataIOT.length > 0 &&
        lastDataIOT[0] != undefined
      ) {
        const [dateIOT] = lastDataIOT[0]["Date (MM/DD/YYYY)"].split(" ");
        const filterDataCreate = dataWellGasLift?.data; // !ojo son datos de prueba, cambiar
        const formatDate = (dateStr) => {
          const [month, day, year] = dateStr.split("/");
          return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        };

        setInputDataTuning((prevState) => ({
          ...prevState,
          test_date_glp: formatDate(dateIOT),
          injection_gas_rate:
            lastDataIOT[0][
            deviceRealTime?.device_gaslift?.discretized
              ? filterDataCreate.well_name + " Well Head Pressure"
              : "Static Pressure"
            ], // !ojo son datos de prueba, cambiar
          injection_pressure: lastDataIOT[0]["Flowing Temperature"], //!ojo son datos de prueba, cambiar
        }));
      }
    } else if (!inputDataTuning?.glpCheck && !emptyTuningData) {
      setInputDataTuning((prevState) => ({
        ...prevState,
        test_date_glp: inputGetDataTuning?.test_date_glp,
        injection_gas_rate: inputGetDataTuning?.injection_gas_rate,
        injection_pressure: inputGetDataTuning?.injection_pressure,
      }));
    }
  }, [  inputDataTuning?.glpCheck,  inputVolumeTrackerWIC?.separator_type, dataIOTAllPlatforms]);

  useEffect(() => {
    if (inputDataTuning?.whpCheck &&
      inputVolumeTrackerWP.separator_type &&
      dataIOTAllPlatforms?.requestData) {
      const dataFiltradaWhp = [];
      const plataformaWhp = inputVolumeTrackerRT?.chartHole; // Plataforma segun el select
      const requestData = dataIOTAllPlatforms?.requestData;

      if (
        requestData &&
        Array.isArray(requestData["SMI-27"]) &&
        Array.isArray(requestData["V-191"])
      ) { // Verifica si requestData es un objeto con los arrays esperados

        const platformKeys = Object.keys(requestData); // Saca las llaves del array para poder filtrar por dispositivo
        platformKeys.forEach((key) => {
          const platformData = requestData[key]; // Guarda la data del array dependiendo de la key ["SMI-27"] ó ["V-191"]
          if (plataformaWhp === key) {
            // compara la plataforma segun la opcion del select con la key evaluada
            platformData.forEach((entry) => {
              if (
                key === "SMI-27" &&
                (entry.LSD === "SMI-27 LP Separator" ||
                  entry.LSD === "SMI-27 Fuel Gas Meter" ||
                  entry.LSD === "SMI-27 IP Separator" ||
                  entry.LSD === "SMI-27 Sales Gas Meter" ||
                  entry.LSD === "SMI-27 LACT")
              ) {
                dataFiltradaWhp.push(entry); // Data filtrada por tipo de parametro y dispositivo ["SMI-27"]
              }  
              if (
                key === "V-191" &&
                (entry.LSD === "V-191 Test Separator")
              ) {
                dataFiltradaWhp.push(entry); // Data filtrada por tipo de parametro y dispositivo ["V-191"]
              }
            });
          }
        });
      } else {
        console.error( "Error: El formato de requestData no es el esperado", requestData    );
      }

      const dataIOT = dataFiltradaWhp?.filter((item) => {
        if(inputVolumeTrackerWP?.separator_type.includes("Well Head Pressure")){
          return true;
        }
        else{
          return item["LSD"] === inputVolumeTrackerWP?.separator_type ; // Filtra los dispositivos segun el select SMI-27 IP Separator
        }
      });

      const lastDataIOT = !!dataIOT ? [dataIOT[dataIOT?.length - 1]] : null; // Deja el ultimo registro del dispositivo segun el select
      if (
        lastDataIOT &&
        lastDataIOT.length > 0 &&
        lastDataIOT[0] != undefined &&
        inputDataTuning?.whpCheck === true // Evalua que exista un ultimo registro del dispositvo y que este activo el btn de realTime
      ) {
        const filterDataCreate = dataWellGasLift?.data; // !Preguntar porque el nombre del pozo no siembre es c6 o c9 para wellhead_pressure
        const [dateIOT] = lastDataIOT[0]["Date (MM/DD/YYYY)"].split(" ");
        const formatDate = (dateStr) => {
          const [month, day, year] = dateStr.split("/");
          return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        };

        setInputDataTuning((prevState) => ({
          ...prevState,
          test_date_whp: formatDate(dateIOT),
          wellhead_temperature: lastDataIOT[0]["Flowing Temperature"],
          wellhead_pressure: inputVolumeTrackerWP?.separator_type?.includes("Well Head Pressure") && filterDataCreate?.discretized_well ? lastDataIOT[0][filterDataCreate.well_name + " Well Head Pressure"]: lastDataIOT[0]["Static Pressure"]
        }));
      }
    } else if (!inputDataTuning?.whpCheck && !emptyTuningData ) {
      setInputDataTuning((prevState) => ({
        ...prevState,
        test_date_whp: inputGetDataTuning?.test_date_whp,
        wellhead_temperature: inputGetDataTuning?.wellhead_temperature,
        wellhead_pressure: inputGetDataTuning?.wellhead_pressure,
      }));
    }
  }, [ inputDataTuning?.whpCheck,  inputVolumeTrackerWP?.separator_type, dataIOTAllPlatforms ]);

  useEffect(() => {
    if (emptyTuningData) {
      setInputDataTuning(DATA_VLP.initialData);
      if (dataWellGasLift) {
        if (dataWellGasLift.statusCode === 200075) {
          const filterDataCreate = dataWellGasLift?.data;
          setInputDataTuning((prevState) => ({
            ...prevState,
            mid_perf_tvd_depth: Number(filterDataCreate?.md_perf_depth),
            reservoir_pressure: Number(filterDataCreate?.layer_pressure),
            reservoir_temperature: Number(filterDataCreate?.layer_temperature),
            water_cut: Number(filterDataCreate?.water_cut_fraction),
            number_of_intervals: 20,
          }));
        }
      }
      setActiveChecks([]);
    } else if (!emptyTuningData) {
      setInputDataTuning({
        ...inputDataTuning,
        mid_perf_tvd_depth: inputGetDataTuning?.mid_perf_tvd_depth,
        reservoir_temperature: inputGetDataTuning?.reservoir_temperature,
        reservoir_pressure: inputGetDataTuning?.reservoir_pressure,
        // mid_perf_tvd_depth: filterDataCreate?.changeData?.md_perf_depth ? Number(filterDataCreate?.md_perf_depth) :  inputGetDataTuning?.mid_perf_tvd_depth,
        // reservoir_temperature: filterDataCreate?.changeData?.layer_temperature ? Number(filterDataCreate?.layer_temperature) : inputGetDataTuning?.reservoir_temperature,
        // reservoir_pressure: filterDataCreate?.changeData?.layer_pressure ? Number(filterDataCreate?.layer_pressure) : inputGetDataTuning?.reservoir_pressure,
        test_date_pp: inputGetDataTuning?.test_date_pp,
        oil_rate: inputGetDataTuning?.oil_rate,
        gas_rate: inputGetDataTuning?.gas_rate,
        gor_pp: inputGetDataTuning?.gor_pp,
        water_cut: inputGetDataTuning?.water_cut,
        // water_cut: filterDataCreate?.changeData?.water_cut_fraction ? Number(filterDataCreate?.water_cut_fraction) : inputGetDataTuning?.water_cut,
        test_date_pp: inputGetDataTuning?.test_date_pp,
        test_date_glp: inputGetDataTuning?.test_date_glp,
        injection_gas_rate: inputGetDataTuning?.injection_gas_rate,
        injection_pressure: inputGetDataTuning?.injection_pressure,
        test_date_whp: inputGetDataTuning?.test_date_whp,
        wellhead_temperature: inputGetDataTuning?.wellhead_temperature,
        wellhead_pressure: inputGetDataTuning?.wellhead_pressure,
        gas_lift_valve_depth_1: inputGetDataTuning?.gas_lift_valve_depth_1,
        gas_lift_valve_depth_2: inputGetDataTuning?.gas_lift_valve_depth_2,
        gas_lift_valve_depth_3: inputGetDataTuning?.gas_lift_valve_depth_3,
        gas_injection_distribution_valve_1: inputGetDataTuning?.gas_injection_distribution_valve_1,
        gas_injection_distribution_valve_2: inputGetDataTuning?.gas_injection_distribution_valve_2,
        gas_injection_distribution_valve_3: inputGetDataTuning?.gas_injection_distribution_valve_3,
        active_checks: inputGetDataTuning?.active_checks,
        paramter1_vlp: inputGetDataTuning?.paramter1_vlp,
        paramter2_vlp: inputGetDataTuning?.paramter2_vlp,
        number_of_intervals: inputGetDataTuning?.number_of_intervals,
        calculated_pressure: inputGetDataTuning?.calculated_pressure,
        mse_vlp: inputGetDataTuning?.mse_vlp,
        whpCheck: inputGetDataTuning?.whpCheck,
        glpCheck: inputGetDataTuning?.glpCheck,
        ppCheck: inputGetDataTuning?.ppCheck,
        drawdownPres: inputGetDataTuning?.drawdownPres,
        enable_gauge_parameters: inputGetDataTuning?.enable_gauge_parameters,
      });

      setEnableInputGOR(!!inputGetDataTuning?.gas_rate ? true : false);
      setDataTableTubing(inputGetDataTuning?.tubing_data);
      setDataTableGauge(inputGetDataTuning?.gauge_data);
      setDataTableTestVLP(inputGetDataTuning?.test_data);
      setActiveChecks(inputGetDataTuning?.active_checks);
      setTimeout(() => {
        refetchPressDrowDown();
      }, 1000);
    } 
  }, [inputGetDataTuning, dataWellGasLift, emptyTuningData]);

  useEffect(() => {
    if (
      (queryCalculateTuningVLP &&
        queryCalculateTuningVLP.statusCode === 200044) ||
      (queryCalculateUntunedVLP &&
        queryCalculateUntunedVLP.statusCode === 200044)
    ) {
      const getDataCalculatedVLP =
        queryCalculateTuningVLP?.data != null
          ? queryCalculateTuningVLP?.data[0]
          : [];
      const getDataCalculatedUntVLP =
        queryCalculateUntunedVLP?.data !== null
          ? queryCalculateUntunedVLP?.data[0]
          : [];
      setDataTableGraphVLP(getDataCalculatedVLP);
      setDataTableGraphUntVLP(getDataCalculatedUntVLP);

      const pressureTuned = getDataCalculatedVLP?.pressure || [];
      const depthTuned = getDataCalculatedVLP?.depth_md || [];
      const pressureUntuned = getDataCalculatedUntVLP?.pressure || [];
      const depthUntuned = getDataCalculatedUntVLP?.depth_md || [];

      const pressureArrayTuned = [
        ...pressureTuned,
        inputDataTuning?.reservoir_pressure + 200,
      ];
      const depthArrayTuned = [
        ...depthTuned,
        inputDataTuning?.mid_perf_tvd_depth + 200,
      ];
      const pressureArrayUntuned = [
        ...pressureUntuned,
        inputDataTuning?.reservoir_pressure + 200,
      ];
      const depthArrayUntuned = [
        ...depthUntuned,
        inputDataTuning?.mid_perf_tvd_depth + 200,
      ];
      calculateRangesGraphVLP(
        pressureArrayTuned,
        depthArrayTuned,
        pressureArrayUntuned,
        depthArrayUntuned
      );
    }
  }, [ queryCalculateTuningVLP, queryCalculateUntunedVLP, inputDataTuning?.mid_perf_tvd_depth, inputDataTuning?.reservoir_pressure ]);

  useEffect(() => {
    if (hasChanges) {
      const customEventChecks = {
        target: {
          name: "active_checks",
          value: [...activeChecks],
        },
      };
      handleChangeInputData(customEventChecks);
      setHasChanges(false);
    }
  }, [hasChanges, activeChecks]);

  useEffect(() => {
    setTimeout(() => {
      if (queryListChartVLP) {
        if (queryListChartVLP?.data && queryListChartVLP.statusCode === STATUS_DATA_CHART_VLP.SUCCESS) {
          const dataListChartQuery = queryListChartVLP?.data;
          setDataOptionScenario(dataListChartQuery);
        } else {
          setDataOptionScenario([]);
        }
      }
    }, 100);
  }, [queryListChartVLP]);

  useEffect(() => {
    if (queryDataChartVLP) {
      if (Object.keys(queryDataChartVLP?.data).length && queryDataChartVLP.statusCode === STATUS_DATA_CHART_VLP.SUCCESS) {
        const dataSpecificScenario = queryDataChartVLP?.data[0];

        setDataScenarioVLP((prevState) => ({
          ...prevState,
          [dataSpecificScenario.name]: dataSpecificScenario,
        }));
      }
    }
  }, [queryDataChartVLP]);

  useEffect(() => {
    if (loadDataTuning) {
    }
  }, [loadDataTuning]);

  const handleCLickExpand = () => {
    setOpen(true);
  };

  const handleCLickSaveChart = () => {
    const validateDataGraph = Object.keys(dataTableGraphVLP?.depth_md).length && Object.keys(dataTableGraphUntVLP?.depth_md).length && Object.keys(dataTableGraphVLP?.pressure).length && Object.keys(dataTableGraphUntVLP?.pressure).length;
    if (!validateDataGraph) {
      message.error("No se puede guardar el escenario porque no hay datos que graficar");
    } else {
      setOpenSave(true);
    }
  };

  function VerifyingNulls(arreglo) {
    let validateValuesNull = false;
    arreglo?.forEach((objeto) => {
      Object.values(objeto)?.forEach((valor) => {
        if (valor === null || valor === "") {
          validateValuesNull = true;
        }
      });
    });
    return validateValuesNull;
  }

  function validateDataRMS() {
    if (inputDataTuning?.enable_gauge_parameters) {
      // Verificar si los arreglos tienen la misma longitud
      const dataUntunedVLP =
        queryCalculateTuningVLP?.data !== null
          ? queryCalculateTuningVLP?.data[1].calc_press_tuned
          : [];
      const pressureReal = dataTableGauge?.map((item) => item.gauge_pressure) || [];
      const PressureCalculated = dataUntunedVLP || [];
      const realEmpty = pressureReal?.every((elemento) => elemento !== "") || null;
      const realCalculated = PressureCalculated?.every( (elemento) => elemento !== "") || null;

      if (
        pressureReal.length !== PressureCalculated.length ||
        !!realEmpty ||
        !!realCalculated
      ) {
        message.error("The error cannot be calculated because data is missing");
        return "";
      }

      // Calcular la suma de los cuadrados de las diferencias
      /* const sumSquaredDifferences = pressureReal?.reduce((acc, realValue, index) => {
        const predictedValue = PressureCalculated[index];
        const difference = realValue - predictedValue;
        return acc + Math.pow(difference, 2); 
        }, 0)*/
      const sumSquaredDifferences = pressureReal?.reduce(
        (acc, realValue, index) => {
          const predictedValue = PressureCalculated[index];
          const difference = realValue - predictedValue;
          return acc + Math.pow(Math.pow(difference, 2), 0.5);
        },
        0
      );

      // Calcular el promedio de los cuadrados de las diferencias
      // const mse = (sumSquaredDifferences / pressureReal.length)?.toFixed(2);
      const STD_DEV = sumSquaredDifferences ? (sumSquaredDifferences)?.toFixed(2) : null;

      return STD_DEV;
    } else {
      return "";
    }
  }

  const wellboreOption = () => {
    setWellboreOptionState(!wellboreOptionState);
  };

  const handleNav = (value) => {
    setSelectedValue(value.key);
  };

  const handleAddTubing = () => {
    const uniqueIds = [...new Set(dataTableTubing.map((obj) => obj.id))];
    if (uniqueIds.length < 3) {
      const maxId = Math.max(...uniqueIds);
      const newTubingData = {
        id: maxId + 1,
        nameTubing: "",
        measured_depth: null,
        internal_diameter: null,
        absolute_roughness: null,
      };
      setDataTableTubing([...dataTableTubing, newTubingData]);
      setHasChanges(true);
    } else {
      message.info("Maximum 3 part of tubing are allowed");
    }
  };

  const handleDeleteTubing = (id) => {
    const newData = dataTableTubing.filter((item) => item.id !== id);
    setDataTableTubing(newData);
    setHasChanges(true);
  };

  const handleAddGauge = () => {
    const uniqueIds = [...new Set(dataTableGauge.map((obj) => obj.id))];
    const maxId = Math.max(...uniqueIds);
    const newGaugeData = {
      id: maxId + 1,
      test_date: null,
      gauge_tvd: null,
      gauge_pressure: null,
      gauge_temperature: null,
    };
    const newGaugeData2 = {
      id: maxId + 1,
      depth_tvd: null,
      measured_pressure_tvd: null,
      oil_rate_tvd: inputDataTuning.oil_rate,
    };
    setDataTableGauge([...dataTableGauge, newGaugeData]);
    setDataTableTestVLP([...dataTableTestVLP, newGaugeData2]);
    setHasChanges(true);
  };

  const handleDeleteGauge = (id) => {
    const newData = dataTableGauge.filter((item) => item.id !== id);
    setDataTableGauge(newData);
    const newData2 = dataTableTestVLP.filter((item) => item.id !== id);
    setDataTableTestVLP(newData2);
    setHasChanges(true);
  };

  const handleUploadFileTubing = (dataFile) => {
    const uniqueIds = [...new Set(dataTableTubing.map((obj) => obj.id))];
    const maxId = Math.max(...uniqueIds) + 1;
    const dataFileEnd = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });
    setDataTableTubing([...dataTableTubing, ...dataFileEnd]);
    setHasChanges(true);
  };

  const handleSwitch = (nameSwitch, checked) => {
    const customEvent = {
      target: {
        name: nameSwitch,
        value: checked,
      },
    };
    handleChangeInputData(customEvent);
  };

  const handleUploadFileGauge = (dataFile) => {
    const uniqueIds = [...new Set(dataTableGauge.map((obj) => obj.id))];
    const maxId = Math.max(...uniqueIds) + 1;
    const dataFilePre = dataFile.map((obj, index) => {
      return { id: index + maxId, ...obj };
    });
    const dataFileEnd = dataFilePre.map((item) => ({
      ...item,
      ["test_date"]: convertDate(item.test_date),
    }));

    const dataFileEndTest = dataFileEnd.map((item) => {
      return {
        id: item.id,
        depth_tvd: item.gauge_tvd,
        measured_pressure_tvd: item.gauge_pressure,
        oil_rate_tvd: inputDataTuning.oil_rate,
      };
    });

    setDataTableGauge([...dataTableGauge, ...dataFileEnd]);
    setDataTableTestVLP([...dataTableTestVLP, ...dataFileEndTest]);
    setHasChanges(true);
  };

  const handleChangeTubing = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name !== "nameTubing") {
      dataValue = Number(value);
    }
    if (value === "") {
      dataValue = "";
      setDataTableTubing((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    } else {
      if (name === "measured_depth" && (dataValue < 0 || dataValue > 60000)) {
        message.error("The value cannot be less than 0 or more than 60000");
      } else if (
        name === "internal_diameter" &&
        (dataValue < 0 || dataValue > 24)
      ) {
        message.error("The value cannot be less than 0 or more than 24");
      } else if (
        name === "absolute_roughness" &&
        (dataValue < 0 || dataValue > 0.01)
      ) {
        message.error("The value cannot be less than 0 or more than 0.01");
      } else {
        setDataTableTubing((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: dataValue } : item
          )
        );
      }
    }
    setHasChanges(true);
  };

  const handleChangeBlur = (e, id) => {
    const { name, value } = e.target;
    let valMeasuredDepth = false;

    if (name === "measured_depth") {
      valMeasuredDepth = sumMeasureDepth("array", dataTableTubing);
      if (valMeasuredDepth) {
        message.error(
          "the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation"
        );
        setDataTableTubing((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      }
    } else if (
      name === "gauge_tvd" &&
      inputDataTuning.enable_gauge_parameters
    ) {
      valMeasuredDepth = sumMeasureDepth("array", dataTableGauge);
      if (valMeasuredDepth) {
        message.error(
          "the sum of MD Length in the table cannot exceed the maximum value of Measured Depth in Well Orientation"
        );
        setDataTableGauge((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: null } : item
          )
        );
      }
    }
  };

  const handleChangeGauge = (e, id) => {
    const { name, value } = e.target;
    let dataValue = value;
    if (name !== "test_date") {
      dataValue = Number(value);
    }
    if (value === "") {
      dataValue = "";
      setDataTableGauge((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, [name]: dataValue } : item
        )
      );
    } else {
      if (name === "gauge_tvd" && (dataValue < 0 || dataValue > 60000)) {
        message.error("The value cannot be less than 0 or more than 60000");
      } else if (
        name === "gauge_pressure" &&
        (dataValue < 0 || dataValue > 25000)
      ) {
        message.error("The value cannot be less than 0 or more than 25000");
      } else if (
        name === "gauge_temperature" &&
        (dataValue < 0 || dataValue > 450)
      ) {
        message.error("The value cannot be less than 0 or more than 450");
      } else {
        if (name === "gauge_tvd" && value !== null) {
          setDataTableTestVLP((prevState) =>
            prevState.map((item) =>
              item.id === id ? { ...item, ["depth_tvd"]: dataValue } : item
            )
          );
        }
        if (name === "gauge_pressure" && value !== null) {
          setDataTableTestVLP((prevState) =>
            prevState.map((item) =>
              item.id === id
                ? { ...item, ["measured_pressure_tvd"]: dataValue }
                : item
            )
          );
        }
        if (name === "gauge_temperature" && value !== null) {
          setDataTableTestVLP((prevState) =>
            prevState.map((item) =>
              item.id === id
                ? { ...item, ["gauge_temperature"]: dataValue }
                : item
            )
          );
        }
        setDataTableGauge((prevState) =>
          prevState.map((item) =>
            item.id === id ? { ...item, [name]: dataValue } : item
          )
        );
      }
    }

    setHasChanges(true);
  };

  const handleChangeInputData = (event) => {
    const { name, value } = event.target;
    let dataValue = value;
    setHasChanges(true);

    const validateMD =
      name === "mid_perf_tvd_depth"
        ? sumMeasureDepth("only", dataValue)
        : false;

    if ((name === "gas_rate" && value === "") || value === null) {
      setEnableInputGOR(false);
    }
    if ((name === "gas_rate" && value !== "") || value === null) {
      setEnableInputGOR(true);
    }
    if ((name === "gor_pp" && value === "") || value === null) {
      setEnableInputGasRate(false);
    }
    if ((name === "gor_pp" && value !== "") || value === null) {
      setEnableInputGasRate(true);
    }
    if ((name === "oil_rate" && value !== "") || value === null) {
      const dataModifyTest = dataTableTestVLP?.map((item) => ({
        ...item,
        oil_rate_tvd: value,
      }));
      setDataTableTestVLP(dataModifyTest);
    }

    if (value === "" || value === null || value === 0) {
      setInputDataTuning((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
      if (name === "calculated_pressure") {
        const mseCalculated = validateDataRMS();
        setInputDataTuning((prevState) => ({
          ...prevState,
          mse_vlp: mseCalculated,
        }));
      } else if (
        (name === "mid_perf_tvd_depth" ||
          name === "gas_lift_valve_depth_1" ||
          name === "gas_lift_valve_depth_2" ||
          name === "gas_lift_valve_depth_3") &&
        (dataValue < 0 || dataValue > 60000)
      ) {
        message.error("The value cannot be less than 0 or more than 60000");
      } else if (validateMD) {
        message.error(
          "Mid-perf Measured Depth cannot exceed the maximum value of Measured Depth in Well Orientation"
        );
        setInputDataTuning((prevState) => ({
          ...prevState,
          [name]: null,
        }));
      } else if (
        (name === "reservoir_pressure" || name === "injection_pressure") &&
        (dataValue < 0 || dataValue > 25000)
      ) {
        message.error("The value cannot be less than 0 or more than 25000");
      } else if (
        name === "reservoir_temperature" &&
        (dataValue < 0 || dataValue > 450)
      ) {
        message.error("The value cannot be less than 0 or more than 450");
      } else if (name === "oil_rate" && (dataValue < 0 || dataValue > 50000)) {
        message.error("The value cannot be less than 0 or more than 50000");
      } else if (
        (name === "gas_rate" || name === "injection_gas_rate") &&
        (dataValue < 0 || dataValue > 100000)
      ) {
        message.error("The value cannot be less than 0 or more than 100000");
      } else if (name === "gor_pp" && (dataValue < 0 || dataValue > 500000)) {
        message.error("The value cannot be less than 0 or more than 500000");
      } else if (
        (name === "water_cut" ||
          name === "gas_injection_distribution_valve_1" ||
          name === "gas_injection_distribution_valve_2" ||
          name === "gas_injection_distribution_valve_3") &&
        (dataValue < 0 || dataValue > 100)
      ) {
        message.error("The value cannot be less than 0 or more than 100");
      } else if (
        name === "wellhead_temperature" &&
        (dataValue < 0 || dataValue > 250)
      ) {
        message.error("The value cannot be less than 0 or more than 250");
      } else if (
        name === "wellhead_pressure" &&
        (dataValue < 0 || dataValue > 5000)
      ) {
        message.error("The value cannot be less than 0 or more than 5000");
      } else if (
        name === "test_date_pp" ||
        name === "test_date_glp" ||
        name === "test_date_whp" ||
        name == "enable_gauge_parameters"
      ) {
        setInputDataTuning((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setInputDataTuning((prevState) => ({
          ...prevState,
          [name]: Number(value),
        }));
      }
    }
  };

  const handleCheckChange = (id, name) => {
    if (activeChecks.includes(id)) {
      setActiveChecks(activeChecks.filter((checkId) => checkId !== id));
      setHasChanges(true);
    } else {
      setActiveChecks([id]);
      setHasChanges(true);
    }
  };

  const handleChangeScenario = (event) => {
    const { name, value } = event.target;
    let dataValue = value;

    const specialCharPattern = /[^a-zA-Z0-9\s]/;
    const scenarioExists = name === "name_scenario" ? dataOptionScenario?.some(obj => obj.name === dataValue) : null;

    switch (name) {
      case "name_scenario":
        if (scenarioExists) {
          message.error("There is already a scenario with the same NAME, please generate a new NAME");
          setInputScenarioInformation((prevState) => ({
            ...prevState,
            name: null,
          }));
        } else if (specialCharPattern.test(dataValue)) {
          message.error("Please avoid special characters in the NAME.");
          setInputScenarioInformation((prevState) => ({
            ...prevState,
            [name]: null,
          }));

        } else {
          setInputScenarioInformation((prevState) => ({
            ...prevState,
            name: dataValue,
          }));
        }
        break;
      case "date_scenario":
        setInputScenarioInformation((prevState) => ({
          ...prevState,
          date: dataValue,
        }));
        break;
      default:
        break;
    }
  };

  const handleClickDone = () => {
    const validateDataTubingNull = VerifyingNulls(dataTableTubing);
    const validateDataGaugeNull = inputDataTuning.enable_gauge_parameters
      ? VerifyingNulls(dataTableGauge)
      : false;

    if (
      validateDataTubingNull ||
      validateDataGaugeNull  ||
      inputDataTuning.mid_perf_tvd_depth === "" ||
      inputDataTuning.reservoir_temperature === "" ||
      inputDataTuning.reservoir_pressure === "" ||
      inputDataTuning.test_date_pp === "" ||
      inputDataTuning.oil_rate === "" ||
      inputDataTuning.water_cut === "" ||
      inputDataTuning.test_date_glp === "" ||
      inputDataTuning.injection_gas_rate === "" ||
      inputDataTuning.injection_pressure === "" ||
      inputDataTuning.test_date_whp === "" ||
      inputDataTuning.wellhead_temperature === "" ||
      inputDataTuning.wellhead_pressure === "" ||
      inputDataTuning.gas_lift_valve_depth_1 === "" ||
      inputDataTuning.gas_lift_valve_depth_2 === "" ||
      inputDataTuning.gas_lift_valve_depth_3 === "" ||
      inputDataTuning.paramter1_vlp === "" ||
      inputDataTuning.paramter2_vlp === "" ||
      inputDataTuning.number_of_intervals === "" ||
      activeChecks.length < 1
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The information is empty please enter rows");
    } else {
            
      setInputEmpty(false);
      const dataWell = {
        well_id: inputGetDataWell.well_id,
        well_platforms: inputGetDataWell.well_platforms,
        well_name: inputGetDataWell.well_name,
        region: inputGetDataWell.region,
        country: inputGetDataWell.country,
        field: inputGetDataWell.field,
        county: inputGetDataWell.county,
        latitude: inputGetDataWell.latitude,
        longitude: inputGetDataWell.longitude,
        company: inputGetDataWell.company,
        objective: inputGetDataWell.objective,
        well_type: inputGetDataWell.well_type,
        well_oritentation: inputGetDataWell.well_orientation,
        artificial_method: inputGetDataWell.artificial_method,
        well_reference: inputGetDataWell.well_reference,
        flow_type: inputGetDataWell.flow_type,
        fluid_type: inputGetDataWell.fluid_type,
      };
      const arrayGaugePressure = inputDataTuning?.enable_gauge_parameters
        ? dataTableGauge?.map((item) => item.gauge_tvd)
        : [];
      const arrayDataDeviation = inputGetDataWell.dataDeviation.map(
        (item) => item.measured_depth
      );
      const arrayTVD = inputGetDataWell.dataDeviation.map((item) => item.tvd);
      const stateCorrelation =
        activeChecks[0] === 0
          ? "BB"
          : activeChecks[0] === 1
            ? "DR"
            : activeChecks[0] === 2
              ? "FB"
              : activeChecks[0] === 3
                ? "HB"
                : "W";
      const stateWellType = inputGetDataWell.well_type === "producer" ? true : false;
      const dataCalculatelVLP = {
        res_pressg: Number(inputDataTuning.reservoir_pressure),
        res_templ: Number(inputDataTuning.reservoir_temperature),
        mid_perf_tvd: Number(inputDataTuning.mid_perf_tvd_depth),
        qo_sc: Number(inputDataTuning.oil_rate),
        qg_sc:
          Number(inputDataTuning.gas_rate) === 0
            ? null
            : Number(inputDataTuning.gas_rate),
        gor:
          Number(inputDataTuning.gor_pp) === 0
            ? null
            : Number(inputDataTuning.gor_pp),
        w_cut: Number(inputDataTuning.water_cut),
        qg_inj_sc: Number(inputDataTuning.injection_gas_rate),
        inj_pressg: Number(inputDataTuning.injection_pressure),
        wh_pressg: Number(inputDataTuning.wellhead_pressure),
        wh_temp: Number(inputDataTuning.wellhead_temperature),
        inj_depth1: Number(inputDataTuning.gas_lift_valve_depth_1),
        inj_depth2: Number(inputDataTuning.gas_lift_valve_depth_2),
        inj_depth3: Number(inputDataTuning.gas_lift_valve_depth_3),
        API: Number(inputGetDataWell.res_oil_gravity),
        pb: Number(inputGetDataWell.bubble_point_pressure),
        rsb: Number(inputGetDataWell.res_initial_gor),
        sgg: Number(inputGetDataWell.res_gas_gravity),
        yco2: Number(inputGetDataWell.res_CO2),
        yh2s: Number(inputGetDataWell.res_H2S),
        yn2: Number(inputGetDataWell.res_N2),
        ppm: Number(inputGetDataWell.res_water_salinity),
        num_interval: Number(inputDataTuning.number_of_intervals),
        parameter1: Number(inputDataTuning.paramter1_vlp),
        parameter2: Number(inputDataTuning.paramter2_vlp),
        depth_press_test_values: arrayGaugePressure,
        type_correlation: stateCorrelation,
        is_producer: stateWellType,
        measured_depth_table: arrayDataDeviation,
        true_vertical_depth_table: arrayTVD,
        switch_lab: inputGetDataWell.fluidLab,
        correlation: inputGetDataWell.calculate_correlation,
        correlation_2: inputGetDataWell.calculate_correlation_oil,
        gas_inj_distribution1: Number(
          inputDataTuning.gas_injection_distribution_valve_1
        ),
        gas_inj_distribution2: Number(
          inputDataTuning.gas_injection_distribution_valve_2
        ),
        gas_inj_distribution3: Number(
          inputDataTuning.gas_injection_distribution_valve_3
        ),
        pdd: !!inputDataTuning.drawdownPres
          ? Number(inputDataTuning.drawdownPres)
          : 0,
      };

      const dataUnstructuredTubing = dataTableTubing?.reduce(
        (resultado, objeto, index) => {
          resultado[`MD${index + 1}`] = objeto.measured_depth;
          resultado[`ID${index + 1}`] = objeto.internal_diameter;
          resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
          return resultado;
        },
        {}
      );
      const dataCreateWellTables = {
        tubing_data: [...dataTableTubing],
        gauge_data: [...dataTableGauge],
        test_data: [...dataTableTestVLP],
        active_checks: [...activeChecks],
      };
      const finalData = Object.assign(
        {},
        dataWell,
        inputDataTuning,
        dataCreateWellTables
      );
      const finalDataCalculateVLP = Object.assign(
        {},
        dataCalculatelVLP,
        dataUnstructuredTubing
      );
      const newDataWellGasLift = {
        ...dataWellGasLift?.data,
        selected_device_graph: {
          chartWellCond: inputVolumeTrackerWIC?.separator_type,
          chartProdHistory: inputVolumeTrackerPH?.separator_type,
          chartHole: inputVolumeTrackerWP?.separator_type,
        },
        selected_platform_graph: {
          chartWellCond: inputVolumeTrackerWIC?.separator_type ? inputVolumeTrackerWIC?.separator_type.split(" ")[0] : "",
          chartProdHistory: inputVolumeTrackerPH?.separator_type ? inputVolumeTrackerPH?.separator_type.split(" ")[0] : "",
          chartHole: inputVolumeTrackerWP?.separator_type ? inputVolumeTrackerWP?.separator_type.split(" ")[0] : "",
        },
      };
      
      setTimeout(() => {
        mutationUpdateDataGasLift.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          well: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: newDataWellGasLift,
        });
      }, 1000);

      setTimeout(() => {
        mutationCreateDataTuning.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalData,
        });
      }, 1000);

      setTimeout(() => {
        mutationCreateCalTuningVLP.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalDataCalculateVLP,
        });
      }, 1000);

      message.success("Processing complete!");
      navigate(`/${NAME_PAGES.gas_lift.list_well}`);
    }
  };

  const handleClickEdit = () => {
    const validateDataTubingNull = VerifyingNulls(dataTableTubing);
    const validateDataGaugeNull = inputDataTuning.enable_gauge_parameters
      ? VerifyingNulls(dataTableGauge)
      : false;

    if (
      validateDataTubingNull ||
      validateDataGaugeNull ||
      inputDataTuning.mid_perf_tvd_depth === "" ||
      inputDataTuning.reservoir_temperature === "" ||
      inputDataTuning.reservoir_pressure === "" ||
      inputDataTuning.test_date_pp === "" ||
      inputDataTuning.oil_rate === "" ||
      inputDataTuning.water_cut === "" ||
      inputDataTuning.test_date_glp === "" ||
      inputDataTuning.injection_gas_rate === "" ||
      inputDataTuning.injection_pressure === "" ||
      inputDataTuning.test_date_whp === "" ||
      inputDataTuning.wellhead_temperature === "" ||
      inputDataTuning.wellhead_pressure === "" ||
      inputDataTuning.gas_lift_valve_depth_1 === "" ||
      inputDataTuning.gas_lift_valve_depth_2 === "" ||
      inputDataTuning.gas_lift_valve_depth_3 === "" ||
      inputDataTuning.paramter1_vlp === "" ||
      inputDataTuning.paramter2_vlp === "" ||
      inputDataTuning.number_of_intervals === "" ||
      activeChecks.length < 1
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("The information is empty please enter rows");
    } else {
      setInputEmpty(false);
      const dataWell = {
        well_id: inputGetDataWell.well_id,
        well_platforms: inputGetDataWell.well_platforms,
        well_name: inputGetDataWell.well_name,
        region: inputGetDataWell.region,
        country: inputGetDataWell.country,
        field: inputGetDataWell.field,
        county: inputGetDataWell.county,
        latitude: inputGetDataWell.latitude,
        longitude: inputGetDataWell.longitude,
        company: inputGetDataWell.company,
        objective: inputGetDataWell.objective,
        well_type: inputGetDataWell.well_type,
        well_oritentation: inputGetDataWell.well_orientation,
        artificial_method: inputGetDataWell.artificial_method,
        well_reference: inputGetDataWell.well_reference,
        flow_type: inputGetDataWell.flow_type,
        fluid_type: inputGetDataWell.fluid_type,
      };
      const arrayGaugePressure = inputDataTuning.enable_gauge_parameters
        ? dataTableGauge.map((item) => item.gauge_pressure)
        : [];
      const arrayDataDeviation = inputGetDataWell.dataDeviation.map(
        (item) => item.measured_depth
      );
      const arrayTVD = inputGetDataWell.dataDeviation.map((item) => item.tvd);
      const stateCorrelation =
        activeChecks[0] === 0
          ? "BB"
          : activeChecks[0] === 1
            ? "DR"
            : activeChecks[0] === 2
              ? "FB"
              : activeChecks[0] === 3
                ? "HB"
                : "W";
      const stateWellType = inputGetDataWell.well_type === "producer" ? true : false;
      const dataCalculatelVLP = {
        res_pressg: Number(inputDataTuning.reservoir_pressure),
        res_templ: Number(inputDataTuning.reservoir_temperature),
        mid_perf_tvd: Number(inputDataTuning.mid_perf_tvd_depth),
        qo_sc: Number(inputDataTuning.oil_rate),
        qg_sc:
          Number(inputDataTuning.gas_rate) === 0
            ? null
            : Number(inputDataTuning.gas_rate),
        gor:
          Number(inputDataTuning.gor_pp) === 0
            ? null
            : Number(inputDataTuning.gor_pp),
        w_cut: Number(inputDataTuning.water_cut),
        qg_inj_sc: Number(inputDataTuning.injection_gas_rate),
        inj_pressg: Number(inputDataTuning.injection_pressure),
        wh_pressg: Number(inputDataTuning.wellhead_pressure),
        wh_temp: Number(inputDataTuning.wellhead_temperature),
        inj_depth1: Number(inputDataTuning.gas_lift_valve_depth_1),
        inj_depth2: Number(inputDataTuning.gas_lift_valve_depth_2),
        inj_depth3: Number(inputDataTuning.gas_lift_valve_depth_3),
        API: Number(inputGetDataWell.res_oil_gravity),
        pb: Number(inputGetDataWell.bubble_point_pressure),
        rsb: Number(inputGetDataWell.res_initial_gor),
        sgg: Number(inputGetDataWell.res_gas_gravity),
        yco2: Number(inputGetDataWell.res_CO2),
        yh2s: Number(inputGetDataWell.res_H2S),
        yn2: Number(inputGetDataWell.res_N2),
        ppm: Number(inputGetDataWell.res_water_salinity),
        num_interval: Number(inputDataTuning.number_of_intervals),
        parameter1: Number(inputDataTuning.paramter1_vlp),
        parameter2: Number(inputDataTuning.paramter2_vlp),
        depth_press_test_values: arrayGaugePressure,
        type_correlation: stateCorrelation,
        is_producer: stateWellType,
        measured_depth_table: arrayDataDeviation,
        true_vertical_depth_table: arrayTVD,
        switch_lab: inputGetDataWell.fluidLab,
        correlation: inputGetDataWell.calculate_correlation,
        correlation_2: inputGetDataWell.calculate_correlation_oil,
        gas_inj_distribution1: Number(
          inputDataTuning.gas_injection_distribution_valve_1
        ),
        gas_inj_distribution2: Number(
          inputDataTuning.gas_injection_distribution_valve_2
        ),
        gas_inj_distribution3: Number(
          inputDataTuning.gas_injection_distribution_valve_3
        ),
        pdd: !!inputDataTuning.drawdownPres
          ? Number(inputDataTuning.drawdownPres)
          : 0,
      };

      const dataUnstructuredTubing = dataTableTubing?.reduce(
        (resultado, objeto, index) => {
          resultado[`MD${index + 1}`] = objeto.measured_depth;
          resultado[`ID${index + 1}`] = objeto.internal_diameter;
          resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
          return resultado;
        },
        {}
      );

      const dataCreateWellTables = {
        tubing_data: [...dataTableTubing],
        gauge_data: [...dataTableGauge],
        test_data: [...dataTableTestVLP],
        active_checks: [...activeChecks],
      };
      const finalData = Object.assign(
        {},
        dataWell,
        inputDataTuning,
        dataCreateWellTables
      );
      const finalDataCalculateVLP = Object.assign(
        {},
        dataCalculatelVLP,
        dataUnstructuredTubing
      );
      const newDataWellGasLift = {
        ...dataWellGasLift?.data,
        selected_device_graph: {
          chartWellCond: inputVolumeTrackerWIC?.separator_type,
          chartProdHistory: inputVolumeTrackerPH?.separator_type,
          chartHole: inputVolumeTrackerWP?.separator_type,
        },
        selected_platform_graph: {
          chartWellCond: inputVolumeTrackerWIC?.separator_type ? inputVolumeTrackerWIC?.separator_type.split(" ")[0] : "",
          chartProdHistory: inputVolumeTrackerPH?.separator_type ? inputVolumeTrackerPH?.separator_type.split(" ")[0] : "",
          chartHole: inputVolumeTrackerWP?.separator_type ? inputVolumeTrackerWP?.separator_type.split(" ")[0] : "",
        },
      };

      setTimeout(() => {
        mutationUpdateDataGasLift.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          well: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: newDataWellGasLift,
        });
      }, 1000);
      setTimeout(() => {
        mutationUpdateDataTuning.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalData,
        });
      }, 1000);

      setTimeout(() => {
        mutationUpdateCalTuningVLP.mutate({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          wellid: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalDataCalculateVLP,
        });
      }, 1000);
      message.success("Processing Edit complete!");
      navigate(`/${NAME_PAGES.gas_lift.list_well}`);
    }
  };

  const handleSaveDataChart = () => {

    const validateDataGraph = Object.keys(dataTableGraphVLP?.depth_md).length && Object.keys(dataTableGraphUntVLP?.depth_md).length && Object.keys(dataTableGraphVLP?.pressure).length && Object.keys(dataTableGraphUntVLP?.pressure).length;

    if (validateDataGraph && !!inputScenarioInformation.name && !!inputScenarioInformation.date) {

      const dataChartSave = {
        tuned: {
          depth_md: dataTableGraphVLP?.depth_md,
          pressure: dataTableGraphVLP?.pressure
        },
        untuned: {
          depth_md: dataTableGraphUntVLP?.depth_md,
          pressure: dataTableGraphUntVLP?.pressure
        }
      }

      const finalDataChartVLP = { ...inputScenarioInformation, data: dataChartSave };

      async function executeFunctionPost() {
        await mutationDataChartVLP.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: dataFilterUserGasLift.county,
          field: dataFilterUserGasLift.field,
          well: dataFilterUserGasLift.well_id,
          username: User.user_name,
          data: finalDataChartVLP
        });

        await new Promise(resolve => setTimeout(resolve, 100));

        setInputEmpty(false);
        setOpenSave(false);
        setInputScenarioInformation(INITIAL_DATA_SCENARIO_CHART);
        message.success("The scenario was saved correctly");
      }
      executeFunctionPost();

    } else if (!validateDataGraph) {
      message.error("The scenario cannot be saved because there is no data to graph.");
    } else {
      message.error("Please enter the data correctly");
      setInputEmpty(true);
    }
  }

  const handleClickClean = () => {
    showCleanConfirm();
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  const handleChangeOptionOpen = () => {
    setOptionOpen(!optionOpen);
  };

  const handleCalculateVLP = () => {
    const validateMaxMD =
      sumMeasureDepth("array", dataTableTubing) ||
        sumMeasureDepth("array", dataTableGauge) ||
        sumMeasureDepth("only", inputDataTuning.mid_perf_tvd_depth)
        ? true
        : false;
    const validateDataTubingNull = VerifyingNulls(dataTableTubing);
    const validateDataGaugeNull = inputDataTuning.enable_gauge_parameters
      ? VerifyingNulls(dataTableGauge)
      : false;
                                                                                                                                   
    if (
      validateDataTubingNull ||
      validateDataGaugeNull ||
      inputDataTuning.mid_perf_tvd_depth === "" ||
      inputDataTuning.reservoir_temperature === "" ||
      inputDataTuning.reservoir_pressure === "" ||
      inputDataTuning.test_date_pp === "" ||
      inputDataTuning.oil_rate === "" ||
      inputDataTuning.water_cut === "" ||
      inputDataTuning.test_date_glp === "" ||
      inputDataTuning.injection_gas_rate === "" ||
      inputDataTuning.injection_pressure === "" ||
      inputDataTuning.test_date_whp === "" ||
      inputDataTuning.wellhead_temperature === "" ||
      inputDataTuning.wellhead_pressure === "" ||
      inputDataTuning.gas_lift_valve_depth_1 === "" ||
      inputDataTuning.gas_lift_valve_depth_2 === "" ||
      inputDataTuning.gas_lift_valve_depth_3 === "" ||
      inputDataTuning.paramter1_vlp === "" ||
      inputDataTuning.paramter2_vlp === "" ||
      inputDataTuning.number_of_intervals === "" ||
      activeChecks.length < 1 ||
      validateMaxMD
    ) {
      setInputEmpty(true);
      handleOpenModal();
      setTypeAlert("Message");
      setMessageAlert("The are empty fields or invalid information");
    } else {
      setInputEmpty(false);
      const arrayGaugePressure = inputDataTuning?.enable_gauge_parameters
        ? dataTableGauge?.map((item) => item.gauge_tvd)
        : [];
      const arrayDataDeviation = inputGetDataWell.dataDeviation.map(
        (item) => item.measured_depth
      );
      const arrayTVD = inputGetDataWell.dataDeviation.map((item) => item.tvd);
      const stateCorrelation =
        activeChecks[0] === 0
          ? "BB"
          : activeChecks[0] === 1
            ? "DR"
            : activeChecks[0] === 2
              ? "FB"
              : activeChecks[0] === 3
                ? "HB"
                : "W";
      const stateWellType =
        inputGetDataWell.well_type === "producer" ? true : false;

      const dataCalculatelVLP = {
        res_pressg: Number(inputDataTuning.reservoir_pressure),
        res_templ: Number(inputDataTuning.reservoir_temperature),
        mid_perf_tvd: Number(inputDataTuning.mid_perf_tvd_depth),
        qo_sc: Number(inputDataTuning.oil_rate),
        qg_sc:
          Number(inputDataTuning.gas_rate) === 0
            ? null
            : Number(inputDataTuning.gas_rate),
        gor:
          Number(inputDataTuning.gor_pp) === 0
            ? null
            : Number(inputDataTuning.gor_pp),
        w_cut: Number(inputDataTuning.water_cut),
        qg_inj_sc: Number(inputDataTuning.injection_gas_rate),
        inj_pressg: Number(inputDataTuning.injection_pressure),
        wh_pressg: Number(inputDataTuning.wellhead_pressure),
        wh_temp: Number(inputDataTuning.wellhead_temperature),
        inj_depth1: Number(inputDataTuning.gas_lift_valve_depth_1),
        inj_depth2: Number(inputDataTuning.gas_lift_valve_depth_2),
        inj_depth3: Number(inputDataTuning.gas_lift_valve_depth_3),
        API: Number(inputGetDataWell.res_oil_gravity),
        pb: Number(inputGetDataWell.bubble_point_pressure),
        rsb: Number(inputGetDataWell.res_initial_gor),
        sgg: Number(inputGetDataWell.res_gas_gravity),
        yco2: Number(inputGetDataWell.res_CO2),
        yh2s: Number(inputGetDataWell.res_H2S),
        yn2: Number(inputGetDataWell.res_N2),
        ppm: Number(inputGetDataWell.res_water_salinity),
        num_interval: Number(inputDataTuning.number_of_intervals),
        parameter1: Number(inputDataTuning.paramter1_vlp),
        parameter2: Number(inputDataTuning.paramter2_vlp),
        depth_press_test_values: arrayGaugePressure,
        type_correlation: stateCorrelation,
        is_producer: stateWellType,
        measured_depth_table: arrayDataDeviation,
        true_vertical_depth_table: arrayTVD,
        switch_lab: inputGetDataWell.fluidLab,
        correlation: inputGetDataWell.calculate_correlation,
        correlation_2: inputGetDataWell.calculate_correlation_oil,
        gas_inj_distribution1: Number(
          inputDataTuning.gas_injection_distribution_valve_1
        ),
        gas_inj_distribution2: Number(
          inputDataTuning.gas_injection_distribution_valve_2
        ),
        gas_inj_distribution3: Number(
          inputDataTuning.gas_injection_distribution_valve_3
        ),
        pdd: !!inputDataTuning.drawdownPres
          ? Number(inputDataTuning.drawdownPres)
          : 0,
      };

      const dataUnstructuredTubing = dataTableTubing?.reduce(
        (resultado, objeto, index) => {
          resultado[`MD${index + 1}`] = objeto.measured_depth;
          resultado[`ID${index + 1}`] = objeto.internal_diameter;
          resultado[`roughness${index + 1}`] = objeto.absolute_roughness;
          return resultado;
        },
        {}
      );

      const dataCalculateVLP = Object.assign(
        {},
        dataCalculatelVLP,
        dataUnstructuredTubing
      );

      if (inputGetDataTuning === null || emptyTuningData ) {
        async function executeFunction() {
          await sequentialMutationVLPCreate(dataCalculateVLP);
        }
        executeFunction();
      } else if (inputGetDataTuning != null && !emptyTuningData) {
        async function executeFunction() {
          await sequentialMutationVLPUpdate(dataCalculateVLP);
        }
        executeFunction();
      }
    }
  };

  const handleOptionChange = (name, option) => {
    switch (name) {
      case "separator_typeWP":
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            selected_platform_graph: {
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartHole: option.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_gaslift.selected_device_graph,
              chartHole: option,
            },
          },
        });
        setInputVolumeTrackerWP((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        setInputVolumeTrackerRT((prevState) => ({
          ...prevState,
          chartHole: option.split(" ")[0],
        }));
        break;
      case "separator_typeWIC":
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            selected_platform_graph: {
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartWellCond: option.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_gaslift.selected_device_graph,
              chartWellCond: option,
            },
          },
        });
        setInputVolumeTrackerWIC((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        setInputVolumeTrackerRT((prevState) => ({
          ...prevState,
          chartWellCond: option.split(" ")[0],
        }));
        break;
      case "separator_type":
        getDeviceRealTime({
          ...deviceRealTime,
          device_gaslift: {
            ...deviceRealTime.device_gaslift,
            selected_platform_graph: {
              ...deviceRealTime.device_gaslift.selected_platform_graph,
              chartProdHistory: option.split(" ")[0],
            },
            selected_device_graph: {
              ...deviceRealTime.device_gaslift.selected_device_graph,
              chartProdHistory: option,
            },
          },
        });
        setInputVolumeTrackerPH((prevState) => ({
          ...prevState,
          separator_type: option,
        }));
        setInputVolumeTrackerRT((prevState) => ({
          ...prevState,
          chartProdHistory: option.split(" ")[0],
        }));
        break;
      default:
        break;
    }
  };

  const sequentialMutationVLPCreate = async (dataCalculateVLP) => {
    await mutationCreateCalTuningVLP.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
      data: dataCalculateVLP,
    });

    await new Promise((resolve) => setTimeout(resolve, 1000));

    await Promise.all([
      refetchCalculateUntunedVLP(),
      refetchCalculateTuningVLP(),
      refetchPressDrowDown(),
    ]);
  };

  const sequentialMutationVLPUpdate = async (dataCalculateVLP) => {
    await mutationUpdateCalTuningVLP.mutateAsync({
      operator: userDataInfo.operator,
      lease: userDataInfo.lease,
      region: userDataInfo.region,
      county: dataFilterUserGasLift.county,
      field: dataFilterUserGasLift.field,
      wellid: dataFilterUserGasLift.well_id,
      username: User.user_name,
      data: dataCalculateVLP,
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));

    await Promise.all([
      refetchCalculateUntunedVLP(),
      refetchCalculateTuningVLP(),
      refetchPressDrowDown(),
    ]);
  };

  const convertDate = (fechaNumerica) => {
    const fecha = new Date((fechaNumerica - (25567 + 1)) * 86400 * 1000); // Convertir la fecha numérica en milisegundos
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();
    const anio = fecha.getFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const showCleanConfirm = () => {
    confirm({
      title: "Do want to clean this tuning?",
      icon: <ExclamationCircleFilled />,
      content:
        "Remember that by executing this processes all fields will be emptied",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setInputDataTuning(DATA_VLP.initialData);
        setDataTableTubing(DATA_VLP.initialDataTubing);
        setDataTableGauge(DATA_VLP.initialDataGauge);
        setDataTableTestVLP(DATA_VLP.initialDataTestVLP);
        setActiveChecks([]);
        message.success("The fields have been cleaned");
      },
      onCancel() { },
    });
  };

  const handleFluidLab = (checked, nameSelect) => {
    switch (nameSelect) {
      case WELL_HEAD_PARAMETERS:
        setInputDataTuning({
          ...inputDataTuning,
          whpCheck: checked,
        });
        break;
      case GAS_LIFT_PARAMETERS:
        setInputDataTuning({
          ...inputDataTuning,
          glpCheck: checked,
        });
        break;
      case PRODUCTION_PARAMETERS:
        setInputDataTuning({
          ...inputDataTuning,
          ppCheck: checked,
        });
        break;

      default:
        break;
    }
  };

  const handleOptionChangeScenario = (nameValue, selectedValue) => {
    if (nameValue === "scenario_config") {
      setSelectOptionScenario(selectedValue);

      if (selectOptionScenario.length < selectedValue.length) {

        const lastScenario = selectedValue[selectedValue.length - 1];
        setOptionSelectedScenario(lastScenario);

        setTimeout(() => {
          refetchQueryDataChartVLP();
        }, 100);
      }
    }
  };
  const handleDeleteScenario = (scenario) => {
    if (!!scenario) {
      confirm({
        title: `Do you want to delete this Scenario ${scenario}`,
        icon: <ExclamationCircleFilled />,
        content: 'Deleted information can´t be recovered',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {

          const updatedArray = selectOptionScenario.filter(item => item !== scenario);
          setSelectOptionScenario(updatedArray);

          async function executeFunctionDelete() {
            await mutationDeleteScenerioChartVLP.mutateAsync({
              operator: userDataInfo.operator,
              lease: userDataInfo.lease,
              region: userDataInfo.region,
              county: dataFilterUserGasLift.county,
              field: dataFilterUserGasLift.field,
              well: dataFilterUserGasLift.well_id,
              username: User.user_name,
              name: scenario
            });

            await new Promise(resolve => setTimeout(resolve, 100));

            message.success("The scenario was deleted correctly");
          }
          executeFunctionDelete();

        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const decimalRound = (number, round) => {
    let valRound =
      number != undefined && number != null && number != ""
        ? Number(number.toFixed(round))
        : null;
    return valRound;
  };

  function sumMeasureDepth(option, value) {
    const valuesDeviation = inputGetDataWell.dataDeviation.length
      ? inputGetDataWell.dataDeviation.length
      : null;
    const lastDeviation =
      inputGetDataWell.dataDeviation[valuesDeviation - 1].measured_depth;

    let validation = false;
    switch (option) {
      case "only":
        validation = value > lastDeviation;
        break;
      case "array":
        const dataValue = value?.reduce((total, item) => {
          return (
            total +
            parseFloat(
              item.measured_depth ? item.measured_depth : item.gauge_tvd
            )
          );
        }, 0);

        validation = dataValue > lastDeviation;
        break;
      default:
        break;
    }
    return validation;
  }

  const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const index = selectOptionScenario.indexOf(value);
    const scenario = dataOptionScenario.find(item => item.name === value);

    return (
      <Tag
        closable={closable}
        onClose={onClose}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <svg width="20" height="10" style={{ marginRight: 4 }}>
          <line
            x1="0"
            y1="5"
            x2="20"
            y2="5"
            stroke="black"
            strokeWidth={TYPE_LINE_TUNNING_GRAPH[2][index % TYPE_LINE_TUNNING_GRAPH[2].length]}
            strokeDasharray={TYPE_LINE_TUNNING_GRAPH[0][index % TYPE_LINE_TUNNING_GRAPH[0].length]}
          />
        </svg>
        {`${scenario.name} (${scenario.date})`}
      </Tag>
    );
  };

  return (
    <div className="flex flex-col w-full h-full bg-white ">
      <div className="flex">
        <Navbar wellboreOption={wellboreOption}></Navbar>
        <Sidebar className="absolute flex h-full "></Sidebar>
      </div>
      <div>
        {loadDataTuning && (
          <div>
            <Loading></Loading>
          </div>
        )}
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <div className="flex flex-col w-full h-full pl-[88px] text-center justify-center">
        <div className="flex w-full h-[50px] justify-start text-center items-center mb-[27px] mt-[60px] pl-[110px]">
          <div className="flex flex-row w-[1230px] h-[24px] gap-5 justify-start text-center items-center">
            <h1 className="flex flex-row font-bold text-[20px]">VLP Tuning {dataFilterUserGasLift.well_id}</h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row items-center justify-start w-full h-full gap-5 text-center ">
            <h1 className="flex flex-row font-bold text-[16px]">
              Reservoir Parameters
            </h1>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full gap-5 text-center">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Mid-perf Measured Depth (feet)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="mid_perf_tvd_depth"
              value={inputDataTuning.mid_perf_tvd_depth ?? ""}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px] mb-[17px] border 
                ${(inputEmpty && inputDataTuning.mid_perf_tvd_depth === "") ||
                  inputDataTuning.mid_perf_tvd_depth === null
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                }`}
              min="0"
              max="60000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir Pressure (psia)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="reservoir_pressure"
              value={inputDataTuning.reservoir_pressure ?? ""}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.reservoir_pressure === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }`}
              min="0"
              max="25000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir Temperature (deg F)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="reservoir_temperature"
              value={inputDataTuning.reservoir_temperature ?? ""}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.reservoir_temperature === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }`}
              min="0"
              max="450"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row items-center justify-start w-full h-full gap-5 text-center ">
            <h1 className="flex flex-row font-bold text-[16px]">
              Production Parameters
            </h1>
            <div className="flex w-full max-w-[592px] text-start font bold text-[15px] gap-2">

              <label htmlFor="" className="text-bc-gray text-[14px] ml-[15px]">
                User
              </label>
              <Switch
                size="small"
                style={{
                  backgroundColor: inputDataTuning?.ppCheck
                    ? "#662D91"
                    : "#707070",
                  borderColor: inputDataTuning?.ppCheck ? "#662D91" : "#707070",
                }}
                checked={inputDataTuning?.ppCheck}
                onChange={(checked) =>
                  handleFluidLab(checked, PRODUCTION_PARAMETERS)
                }
              ></Switch>
              <label htmlFor="" className="text-bc-gray text-[14px] ml-[3px]">
                Real Time
              </label>
              <Tooltip title="This switch enables to use Real Time data">
                <InfoCircleOutlined
                  className=""
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                  />
              </Tooltip>
              {
                inputDataTuning?.ppCheck && <Select
                  style={{
                    backgroundColor: !inputVolumeTrackerPH.separator_type
                      ? "#FEF9C3"
                      : "",
                    border: !inputVolumeTrackerPH.separator_type
                      ? "1px solid #B91C1C"
                      : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  className="w-[180px] h-[18px] ml-[10px] text-left"
                  name={"separator_type"}
                  value={inputVolumeTrackerPH.separator_type}
                  onChange={(value) =>
                    handleOptionChange("separator_type", value)
                  }
                >
                  <Option value="">Select option</Option>
                  {dataListDevicesGaslift?.data
                    ?.filter((item) => item.includes("Separator"))
                    .map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              }
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full gap-5 text-center">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test Date
            </label>
            <input
              type="date"
              name="test_date_pp"
              value={inputDataTuning.test_date_pp ?? ""}
              onChange={handleChangeInputData}
              disabled={inputDataTuning?.ppCheck}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.test_date_pp === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }`}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Oil Rate (STB/D)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="oil_rate"
              value={inputDataTuning.oil_rate ?? ""}
              onChange={handleChangeInputData}
              disabled={inputDataTuning?.ppCheck}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.oil_rate === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }`}
              min="0"
              max="50000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Gas Rate (MSCF/D)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              disabled={enableInputGasRate || inputDataTuning?.ppCheck}
              name="gas_rate"
              value={inputDataTuning.gas_rate ?? ""}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border `}
              min="0"
              max="100000"
            />
          </div>
        </div>
        <div className="flex flex-row justify-start w-full gap-5 text-start">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              GOR (SCF/STB)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              disabled={enableInputGOR}
              name="gor_pp"
              value={inputDataTuning.gor_pp ?? ""}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border`}
              min="0"
              max="500000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Water Cut (%)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="water_cut"
              value={inputDataTuning.water_cut ?? ""}
              onChange={handleChangeInputData}
              disabled={inputDataTuning?.ppCheck}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.water_cut === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }`}
              min="0"
              max="100"
            />
          </div>
          <div
            className="flex flex-col w-1/3 max-w-[592px]"
            hidden={true}
          ></div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row items-center justify-start w-full h-full gap-5 text-center ">
            <h1 className="flex flex-row font-bold text-[16px]">
              Gas Lift Parameters
            </h1>

            <div className="flex w-full max-w-[592px] text-start font bold text-[15px] gap-2">
              <label htmlFor="" className="text-bc-gray text-[14px] ml-[15px]">
                User
              </label>
              <Switch
                size="small"
                style={{
                  backgroundColor: inputDataTuning?.glpCheck
                  ? "#662D91"
                  : "#707070",
                  borderColor: inputDataTuning?.glpCheck
                  ? "#662D91"
                  : "#707070",
                }}
                checked={inputDataTuning?.glpCheck}
                onChange={(checked) =>
                  handleFluidLab(checked, GAS_LIFT_PARAMETERS)
                }
                ></Switch>
              <label htmlFor="" className="text-bc-gray text-[14px] ml-[3px]">
                Real Time
              </label>
              <Tooltip title="This switch enables to use Real Time data">
                <InfoCircleOutlined
                  className=""
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                  />
              </Tooltip>
              {inputDataTuning?.glpCheck && (
                <Select
                  style={{
                    backgroundColor: !inputVolumeTrackerWIC.separator_type
                      ? "#FEF9C3"
                      : "",
                    border: !inputVolumeTrackerWIC.separator_type
                      ? "1px solid #B91C1C"
                      : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  className="w-[180px] h-[18px] ml-[10px] text-left"
                  name={"separator_typeWIC"}
                  value={inputVolumeTrackerWIC.separator_type}
                  onChange={(value) =>
                    handleOptionChange("separator_typeWIC", value)
                  }
                >
                  <Option value="">Select option</Option>
                  {dataListDevicesGaslift?.data?.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center w-full gap-5 text-center">
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Test Date
            </label>
            <input
              type="date"
              name="test_date_glp"
              value={inputDataTuning.test_date_glp ?? ""}
              onChange={handleChangeInputData}
              disabled={inputDataTuning?.glpCheck}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.test_date_glp === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                } `}
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Injection Gas Rate (MSCF/D)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="injection_gas_rate"
              value={
                inputDataTuning.injection_gas_rate === -995.25
                  ? "NA"
                  : inputDataTuning.injection_gas_rate
              }
              onChange={handleChangeInputData}
              disabled={inputDataTuning?.glpCheck}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.injection_gas_rate === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }  `}
              min="0"
              max="100000"
            />
          </div>
          <div className="flex flex-col w-1/3">
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Injection Pressure (psig)
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              name="injection_pressure"
              value={inputDataTuning.injection_pressure ?? ""}
              onChange={handleChangeInputData}
              disabled={inputDataTuning?.glpCheck}
              className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.injection_pressure === ""
                ? " border-red-700 bg-yellow-100"
                : "border-solid border-[#BCBCCC]"
                }  `}
              min="0"
              max="25000"
            />
          </div>
        </div>

        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row justify-center w-full gap-5 text-center">
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Gas Lift Valve Depth 1 (feet)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="gas_lift_valve_depth_1"
                value={inputDataTuning.gas_lift_valve_depth_1 ?? ""}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_lift_valve_depth_1 === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="60000"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Gas Lift Valve Depth 2 (feet)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="gas_lift_valve_depth_2"
                value={inputDataTuning.gas_lift_valve_depth_2 ?? ""}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_lift_valve_depth_2 === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="60000"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Gas Lift Valve Depth 3 (feet)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="gas_lift_valve_depth_3"
                value={inputDataTuning.gas_lift_valve_depth_3 ?? ""}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.gas_lift_valve_depth_3 === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="60000"
              />
            </div>
          </div>
        </div>

        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row justify-center w-full gap-5 text-center">
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Gas Injection Distribution Valve 1 (%)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="gas_injection_distribution_valve_1"
                value={inputDataTuning.gas_injection_distribution_valve_1 ?? ""}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty &&
                  inputDataTuning.gas_injection_distribution_valve_1 === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="100"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Gas Injection Distribution Valve 2 (%)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="gas_injection_distribution_valve_2"
                value={inputDataTuning.gas_injection_distribution_valve_2 ?? ""}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty &&
                  inputDataTuning.gas_injection_distribution_valve_2 === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="100"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Gas Injection Distribution Valve 3 (%)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="gas_injection_distribution_valve_3"
                value={inputDataTuning.gas_injection_distribution_valve_3 ?? ""}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty &&
                  inputDataTuning.gas_injection_distribution_valve_3 === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="100"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center  mb-[10px]">
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row items-center justify-start w-full h-full gap-5 text-center ">
            <h1 className="flex flex-row font-bold text-[16px]">
              Well Head Parameters
            </h1>

            <div className="flex w-full max-w-[592px] text-start font bold text-[15px] gap-2">
              <label htmlFor="" className="text-bc-gray text-[14px] ml-[15px]">
                User
              </label>
              <Switch
                size="small"
                style={{
                  backgroundColor: inputDataTuning?.whpCheck
                  ? "#662D91"
                  : "#707070",
                  borderColor: inputDataTuning?.whpCheck
                  ? "#662D91"
                  : "#707070",
                }}
                checked={inputDataTuning?.whpCheck}
                onChange={(checked) =>
                  handleFluidLab(checked, WELL_HEAD_PARAMETERS)
                }
                ></Switch>
              <label htmlFor="" className="text-bc-gray text-[14px] ml-[3px]">
                Real Time
              </label>
              <Tooltip title="This switch enables to use Real Time data">
                <InfoCircleOutlined
                  className=""
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                  />
              </Tooltip>
              {inputDataTuning?.whpCheck && (
                <Select
                  style={{
                    backgroundColor: !inputVolumeTrackerWP.separator_type
                      ? "#FEF9C3"
                      : "",
                    border: !inputVolumeTrackerWP.separator_type
                      ? "1px solid #B91C1C"
                      : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  className="w-[180px] h-[18px] ml-[10px] text-left"
                  name={"separator_typeWP"}
                  value={inputVolumeTrackerWP.separator_type}
                  onChange={(value) =>
                    handleOptionChange("separator_typeWP", value)
                  }
                >
                  <Option value="">Select option</Option>
                  {dataListDevicesGaslift?.data?.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
          <div className="flex flex-row justify-center w-full gap-5 text-center">
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Test Date
              </label>
              <input
                type="date"
                name="test_date_whp"
                value={inputDataTuning.test_date_whp ?? ""}
                onChange={handleChangeInputData}
                disabled={inputDataTuning?.whpCheck}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.test_date_whp === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Wellhead Pressure (psig)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="wellhead_pressure"
                value={
                  inputDataTuning.wellhead_pressure === -999.25
                    ? "NA"
                    : inputDataTuning.wellhead_pressure
                }
                onChange={handleChangeInputData}
                disabled={inputDataTuning?.whpCheck}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.wellhead_pressure === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="5000"
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Wellhead Temperature (degF)
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="wellhead_temperature"
                value={
                  inputDataTuning.wellhead_temperature === -999.25
                    ? "NA"
                    : inputDataTuning.wellhead_temperature
                }
                onChange={handleChangeInputData}
                disabled={inputDataTuning?.whpCheck}
                className={`w-full max-w-[592px] h-[47px] px-3 rounded-[4px]  mb-[17px] border ${inputEmpty && inputDataTuning.wellhead_temperature === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-solid border-[#BCBCCC]"
                  }  `}
                min="0"
                max="250"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full h-full pl-[160px] pr-[100px] text-center justify-center mb-[10px]">
        <div className="flex flex-col w-full h-full justify-center text-center items-center mb-[27px]">
          <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
            <div className="flex flex-row items-center justify-start w-full h-full gap-5 text-center ">
              <h1 className="flex flex-row font-bold text-[16px]">Tubing</h1>
              <ImportFile uploadfile={handleUploadFileTubing}></ImportFile>
            </div>
          </div>
          <ul className="flex flex-col w-full h-full">
            {/* seccion de titulos */}
            <div className="flex flex-col w-full text-start justify-end items-end mb-[7px]">
              <div className="flex flex-row w-full h-[20px] pl-[40px] pr-[40px] gap-2">
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Name
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  MD Length (feet)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Internal Diameter (inches)
                </label>
                <label className="flex max-w-[592px] w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                  Absolute Roughness (inches)
                </label>
              </div>
            </div>
            {dataTableTubing?.map((item, index) => (
              <li
                key={item.id + "tubing" + index}
                className="flex flex-row w-full overflow-y-auto"
              >
                {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500" /> */}
                <div className="flex w-6 h-[47px] fill-neutral-500"></div>
                <label
                  htmlFor=""
                  className="flex w-[20px] h-[47px] pr-[10px] font-bold text-lg text-center items-center justify-center "
                >
                  {index + 1}
                </label>
                <div className="flex flex-col w-full pr-[10px] gap-x-[18px] mb-[10px]">
                  <div className="flex flex-row w-full gap-5">
                    <div className="flex flex-col items-end w-1/4 text-right">
                      <input
                        type="text"
                        name={`nameTubing`}
                        value={item.nameTubing ?? ""}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]`}
                      />
                    </div>
                    <div className="flex flex-col items-end w-1/4 text-right">
                      <input
                        type="number"
                        name={`measured_depth`}
                        value={item.measured_depth ?? ""}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) => handleChangeBlur(e, item.id)}
                        className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid 
                          ${item.measured_depth === null
                            ? "border-red-700 bg-yellow-100"
                            : "border-[#BCBCCC]"
                          }`}
                      />
                    </div>
                    <div className="flex flex-col items-end w-1/4 text-right">
                      <input
                        type="number"
                        // defaultValue={item.internal_diameter}
                        name={`internal_diameter`}
                        value={item.internal_diameter ?? ""}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>
                    <div className="flex flex-col items-end w-1/4 text-right">
                      <input
                        type="number"
                        name={`absolute_roughness`}
                        value={item.absolute_roughness ?? ""}
                        onChange={(e) => handleChangeTubing(e, item.id)}
                        onWheel={(e) => e.target.blur()}
                        className="w-full  max-w-[592px]  h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                      />
                    </div>

                    <div
                      className={`flex flex-row  text-right items-start gap-2 ${dataTableTubing.length - 1 === index ? "" : "pr-8"
                        }`}
                    >
                      {dataTableTubing.length - 1 === index && (
                        <IconAdd
                          className="w-6 h-[47px] fill-neutral-500"
                          onClick={() => handleAddTubing()}
                        />
                      )}
                      {dataTableTubing.length >= 2 && (
                        <IconDelete
                          className="w-6 h-[47px] fill-neutral-500 "
                          onClick={() => handleDeleteTubing(item.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col w-full h-full justify-center text-center items-center mb-[27px]">
          <div className="flex w-full h-full justify-center text-center items-center mb-[7px]">
            <div className="flex flex-row items-center justify-start w-full h-full gap-2 text-center ">
              <h1 className="flex flex-row font-bold text-[16px] mr-[12px]">
                Gauge Parameters
              </h1>
              <label htmlFor="" className="text-bc-gray text-[14px] ml-[15px]">
                Off
              </label>
              <Switch
                style={{
                  backgroundColor: inputDataTuning?.enable_gauge_parameters
                    ? "#662D91"
                    : "#707070",
                  borderColor: inputDataTuning?.enable_gauge_parameters
                    ? "#662D91"
                    : "#707070",
                }}
                checked={inputDataTuning.enable_gauge_parameters}
                onChange={(value) =>
                  handleSwitch("enable_gauge_parameters", value)
                }
                size="small"
              />
              <label htmlFor="" className="text-bc-gray text-[14px]">
                On
              </label>
              <Tooltip title="Enable this switch when production test information is available">
                <InfoCircleOutlined
                  className=""
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                />
              </Tooltip>
              <ImportFile uploadfile={handleUploadFileGauge}></ImportFile>
            </div>
          </div>
          {inputDataTuning.enable_gauge_parameters &&
            <ul className="flex flex-col w-full h-full">
              {/* seccion de titulos */}
              <div className="flex flex-col w-full text-start justify-end items-end mb-[7px]">
                <div className="flex flex-row w-full h-[20px] pl-[40px] pr-[40px] gap-2">
                  <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                    Test Date
                  </label>
                  <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                    Gauge MD (feet)
                  </label>
                  <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                    Gauge Pressure (psia)
                  </label>
                  <label className="flex w-1/4 text-left items-start justify-start font-bold text-[15px] mb-[7px]">
                    Gauge Temperature (deg F)
                  </label>
                </div>
              </div>
                {dataTableGauge?.map((item, index) => (
                  <li
                    key={item.id + "gauge" + index}
                    className="flex flex-row w-full overflow-y-auto"
                  >
                    {/* <IconArrowsVertical className="flex w-6 h-[47px] fill-neutral-500" /> */}
                    <div className="flex w-6 h-[47px] fill-neutral-500"></div>
                    <label
                      htmlFor=""
                      className="flex w-[20px] h-[47px] pr-[10px] font-bold text-lg text-center items-center justify-center "
                    >
                      {index + 1}
                    </label>
                    <div className="flex flex-col w-full pr-[10px] gap-x-[18px] mb-[10px]">
                      <div className="flex flex-row w-full gap-5">
                        <div className="flex flex-col items-end w-1/4 text-right">
                          <input
                            type="date"
                            name={`test_date`}
                            value={
                              dataTableGauge.find((data) => data.id === item.id)
                                ?.test_date ?? ""
                            }
                            onChange={(e) => handleChangeGauge(e, item.id)}
                            className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]
                              ${inputEmpty && item.test_date === ""
                                ? " border-red-700 bg-yellow-100"
                                : "border-solid border-[#BCBCCC]"
                                }  `}
                          />
                        </div>
                        <div className="flex flex-col items-end w-1/4 text-right">
                          <input
                            type="number"
                            //defaultValue={item.gauge_tvd}
                            name={`gauge_tvd`}
                            value={
                              dataTableGauge.find((data) => data.id === item.id)
                                ?.gauge_tvd ?? ""
                            }
                            onChange={(e) => handleChangeGauge(e, item.id)}
                            onWheel={(e) => e.target.blur()}
                            onBlur={(e) => handleChangeBlur(e, item.id)}
                            className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]
                            ${inputEmpty &&  item.gauge_tvd === ""
                                ? "border-red-700 bg-yellow-100"
                                : "border-[#BCBCCC]"
                              }
                            `}
                          />
                        </div>
                        <div className="flex flex-col items-end w-1/4 text-right">
                          <input
                            type="number"
                            name={`gauge_pressure`}
                            value={
                              dataTableGauge.find((data) => data.id === item.id)
                                ?.gauge_pressure ?? ""
                            }
                            onChange={(e) => handleChangeGauge(e, item.id)}
                            onWheel={(e) => e.target.blur()}
                            className={`w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]
                              ${inputEmpty && item.gauge_pressure === ""
                                ? " border-red-700 bg-yellow-100"
                                : "border-solid border-[#BCBCCC]"
                                }  
                              `}
                          />
                        </div>
                        <div className="flex flex-col items-end w-1/4 text-right">
                          <input
                            type="number"
                            name={`gauge_temperature`}
                            value={dataTableGauge[index].gauge_temperature ?? ""}
                            onChange={(e) => handleChangeGauge(e, item.id)}
                            onWheel={(e) => e.target.blur()}
                            className={`w-full  max-w-[592px]  h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]
                              ${inputEmpty && item.gauge_temperature === ""
                                ? " border-red-700 bg-yellow-100"
                                : "border-solid border-[#BCBCCC]"
                                } 
                              `}
                          />
                        </div>

                        <div
                          className={`flex flex-row  text-right items-start gap-2 ${dataTableGauge.length - 1 === index ? "" : "pr-8"
                            }`}
                        >
                          {dataTableGauge.length - 1 === index && (
                            <IconAdd
                              className="w-6 h-[47px] fill-neutral-500 "
                              onClick={() => handleAddGauge()}
                            />
                          )}
                          {dataTableGauge.length >= 2 && (
                            <IconDelete
                              className="w-6 h-[47px] fill-neutral-500 "
                              onClick={() => handleDeleteGauge(item.id)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          }
        </div>

        <div className="flex flex-row w-full h-full mb-[20px]">
          <div className="flex flex-col w-2/5 text-right items-start pl-[45px] ">
            <Menu
              className="flex w-[446px]"
              mode="horizontal"
              defaultSelectedKeys={["0"]}
              onClick={handleNav}
              items={menuItems}
            />

            {selectedValue === "0" && (
              <>
                <div className="flex flex-col w-full h-full text-right items-start mt-[30px]">
                  <div className="flex flex-col w-full h-full text-right items-start mb-[10px]">
                    {Object.keys(DATA_VLP.typeLineGraph).map((id, index) => (
                      <div key={"corrrelation" + index}>
                        <Checkbox
                          name={DATA_VLP.typeLineGraph[id]}
                          className={`my-checkbox ${parseInt(id) === 0 ? "mt-[]" : "mt-[25px]"
                            }`}
                          checked={
                            activeChecks?.length > 0
                              ? activeChecks?.includes(parseInt(id))
                              : false
                          }
                          onChange={() =>
                            handleCheckChange(parseInt(id), DATA_VLP.typeLineGraph[id])
                          }
                          disabled={
                            parseInt(id) === 1 ||
                            parseInt(id) === 2 ||
                            parseInt(id) === 4
                          }
                        >
                          <div className="flex flex-row items-center justify-center text-center">
                            <div
                              style={{ background: DATA_VLP.typeLineColorGraph[id] }}
                              className={`flex w-[10px] h-[10px]  rounded-full ml-[5px] mr-[5px] text-center items-center justify-center`}
                            ></div>
                            <label htmlFor="">{DATA_VLP.typeLineGraph[id]}</label>
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col w-full text-right items-start mb-[18px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Number of Intervals
                    </label>
                    <input
                      name="number_of_intervals"
                      value={inputDataTuning.number_of_intervals ?? ""}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={`w-full h-[47px] rounded-[4px] px-2 border ${inputEmpty && inputDataTuning.number_of_intervals === ""
                        ? " border-red-700 bg-yellow-100"
                        : "border-solid border-[#BCBCCC]"
                        }`}
                    />
                  </div>
                  <div className="flex flex-col w-full text-right items-end mb-[10px] ">
                    <button
                      className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                      onClick={handleCalculateVLP}
                    >
                      Run
                    </button>
                  </div>
                  <div className="flex flex-col w-full text-right items-start mb-[10px]">
                    <h1 className="flex flex-row font-bold text-[20px]">
                      Test Data
                    </h1>
                  </div>
                  <div className="flex flex-col w-full h-full mb-[10px]">
                    <div className="flex flex-col w-full h-full text-start justify-end items-end mb-[7px]">
                      <div className="flex flex-row w-full gap-2">
                        <label className="flex w-1/3 text-left items-start justify-start font-bold text-[15px] ">
                          TVD Depth (ft)
                        </label>
                        <label className="flex w-1/3 text-left items-start justify-start font-bold text-[15px]">
                          Measured Pressure (psig)
                        </label>
                        <label className="flex w-1/3 text-left items-start justify-start font-bold text-[15px]">
                          Oil Rate (STB/D)
                        </label>
                      </div>
                    </div>
                    {dataTableTestVLP?.map((item, index) => (
                      <li
                        key={item.id + "test" + index}
                        className="flex flex-row w-full h-full min-h-[30px] max-h-[500px]"
                      >
                        <div className="flex flex-col w-full pr-[10px] gap-x-[18px] mb-[10px]">
                          <div className="flex flex-row w-full gap-5">
                            <div className="flex flex-col items-end w-1/3 text-right">
                              <input
                                type="text"
                                //defaultValue={item.depth_tvd}
                                name={`depth_tvd`}
                                value={
                                  dataTableTestVLP.find(
                                    (data) => data.id === item.id
                                  )?.depth_tvd ?? ""
                                }
                                readOnly
                                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                              />
                            </div>
                            <div className="flex flex-col items-end w-1/3 text-right">
                              <input
                                type="number"
                                // defaultValue={item.measured_pressure_tvd}
                                name={`measured_pressure_tvd`}
                                value={
                                  dataTableTestVLP.find(
                                    (data) => data.id === item.id
                                  )?.measured_pressure_tvd ?? ""
                                }
                                readOnly
                                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                              />
                            </div>
                            <div className="flex flex-col items-end w-1/3 text-right">
                              <input
                                type="number"
                                // defaultValue={item.oil_rate_tvd}
                                name={`oil_rate_tvd`}
                                value={
                                  dataTableTestVLP.find(
                                    (data) => data.id === item.id
                                  )?.oil_rate_tvd ?? ""
                                }
                                readOnly
                                className="w-full max-w-[592px] h-[47px] px-2 rounded-[4px] border border-solid border-[#BCBCCC]"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </div>
                </div>
              </>
            )}
            {selectedValue === "1" && (
              <>
                <div className="flex flex-col w-full h-full text-right items-start mt-[30px]">
                  <div className="flex flex-col w-full h-full col-span-2 text-right items-start mb-[12px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Parameter 1
                    </label>
                    <input
                      name="paramter1_vlp"
                      value={inputDataTuning.paramter1_vlp ?? ""}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[12px] border ${inputEmpty && inputDataTuning.paramter1_vlp === ""
                        ? " border-red-700 bg-yellow-100"
                        : "border-solid border-[#BCBCCC]"
                        }`}
                    />
                  </div>
                  <div className="flex flex-col w-full col-span-2 text-right items-start mb-[12px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Parameter 2
                    </label>
                    <input
                      name="paramter2_vlp"
                      value={inputDataTuning.paramter2_vlp ?? ""}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[12px] border ${inputEmpty && inputDataTuning.paramter2_vlp === ""
                        ? " border-red-700 bg-yellow-100"
                        : "border-solid border-[#BCBCCC]"
                        }`}
                    />
                  </div>
                  <div className="flex flex-col w-full col-span-2 text-right items-start md-[10px]">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[150px] text-start text-[15px] mb-[7px]"
                    >
                      Number of Intervals
                    </label>
                    <input
                      name="number_of_intervals"
                      value={inputDataTuning.number_of_intervals ?? ""}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[14px] border ${inputEmpty && inputDataTuning.number_of_intervals === ""
                        ? " border-red-700 bg-yellow-100"
                        : "border-solid border-[#BCBCCC]"
                        }`}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full col-span-2 text-right ">
                    <label
                      htmlFor=""
                      className="flex w-full max-w-[350px] text-start text-[15px] mb-[7px]"
                    >
                      Drawdown Pressure (psi) from Test at Reservoir Depth
                    </label>
                    <input
                      name="drawdownPres"
                      value={inputDataTuning?.drawdownPres ?? ""}
                      onChange={handleChangeInputData}
                      onWheel={(e) => e.target.blur()}
                      type="text"
                      className={`w-full h-[47px] rounded-[4px] px-2  mb-[17px] border ${inputEmpty && inputDataTuning.drawdownPres === ""
                        ? " border-red-700 bg-yellow-100"
                        : "border-solid border-[#BCBCCC]"
                        }`}
                    />
                  </div>
                  <div className="flex flex-col w-full text-right items-end mb-[10px]">
                    <button
                      className="h-[2em] w-[5em] bg-pa-purple text-white rounded-md hover:bg-pc-purple"
                      onClick={handleCalculateVLP}
                    >
                      Run
                    </button>
                  </div>
                  <div className="flex flex-col items-start w-full col-span-2 text-right">
                    <h1 className="flex flex-row font-bold text-[16px] mb-[10px]">
                      Tuned data
                    </h1>
                  </div>
                  <div className="flex flex-row w-full gap-5 ">
                    <div className="flex flex-col items-end w-1/2 text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Flowing Pressure (psia) at Sensor Depth
                      </label>
                      <input
                        type="number"
                        name="calculated_pressure"
                        value={inputDataTuning.calculated_pressure ?? ""}
                        onChange={handleChangeInputData}
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                    <div className="flex flex-col items-end w-1/2 text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Standard Deviation
                      </label>
                      <input
                        name="mse_vlp"
                        value={inputDataTuning.mse_vlp ?? ""}
                        onChange={handleChangeInputData}
                        type="text"
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-full gap-5 ">
                    <div className="flex flex-col items-end w-1/2 text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Flowing Pressure (psia) at Reservoir Depth
                      </label>
                      <input
                        type="calculatedPressRD"
                        name="calculatedPressRD"
                        value={decimalRound(
                          inputDataTuning?.calculatedPressRD,
                          2
                        )}
                        onChange={handleChangeInputData}
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                    <div className="flex flex-col items-end w-1/2 text-start ">
                      <label
                        htmlFor=""
                        className="flex w-full items-end text-[15px] mb-[7px]"
                      >
                        Calculated Drawdown Pressure (psi)
                      </label>
                      <input
                        name="calculatedDrawdown"
                        value={decimalRound(
                          inputDataTuning?.calculatedDrawdown,
                          2
                        )}
                        onChange={handleChangeInputData}
                        type="text"
                        className="w-full h-[47px] rounded-[4px] px-2 border border-solid border-[#BCBCCC] mb-[17px]"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col items-start w-full ml-2 text-right border border-solid rounded-md">
            <div className="flex w-full h-[25px] items-start justify-end text-center pr-[10px] mt-2">
              {optionOpen &&
                <Select
                  style={{
                    borderRadius: '8px',
                    textAlignalign: "left"
                  }}
                  className="min-w-[220px] max-w-[750px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                  name={"scenario_config"}
                  placeholder={"Select scenario"}
                  mode='multiple'
                  value={selectOptionScenario}
                  onChange={(value) => handleOptionChangeScenario("scenario_config", value)}
                  tagRender={tagRender}
                >
                  <Option value="" disabled selected>Select scenario</Option>
                  {dataOptionScenario.map((scenario, index) => (
                    <>
                      <Option
                        key={index}
                        value={scenario.name}
                        disabled={selectOptionScenario.length >= 5 && !selectOptionScenario.includes(scenario.name)}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{scenario.name} ({scenario.date})</span>
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation(); // Detener la propagación para evitar seleccionar la opción
                              handleDeleteScenario(scenario.name);
                            }}
                            style={{ marginRight: 8, cursor: 'pointer' }}
                          />
                        </div>
                      </Option>
                    </>
                  ))}
                </Select>
              }
              {!optionOpen && (
                <Tooltip placement="bottom" title={"expand options"} color={"#662D91"}>
                  <CaretDownOutlined
                    onClick={() => handleChangeOptionOpen()}
                    className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                  />
                </Tooltip>
              )}
              {optionOpen && (
                <Tooltip placement="bottom" title={"hide options"} color={"#662D91"}>
                  <CaretLeftOutlined
                    onClick={() => handleChangeOptionOpen()}
                    className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                  />
                </Tooltip>
              )}
              <Tooltip placement="bottom" title={"save calculations"} color={"#662D91"}>
                <SaveNavbar
                  onClick={() => handleCLickSaveChart()}
                  className="flex w-6 h-6 mr-4 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                />
              </Tooltip>
              <Tooltip placement="bottom" title={"expand chart"} color={"#662D91"}>
                <ExpandIcon1
                  className="w-5 h-5 hover:bg-pc-grey3"
                  onClick={handleCLickExpand}
                ></ExpandIcon1>
              </Tooltip>
            </div>
            {selectedValue === "0" && (
              <div className="flex flex-col items-start w-full h-full ml-2 text-right">
                <div className="flex w-full h-full">
                  <ChartVLP
                    activeChecks={activeChecks}
                    dataPoints={dataTableGauge}
                    dataPoint2={inputDataTuning}
                    dataPoint3={queryPressDrowDown?.data}
                    dataTableLine={[]}
                    dataTableLine2={dataTableGraphUntVLP}
                    domainMaxDepth={maxDepth}
                    domainMinDepth={minDepth}
                    domainMaxPressure={maxPressure}
                    domainMinPressure={minPressure}
                    selectedScenario={selectOptionScenario}
                    dataScenario={dataScenarioVLP}
                  ></ChartVLP>
                </div>
                <div className='flex flex-row items-center justify-center w-full gap-2 mb-1'>
                  <div className='w-4 h-1 bg-[#253fce]'></div>
                  <p className='text-bc-grey text-[16px]'>Untuned FBHP</p>
                  {(inputDataTuning.enable_gauge_parameters) && (
                    <>
                      <div className='rounded-full w-3 h-3 bg-[#1e1b4b]'></div>
                      <p className='text-bc-grey text-[13px]'>Gauge Pressure</p>
                    </>
                  )}
                  {(queryDataTuning?.data?.mid_perf_tvd_depth && queryDataTuning?.data?.reservoir_pressure) && (
                    <>
                      <div className='rounded-full w-3 h-3 bg-[#684200]'></div>
                      <p className='text-bc-grey text-[13px]'>Reservoir Pressure</p>
                    </>
                  )}
                  {(queryDataTuning?.data?.mid_perf_tvd_depth && queryPressDrowDown?.data?.pwf_user) && (
                    <>
                      <div className='rounded-full w-3 h-3 bg-[#009130]'></div>
                      <p className='text-bc-grey text-[13px]'>FBHP at Perforations</p>
                    </>
                  )}
                </div>
                <Modal
                  title="Tuned VLP"
                  centered
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width="85%"
                  className="bg-[#662D91] rounded-md"
                  okButtonProps={{
                    className: "bg-[#662D91]",
                  }}
                >
                  <div className="mb-[25px] justify-center mt-[25px] ml-[150px]"></div>
                  <div className="flex w-full h-[25px] items-start justify-end text-center pr-[10px] mt-2">
                    {optionOpen &&
                      <Select
                        style={{
                          borderRadius: '8px',
                          textAlignalign: "left"
                        }}
                        className="min-w-[220px] max-w-[750px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                        name={"scenario_config"}
                        placeholder={"Select scenario"}
                        mode='multiple'
                        value={selectOptionScenario}
                        onChange={(value) => handleOptionChangeScenario("scenario_config", value)}
                        tagRender={tagRender}
                      >
                        <Option value="" disabled selected>Select scenario</Option>
                        {dataOptionScenario.map((scenario, index) => (
                          <Option
                            key={index}
                            value={scenario.name}
                            disabled={selectOptionScenario.length >= 5 && !selectOptionScenario.includes(scenario.name)}
                          >
                            {scenario.name} ({scenario.date})
                          </Option>
                        ))}
                      </Select>
                    }
                    {!optionOpen && (
                      <Tooltip placement="bottom" title={"expand options"} color={"#662D91"}>
                        <CaretDownOutlined
                          onClick={() => handleChangeOptionOpen()}
                          className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                        />
                      </Tooltip>
                    )}
                    {optionOpen && (
                      <Tooltip placement="bottom" title={"hide options"} color={"#662D91"}>
                        <CaretLeftOutlined
                          onClick={() => handleChangeOptionOpen()}
                          className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                        />
                      </Tooltip>
                    )}
                    <Tooltip placement="bottom" title={"save calculations"} color={"#662D91"}>
                      <SaveNavbar
                        onClick={() => handleCLickSaveChart()}
                        className="flex w-6 h-6 mr-4 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                      />
                    </Tooltip>
                  </div>
                  <div className="flex h-[950px] w-full">
                    <ChartVLP
                      activeChecks={activeChecks}
                      dataPoints={dataTableGauge}
                      dataPoint2={inputDataTuning}
                      dataPoint3={queryPressDrowDown?.data}
                      dataTableLine={[]}
                      dataTableLine2={dataTableGraphUntVLP}
                      domainMaxDepth={maxDepth}
                      domainMinDepth={minDepth}
                      domainMaxPressure={maxPressure}
                      domainMinPressure={minPressure}
                      selectedScenario={selectOptionScenario}
                      dataScenario={dataScenarioVLP}
                    ></ChartVLP>
                  </div>
                  <div className='flex flex-row items-center justify-center w-full gap-2'>
                    <div className='w-4 h-1 bg-[#253fce]'></div>
                    <p className='text-bc-grey text-[16px]'>Untuned FBHP</p>
                    {(inputDataTuning.enable_gauge_parameters) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#1e1b4b]'></div>
                        <p className='text-bc-grey text-[13px]'>Gauge Pressure</p>
                      </>
                    )}
                    {(queryDataTuning?.data?.mid_perf_tvd_depth && queryDataTuning?.data?.reservoir_pressure) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#684200]'></div>
                        <p className='text-bc-grey text-[13px]'>Reservoir Pressure</p>
                      </>
                    )}
                    {(queryDataTuning?.data?.mid_perf_tvd_depth && queryPressDrowDown?.data?.pwf_user) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#009130]'></div>
                        <p className='text-bc-grey text-[13px]'>FBHP at Perforations</p>
                      </>
                    )}
                  </div>
                </Modal>
              </div>
            )}
            {selectedValue === "1" && (
              <div
                className="flex flex-col items-start w-full h-full ml-2 text-right"
              >
                <div div className="flex flex-col w-full h-full text-right">
                  <ChartVLP
                    activeChecks={activeChecks}
                    dataPoints={dataTableGauge}
                    dataPoint2={inputDataTuning}
                    dataPoint3={queryPressDrowDown?.data}
                    dataTableLine={dataTableGraphVLP}
                    dataTableLine2={dataTableGraphUntVLP}
                    domainMaxDepth={maxDepth}
                    domainMinDepth={minDepth}
                    domainMaxPressure={maxPressure}
                    domainMinPressure={minPressure}
                    tuningnNav={true}
                    selectedScenario={selectOptionScenario}
                    dataScenario={dataScenarioVLP}
                  ></ChartVLP>
                  <div className='flex flex-row items-center justify-center w-full gap-2 mb-1'>
                    <div className='w-4 h-1 bg-[#FE3F40]'></div>
                    <p className='text-bc-grey text-[16px]'>Tuned FBHP</p>
                    <div className='w-4 h-1 bg-[#253fce]'></div>
                    <p className='text-bc-grey text-[16px]'>Untuned FBHP</p>
                    {(inputDataTuning.enable_gauge_parameters) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#1e1b4b]'></div>
                        <p className='text-bc-grey text-[13px]'>Gauge Pressure</p>
                      </>
                    )}
                    {(queryDataTuning?.data?.mid_perf_tvd_depth && queryDataTuning?.data?.reservoir_pressure) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#684200]'></div>
                        <p className='text-bc-grey text-[13px]'>Reservoir Pressure</p>
                      </>
                    )}
                    {(queryDataTuning?.data?.mid_perf_tvd_depth && queryPressDrowDown?.data?.pwf_user) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#009130]'></div>
                        <p className='text-bc-grey text-[13px]'>FBHP at Perforations</p>
                      </>
                    )}
                  </div>
                </div>
                <Modal
                  title="VLP Correlations"
                  centered
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width="85%"
                  className="bg-[#662D91] rounded-md"
                  okButtonProps={{
                    className: "bg-[#662D91]",
                  }}
                >
                  <div className="flex w-full h-[25px] items-start justify-end text-center pr-[10px] mt-2">
                    {optionOpen &&
                      <Select
                        style={{
                          borderRadius: '8px',
                          textAlignalign: "left"
                        }}
                        className="min-w-[220px] max-w-[750px] min-h-[24px] mr-[15px] text-left overflow-x-auto"
                        name={"scenario_config"}
                        placeholder={"Select scenario"}
                        mode='multiple'
                        value={selectOptionScenario}
                        onChange={(value) => handleOptionChangeScenario("scenario_config", value)}
                        tagRender={tagRender}
                      >
                        <Option value="" disabled selected>Select scenario</Option>
                        {dataOptionScenario.map((scenario, index) => (
                          <Option
                            key={index}
                            value={scenario.name}
                            disabled={selectOptionScenario.length >= 5 && !selectOptionScenario.includes(scenario.name)}
                          >
                            {scenario.name} ({scenario.date})
                          </Option>
                        ))}
                      </Select>
                    }
                    {!optionOpen && (
                      <Tooltip placement="bottom" title={"expand options"} color={"#662D91"}>
                        <CaretDownOutlined
                          onClick={() => handleChangeOptionOpen()}
                          className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                        />
                      </Tooltip>
                    )}
                    {optionOpen && (
                      <Tooltip placement="bottom" title={"hide options"} color={"#662D91"}>
                        <CaretLeftOutlined
                          onClick={() => handleChangeOptionOpen()}
                          className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                        />
                      </Tooltip>
                    )}
                    <Tooltip placement="bottom" title={"save calculations"} color={"#662D91"}>
                      <SaveNavbar
                        onClick={() => handleCLickSaveChart()}
                        className="flex w-6 h-6 mr-4 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
                      />
                    </Tooltip>
                  </div>
                  <div className="w-full h-[950px]">
                    <ChartVLP
                      activeChecks={activeChecks}
                      dataPoints={dataTableGauge}
                      dataPoint2={inputDataTuning}
                      dataPoint3={queryPressDrowDown?.data}
                      dataTableLine={dataTableGraphVLP}
                      dataTableLine2={dataTableGraphUntVLP}
                      domainMaxDepth={maxDepth}
                      domainMinDepth={minDepth}
                      domainMaxPressure={maxPressure}
                      domainMinPressure={minPressure}
                      tuningnNav={true}
                      selectedScenario={selectOptionScenario}
                      dataScenario={dataScenarioVLP}
                    >
                    </ChartVLP>
                  </div>
                  <div className='flex flex-row items-center justify-center w-full gap-2'>
                    <div className='w-4 h-1 bg-[#FE3F40]'></div>
                    <p className='text-bc-grey text-[16px]'>Tuned FBHP</p>
                    <div className='w-4 h-1 bg-[#253fce]'></div>
                    <p className='text-bc-grey text-[16px]'>Untuned FBHP</p>
                    {(!!dataTableGauge) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#1e1b4b]'></div>
                        <p className='text-bc-grey text-[13px]'>Gauge Pressure</p>
                      </>
                    )}
                    {(queryDataTuning?.data?.mid_perf_tvd_depth && queryDataTuning?.data?.reservoir_pressure) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#684200]'></div>
                        <p className='text-bc-grey text-[13px]'>Reservoir Pressure</p>
                      </>
                    )}
                    {(queryPressDrowDown?.data?.mid_perf_tvd_depth && queryPressDrowDown?.data?.pwf_user) && (
                      <>
                        <div className='rounded-full w-3 h-3 bg-[#009130]'></div>
                        <p className='text-bc-grey text-[13px]'>FBHP at Perforations</p>
                      </>
                    )}
                  </div>
                </Modal>
              </div>
            )}
            <Modal
              title="Save Scenario"
              centered
              open={openSave}
              onOk={handleSaveDataChart}
              onCancel={() => {
                setOpenSave(false);
                setInputEmpty(false);
                setInputScenarioInformation(INITIAL_DATA_SCENARIO_CHART);
              }}
              width={1000}
              className="bg-[#662D91] rounded-md"
              okButtonProps={{
                className: "bg-[#662D91]",
              }}
            >
              <div className="flex flex-col items-center justify-center">
                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-left text-[15px] mb-[10px]"
                >
                  Name
                </label>
                <div className="flex justify-center w-full">
                  <input
                    type="text"
                    name="name_scenario"
                    value={inputScenarioInformation.name ?? ""}
                    onChange={handleChangeScenario}
                    className={`w-full max-w-[592px] h-[47px] rounded-[4px] border border-solid px-3
                      ${inputScenarioInformation.name === null || (inputEmpty && !inputScenarioInformation.name)
                        ? "border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                      }  
                    `}
                  />
                </div>

                <label
                  htmlFor=""
                  className="w-full max-w-[592px] text-left text-[15px] mb-[10px] mt-[20px]"
                >
                  Date
                </label>
                <div className="flex justify-center w-full">
                  <input
                    type="date"
                    name="date_scenario"
                    value={inputScenarioInformation.date ?? ""}
                    onChange={handleChangeScenario}
                    className={`w-full max-w-[592px] h-[47px] rounded-[4px] border border-solid px-3
                      ${inputScenarioInformation.date === null || (inputEmpty && !inputScenarioInformation.date)
                        ? "border-red-700 bg-yellow-100"
                        : "border-[#BCBCCC]"
                      }  
                    `}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="flex flex-col w-full h-[40px] text-right items-end">
          {selectedWell === null && (
            <>
              <h1>Select Well</h1>
            </>
          )}
          {selectedWell !== null && (
            <>
              {emptyTuningData  && (
                <>
                  <button
                    className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickDone()}
                  >
                    Done
                  </button>
                </>
              )}

              { !emptyTuningData  && (
                <div className="flex flex-col gap-5">
                  <button
                    hidden={true}
                    className="w-[180px] h-[40px] mr-[10px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickClean()}
                  >
                    Clean
                  </button>
                  <button
                    className="w-[180px] h-[40px] bg-pa-purple text-white rounded-md"
                    onClick={() => handleClickEdit()}
                  >
                    Save
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
