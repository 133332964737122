import React, { useEffect, useState } from 'react';
import { CartesianGrid, ComposedChart, Line, ReferenceArea, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis } from 'recharts';
import { useTabDCA } from '../../../hooks/useTabDCA';
import moment from 'moment';
import { Alert, Modal } from 'antd';

  export const ChartForecasting = ({table, updateMonths, current, status, updateStatus, dataDCA, stateEmpty, updateData }) => {
    
    const { dataForecast, refetchDataForecast, extractTitle } = useTabDCA(table);
    const { sensibility, period } = extractTitle(table);
    const [selectionStart, setSelectionStart] = useState(null);

    useEffect(() => {
      
      if(status && !!dataForecast ){
        refetchDataForecast();
      }

    }, [table, refetchDataForecast, status, current ])

    const prepareChartData = () => {
      if (!dataForecast) {
        return { blackPoints: [], redLine: [] };
      }
    
      const blackPoints = dataForecast?.data
        ?.filter(item => 
          item.y_plot !== null && 
          item.y_plot !== -999.25 && 
          !isNaN(Number(item.y_plot))
        )
        ?.map(item => ({
          x: item.x_plot_true,
          y: Number(Number(item.y_plot).toFixed(2))
        }));
    
      const redLine = dataForecast.data
        ?.filter(item => 
          item.y_plot_calc !== null && 
          item.y_plot_calc !== -999.25 && 
          !isNaN(Number(item.y_plot_calc))
        )
        ?.map(item => ({
          x: item.x_plot_true,
          y: Number(Number(item.y_plot_calc).toFixed(2))
        }));
    
      return { blackPoints, redLine };
    };
    
    const { blackPoints, redLine } = prepareChartData();    
    
    function renderTooltip ({ active, payload, label}) {

      if (active && payload && payload.length) {
        const blackVal = payload[0].payload;
        const blaackP = blackVal['y'];

        const dotVal = payload[1].payload
        const dotP = dotVal['y']

        return (
          <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
            <p >{`Month: ${label}`}</p>
              <div style={{ flex: 1 }}>
                {blackVal && (
                  <p style={{ color: '#ff0000' }}>
                    {`Oil Rate Calculated: ${blaackP}`}
                  </p>
                )}
              </div>
              <div style={{ flex: 1 }}>
                {dotVal && (
                  <p style={{ color: '#000000' }}>
                    {`Oil Rate History: ${dotP}`}
                  </p>
                )}
              </div>
          </div>
        );
      }
      return null;
    };
    
    const [state, setState] = useState({
      data: redLine,
      left: 'dataMin',
      right: 'dataMax',
      refAreaLeft: '',
      refAreaRight: '',
      top: 'dataMax+50',
      bottom: 'dataMin',
      animation: true,
    });

    const getAxisYDomain = (from, to, ref, offset) => {
      const dataValidateAxisY = [...redLine, ...blackPoints];
      const refData = dataValidateAxisY?.slice(from - 1, to + 2);
      
      let [bottom, top] = [Number(refData[0][ref]), Number(refData[0][ref])];
  
      refData.forEach((d) => {
          const value = Number(d[ref]); 
  
          if (value > top) top = value;
          if (value < bottom) bottom = value;
      });
      
      return [bottom - 5, top + offset];
    };
  
    const zoomIn = () => {
      let { refAreaLeft, refAreaRight } = state;
      const { data } = state;
      
      if (refAreaLeft === refAreaRight || refAreaRight === '') {
        setState((prevState) => ({
          ...prevState,
          refAreaLeft: '',
          refAreaRight: '',
        }));
        return;
      }
   
      if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
      
      const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'y', 100);
      
      // Filtrar datos dentro del rango de 'bottom' y 'top' para visualización
      const filteredData = data.filter((d) => d.y >= bottom && d.y <= top && d.x >= refAreaLeft && d.x <= refAreaRight);
      
      setState((prevState) => ({
        ...prevState,
        refAreaLeft: '',
        refAreaRight: '',
        data: filteredData,
        left: refAreaLeft,
        right: refAreaRight,
        bottom: Math.floor(bottom),  
        top: Math.ceil(top)
      }));
   };
    
    const zoomOut = () => {
      setState({
        data: redLine.slice(),
        refAreaLeft: '',
        refAreaRight: '',
        left: 'dataMin',
        right: 'dataMax',
        top: 'dataMax+50',
        bottom: 'dataMin',
      });
    };

    const handleClick = (e) => {
      
      if (!selectionStart && current === 4) {
        setSelectionStart(e.activeLabel);
        const months = {
          initialMonth: e.activeLabel,
          endMonth: "",
          name: "start_select"
        }
        updateMonths(months);
      } else if (current === 4) {
        // Segundo clic: guardar el punto de fin y actualiza el rango
        const selectionEnd = e.activeLabel;
        const months = {
          initialMonth: selectionStart,
          endMonth: selectionEnd,
          name: "end_select"
        }
        updateMonths(months);

        // Reiniciar la selección
        setSelectionStart(null);
      }
    };

    const handleCLickModalTable = (name) => {
      updateStatus(name)
    }

    const handleUpdateData = (e) => {
      updateData(e)
    }
    
    const { data, left, right, refAreaLeft, refAreaRight, top, bottom } = state;
    
    return (
      <div className="flex w-full h-full" style={{ userSelect: 'none'}}>
        <div className="flex flex-col w-full">
        <Modal
          centered
          open={status}
          onOk={() => handleCLickModalTable("periodSelection")}
          onCancel={() => handleCLickModalTable("periodSelection")}
          width={1200}
          className="rounded-md mt-[10px] mb-[10px]"
          okButtonProps={{
            className: "bg-[#662D91]",
          }}
        >
          { status && (
            <>
              <div className={`flex flex-col w-full h-[700px] text-start font-bold text-[15px] mb-[7px] col-span-2 `}>
                <h1 className="text-center font-bold text-lg p-2">{`Sensibility: ${sensibility} - Period: ${period}`}</h1>
                <button type="button" className="flex w-[80px] h-[20px] bg-pa-purple text-white rounded-md hover:bg-pc-purple text-center items-center justify-center text-[12px]" onClick={zoomOut}>
                  Zoom Out
                </button>
                <ResponsiveContainer width="100%" height="80%">
                  <ComposedChart
                    data={data}
                    onMouseDown={(e) => setState((prevState) => ({ ...prevState, refAreaLeft: e.activeLabel }))}
                    onMouseMove={(e) => refAreaLeft && setState((prevState) => ({ ...prevState, refAreaRight: e.activeLabel }))}
                    onMouseUp={zoomIn}
                    onClick={handleClick}
                    margin={{ top: 20, right: 30, left: 20, bottom: 25 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      type='number'
                      domain={[left, right]} 
                      allowDataOverflow={true}
                      dataKey="x"
                      label={{
                        value: `Time (Month)`,
                        style: { textAnchor: 'middle' },
                        position: 'bottom',
                      }}
                    />
                    <YAxis
                      type="number"
                      allowDataOverflow
                      domain={[bottom, top]}
                      yAxisId="1"
                      label={{
                        value: `Oil Rate (STB/D)`,
                        style: { textAnchor: 'middle' },
                        angle: -90,
                        position: 'left',
                        offset: 0,
                      }}
                    />
                    <Tooltip content={renderTooltip} />
                    <Line type="monotone" yAxisId="1" dataKey="y" name="Oil Rate Calculated" data={redLine} stroke="#ff0000" strokeWidth={2} dot={false} animationDuration={300}/>
                    <Scatter   yAxisId="1" dataKey="y" name="Oil Rate History" data={blackPoints} fill="#000000" radius={1} animationDuration={300}/>
                    {refAreaLeft && refAreaRight ? (
                      <ReferenceArea  yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                    ) : null}
                  </ComposedChart>
                </ResponsiveContainer>
                <div className="grid grid-cols-2" >
                  <div className="flex flex-col w-[480px] w-full text-right items-start">
                    <label className="flex w-full max-w-[592px] text-start font bold text-[15px] mt-[10px] mb-[7px]">
                      Fluid rate at the beginning of the custom period 
                    </label>
                    <input
                      type="text"
                      name="start_select"
                      value={dataDCA.start_select}
                      onChange={handleUpdateData}
                      className={`w-full max-w-[380px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                        stateEmpty && dataDCA.start_select === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border border-solid border-[#BCBCCC]"
                      } `}
                    />
                  </div>
                  <div className="flex flex-col w-[480px] w-full text-right items-start">
                    <label className="flex w-full max-w-[520px] text-start font bold text-[15px] mt-[10px] mb-[7px]">
                      Fluid rate at the end of the custom period
                    </label>
                    <input
                      type="text"
                      name="end_select"
                      value={dataDCA.end_select}
                      onChange={handleUpdateData}
                      className={`w-full max-w-[380px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                        stateEmpty && dataDCA.end_select === ""
                          ? " border-red-700 bg-yellow-100"
                          : "border border-solid border-[#BCBCCC]"
                      } `}
                      disabled={dataDCA.start_select === "" ? true : false} 
                    />
                  </div>
                </div>
              </div>
            </>
            )
          }
        </Modal>  
        </div>
      </div>
    );
  };
  
  export default ChartForecasting;