import { message } from "antd";
import React, { useState } from "react";
import { AlertModal } from "../AlertModal";
import { postClients, postProjects, postPlatforms, postRoles } from "../../services/api/usersAPI";
import { useUsers } from "../../hooks/useUsers";

const AddRegister = ({Data, name, dataTable}) => {
  
  const [inputValue, setInputValue] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState(false);

  const handleInputChange = (event) => {
    const data = event.target.value.toLowerCase();
    const pattern = /^[a-zA-Z0-9\s-_]*$/;

    if (pattern.test(data)) {
      const transformedData = data.replaceAll(' ', '_');
      setInputValue(transformedData);
    } else {
      handleOpenModal();
    }
  };

  const handleAddOption = async (event) => {
    event.preventDefault();

    const newIdClient = Data?.length > 0 ? Data[Data.length - 1].idclient + 1 : 1;
    if(inputValue !== ""){
      const isOptionExists = validateExist()
      
      // const isOptionExists = Data.some((option) => option.client === inputValue);
      if (isOptionExists) {
        message.info("The option already exists. Please choose another name");
      }
      else {
        const bodyTableClient = constructBody(newIdClient, inputValue)
        
        dataTable([...Data, bodyTableClient]);
        setInputValue("");
      }
    } else (
      message.info("Please insert information")
    )
  };

  const validateExist = () => {
    switch (name) { // entityType puede ser una variable o estado que indica si es un cliente, proyecto, etc.
      case "clients":
        return Data.some((option) => option.client === inputValue);
      case "projects":
        return Data.some((option) => option.toLowerCase() === inputValue.toLowerCase());
      case "platforms":
        return Data.some((option) => option.toLowerCase() === inputValue.toLowerCase());
      case "role":
        return Data.some((option) => option.toLowerCase() === inputValue.toLowerCase()); ;
      default:
        throw new Error("Unknown entity type");
    }
  };

  const constructBody = (newIdClient, inputValue) => {
    switch (name) { // entityType puede ser una variable o estado que indica si es un cliente, proyecto, etc.
      case "clients":
        return { idclient: newIdClient, client: inputValue };
      case "projects":
        return inputValue;
      case "platforms":
        return inputValue ;
      case "role":
        return inputValue; 
      default:
        throw new Error("Unknown entity type");
    }
  };

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };
  
  return (
    <>
      <div className="flex w-full h-full">
      <AlertModal showAlert={showAlert} handleCloseModal={handleCloseModal} messageAlert={messageAlert} typeModal={typeAlert}></AlertModal>
      </div>
      <div className="flex flex-row w-full justify-start items-center">
        <div className="flex flex-row w-full text-start gap-3">
          <input
            onKeyUp={handleInputChange}
            onChange={handleInputChange}
            value={inputValue}
            placeholder="  Enter a new Register"
            type="text"
            className="w-full h-[47px] rounded-[4px] border border-solid border-[#BCBCCC]"
          />
          <button 
            className="w-[60px] h-[46px] bg-pa-purple text-white rounded-md" 
            onClick={handleAddOption}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

export default AddRegister;
