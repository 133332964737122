import React, {useEffect, useRef} from 'react'

export const ChartCrossOilGor = ({ graphicRank, graphicDepth, enable }) => {
  
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const graphic = enable === "3drank" ? graphicRank : graphicDepth;
      divRef.current.innerHTML = graphic;
      
      // Evaluate scripts in HTML content
      const scripts = divRef.current.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; i++) {
        const script = document.createElement('script');
        script.textContent = scripts[i].textContent;
        document.body.appendChild(script);
        document.body.removeChild(script); // Remove it to avoid duplicates
      }
    }
  }, [graphicRank, graphicDepth, enable]);
 
  return (
    <div className='w-full h-full'>
      <div 
        ref={divRef}
      />
    </div>
  );
};