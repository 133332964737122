export const styleIconSideBar = "w-8 h-6 fill-neutral-500"

export const PATH_PRODUCT = {
	emerson: {
		nav: "https://www.zediaccess.com/Logon",
		name: "EMERSON"
	},
	ada_bi: {
		nav: "https://drillbi-rollout.enovate.app/Login",
		name: "ADA BI Drilling"
	},
	ada_do: {
		nav: "https://drilloptim-rollout.enovate.app",
		name: "ADA DO Optim"
	},
	b4carbon: {
		nav: "https://auth-uat.blockchainforenergy.net/u/login?state=hKFo2SBpdUlFSXpqeFVmNmoxWHZHdkJHX3dKMTFoa3ZGNi1acaFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIE5pbnp4MWFKa0lFS3JGMGU2QUVVdEc2N2hEb1V5WTk3o2NpZNkgVjRPaVliamZSVjhpUGtLcGVZa0pzaHkxOE9NNkpRNG4",
		name: "B4ECarbon" },
	Kuva: {
		nav: "https://app.kuva.cloud/#/",
		name: "KUVA"
	},
}

export const MODULE_SIDEBAR = {
	map: "Map",
	dca: "Forecasting",
	gas_lift: "Lifting Optimization",
	plotting: "Real Time Trending",
	screening_tool: "Field AI Performance",
	plunger_lift: "Plunger Lift Digital Twin",
	well_injector: "Well Injector",
	register: "Register",
	internal: "Internal",
	external: "External",
	control: "Control"
}
