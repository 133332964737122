import L from 'leaflet'

export const IconLocation = L.icon({
    iconSize: [30, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],                                                          
    iconUrl: 'data:image/svg+xml;base64,' + btoa(`
      <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <defs>
          <style>
            .cls-1 {
              fill: #5076ff;
            }
          </style>
        </defs>
        <path class="cls-1" d="M8,16s6-5.69,6-10c0-3.31-2.69-6-6-6S2,2.69,2,6c0,4.31,6,10,6,10M8,9c-1.66,0-3-1.34-3-3s1.34-3,3-3,3,1.34,3,3-1.34,3-3,3"/>
      </svg>
    `),
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});