import React, { useContext, useState } from "react";
import { Collapse, initTE, Dropdown } from "tw-elements";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DatePicker, Tooltip, Modal, message, Select } from "antd";
import { AuthContext } from "../../auth/context/AuthContext";
import { DashboardContext } from "../../context/Dashboard/DashboardContentx";
import { ReactComponent as SaveNavbar } from "../../assets/img/icons/save.svg";
import { ReactComponent as SettingNavbar } from "../../assets/img/icons/setting.svg";
import { ReactComponent as AddNavbar } from "../../assets/img/icons/add.svg";
import { ReactComponent as AddFilter } from "../../assets/img/icons/filterUser.svg";
import { ReactComponent as ReturnPage } from "../../assets/icon/return.svg";
import { ReactComponent as TuningNavbar } from "../../assets/img/icons/tuning.svg";
import { ReactComponent as AddParameter } from "../../assets/img/icons/assing.svg";
import { useFilters } from "../../hooks/useFilters";
import { useLocation } from "react-router-dom";
import {
	STATUS_TYPE_WELL_GASLIFT,
	STATUS_TYPE_WELL_DCA,
	STATUS_TYPE_PLUNGER_LIFT,
	STATUS_TYPE_INJECTOR,
} from "../elements/types/type_response_API";
import { ChevronDown } from "react-bootstrap-icons";
import { useTabGaslift } from "../../hooks/useTabGaslift";
import { useTabDCA } from "../../hooks/useTabDCA";
import { useTabPlungerLift } from "../../hooks/useTabPlungerLift";
import { FilterUser } from "../Dashboard/Filters/FilterUser";
import { ParametersConfig } from "../User_Control/Parameters/ParametersConfig";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { DashboardPLContext } from "../../context/DashboardPlungerLift/DashboardPLContext.jsx";
import { DashboardSCTContext } from "../../context/DashboardSCT/DasboardSCTContext";
import { DashboardDCAContext } from "../../context/DashboardDCA/DashboardDCAContext.jsx";
import { DataGasLiftContext } from "../../context/DataGasLift/DataGasLiftContext";
import { useTabIOT } from "../../hooks/useTabIOT";
import { DashboardPlottingContext } from "../../context/DashboardPlotting/DashboardPlottingContext";
import { useTabPlotting } from "../../hooks/useTabPlotting";
import { NAME_PAGES } from "../elements/types/type_pages";
import { AlertModalOption } from "../AlertModalOption.jsx";
import {
	DATA_CREATE_WELL_GAS_LIFT,
	DATA_DOMAIN_GRAPH,
	TYPE_FLUID_TYPE_WELL,
} from "../elements/types/type_gas_lift.js";
import { DATA_WELL_PLUNGER_LIFT } from "../elements/types/type_plunger_Lift.js";
import { DATA_END_PARAMETERS as DATA_END_PARAMETERS_PLUNGERLIFT } from "../elements/types/type_plunger_Lift.js";
import { DATA_END_PARAMETERS as DATA_END_PARAMETERS_GASLIFT } from "../elements/types/type_gas_lift.js";
import {
	AddKeysMissing,
	AddKeysMissingDomain,
	isObjectNotEmpty,
} from "../../helpers/helpersValidate.js";
import { NAVBAR_TOOLTIP } from "../elements/types/type_tooltip.js";
import { USER_FILTER, WELL_FILTER } from "../elements/types/type_dashboard.js";
import {
	DATA_CREATE_WELL_DCA,
	DATA_DOMAIN_GRAPH_DCA,
} from "../elements/types/type_dca.js";
import {
	DATA_CREATE_WELL_INJECTOR,
	DATA_DOMAIN_GRAPH_INJECTOR,
} from "../elements/types/type_injector.js";
import { DashboardInjectorContext } from "../../context/DashboardInjector/DashboardInjectorContext.jsx";
import { useTabTuning } from "../../hooks/useTabTuning.jsx";
import { flattenObject } from "../utils/utils.js";
import { useTabInjector } from "../../hooks/useTabInjector.jsx";

// const { RangePicker } = DatePicker;

export const Navbar = ({ wellboreOption, addwellOption }) => {
	const { Option } = Select;
	const { refetchDataListDevicesDCA, refetchDataListDevicesGaslift } =
		useTabIOT();
	const { dataParamsPlotting, addParameters, modifyParameters } =
		useTabPlotting();
	const { dataPlotting } = useContext(DashboardPlottingContext);
	const { signout } = useContext(AuthContext);
	const {
		dataPage,
		dataDomainGraph,
		dataDomainGraphPL,
		dataDomainGraphDCA,
		dataDomainGraphInjector,
		dataAlertInjector,
		openFirstModal,
		toggleOpenFirstModal,
		selectedWell,
		selectedWellDCA,
		selectedWellInjector,
		dataAlertDCA,
		dataAlertGasLift,
		dataAlertPlungerLift,
		getSomeAlert,
		someAlert,
		deviceRealTime,
		checkTypeGraph,
		selectedReservoirSCT,
		selectedWellPL,
		getSelectedWell,
		getSelectedWellDCA,
		getSelectedWellInjector,
		getSelectedReservoirSCT,
		getSelectedWellPL,
		slideGraph,
	} = useContext(DashboardContext);
	const {
		validateAlertGasLift,
		getValidateAlertGasLift,
		dataFilterUserGasLift,
	} = useContext(DataGasLiftContext);
	const {
		selectDashboardPL,
		validateAlertPlungerLift,
		getValidateAlertPlungerLift,
		dataFilterUserPL,
	} = useContext(DashboardPLContext);
	const { dataFilterUserDCA, getValidateAlertDCA, validateAlertDCA } =
		useContext(DashboardDCAContext);
	const {
		dataFilterUserInjector,
		getValidateAlertInjector,
		validateAlertInjector,
	} = useContext(DashboardInjectorContext);
	const { dataFilterUserSCT } = useContext(DashboardSCTContext);
	const {
		User,
		userDataInfo,
		refetchGetDataFilter,
		dataFilter,
		mutationUpdateFilter,
		getUserDataInfo,
	} = useFilters();
	const { dataWellGasLift, mutationUpdateDataGasLift } = useTabGaslift();
	const { dataWellDCA, mutationUpdateJsonWellDCA } = useTabDCA();
	const { mutationUpdateWellPlungerLift, dataWellPL } = useTabPlungerLift();
	const { mutationUpdateDataInjector, dataWellInjector } = useTabInjector();

	const location = useLocation();
	const Navigate = useNavigate();
	const { confirm } = Modal;
	const dataGetWellGasLift =
		dataWellGasLift?.statusType === STATUS_TYPE_WELL_GASLIFT.SUCCESS &&
		dataFilter !== null &&
		isObjectNotEmpty(dataWellGasLift?.data)
			? dataWellGasLift?.data
			: null;
	const dataGetWellDCA =
		dataWellDCA?.statusType === STATUS_TYPE_WELL_DCA.SUCCESS &&
		dataFilter !== null &&
		isObjectNotEmpty(dataWellDCA?.data)
			? dataWellDCA?.data
			: null;
	const dataGetWellInjector =
		dataWellInjector?.statusType === STATUS_TYPE_INJECTOR.SUCCESS &&
		dataFilter !== null &&
		isObjectNotEmpty(dataWellInjector?.data)
			? dataWellInjector?.data
			: null;
	const dataGetWellPlungerLift =
		dataWellPL?.statusType === STATUS_TYPE_PLUNGER_LIFT.SUCCESS &&
		dataFilter !== null &&
		isObjectNotEmpty(dataWellPL?.data)
			? dataWellPL?.data
			: null;
	const [showAlert, setShowAlert] = useState(false);
	const [messageAlert, setMessageAlert] = useState(false);
	const [typeAlert, setTypeAlert] = useState(false);
	const [sectionSave, setSectionSave] = useState("");
	const [titleWell, setTitleWell] = useState("");

	const dataFilterUser =
		dataFilter?.statusType !== "ERROR" ? dataFilter?.data : null;

	useEffect(() => {
		initTE({ Collapse, Dropdown });
		refetchGetDataFilter();
	}, []);

	useEffect(() => {
		if (dataPage === NAME_PAGES.DCA.dashboard && !!selectedWellDCA) {
			refetchDataListDevicesDCA();
		}
		if (dataPage === NAME_PAGES.gas_lift.dashboard && !!selectedWell) {
			refetchDataListDevicesGaslift();
		}
	}, [dataPage]);
	useEffect(() => {
		if (dataFilterUser === null) {
			getUserDataInfo(USER_FILTER);
			toggleOpenFirstModal("filters");
			return;
		}
		if (!dataFilterUser) return;
		const { operator, lease, region } = dataFilterUser;
		const isComplete =
			operator &&
			operator !== "" &&
			lease &&
			lease !== "" &&
			region &&
			region !== "";
		if (isComplete) {
			getUserDataInfo({
				...userDataInfo,
				/* ...dataFilterUser, */ // !esto queda para cuando se modifique el proceso de checks
				operator: dataFilterUser?.operator,
				lease: dataFilterUser?.lease,
				region: dataFilterUser?.region,
			});
		}

		const dataFilterMap = {
			checkedWellDCA: getSelectedWellDCA,
			checkedWellGaslift: getSelectedWell,
			checkedWellScreeningTool: getSelectedReservoirSCT,
			checkedWellPlungerLift: getSelectedWellPL,
			checkedWellInjector: getSelectedWellInjector,
		};

		const selectedValues = {
			selectedWell,
			selectedWellPL,
			selectedReservoirSCT,
			selectedWellDCA,
			selectedWellInjector,
		};

		const hasValidSelections = Object.values(selectedValues).some(
			(value) => value !== null && value !== undefined,
		);

		if (dataFilterUser && hasValidSelections) {
			Object.entries(dataFilterMap).forEach(([key, func]) => {
				if (
					dataFilterUser[key] != null &&
					dataFilterUser[key] !== "" &&
					selectedValues[key] !== null &&
					selectedValues[key] === undefined
				) {
					func(dataFilterUser[key]);
				}
			});
		}
	}, [dataFilterUser]);

	useEffect(() => {
		const validationExistWell = {
			plunger_lift:
				dataPage === NAME_PAGES.plunger_lift.list_well &&
				location.pathname !==
					`/${NAME_PAGES.plunger_lift.list_well}/${NAME_PAGES.plunger_lift.create_well}` &&
				dataFilterUserPL?.well_name &&
				dataFilterUserPL?.well_id
					? `Well: ${dataFilterUserPL?.well_name} - ${dataFilterUserPL?.well_id}`
					: "",
			gas_lift:
				dataPage === NAME_PAGES.gas_lift.list_well &&
				location.pathname !==
					`/${NAME_PAGES.gas_lift.list_well}/${NAME_PAGES.gas_lift.create_well}` &&
				dataFilterUserGasLift?.well_name &&
				dataFilterUserGasLift?.well_id
					? `Well: ${dataFilterUserGasLift?.well_name} - ${dataFilterUserGasLift?.well_id}`
					: "",
			screening_tool:
				dataPage !== NAME_PAGES.screening_tool.list_well &&
				dataPage === NAME_PAGES.screening_tool.dashboard &&
				dataFilterUserSCT?.reservoir_name &&
				dataFilterUserSCT?.reservoir_id
					? `Reservoir: ${dataFilterUserSCT?.reservoir_name} - ${dataFilterUserSCT?.reservoir_id}`
					: "",
			dca:
				dataPage === NAME_PAGES.DCA.list_well &&
				location.pathname !==
					`/${NAME_PAGES.DCA.list_well}/${NAME_PAGES.DCA.create_well}` &&
				dataFilterUserDCA?.well_name &&
				dataFilterUserDCA?.well_id
					? `Well: ${dataFilterUserDCA?.well_name} - ${dataFilterUserDCA?.well_id}`
					: "",
			injector:
				dataPage === NAME_PAGES.well_injector.list_well &&
				location.pathname !==
					`/${NAME_PAGES.well_injector.list_well}/${NAME_PAGES.well_injector.create_well}` &&
				dataFilterUserInjector?.well_name &&
				dataFilterUserInjector?.well_id
					? `Well: ${dataFilterUserInjector?.well_name} - ${dataFilterUserInjector?.well_id}`
					: "",
		};

		const valuesWithText = Object.values(validationExistWell).filter(
			(value) => value !== "",
		);
		setTitleWell(valuesWithText[0]);
	}, [
		dataPage,
		dataFilterUserGasLift,
		dataFilterUserPL,
		dataFilterUserSCT,
		dataFilterUserDCA,
	]);

	const handleSave = () => {
		//Validacion de alarmas y dominios dashboard
		const validateAlertsDCA = validateEmptyAlerts(dataAlertDCA);
		const validateDomainsDCA = validateEmptyDomains(dataDomainGraphDCA);
		const validateAlertsGL = validateEmptyAlerts(dataAlertGasLift);
		const validateDomainsGL = validateEmptyDomains(dataDomainGraph);
		const validateAlertsPL = validateEmptyAlerts(dataAlertPlungerLift);
		const validateDomainsPL = validateEmptyDomains(dataDomainGraphPL);
		const validateDomainsInjector = validateEmptyDomains(dataDomainGraphInjector);
		const validateAlertsInjector = validateEmptyAlerts(dataAlertInjector);

		if (dataPage === NAME_PAGES.gas_lift.dashboard) {
			if (validateAlertsGL.length > 0 || validateDomainsGL.length > 0) {
				const updatedAlertGasLift = {
					...validateAlertGasLift,
					alertCard:
						validateAlertsGL.length > 0 ? true : validateAlertGasLift.alertCard,
					alertDomain:
						validateDomainsGL.length > 0 ? true : validateAlertGasLift.alertDomain,
				};
				getValidateAlertGasLift(updatedAlertGasLift);
				setShowAlert(true);
				setTypeAlert("Warning");
				setMessageAlert(
					"There are empty domains and alerts. Do you want to continue saving?",
				);
				setSectionSave("Gas Lift");
			} else {
				saveSectionData("Gas Lift");
			}
		} else if (dataPage === NAME_PAGES.DCA.dashboard) {
			if (validateAlertsDCA.length > 0 || validateDomainsDCA.length > 0) {
				const updatedAlertDCA = {
					...validateAlertDCA,
					alertCard:
						validateAlertsDCA.length > 0 ? true : validateAlertDCA.alertCard,
					alertDomain:
						validateDomainsDCA.length > 0 ? true : validateAlertDCA.alertDomain,
				};
				getValidateAlertDCA(updatedAlertDCA);
				setShowAlert(true);
				setTypeAlert("Warning");
				setMessageAlert(
					"There are empty domains and alerts. Do you want to continue saving?",
				);
				setSectionSave("DCA");
			} else {
				saveSectionData("DCA");
			}
		} else if (dataPage === NAME_PAGES.well_injector.dashboard) {
			if (
				validateAlertsInjector.length > 0 ||
				validateDomainsInjector.length > 0
			) {
				const updatedAlertInjector = {
					...validateAlertInjector,
					alertCard:
						validateAlertsInjector.length > 0
							? true
							: validateAlertInjector.alertCard,
					alertDomain:
						validateDomainsInjector.length > 0
							? true
							: validateAlertInjector.alertDomain,
				};
				getValidateAlertInjector(updatedAlertInjector);
				setShowAlert(true);
				setTypeAlert("Warning");
				setMessageAlert(
					"There are empty domains and alerts. Do you want to continue saving?",
				);
				setSectionSave("Injector");
			} else {
				saveSectionData("Injector");
			}
		} else if (
			dataPage === NAME_PAGES.gas_lift.list_well ||
			dataPage === NAME_PAGES.DCA.list_well ||
			dataPage === NAME_PAGES.screening_tool.list_well ||
			dataPage === NAME_PAGES.plunger_lift.list_well
		) {
			if (
				userDataInfo &&
				userDataInfo?.operator !== "" &&
				userDataInfo?.lease !== "" &&
				userDataInfo?.region !== ""
			) {
				const dataFilterCreate = Object.assign({}, userDataInfo, {
					checkedWellDCA: selectedWellDCA ? selectedWellDCA : "",
					checkedWellGaslift: selectedWell ? selectedWell : "",
					checkedWellScreeningTool: selectedReservoirSCT ? selectedReservoirSCT : "",
					checkedWellPlungerLift: selectedWellPL ? selectedWellPL : "",
				});

				setTimeout(() => {
					mutationUpdateFilter.mutate({
						user: User.user_name,
						data: dataFilterCreate,
					});
				}, 500);
			}
		} else if (dataPage === NAME_PAGES.plotting.dashboard) {
			if (
				userDataInfo &&
				userDataInfo?.operator !== "" &&
				userDataInfo?.lease !== "" &&
				userDataInfo?.region !== ""
			) {
				if (
					!dataPlotting ||
					dataPlotting.selected_platform === "" ||
					dataPlotting.selected_device.length === 0 ||
					dataPlotting.selected_parameter.length === 0 ||
					dataPlotting.dataPlotting.length === 0
				) {
					message.error("Please validate the form information.");
					return;
				}
				if (dataParamsPlotting?.data?.data) {
					modifyParameters.mutate({
						operator: userDataInfo.operator,
						lease: userDataInfo.lease,
						region: userDataInfo.region,
						user: User?.user_name,
						data: dataPlotting,
					});
				} else {
					addParameters.mutate({
						operator: userDataInfo.operator,
						lease: userDataInfo.lease,
						region: userDataInfo.region,
						user: User?.user_name,
						data: dataPlotting,
					});
				}
			}
		} else if (dataPage === NAME_PAGES.plunger_lift.dashboard) {
			if (validateAlertsPL.length > 0 || validateDomainsPL.length > 0) {
				const updateAlertPlungerLift = {
					...validateAlertPlungerLift,
					alertCard:
						validateAlertsPL.length > 0 ? true : validateAlertPlungerLift.alertCard,
					alertDomain:
						validateDomainsPL.length > 0
							? true
							: validateAlertPlungerLift.alertDomain,
				};
				getValidateAlertPlungerLift(updateAlertPlungerLift);
				setShowAlert(true);
				setTypeAlert("Warning");
				setMessageAlert("There are empty alerts. Do you want to continue saving?");
				setSectionSave("Plunger Lift");
			} else {
				saveSectionData("Plunger Lift");
			}
		}
	};

	const handleCLickExpand = (option) => {
		toggleOpenFirstModal(option);
	};

	const logOut = async (e) => {
		signout();
	};
	const closeDataFilter = () => {
		if (openFirstModal[0].enabled && openFirstModal[0].modal === "filters") {
			toggleOpenFirstModal("filters");
		} else if (
			openFirstModal[1].enabled &&
			openFirstModal[1].modal === "parameters"
		) {
			toggleOpenFirstModal("parameters");
		}
	};

	const handleConfirm = (link) => {
		const selectedWells = {
			[NAME_PAGES.gas_lift.dashboard]: selectedWell,
			[NAME_PAGES.DCA.dashboard]: selectedWellDCA,
			[NAME_PAGES.plunger_lift.dashboard]: selectedWellPL,
		};
		const validationWell = !!selectedWells[dataPage];

		if (validationWell) {
			confirm({
				title: `Do you want to save the changes of the alarm's thresholds?`,
				icon: <ExclamationCircleFilled />,
				//content: 'Remember that if you eliminate the well you will not be able to recover it',
				okText: "Save Changes",
				okButtonProps: {
					className: "bg-[#662D91]",
				},
				cancelText: "Exit",
				onOk() {
					switch (dataPage) {
						case "DashboardGasLift":
							const data_alert = dataAlertGasLift;
							const data_check = someAlert.gas_lift;
							const dataFinalWellGasLift = {
								...dataGetWellGasLift,
								data_alert: data_alert,
								data_check: data_check,
							};
							setTimeout(() => {
								mutationUpdateDataGasLift.mutate({
									operator: userDataInfo?.operator,
									lease: userDataInfo?.lease,
									region: userDataInfo?.region,
									county: dataGetWellGasLift?.county,
									field: dataGetWellGasLift?.field,
									well: dataGetWellGasLift?.well_id,
									username: User?.user_name,
									data: dataFinalWellGasLift,
								});
							}, 500);

							getSomeAlert({ ...someAlert, messageChange: false });
							Navigate(link);

							break;
						case "DashboardDCA":
							const data_alert_dca = dataAlertDCA;
							const data_check_dca = someAlert.forecasting;
							const dataFinalWellDCA = {
								...dataGetWellDCA,
								data_alert: data_alert_dca,
								check_Alert: data_check_dca,
							};

							setTimeout(() => {
								mutationUpdateJsonWellDCA.mutate({
									operator: userDataInfo?.operator,
									lease: userDataInfo?.lease,
									region: userDataInfo?.region,
									county: dataWellDCA?.county,
									field: dataWellDCA?.field,
									wellid: dataWellDCA?.well_id,
									userName: User?.user_name,
									data: dataFinalWellDCA,
								});
							}, 500);

							getSomeAlert({ ...someAlert, messageChange: false });
							Navigate(link);
							break;
						case "DashboardPlungerLift":
							const data_alert_plunger = dataAlertPlungerLift;
							const data_check_plunger = someAlert.plunger_lift;
							const dataFinalWellPlungerLift = {
								...dataGetWellPlungerLift,
								data_alert: data_alert_plunger,
								data_check: data_check_plunger,
							};
							setTimeout(() => {
								mutationUpdateWellPlungerLift.mutate({
									operator: userDataInfo?.operator,
									lease: userDataInfo?.lease,
									region: userDataInfo?.region,
									county: dataGetWellPlungerLift?.county,
									field: dataGetWellPlungerLift?.field,
									well: dataGetWellPlungerLift?.well_id,
									username: User?.user_name,
									data: dataFinalWellPlungerLift,
								});
							}, 500);

							getSomeAlert({ ...someAlert, messageChange: false });
							Navigate(link);

							break;
						default:
							break;
					}
				},
				onCancel() {
					getSomeAlert({ ...someAlert, messageChange: false });
					Navigate(link);
				},
			});
		} else {
			confirm({
				title: `Please select the proper well`,
				icon: <ExclamationCircleFilled />,
				okText: "Ok",
				okButtonProps: {
					className: "bg-[#662D91]",
				},
				okCancel: false,
				onOk() {
					getSomeAlert({ ...someAlert, messageChange: false });
					Navigate(link);
				},
			});
		}
	};

	// Transformar DATA_END_PARAMETERS en un objeto plano
	const flattDataEndParamePl = flattenObject(DATA_END_PARAMETERS_PLUNGERLIFT);

	const handleCloseModalAlert = () => {
		setShowAlert(false);
	};

	const handleOkModal = () => {
		switch (sectionSave) {
			case "DCA":
				saveSectionData(sectionSave);
				break;
			case "Gas Lift":
				saveSectionData(sectionSave);
				break;
			case "Plunger Lift":
				saveSectionData(sectionSave);
				break;
			case "Injector":
				saveSectionData(sectionSave);
				break;
			default:
				break;
		}
		setShowAlert(false);
	};
	const saveSectionData = (section) => {
		switch (section) {
			case "DCA":
				if (dataAlertDCA && !!selectedWellDCA && dataGetWellDCA) {
					getValidateAlertDCA({
						...validateAlertDCA,
						alertCard: false,
					});
					const data_alert_dca = dataAlertDCA;
					const data_check_dca = someAlert.forecasting;
					const type_graph_dashboard = checkTypeGraph?.forecasting;
					const filteredJsonEnd = AddKeysMissing(
						dataGetWellDCA,
						DATA_CREATE_WELL_DCA,
					);
					const dataDomainGraphEnd = AddKeysMissingDomain(
						dataDomainGraphDCA,
						DATA_DOMAIN_GRAPH_DCA,
					);
					const dataFinalWellDCA = {
						...filteredJsonEnd,
						data_alert: data_alert_dca,
						data_check: data_check_dca,
						type_graph_dashboard: type_graph_dashboard,
						data_domain_graph: dataDomainGraphEnd,
					};
					setTimeout(() => {
						mutationUpdateJsonWellDCA.mutate({
							operator: userDataInfo?.operator,
							lease: userDataInfo?.lease,
							region: userDataInfo?.region,
							county: dataGetWellDCA?.county,
							field: dataGetWellDCA?.field,
							wellid: dataGetWellDCA?.well_id,
							userName: User?.user_name,
							data: dataFinalWellDCA,
						});
					}, 500);
					message.success("Processing complete!");
					getSomeAlert({ ...someAlert, messageChange: false });
				} else {
					message.error("Please validate the form information.");
				}
				break;
			case "Gas Lift":
				if (dataAlertGasLift && !!selectedWell && dataGetWellGasLift) {
					getValidateAlertGasLift({
						...validateAlertGasLift,
						alertDomain: false,
						alertCard: false,
					});
					const data_alert = dataAlertGasLift;
					const data_check = someAlert.gas_lift;
					const type_graph_dashboard = checkTypeGraph?.gas_lift;

					let construcDataEndParameters =
						DATA_END_PARAMETERS_GASLIFT.initialMandatoryData;

					if (
						dataGetWellGasLift.fluid_type === TYPE_FLUID_TYPE_WELL.options[0].value
					) {
						construcDataEndParameters = {
							...construcDataEndParameters,
							...DATA_END_PARAMETERS_GASLIFT.initialDataPVTBlackOil,
							...DATA_END_PARAMETERS_GASLIFT.initialDataReservoirBlackOil,
							...DATA_END_PARAMETERS_GASLIFT.initialDataFlowBlackOil,
						};
					} else if (
						dataGetWellGasLift.fluid_type === TYPE_FLUID_TYPE_WELL.options[1].value
					) {
						construcDataEndParameters = {
							...construcDataEndParameters,
							...DATA_END_PARAMETERS_GASLIFT.initialDataPVTDryGas,
							...DATA_END_PARAMETERS_GASLIFT.initialDataReservoirDryGas,
							...DATA_END_PARAMETERS_GASLIFT.initialDataFlowDryGas,
						};
					}

					const filteredJsonEnd = AddKeysMissing(dataGetWellGasLift, {
						...DATA_CREATE_WELL_GAS_LIFT,
						...construcDataEndParameters,
					});
					const dataDomainGraphEnd = AddKeysMissingDomain(
						dataDomainGraph,
						DATA_DOMAIN_GRAPH,
					);
					const dataFinalWell = {
						...filteredJsonEnd,
						data_domain_graph: dataDomainGraphEnd,
						data_alert: data_alert,
						data_check: data_check,
						type_graph_dashboard: type_graph_dashboard,
						slide_graph_gaslift: slideGraph.gas_lift,
					};

					setTimeout(() => {
						mutationUpdateDataGasLift.mutate({
							operator: userDataInfo?.operator,
							lease: userDataInfo?.lease,
							region: userDataInfo.region,
							county: dataGetWellGasLift?.county,
							field: dataGetWellGasLift?.field,
							well: dataGetWellGasLift?.well_id,
							username: User?.user_name,
							data: dataFinalWell,
						});
					}, 500);
					message.success("Processing complete!");
					getSomeAlert({ ...someAlert, messageChange: false });
				} else {
					message.error("Please validate the form information.");
				}

				break;
			case "Plunger Lift":
				const data_alert = dataAlertPlungerLift;
				const data_check = someAlert.plunger_lift;
				if (!!selectedWellPL && dataGetWellPlungerLift) {
					getValidateAlertPlungerLift({
						...validateAlertGasLift,
						alertDomain: false,
						alertCard: false,
					});
					const filteredJsonEnd = AddKeysMissing(dataGetWellPlungerLift, {
						...DATA_WELL_PLUNGER_LIFT.InitialDataWell,
						dataDeviation: [],
						dataEquipment: [],
						...flattDataEndParamePl,
					});
					const dataDomainGraphEnd = AddKeysMissingDomain(
						dataDomainGraphPL,
						DATA_END_PARAMETERS_PLUNGERLIFT?.initialMandatoryData?.data_domain_graph,
					);
					const dataFinalWell = Object.assign({}, filteredJsonEnd, {
						type_graph_dashboard: {
							...selectDashboardPL,
							production_history: {
								// ...selectDashboardPL.production_history,
								type_graph: checkTypeGraph.plunger_lift.production_history,
								device_sensor:
									deviceRealTime.device_plungerlift.selected_device_graph
										.chartProdHistory,
							},
							wellhead_production_conditions: {
								...deviceRealTime.device_plungerlift.selected_device_graph
									.chartWellCond,
								type_graph: checkTypeGraph.plunger_lift.wellhead_production,
							},
						},
						data_domain_graph: dataDomainGraphEnd,
						data_alert: data_alert,
						data_check: data_check,
						slide_graph_plungerlift: slideGraph.plunger_lift,
					});
					mutationUpdateWellPlungerLift.mutate({
						operator: userDataInfo?.operator,
						lease: userDataInfo?.lease,
						region: userDataInfo?.region,
						county: dataGetWellPlungerLift?.county,
						field: dataGetWellPlungerLift?.field,
						well: dataGetWellPlungerLift?.well_id,
						username: User.user_name,
						data: dataFinalWell,
					});
				} else {
					message.error("Please validate the form information.");
				}
				break;
			case "Injector":
				if (dataAlertInjector && !!selectedWellInjector && dataGetWellInjector) {
					getValidateAlertInjector({
						...validateAlertInjector,
						alertCard: false,
					});
					const data_alert_injector = dataAlertInjector;
					const data_check_injector = someAlert.Injector;
					const type_graph_dashboard = checkTypeGraph?.injector;
					const filteredJsonEnd = AddKeysMissing(
						dataGetWellInjector,
						DATA_CREATE_WELL_INJECTOR,
					);
					const dataDomainGraphEnd = AddKeysMissingDomain(
						dataDomainGraphInjector,
						DATA_DOMAIN_GRAPH_INJECTOR,
					);
					const dataFinalWellInjector = {
						...filteredJsonEnd,
						data_alert: data_alert_injector,
						data_check: data_check_injector,
						type_graph_dashboard: type_graph_dashboard,
						data_domain_graph: dataDomainGraphEnd,
					};
					setTimeout(() => {
						mutationUpdateDataInjector.mutate({
							operator: userDataInfo?.operator,
							lease: userDataInfo?.lease,
							region: userDataInfo?.region,
							county: dataGetWellInjector?.county,
							field: dataGetWellInjector?.field,
							wellid: dataGetWellInjector?.well_id,
							userName: User?.user_name,
							data: dataFinalWellInjector,
						});
					}, 500);
					message.success("Processing complete!");
					getSomeAlert({ ...someAlert, messageChange: false });
				} else {
					message.error("Please validate the form information.");
				}
				break;
			default:
				break;
		}
	};

	const validateEmptyAlerts = (json) => {
		const invalidEntries = Object.entries(json).filter(
			([key, value]) => value === null || value === "",
		);
		return invalidEntries;
	};

	const validateEmptyDomains = (json) => {
		const invalidEntries = Object.entries(json).filter(
			([key, value]) => value === null || value === "",
		);
		return invalidEntries;
	};
	return (
		<>
			<AlertModalOption
				showAlert={showAlert}
				handleOkModal={handleOkModal}
				handleCloseModal={handleCloseModalAlert}
				messageAlert={messageAlert}
				typeModal={typeAlert}
			></AlertModalOption>
			<Modal
				title="Setting filters"
				centered
				open={openFirstModal[0].enabled || openFirstModal[1].enabled}
				onCancel={() => closeDataFilter()}
				width={1000}
				className="rounded-md"
				footer={null}
			>
				{openFirstModal[0].enabled && openFirstModal[0].modal === "filters" && (
					<div className="h-[400px]">
						<FilterUser closeModal={() => toggleOpenFirstModal("filters")} />
					</div>
				)}
				{openFirstModal[1].enabled && openFirstModal[1].modal === "parameters" && (
					<div className="h-[800px]">
						<ParametersConfig
							closeModal={() => toggleOpenFirstModal("parameters")}
						></ParametersConfig>
					</div>
				)}
			</Modal>
			<nav
				className="relative flex w-full pl-[90px] flex-nowrap items-center justify-between bg-white text-pa-font1 border-b border-solid hover:text-neutral-700 focus:text-neutral-700 lg:flex-wrap lg:justify-start p-1"
				data-te-navbar-ref
			>
				<div className="flex flex-wrap items-center justify-between w-full px-3">
					<button
						className="block px-2 bg-transparent border-0 text-neutral-300 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
						type="button"
						data-te-collapse-init
						data-te-target="#navbarSupportedContent9"
						aria-controls="navbarSupportedContent9"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="[&>svg]:w-7">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="h-7 w-7"
							>
								<path
									fillRule="evenodd"
									d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
									fillrulecliprule="evenodd"
								/>
							</svg>
						</span>
					</button>

					<div
						className="!visible mt-2 hidden flex-grow basis-[100%] items-center lg:mt-0 lg:!flex lg:basis-auto"
						id="navbarSupportedContent9"
						data-te-collapse-item
					>
						<ul
							className="flex flex-col pl-0 mr-auto list-style-none lg:mt-1 lg:flex-row"
							data-te-navbar-nav-ref
						>
							<Tooltip
								placement="bottom"
								title={NAVBAR_TOOLTIP.add_edit_filer}
								color={"#662D91"}
							>
								<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
									<AddFilter
										className={`w-6 h-6 ${
											openFirstModal[0].enabled ||
											!userDataInfo?.operator ||
											!userDataInfo?.lease ||
											!userDataInfo?.region
												? "fill-red-600"
												: "fill-neutral-500"
										}  hover:fill-pa-purple bg-opacity-50`}
										onClick={() => handleCLickExpand("filters")}
									/>
								</li>
							</Tooltip>
							{location.pathname === "/Users-Parameters" &&
								dataPage === "Users-Parameters" && (
									<Tooltip
										placement="bottom"
										title={NAVBAR_TOOLTIP.config_parameters}
										color={"#662D91"}
									>
										<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
											<AddParameter
												className={`w-6 h-6 ${openFirstModal[1].enabled ? "fill-red-600" : "fill-neutral-500"}  hover:fill-pa-purple bg-opacity-50`}
												onClick={() => handleCLickExpand("parameters")}
											/>
										</li>
									</Tooltip>
								)}
							{location.pathname === "/DashboardDCA" &&
								dataPage === "DashboardDCA" && (
									<Tooltip
										placement="bottom"
										title={NAVBAR_TOOLTIP.add_edit_wells}
										color={"#662D91"}
									>
										<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
											<NavLink
												to={
													!someAlert?.messageChange ? `/${NAME_PAGES.DCA.list_well}` : "#"
												}
												onClick={
													someAlert?.messageChange
														? () => handleConfirm(`/${NAME_PAGES.DCA.list_well}`)
														: null
												}
											>
												<AddNavbar
													className={`w-6 h-6 hover:fill-pa-purple
                          ${
																											!selectedWellDCA || selectedWellDCA === "null"
																												? "fill-red-700 bg-yellow-100"
																												: "fill-neutral-500 bg-opacity-50"
																										} `}
												/>
											</NavLink>
										</li>
									</Tooltip>
								)}
							{(location.pathname === "/AddWellDCA/StepMenu-EditWellDCA" ||
								location.pathname === "/AddWellDCA/StepMenu-WellDCA") && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.return_process}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to={`/${NAME_PAGES.DCA.list_well}`}>
											<ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{(location.pathname === "/AddScreeningTool/StepMenu-EditScreeningTool" ||
								location.pathname === "/AddScreeningTool/StepMenu-ScreeningTool") && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.return_process}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to="/AddScreeningTool">
											<ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{(location.pathname === "/AddWellInjector/StepMenu-EditWellInjector" ||
								location.pathname === "/AddWellInjector/StepMenu-WellInjector") && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.return_process}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to={`/${NAME_PAGES.well_injector.list_well}`}>
											<ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{location.pathname === "/AddScreeningTool" && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.dashboard.screening_tool}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to="/DashboardScreeningTool">
											<TuningNavbar className="w-6 h-6 fill-neutral-500" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{location.pathname === "/DashboardGasLift" &&
								(dataPage === "DashboardGasLift" ||
									dataPage === '"DashboardGasLift"') && (
									<Tooltip
										placement="bottom"
										title={NAVBAR_TOOLTIP.add_edit_wells}
										color={"#662D91"}
									>
										<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
											<NavLink
												to={
													!someAlert?.messageChange
														? `/${NAME_PAGES.gas_lift.list_well}`
														: "#"
												}
												onClick={
													someAlert?.messageChange
														? () => handleConfirm(`/${NAME_PAGES.gas_lift.list_well}`)
														: null
												}
											>
												{location.pathname === "/DashboardGasLift" &&
													dataPage === "DashboardGasLift" && (
														<AddNavbar
															className={`w-6 h-6 hover:fill-pa-purple
                            ${
																													!selectedWell || selectedWell === "null"
																														? "fill-red-700 bg-yellow-100"
																														: "fill-neutral-500 bg-opacity-50"
																												} `}
														/>
													)}
											</NavLink>
										</li>
									</Tooltip>
								)}
							{location.pathname !== `/${NAME_PAGES.gas_lift.list_well}` &&
								(dataPage === "AddWellGasLift" || dataPage === '"AddWellGasLift"') && (
									<Tooltip
										placement="bottom"
										title={NAVBAR_TOOLTIP.return_process}
										color={"#662D91"}
									>
										<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
											<NavLink to={`/${NAME_PAGES.gas_lift.list_well}`}>
												<ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
											</NavLink>
										</li>
									</Tooltip>
								)}
							{location.pathname === "/AddWellDCA" && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.dashboard.dca}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to={`/${NAME_PAGES.DCA.dashboard}`}>
											<TuningNavbar className="w-6 h-6 fill-neutral-500" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{location.pathname === "/AddWellGasLift" && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.dashboard.gas_lift}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to={`/${NAME_PAGES.gas_lift.dashboard}`}>
											<TuningNavbar className="w-6 h-6 fill-neutral-500" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{location.pathname === "/DashboardScreeningTool" &&
								dataPage === "DashboardScreeningTool" && (
									<Tooltip
										placement="bottom"
										title={NAVBAR_TOOLTIP.add_edit_reservoir}
										color={"#662D91"}
									>
										<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
											<NavLink
												to={!someAlert?.messageChange ? "/AddScreeningTool" : "#"}
												onClick={
													someAlert?.messageChange
														? () => handleConfirm("/AddScreeningTool")
														: null
												}
											>
												<AddNavbar
													className={`w-6 h-6 hover:fill-pa-purple
                            ${
																													!selectedReservoirSCT
																														? "fill-red-700 bg-yellow-100"
																														: "fill-neutral-500 bg-opacity-50"
																												} `}
												/>
											</NavLink>
										</li>
									</Tooltip>
								)}
							{location.pathname === "/DashboardPlungerLift" &&
								dataPage === "DashboardPlungerLift" && (
									<Tooltip
										placement="bottom"
										title={NAVBAR_TOOLTIP.add_edit_wells}
										color={"#662D91"}
									>
										<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
											<NavLink
												to={!someAlert?.messageChange ? "/AddPlungerLift" : "#"}
												onClick={
													someAlert?.messageChange
														? () => handleConfirm("/AddPlungerLift")
														: null
												}
											>
												{/* <AddNavbar
												className={`w-6 h-6 hover:fill-pa-purple
													${!selectedReservoirSCT
													? "fill-red-700 bg-yellow-100"
													: "fill-neutral-500 bg-opacity-50"
													} `}
												/> */}
												<AddNavbar
													className={`w-6 h-6 hover:fill-pa-purple fill-neutral-500 bg-opacity-50 `}
												/>
											</NavLink>
										</li>
									</Tooltip>
								)}
							{location.pathname === "/DashboardInjector" && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.add_edit_wells}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to={"/AddWellInjector"} onClick={null}>
											{/* <AddNavbar
											className={`w-6 h-6 hover:fill-pa-purple
												${!selectedReservoirSCT
												? "fill-red-700 bg-yellow-100"
												: "fill-neutral-500 bg-opacity-50"
												} `}
											/> */}
											<AddNavbar
												className={`w-6 h-6 hover:fill-pa-purple fill-neutral-500 bg-opacity-50 `}
											/>
										</NavLink>
									</li>
								</Tooltip>
							)}
							{(location.pathname === "/AddPlungerLift/StepMenu-WellPlungerLift" ||
								location.pathname === "/AddPlungerLift/StepMenu-EditWellPlungerLift" ||
								location.pathname === "/AddPlungerLift/StepMenu-FluidPlungerLift" ||
								location.pathname === "/AddPlungerLift/StepMenu-EditFluidPlungerLift" ||
								location.pathname === "/AddPlungerLift/StepMenu-ReservoirPlungerLift" ||
								location.pathname ===
									"/AddPlungerLift/StepMenu-EditReservoirPlungerLift" ||
								location.pathname === "/AddPlungerLift/StepMenu-PlungerLift" ||
								location.pathname === "/AddPlungerLift/StepMenu-EditPlungerLift") && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.return_process}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to="/AddPlungerLift">
											<ReturnPage className="w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{location.pathname === "/AddPlungerLift" && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.dashboard.plunger_lift}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to="/DashboardPlungerLift">
											<TuningNavbar className="w-6 h-6 fill-neutral-500" />
										</NavLink>
									</li>
								</Tooltip>
							)}
							{location.pathname === "/AddWellInjector" && (
								<Tooltip
									placement="bottom"
									title={NAVBAR_TOOLTIP.dashboard.well_injector}
									color={"#662D91"}
								>
									<li className="pl-2 my-4 lg:my-0 " data-te-nav-item-ref>
										<NavLink to={`/${NAME_PAGES.well_injector.dashboard}`}>
											<TuningNavbar className="w-6 h-6 fill-neutral-500" />
										</NavLink>
									</li>
								</Tooltip>
							)}

							{titleWell && (
								<p className="flex pl-2 my-4 items-end text-start justify-end w-full h-6 lg:my-0 text-[12px] font-sans text-grey-500">
									{titleWell}
								</p>
							)}

							{dataPage === "DashboardDCA" && (
								<>
									<li
										className="pl-2 mb-4 lg:mb-0 lg:pl-0 lg:pr-1"
										data-te-nav-item-ref
										data-te-dropdown-ref
									>
										<ul
											className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
											aria-labelledby="dropdownMenuButton2"
											data-te-dropdown-menu-ref
										>
											<optgroup label="Label 1" className="pl-2 text-sm"></optgroup>
											<li>
												<a
													className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
													href="#"
													data-te-dropdown-item-ref
												>
													Action
												</a>
											</li>
											<li>
												<a
													className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
													href="#"
													data-te-dropdown-item-ref
												>
													Another action
												</a>
											</li>
											<optgroup label="Label 2" className="pl-2 text-sm"></optgroup>
											<li>
												<a
													className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
													href="#"
													data-te-dropdown-item-ref
												>
													Something else here
												</a>
											</li>
										</ul>
									</li>
								</>
							)}
						</ul>
					</div>

					{/* 
						<div className="relative flex items-center w-[50%]">
							<h1 htmlFor="" className="mr-2 text-white">
							SELECTED WELL
							</h1>
							<Space direction="vertical" size={12}>
							<RangePicker
								popupClassName="custom-popup-className"
								placement={"bottomRight"}
								bordered={true}
							/>
							</Space>
						</div> 
					*/}

					<div className="relative flex items-center gap-2 ">
						<Tooltip placement="bottom" title={"Settings"} color={"#662D91"}>
							{((location.pathname === NAME_PAGES.gas_lift.routes.dashboard &&
								!!selectedWell) ||
								(location.pathname === NAME_PAGES.DCA.routes.dashboard &&
									!!selectedWellDCA) ||
								(location.pathname === NAME_PAGES.screening_tool.routes.dashboard &&
									!!selectedReservoirSCT) ||
								location.pathname === NAME_PAGES.gas_lift.routes.list_well ||
								location.pathname === NAME_PAGES.DCA.routes.list_well ||
								location.pathname === NAME_PAGES.plotting.routes.dashboard ||
								location.pathname === NAME_PAGES.screening_tool.routes.list_well ||
								location.pathname === NAME_PAGES.plunger_lift.routes.list_well) && (
								<NavLink>
									<SettingNavbar className="flex w-6 h-6 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple" />
								</NavLink>
							)}
						</Tooltip>

						<Tooltip placement="bottom" title={"Save"} color={"#662D91"}>
							{((location.pathname === NAME_PAGES.gas_lift.routes.dashboard &&
								!!selectedWell) ||
								(location.pathname === NAME_PAGES.DCA.routes.dashboard &&
									!!selectedWellDCA) ||
								(location.pathname === NAME_PAGES.screening_tool.routes.dashboard &&
									!!selectedReservoirSCT) ||
								(location.pathname === NAME_PAGES.plunger_lift.routes.dashboard &&
									!!selectedWellPL) ||
								(location.pathname === NAME_PAGES.well_injector.routes.dashboard &&
									!!selectedWellInjector) ||
								location.pathname === NAME_PAGES.gas_lift.routes.list_well ||
								location.pathname === NAME_PAGES.DCA.routes.list_well ||
								location.pathname === NAME_PAGES.plotting.routes.dashboard ||
								location.pathname === NAME_PAGES.screening_tool.routes.list_well ||
								location.pathname === NAME_PAGES.plunger_lift.routes.list_well ||
								location.pathname === NAME_PAGES.well_injector.routes.list_well) && (
								<NavLink>
									<SaveNavbar
										onClick={() => handleSave()}
										className="flex w-6 h-6 mr-2 bg-opacity-50 fill-neutral-500 hover:fill-pa-purple"
									/>
								</NavLink>
							)}
						</Tooltip>
						<div
							className="relative "
							data-te-dropdown-ref
							data-te-dropdown-alignment="end"
						>
							<button
								className="hidden-arrow mr-4 flex items-center text-neutral-600 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
								type="button"
								id="dropdownMenuButton1"
								data-te-dropdown-toggle-ref
								aria-expanded="false"
							>
								<div className="ml-2 mr-2">
									<h1 className="font-bold text-md">{User.user_name}</h1>
									<h2 className="text-sm">Operator Co.</h2>
								</div>
								<ChevronDown />
							</button>

							<ul
								className="optionsNavBar float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
								aria-labelledby="dropdownMenuButton1"
								data-te-dropdown-menu-ref
							>
								<li>
									<button className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30">
										Settings
									</button>
								</li>
								<li>
									<button
										onClick={logOut}
										className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
									>
										Log Out
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};
