// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartPlotting_principalContainer__T7Npu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    border: 2px solid #f5f5f5;
  }
  .ChartPlotting_containerTool__gcT1O {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 30px;
    border-bottom: 2px solid #f5f5f5;
    border-radius: 5px 0px;
    padding: 0px 30px;
    gap: 5px;
    justify-content: space-between;
  }
  .ChartPlotting_btn__7jQEg {
    box-sizing: content-box;
    border-radius: 0px;
    border-style: none;
    opacity: 0.5;
}
  .ChartPlotting_btn__7jQEg:hover {
    --tw-text-opacity: 1;
    color: rgb(102 45 145 / var(--tw-text-opacity));
    text-decoration-line: none;
    opacity: 0.75;
}
  .ChartPlotting_btn__7jQEg:focus {
    opacity: 1;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline: 2px solid transparent;
    outline-offset: 2px;
}
  .ChartPlotting_containerBtn__I0\\+JZ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: auto;
    background: #662d91;
    background: #E7E6F7;
    color: #662d91;
    padding: 5px;
    border-radius: 4px;
    font-size: 11px;
    min-width: 100px;
    border: 1px solid #662d91;
  }
  .ChartPlotting_containerZoom__I7PN9{
      display: flex;
      gap: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Plotting/GraphicsPlotting/ChartPlotting.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,yBAAyB;EAC3B;EACA;IACE,aAAa;IACb,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,gCAAgC;IAChC,sBAAsB;IACtB,iBAAiB;IACjB,QAAQ;IACR,8BAA8B;EAChC;EAEE;IAAA,uBAAsK;IAAtK,kBAAsK;IAAtK,kBAAsK;IAAtK;AAAsK;EAAtK;IAAA,oBAAsK;IAAtK,+CAAsK;IAAtK,0BAAsK;IAAtK;AAAsK;EAAtK;IAAA,UAAsK;IAAtK,sBAAsK;IAAtK,8BAAsK;IAAtK,uGAAsK;IAAtK,8BAAsK;IAAtK;AAAsK;EAExK;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,yBAAyB;EAC3B;EACA;MACI,aAAa;MACb,SAAS;EACb","sourcesContent":[".principalContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 95%;\n    border: 2px solid #f5f5f5;\n  }\n  .containerTool {\n    display: flex;\n    flex-direction: row-reverse;\n    width: 100%;\n    height: 30px;\n    border-bottom: 2px solid #f5f5f5;\n    border-radius: 5px 0px;\n    padding: 0px 30px;\n    gap: 5px;\n    justify-content: space-between;\n  }\n  .btn {\n    @apply box-content border-none rounded-none opacity-50 hover:no-underline hover:opacity-75 hover:text-pa-purple focus:opacity-100 focus:shadow-none focus:outline-none;\n  }\n  .containerBtn {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 20px;\n    width: auto;\n    background: #662d91;\n    background: #E7E6F7;\n    color: #662d91;\n    padding: 5px;\n    border-radius: 4px;\n    font-size: 11px;\n    min-width: 100px;\n    border: 1px solid #662d91;\n  }\n  .containerZoom{\n      display: flex;\n      gap: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"principalContainer": `ChartPlotting_principalContainer__T7Npu`,
	"containerTool": `ChartPlotting_containerTool__gcT1O`,
	"btn": `ChartPlotting_btn__7jQEg`,
	"containerBtn": `ChartPlotting_containerBtn__I0+JZ`,
	"containerZoom": `ChartPlotting_containerZoom__I7PN9`
};
export default ___CSS_LOADER_EXPORT___;
