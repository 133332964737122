import React, { useContext, useEffect, useState } from "react";
import { Menu, Select } from "antd";
import { ReactComponent as GeneralIcon } from "../../../assets/img/icons/general.svg";
import { ReactComponent as DCAIcon } from "../../../assets/img/icons/graph.svg";
import { ReactComponent as GasLiftIcon } from "../../../assets/img/icons/gas_lift.svg";
import { ReactComponent as PlungerLiftIcon } from "../../../assets/img/icons/plunger_lift.svg";
import { ParametersDCA } from "../Complements/ParametersDCA";
import { ParametersGaslift } from "../Complements/ParametersGasLift";
import { ParametersPlungerLift } from "../Complements/ParametersPlungerLift";
import { ParametersGeneral } from "../Complements/ParametersGeneral";
import { DashboardContext } from "../../../context/Dashboard/DashboardContentx";

const items = [
	{
		label: "General",
		key: "general",
		icon: <GeneralIcon className="w-5 h-5" />,
	},
	{
		label: "DCA",
		key: "dca",
		icon: <DCAIcon className="w-5 h-5" />,
	},
	{
		label: "Gas Lift",
		key: "gasLift",
		icon: <GasLiftIcon className="w-5 h-5" />,
	},
	{
		label: "Plunger Lift",
		key: "plungerLift",
		icon: <PlungerLiftIcon className="w-5 h-5 " />,
	},
];

const dataListParameters = [
	"C6 Well Head Pressure",
	"C9 Well Head Pressure",
	"Corrected Accumulated Volume",
	"Corrected Current Day Volume",
	"Corrected Flow Rate",
	"Corrected Previous Day Volume",
	"Date (MM/DD/YYYY)",
	"Differential Pressure",
	"Flowing Temperature",
	"Gas Flow Rate",
	"Gas Rate",
	"Liquid Flow Rate",
	"Liquid Previous Day Total",
	"Meter 1 Current Day Volume",
	"Meter 1 Flow Rate",
	"Meter 1 Net Accumulated Volume",
	"Meter 1 Previous Day Volume",
	"Meter 2 Current Day Volume",
	"Meter 2 Flow Rate",
	"Meter 2 Net Accumulated Volume",
	"Meter 2 Previous Day Volume",
	"Oil Rate",
	"GOR",
	"WOR",
	"Separator Pressure",
	"Static Pressure",
];

const intialSelectedParameters = {
	date: "",
	static_pressure: "",
	flowing_temperature: "",
	gas_rate: "",
	water_rate: "",
	oil_rate: "",
	acumulativeGas: "",
	acumulativeOil: "",
	injection_pressure: "",
	injection_gas_rate: "",
	volumetric_flow_rate: "",
	Pipeline_Pressure: "",
	Tubing_Pressure: "",
	Casing_Pressure: "",
	liquid: "",
	gas: "",
};

export const ParametersConfig = ({ closeModal }) => {
	const { openFirstModal } = useContext(DashboardContext);
	const [selectedParameters, setSelectedParameters] = useState(
		intialSelectedParameters,
	);

	useEffect(() => {
		console.log(
			"se van a aplicar la ejecucion de apis para la lectura de parametros",
		);
	}, [openFirstModal[0].enabled]);

	const [current, setCurrent] = useState("mail");
	const onClick = (e) => {
		console.log("click ", e);
		setCurrent(e.key);
	};
	const handleChangeSelect = (name, value) => {
		setSelectedParameters((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};
	const handleSaveFilter = () => {};
	const handleCancel = () => {
		if (closeModal) closeModal();
	};
	return (
		<div className="flex flex-col justify-between w-full h-full text-center">
			<div className="flex flex-col flex-grow overflow-y-auto">
				<Menu
					onClick={onClick}
					selectedKeys={[current]}
					mode="horizontal"
					defaultOpenKeys={["DCA"]}
					items={items}
					className="items-center justify-center text-center"
				/>

				{current === "general" && (
					<ParametersGeneral
						dataListParameters={dataListParameters}
						handleChangeSelect={handleChangeSelect}
						selectedParameters={selectedParameters}
					/>
				)}
				{current === "dca" && (
					<ParametersDCA
						dataListParameters={dataListParameters}
						handleChangeSelect={handleChangeSelect}
						selectedParameters={selectedParameters}
					/>
				)}
				{current === "gasLift" && (
					<ParametersGaslift
						dataListParameters={dataListParameters}
						handleChangeSelect={handleChangeSelect}
						selectedParameters={selectedParameters}
					/>
				)}
				{current === "plungerLift" && (
					<ParametersPlungerLift
						dataListParameters={dataListParameters}
						handleChangeSelect={handleChangeSelect}
						selectedParameters={selectedParameters}
					/>
				)}
			</div>
			<div className="flex justify-end w-full p-4 border-t">
				<button
					onClick={handleCancel}
					className="h-8 px-4 py-1 text-black border border-gray-300 rounded-md hover:bg-gray-100"
				>
					Cancel
				</button>
				<button
					onClick={() => {
						handleSaveFilter();
						if (closeModal) closeModal();
					}}
					className="px-4 py-1 ml-2 text-white rounded-md h-8 bg-[#662D91] hover:bg-[#4096FF]"
				>
					OK
				</button>
			</div>
		</div>
	);
};
