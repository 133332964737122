export const DATA_CREATE_WELL_GAS_LIFT = {
	well_id: "",
	region: "",
	field: "",
	latitude: "",
	company: "",
	well_type: "",
	artificial_method: "",
	flow_type: "",
	well_name: "",
	discretized_well: false,
	well_platforms: "",
	country: "",
	county: "",
	longitude: "",
	objective: "",
	well_orientation: "",
	well_reference: "",
	fluid_type: "",
	dataDeviation: [],
	dataEquipment: [],
};

export const DATA_END_PARAMETERS = {
	// data PVT black oil
	initialDataPVTBlackOil: {
		res_pressure: "",
		res_temperature: "",
		res_initial_gor: "",
		res_oil_gravity: "",
		res_gas_gravity: "",
		res_H2S: "",
		res_CO2: "",
		res_N2: "",
		res_water_salinity: "",
		sep_pressure: "",
		sep_temperature: "",
		sep_oil_gravity: "",
		sep_gas_gravity: "",
		sep_solution_gor: "",
		sep_oil_volume_factor: "",

		temperature_lab: "",
		solution_gor_pb: "",
		bubble_point_pressure_lab: "",
		oil_fvf: "",

		option_data_point: "",
		calculate_correlation: "",
		calculate_correlation_oil: "",
		bubble_point_pressure: "",
		data_point_table: [],
		fluidLab: false,
		table_fluid_lab: [],
		changeData: {},
	},

	// data PVT dry gas
	initialDataPVTDryGas: {
		res_pressure: "",
		res_temperature: "",
		res_initial_gor: "",
		res_oil_gravity: "",
		res_gas_gravity: "",
		res_H2S: "",
		res_CO2: "",
		res_N2: "",
		res_N2O: "",
		res_water_salinity: "",
		res_type_of_gas: "condensate_gas",
		sep_primary_pressure: "",
		sep_primary_temperature: "",
		sep_primary_gas_oil_ratio: "",
		sep_primary_gas_gravity: "",
		sep_secondary_pressure: "",
		sep_secondary_temperature: "",
		sep_secondary_gas_oil_ratio: 0,
		sep_secondary_gas_gravity: 0.55,
		sep_stock_gas_oil_ratio: 0,
		sep_stock_gas_gravity: 0.55,
		sep_number_separators: 2,
		reservoir_gas_composition: [],
		C7_specific_gravity: "",
		// Data lab
		dew_point_lab: "",
		temperature_lab: "",
		vaporized_lab: "",
		enable_lab: true,
		// Data Calculation
		option_data_point: "",
		data_point_table: [],
		select_z_gas_compressibility_factor: "",
		select_gas_viscosity: "",
		select_gas_compressibility: "",
		select_pb_rs_bo: "",
		select_oil_viscosity: "",
		select_dew_point: "",
		select_vaporized: "",
		state_check_specific_gravity: null,
		state_check_critical_propierties: null,
		state_check_chart: [],
		// Result Data
		gas_mixture_specific: "",
		gas_mixture_molecular: "",
		dew_point_pressure: "",
	},

	// data reservoir black oil
	initialDataReservoirBlackOil: {
		layer_pressure: "",
		layer_temperature: "",
		md_perf_depth: "",
		water_cut_fraction: "",
		state_reservoir: "",
		ipr_model: "",
		test_pressure_1_s: "",
		test_flow_rate_1_s: "",
		test_pressure_2_s: "",
		test_flow_rate_2_s: "",
		liquid_flow_rate_s: "",
		productivity_index_j_s: "",
		test_pressure_1_us: "",
		test_flow_rate_1_us: "",
		test_pressure_2_us: "",
		test_flow_rate_2_us: "",
		liquid_flow_rate_us: "",
		productivity_index_j_us: "",
		check_phases: false,
		pwf_ipr: false,
		straight_line: false,
		bubblePointPressure: "",
		nameDataGraphic: "",
		dateDataGraphic: new Date().toISOString().split("T")[0],
		selectDataIPR: "",
	},

	// data reservoir dry gas
	initialDataReservoirDryGas: {
		layer_pressure: "",
		layer_temperature: "",
		dry_gas_api_gravity_hydrocarbon: "",
		dry_gas_ipr_model: "",
		dry_gas_pseudo_pressure_single_rate: {
			single_gas_rate: "",
			single_bottom_hole_flowing_pressure: "",
			single_exponent_n: "",
			single_result_coefficient_c: "",
		},
		dry_gas_pseudo_pressure_multi_rate: {
			multi_rate_data_table: {},
			multi_rate_result_coefficient_c: "",
			multi_rate_result_exponent_n: "",
		},
		dry_gas_forchheimer_reservoir_propierties: {
			forchheimer_formation_thickness: "",
			forchheimer_porosity: "",
			forchheimer_permeability_to_gas: "",
			forchheimer_drainage_area: "",
			forchheimer_dietz_shape_factor: "",
			forchheimer_perforated_interval: "",
			forchheimer_wellbore_radius: "",
			forchheimer_skin_factor: "",
		},
		dry_gas_forchheimer_multi_rate: {
			forchheimer_data_table: {},
			forchheimer_result_coefficient_a: "",
			forchheimer_result_exponent_b: "",
		},
	},

	// data inflow/outflow black oil
	initialDataFlowBlackOil: {
		oil_flow_rate_min: "",
		oil_flow_rate_max: "",
		number_rates_flow: "",
		check_import_model_ipr: false,
		select_parameters: "",
		min_gor_flow: "",
		max_gor_flow: "",
		number_cases_gor_flow: "",
		min_wt_flow: "",
		max_wt_flow: "",
		number_cases_wt_flow: "",
		min_inj_flow: "",
		max_inj_flow: "",
		number_cases_inj_flow: "",
		table_inflow_outflow: [],
		table_performance_flow: [],
		minChartFlow: "",
		maxChartFlow: "",
	},

	initialDataFlowDryGas: {
		oil_flow_rate_min: "",
		oil_flow_rate_max: "",
		number_rates_flow: "",
		check_import_model_ipr: false,
		select_parameters: "",
		min_gor_flow: "",
		max_gor_flow: "",
		number_cases_gor_flow: "",
		min_wt_flow: "",
		max_wt_flow: "",
		number_cases_wt_flow: "",
		min_inj_flow: "",
		max_inj_flow: "",
		number_cases_inj_flow: "",
		table_inflow_outflow: [],
		table_performance_flow: [],
		minChartFlow: "",
		maxChartFlow: "",
	},

	initialMandatoryData: {
		data_alert: {
			twc_pressure_min: null,
			twc_temp_min: null,
			awc_pressure_min: null,
			awc_temp_min: null,
			pc_oil_rate_min: null,
			pc_gas_rate_min: null,
			pc_water_rate_min: null,
			pc_gor_min: null,
			pc_wor_min: null,
			alm_injection_min: null,
			alm_inj_gas_min: null,
			alm_inj_GLR_min: null,
			alm_formation_min: null,
			alm_water_cut_min: null,
			em_eur_min: null,
			em_npv_min: null,
			bhc_pressure_min: null,
			bhc_temp_min: null,
			twc_pressure_max: null,
			twc_temp_max: null,
			awc_pressure_max: null,
			awc_temp_max: null,
			pc_oil_rate_max: null,
			pc_gas_rate_max: null,
			pc_water_rate_max: null,
			pc_gor_max: null,
			pc_wor_max: null,
			alm_injection_max: null,
			alm_inj_gas_max: null,
			alm_inj_GLR_max: null,
			alm_formation_max: null,
			alm_water_cut_max: null,
			em_eur_max: null,
			em_npv_max: null,
			bhc_pressure_max: null,
			bhc_temp_max: null,
		},
		// datos que comparten los dos tipos de pozos
		data_check: {
			valuesAlert: {
				pc_gas_rate: false,
				twc_temp: false,
				pc_oil_rate: false,
				pc_water_rate: false,
				twc_pressure: false,
				alm_injection: false,
				alm_inj_gas: false,
				alm_inj_GLR: false,
				alm_formation: false,
				alm_water_cut: false,
				em_eur: false,
				em_npv: false,
				awc_pressure: false,
				awc_temp: false,
				bhc_pressure: false,
				bhc_temp: false,
				pc_gor: false,
				pc_wor: false,
			},
			valuesCheck: {
				pc_gas_rate: false,
				twc_temp: false,
				pc_oil_rate: false,
				pc_water_rate: false,
				twc_pressure: false,
				alm_injection: false,
				alm_inj_gas: false,
				alm_inj_GLR: false,
				alm_formation: false,
				alm_water_cut: false,
				em_eur: false,
				em_npv: false,
				awc_pressure: false,
				awc_temp: false,
				bhc_pressure: false,
				bhc_temp: false,
				pc_gor: false,
				pc_wor: false,
			},
			countAlert: 0,
			countCheck: 0,
		},
		data_domain_graph: {
			press_max_wpc: null,
			press_min_wpc: null,
			temp_max_wpc: null,
			temp_min_wpc: null,
			injrate_max_wic: null,
			injrate_min_wic: null,
			injpress_max_wic: null,
			injpress_min_wic: null,
			rate_max_ph: null,
			rate_min_ph: null,
			gas_min_ph: null,
			gas_max_ph: null,
		},
		type_graph_dashboard: [],
		slide_graph_gaslift: 1
	},
};

export const REQUIRED_PROPIERTIES = [
	"well_id",
	"well_platforms",
	"well_name",
	"fluid_type",
	"region",
	"country",
	"field",
	"latitude",
	"longitude",
	"res_pressure",
	"res_temperature",
	"res_initial_gor",
	"res_oil_gravity",
	"res_H2S",
	"res_CO2",
	"res_N2",
	"layer_pressure",
	"layer_temperature",
	"oil_flow_rate_min",
	"oil_flow_rate_max",
	"number_rates_flow",
	"check_import_model_ipr",
];

export const ADD_REQUIRED_PROPIERTIES = {
	black_oil : [
		"res_gas_gravity",
		"md_perf_depth",
		"water_cut_fraction",
	]
};

export const INITIAL_DATA_WELL_DEVIATION = [
	{
		id: 0,
		measured_depth: 0,
		tvd: 0,
		inc: 0,
	},
	{
		id: 1,
		measured_depth: "",
		tvd: "",
		inc: null,
	},
	{
		id: 2,
		measured_depth: "",
		tvd: "",
		inc: "", //!preguntar porque se asigna en null
	},
];

export const INITIAL_DATA_WELL_EQUIPMENT = [
	{
		id: 0,
		name: "",
		type: "",
		measured_depth: "",
		internal_diameter: "",
		external_diameter: "",
		absolute_roughness: "",
	},
	{
		id: 1,
		name: "",
		type: "",
		measured_depth: "",
		internal_diameter: "",
		external_diameter: "",
		absolute_roughness: "",
	},
	{
		id: 2,
		name: "",
		type: "",
		measured_depth: "",
		internal_diameter: "",
		external_diameter: "",
		absolute_roughness: "",
	},
];

export const TYPE_FLUID_TYPE_WELL = {
	key: "option_data_point",
	label: "Fluid type",
	options: [
		{ value: "black_oil", label: "Oil and Water", disable: false },
		{ value: "dry_gas", label: "Dry and Wet Gas", disable: false },
		{ value: "volatile_oil", label: "Volatile Oil", disable: true },
		{ value: "condensate", label: "Condensatee", disable: true },
	],
};

export const INITIAL_POST_DATA_PVT = {
	API_gravity_of_oil: null,
	specific_gravity_of_gas: null,
	temp_res: null,
	rsb_init: null,
	rsb_sep: null,
	bob_sep: null,
	sGg_sep: null,
	p_sep: null,
	t_sep: null,
	API_sep: null,
	lab_temp: null,
	pb_lab: null,
	rsb_lab: null,
	rsdb_lab: null,
	bodb_lab: null,
	rsw_calc: null,
	pb_calc: null,
	specific_gravity_of_oil: null,
};

export const INITIAL_POST_PVT_CALC = {
	p_lab: null,
	rs_lab: null,
	rs_init: null,
	co_lab: null,
	bo_lab: null,
	visc_oil_lab: null,
	press_calc: null,
	numbers_press_rows: null,
};

export const DATA_FLUID = {
	initial_data_fluid: {
		res_pressure: "",
		res_temperature: "",
		res_initial_gor: "",
		res_oil_gravity: "",
		res_gas_gravity: "",
		res_H2S: "",
		res_CO2: "",
		res_N2: "",
		res_water_salinity: "",
		sep_pressure: "",
		sep_temperature: "",
		sep_oil_gravity: "",
		sep_gas_gravity: "",
		sep_solution_gor: "",
		sep_oil_volume_factor: "",
	},
	initialInputFluidLab: {
		temperature_lab: "",
		solution_gor_pb: "",
		bubble_point_pressure_lab: "",
		oil_fvf: "",
	},
	initialDataTableFluidLab1: [
		{
			id: 0,
			pressureTable1: "",
			gas_oil_ratio: "",
			oil_fvf: "",
			oil_viscosity: "",
			oil_compressibility: "",
		},
		{
			id: 1,
			pressureTable1: "",
			gas_oil_ratio: "",
			oil_fvf: "",
			oil_viscosity: "",
			oil_compressibility: "",
		},
		{
			id: 2,
			pressureTable1: "",
			gas_oil_ratio: "",
			oil_fvf: "",
			oil_viscosity: "",
			oil_compressibility: "",
		},
		{
			id: 3,
			pressureTable1: "",
			gas_oil_ratio: "",
			oil_fvf: "",
			oil_viscosity: "",
			oil_compressibility: "",
		},
	],
	initialAutomaticData: [
		{
			id: 0,
			name: "From",
			from_temperature: "",
			from_pressure: "",
		},
		{
			id: 1,
			name: "To",
			to_temperature: "",
			to_pressure: "",
		},
		{
			id: 2,
			name: "Values",
			values_temperature: 1,
			values_pressure: "",
		},
	],
	initialDataCalculation: {
		option_data_point: "",
		calculate_correlation: "",
		calculate_correlation_oil: "",
		bubble_point_pressure: "",
	},
	initialUserSelectionData: [
		{
			id: 0,
			temperature: "",
			pressure: "",
		},
		{
			id: 1,
			temperature: "",
			pressure: "",
		},
		{
			id: 2,
			temperature: "",
			pressure: "",
		},
	],
	stateChecks: [
		{
			solution_gor: false,
			oil_fvf: false,
			oil_viscosity: false,
			oil_compressibility: false,
		},
		{
			"Bubble point pressure": {
				Laboratory: false,
				Glaso: false,
				Standing: false,
				"Petrosky and Farshad": false,
			},
			"Solution Gas-Oil ratio": {
				Laboratory: false,
				Glaso: false,
				Standing: false,
				"Petrosky and Farshad": false,
			},
			"Oil formation volume factor": {
				Laboratory: false,
				Glaso: false,
				Standing: false,
				"Petrosky and Farshad": false,
			},
			"Oil viscosity": {
				Laboratory: false,
				"Beggs et al., Beal": false,
				"Glaso, Beggs et al., Vazquez et al": false,
			},
		},
		{
			solution_gor: false,
			oil_fvf: false,
			oil_viscosity: false,
			oil_compressibility: false,
		},
		{
			"Solution GOR": false,
			"Oil FVF": false,
		},
	],
	initialDataTablePVT: [
		{
			rs: [],
			press: [],
			bo: [],
			viso: [],
			co: [],
		},
	],
};

export const DATA_RESERVOIR = {
	initialInputReservoir: {
		layer_pressure: "",
		layer_temperature: "",
		md_perf_depth: "",
		water_cut_fraction: "",
		state_reservoir: "saturated",
		ipr_model: "",
		test_pressure_1_s: "",
		test_flow_rate_1_s: "",
		test_pressure_2_s: "",
		test_flow_rate_2_s: "",
		productivity_index_j_s: "",
		liquid_flow_rate_s: "",
		test_pressure_1_us: "",
		test_flow_rate_1_us: "",
		test_pressure_2_us: "",
		test_flow_rate_2_us: "",
		productivity_index_j_us: "",
		liquid_flow_rate_us: "",
		check_phases: "",
		pwf_ipr: false,
		straight_line: false,
		bubblePointPressure: 0,
		nameDataGraphic: "",
		dateDataGraphic: new Date().toISOString().split("T")[0],
		selectDataIPR: "",
	},
};

export const DATA_DOMAIN_GRAPH = {
	press_max_wpc: null,
	press_min_wpc: null,
	temp_max_wpc: null,
	temp_min_wpc: null,
	injrate_max_wic: null,
	injrate_min_wic: null,
	injpress_max_wic: null,
	injpress_min_wic: null,
	rate_max_ph: null,
	rate_min_ph: null,
	gas_min_ph: null,
	gas_max_ph: null,
};

export const STATE_ALERTS = {
	alert_pc_gas_rate: "pc_gas_rate",
	alert_pc_oil_rate: "pc_oil_rate",
	alert_pc_water_rate: "pc_water_rate",
	alert_twc_temp: "twc_temp",
	alert_twc_pressure: "twc_pressure",
	alert_awc_pressure: "awc_pressure",
	alert_awc_temp: "awc_temp",
	alert_alm_injection: "alm_injection",
	alert_alm_inj_gas: "alm_inj_gas",
	alert_alm_water_cut: "alm_water_cut",
	alert_bhc_pressure: "bhc_pressure",
	alert_alm_formation: "alm_formation",
	alert_alm_inj_GLR: "alm_inj_GLR",
	alert_pc_wor: "pc_wor",
	alert_pc_gor: "pc_gor",
};

export const INITIAL_DATA_ALERTS_GASLIFT = {
	twc_pressure_min: null,
	twc_temp_min: null,
	awc_pressure_min: null,
	awc_temp_min: null,
	pc_oil_rate_min: null,
	pc_gas_rate_min: null,
	pc_water_rate_min: null,
	pc_gor_min: null,
	pc_wor_min: null,
	alm_injection_min: null,
	alm_inj_gas_min: null,
	alm_inj_GLR_min: null,
	alm_formation_min: null,
	alm_water_cut_min: null,
	em_eur_min: null,
	em_npv_min: null,
	bhc_pressure_min: null,
	bhc_temp_min: null,
	twc_pressure_max: null,
	twc_temp_max: null,
	awc_pressure_max: null,
	awc_temp_max: null,
	pc_oil_rate_max: null,
	pc_gas_rate_max: null,
	pc_water_rate_max: null,
	pc_gor_max: null,
	pc_wor_max: null,
	alm_injection_max: null,
	alm_inj_gas_max: null,
	alm_inj_GLR_max: null,
	alm_formation_max: null,
	alm_water_cut_max: null,
	em_eur_max: null,
	em_npv_max: null,
	bhc_pressure_max: null,
	bhc_temp_max: null,
};

export const dataCheckPVT = [
	{
		name: "Bubble point pressure",
		check: false,
	},
	{
		name: "Solution Gas-Oil ratio",
		check: false,
	},
	{
		name: "Oil formation volume factor",
		check: false,
	},
	{
		name: "Oil viscosity",
		check: false,
	},
];

export const TYPE_LINE_TUNNING_GRAPH = [
	// Stroke Dasharray values
	["15 2", "5 3", "2 4", "10 10", "2 10 5 5"],

	// Line types
	["monotone", "monotone", "monotone", "monotone", "monotone"],

	// Stroke Width values
	[4, 2, 4, 2, 2],

	// stroke values
	["#FF5733", "#2b983e", "#3357FF", "#cb378c", "#31b3ad"],
];

export const INITIAL_DATA_TABLE_MULTI_RATE = [
	{
		id: 0,
		check: true,
		gas_rate: "",
		liquid_rate: "",
		water_cut: "",
		bottom_hole_flowing_pressure: "",
	},
	{
		id: 1,
		check: true,
		gas_rate: "",
		liquid_rate: "",
		water_cut: "",
		bottom_hole_flowing_pressure: "",
	},
	{
		id: 2,
		check: true,
		gas_rate: "",
		liquid_rate: "",
		water_cut: "",
		bottom_hole_flowing_pressure: "",
	},
];

export const INITIAL_DATA_SCENARIO = {
	data_scenario_chart: {
		name: "",
		date: "",
	},
	open_select_scenario: false,
	open_modal_save_scenario: false,
	execute_save_scenario: false,
};

export const OPTION_IPR_MODEL = {
	forchheimer_pseudo_press_prop: 1,
	pseudo_press_single_rate: 2,
	pseudo_press_multi_rate: 3,
	forchheimer_pseudo_press_multi_rate: 4,
};

export const OPTION_NAME_IPR_MODEL = {
	forchheimer_pseudo_press_prop: "reservoir_prop",
	pseudo_press_single_rate: "single-rate_test_c_n",
	pseudo_press_multi_rate: "multi-rate_test_c_n",
	forchheimer_pseudo_press_multi_rate: "multi-rate_test_a_b",
};

export const INITIAL_BODY_IPR_DRY_GAS = {
	press_res: 0,
	sgg_mix: 0,
	yn2: 0,
	yco2: 0,
	yh2s: 0,
	perm_gas: 0,
	temp_res: 0,
	dietz_factor: 0,
	h_res: 0,
	rw_in: 0,
	skin_factor: 0,
	area_acre: 0,
	h_perforated: 0,
	option: 0,
	gas_rate_test: 0,
	bhp_1P_test: 0,
	n_exponent_1P: 0,
	test_number: 0,
	gas_rate_test_faf: [],
	bhp_test: [],
};

export const NAME_ACTION_BUTTON = {
	well: "well_gas_lift",
	pvt: "pvt_data",
	reservoir: "reservoir_data",
	vlp: "vlp_tuning",
	inflow_outflow: "inflow_outflow_data",
	history: "history_data",
	multi_well: "multi_well",
};

export const REQUIRED_COLUMNS_DOCUMENT_HISTORY = [
	"date",
	"oil_rate",
	"water_rate",
	"gas_rate",
	"wellhead_pressure",
	"wellhead_tem",
	"injection_gas",
	"injection_pressure",
];

export const REQUIRED_COLUMNS_TABLE_DEVIATION = [
	"measured_depth",
	"tvd",
	"inc",
];

export const REQUIRED_COLUMNS_TABLE_EQUIPMENT = [
	"name",
	"type",
	"measured_depth",
	"internal_diameter",
	"external_diameter",
	"absolute_roughness",
];

export const REQUIRED_COLUMNS_TABLE_LAB_PVT = [
	"pressureTable1",
	"gas_oil_ratio",
	"oil_fvf",
	"oil_viscosity",
	"oil_compressibility",
];

export const REQUIRED_COLUMNS_TEMPLATE_ALERTS = ["item", "min", "max"];

export const REQUIRED_COLUMNS_DOCUMENT_IPR_DRYGAS = [
	"gas_rate",
	"liquid_rate",
	"water_cut",
	"bottom_hole_flowing_pressure",
];

export const DATA_MULTI_WELL = {
	well_name: "",
	data_range: '"',
	oil_meter_device: "",
	gas_meter_device: "",
	water_meter_device: "",
	liquid_meter_device: "",
	well_status: "",
	fluid_type: "",
	highest_tubing: "",
	current_tubing: "",
	gas_rate: "",
	liquid_rate: "",
	pressure_rate: "",
	prod_index: "",
};

export const INITIAL_STATE_PROCESS = {
	flow: false,
	dashboard: false,
	vlp: false,
	ipr: false,
	pvt: false,
};

export const INITIAL_INPUT_VOLUME_TRACKER = {
	production_history: {
		fcat: null,
		fcat_gas: null,
		water_cut: null,
		separator_type: "",
	},
};

// INITIAL DATA MULTI WELL

export const COLORS_LINE_MULTI_WELL = ["#82ca9d", "#3498db"];

export const REQUIRED_KEYS = [
	"test_date",
	"test_duration",
	"oil_rate",
	"water_rate",
	"gas_rate",
	"press_tubing_flowing",
	"press_tubing_shutin",
	"n_test",
];

export const INITIAL_DATA_MULTI_WELL = {
	well_id: "",
	well_name: "",
	// TABLE PRODUCTION TEST INFORMATION
	test_date: null,
	test_duration: null,
	oil_rate: null,
	water_rate: null,
	gas_rate: null,
	press_tubing_flowing: null,
	press_tubing_shutin: null,
	n_test: null,
	pointPI: null,
	pointPressDD: null,
	// TABLE MULTI WELL
	status_check: false,
	start_date: null,
	end_date: null,
	well_status: null,
	fluid_type: null,
	press_highest: null,
	current_tubing: null,
	pressure_change: null,
	pressure_draw_down: null,
	gas_rate_table: null,
	liquid_rate: null,
	prod_index: null,
	state_change: false,
};

// DATA FLUID DRY GAS

export const DATA_FLUID_DRY_GAS = {
	initialAutomaticData: [
		{
			id: 0,
			name: "From",
			from_temperature: "",
			from_pressure: "",
		},
		{
			id: 1,
			name: "To",
			to_temperature: "",
			to_pressure: "",
		},
		{
			id: 2,
			name: "Values",
			values_temperature: 1,
			values_pressure: "",
		},
	],
	initialUserSelectionData: [
		{
			id: 0,
			temperature: "",
			pressure: "",
		},
		{
			id: 1,
			temperature: "",
			pressure: "",
		},
		{
			id: 2,
			temperature: "",
			pressure: "",
		},
	],
	initialDataStateCheckCalc: [
		{
			name: "Z Factor",
			value: "z_factor",
			state: false,
		},
		{
			name: "Gas Viscosity",
			value: "visc_gas",
			state: false,
		},
		{
			name: "Gas Density",
			value: "den_gas_lbm_value",
			state: false,
		},
		{
			name: "Gas FVF",
			value: "fvf_gas_bbl",
			state: false,
		},
		{
			name: "Gas Compressibility",
			value: "compres_gas",
			state: false,
		},
		{
			name: "Vaporized Oil Ratio",
			value: "condensate_yield",
			state: false,
		}
	],
	initialDataStateChecklab : [
		{
			name: "Dew-point Pressure",
			check: false,
		},
		{
			name: "Vaporized Oil Ratio",
			check: false,
		},
	],
};

export const CONSTRUCT_INPUT_PVT_DRY_GAS = {
	reservoir_data: {
		res_pressure: [
			"Pressure (psia)",
			"Reservoir pressure must be between 14.7 and 25000 psia",
		],
		res_temperature: [
			"Temperature (deg F)",
			"Reservoir temperature must be between 70 and 350 deg F",
		],
		res_oil_gravity: ["Oil gravity (API)", "Maximum allowable value: 150"],
		res_gas_gravity: [
			"Gas gravity (Air = 1.000)",
			"Maximum allowable value: 5.0",
		],
		res_initial_gor: [
			"Initial Solution Gas-Oil Ratio (SCF/STB)",
			"Initial Solution Gas-Oil Ratio must be between 0 and 100000 SCF/STB",
		],
		res_H2S: ["H2S (mole fraction)", "H2S must be between 0 and 1 mole fraction"],
		res_CO2: ["CO2 (mole fraction)", "CO2 must be between 0 and 1 mole fraction"],
		res_N2: ["N2 (mole fraction)", "N2 must be between 0 and 1 mole fraction"],
		res_N2O: ["H2O (mole fraction)", "N2O must be between 0 and 1 mole fraction"],
		res_water_salinity: [
			"Water salinity (ppm)",
			"Water salinity must be between 0 and 250000 ppm",
		],
	},
	type_of_gas_reservoir: {
		key: "res_type_of_gas",
		label: "Type of Gas Reservoir",
		options: [
			{ value: "natural_gas", label: "Dry gas or Wet gas" },
			{ value: "condensate_gas", label: "Gas Condensate" },
		],
	},
	separator_data: {
		sep_primary_pressure: [
			"Primary Separator Pressure (psia)",
			"Separator pressure must be between 14.7 and 25000 psia",
		],
		sep_primary_temperature: [
			"Primary Separator Temperature (deg F)",
			"Separator temperature must be between 70 and 350 deg F",
		],
		sep_primary_gas_oil_ratio: [
			"Primary Separator Gas-Oil Ratio (SCF/STB)",
			"Gas-Oil Ratio must be between 0 and 100000 SCF/STB",
		],
		sep_primary_gas_gravity: [
			"Primary Separator Gas gravity (Air = 1.000)",
			"Maximum allowable value: 5.0",
		],
		sep_secondary_pressure: [
			"Secondary Separator Pressure (psia)",
			"Separator pressure must be between 14.7 and 25000 psia",
		],
		sep_secondary_temperature: [
			"Secondary Separator Temperature (deg F)",
			"Separator temperature must be between 70 and 350 deg F",
		],
		sep_secondary_gas_oil_ratio: [
			"Secondary Separator Gas-Oil Ratio (SCF/STB)",
			"Gas-Oil Ratio must be between 0 and 100000 SCF/STB",
		],
		sep_secondary_gas_gravity: [
			"Secondary Separator Gas gravity (Air = 1.000)",
			"Maximum allowable value: 5.0",
		],
		sep_stock_gas_oil_ratio: ["Stock-tank Gas-Oil Ratio (SCF/STB)", ""],
		sep_stock_gas_gravity: ["Stock-tank Gas gravity (Air = 1.000)", ""],
	},
	sep_number_separators: {
		key: "sep_number_separators",
		label: "Number of Separators",
		options: [
			{ value: 2, label: "2 separators (1 separator + stock tank)" },
			{ value: 3, label: "3 separators (2 separators + stock tank)" },
		],
	},
	reservoir_gas_composition: {
		data: [
			{
				component: "He",
				type: "Non hydrocarbon",
				critical_pressure: 32.9,
				critical_temperature: 9.34,
				molecular_weight: 4.003,
				molar_percent: 0,
			},
			{
				component: "H20",
				type: "Non hydrocarbon",
				critical_pressure: 3200.1,
				critical_temperature: 1164.85,
				molecular_weight: 18.015,
				molar_percent: 0,
			},
			{
				component: "N2",
				type: "Non hydrocarbon",
				critical_pressure: 493.1,
				critical_temperature: 227.16,
				molecular_weight: 28.013,
				molar_percent: 0,
			},
			{
				component: "H2S",
				type: "Non hydrocarbon",
				critical_pressure: 1306.0,
				critical_temperature: 672.35,
				molecular_weight: 34.08,
				molar_percent: 0,
			},
			{
				component: "CO2",
				type: "Non hydrocarbon",
				critical_pressure: 1071.0,
				critical_temperature: 547.58,
				molecular_weight: 44.01,
				molar_percent: 0,
			},
			{
				component: "C1",
				type: "Hydrocarbon",
				critical_pressure: 666.4,
				critical_temperature: 343.01,
				molecular_weight: 16.043,
				molar_percent: 0,
			},
			{
				component: "C2",
				type: "Hydrocarbon",
				critical_pressure: 706.5,
				critical_temperature: 549.59,
				molecular_weight: 30.07,
				molar_percent: 0,
			},
			{
				component: "C3",
				type: "Hydrocarbon",
				critical_pressure: 616.0,
				critical_temperature: 665.73,
				molecular_weight: 44.097,
				molar_percent: 0,
			},
			{
				component: "iC4",
				type: "Hydrocarbon",
				critical_pressure: 527.9,
				critical_temperature: 734.13,
				molecular_weight: 58.123,
				molar_percent: 0,
			},
			{
				component: "nC4",
				type: "Hydrocarbon",
				critical_pressure: 550.6,
				critical_temperature: 765.29,
				molecular_weight: 58.123,
				molar_percent: 0,
			},
			{
				component: "iC5",
				type: "Hydrocarbon",
				critical_pressure: 490.4,
				critical_temperature: 828.77,
				molecular_weight: 72.15,
				molar_percent: 0,
			},
			{
				component: "nC5",
				type: "Hydrocarbon",
				critical_pressure: 488.6,
				critical_temperature: 845.47,
				molecular_weight: 72.15,
				molar_percent: 0,
			},
			{
				component: "C6",
				type: "Hydrocarbon",
				critical_pressure: 436.9,
				critical_temperature: 913.27,
				molecular_weight: 86.177,
				molar_percent: 0,
			},
			{
				component: "C7+",
				type: "Hyd Pseudo Comp.",
				critical_pressure: null,
				critical_temperature: null,
				molecular_weight: 114.2,
				molar_percent: 0,
			},
		],
		columns: [
			{ key: "component", label: "Component" },
			{ key: "type", label: "Type" },
			{ key: "critical_pressure", label: "Critical Pressure (psia)" },
			{ key: "critical_temperature", label: "Critical Temperature (°R)" },
			{ key: "molecular_weight", label: "Molecular Weight (lbm/lb-mol)" },
			{
				key: "molar_percent",
				label: "Molar Percent",
				info: "Molar value must be between 0 and 100 percent",
			},
		],
		input: {
			name: "C7_specific_gravity",
			label: "C7 + specific gravity (dimensionless)",
			info: "C7 + specific gravity value must be between 0.55 and 5 dimensionless",
		},
	},
	select_pvt_calc_gas_1: [
		{
			key: "select_z_gas_compressibility_factor",
			label: "Z-Gas Compressibility Factor",
			options: [
				{ value: "brill_and_beggs", label: "Brill and Beggs" },
				{ value: "dranchuk_and_aboukassem", label: "Dranchuk and Abou-Kassem" },
				{ value: "papay", label: "Papay" },
			],
		},
		{
			key: "select_gas_viscosity",
			label: "Gas Viscosity",
			options: [
				{ value: "carr_kobayashi", label: "Carr et al" },
				{ value: "lee_and_gonzalez", label: "Lee et al" },
			],
		},
		{
			key: "select_gas_compressibility",
			label: "Gas Compressibility",
			options: [
				{ value: "brill_and_beggs", label: "Brill and Beggs" },
				{ value: "papay", label: "Papay" },
			],
		},
	],
	select_pvt_calc_gas_2: [
		{
			key: "select_dew_point",
			label: "Dew-point Pressure",
			options: [
				{ value: "ovalle", label: "Ovalle" },
				{ value: "nemeth_and_kennedy", label: "Nemeth and Kennedy" },
			],
		},
		{
			key: "select_vaporized",
			label: "Vaporized Oil Ratio",
			options: [
				{ value: "ovalle", label: "Ovalle" },
				{ value: "el_banbi", label: "El-Banbi et. al." },
			],
		},
	],
	select_pvt_calc_oil: [
		{
			key: "select_pb_rs_bo",
			label: "Rs, Bo",
			options: [
				{ value: "standing_modified", label: "Standing after El-Banbi et. al. (2006)" },
				{ value: "vasquez_begss_modified", label: "Vasquez and Beggs after El-Banbi et. al. (2006)" },
			],
		},
		{
			key: "select_oil_viscosity",
			label: "Oil Viscosity",
			options: [
				{ value: "beggs_and_robinson", label: "Beggs and Robinson" },
				{ value: "glaso", label: "Glaso; Beggs and Robinson" },
			],
		},
	],
	select_data_point_calc: {
		key: "option_data_point",
		options: [
			{ value: "automatic", label: "Automatic" },
			{ value: "user_selection", label: "User selection" },
		],
	},
	result_data_pvt: {
		gas_mixture_molecular: {
			name: "gas_mixture_molecular",
			label: "Gas mixture molecular weight (lbm/lb-mol)",
		},
		gas_mixture_specific: {
			name: "gas_mixture_specific",
			label: "Gas mixture specific gravity (dimensionless)",
		},
	},
	result_data_pvt_condensate : {
		name: "dew_point_pressure",
		label: "Dew-point Pressure (psia)",
	},
	state_check_specific_gravity: [
		{
			value: 1,
			name: "User defined",
			infoTooltip:
				"The reservoir information must be completed to enable this option",
		},
		{
			value: 2,
			name: "Calculate from separator data",
			infoTooltip:
				"Must be correctly completed data: The Primary Separator Pressure, Primary Separator Temperature, Primary Separator Gas-Oil Ratio, Primary Separator Gas gravity and Number of Separators",
		},
		{
			value: 3,
			name: "Calculate from gas composition",
			infoTooltip:
				"The sum of molar percent values in Reservoir Gas Composition must be 100%",
		},
	],
	state_check_critical_propierties: [
		{ value: 1, name: "Calculate from correlation" },
		{ value: 2, name: "Calculate from gas composition" },
	],
	state_check_chart_calc: {
		values: {
			z_factor: "Z Factor",
			visc_gas: "Gas Viscosity (cp)",
			den_gas_lbm_value: "Gas Density (lbm/ft³)",
			fvf_gas_bbl: "Gas FVF (ft3/scf)",
			compres_gas: "Gas Compressibility (1/psi)",
			condensate_yield: "Vaporized Oil Ratio (stb/MMscf)",
		},
		colors: {
			z_factor: "#5D7EC6",
			visc_gas: "#FFC737",
			den_gas_lbm_value: "#dc2626",
			fvf_gas_bbl: "#008A15",
			compres_gas: "#ff7300",
			condensate_yield: "#4c0519",
		},
	},
	fluid_lab_data: {
		temperature_lab: {
			name: "temperature_lab",
			label: "Temperature (deg F)",
			info: "Reservoir temperature must be between 70 and 350 deg F",
		},
		vaporized_lab: {
			name: "vaporized_lab",
			label: "Vaporized Oil Ratio at Pd (stb/MMscf)",
			info: "Vaporized Oil Ratio at Pd must be between 0 and 500 stb/MMscf",
		},
		dew_point_lab: {
			name: "dew_point_lab",
			label: "Dew-point Pressure (psia)",
			info: "Dew-point Pressure must be between 25 and 25000 psia",
		},
	}
};
