import { config } from "../../config/config";
import { validateParams } from "../utils/utils";

const DB_URL_PLOTTING = config.API.PLOTTING.URL_PLOTTING;
const DB_URL_PARAMETERS_PLOTTING = config.API.PLOTTING.URL_PARAMETERS_PLOTTING;
const TOKEN_PLOTTING = config.AUTH.TOKEN_PLOTTING;
const TOKEN_PARAMETERS_PLOTTING = config.AUTH.TOKEN_PARAMETERS_PLOTTING;

export const getListDevicesPlotting = async (
	operator,
	lease,
	region,
	platforms,
) => {
	if (!validateParams(operator, lease, region, platforms)) return;

	try {
		const response = await fetch(
			`${DB_URL_PLOTTING}/device_list_plotting/${operator}/${lease}/${region}/${platforms}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PLOTTING,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getListParametersPlotting = async (
	operator,
	lease,
	region,
	platforms = [],
	devices = [],
) => {
	if (!validateParams(operator, lease, region)) return;

	try {
		const filteredDevices = devices
			.filter((device) =>
				platforms.some((platform) =>
					device.toLowerCase().startsWith(platform.toLowerCase()),
				),
			)
			.map((device) => {
				const matchingPlatform = platforms.find((platform) =>
					device.toLowerCase().startsWith(platform.toLowerCase()),
				);
				return matchingPlatform
					? device.substring(matchingPlatform.length).trim()
					: device;
			});

		const response = await fetch(
			`${DB_URL_PLOTTING}/parameterlist/${operator}/${lease}/${region}?platforms=${platforms}&devices=${filteredDevices}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PLOTTING,
				},
			},
		);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getParametersPlotting = async (operator, lease, region, user) => {
	if (!validateParams(operator, lease, region, user)) return;

	try {
		const user_modify = user?.replace(/\./g, "_");
		const response = await fetch(
			`${DB_URL_PARAMETERS_PLOTTING}/plotting/get/${operator}/${lease}/${region}/${user_modify}/plotting`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PARAMETERS_PLOTTING,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const createParametersPlotting = async (parameters) => {
	const { operator, lease, region, user, data } = parameters;
	if (!validateParams(operator, lease, region, user, data)) return;

	try {
		const user_modify = user?.replace(/\./g, "_");
		const response = await fetch(
			`${DB_URL_PARAMETERS_PLOTTING}/plotting_input/upload_plotting/${operator}/${lease}/${region}/${user_modify}/plotting`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PARAMETERS_PLOTTING,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem creating plotting parameters");
	}
};

export const updateParametersPlotting = async (parameters) => {
	const { operator, lease, region, user, data } = parameters;
	if (!validateParams(operator, lease, region, user, data)) return;

	try {
		const user_modify = user?.replace(/\./g, "_");
		const response = await fetch(
			`${DB_URL_PARAMETERS_PLOTTING}/plotting_update/update_plotting/${operator}/${lease}/${region}/${user_modify}/plotting`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PARAMETERS_PLOTTING,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem updating plotting parameters");
	}
};

export const deleteParametersPlotting = async (parameters) => {
	const { clients, projects, platforms, user, name_scenario, data } = parameters;
	if (!validateParams(clients, projects, platforms, user, name_scenario)) return;

	try {
		const user_modify = user?.replace(/\./g, "_");
		const response = await fetch(
			`${DB_URL_PARAMETERS_PLOTTING}/parameters/${clients}/${projects}/${platforms}/${user_modify}/${name_scenario}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": TOKEN_PARAMETERS_PLOTTING,
				},
				body: JSON.stringify(data),
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem deleting plotting parameters");
	}
};
/// New endpoints

export const getPlatformsListPlotting = async (operator, lease, region) => {
	if (!validateParams(operator, lease, region)) return;

	try {
		const response = await fetch(
			`${DB_URL_PLOTTING}/platforms_list_plotting/${operator}/${lease}/${region}`,
			{
				method: "GET",
				headers: {
					"x-api-key": TOKEN_PLOTTING,
				},
			},
		);
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getDevicesListPlotting = async (
	operator,
	lease,
	region,
	platforms,
) => {
	if (!validateParams(operator, lease, region, platforms) || !platforms.length)
		return;

	try {
		// Construcción directa del parámetro platformNames
		const queryString = `platformNames=${JSON.stringify(platforms)}`;
		const url = `${DB_URL_PLOTTING}/platform_devices_plotting/${operator}/${lease}/${region}?${queryString}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				"x-api-key": TOKEN_PLOTTING,
			},
		});
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getParametersListPlotting = async (
	operator,
	lease,
	region,
	platformDevices,
) => {

	if (
		!validateParams(
			operator,
			lease,
			region,
			platformDevices || !platformDevices.length,
		)
	)
		return;
		const encodedDevices = encodeURIComponent(platformDevices);

	try {
		const queryString = `platformDevices=${encodedDevices}`;
		const url = `${DB_URL_PLOTTING}/parameter_list_plotting/${operator}/${lease}/${region}?${queryString}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"x-api-key": TOKEN_PLOTTING,
			},
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};

export const getIOTWellData = async (
	operator,
	lease,
	region,
	parameters,
	startDate,
	endDate,
) => {
	if (!validateParams(operator, lease, region, parameters, startDate, endDate))
		return;
	const encodedParameters = encodeURIComponent(parameters);
	try {
		const queryString = `parameters=${encodedParameters}`;
		const queryStarDate = `endDate=${endDate}`;
		const queryEndDate = `startDate=${startDate}`;
		const url = `${DB_URL_PLOTTING}/iot_well_data_plotting/${operator}/${lease}/${region}?${queryString}&${queryStarDate}&${queryEndDate}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				"x-api-key": TOKEN_PLOTTING,
			},
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		return response.json();
	} catch (error) {
		throw new Error("Problem getting the information");
	}
};
