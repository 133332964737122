import { useContext, useState } from "react";
import { AuthContext } from "../auth/context/AuthContext";
import { useMutation, useQuery } from "react-query";
import { message } from "antd";
import {
	udpateJsonPVT,
	updateJsonDataPVT,
	uploadJsonDataPVT,
	uploadJsonPVT,
	getCalculationsParameters,
	getCalculationsDeviaton,
	getRS,
	uploadJsonTablePVT,
	uploadPVT,
	getCalculationsDeviatonPB,
	getRSTuned,
	getBOTuned,
	getVisoTuned,
	getPBTuned,
	getCalculationPb,
	getSepPress,
	getSepCalculate,
	getCo,
	getViso,
	getBo,
	postJsonPVTCalcDryGas,
	getPVTCalcDryGas,
} from "../services/api/pvtAPI";
import {
	calculateQIPR,
	dataGraphic,
	dataReservoirDryGas,
	deleteScenarioReservoir,
	listDataGraphic,
	uploadaDataGraphic,
	uploadaDataReservoirDryGas,
	uploadJsonIPR,
	deleteScenariosReservoir,
	getListDataChartIPRGas,
	getDataChartIPRGas,
	postDataChartIPRGas,
	deleteScenarioChartIPRGas,
	dataJsonIPR,
} from "../services/api/iprAPI";
import {
	createWellTranding,
	updateWellTranding,
	getPressureChanges,
	getPressureDrawDown,
	getProductivityIndex,
	getFluidType,
	getListMultiWell,
} from "../services/api/multiWellTrandingAPI";
import { getListTuning } from "../services/api/tuningAPI";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { DataGasLiftContext } from "../context/DataGasLift/DataGasLiftContext";
import {
	createParametersGasLift,
	deleteParametersGasLift,
	getListWellGasLift,
	getParametersGasLift,
	updateParametersGasLift,
	getDataHistory,
	createDataHistory,
	updateDataHistory,
	deleteDataHistory,
	getListDataHistory,
	getWellsUserGasLift,
	uploadJsonVolumeTracker,
	getJsonVolumeTracker,
	getJsonChartGL_well_head,
	getJsonChartGL_well_head_inj,
	getChartProductionData,
	getDataAllocation,
	updloadFile3D,
	getDataGraph3D,
} from "../services/api/gasLiftAPI";
import {
	GAS_LIFT,
	MULTI_WELL,
	STATUS_CODE_CALCULATE_IPR,
	STATUS_DATA_GAS_LIFT,
	STATUS_TYPE_CALCULATE_IPR,
	STATUS_TYPE_CALCULATE_PVT,
} from "../components/elements/types/type_response_API";
import { WELL_SENSOR_DEVICE } from "../components/elements/types/type_dashboard";
import {
	DATA_RESERVOIR,
	INITIAL_STATE_PROCESS,
} from "../components/elements/types/type_gas_lift";
import { useFilters } from "./useFilters";
import { useNavigate } from "react-router-dom";
import { NAME_PAGES } from "../components/elements/types/type_pages";

const initialDataDeviationApi = {
	id: 0,
	md: [],
	tvd: [],
	inc: [],
	t: null,
	p: null,
};

const initialDataEquipmentApi = {
	id: 0,
	name: "",
	type: "",
	measured_depth: "",
	internal_diameter: "",
	external_diameter: "",
	absolute_roughness: "",
};

const initiailInputPropiertiesOut = {
	Pb: "",
	Bo: "",
	Uo: "",
	Deno: "",
	SigmaO: "",
	Rs: "",
	Bg: "",
	Ug: "",
	Deng: "",
	Bw: "",
	Uw: "",
	Denw: "",
	SigmaW: "",
};

const initiailInputCalculatedLab = {
	BoUntuned: null,
	PbUntuned: null,
	RsUntuned: null,
	VisoUntuned: null,
	CoUntuned: null,
	BoTuned: null,
	PbTuned: null,
	RsTuned: null,
	VisoTuned: null,
	CoTuned: null,
};

const initialDataTableLab = [
	{
		pressureLab: null,
		BoExp: null,
		RsExp: null,
		VisoExp: null,
		CoExp: null,
		BoUntuned: null,
		RsUntuned: null,
		VisoUntuned: null,
		CoUntuned: null,
		BoTuned: null,
		RsTuned: null,
		VisoTuned: null,
		CoTuned: null,
	},
];

export const useTabGaslift = () => {
	const navigate = useNavigate();
	const { User } = useContext(AuthContext);
	const {
		getUserDataInfo,
		userDataInfo,
		selectedWell,
		getSelectedWell,
		deviceRealTime,
		getDeviceRealTime,
	} = useContext(DashboardContext);
	const {
		dataWell,
		getDataWell,
		getDataFilterUserGaslift,
		dataFilterUserGasLift,
		dataCreated,
		getDataCreated,
		enableCheckLab,
		lastSelectDataIPR,
		getSelectDataIPR,
		viewLoadingGasLift,
		getViewLoadingGasLift,
		stateMessageApis,
		getStateMessageAlert,
	} = useContext(DataGasLiftContext);
	const { dataFilter, mutationUpdateFilter } = useFilters();
	const [dataDeviationApi, setDataDeviationApi] = useState(
		initialDataDeviationApi,
	);
	const [dataEquipmentApi, setDataEquipmentApi] = useState(
		initialDataEquipmentApi,
	);
	const [bodyAddWell, setBodyAddWell] = useState([]);
	const [inputPropertiesOut, setInputPropertiesOut] = useState(
		initiailInputPropiertiesOut,
	);
	const [viewFluidLab, setViewFluidLab] = useState(false);
	const [inputCalculatedLab, setInputCalculatedLab] = useState(
		initiailInputCalculatedLab,
	);
	const [dataTableLab, setDataTableLab] = useState(initialDataTableLab);
	const [executeTuned, setExecuteTuned] = useState(false);
	const [executeTunedCorrelation, setExecuteTunedCorrelation] = useState(false);
	const [indexTableLab, setIndexTableLab] = useState(0);
	const [dataTableIPR, setDataTableIPR] = useState();
	const [dataValuePb, setDataValuePb] = useState([]);
	const [userMultiselect, setUserMultiselect] = useState();
	const [inputReservoir, setInputReservoir] = useState(
		DATA_RESERVOIR.initialInputReservoir,
	);
	const [dataWellGL, setDataWellGL] = useState([]);
	const [statedataListIPR, setDataListIPR] = useState(false);
	const [optionSelectedScenario, setOptionSelectedScenario] = useState();

	//FUNCIONES
	const transformData = (dataTableIPR) => {
		if (!inputReservoir || !inputReservoir.ipr_model || !dataTableIPR) return [];

		const { ipr_model } = inputReservoir;
		const { qo, steps } = dataTableIPR;

		const transformModelData = (qoModel) => {
			if (!qoModel || !Array.isArray(qoModel) || qoModel.length === 0) {
				return [];
			}

			return qoModel.map((qoValue, index) => ({
				qo: qoValue,
				steps: steps[index].toFixed(2),
			}));
		};

		const transformCompositeData = () => {
			if (
				!qo.qliq_pet ||
				!Array.isArray(qo.qliq_pet) ||
				(qo.qliq_pet.length === 0) | !Array.isArray(qo.qo_pet) ||
				(qo.qo_pet.length === 0) | !Array.isArray(qo.qw_pet) ||
				qo.qw_pet.length === 0
			) {
				return [];
			}

			return qo.qliq_pet.map((_, index) => ({
				qliq_pet: qo.qliq_pet[index],
				qo_pet: qo.qo_pet[index],
				qw_pet: qo.qw_pet[index],
				steps: steps[index].toFixed(2),
			}));
		};

		switch (ipr_model) {
			case "vogel":
				return transformModelData(qo.vogel);
			case "fetko":
				return transformModelData(qo.fetko);
			case "straight":
				return transformModelData(qo.straight);
			case "composite":
				return transformCompositeData();
			default:
				return qo
					? Object.keys(qo).flatMap((model) =>
						transformModelData(qo[model]).map((data) => ({ ...data, model })),
					)
					: [];
		}
	};

	const decimalRound = (number, round) => {
		const numVal = Array.isArray(number) ? number[0] : number;
		let valRound = Number(numVal.toFixed(round));
		return valRound;
	};

	//LLAMADOS API
	const {
		data: chartWellHeadProd,
		isLoading: loadDataChartGL_well_head,
		isError: ErrorDataChartGL_well_head,
		refetch: refetchChartWellheadProd,
	} = useQuery({
		queryKey: ["chartWellHeadProd"],
		queryFn: () =>
			getJsonChartGL_well_head(
				"500",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				dataFilterUserGasLift.well_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: chartWellheadInj,
		isLoading: loadDataChartGL_well_head_inj,
		isError: ErrorDataChartGL_well_head_inj,
		refetch: refetchChartWellheadInj,
	} = useQuery({
		queryKey: ["chartWellheadInj"],
		queryFn: () =>
			getJsonChartGL_well_head_inj(
				"500",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: chartProductionData,
		isLoading: loadChartProductionData,
		isError: errorChartProductionData,
		refetch: refetchChartProductionData,
	} = useQuery({
		queryKey: ["chartProductionData"],
		queryFn: () =>
			getChartProductionData(
				"500",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataWellGL.county,
				dataWellGL.field,
				dataWellGL.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataWellGasLift,
		isLoading: loadDataWellGasLift,
		isError: errorDataWellGasLift,
		refetch: refetchDataWellGasLift,
		isFetching: isFechingDataWellGasLift,
	} = useQuery({
		queryKey: ["get_data_gas_lift"],
		queryFn: () =>
			getParametersGasLift(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (data?.statusType != "ERROR") {
				const filtered = WELL_SENSOR_DEVICE?.data.filter(
					(item) => item.name === data?.data?.well_platforms,
				);
				const isEmptyObject = (obj) => {
					return Object.keys(obj).length === 0 && obj.constructor === Object;
				};
				const validateSelectedPlatform = isEmptyObject(
					deviceRealTime?.device_gaslift?.selected_platform_graph,
				);
				const validateSelectedDevice = isEmptyObject(
					deviceRealTime?.device_gaslift?.selected_device_graph,
				);
				if (validateSelectedPlatform || validateSelectedDevice) {
					getDeviceRealTime({
						...deviceRealTime,
						list_device_gaslift: filtered,
						device_gaslift: {
							...deviceRealTime.device_gaslift,
							platform: data?.data?.well_platforms,
							discretized: data?.data?.discretized_well,
							selected_platform_graph: {
								chartProdHistory: data?.data?.well_platforms,
								chartWellCond: data?.data?.well_platforms,
								chartHole: data?.data?.well_platforms,
							},
							selected_device_graph: {
								chartProdHistory: null,
								chartWellCond: null,
								chartHole: null,
							},
						},
					});
				}
			}
		},
		onError: (error) => { },
	});

	const {
		data: listWellsGasLift,
		isLoading: loadListWellsGasLift,
		isError: ErrorListWellsGasLift,
		refetch: refetchListWellsGasLift,
	} = useQuery({
		queryKey: ["get_list_wells_user_gas_lift"],
		queryFn: () =>
			getWellsUserGasLift(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});

			if (stateMessageApis.pvt || stateMessageApis.flow || stateMessageApis.ipr) {
				navigate(`/${NAME_PAGES.gas_lift.list_well}`);
				getStateMessageAlert(INITIAL_STATE_PROCESS);
			}
		},
		onError: (error) => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});

			if (stateMessageApis.pvt || stateMessageApis.flow || stateMessageApis.ipr) {
				navigate(`/${NAME_PAGES.gas_lift.list_well}`);
				getStateMessageAlert(INITIAL_STATE_PROCESS);
			}
		},
	});

	const {
		data: wellsUserGasLift,
		isLoading: loadWellsUserGasLift,
		isError: ErrorWellsUserGasLift,
		refetch: refetchWellsUserGasLift,
	} = useQuery({
		queryKey: ["get_list_wells_gas_lift"],
		queryFn: () =>
			getListWellGasLift(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				userMultiselect,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: listRS,
		isLoading: loadListRS,
		isError: ErrorloadListRS,
		refetch: refetchloadListRS,
	} = useQuery({
		queryKey: ["get_list_rs"],
		queryFn: () =>
			getRS(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				enableCheckLab ? "_tuned_calc" : "",
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: listBo,
		isLoading: loadListBo,
		isError: ErrorloadListBo,
		refetch: refetchloadListBo,
	} = useQuery({
		queryKey: ["get_list_bo"],
		queryFn: () =>
			getBo(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				enableCheckLab ? "_tuned_calc" : "",
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: listViso,
		isLoading: loadListViso,
		isError: ErrorloadListViso,
		refetch: refetchloadListViso,
	} = useQuery({
		queryKey: ["get_list_viso"],
		queryFn: () =>
			getViso(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				enableCheckLab ? "_tuned_calc" : "",
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: listCo,
		isLoading: loadListCo,
		isError: ErrorloadListCo,
		refetch: refetchloadListCo,
	} = useQuery({
		queryKey: ["get_list_co"],
		queryFn: () =>
			getCo(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				enableCheckLab ? "_tuned_calc" : "",
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const mutationSaveDataGasLift = useMutation({
		mutationFn: createParametersGasLift,
		onSuccess: (data, dataMutation) => {
			if (data?.statusType === GAS_LIFT.DATA_WELL.TYPE.SUCCESS) {
				async function executeFunction() {
					await mutationUpdateFilter.mutateAsync({
						user: User.user_name,
						data: {
							...dataFilter?.data,
							checkedWellGaslift: dataMutation.well,
						},
					});

					getSelectedWell(dataMutation.well ?? null);

					await refetchListWellsGasLift();

					getViewLoadingGasLift({
						...viewLoadingGasLift,
						loadSaveParameters: false,
					});
					navigate(`/${NAME_PAGES.gas_lift.list_well}`);
					message.success(`Data Well Gas lift Saved Successfull`);
				}
				executeFunction();
			} else {
				message.error(`An error has occurred when loading the data Well Gas lift`);
				getViewLoadingGasLift({
					...viewLoadingGasLift,
					loadSaveParameters: false,
				});
			}
		},
		onError: (error) => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred when loading the data Well Gas lift`);
		},
	});

	const mutationSaveDataIPR = useMutation({
		mutationFn: uploadJsonIPR,
		onSuccess: () => {
			message.success(`IPR Data Saved Successfull`);
			setTimeout(() => {
				refetchListqIPR();
			}, 500);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the IPR Data`);
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadDataInOutFlow: false,
			});
			getStateMessageAlert({ ...stateMessageApis, flow: false });
		},
	});

	const {
		data: listqIPR,
		isLoading: loadListqIPR,
		isError: ErrorListqIPR,
		refetch: refetchListqIPR,
	} = useQuery({
		queryKey: ["get_QIPR"],
		queryFn: () =>
			calculateQIPR(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			if (data?.statusType === "ERROR") {
				setDataListIPR(true);
			} else if (data?.statusType === "SUCCESS") {
				const dataReservoior = data.data;
				setDataTableIPR(transformData(dataReservoior));
				setDataListIPR(true);
			}
		},
		onError: (error) => { },
	});

	const {
		data: dataFile3D,
		isLoading: loadDataFile3D,
		isError: ErrorDataFile3D,
		refetch: refetchDataFile3D
	} = useQuery({
		queryKey: ["get_file3D"],
		queryFn: () =>
			getDataGraph3D(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		enabled: false,
		onSuccess: (data) => {

		},
		onError: (error) => {

		},
	});

	const mutationUpdateDataGasLift = useMutation({
		mutationFn: updateParametersGasLift,
		onMutate: () => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: true,
			});
		},

		onSuccess: (data) => {
			if (data?.statusType === GAS_LIFT.DATA_WELL.TYPE.SUCCESS) {
				refetchListWellsGasLift();
				message.success(`Well Data Gas Lift Updated Successfull`);
			} else {
				message.error(`An error occurred while updating Well Gas Lift data`);
				getViewLoadingGasLift({
					...viewLoadingGasLift,
					loadSaveParameters: false,
				});
			}
		},
		onError: (error) => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
			message.error(`An error occurred while updating Well Gas Lift data`);
		},
	});


	const mutationUploadFile3D = useMutation({
		mutationFn: updloadFile3D,
		onMutate: () => {
		},

		onSuccess: (data) => {
			refetchDataFile3D();
		},
		onError: (error) => {
		},
	});

	const mutationDeleteDataGasLift = useMutation({
		mutationFn: deleteParametersGasLift,
		onMutate: () => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: true,
			});
		},
		onSuccess: () => {
			message.success(`Data deleted Successfull`);
			refetchListWellsGasLift();
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
		},
		onError: (error) => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
			message.error(
				`An error has occurred when loading the Activity Distribution Graph`,
			);
		},
	});

	const mutationUploadJsonPVT = useMutation({
		mutationFn: uploadJsonPVT,
		onSuccess: () => {
			message.success(`Uploaded Data Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the PVT data`);
		},
	});

	const mutationUploadPVT = useMutation({
		mutationFn: uploadPVT,
		onSuccess: () => {
			message.success("Uploaded Data Successfully");
		},
		onError: (error) => {
			message.error("An error occurred when uploading the PVT data");
		},
	});

	const mutationUploadJsonDataPVT = useMutation({
		mutationFn: uploadJsonDataPVT,
		onSuccess: () => {
			message.success(`Uploaded PVT Data Successfull`);
		},
		onError: () => {
			message.error(`An error has occurred when loading the PVT data`);
		},
	});

	const mutationUpdateJsonPVT = useMutation({
		mutationFn: udpateJsonPVT,
		onSuccess: () => {
			//message.success(`Update PVT Data Successfull`)
		},
		onError: (error) => {
			message.error(`An error has occurred when updating the PVT data`);
		},
	});

	const mutationUpdateJsonDataPVT = useMutation({
		mutationFn: updateJsonDataPVT,
		onSuccess: () => {
			// message.success(`PVT Data Updated Successfull`)
		},
		onError: () => {
			message.error(`An error has occurred when updating PVT data`);
		},
	});

	const generateRadomId = () => {
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const lowercaseLettersAndNumbers = "abcdefghijklmnopqrstuvwxyz0123456789";
		let id = "";

		// Generar los primeros tres caracteres con letras mayúsculas
		for (let i = 0; i < 3; i++) {
			const randomUppercaseLetter = uppercaseLetters.charAt(
				Math.floor(Math.random() * uppercaseLetters.length),
			);
			id += randomUppercaseLetter;
		}

		// Generar el resto de caracteres con letras minúsculas y números
		const remainingLength = 10 - id.length; // Asegurarse de que la longitud total sea al menos 10
		for (let i = 0; i < remainingLength; i++) {
			const randomChar = lowercaseLettersAndNumbers.charAt(
				Math.floor(Math.random() * lowercaseLettersAndNumbers.length),
			);
			id += randomChar;
		}

		return id;
	};

	const calculateDomain = (value, domain) => {
		const allValues = value.reduce((acc, obj) => {
			Object.values(obj).forEach((value) => {
				acc.push(value);
			});
			return acc;
		}, []);

		switch (domain) {
			case "max":
				const maxValue = Math.max(...allValues);
				const roundedMax = Math.ceil(Math.round(maxValue * 10) / 100) * 10;
				return roundedMax;
			case "min":
				const minValue = Math.min(...allValues);
				const roundedMin = Math.floor(Math.round(minValue * 10) / 100) * 10;
				return roundedMin;
			default:
				return null;
		}
	};

	const {
		data: listDataTuning,
		isLoading: loadListDataTuning,
		isError: errorListDataTuning,
		refetch: refetchDataTuning,
	} = useQuery({
		queryKey: ["ListDataTuning"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getListTuning(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});

			if (stateMessageApis.vlp) {
				navigate(`/${NAME_PAGES.gas_lift.list_well}`);
				getStateMessageAlert(INITIAL_STATE_PROCESS);
			}
		},
		onError: () => {
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadSaveParameters: false,
			});
			if (stateMessageApis.vlp) {
				navigate(`/${NAME_PAGES.gas_lift.list_well}`);
				getStateMessageAlert(INITIAL_STATE_PROCESS);
			}
		},
	});

	const mutationDataTablePVT = useMutation({
		mutationFn: uploadJsonTablePVT,
		onSuccess: () => {
			//message.success(`Fluid lab data Saved Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the IPR Data`);
		},
	});

	const {
		data: dataParameters,
		isLoading: loadDataParameters,
		isError: ErrorDataParameters,
		refetch: refetchDataParameters,
	} = useQuery({
		queryKey: ["calculationsParameters"],
		queryFn: () =>
			getCalculationsParameters(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataDeviationPB,
		isLoading: loadDataDeviationPB,
		isError: ErrorDataDeviationPB,
		refetch: refetchDataDeviationPB,
	} = useQuery({
		queryKey: ["calculationsDataDeviationPB"],
		queryFn: () =>
			getCalculationsDeviatonPB(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataDeviation,
		isLoading: loadDataDeviation,
		isError: ErrorDataDeviation,
		refetch: refetchDataDeviation,
	} = useQuery({
		queryKey: ["calculationsDataDeviation"],
		queryFn: () =>
			getCalculationsDeviaton(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: valuePb,
		isLoading: loadValuePb,
		isError: ErrorValuePb,
		refetch: refetchValuePb,
	} = useQuery({
		queryKey: ["calculationsPb"],
		queryFn: () =>
			getCalculationPb(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				enableCheckLab ? "_tuned_calc" : "",
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataPBTuned,
		isLoading: loadDataPBTuned,
		isError: ErrorDataPBTuned,
		refetch: refetchDataPBTuned,
	} = useQuery({
		queryKey: ["dataPBTuned"],
		queryFn: () =>
			getPBTuned(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataRSTuned,
		isLoading: loadDataRSTuned,
		isError: ErrorDataRSTuned,
		refetch: refetchDataRSTuned,
	} = useQuery({
		queryKey: ["dataRSTuned"],
		queryFn: () =>
			getRSTuned(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataBOTuned,
		isLoading: loadDataBOTuned,
		isError: ErrorDataBOTuned,
		refetch: refetchDataBOTuned,
	} = useQuery({
		queryKey: ["dataBOTuned"],
		queryFn: () =>
			getBOTuned(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataVisoTuned,
		isLoading: loadDataVisoTuned,
		isError: ErrorDataVisoTuned,
		refetch: refetchDataVisoTuned,
	} = useQuery({
		queryKey: ["dataVisoTuned"],
		queryFn: () =>
			getVisoTuned(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataSepPress,
		isLoading: loadDataSepPress,
		isError: ErrorDataSepPress,
		refetch: refetchDataSepPress,
	} = useQuery({
		queryKey: ["dataSepPress"],
		queryFn: () =>
			getSepPress(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataSepCalculate,
		isLoading: loadDataSepCalculate,
		isError: ErrorDataSepCalculate,
		refetch: refetchDataSepCalculate,
	} = useQuery({
		queryKey: ["dataSepCalculate"],
		queryFn: () =>
			getSepCalculate(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				!enableCheckLab ? "_calc" : "",
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	//DATA HISTORY REQUEST
	const {
		data: dataHistoryGasLfit,
		isLoading: loadDataHistory,
		isError: errorDataHistory,
		refetch: refetchDataHistory,
	} = useQuery({
		queryKey: ["DataHistory"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getDataHistory(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onError: (error) => {
			// setEnabledDataHistory(false);
		},
		onSuccess: (data) => {
			// setEnabledDataHistory(false);
		},
	});

	const mutationCreateDataHistory = useMutation({
		mutationFn: createDataHistory,
		onSuccess: () => {
			setTimeout(() => {
				refetchDataHistory();
				refetchListWellsGasLift();
			}, 200);
			message.success(`Data History Upload Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when Upload`);
		},
	});

	const mutationDeleteDataHistory = useMutation({
		mutationFn: deleteDataHistory,
		onSuccess: () => {
			setTimeout(() => {
				refetchDataHistory();
				refetchListWellsGasLift();
			}, 200);
			message.success(`Data History Delete Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when delete`);
		},
	});

	const mutationUpdateDataHistory = useMutation({
		mutationFn: updateDataHistory,
		onSuccess: () => {
			setTimeout(() => {
				refetchDataHistory();
				refetchListWellsGasLift();
			}, 200);
			message.success(`Data History Update Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when Upload`);
		},
	});

	const {
		data: queryListDataHistory,
		isLoading: loadListDataHistory,
		isError: errorListDataHistory,
		refetch: refetchListDataHistory,
	} = useQuery({
		queryKey: ["listDataHistory"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getListDataHistory(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => {
			// setEnabledDataHistory(false);
		},
	});

	const {
		data: dataAllocation,
		isLoading: loadDataAllocation,
		isError: ErrorDataAllocation,
		refetch: refetchDataAllocation,
	} = useQuery({
		queryKey: ["get_data_allocation"],
		queryFn: () =>
			getDataAllocation(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: dataVolumeTracker,
		isLoading: loadVolumeTracker,
		isError: ErrorloadVolumeTracker,
		refetch: refetchVolumeTracker,
	} = useQuery({
		queryKey: ["get_VolumTracker"],
		queryFn: () =>
			getJsonVolumeTracker(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const mutationUploadJsonVolumeTracker = useMutation({
		mutationFn: uploadJsonVolumeTracker,
		onSuccess: () => {
			message.success(`Uploaded Data Successfull`);
			setTimeout(() => {
				refetchVolumeTracker();
			}, 500);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the PVT data`);
		},
	});

	//saved, listing and delete of the data saved of the graphic of IPR

	const {
		data: queryListDataGraphic,
		isLoading: loadListDataGraphic,
		isError: errorListDataGraphic,
		refetch: refetchListDataGraphic,
	} = useQuery({
		queryKey: ["ListDataGraphicsIPR"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			listDataGraphic(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			// message.success(`Data query Successfull`);
		},
		onError: (error) => { },
	});

	const {
		data: queryDataGraphic,
		isLoading: loadDataGraphic,
		isError: errorDataGraphic,
		refetch: refetchDataGraphic,
	} = useQuery({
		queryKey: ["dataGraphicsIPR"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			dataGraphic(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				lastSelectDataIPR,
			),
		onSuccess: (data) => {
			// message.success(`Data query Successfull`);
		},
		onError: (error) => { },
	});

	const {
		data: queryDataJsonIPR,
		isLoading: loadDataJsonIPR,
		isError: errorDataJsonIPR,
		refetch: refetchDataJsonIPR,
	} = useQuery({
		queryKey: ["dataJsonIPR"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			dataJsonIPR(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			// message.success(`Data query Successfull`);
		},
		onError: (error) => { },
	});

	const mutationUploadJsonDataGraph = useMutation({
		mutationFn: uploadaDataGraphic,
		onSuccess: () => {
			message.success(`Uploaded Data Successfull`);
			setTimeout(() => {
				refetchListDataGraphic();
			}, 500);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the data of the graphic`);
		},
	});

	const mutationDeleteDataGraphicIPR = useMutation({
		mutationFn: deleteScenarioReservoir,
		onSuccess: () => {
			setTimeout(() => {
				refetchListDataGraphic();
			}, 200);
			message.success(`Data of graphic Delete Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when delete`);
		},
	});

	const mutationDeleteScenariosReservoir = useMutation({
		mutationFn: deleteScenariosReservoir,
		onSuccess: () => {
			setTimeout(() => {
				refetchListDataGraphic();
			}, 200);
		},
		onError: (error) => {
			message.error(`An error has occurred when delete`);
		},
	});

	// API`s IPR Dry Gas

	const mutationDataIPRDryGas = useMutation({
		mutationFn: uploadaDataReservoirDryGas,
		onSuccess: (data) => {
			if (data?.statusType === GAS_LIFT.DATA_IPR.TYPE.SUCCESS) {
				// message.success(`Uploaded Data Successfull`);
			} else {
				message.error(`Uploaded Data Failed`);
			}
		},
		onError: (error) => {
			message.error(`error`);
		},
	});

	const {
		data: queryDataIPRDryGas,
		isFetching: isFetchingDataIPRDryGas,
		refetch: refetchDataIPRDryGas,
	} = useQuery({
		queryKey: ["dataCalculateIPRDryGas"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			dataReservoirDryGas(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	// API`s escenario IPR Dry Gas

	const {
		data: queryDataChartIPRGas,
		isLoading: loadDataChartIPRGas,
		isError: errorDataChartIPRGas,
		refetch: refetchDataChartIPRGas,
	} = useQuery({
		queryKey: ["DataChartIPRGas"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getDataChartIPRGas(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				optionSelectedScenario,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: queryListChartIPRGas,
		isLoading: loadQueryListChartIPRGas,
		isError: errorQueryListChartIPRGas,
		refetch: refetchQueryListChartIPRGas,
	} = useQuery({
		queryKey: ["query_list_chart_ipr_gas"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getListDataChartIPRGas(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			if (data) {
				if (Object.keys(data?.data).length) {
					// console.warn(`This well has no saved scenarios`);
				} else {
					// console.info(`Data Processed Successfull`);
				}
			}
		},
		onError: () => { },
	});

	const mutationDataChartIPRGas = useMutation({
		mutationFn: postDataChartIPRGas,
		onSuccess: (data) => {
			if (data?.statusType === STATUS_TYPE_CALCULATE_IPR.SUCCESS) {
				message.success(`Data chart IPR Gas Successfull`);
				refetchQueryListChartIPRGas();
			} else {
				message.error(`An error has occurred at the time of create`);
			}
		},
		onError: (error) => {
			message.error(`An error has occurred at the time of create`);
		},
	});

	const mutationDeleteScenerioChartIPRGas = useMutation({
		mutationFn: deleteScenarioChartIPRGas,
		onSuccess: (data) => {
			if (data?.statusType === STATUS_TYPE_CALCULATE_IPR.SUCCESS) {
				message.success(`Data scenario Delete Successfull`);
				refetchQueryListChartIPRGas();
			} else {
				message.error(`An error has occurred at the time of delete`);
			}
		},
		onError: (error) => {
			message.error(`An error has occurred at the time of delete`);
		},
	});

	// API's PVT Dry Gas

	const mutationDataPVTCalcDryGas = useMutation({
		mutationFn: postJsonPVTCalcDryGas,
		onSuccess: (data) => {
			if (data?.statusCode === GAS_LIFT.PVT.POST_CALCULATE.CODE.SUCCESS) {
				refetchQueryPVTCalcDryGas();
			} else {
				message.error(`An error has occurred at the time of calculate PVT`);
				console.error(data?.statusMessage);
			}
		},
		onError: (error) => {
			message.error(`An error has occurred at the time of calculate PVT`);
		},
	});

	const {
		data: queryPVTCalcDryGas,
		refetch: refetchQueryPVTCalcDryGas,
		isFetching: isFetchingQueryPVTCalcDryGas,
	} = useQuery({
		queryKey: ["query_calc_pvt_dry_gas"],
		retry: 1,
		enabled: false,
		queryFn: () =>
			getPVTCalcDryGas(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		onSuccess: (data) => {
			if (data?.statusCode === GAS_LIFT.PVT.GET_CALCULATE.CODE.SUCCESS) {
				message.success("PVT calculated Successfull");
			} else {
				message.error("An error has occurred at the time of calculate PVT");
				console.error(data?.statusMessage);
			}
		},
		onError: (error) => {
			message.error(`An error has occurred at the time of calculate PVT`);
		},
	});

	// API's multi well trending

	const mutationUploadWellTrending = useMutation({
		mutationFn: createWellTranding,
		onMutate: () => {
			getViewLoadingGasLift({ ...viewLoadingGasLift, loadDataMultiWell: true });
		},
		onSuccess: (data) => {
			if (data) {
				if (data.statusType === MULTI_WELL.TYPE.SUCCESS) {
					refetchDataPressureChanges();
					refetchDataPressureDrawDown();
					refetchDataProductivityIndex();
					refetchDataFluidType();
					refetchDataListMultiWell();
					getViewLoadingGasLift({
						...viewLoadingGasLift,
						loadDataMultiWell: false,
					});
					message.success("Data Multi Well Gas lift Saved Successfull");
				} else {
					message.error(
						`An error has occurred when uploading the data production test`,
					);
					getViewLoadingGasLift({
						...viewLoadingGasLift,
						loadDataMultiWell: false,
					});
				}
			}
		},
		onError: (error) => {
			message.error(
				`An error has occurred when uploading the data production test`,
			);
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadDataMultiWell: false,
			});
		},
	});

	const mutationUpdateWellTrending = useMutation({
		mutationFn: updateWellTranding,
		onMutate: () => {
			getViewLoadingGasLift({ ...viewLoadingGasLift, loadDataMultiWell: true });
		},
		onSuccess: (data) => {
			if (data) {
				if (data.statusType === MULTI_WELL.TYPE.SUCCESS) {
					refetchDataPressureChanges();
					refetchDataPressureDrawDown();
					refetchDataProductivityIndex();
					refetchDataFluidType();
					refetchDataListMultiWell();

					message.success("Data Multi Well Gas lift Updated Successfull");

					getViewLoadingGasLift({
						...viewLoadingGasLift,
						loadDataMultiWell: false,
					});
				} else {
					message.error(
						`An error has occurred when uploading the data production test`,
					);
					getViewLoadingGasLift({
						...viewLoadingGasLift,
						loadDataMultiWell: false,
					});
				}
			}
		},
		onError: (error) => {
			message.error(
				`An error has occurred when uploading the data production test`,
			);
			getViewLoadingGasLift({
				...viewLoadingGasLift,
				loadDataMultiWell: false,
			});
		},
	});

	const {
		data: queryDataPressureChanges,
		isLoading: loadDataPressureChanges,
		isError: errorDataPressureChanges,
		refetch: refetchDataPressureChanges,
		isFetching: isFetchingDataPressureChanges,
	} = useQuery({
		queryKey: ["DataPressureChanges"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getPressureChanges(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: queryDataPressureDrawDown,
		isLoading: loadDataPressureDrawDown,
		isError: errorDataPressureDrawDown,
		refetch: refetchDataPressureDrawDown,
		isFetching: isFetchingDataPressureDrawDown,
	} = useQuery({
		queryKey: ["PressureDrawDown"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getPressureDrawDown(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: queryDataProductivityIndex,
		isLoading: loadDataProductivityIndex,
		isError: errorDataProductivityIndex,
		refetch: refetchDataProductivityIndex,
		isFetching: isFetchingDataProductivityIndex,
	} = useQuery({
		queryKey: ["ProductivityIndex"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getProductivityIndex(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: queryDataFluidType,
		isLoading: loadDataFluidType,
		isError: errorDataFluidType,
		refetch: refetchDataFluidType,
		isFetching: isFetchingDataFluidType,
	} = useQuery({
		queryKey: ["FluidTypeMultiWell"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getFluidType(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: queryDataListMultiWell,
		isLoading: loadDataListMultiWell,
		isError: errorDataListMultiWell,
		refetch: refetchDataListMultiWell,
		isFetching: isFetchingDataListMultiWell,
	} = useQuery({
		queryKey: ["DataListMultiWell"],
		retry: 2,
		enabled: false,
		queryFn: () =>
			getListMultiWell(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: lastChartProductionData,
		isLoading: loadLastChartProductionData,
		isError: errorLastChartProductionData,
		refetch: refetchLastChartProductionData,
	} = useQuery({
		queryKey: ["chartProductionData"],
		queryFn: () =>
			getChartProductionData(
				"1",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataWellGL.county,
				dataWellGL.field,
				dataWellGL.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: lastChartWellheadInj,
		isLoading: loadLastChartGL_well_head_inj,
		isError: ErrorLastChartGL_well_head_inj,
		refetch: refetchLastChartWellheadInj,
	} = useQuery({
		queryKey: ["chartWellheadInj"],
		queryFn: () =>
			getJsonChartGL_well_head_inj(
				"1",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	const {
		data: lastChartWellHeadProd,
		isLoading: loadLastChartGL_well_head,
		isError: ErrorLastChartGL_well_head,
		refetch: refetchLastChartWellheadProd,
	} = useQuery({
		queryKey: ["chartWellHeadProd"],
		queryFn: () =>
			getJsonChartGL_well_head(
				"1",
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserGasLift.county,
				dataFilterUserGasLift.field,
				dataFilterUserGasLift.well_id,
				User.user_name,
				dataFilterUserGasLift.well_name,
			),
		retry: 1,
		enabled: false,
		onSuccess: (data) => { },
		onError: (error) => { },
	});

	return {
		User,
		bodyAddWell,
		dataCreated,
		dataWell,
		userDataInfo,
		selectedWell,
		getSelectedWell,
		dataDeviationApi,
		dataEquipmentApi,
		mutationUploadJsonPVT,
		mutationUploadPVT,
		mutationUploadJsonDataPVT,
		mutationUpdateJsonPVT,
		mutationUpdateJsonDataPVT,
		getUserDataInfo,
		getDataWell,
		dataHistoryGasLfit,
		initiailInputPropiertiesOut,
		setDataDeviationApi,
		setDataEquipmentApi,
		setBodyAddWell,
		listWellsGasLift,
		refetchListWellsGasLift,
		inputPropertiesOut,
		setInputPropertiesOut,
		dataWellGasLift,
		loadDataWellGasLift,
		loadDataHistory,
		refetchDataWellGasLift,
		refetchDataHistory,
		generateRadomId,
		getDataFilterUserGaslift,
		dataFilterUserGasLift,
		mutationSaveDataGasLift,
		mutationUpdateDataGasLift,
		mutationDeleteDataGasLift,
		mutationCreateDataHistory,
		mutationDeleteDataHistory,
		mutationUpdateDataHistory,
		mutationDataTablePVT,
		selectedWell,
		getSelectedWell,
		dataCreated,
		getDataCreated,
		listDataTuning,
		listqIPR,
		mutationSaveDataIPR,
		mutationUploadFile3D,
		refetchListqIPR,
		refetchDataTuning,
		viewFluidLab,
		setViewFluidLab,
		inputCalculatedLab,
		dataTableLab,
		setDataTableLab,
		setExecuteTuned,
		executeTuned,
		setExecuteTunedCorrelation,
		executeTunedCorrelation,
		setIndexTableLab,
		indexTableLab,
		queryListDataHistory,
		refetchListDataHistory,
		errorListDataHistory,
		errorDataWellGasLift,
		calculateDomain,
		dataTableIPR,
		setDataTableIPR,
		dataParameters,
		refetchDataParameters,
		dataDeviationPB,
		refetchDataDeviationPB,
		dataDeviation,
		refetchDataDeviation,
		dataPBTuned,
		refetchDataPBTuned,
		dataRSTuned,
		refetchDataRSTuned,
		dataBOTuned,
		refetchDataBOTuned,
		dataVisoTuned,
		refetchDataVisoTuned,
		listRS,
		refetchloadListRS,
		refetchValuePb,
		valuePb,
		dataValuePb,
		dataSepPress,
		refetchDataSepPress,
		dataSepCalculate,
		refetchDataSepCalculate,
		refetchloadListBo,
		refetchloadListCo,
		refetchloadListViso,
		listCo,
		listBo,
		listViso,
		inputReservoir,
		setInputReservoir,
		setUserMultiselect,
		refetchWellsUserGasLift,
		wellsUserGasLift,
		dataVolumeTracker,
		refetchVolumeTracker,
		mutationUploadJsonVolumeTracker,
		mutationUploadJsonDataGraph,
		mutationDeleteDataGraphicIPR,
		mutationDeleteScenariosReservoir,
		queryDataGraphic,
		queryListDataGraphic,
		refetchDataGraphic,
		refetchListDataGraphic,
		lastSelectDataIPR,
		getSelectDataIPR,
		dataWellGL,
		setDataWellGL,
		chartWellHeadProd,
		loadDataChartGL_well_head,
		ErrorDataChartGL_well_head,
		refetchChartWellheadProd,
		chartWellheadInj,
		loadDataChartGL_well_head_inj,
		ErrorDataChartGL_well_head_inj,
		refetchChartWellheadInj,
		refetchDataIPRDryGas,
		mutationDataIPRDryGas,
		queryDataIPRDryGas,
		loadListWellsGasLift,
		statedataListIPR,
		setDataListIPR,
		queryDataChartIPRGas,
		refetchDataChartIPRGas,
		queryListChartIPRGas,
		refetchQueryListChartIPRGas,
		mutationDataChartIPRGas,
		mutationDeleteScenerioChartIPRGas,
		setOptionSelectedScenario,
		chartProductionData,
		refetchChartProductionData,
		queryDataProductivityIndex,
		refetchDataProductivityIndex,
		queryDataPressureDrawDown,
		refetchDataPressureDrawDown,
		queryDataPressureChanges,
		refetchDataPressureChanges,
		mutationUpdateWellTrending,
		mutationUploadWellTrending,
		queryDataFluidType,
		refetchDataFluidType,
		refetchDataListMultiWell,
		queryDataListMultiWell,
		lastChartWellHeadProd,
		lastChartWellheadInj,
		lastChartProductionData,
		refetchLastChartProductionData,
		refetchLastChartWellheadInj,
		refetchLastChartWellheadProd,
		isFetchingDataPressureChanges,
		isFetchingDataPressureDrawDown,
		isFetchingDataProductivityIndex,
		isFetchingDataFluidType,
		isFetchingDataListMultiWell,
		queryDataJsonIPR,
		refetchDataJsonIPR,
		refetchQueryPVTCalcDryGas,
		isFetchingQueryPVTCalcDryGas,
		queryPVTCalcDryGas,
		mutationDataPVTCalcDryGas,
		isFechingDataWellGasLift,
		isFetchingDataIPRDryGas,
		dataAllocation,
		refetchDataAllocation,
		dataFile3D,
		loadDataFile3D,
		ErrorDataFile3D,
		refetchDataFile3D
	};
};
