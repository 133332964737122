import React from "react";
import { DashboardContext } from "./DashboardContentx";
import { useDashboard } from "../../hooks/useDashboard";

export const DashboardProvider = ({ children }) => {
	const {
		dataPage,
		userDataInfo,
		selectedWell,
		selectedWellDCA,
		selectedReservoirSCT,
		selectedWellPL,
		selectedWellInjector,
		dataDomainGraph,
		dataDomainGraphPL,
		dataDomainGraphDCA,
		dataDomainGraphInjector,
		dataAlertGasLift,
		dataAlertPlungerLift,
		dataAlertDCA,
		dataAlertInjector,
		someAlert,
		checkTypeGraph,
		deviceRealTime,
		selectIntervalVLP,
		viewLoadingGeneral,
		slideGraph,
		openFirstModal,
		processExecute,
		getDataPage,
		getUserDataInfo,
		getSelectedWell,
		getSelectedWellDCA,
		getSelectedReservoirSCT,
		getSelectedWellPL,
		getSelectedWellInjector,
		getDataDomainGraph,
		getDataDomainGraphPL,
		getDataDomainGraphDCA,
		getDataDomainGraphInjector,
		getDataAlertGasLift,
		getDataAlertPlungerLift,
		getDataAlertDCA,
		getDataAlertInjector,
		getSomeAlert,
		setOpenFirstModal,
		toggleOpenFirstModal,
		getCheckTypeGraph,
		getDeviceRealTime,
		getSelectIntervalVLP,
		getViewLoadingGeneral,
		getSlideGraph,
		getProcessExecute
	} = useDashboard()
	return (
		<DashboardContext.Provider value={{
			dataPage,
			userDataInfo,
			selectedWell,
			selectedWellDCA,
			selectedReservoirSCT,
			selectedWellPL,
			selectedWellInjector,
			dataDomainGraph,
			dataDomainGraphPL,
			dataDomainGraphDCA,
			dataDomainGraphInjector,
			dataAlertGasLift,
			dataAlertPlungerLift,
			dataAlertDCA,
			dataAlertInjector,
			someAlert,
			checkTypeGraph,
			deviceRealTime,
			selectIntervalVLP,
			viewLoadingGeneral,
			slideGraph,
			openFirstModal,
			processExecute,
			getDataPage,
			getUserDataInfo,
			getSelectedWell,
			getSelectedWellDCA,
			getSelectedReservoirSCT,
			getSelectedWellPL,
			getSelectedWellInjector,
			getDataDomainGraph,
			getDataDomainGraphPL,
			getDataDomainGraphDCA,
			getDataDomainGraphInjector,
			getDataAlertGasLift,
			getDataAlertPlungerLift,
			getDataAlertDCA,
			getDataAlertInjector,
			getSomeAlert,
			setOpenFirstModal,
			toggleOpenFirstModal,
			getCheckTypeGraph,
			getDeviceRealTime,
			getSelectIntervalVLP,
			getViewLoadingGeneral,
			getSlideGraph,
			getProcessExecute
		}}>
			{children}
		</DashboardContext.Provider>
	)
}
