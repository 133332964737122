import { message } from "antd";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../auth/context/AuthContext";
import { DashboardContext } from "../context/Dashboard/DashboardContentx";
import { DashboardInjectorContext } from "../context/DashboardInjector/DashboardInjectorContext";
import {
	uploadDataInjector,
	updateDataInjector,
	getListWellsInjector,
	getDatainjector,
	deleteDataInjector,
	deleteDataHistory,
	getChartInjectorPerformanceData,
	getChartInjectorHistoryData,
	getCumulative_allData,
	getCumulative_lastData,
	getDataHistory,
	getListDataHistory,
	updateDataHistory,
	uploadDataHistory,
	uploadJsonVolumeTracker,
	getLastChartInjectorPerformanceData,
	getLastChartInjectorHistoryData,
} from "../services/api/injectorAPI";
import { WELL_SENSOR_DEVICE } from "../components/elements/types/type_dashboard";
import { STATUS_TYPE_INJECTOR } from "../components/elements/types/type_response_API";
import { useFilters } from "./useFilters";

export const useTabInjector = (table = []) => {
	const { User } = useContext(AuthContext);
	const { getUserDataInfo, userDataInfo, deviceRealTime, getDeviceRealTime } =
		useContext(DashboardContext);
	const {
		getDataFilterUserInjector,
		dataFilterUserInjector,
		getViewLoadingInjector,
		viewLoadingInjector,
	} = useContext(DashboardInjectorContext);
	const [viewLoading, setViewLoading] = useState(true);
	const [userMultiselect, setUserMultiselect] = useState();
	const { dataFilter, mutationUpdateFilter } = useFilters();

	//LLAMADOS API
	const mutationUploadDataInjector = useMutation({
		mutationFn: uploadDataInjector,
		onMutate: () => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: true,
			});
		},
		onSuccess: (data, dataMutation) => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: false,
			});
			if (data?.statusType === STATUS_TYPE_INJECTOR.SUCCESS) {
				async function executeFunction() {
					await mutationUpdateFilter.mutateAsync({
						user: User.user_name,
						data: {
							...dataFilter?.data,
							checkedWellInjector: dataMutation.wellid,
						},
					});

					await new Promise((resolve) => setTimeout(resolve, 100));
					await refetchListWellInjector();
					message.success(`Data Well Injector Saved Successfull`);
				}
				executeFunction();
			} else {
				message.error(`An error has occurred when loading the data Well Injector`);
			}
		},
		onError: (error) => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred when loading the information of well`);
		},
	});

	const mutationUpdateDataInjector = useMutation({
		mutationFn: updateDataInjector,
		onMutate: () => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: true,
			});
		},
		onSuccess: (data) => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: false,
			});
			if (data?.statusType === STATUS_TYPE_INJECTOR.SUCCESS) {
				refetchDataWellInjector();
				message.success("Processing complete!");
			} else {
				message.info("An error has occurred when updated the information of well");
			}
		},
		onError: (error) => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred when loading the information of well`);
		},
	});

	const mutationDeleteDataInjector = useMutation({
		mutationFn: deleteDataInjector,
		onMutate: () => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: true,
			});
		},
		onSuccess: () => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: false,
			});
			message.success(`Data the well Successfull`);
		},
		onError: (error) => {
			getViewLoadingInjector({
				...viewLoadingInjector,
				loadSaveParameters: false,
			});
			message.error(`An error has occurred when delete the information of well`);
		},
	});

	const {
		data: dataWellInjector,
		isLoading: loadDataWellInjector,
		isError: ErrorDataWellInjector,
		refetch: refetchDataWellInjector,
	} = useQuery({
		queryKey: ["dataWellInjector"],
		queryFn: () =>
			getDatainjector(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
			if (data?.statusType != "ERROR") {
				const filtered = WELL_SENSOR_DEVICE?.data.filter(
					(item) => item.name === data?.data?.well_platforms,
				);
				getDeviceRealTime({
					...deviceRealTime,
					list_device_dca: filtered,
					device_dca: {
						...deviceRealTime.device_dca,
						platform: data?.data?.well_platforms,
						discretized: data?.data?.discretized_well,
					},
				});
			}
		},
		onError: (error) => {},
	});

	const {
		data: listWellInjector,
		isLoading: loadListWellInjector,
		isError: ErrorListWellInjector,
		refetch: refetchListWellInjector,
	} = useQuery({
		queryKey: ["listWellInjector"],
		queryFn: () =>
			getListWellsInjector(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const mutationUploadJsonVolumeTracker = useMutation({
		mutationFn: uploadJsonVolumeTracker,
		onSuccess: () => {
			message.success(`Uploaded Data Successfull`);
		},
		onError: (error) => {
			message.error(`An error has occurred when loading the PVT data`);
		},
	});

	const mutationUploadDataHistory = useMutation({
		mutationFn: uploadDataHistory,
		onSuccess: () => {
			refetchListDataHistory();
			message.success(`The charge of data history was successful`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when loading the information of the data history`,
			);
		},
	});

	const mutationUpdateDataHistory = useMutation({
		mutationFn: updateDataHistory,
		onSuccess: () => {
			refetchListDataHistory();
			message.success(`The charge of data history was successful`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when loading the information of the data history`,
			);
		},
	});

	const mutationDeleteDataHistory = useMutation({
		mutationFn: deleteDataHistory,
		onSuccess: () => {
			refetchListDataHistory();
			message.success(`The elimination of the Data history was successful`);
		},
		onError: (error) => {
			message.error(
				`An error has occurred when delete the information of the data history`,
			);
		},
	});

	const {
		data: dataHistoryInjector,
		isLoading: loadDataHistoryDCA,
		isError: ErrorDataHistoryDCA,
		refetch: refetchDataHistoryDCA,
	} = useQuery({
		queryKey: ["dataHistoryInjector"],
		queryFn: () =>
			getDataHistory(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
		},
		onError: (error) => {},
	});

	const {
		data: lastDataHistoryInjector,
		isLoading: loadLastDataHistory,
		isError: ErrorLastDataHistory,
		refetch: refetchLastDataHistory,
	} = useQuery({
		queryKey: ["dataLastHistoryInjector"],
		queryFn: () =>
			getLastChartInjectorHistoryData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
				dataFilterUserInjector.well_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
		},
		onError: (error) => {},
	});

	const {
		data: dataListDataHistory,
		isLoading: loadListDataHistory,
		isError: ErrorListDataHistory,
		refetch: refetchListDataHistory,
	} = useQuery({
		queryKey: ["dataHistoryList"],
		queryFn: () =>
			getListDataHistory(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				User.user_name,
			),
		retry: 2,
		enabled: false,
		onSuccess: (data) => {
			setViewLoading(false);
		},
		onError: (error) => {
			setViewLoading(false);
		},
	});

	const generateRandomID = () => {
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const lowercaseLettersAndNumbers = "abcdefghijklmnopqrstuvwxyz0123456789";
		let id = "";

		// Generar los primeros tres caracteres con letras mayúsculas
		for (let i = 0; i < 3; i++) {
			const randomUppercaseLetter = uppercaseLetters.charAt(
				Math.floor(Math.random() * uppercaseLetters.length),
			);
			id += randomUppercaseLetter;
		}

		// Generar el resto de caracteres con letras minúsculas y números
		const remainingLength = 10 - id.length;
		for (let i = 0; i < remainingLength; i++) {
			const randomChar = lowercaseLettersAndNumbers.charAt(
				Math.floor(Math.random() * lowercaseLettersAndNumbers.length),
			);
			id += randomChar;
		}

		return id;
	};

	const {
		data: chartInjectorHistoryData,
		isLoading: loadChartInjectorHistoryData,
		isError: errorChartInjectorHistoryData,
		refetch: refetchChartInjectorHistoryData,
	} = useQuery({
		queryKey: ["chartInjectorHistoryData"],
		queryFn: () =>
			getChartInjectorHistoryData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
				dataFilterUserInjector.well_name,
			),
		retry: 3,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});
	const {
		data: cumulative_all,
		isLoading: loadCumulative_all,
		isError: errorCumulative_all,
		refetch: refetchCumulative_all,
	} = useQuery({
		queryKey: ["cumulative_all"],
		queryFn: () =>
			getCumulative_allData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
			),
		retry: 3,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: cumulative_last,
		isLoading: loadCumulative_last,
		isError: errorCumulative_last,
		refetch: refetchCumulative_last,
	} = useQuery({
		queryKey: ["cumulative_last"],
		queryFn: () =>
			getCumulative_lastData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
			),
		retry: 3,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	const {
		data: chartInjectorPerformanceData,
		isLoading: loadChartInjectorPerformanceData,
		isError: errorChartInjectorPerformanceData,
		refetch: refetchChartInjectorPerformanceData,
	} = useQuery({
		queryKey: ["chartInjectorPerformanceData"],
		queryFn: () =>
			getChartInjectorPerformanceData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
				dataFilterUserInjector.well_name,
			),
		retry: 3,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});
	const {
		data: lastDatachartInjectorPerformanceData,
		isLoading: loadLastDataChartInjectorPerformanceData,
		isError: errorLastDataChartInjectorPerformanceData,
		refetch: refetchLastDataChartInjectorPerformanceData,
	} = useQuery({
		queryKey: ["lastDatachartInjectorPerformanceData"],
		queryFn: () =>
			getLastChartInjectorPerformanceData(
				userDataInfo.operator,
				userDataInfo.lease,
				userDataInfo.region,
				dataFilterUserInjector.county,
				dataFilterUserInjector.field,
				dataFilterUserInjector.well_id,
				User.user_name,
				dataFilterUserInjector.well_name,
			),
		retry: 3,
		enabled: false,
		onSuccess: (data) => {},
		onError: (error) => {},
	});

	return {
		userDataInfo,
		getUserDataInfo,
		dataFilterUserInjector,
		getDataFilterUserInjector,
		mutationUploadDataInjector,
		mutationUpdateDataInjector,
		mutationDeleteDataInjector,
		mutationUploadDataHistory,
		mutationUpdateDataHistory,
		mutationDeleteDataHistory,
		mutationUploadJsonVolumeTracker,
		dataWellInjector,
		listWellInjector,
		dataHistoryInjector,
		dataListDataHistory,
		chartInjectorHistoryData,
		chartInjectorPerformanceData,
		lastDatachartInjectorPerformanceData,
		lastDataHistoryInjector,
		cumulative_all,
		cumulative_last,
		refetchDataWellInjector,
		refetchListWellInjector,
		refetchDataHistoryDCA,
		refetchListDataHistory,
		refetchChartInjectorHistoryData,
		refetchChartInjectorPerformanceData,
		refetchLastDataChartInjectorPerformanceData,
		refetchCumulative_all,
		refetchCumulative_last,
		refetchLastDataHistory,
		ErrorDataWellInjector,
		ErrorListWellInjector,
		ErrorDataHistoryDCA,
		errorCumulative_all,
		errorCumulative_last,
		loadCumulative_all,
		loadCumulative_last,
		loadListWellInjector,
		viewLoading,
		setViewLoading,
		generateRandomID,
		setUserMultiselect,
	};
};
