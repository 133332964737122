import React, { useState } from "react";
import styleChartProd from "./ChartProdHistory.module.css";
import { ReactComponent as ArrowIcon } from "../../../../assets/icon/arrow-repeat.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/icon/expand1.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  ComposedChart,
} from "recharts";
import { Select } from "antd";
const {
  containerPrincipal,
  containerLegend,
  styleRefenence1,
  styleRefenence3,
  sizeReference,
} = styleChartProd;
const data = [
  { date: "11/12/2024", liquid: 70, gas: 420 },
  { date: "11/13/2024", liquid: 60, gas: 400 },
  { date: "11/14/2024", liquid: 80, gas: 410 },
  { date: "11/15/2024", liquid: 70, gas: 520 },
  { date: "11/16/2024", liquid: 90, gas: 500 },
  { date: "11/17/2024", liquid: 65, gas: 410 },
];

const ChartProdHistory = ({ typeGraph,data }) => {


  return (
    <div className={containerPrincipal}>
      <div style={{ width: "100%", height: "350px", padding: "5px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart  data={data?.dataset1}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tick={{
                fontSize: 12,
              }}
              label={{
                value: "Date",
                position: "insideBottom",
                offset: -5,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
            />
            <YAxis
              yAxisId={1}
              label={{
                value: "Flow Line Gas Rate (Mscf/D)",
                angle: -90,
                position: "insideLeft",
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
            />
            <YAxis
              yAxisId={2}
              orientation="right"
              allowDataOverflow={true}
              label={{
                value: "Flow line Gas Rate (Bbi/D)",
                angle: -90,
                position: "right",
                offset: -5,
                style: {
                  fontSize: "12px",
                  textAnchor: "middle",
                },
              }}
              tick={{
                fontSize: 12,
              }}
            />
            <Tooltip />
            {typeGraph === "Bar" ? (
              <>
                <Bar
                  dataKey="liquid_rate"
                  name="liquid_rate"
                  fill="#3737FF"
                  yAxisId={1}
                />
                <Bar dataKey="gas_rate" name="gas_rate" fill="#FF2727" yAxisId={2} />
              </>
            ) : (
              <>
                <Line
                  type="monotone"
                  dataKey="liquid_rate"
                  stroke="#0000FF"
                  name="liquid_rate"
                  yAxisId={1}
                />
                <Line
                  type="monotone"
                  dataKey="gas_rate"
                  stroke="#FF0000"
                  name="gas_rate"
                  yAxisId={2}
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
        <div className={containerLegend}>
          <span className={sizeReference}>
            <span className={styleRefenence3}>●</span> Liquid
          </span>
          <span className={sizeReference}>
            <span className={styleRefenence1}>●</span> Gas
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChartProdHistory;
