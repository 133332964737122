export const OPTIONS_PLOTTING = [
	{
		value: "Static Pressure",
		label: "Pressure",
		domain: [0, 0],
	},
	{
		value: "Differential Pressure",
		label: "Differential Pressure",
		domain: [0, 0],
	},
	{
		value: "Flowing Temperature",
		label: "Flowing Temperature",
		domain: [0, 0],
	},
	{
		value: "Liquid Flow Rate",
		label: "Liquid Flow Rate",
		domain: [0, 0],
	},
	{
		value: "Liquid Previous Day Total",
		label: "Liquid Yesterday Volume",
		domain: [0, 0],
	},
	{
		value: "Gas Flow Rate",
		label: "Gas Flow Rate",
		domain: [0, 0],
	},
	{
		value: "Corrected Current Day Volume",
		label: "Gas Daily Volume",
		domain: [0, 0],
	},
	{
		value: "Corrected Previous Day Volume",
		label: "Gas Yesterday Volume",
		domain: [0, 0],
	},
	{
		value: "Corrected Accumulated Volume",
		label: "Gas Current Month Volume",
		domain: [0, 0],
	},
	{
		value: "Meter 1 Current Day Volume",
		label: "Meter 1 Current Day Volume",
		domain: [0, 0],
	},
	{
		value: "Meter 1 Flow Rate",
		label: "Meter 1 Flow Rate",
		domain: [0, 0],
	},
	{
		value: "Meter 1 Net Accumulated Volume",
		label: "Meter 1 Net Accumulated Volume",
		domain: [0, 0],
	},
	{
		value: "Meter 1 Previous Day Volume",
		label: "Meter 1 Previous Day Volume",
		domain: [0, 0],
	},
	{
		value: "Meter 2 Current Day Volume",
		label: "Meter 2 Current Day Volumee",
		domain: [0, 0],
	},
	{
		value: "Meter 2 Flow Rate",
		label: "Meter 2 Flow Rate",
		domain: [0, 0],
	},
	{
		value: "Meter 2 Net Accumulated Volume",
		label: "Meter 2 Net Accumulated Volume",
		domain: [0, 0],
	},
	{
		value: "Meter 2 Previous Day Volume",
		label: "Meter 2 Previous Day Volume",
		domain: [0, 0],
	},
	{
		value: "C6 Well Head Pressure",
		label: "C6 Well Head Pressure",
		domain: [0, 0],
	},
	{
		value: "C9 Well Head Pressure",
		label: "C9 Well Head Pressure",
		domain: [0, 0],
	},
];

export const INITIAL_DATA_PLOTTING = {
	selected_platform: [],
	selected_device: [],
	selected_parameter: [],
	dataPlotting: [],
	endDateIOT: "",
	startDateIOT: "",
};

export const INITIAL_DATA_TEST = {
	well_name:"",
	test_name:"",
	test_date:"",
	fluid_type:"",
	start_date: "",
	end_date: "",
	platforms: [],
	device: [],
	parameters: [],
	data_Plotting: [],
	// DATA MENU PVT PARAMETERS
	data_pvt : {
		oil_gravity: "",
		gas_gravity: "",
		solution_gor: "",
		h2s: "",
		co: "",
		n2: "",
		water_salinity: "",
		reservoir_pressure: "",
		reservoir_temperature: "",
		primary_sep_gas_gravity: "",
		secondary_sep_gas_gravity: "",
		stock_tank_gas_gravity: "",
		primary_sep_gor: "",
		secondary_sep_gor: "",
		stock_tank_gor: "",
		select_compressibility_factor: "",
		select_gas_viscosity: "",
		select_reservoir_temperature: "",
		select_pb_ra_bo: "",
		select_oil_viscosity: "",
	},
	// DATA MENU LIQUID AND GAS RATE PARAMETER
	data_liquid: {
		gas_rate: [],
		liquid_rate: [],
		table_period: [
			{
				id: 0,
				state_check: false,
				period: 1,
				duration: "",
				gas_rate: "",
				liquid_rate: "",
				water_cute: ""
			},
			{
				id: 1,
				state_check: false,
				period: 2,
				duration: "",
				gas_rate: "",
				liquid_rate: "",
				water_cute: ""
			},
			{
				id: 2,
				state_check: false,
				period: 3,
				duration: "",
				gas_rate: "",
				liquid_rate: "",
				water_cute: ""
			}
		],
	},
	// DATA MENU PRESSURE PARAMETER
	data_pressure : {
			pressure: [],
			table_period: [
					{
							id: 0,
							period: 1,
							state_check: false,
							time_to_stabilization: "",
							stabilized_surface_pressure: "",
							ai_buttom_hole_pressure: "",
					},
					{
							id: 1,
							period: 2,
							state_check: false,
							time_to_stabilization: "",
							stabilized_surface_pressure: "",
							ai_buttom_hole_pressure: "",
					},
					{
							id: 2,
							period: 3,
							state_check: false,
							time_to_stabilization: "",
							stabilized_surface_pressure: "",
							ai_buttom_hole_pressure: "",
					},
			],
	},
	// DATA MENU IPR MODEL
	data_ipr : {
			ipr_model: "",
			exponent_n:""
	}
};

export const COLORS_LINES = [
	"#662D91",
	"#C03650",
	"#A393D6",
	"#3b50b2",
	"#60C7EB",
	"#FCA200",
	"#92D051",
	"#4d3a43",
	"#155263",
	"#f70776",
];
export const LINE_TYPES = [
	"monotone",
	"linear",
	"step",
	"stepBefore",
	"stepAfter",
	"basis",
	"cardinal",
	"natural",
];

export const LINE_STYLES = [
	"0",
	"5 5",
	"2 2",
	"10 5",
	"5 5 2 5",
	"10 5 2 5",
	"2 10",
	"15 5",
];

export const table_pressure_parameter = [
	{
		id: 0,
		time: null,
		stabilized: null,
		bottom_hole: null,
	},
	{
		id: 1,
		time: null,
		stabilized: null,
		bottom_hole: null,
	},
	{
		id: 2,
		time: null,
		stabilized: null,
		bottom_hole: null,
	},
];

export const table_liquid_and_gas = [
	{
		id: 0,
		duration: null,
		gasrate: null,
		liquidrate: null,
	},
	{
		id: 1,
		duration: null,
		gasrate: null,
		liquidrate: null,
	},
	{
		id: 2,
		duration: null,
		gasrate: null,
		liquidrate: null,
	},
];
