import React, { useState } from 'react'
import { ReactComponent as IconEdit } from "../../../assets/icon/edit.svg";
import { ReactComponent as IconDelete } from "../../../assets/icon/x-circle.svg";
import { Modal, Pagination } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const initialDataOperator = {
  idclient:"",
  client:""
}
export const CrudOperator = ({dataList}) => {
  const { confirm } = Modal;
  const [dataOperator, setDataOperator] = useState(initialDataOperator);
  const [selectedItemCrud, setSelectedItemCrud] = useState();
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const totalItems = dataList ? dataList.length : 0;
  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = currentPage * pageSize;

  const handleChangeDataOperator = ({ target: { name, value } }) => {
    setDataOperator({ ...dataOperator, [name]: value });
  }

  const handleSelectItem = (elemento, caso) => {
    setSelectedItemCrud(elemento);
    caso === "Editar" ? showEditConfirm(elemento) : showDeleteConfirm(elemento);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const showDeleteConfirm = (elemento) => {
    confirm({
      title: 'Are you sure you want to delete this operator?',
      icon: <ExclamationCircleFilled />,
      content: 'You have selected the operator '+elemento.client+" with the id "+elemento.idclient+', If you delete this parameter you will not be able to recover it.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK',selectedItemCrud);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const showEditConfirm = (elemento) => {
    setDataOperator({ idclient:elemento.idclient, client: elemento.client});
    setOpen(true);
  };
  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div className="flex flex-col w-full h-full">
      <Modal
        title="Edit Info Operator"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >

        <div className='flex flex-col w-full h-[400px] text-center justify-center items-center p-10'>
          <label htmlFor="" className='text-[15px] font-bold'>ID Operator</label>
          <input type="text" className='w-full h-[47px] rounded-[4px] border border-solid mb-[10px] mt-[10px] text-center'
            name='idclient'
            value={dataOperator.idclient}
            onChange={handleChangeDataOperator}
          />
          <label htmlFor="" className='text-[15px] font-bold'>Operator</label>
          <input type="text" className='w-full h-[47px] rounded-[4px] border border-solid mb-[10px] mt-[10px] text-center'
            name='client'
            value={dataOperator.client}
            onChange={handleChangeDataOperator}
          />
        </div>
      </Modal>
      <div className="flex flex-col h-full w-full p-5">
        <div className="flex flex-col w-full border border-solid text-left">
          <table>
            <thead className="h-[30px] w-full text-left bg-zinc-800 text-white">
              <tr>
                <th className="pl-[20px]">Id Operator</th>
                <th>Operator</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {dataList?.slice(startIdx, endIdx).map((item) => (
                <tr key={item.iduser}>
                  <td className="pl-[20px]">{item.idclient}</td>
                  <td>{item.client}</td>
                  <td className="text-center">
                    <IconEdit className="w-6 h-[47px] fill-yellow-400 cursor-pointer" onClick={() =>
                      handleSelectItem(item, "Editar")
                    } />
                  </td>
                  <td className="text-center">
                    <IconDelete className="w-6 h-[47px] fill-red-600 cursor-pointer" onClick={() =>
                      handleSelectItem(item, "Eliminar")
                    } />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-row max-w-[1500px] w-full h-full">
          <div className="flex flex-row w-full ml-[20px] mr-[20px] mt-[10px]">
            <div className="flex flex-col w-full ml-[20px] text-start">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handlePageChange}
                itemRender={itemRender}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
