import React, { useEffect, useState, useContext } from 'react'
import { Navbar } from '../../components/layout/Navbar'
import { Sidebar } from '../../components/layout/Sidebar'
import { message, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ImportFileHistory } from '../../components/ScreeningTool/Complements/ImportFileHistory'
import { DATA_SCREENING_TOOL } from '../../components/elements/types/type_screening_tool'
import { STATUS_CODE_SCREENING_TOOL } from "../../components/elements/types/type_response_API";
import { useTabScreening } from '../../hooks/useTabScreening'
import { useUsers } from '../../hooks/useUsers'
import { DashboardContext } from '../../context/Dashboard/DashboardContentx';
import { ReactComponent as IconCheckFile } from "../../assets/icon/check-circle.svg";
import { ReactComponent as IconCircle } from "../../assets/icon/circle.svg";
import { NAME_PAGES } from '../../components/elements/types/type_pages'

export const StepMenuEditScreeningTool = () => {
  const { Option } = Select;
  const navigate = useNavigate()
  const { User } = useUsers();
  const { selectedReservoirSCT } = useContext(DashboardContext);
  const {
    userDataInfo,
    mutationUpdateScreening, 
    queryFileScreening,
    queryListReservoir, 
    refetchQueryFileScreening,
    refetchQueryListReservoir,
  } = useTabScreening();
  const [ inputDataScreeningTool, setInputDataScreeningTool ] = useState(DATA_SCREENING_TOOL.initialDataScreeningTool);
  const [ inputEmpty, setInputEmpty ] = useState(false);
  const [ dataFile, setDataFile ] = useState();
  const [ stateUploadFile, setStateUploadFile ] = useState(false);
  
  useEffect (() => {
    refetchQueryFileScreening();
    refetchQueryListReservoir();
  }, []);
  
  useEffect(() => {
    if(queryListReservoir?.statusCode === STATUS_CODE_SCREENING_TOOL.SUCCESS && !!queryListReservoir.data[0]) {
      const dataList = !!Object.keys(queryListReservoir?.data[0]).length ? queryListReservoir?.data[0] : [];
      const reservoir = dataList?.find(item => item.reservoir_id === selectedReservoirSCT);
      
      setInputDataScreeningTool({
        ...inputDataScreeningTool,
        reservoir_id: reservoir?.reservoir_id,
        reservoir_name: reservoir?.reservoir_name,
        reservoir_type: reservoir?.reservoir_type,
        country: reservoir?.country,
        state: reservoir?.state,
        field: reservoir?.field,
      });

    }else{
      setInputDataScreeningTool(DATA_SCREENING_TOOL.initialDataScreeningTool)
    }
  }, [queryListReservoir]);

  useEffect(() => {
    if (!!queryFileScreening) {
      const dataJson = convertCsvToJson(queryFileScreening);

      const validaDataJson = dataJson.map(item => ({
        "Well Name": item["Well Name"],
        "Oil Rate (STB/D)": parseFloat(item["Oil Rate (STB/D)"]),
        "Gas rate (Mscf/D)": parseFloat(item["Gas rate (Mscf/D)"]),
        "Water rate (STB/D)": parseFloat(item["Water rate (STB/D)"]),
        "Depth (ft)": parseInt(item["Depth (ft)"])
      }));
     
      if(Object.keys(validaDataJson).length){
        setDataFile(validaDataJson);
        setStateUploadFile(true);
      }else{
        setDataFile([]);
        setStateUploadFile(false);
      }
    }
  }, [queryFileScreening]);
  
  const handleChangeInputData = (e) => {
    const {name, value} = e.target;

    setInputDataScreeningTool((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeSelect = (nameSelected, valueSelected) => {
    const customEvent = {
      target: {
        name: nameSelected,
        value: valueSelected,
      },
    };
    handleChangeInputData(customEvent);
  }

  const handleKeyDown = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const handleClickPrev = () => {
    navigate(`/${NAME_PAGES.screening_tool.list_well}`)
  }

  const handleClickDone = () => {
    
    if(
      !inputDataScreeningTool.reservoir_name ||
      !inputDataScreeningTool.reservoir_type ||
      !inputDataScreeningTool.reservoir_id ||
      !inputDataScreeningTool.country ||
      !inputDataScreeningTool.state ||
      !inputDataScreeningTool.field ||
      !Object.keys(dataFile).length
    ){
      setInputEmpty(true);
      message.error("There are empty fields please validate that the information is complete.");
    }
    else{

      const { reservoir_id, field, country, ...filteredDataCreateReservoir } = inputDataScreeningTool;
      const dataFinalCreateReservoir = {...filteredDataCreateReservoir, data: [...dataFile]}
      
      async function executeFunction() {
        await mutationUpdateScreening.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputDataScreeningTool.country,
          field: inputDataScreeningTool.field,
          reservoir: inputDataScreeningTool.reservoir_id,
          userName: User.user_name,
          data: dataFinalCreateReservoir
        });

        await new Promise(resolve => setTimeout(resolve, 500));
        
        message.success("Processing complete!");
        navigate(`/${NAME_PAGES.screening_tool.dashboard}`);
        setInputEmpty(false);
      }
      executeFunction();
    }
  }

  const handleUploadFile = (dataFile) => {

    const dataFileEnd = dataFile.map((item)=>({
      ...item,
      'Depth (ft)': !item['Depth (ft)'] ? 0 : item['Depth (ft)']
    }));
    
    if(!!Object.keys(dataFile).length){
      setStateUploadFile(true);
      setDataFile(dataFileEnd);
    }
  } 
  
  const ButtonDowload = () => {
    if (dataFile !== undefined ) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(dataFile);
      link.download = 'Gas lift screening data.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      message.info("The file could not be downloaded")
    }
  }

  function convertCsvToJson(data) {
    if (!!data) {
      const lines = data.trim().split('\n');
      const headers = lines[0].split(',');
      const jsonData = lines.slice(1).map(line => {
        const values = line.split(',');
        const obj = {};
        headers.forEach((header, index) => {
          obj[header.trim()] = values[index].trim();
        });
        return obj;
      });
      return jsonData;
    }else{
      return [];
    }
  }

  return (
    <div className='flex flex-col w-full h-full bg-white min-h-screen'>
        <div className="flex">
          <Navbar/>
          <Sidebar className="flex absolute h-full "/>
        </div>
        <div className='flex flex-col w-full h-full pl-[88px] text-center justify-center'>
          <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px] ">
            <div className="flex flex-row w-[77%] h-[24px] gap-5">
              <h1 className="flex flex-row font-bold text-[20px]">
                Reservoir definition
              </h1>
            </div>
          </div>
          <div className="flex w-full h-[42em] justify-center text-center items-center mb-[0] ">
            <div className='flex flex-col w-[77%] h-full'>
            <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Reservoir Id
              </label>
              <input
                type="text"
                name="reservoir_id"
                onKeyDown={handleKeyDown}
                value={inputDataScreeningTool.reservoir_id}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                  inputEmpty && inputDataScreeningTool.reservoir_id === ""
                    ? "border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                } `}
                disabled
              />
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Reservoir name
              </label>
              <input
                type="text"
                name="reservoir_name"
                onKeyDown={handleKeyDown}
                value={inputDataScreeningTool.reservoir_name}
                onChange={handleChangeInputData}
                disabled
                className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                  inputEmpty && inputDataScreeningTool.reservoir_name === ""
                    ? " border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                } `}
              />
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Reservoir type
              </label>
              <Select
                style={{
                  backgroundColor: inputEmpty && inputDataScreeningTool.reservoir_type === "" ? "#B91C1C" : "",
                  border: inputEmpty && inputDataScreeningTool.reservoir_type === "" ? "1px solid #B91C1C" : "",
                  borderRadius: "8px",
                  textAlignalign: "left"
                }}
                name="reservoir_type"
                value={inputDataScreeningTool.reservoir_type || ""}
                className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
                onChange={(value) => handleChangeSelect("reservoir_type", value)}
              >
                <Option value="">Selected option</Option>
                <Option value="oil">Oil </Option>
                <Option value="Gas">Gas</Option>
              </Select>
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                onKeyDown={handleKeyDown}
                value={inputDataScreeningTool.country}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                  inputEmpty && inputDataScreeningTool.country === ""
                    ? " border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                } `}
                disabled
              />
               <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                State
              </label>
              <input
                type="text"
                name="state"
                onKeyDown={handleKeyDown}
                value={inputDataScreeningTool.state}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                  inputEmpty && inputDataScreeningTool.state === ""
                    ? " border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                } `}
              />
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Field
              </label>
              <input
                type="text"
                name="field"
                onKeyDown={handleKeyDown}
                value={inputDataScreeningTool.field}
                onChange={handleChangeInputData}
                className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                  inputEmpty && inputDataScreeningTool.field === ""
                    ? " border-red-700 bg-yellow-100"
                    : "border-[#BCBCCC]"
                } `}
                disabled
              />
              <label
                htmlFor=""
                className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
              >
                Data History
              </label>
              <div className='flex gap-2' >
                <ImportFileHistory uploadfile={handleUploadFile}></ImportFileHistory>
                { stateUploadFile
                  ? 
                  <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                    <IconCheckFile className="w-5 h-5 fill-green-600"></IconCheckFile>
                    <label htmlFor="" className="font-sans text-green-600">The file has been loaded</label>
                  </div>
                  :  
                  <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                    <IconCircle className="w-5 h-5 fill-pa-purple"></IconCircle>
                    <label htmlFor="" className="font-sans text-red-600">The file has not been loaded</label>
                  </div>
                }
                {/* <button 
                  className='ml-[35px] flex w-[257px] h-[42px] justify-center text-center items-center text-[15px] text-pa-purple border border-solid border-pa-purple rounded-md hover:bg-pa-purple hover:text-pa-white1'
                  onClick={ButtonDowload}
                >
                  Dowload file
                </button> */}
              </div>
            </div>
          </div>
          <div className="flex w-full text-center justify-center items-center mt-auto">
              <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickPrev()}
                >
                  Cancel
                </button>

                <button
                  className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                  onClick={() => handleClickDone()}
                >
                  Run
                </button>
              </div>
            </div>
        </div>
    </div>
  )
}
