import React, { useRef } from "react";
import { message } from "antd";
import { ReactComponent as IconUpload } from "../../../assets/img/icons/upload.svg";

const ImportFileDataHistory = ({ uploadfile }) => {
  const fileInputRef = useRef(null);

  const handleImport = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const validExtensions = ["xls", "xlsx", "csv"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (validExtensions.includes(fileExtension)) {
        uploadfile(file);
        message.success("The file has been selected successfully.");
      } else {
        message.error("Please upload a valid Excel file");
      }
    } else {
      message.error("No file selected.");
    }
    event.target.value = "";
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        name="file"
        id="inputGroupFile"
        onChange={handleImport}
        style={{ display: "none" }}
      />
      <label htmlFor="fileInput">
        <IconUpload
          size={32}
          className="flex w-4 h-4 bg-opacity-50 cursor-pointer fill-neutral-500 hover:fill-pa-purple"
          onClick={handleImageClick}
        />
      </label>
    </div>
  );
};

export default ImportFileDataHistory;
