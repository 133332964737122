import React, { useEffect, useState, useContext } from 'react'
import { Navbar } from '../../components/layout/Navbar'
import { Sidebar } from '../../components/layout/Sidebar'
import { message, Select, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ImportFileHistory } from '../../components/ScreeningTool/Complements/ImportFileHistory'
import { DATA_SCREENING_TOOL } from '../../components/elements/types/type_screening_tool'
import { useTabScreening } from '../../hooks/useTabScreening'
import { useUsers } from '../../hooks/useUsers'
import { AlertModal } from '../../components/AlertModal'
import { ReactComponent as IconCheckFile } from "../../assets/icon/check-circle.svg";
import { ReactComponent as IconCircle } from "../../assets/icon/circle.svg";
import { STATUS_CODE_SCREENING_TOOL } from "../../components/elements/types/type_response_API";
import { DashboardSCTContext } from '../../context/DashboardSCT/DasboardSCTContext'
import { NAME_PAGES } from '../../components/elements/types/type_pages'
import { InfoCircleOutlined } from '@ant-design/icons'
import { DashboardContext } from '../../context/Dashboard/DashboardContentx'

export const StepMenuScreeningTool = () => {
  const navigate = useNavigate()
  const { Option } = Select;
  const { getDataFilterUserSCT } = useContext(DashboardSCTContext);
  const { getSelectedReservoirSCT } = useContext(DashboardContext);

  const { User } = useUsers();
  const { userDataInfo, mutationCreateScreening, refetchQueryListReservoir, queryListReservoir } = useTabScreening();
  const [ inputDataScreeningTool,setInputDataScreeningTool ] = useState(DATA_SCREENING_TOOL.initialDataScreeningTool);
  const [ inputEmpty, setInputEmpty ] = useState(false);
  const [ dataFile, setDataFile ] = useState([]);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ messageAlert, setMessageAlert ] = useState(false)
  const [ typeAlert, setTypeAlert ] = useState(false);
  const [ stateUploadFile, setStateUploadFile ] = useState(false);
  const [ dataListReservoir, setDataListReservoir ] = useState([]);

  useEffect(()=>{
    refetchQueryListReservoir();
  }, []);

  useEffect(() => {
    
    if(!!queryListReservoir?.data[0] && queryListReservoir?.statusCode === STATUS_CODE_SCREENING_TOOL.SUCCESS){
      setDataListReservoir(queryListReservoir?.data[0]);
      
    }else if(!queryListReservoir?.data && queryListReservoir?.statusCode === STATUS_CODE_SCREENING_TOOL.ERROR){
      setDataListReservoir([]);
    }
  }, [queryListReservoir]);
  
  const handleChangeInputData = (e) => {
    const {name,value} = e.target;
    const wellIdExists = name === "reservoir_id" && dataListReservoir ? Object.values(dataListReservoir)?.some((obj) => obj.reservoir_id === value) : null;
    const wellNameExists = name === "reservoir_name" && dataListReservoir ? Object.values(dataListReservoir)?.some((obj) => obj.reservoir_name === value) : null;

    if (wellIdExists) {
      setInputDataScreeningTool((prevState) => ({
        ...prevState,
        reservoir_id: null,
      }));

      message.error(
        "There is already a well with the same ID, please generate a new ID"
      );
    }else if (wellNameExists){
      setInputDataScreeningTool((prevState) => ({
        ...prevState,
        reservoir_name: null,
      }));

      message.error(
        "There is already a well with the same NAME, please generate a new NAME"
      );
    }else{
      setInputDataScreeningTool((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  const handleChangeSelect = (nameSelected,valueSelected) => {
    const customEvent = {
      target: {
        name: nameSelected,
        value: valueSelected,
      },
    };
    handleChangeInputData(customEvent);
  }

  const handleKeyDown = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const handleClickPrev = () => {
    navigate(`/${NAME_PAGES.screening_tool.list_well}`)
  }
  
  const handleClickDone = () => {
    if(
      !inputDataScreeningTool.reservoir_name ||
      !inputDataScreeningTool.reservoir_type ||
      !inputDataScreeningTool.reservoir_id ||
      !inputDataScreeningTool.country ||
      !inputDataScreeningTool.state ||
      !inputDataScreeningTool.field || 
      !Object.keys(dataFile).length
    ){
      handleOpenModal();
      setTypeAlert("Warning");
      setMessageAlert("There are empty fields please validate that the information is complete.");
      setInputEmpty(true);
    }
    else{
      const dataCreateReservoir = {
        reservoir_name: inputDataScreeningTool.reservoir_name,
        reservoir_type: inputDataScreeningTool.reservoir_type,
        state: inputDataScreeningTool.state,
        selection_dashboard: inputDataScreeningTool.selection_dashboard,
        gor: 75,
        water_cut: 75,
        oil_rate: 75,
        depth: 75,
      };
      const dataFinalCreateReservoir = {...dataCreateReservoir, data: [...dataFile]}
      
      async function executeFunctionCreate() {

        await mutationCreateScreening.mutateAsync({
          operator: userDataInfo.operator,
          lease: userDataInfo.lease,
          region: userDataInfo.region,
          county: inputDataScreeningTool.country,
          field: inputDataScreeningTool.field,
          reservoir: inputDataScreeningTool.reservoir_id,
          userName: User.user_name,
          data: dataFinalCreateReservoir
        });

        const dataFilterReservoir = {
          field: inputDataScreeningTool.field,
          state: inputDataScreeningTool.state,
          reservoir_id: inputDataScreeningTool.reservoir_id,
          reservoir_name: inputDataScreeningTool.reservoir_name,
          county: inputDataScreeningTool.country
        };
        getDataFilterUserSCT(dataFilterReservoir);
        getSelectedReservoirSCT(inputDataScreeningTool.reservoir_id);

        await new Promise(resolve => setTimeout(resolve, 1000));
        
        message.success("Processing complete!");
        navigate(`/${NAME_PAGES.screening_tool.dashboard}`);
        setInputEmpty(false);

      }
      executeFunctionCreate();
    }
  }

  const handleUploadFile = (dataFile) => {
    
    if(!!Object.keys(dataFile).length){
      const dataFileEnd = dataFile.map((item)=>({
        ...item,
        'Depth (ft)': !item['Depth (ft)'] ? 0.0 : item['Depth (ft)']
      }));
      setStateUploadFile(true);
      setDataFile(dataFileEnd);
    }else{
      setStateUploadFile(false);
      setDataFile([]);
    }
  }

  const handleOpenModal = () => {
    setShowAlert(true);
  };

  const handleCloseModal = () => {
    setShowAlert(false);
  };

  return (
    <div className='flex flex-col w-full h-full bg-white min-h-screen'>
      <div className="flex">
        <Navbar/>
        <Sidebar className="flex absolute h-full "/>
      </div>
      <div className="flex w-full h-full">
        <AlertModal
          showAlert={showAlert}
          handleCloseModal={handleCloseModal}
          messageAlert={messageAlert}
          typeModal={typeAlert}
        ></AlertModal>
      </div>
      <div className='flex flex-col w-full h-full pl-[88px] text-center justify-center'>
        <div className="flex w-full h-[24px] justify-center text-center items-center mb-[27px] mt-[27px] ">
          <div className="flex flex-row w-[77%] h-[24px] gap-5">
            <h1 className="flex flex-row font-bold text-[20px]">
              Reservoir definition
            </h1>
          </div>
        </div>
        <div className="flex w-full h-[42em] justify-center text-center items-center mb-[0] ">
          <div className='flex flex-col w-[77%] h-full'>
          <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir Id
            </label>
            <input
              type="text"
              name="reservoir_id"
              onKeyDown={handleKeyDown}
              value={inputDataScreeningTool.reservoir_id}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                (inputEmpty && inputDataScreeningTool.reservoir_id === "") || inputDataScreeningTool.reservoir_id === null
                  ? " border-red-700 bg-yellow-100"
                  : "border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir name
            </label>
            <input
              type="text"
              name="reservoir_name"
              onKeyDown={handleKeyDown}
              value={inputDataScreeningTool.reservoir_name}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                (inputEmpty && inputDataScreeningTool.reservoir_name === "") || inputDataScreeningTool.reservoir_name === null
                  ? " border-red-700 bg-yellow-100"
                  : "border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Reservoir type
            </label>
            <Select
              style={{
                backgroundColor: inputEmpty && inputDataScreeningTool.reservoir_type === "" ? "#B91C1C" : "",
                border: inputEmpty && inputDataScreeningTool.reservoir_type === "" ? "1px solid #B91C1C" : "",
                borderRadius: "8px",
                textAlignalign: "left"
              }}
              name="fluid_type"
              value={inputDataScreeningTool.reservoir_type || ""}
              className="w-full max-w-[592px] h-[47px] text-start mb-[17px]"
              onChange={(value) => handleChangeSelect("reservoir_type", value)}
            >
              <Option value="">Selected option</Option>
              <Option value="oil">Oil </Option>
              <Option value="Gas">Gas</Option>
            </Select>
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Country
            </label>
            <input
              type="text"
              name="country"
              onKeyDown={handleKeyDown}
              value={inputDataScreeningTool.country}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                inputEmpty && inputDataScreeningTool.country === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-[#BCBCCC]"
              } `}
            />
              <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              State
            </label>
            <input
              type="text"
              name="state"
              onKeyDown={handleKeyDown}
              value={inputDataScreeningTool.state}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                inputEmpty && inputDataScreeningTool.state === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Field
            </label>
            <input
              type="text"
              name="field"
              onKeyDown={handleKeyDown}
              value={inputDataScreeningTool.field}
              onChange={handleChangeInputData}
              className={`w-full max-w-[592px] h-[47px] rounded-[4px] px-3 mb-[17px] border border-solid  ${
                inputEmpty && inputDataScreeningTool.field === ""
                  ? " border-red-700 bg-yellow-100"
                  : "border-[#BCBCCC]"
              } `}
            />
            <label
              htmlFor=""
              className="w-full max-w-[592px] text-start font bold text-[15px] mb-[7px]"
            >
              Data History
              <Tooltip title="Remember iclude: Well Name/Oil Rate (STB/D)/Gas rate (Mscf/D)/Water rate (STB/D)/Depth (ft)">
                <InfoCircleOutlined className="ml-2" style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
              </Tooltip>
            </label>
              <div className="flex w-[157px] h-[42px] justify-start items-start">
                <ImportFileHistory uploadfile={handleUploadFile} ></ImportFileHistory>
              </div>
            { stateUploadFile
              ? 
              <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                <IconCheckFile className="w-5 h-5 fill-green-600"></IconCheckFile>
                <label htmlFor="" className="font-sans text-green-600">The file has been loaded</label>
              </div>
              :  
              <div className="flex flex-row w-full h-[42px] items-center justify-start gap-2">
                <IconCircle className="w-5 h-5 fill-pa-purple"></IconCircle>
                <label htmlFor="" className="font-sans text-red-600">The file has not been loaded</label>
              </div>
            }
          </div>
        </div>
        <div className="flex w-full text-center justify-center items-center mt-auto">
            <div className="flex flex-row gap-4 w-[1230px] h-full pl-[88px] text-right justify-end items-end">
              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickPrev()}
              >
                Cancel
              </button>

              <button
                className="w-[180px] h-[46px] bg-pa-purple text-white rounded-md"
                onClick={() => handleClickDone()}
              >
                Run
              </button>
            </div>
          </div>
      </div>
    </div>
  )
}
