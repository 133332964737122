import React, { useEffect, useState } from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer 
} from 'recharts';

const ChartReservoirIPR = ({dataGraph, typeGraph}) => {
  
  const [data, setData] = useState([]);

  useEffect(() => {
    let validateDataGraph = [];
    if (typeGraph === "pseudo_press_single_rate") {
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated", "press_decrease");
      setData(validateDataGraph);
    }else if (typeGraph === "forchheimer_pseudo_press_prop") {
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated_a_b", "press_decrease");
      setData(validateDataGraph);
    }else if (typeGraph === "pseudo_press_multi_rate"){
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated_faf", "press_decrease");
      setData(validateDataGraph);
    }else if (typeGraph === "forchheimer_pseudo_press_multi_rate"){
      validateDataGraph = transformedData(dataGraph, "gas_rate_estimated", "press_decrease");
      setData(validateDataGraph);
    }else{
      setData([]);
    }
  }, [dataGraph, typeGraph]);
  
  function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
      const dataTooltip = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '5px' }}>
          <p style={{ color: '#000000' }}>{`Pressure: ` + (dataTooltip?.press).toFixed(2) + ` (psia)`}</p>
          <p style={{ color: '#d4362c' }}>{`Gas rate: ` + (dataTooltip?.gas_rate).toFixed(2) + ` (Mscf/d)`}</p>
          <p style={{ color: '#000000' }}>{`AOFP: ` + (data[data.length-1]?.gas_rate).toFixed(2) + ` (Mscf/d)`}</p>
        </div>
      )
    }
  }

  function transformedData(data, value1, value2) {
    if(data && value1 && value2){
      const transform = data[value1]?.map((_, index) => ({
        gas_rate: data[value1][index],
        press: data[value2][index]
      }));
  
      return transform ? transform : [];
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 25,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="gas_rate" 
          label={{ 
            value: "Gas rate (Mscf/d)", 
            position: "insideBottom", 
            offset: -5,
            dx: -5,
            dy: 10,
          }}
          type="number"
          tickCount={11}
        />
        <YAxis
          type="number"
          label={{ 
            value: "Bottom hole flowing pressure (psia)", 
            angle: -90, 
            position: "insideLeft", 
            offset: -5,
            dx: -5,
            dy: 100,
          }}
          tickCount={11}
        />
        <Tooltip content={<CustomTooltip/>}/>
        <Line type="monotone" dataKey="press" stroke="#d4362c" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default ChartReservoirIPR;

