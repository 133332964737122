import React, { useState } from "react";
import classes from "./Carrusel.module.css";
import { Tooltip as AntdTooltip } from "antd";
import { ReactComponent as IconArrowLeft } from "../../../../assets/img/icons/ArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../../../../assets/img/icons/ArrowRight.svg";
const {
  containterCarrusel,
  containerPrincipal,
  containerSlides,
  slide,
  containerBtns,
  navButton,
  indicatorsContainer,
  indicator,
  activeIndicator,
} = classes;
const Carrusel = ({ children, tooltips }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = React.Children.count(children);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  return (
    <div className={containterCarrusel}>
      <div className={containerPrincipal}>
        <div
          className={containerSlides}
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {React.Children.map(children, (child) => (
            <div className={slide}>{child}</div>
          ))}
        </div>
        <div className={containerBtns}>
          <AntdTooltip
            placement="bottom"
            title={tooltips?.[currentSlide - 1 >= 0 ? currentSlide - 1 : totalSlides - 1] || "Previous"}
            color="#662D91"
          >
            <button className={navButton} onClick={handlePrev}>
              <IconArrowLeft />
            </button>
          </AntdTooltip>
          <div className={indicatorsContainer}>
            {React.Children.map(children, (_, index) => (
              <div
                onClick={() => goToSlide(index)}
                className={`${indicator} ${
                  currentSlide === index ? activeIndicator : ""
                }`}
              />
            ))}
          </div>
          <AntdTooltip
            placement="bottom"
            title={tooltips?.[currentSlide + 1 < totalSlides ? currentSlide + 1 : 0] || "Next"}
            color="#662D91"
          >
            <button className={navButton} onClick={handleNext}>
              <IconArrowRight />
            </button>
          </AntdTooltip>
        </div>
      </div>
    </div>
  );
};

export default Carrusel;
