import { Modal } from "antd";
import React from "react";

export const AlertModalOption = ({
	showAlert,
	handleOkModal,
	handleCloseModal,
	messageAlert,
	typeModal,
}) => {
	return (
		<Modal
			zIndex={10000}
			title={typeModal}
			open={showAlert}
			onCancel={handleCloseModal}
			onOk={handleOkModal}
			okText="Ok"
			className="bg-[#662D91] rounded-md"
			okButtonProps={{
				className: "bg-[#662D91]",
			}}
		>
			<p>{messageAlert}</p>
		</Modal>
	);
};
