import {
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Checkbox, message, Modal, Select, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AddRegister from "../../components/User/AddRegister";
import { useUsers } from "../../hooks/useUsers";
import {
    getUsersCLients,
  postRoles,
  postUsersAdd,
  putUsersUpdate,
} from "../../services/api/usersAPI";
import { UserContext } from "../../context/User/UserContext";
import { useNavigate } from "react-router-dom";

export const FormUser = ({ type, user, table }) => {
  const navigate = useNavigate();
  const { Option } = Select;
  const {
    User,
    body,
    setBody,
    TableOperators,
    optionRegions,
    optionLeases,
    TableRoles,
    setOptionsOperators,
    setLeases,
    setRegions,
    setOptionsRoles,
    validatePassword,
    handleCheckDuplicates,
    handleCheckDuplicatesUpdate,
    UpdateSuperUser,
    setLoadPage,
    refetchDataUserName,
    dataUserName,
    loadDataUserName,
    ErrorDataUserName,
    refetchDataOptionOperators,
    dataOptionsOperators
  } = useUsers();
  const Token = User.token;
  const [validationPassword, setValidationPassword] = useState("");
  const {selectUser, selectedUserCrud } = useContext(UserContext);
  const [error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [messagePattern, setPattern] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isCheckedTable, setIsCheckedTable] = useState({
    users_enovates: false,
    users_externals: false,
  });
  const [passwordValidate, SetPasswordValidate] = useState(false);
  const [inputEmpty, setInputEmpty] = useState(false);

  useEffect(() => {
    if (type === "edit") {
      refetchDataUserName();
    }
  }, []);

  useEffect(() => {
    if (dataUserName && type === "edit") {
      setIsCheckedTable({ ...isCheckedTable, [table]: true });
      setBody({
        ...body,
        user_name: dataUserName.user_name,
        user_password: dataUserName.user_password,
        clients: dataUserName.clients,
        proyects: dataUserName.proyects,
        platforms: dataUserName.platforms,
        role: dataUserName.roles_name,
      });
    }
  }, [dataUserName]);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        className="text-white rounded-md bg-pa-purple"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setIsCheckedTable((prevState) => {
      // Resetear todos los checkboxes a false
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Activar solo el checkbox que fue clickeado
      return {
        ...newState,
        [name]: checked,
      };
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    const validateTableUser = Object.values(isCheckedTable).some(
      (value) => value === true
    );
    if (validateTableUser) {
      if (name === "user_name") {
        const pattern = /^[a-zA-Z0-9\s-@._]*$/;
        if (pattern.test(value)) {
          let transformedValue = "";
          transformedValue = value.replaceAll(" ", "_");
          selectedUserCrud({...selectUser, [name]: value });
          setBody({ ...body, [name]: transformedValue });
        } else {
          showModal();
          setPattern(true);
          setTitleModal("Error");
          setMessageModal("The entered value contains illegal characters");
        }
      } else if (name === "user_password") {
        if (validatePassword(value)) {
          setValidationPassword("✔️");
        } else {
          setValidationPassword("❌");
        }
        SetPasswordValidate(validatePassword(value));
        selectedUserCrud({...selectUser, [name]: value });
        setBody({ ...body, [name]: value });
      } else {
        selectedUserCrud({...selectUser, [name]: value });
        setBody({ ...body, [name]: value });
      }
    } else {
      message.info("Select first the type user");
    }
  };

  const handleChangeSelect = (nameData, valueData, isMultiple = false) => {
    const validateTableUser = Object.values(isCheckedTable).some(
      (value) => value === true
    );

    if (validateTableUser) {
      let filteredValues = valueData;

      if (isMultiple && Array.isArray(valueData)) {
        filteredValues = valueData.filter((option) => option && option !== "");
      }

      const customEvent = {
        target: {
          name: nameData,
          value: filteredValues,
        },
      };
      handleChange(customEvent);
    } else {
      message.info("Select first the type user");
    }
  };

  //Send Form Accion
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        const table = Object.entries(isCheckedTable).find(([key, value]) => value === true)?.[0];
        SetPasswordValidate(validatePassword(body.user_password));

        if (isValidSubmission(type)) {
            setDisabled(true);
            setLoadPage(true);
            try {
                if (type === "register") {
                    await handleRegister(table);
                } else if (type === "edit") {
                    await handleEdit(table);
                }
            } catch (error) {
                setError("No registration data has been entered");
            } finally {
                setLoadPage(false);
                setSubmitted(false);
                setTouchedPassword(false);
                setDisabled(false);
            }
        } else {
            message.info("Incomplete fields, please validate the information entered");
        }
    };

    // Validación de campos de formulario
    const isValidSubmission = (type) => {
        return (
            body.user_name !== "" &&
            body.user_password !== "" &&
            body.clients !== "" &&
            body.platforms !== "" &&
            body.proyects !== "" &&
            body.role !== "" &&
            (type === "register" || type === "edit")
        );
    };

    // Manejo del registro de usuarios
    const handleRegister = async (table) => {
        // Se deben crear primero los clientes, proyectos y plataformas
        await new Promise(resolve => setTimeout(resolve, 300));
        await handleCheckDuplicates(TableOperators, optionLeases, optionRegions);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Simula el tiempo de carga

        const data = await postUsersAdd(body, table, Token);
        if (data.token) {
            showModal("Successful", "Register Completed");
        } else {
            showModal("Error", "The process wasn't successful, please validate the information entered");
        }
    };

    // Manejo de la edición de usuarios
    const handleEdit = async (table) => {
        await new Promise(resolve => setTimeout(resolve, 300));
        await handleCheckDuplicatesUpdate(TableOperators, optionLeases, optionRegions);
        await new Promise(resolve => setTimeout(resolve, 2000));
        const { data: dataOptionsOperators } = await refetchDataOptionOperators();
        await new Promise(resolve => setTimeout(resolve, 500));
        const { idClients, idClient } = getClientIds(dataOptionsOperators,table);
        await new Promise(resolve => setTimeout(resolve, 500));
        const bodyUpdate = {
            user_name: body.user_name,
            user_clientId: table === "users_externals" ? idClient : idClients,
            platforms: body.platforms,
            proyects: body.proyects,
        };
        const data = await putUsersUpdate(bodyUpdate, dataUserName.iduser, table, Token);
        if (!data?.message) {
            showModal("Successful", "Update Completed");
        } else {
            showModal("Error", "The process wasn't successful, please validate the information entered");
        }
    };

    // Obtiene idClients e idClient a partir de los clientes
    const getClientIds = (dataOptionsOperators, table) => {
        let idClients = [];
        let idClient = null;
    
        idClients = dataOptionsOperators
            ?.filter(item => body.clients.includes(item.client))
            .map(item => item.idclient) || [];
    
        if (table === "users_externals") {
            const dataValidate = dataOptionsOperators?.find(item => body.clients === item.client);
            idClient = dataValidate ? dataValidate.idclient : null;
        }
        return { idClients, idClient };
    };

    // Muestra un modal con título y mensaje
    const showModal = (title, message) => {
        setTitleModal(title);
        setMessageModal(message);
        setIsModalOpen(true);;
    };
    const handleAddOption = async (dataTable, name) => {
        if (!!dataTable) {
        message.info(
            "A new item has been created, please select it in the respective box"
        );
        handleEntityRegistration(dataTable, name);
        } else message.info("The creation not succefull");
    };

    const handleEntityRegistration = async (dataTable, name) => {
        switch (
        name // entityType es una variable o estado que indica si es un cliente, proyecto, etc.
        ) {
        case "clients":
            return await setOptionsOperators(dataTable);
        case "projects":
            return await setLeases(dataTable);
        case "platforms":
            return await setRegions(dataTable);
        case "role":
            return await setOptionsRoles(dataTable);
        default:
            throw new Error("Unknown entity type");
        }
    };

    const handleOk = () => {
        if (messageModal === "Register Completed" || messageModal === "Update Completed" ) {
            UpdateSuperUser();
            message.info("Wait a few seconds while the Super User is updated");
            setBody({
                user_name: "",
                user_password: "",
                clients: "",
                proyects: "",
                platforms: "",
                role: "",
            });
        }

        if (table === "users_externals") {
        navigate("/Users-External");
        setIsModalOpen(false);
        } 
        else if(table === "users_enovates") {
        navigate("/Users-Internal");
        setIsModalOpen(false);
        }
        
    };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={titleModal}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
        centered
        className="rounded-md"
        okButtonProps={{
          className: "bg-[#662D91]",
        }}
      >
        <p className="text-lg text-center">{messageModal}</p>
      </Modal>
      <form
        className="flex flex-col w-[400px] bg-zinc-200"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col items-start justify-center w-full h-full text-start">
          <div className="mt-5 mb-5 ml-auto mr-auto">
            <h3 className="text-3xl text-right">PRODUCTION </h3>
            <h3 className="text-2xl text-right mt-[-10px]">ANALYTICS </h3>
          </div>
          <div className="flex flex-col w-full mt-3 px-[30px]">
            <div className="flex flex-row w-full">
              <h1 className="flex font-bold font-row">TYPE USER</h1>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-4 mt-3">
              <Checkbox
                name="users_enovates"
                checked={isCheckedTable.users_enovates}
                onChange={handleCheckboxChange}
              >
                <div className="flex flex-row text-center">
                  <label>Master</label>
                </div>
              </Checkbox>
              <Checkbox
                name="users_externals"
                checked={isCheckedTable.users_externals}
                onChange={handleCheckboxChange}
              >
                <div className="flex flex-row text-center">
                  <label>Simple User</label>
                </div>
              </Checkbox>
            </div>
            <div className="flex flex-row items-center justify-start w-full mt-3">
              <div className="flex flex-col w-full text-start">
                <label
                  htmlFor=""
                  className="flex w-full items-end text-start text-[15px] font-bold"
                >
                  USER NAME
                </label>
                <input
                  type="email"
                  name="user_name"
                  value={body.user_name}
                  placeholder="youremail@company.ltd"
                  onChange={handleChange}
                  className={`w-full h-[47px] rounded-[4px] border border-solid mb-[17px] mt-[10px] ${
                    inputEmpty && body.user_name === ""
                      ? " border-red-700 bg-yellow-100"
                      : "border border-solid border-[#BCBCCC]"
                  }`}
                />
              </div>
            </div>
            {type === "register" &&
                <div className="flex flex-row items-center justify-start w-full">
                    <div className="flex flex-col w-full text-start">
                        <label htmlFor="password" className="text-[15px] font-bold">
                        PASSWORD
                        </label>
                        <div className="relative w-full">
                        <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            name="user_password"
                            value={body.user_password}
                            onChange={handleChange}
                            onBlur={() => setTouchedPassword(true)}
                            className={`w-full h-[47px] rounded-[4px] border border-solid mb-[17px] mt-[10px] pr-10 ${
                            (submitted || touchedPassword) &&
                            (body.user_password === "" || !passwordValidate)
                                ? "border-red-700 bg-yellow-100"
                                : "border border-solid border-[#BCBCCC]"
                            }`}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
                            <button
                            type="button"
                            className="text-gray-600"
                            style={{
                                fontSize: "22px",
                                color: "rgba(0, 0, 0, 0.45)",
                            }}
                            title="View password"
                            onClick={() => setShowPassword(!showPassword)}
                            >
                            {showPassword ? (
                                <EyeInvisibleOutlined />
                            ) : (
                                <EyeOutlined />
                            )}
                            </button>
                            <Tooltip
                            title={
                                <ul className="pl-4 list-disc">
                                <li>
                                    The password must contain a minimum of 8 characters.
                                </li>
                                <li>
                                    The password must contain at least one uppercase
                                    letter.
                                </li>
                                <li>
                                    The password must contain at least one special
                                    character.
                                </li>
                                <li>
                                    The password must contain at least one number.
                                </li>
                                </ul>
                            }
                            placement="top"
                            >
                            <InfoCircleOutlined
                                style={{
                                color: "rgba(0, 0, 0, 0.45)",
                                fontSize: "18px",
                                cursor: "pointer",
                                }}
                            />
                            </Tooltip>
                            <h1>{validationPassword}</h1>
                        </div>
                    </div>
                </div>
                </div>

            }

            <div className="flex flex-row items-center justify-start w-full">
              <div className="flex flex-col w-full text-start">
                {(isCheckedTable.users_enovates ||
                  isCheckedTable.users_externals) && (
                  <>
                    <label
                      htmlFor=""
                      className="flex w-full items-end text-start text-[15px] font-bold"
                    >
                      OPERATOR
                    </label>
                  </>
                )}
                {/* Espacio para el tipo de select segun TYPE USER */}
                {!isCheckedTable.users_enovates == false && (
                  <>
                    <Select
                      style={{
                        backgroundColor:
                          inputEmpty && body.clients === "" ? "#B91C1C" : "",
                        border:
                          inputEmpty && body.clients === ""
                            ? "1px solid #B91C1C"
                            : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      name="clients"
                      value={body.clients}
                      onChange={(value) =>
                        handleChangeSelect("clients", value, true)
                      }
                      className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid mb-[17px] mt-[10px]"
                      mode="multiple"
                      tagRender={tagRender}
                    >
                      {TableOperators.map((item) => (
                        <Option key={item.idclient} value={item.client}>
                          {item.client}
                        </Option>
                      ))}
                    </Select>
                  </>
                )}
                {!isCheckedTable.users_externals == false && (
                  <>
                    <Select
                      style={{
                        backgroundColor:
                          inputEmpty && body.clients === "" ? "#B91C1C" : "",
                        border:
                          inputEmpty && body.clients === ""
                            ? "1px solid #B91C1C"
                            : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      name="clients"
                      value={body.clients}
                      onChange={(value) =>
                        handleChangeSelect("clients", value, false)
                      }
                      className="w-full h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                      placeholder="Select Client"
                    >
                      {TableOperators.map((item) => (
                        <Option key={item.idclient} value={item.client}>
                          {item.client}
                        </Option>
                      ))}
                    </Select>
                  </>
                )}
              </div>
            </div>
            {(isCheckedTable.users_enovates ||
              isCheckedTable.users_externals) && (
              <>
                <AddRegister
                  Data={TableOperators}
                  name={"clients"}
                  dataTable={(dataTable) =>
                    handleAddOption(dataTable, "clients")
                  }
                ></AddRegister>
              </>
            )}
            <div className="flex flex-row items-center justify-start w-full mt-3">
              <div className="flex flex-col w-full text-start">
                <label
                  htmlFor=""
                  className="flex w-full items-end text-start text-[15px] font-bold"
                >
                  LEASE
                </label>
                <Select
                  style={{
                    backgroundColor:
                      inputEmpty && body.proyects === "" ? "#B91C1C" : "",
                    border:
                      inputEmpty && body.proyects === ""
                        ? "1px solid #B91C1C"
                        : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  name="proyects"
                  value={body.proyects}
                  onChange={(value) =>
                    handleChangeSelect("proyects", value, true)
                  }
                  className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                  mode="multiple"
                  tagRender={tagRender}
                >
                  {optionLeases.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <AddRegister
              Data={optionLeases}
              name={"projects"}
              dataTable={(dataTable) => handleAddOption(dataTable, "projects")}
            ></AddRegister>
            <div className="flex flex-row items-center justify-start w-full mt-3">
              <div className="flex flex-col w-full text-start">
                <label
                  htmlFor=""
                  className="flex w-full items-end text-start text-[15px] font-bold"
                >
                  REGION
                </label>
                <Select
                  style={{
                    backgroundColor:
                      inputEmpty && body.platforms === "" ? "#B91C1C" : "",
                    border:
                      inputEmpty && body.platforms === ""
                        ? "1px solid #B91C1C"
                        : "",
                    borderRadius: "8px",
                    textAlignalign: "left",
                  }}
                  name="platforms"
                  value={body.platforms}
                  onChange={(value) =>
                    handleChangeSelect("platforms", value, true)
                  }
                  className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                  mode="multiple"
                  tagRender={tagRender}
                >
                  {optionRegions.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <AddRegister
              Data={optionRegions}
              name={"platforms"}
              dataTable={(dataTable) => handleAddOption(dataTable, "platforms")}
            ></AddRegister>
            <div className="flex flex-row items-center justify-start w-full mt-3">
              <div className="flex flex-col w-full text-start">
                {isCheckedTable.users_enovates && (
                  <>
                    {/* Espacio para el tipo de select segun TYPE USER */}
                    <label
                      htmlFor=""
                      className="flex w-full items-end text-start text-[15px] font-bold"
                    >
                      ROLE
                    </label>
                  </>
                )}
                {isCheckedTable.users_enovates && (
                  <>
                    {/* Espacio para el tipo de select segun TYPE USER */}
                    <Select
                      style={{
                        backgroundColor:
                          inputEmpty && body.role === "" ? "#B91C1C" : "",
                        border:
                          inputEmpty && body.role === ""
                            ? "1px solid #B91C1C"
                            : "",
                        borderRadius: "8px",
                        textAlignalign: "left",
                      }}
                      name="role"
                      value={body.role}
                      onChange={(value) =>
                        handleChangeSelect("role", value, false)
                      }
                      className="w-full max-w-[592px] h-[47px] rounded-[4px] border-solid border-[#BCBCCC] mb-[17px] mt-[10px]"
                      // mode="multiple"
                      tagRender={tagRender}
                      // defaultValue={["gold", "cyan"]}
                    >
                      {TableRoles.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </>
                )}
              </div>
            </div>
            {isCheckedTable.users_enovates && (
              <>
                <AddRegister
                  Data={TableRoles}
                  name={"role"}
                  dataTable={(dataTable) => handleAddOption(dataTable, "role")}
                ></AddRegister>
              </>
            )}
            <div className="flex flex-row w-full mt-3 mb-7">
              <div className="flex flex-col items-center justify-center w-full">
                <button className="btn-1" type="submit" disabled={disabled}>
                  REGISTER
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
