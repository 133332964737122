import React from "react";
import { DashboardSCTContext } from "./DasboardSCTContext";
import { useDashboardSCT } from "../../hooks/useDashboardSCT";

export const DashboardSCTProvider = ({ children }) => {
	const {
		dataFilterUserSCT,
		viewLoadingSC,
		getDataFilterUserSCT,
		getViewLoadingSC,
	} = useDashboardSCT();
	return (
		<DashboardSCTContext.Provider
			value={{
				dataFilterUserSCT,
				viewLoadingSC,
				getDataFilterUserSCT,
				getViewLoadingSC,
			}}
		>
			{children}
		</DashboardSCTContext.Provider>
	);
};
