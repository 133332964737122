import React, { useContext, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from '../../assets/black-logo.png'
import { Alert } from 'antd';
import { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { loginUser } from '../../auth/services/loginAPI';
import { AuthContext } from "../../auth/context/AuthContext";



export const AuthForm = () => {
  const { signin } = useContext(AuthContext);
  const [captchaValide, changeCaptchaValide] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [user_data, setUser_data] = useState({user_name: "", user_password: "" });
  const [messageAlert, setMessageAlert] = useState("");
  const navigate = useNavigate();

  const captcha = useRef(null);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    let newData = { ...user_data, [name]: value };
    setUser_data(newData);
  };
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {

    const captchaDiv = document.querySelector(".recaptcha > div");
    setTimeout(() => {
      if (captchaDiv && captchaDiv.childElementCount === 0) {
        setShouldReload(true);
      }
    }, 1000);

  }, []);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessageAlert("");

    if(user_data.user_password && user_data.user_name){
      if (!event.target.checkValidity() || !captcha.current?.getValue()) {
        changeCaptchaValide(false);
      } 
      else {
        if (captcha.current?.getValue() && event.target.checkValidity()) {
          const responseLogin = await loginUser(user_data);
          if (responseLogin.token) {
            const userData = {
              user_name: (user_data.user_name).split('@')[0],
              user_email: user_data.user_name,
              token: responseLogin.token,
              table: responseLogin.table,
              captcha: captcha.current?.getValue(),
              role:responseLogin.role,
              auth: true
            };
            signin(userData);
            changeCaptchaValide(true);
            navigate("/Map");
            return null;
          } else {
            setMessageAlert("Incorrect user or password, please verify the data");
            //alert("Incorrect user or password, please verify the data's form");
          }
        }
      }
    }
    else{
      setMessageAlert("the username and password are required to enter");
    }
  };

  function onChangeCaptcha() {
    if (captcha.current?.getValue()) {
      changeCaptchaValide(true);
    } else {
      changeCaptchaValide(false);
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-[450px] h-full bg-white shandow-md rounded-xl lg:rounded-lg bg-opacity-80  p-12  text-center justify-center "
    >
      <div className="flex  flex-col cw-full h-15 text-center justify-normal items-center">
          <img src={Logo} alt="" className="w-[250px] items-center "></img>
      </div>
      <div className="mb-10 mt-10 mr-auto ml-auto">
        <h3 className="text-4xl text-right text-pc-purple">PRODUCTION</h3>
        <h3 className="text-3xl text-right text-pc-purple mt-[-10px]">ANALYTICS </h3>
      </div>
      <div className="mb-4">
        <label htmlFor="text" className="block text-nc-white text-left text-xs pl-2 pb-3">
          USER NAME
        </label>
        <input
          type="email"
          name="user_name"
          id="user_name"
          placeholder="youremail@company.ltd"
          value={user_data.user_name}
          onChange={handleInputChange}
          className="bg-pc-grey outline-none  rounded-xl w-full p-3 text-nc-gray leading-tight hover:border-black  focus:border-black focus:border-2"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="password" className="block text-nc-white text-left text-[14px] pl-2 pb-3">
          PASSWORD
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            name="user_password"
            id="user_password"
            placeholder="******"
            value={user_data.user_password}
            onChange={handleInputChange}
            className="bg-pc-grey outline-none  rounded-xl w-full p-3 text-nc-gray leading-tight hover:border-black  focus:border-black focus:border-2"
          />
          <span
            className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            )}
          </span>
        </div>
      </div>

      <div className="flex flex-row gap-2 pl-2 pb-3">
        <NavLink to="/forgot-password" className="text-gray-500 hover:text-pc-blue font-sans text-[11px] hover:underline  focus:underline ">FORGOT PASSWORD</NavLink>
        <label htmlFor="" className="text-gray-500 hover:text-pc-blue font-sans text-[11px]">|</label>
        <NavLink to="/add-account" className="text-gray-500 hover:text-pc-blue font-sans text-[11px] hover:underline  focus:underline">REQUEST ACCESS</NavLink>
      </div>

      <div className="recaptcha">
        <ReCAPTCHA
          hl="en"
          ref={captcha}
          sitekey="6Lfs5UUqAAAAAGkJoYaiuUIWge8DTEwTh3n7qEY8"
          onChange={onChangeCaptcha}
        />
      </div>
      {captchaValide === false && (
        <div className="my-3"><Alert message="Please check the captcha validation" type="error" showIcon /></div>
      )}
      {messageAlert.trim() !== "" &&
        <div className="my-3"><Alert message={messageAlert} type="error" showIcon /></div>
      }

      <div className="felx m-4 justify-center items-center text-center">
        <button className="text-black text-center bg-pc-grey p-2 w-[150px] h-[40px] rounded-xl hover:text-white hover:bg-neutral-600">SUBMIT</button>
      </div>

    </form>
  );
}
