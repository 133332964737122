import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";

import { TYPE_LINE_TUNNING_GRAPH } from "../../elements/types/type_gas_lift";

const ChartVLP = ({ 
    activeChecks, 
    dataPoints, 
    dataTableLine, 
    dataTableLine2, 
    domainMaxDepth, 
    domainMinDepth, 
    domainMaxPressure, 
    domainMinPressure, 
    tuningnNav, 
    dataPoint2, 
    dataPoint3, 
    selectedScenario, 
    dataScenario
}) => {
    
    const [depthArrayTuned, setDepthArrayTuned] = useState([]);
    const [depthArrayUntuned, setDepthArrayUntuned] = useState([]);
    const [pressureArrayTuned, setPressureArrayTuned] = useState([]);
    const [pressureArrayUntuned, setPressureArrayUntuned] = useState([]);
    const [coordinatesPoints, setCoordinatesPoints] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [ renderCombinedData, setRenderCombinedData ] = useState(false);

  useEffect(() =>{
    
    const valueDepthArray = dataTableLine != undefined ? dataTableLine?.depth_md : [];
    const valuePressureArray = dataTableLine != undefined ? dataTableLine?.pressure : [];
  
    const valueDepthArray2 = dataTableLine2 != undefined ? dataTableLine2?.depth_md : [];
    const valuePressureArray2 = dataTableLine2 != undefined ? dataTableLine2?.pressure : [];
    
    setDepthArrayTuned(valueDepthArray);
    setPressureArrayTuned(valuePressureArray);

    setPressureArrayUntuned(valuePressureArray2);
    setDepthArrayUntuned(valueDepthArray2);

    const valueCoordinatesPoints = dataPoints?.map((item) => ({
      depth: item?.gauge_tvd,
      gauge_pressure: item?.gauge_pressure
    })) || [];

    const valueCoordinatesPoints2 = [{
      depth: dataPoint2?.mid_perf_tvd_depth,
      reservoir_pressure: dataPoint2?.reservoir_pressure
    }];

    const valueCoordinatesPoints3 = [{
      depth: dataPoint2?.mid_perf_tvd_depth,
      pwf_user: dataPoint3?.pwf_user
    }];

    setCoordinatesPoints([...valueCoordinatesPoints, ...valueCoordinatesPoints2, ...valueCoordinatesPoints3]);
  },[dataTableLine, dataTableLine2, dataPoints, dataPoint2, dataPoint3]);

  useEffect(() => {
    const valueCombinedData = combinedDataVLP(depthArrayUntuned, pressureArrayUntuned, depthArrayTuned, pressureArrayTuned) || [];
    
    const finalData = [...valueCombinedData, ...coordinatesPoints];
    setCombinedData(finalData);    

    if (selectedScenario?.length) {
      setRenderCombinedData(true);
    }

  }, [depthArrayTuned, depthArrayUntuned, pressureArrayTuned, pressureArrayUntuned]);
  
  useEffect(() => {

  const valueCombinedData = combinedDataVLP(depthArrayUntuned, pressureArrayUntuned, depthArrayTuned, pressureArrayTuned);
    if (selectedScenario) {
      const filteredData = Object.keys(dataScenario)
      .filter(key => selectedScenario.includes(key))
      .reduce((obj, key) => {
          obj[key] = dataScenario[key];
          return obj;
      }, {});
  
      const depthMap = new Map(valueCombinedData?.map(item => [item.depth, item]));
  
      Object.keys(filteredData).forEach((key) => {
          const tunedData = filteredData[key].data.tuned;
          const untunedData = filteredData[key].data.untuned;
  
          untunedData.depth_md.forEach((depth, index) => {
              if (depthMap.has(depth)) {
                  const item = depthMap.get(depth);
                  item[`pressure_untuned_scenario_${key}`] = (untunedData.pressure[index])?.toFixed(2);
                  item[`pressure_tuned_scenario_${key}`] = depthArrayTuned ? (tunedData.pressure[index]).toFixed(2) : null;
              } else {
                  depthMap.set(depth, {
                      depth,
                      pressure_untuned: null,
                      pressure_tuned: null,
                      [`pressure_untuned_scenario_${key}`]: (untunedData.pressure[index])?.toFixed(2),
                      [`pressure_tuned_scenario_${key}`]: depthArrayTuned ? (tunedData.pressure[index]).toFixed(2) : null,
                  });
              }
          });
      });
  
      const combinedData = Array.from(depthMap.values());
      const finalData = [...combinedData, ...coordinatesPoints];
      setCombinedData(finalData);
      setRenderCombinedData(false);
     
    }
  }, [dataScenario, selectedScenario, renderCombinedData]);
  
  function customTooltip({ active, payload, option }) {
    
    if (active && payload && payload.length) {
      
      const valuesData = payload[0]?.payload;
      const tunedData = payload.filter(data => data.name.toLowerCase().includes("_tuned_scenario"));
      const untunedData = payload.filter(data => data.name.toLowerCase().includes("_untuned_scenario"));
      
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: "5px", textAlign: "left" }}
        >
          <p className="text-[15px]">
            {`Depth: `}
            <strong>{valuesData?.depth} (ft)</strong>
          </p>
          <p className="text-[15px]" style={{ color: "#009130"}}>
            {`FBHP at Perforations: `}
            <strong>{valuesData?.pwf_user} (ft)</strong>
          </p>
          <p className="text-[15px]" style={{ color: "#1e1b4b"}}>
            {`Gauge Pressure: `}
            <strong>{valuesData?.gauge_pressure} (ft)</strong>
          </p>
          <p className="text-[15px]" style={{ color: "#684200"}}>
            {`Reservoir Pressure: `}
            <strong>{valuesData?.reservoir_pressure} (ft)</strong>
          </p>
          {valuesData?.pressure_untuned && (
            <>
              <p className="text-[15px] mt-[2px]">
                {`Case Base: `}
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
                <p className="text-[15px] mr-[5px]"  style={{ color: "#253fce"}}>
                    {`Pressure: `}
                    <strong>{valuesData?.pressure_untuned} (psi)</strong>
                </p>
                {tuningnNav &&
                    <>
                    <p className="text-[15px]" style={{ color: "#FE3F40"}}>
                        {`Pressure: `}
                        <strong>{valuesData?.pressure_tuned} (psi)</strong>
                    </p>
                    </>
                }
              </div>
            </>

          )}
          {untunedData?.map((tuned, index) => (
              <>
                <p className="text-[15px]">{`${tuned.name.replace('pressure_untuned_scenario_', '')}`+":"}</p>
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2px' }}>
                  <div className="mr-[5px]">
                    <p className="text-[15px]" style={{ color: "#253fce" }}>
                        {`Pressure: `}
                        <strong>{tuned.value} (psi)</strong>
                    </p>
                  </div>
                  {tunedData[index] && (
                    <div>
                      <p className="text-[15px]" style={{ color: "#FE3F40" }}>
                          {`Pressure: `}
                          <strong>{tunedData[index].value} (psi)</strong>
                      </p>
                    </div>
                  )}
                </div>
              </>
          ))}
        </div>
      );
    }
  
    return null;
  }
  
  function generateTicks(max, min, divisions) {
    if (!Number.isNaN(max) && !Number.isNaN(min) && divisions > 1) {
  
      const range = max - min;
      const step = range / (divisions - 1);
  
      const ticks = Array.from({ length: divisions }, (_, i) =>
        Number((min + step * i).toFixed(0)) // Redondea a 0 decimales
      );      
      return ticks;
    } else {
      return null;
    }
  }

  function combinedDataVLP(depthArrayUntuned, pressureArrayUntuned, depthArrayTuned, pressureArrayTuned) {
      
      const combinedArray = depthArrayUntuned?.map((depth, index) => ({
        depth,
        pressure_untuned: parseFloat((pressureArrayUntuned[index]).toFixed(2)),
        pressure_tuned: depthArrayTuned ? parseFloat((pressureArrayTuned[index]).toFixed(2)) : null
      }));
      
      return combinedArray;
    
  };
  
  function validateDomain(min, max) {
    const validate = [
      isNaN(min) || min === undefined || min === null ? 'auto' : min,
      isNaN(max) || max === undefined || max === null ? 'auto' : max
    ];
    
    return validate;
  }

  return (
    <div className="flex w-full h-full">
      <ResponsiveContainer width="100%" height="98%">
        <ComposedChart
          width={400}
          height={300}
          margin={{
            top: 21,
            right: 30,
            left: 10,
            bottom: 10,
          }}
          data={combinedData}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="4 4" />
          <YAxis
            dataKey="depth"
            domain={validateDomain(domainMinDepth, domainMaxDepth)}
            interval={0}
            label={{
              value: `Depth (feet)`,
              style: { textAnchor: "middle" },
              angle: -90,
              position: "left",
              offset: 0,
            }}
            allowDataOverflow={true}
            tick={5}
            ticks={generateTicks(domainMinDepth, domainMaxDepth, 11)}
            yAxisId="depth"
          />
          <XAxis
            domain={validateDomain(domainMinPressure, domainMaxPressure)}
            interval={0}
            orientation="bottom"
            type="number"
            label={{
              value: `Pressure (psi)`,
              style: { textAnchor: "middle" },
              position: "bottom",
              offset: 0,
            }}
            allowDataOverflow={true}
            tick={5}
            ticks={generateTicks(domainMinPressure, domainMaxPressure, 9)}
          />
          
          <Tooltip content={customTooltip}/>

          <Line
            type="monotone"
            strokeWidth={2}
            dot={false}
            dataKey="pressure_tuned"
            stroke="#FE3F40"
            name="Pressure Tuned"
            yAxisId="depth"
          />

          <Line
            type="monotone"
            strokeWidth={2}
            dot={false}
            dataKey="pressure_untuned"
            stroke="#253fce"
            name="Pressure Untuned"
            yAxisId="depth"
          />

          {selectedScenario?.map((key, index) => (
            <Line
              key={`untuned_${key}`}
              type="monotone"
              dataKey={`pressure_untuned_scenario_${key}`}
              stroke="#253fce"
              name={`pressure_untuned_scenario_${key}`}
              dot={false}
              strokeWidth={TYPE_LINE_TUNNING_GRAPH[2][index % TYPE_LINE_TUNNING_GRAPH[2].length]}
              strokeDasharray={TYPE_LINE_TUNNING_GRAPH[0][index % TYPE_LINE_TUNNING_GRAPH[0].length]}
              yAxisId="depth"
            />
          ))}
          {selectedScenario?.map((key, index) => (
            <Line
              key={`tuned_${key}`}
              type="monotone"
              dataKey={`pressure_tuned_scenario_${key}`}
              stroke="#FE3F40"
              name={`pressure_tuned_scenario_${key}`}
              dot={false}
              strokeWidth={TYPE_LINE_TUNNING_GRAPH[2][index % TYPE_LINE_TUNNING_GRAPH[2].length]}
              strokeDasharray={TYPE_LINE_TUNNING_GRAPH[0][index % TYPE_LINE_TUNNING_GRAPH[0].length]}
              yAxisId="depth"
            />
          ))}

          {dataPoint2?.enable_gauge_parameters && (
            <Scatter
              type="monotone"
              stroke="#1e1b4b"
              strokeWidth={6}
              dataKey="gauge_pressure"
              yAxisId="depth"
              tooltipType="none"
            />
          )}
          <Scatter
            type="monotone"
            stroke="#684200"
            strokeWidth={6}
            dataKey="reservoir_pressure"
            yAxisId="depth"
            tooltipType="none"
          />
          <Scatter
            type="monotone"
            strokeWidth={6}
            stroke="#009130"
            dataKey="pwf_user"
            yAxisId="depth"
            tooltipType="none"
          />

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartVLP;
