import { Alert, message } from "antd"
import { useEffect, useState, useContext} from "react"
import { DashboardContext } from "../context/Dashboard/DashboardContentx";

export const ErrorLoadingData = () => {
    return (
      <div className="top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full bg-white">
        <Alert
              message="Alert"
              description="Create an well first for show the data"
              type="error"
            />
      </div>
    )
}

export const ErrorLoadingDashboard= () => {
    const { selectedWell, deviceRealTime, selectedWellDCA, dataPage} = useContext(DashboardContext);
    const [ messageError, setMessageError ] = useState("")
    useEffect(()=>{
      switch (dataPage) {
        case "DashboardGasLift":
          if((!selectedWell || selectedWell === "null")){
            setMessageError("Click on the plus sign button at the top left to select a well")
          }
          break;
        case "DashboardDCA":
          if((!selectedWellDCA || selectedWellDCA === "null")){
            setMessageError("Click on the plus sign button at the top left to select a well")
          }else if (!deviceRealTime.device_dca.name) {
            setMessageError("Select the appropriate sensor at the top right dropdown menu")
          }
          break;
        default:
          break;
      }
    }, [selectedWell, selectedWellDCA, deviceRealTime, dataPage])
    return (
      <div className="top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full bg-white">
        <Alert
          description={messageError}
          type="error"
          style={{fontSize: 20}}
        />
      </div>
    )
} 

export const ErrorLoadingTypeGraph= (messagePage) => {
 
  return (
      <div className="top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-full h-full bg-white">
        <Alert
          description={messagePage.messagePage === "flow" ? "Inflow-Outflow plot not available. Go to Inflow-Outflow module" : "VLP plot not available. Go to VLP module"}
          type="error"
          style={{fontSize: 20}}
        />
      </div>
  )
} 