import React from 'react'
import { DashboardPLContext } from './DashboardPLContext';
import { useDashboardPL } from '../../hooks/useDashboardPlungerLift';

export const DashboardPLProvider = ({children}) => {
  const {
    dataCreated,
    dataFilterUserPL,
    dataWellTables,
    dataWell,
    dataFluid,
    datReservoir,
    enableCheckLab,
    getDataCreated,
    getDataFilterPL,
    getDataWellTables,
    getDataWell,
    getDataFluid,
    getDataReservoir,
    getEnableCheckLab,
    handleSelectWellGaslift
  } = useDashboardPL();
  return (
    <DashboardPLContext.Provider value={{   
      dataCreated,
      dataFilterUserPL,
      dataWellTables,
      dataWell,
      dataFluid,
      datReservoir,
      enableCheckLab,
      getDataCreated,
      getDataFilterPL,
      getDataWellTables,
      getDataWell,
      getDataFluid,
      getDataReservoir,
      getEnableCheckLab,
      handleSelectWellGaslift,
    }}>
        {children}
    </DashboardPLContext.Provider>
  )
}
