import React, { useContext, useState } from 'react'
import { AuthContext } from '../auth/context/AuthContext'
import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'
import { calculateBgPVT, calculateBoPVT, calculateBwPVT, calculateCoPVT, calculateDengPVT, calculateDenoPVT, calculateDenwPVT, calculatePbPVT, calculatePpcPVT, calculatePprPVT, calculateRsPVT, calculateSigmalPVT, calculateStogPVT, calculateStwgPVT, calculateTcpPVT, calculateTprPVT, calculateVisgPVT, calculateVislPVT, calculateVisoPVT, calculateViswPVT, calculateZfactorPVT, udpateJsonPVT, updateJsonDataPVT, updateJsonFoPVT, uploadJsonDataPVT, uploadJsonFoPVT, uploadJsonPVT, getCalculationsParameters, getCalculationsDeviaton, getRS, uploadJsonTablePVT, uploadPVT, getCalculationsDeviatonPB, getRSTuned, getBOTuned, getVisoTuned, getPBTuned, getCalculationPb, getSepPress, getSepCalculate, getCo, getViso, getBo } from '../services/api/pvtAPI'
import { calculateQIPR, dataGraphic, dataReservoirDryGas, deleteDataGraphic, listDataGraphic, uploadaDataGraphic, uploadaDataReservoirDryGas, uploadJsonIPR, deleteScenariosReservoir } from '../services/api/iprAPI'
import { getListTuning } from '../services/api/tuningAPI'
import { DashboardContext } from '../context/Dashboard/DashboardContentx'
import { DataGasLiftContext } from '../context/DataGasLift/DataGasLiftContext'
import { createParametersGasLift, deleteParametersGasLift, getListWellGasLift, getParametersGasLift, updateParametersGasLift, getDataHistory, createDataHistory, updateDataHistory, deleteDataHistory, getListDataHistory, getWellsUserGasLift, uploadJsonVolumeTracker, getJsonVolumeTracker, getJsonIOTAllPlatforms, getJsonIOTVolumeTracker } from '../services/api/gasLiftAPI'
import { STATUS_TYPE_CALCULATE_PVT } from '../components/elements/types/type_response_API'
import { WELL_SENSOR_DEVICE } from '../components/elements/types/type_dashboard'
import { DATA_RESERVOIR } from '../components/elements/types/type_gas_lift'

const initialDataDeviationApi = {
  id:0,
  md: [],
  tvd: [],
  inc: [],
  t: null,
  p: null,
}

const initialDataEquipmentApi = {
  id: 0,
  name: "",
  type: "",
  measured_depth: "",
  internal_diameter: "",
  external_diameter: "",
  absolute_roughness: "",
}

const initiailInputPropiertiesOut = {
  Pb: "",
  Bo: "",
  Uo: "",
  Deno: "",
  SigmaO: "",
  Rs: "",
  Bg: "",
  Ug: "",
  Deng: "",
  Bw: "",
  Uw: "",
  Denw: "",
  SigmaW: "",
};

const initiailInputCalculatedLab = {
  BoUntuned: null,
  PbUntuned: null,
  RsUntuned: null,
  VisoUntuned: null,
  CoUntuned: null,
  BoTuned: null,
  PbTuned: null,
  RsTuned: null,
  VisoTuned: null,
  CoTuned: null
};

const initialDataTableLab = [{
  pressureLab: null,
  BoExp: null,
  RsExp: null,
  VisoExp: null,
  CoExp: null,
  BoUntuned: null,
  RsUntuned: null,
  VisoUntuned: null,
  CoUntuned: null,
  BoTuned: null,
  RsTuned: null,
  VisoTuned: null,
  CoTuned: null
}]

export const useTabGaslift = () => {

  const { User } = useContext(AuthContext);
  const { getUserDataInfo, userDataInfo, selectedWell, getSelectedWell, deviceRealTime, getDeviceRealTime } = useContext(DashboardContext);
  const { dataWell, dataWellTables, getDataWell, getDataWellTables, getDataFilterUserGaslift, dataFilterUserGasLift, dataCreated, getDataCreated, enableCheckLab, lastSelectDataIPR, getSelectDataIPR, viewLoadingGasLift, getViewLoadingGasLift} = useContext(DataGasLiftContext);
  const [dataDeviationApi, setDataDeviationApi] = useState(initialDataDeviationApi);
  const [dataEquipmentApi, setDataEquipmentApi] = useState(initialDataEquipmentApi);
  const [bodyAddWell, setBodyAddWell] = useState([]);
  const [inputPropertiesOut, setInputPropertiesOut] = useState(initiailInputPropiertiesOut);
  const [viewFluidLab, setViewFluidLab] = useState(false);
  const [inputCalculatedLab, setInputCalculatedLab] = useState(initiailInputCalculatedLab);
  const [dataTableLab, setDataTableLab] = useState(initialDataTableLab);
  const [executeTuned, setExecuteTuned] = useState(false);
  const [executeTunedCorrelation, setExecuteTunedCorrelation] = useState(false);
  const [indexTableLab, setIndexTableLab] = useState(0);
  const [dataTableIPR, setDataTableIPR] = useState();
  const [dataValuePb, setDataValuePb] = useState([]);
  const [userMultiselect, setUserMultiselect] = useState();
  const [inputReservoir, setInputReservoir] = useState(DATA_RESERVOIR.initialInputReservoir);
  const [ dataWellGL, setDataWellGL ] = useState([]);
  const [statedataListIPR, setDataListIPR] = useState(false);

  //FUNCIONES
  const transformData = (dataTableIPR) => {
    if (!inputReservoir || !inputReservoir.ipr_model || !dataTableIPR) return [];

    const { ipr_model } = inputReservoir;
    const { qo, steps } = dataTableIPR;

    const transformModelData = (qoModel) => {
      if (!qoModel || !Array.isArray(qoModel) || qoModel.length === 0) {
        return [];
      }
  
      return qoModel.map((qoValue, index) => ({
        qo: qoValue,
        steps: steps[index].toFixed(2),
      }));
    };

    const transformCompositeData = () => {
      if (!qo.qliq_pet || !Array.isArray(qo.qliq_pet) || qo.qliq_pet.length === 0 | !Array.isArray(qo.qo_pet) || qo.qo_pet.length === 0 | !Array.isArray(qo.qw_pet) || qo.qw_pet.length === 0) {
        return [];
      }
      
      return qo.qliq_pet.map((_, index) => ({
        qliq_pet: qo.qliq_pet[index],
        qo_pet: qo.qo_pet[index],
        qw_pet: qo.qw_pet[index],
        steps: steps[index].toFixed(2),
      }));
    };
  
    switch (ipr_model) {
      case 'vogel':
        return transformModelData(qo.vogel);
      case 'fetko':
        return transformModelData(qo.fetko);
      case 'straight':
        return transformModelData(qo.straight);
      case 'composite':
        return transformCompositeData();
      default:
        return qo ? Object.keys(qo).flatMap(model => 
          transformModelData(qo[model]).map(data => ({ ...data, model }))
        ) : [];
    }
  };

  const decimalRound = (number, round) => {
    const numVal = Array.isArray(number) ? number[0] : number;
    let valRound = Number((numVal).toFixed(round));
    return valRound
  }


  //LLAMADOS API
  const { data: dataIOTAllPlatforms, isLoading: loadDataIOTAllPlatforms, isError: ErrorDataIOTAllPlatforms, refetch: refetchDataIOTAllPlatforms} = useQuery({
    queryKey: ['dataIOTAllPlatforms'],
    queryFn: () => getJsonIOTAllPlatforms(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    refetchInterval: 15 * 60 * 1000,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  })

  const { data: dataWellGasLift, isLoading: loadDataWellGasLift, isError: errorDataWellGasLift, refetch: refetchDataWellGasLift } = useQuery({
    queryKey: ['get_data_gas_lift'],
    queryFn: () => getParametersGasLift(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
        if(data.statusType != "ERROR"){
          const filtered = WELL_SENSOR_DEVICE?.data.filter(item => item.name === data?.data?.well_platforms);
          const isEmptyObject = (obj) => {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
          };
          const validateSelectedPlatform = isEmptyObject(deviceRealTime?.device_gaslift?.selected_platform_graph)
          const validateSelectedDevice =  isEmptyObject(deviceRealTime?.device_gaslift?.selected_device_graph)
          if(validateSelectedPlatform || validateSelectedDevice){
            getDeviceRealTime({
              ...deviceRealTime,
              list_device_gaslift: filtered,
              device_gaslift:{
                ...deviceRealTime.device_gaslift,
                platform:data?.data?.well_platforms,
                discretized:data?.data?.discretized_well,
                selected_platform_graph:{
                  chartProdHistory:data?.data?.well_platforms,
                  chartWellCond:data?.data?.well_platforms,
                  chartHole:data?.data?.well_platforms
                },
                selected_device_graph:{
                  chartProdHistory:"",
                  chartWellCond:"",
                  chartHole:""
                }
              }
            });
          }
        }
    },
    onError: (error) => {
    }
  })

  const { data: listWellsGasLift, isLoading: loadListWellsGasLift, isError: ErrorListWellsGasLift, refetch: refetchListWellsGasLift } = useQuery({
    queryKey: ['get_list_wells_user_gas_lift'],
    queryFn: () => getWellsUserGasLift(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  });
  
  const { data: wellsUserGasLift, isLoading: loadWellsUserGasLift, isError: ErrorWellsUserGasLift, refetch: refetchWellsUserGasLift } = useQuery({
    queryKey: ['get_list_wells_gas_lift'],
    queryFn: () => getListWellGasLift(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, userMultiselect),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
    
    },
    onError: (error) => {
    }
    
  });
  
  const { data: listRS, isLoading: loadListRS, isError: ErrorloadListRS, refetch: refetchloadListRS } = useQuery({
    queryKey: ['get_list_rs'],
    queryFn: () => getRS(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, enableCheckLab ? "_tuned_calc" : ""),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: listBo, isLoading: loadListBo, isError: ErrorloadListBo, refetch: refetchloadListBo } = useQuery({
    queryKey: ['get_list_bo'],
    queryFn: () => getBo(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, enableCheckLab ? "_tuned_calc" : ""),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: listViso, isLoading: loadListViso, isError: ErrorloadListViso, refetch: refetchloadListViso } = useQuery({
    queryKey: ['get_list_viso'],
    queryFn: () => getViso(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, enableCheckLab ? "_tuned_calc" : ""),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: listCo, isLoading: loadListCo, isError: ErrorloadListCo, refetch: refetchloadListCo } = useQuery({
    queryKey: ['get_list_co'],
    queryFn: () => getCo(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, enableCheckLab ? "_tuned_calc" : ""),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const mutationSaveDataGasLift = useMutation({
    mutationFn: createParametersGasLift,
    onMutate: () => {
      getViewLoadingGasLift({...viewLoadingGasLift, loadSaveParameters :true});
    },
    onSuccess: () => {
      getViewLoadingGasLift({...viewLoadingGasLift,loadSaveParameters:false});
      message.success(`Data Well Gas lift Saved Successfull`);
      setTimeout(() => {
        refetchListWellsGasLift();
      }, 200);
    },
    onError: (error) => {
      getViewLoadingGasLift({...viewLoadingGasLift,loadSaveParameters:false});
      message.error(`An error has occurred when loading the data Well Gas lift`);
    }
  })

  const mutationSaveDataIPR = useMutation({
    mutationFn: uploadJsonIPR,
    onSuccess: () => {
      message.success(` IPR Data Saved Successfull`)
      setTimeout(() => {
        refetchListqIPR()
      }, 500);
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the IPR Data`)
    }
  })

  const { data: listqIPR, isLoading: loadListqIPR, isError: ErrorListqIPR, refetch: refetchListqIPR } = useQuery({
    queryKey: ['get_QIPR'],
    queryFn: () => calculateQIPR(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data?.statusType === "ERROR") {
        setDataListIPR(true)
      }
      else if (data?.statusType === "SUCCESS") {
        const dataReservoior = data.data
        setDataTableIPR(transformData(dataReservoior));
        setDataListIPR(true)
      }
    },
    onError: (error) => {
    }
  })
  const mutationUpdateDataGasLift = useMutation({
    mutationFn: updateParametersGasLift,
    onMutate: () => {
      getViewLoadingGasLift({...viewLoadingGasLift, loadSaveParameters :true});
    },
    onSuccess: () => {
      message.success(`Well Data Gas Lift Updated Successfull`);
      refetchListWellsGasLift();
      getViewLoadingGasLift({...viewLoadingGasLift,loadSaveParameters:false});
    },
    onError: (error) => {
      getViewLoadingGasLift({...viewLoadingGasLift,loadSaveParameters:false});
      message.error(`An error occurred while updating Well Gas Lift data`);
    }
  })

  const mutationDeleteDataGasLift = useMutation({
    mutationFn: deleteParametersGasLift,
    onMutate: () => {
      getViewLoadingGasLift({...viewLoadingGasLift, loadSaveParameters :true});
    },
    onSuccess: () => {
      message.success(`Data deleted Successfull`);
      refetchListWellsGasLift();
      getViewLoadingGasLift({...viewLoadingGasLift,loadSaveParameters:false});
    },
    onError: (error) => {
      getViewLoadingGasLift({...viewLoadingGasLift,loadSaveParameters:false});
      message.error(`An error has occurred when loading the Activity Distribution Graph`);
    }
  })


  const mutationUploadJsonPVT = useMutation({
    mutationFn: uploadJsonPVT,
    onSuccess: () => {
      message.success(`Uploaded Data Successfull`)
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the PVT data`)
    }
  })

  const mutationUploadPVT = useMutation({
    mutationFn: uploadPVT,
    onSuccess: () => {
      message.success("Uploaded Data Successfully");
    },
    onError: (error) => {
      message.error("An error occurred when uploading the PVT data");
    }
  });


  const mutationUploadJsonDataPVT = useMutation({
    mutationFn: uploadJsonDataPVT,
    onSuccess: () => {
      message.success(`Uploaded PVT Data Successfull`)
    },
    onError: () => {
      message.error(`An error has occurred when loading the PVT data`)
    }
  })

  const mutationUpdateJsonPVT = useMutation({
    mutationFn: udpateJsonPVT,
    onSuccess: () => {
      //message.success(`Update PVT Data Successfull`)
    },
    onError: (error) => {
      message.error(`An error has occurred when updating the PVT data`)
    }
  })


  const mutationUploadFoPVT = useMutation({
    mutationFn: uploadJsonFoPVT,
    onSuccess: () => {
      // message.success(`Values FO Uploaded Successfull`)
    },
    onError: (error) => {
      message.error(`An error has occurred when upload Values`)
    }
  })

  const mutationUpdateFoPVT = useMutation({
    mutationFn: updateJsonFoPVT,
    onSuccess: () => {
      // message.success(`Values FO Updated Successfull`)
    },
    onError: (error) => {
      message.error(`An error has occurred when updating the values`)
    }
  })

  const mutationUpdateJsonDataPVT = useMutation({
    mutationFn: updateJsonDataPVT,
    onSuccess: () => {
      // message.success(`PVT Data Updated Successfull`)
    },
    onError: () => {
      message.error(`An error has occurred when updating PVT data`)
    }
  })

  const { data: dataCalculate_Pb, isLoading: loadDataCalculate_Pb, isError: ErrorDataCalculate_Pb, refetch: refetchDataCalculate_Pb } = useQuery({
    queryKey: ['calculate_Pb'],
    queryFn: () => calculatePbPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.pb[0];
        if (viewFluidLab) {
          if (executeTuned) {
            setInputCalculatedLab((prevState) => ({
              ...prevState,
              "PbTuned": dataCalculate,
            }))
          } else {
            setInputCalculatedLab((prevState) => ({
              ...prevState,
              "PbUntuned": dataCalculate,
            }))
          }
        }
        else {
          setInputPropertiesOut((prevState) => ({
            ...prevState,
            "Pb": decimalRound(dataCalculate, 2),
          }));
        }
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Rs, isLoading: loadDataCalculate_Rs, isError: ErrorDataCalculate_Rs, refetch: refetchDataCalculate_Rs } = useQuery({
    queryKey: ['calculateRs'],
    queryFn: () => calculateRsPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.rs[0]
        if (viewFluidLab) {
          const operationProcess = executeTunedCorrelation ? 'RsTuned' : 'RsUntuned';
          setInputCalculatedLab((prevState) => ({
            ...prevState,
            [operationProcess]: dataCalculate,
          }))
        }
        else {
          setInputPropertiesOut((prevState) => ({
            ...prevState,
            "Rs": decimalRound(dataCalculate, 2),
          }));
        }
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }

    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Co, isLoading: loadDataCalculate_Co, isError: ErrorDataCalculate_Co, refetch: refetchDataCalculate_Co } = useQuery({
    queryKey: ['calculateCo'],
    queryFn: () => calculateCoPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      const dataCalculate = data?.data?.co[0]
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        if (viewFluidLab) {
          const operationProcess = executeTunedCorrelation ? 'CoTuned' : 'CoUntuned';
          setInputCalculatedLab((prevState) => ({
            ...prevState,
            [operationProcess]: dataCalculate,
          }))
        }
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })
  const { data: dataCalculate_Bo, isLoading: loadDataCalculate_Bo, isError: ErrorDataCalculate_Bo, refetch: refetchDataCalculate_Bo } = useQuery({
    queryKey: ['calculateBo'],
    queryFn: () => calculateBoPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = decimalRound(data.data.bo[0], 4)
        if (viewFluidLab) {
          const operationProcess = executeTunedCorrelation ? 'BoTuned' : 'BoUntuned';
          setInputCalculatedLab((prevState) => ({
            ...prevState,
            [operationProcess]: decimalRound(dataCalculate, 4),
          }))
        }
        else {
          setInputPropertiesOut((prevState) => ({
            ...prevState,
            "Bo": decimalRound(dataCalculate, 4),
          }));

        }
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Deno, isLoading: loadDataCalculate_Deno, isError: ErrorDataCalculate_Deno, refetch: refetchDataCalculate_Deno } = useQuery({
    queryKey: ['calculateDeno'],
    queryFn: () => calculateDenoPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.deno[0]
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Deno": decimalRound(dataCalculate, 2),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })
  const { data: dataCalculate_Viso, isLoading: loadDataCalculate_Viso, isError: ErrorDataCalculate_Viso, refetch: refetchDataCalculate_Viso } = useQuery({
    queryKey: ['calculateViso'],
    queryFn: () => calculateVisoPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.viso[0]
        if (viewFluidLab) {
          const operationProcess = executeTunedCorrelation ? 'VisoTuned' : 'VisoUntuned';
          setInputCalculatedLab((prevState) => ({
            ...prevState,
            [operationProcess]: dataCalculate,
          }))
        }
        else {
          setInputPropertiesOut((prevState) => ({
            ...prevState,
            "Uo": decimalRound(dataCalculate, 4),
          }));

        }
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Stog, isLoading: loadDataCalculate_Stog, isError: ErrorDataCalculate_Stog, refetch: refetchDataCalculate_Stog } = useQuery({
    queryKey: ['calculateStog'],
    queryFn: () => calculateStogPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.stog
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "SigmaO": decimalRound(dataCalculate),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Ppc, isLoading: loadDataCalculate_Ppc, isError: ErrorDataCalculate_Ppc, refetch: refetchDataCalculate_Ppc } = useQuery({
    queryKey: ['calculatePpc'],
    queryFn: () => calculatePpcPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      }
      else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Tcp, isLoading: loadDataCalculate_Tcp, isError: ErrorDataCalculate_Tcp, refetch: refetchDataCalculate_Tcp } = useQuery({
    queryKey: ['calculateTcp'],
    queryFn: () => calculateTcpPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Ppr, isLoading: loadDataCalculate_Ppr, isError: ErrorDataCalculate_Ppr, refetch: refetchDataCalculate_Ppr } = useQuery({
    queryKey: ['calculatePpr'],
    queryFn: () => calculatePprPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Tpr, isLoading: loadDataCalculate_Tpr, isError: ErrorDataCalculate_Tpr, refetch: refetchDataCalculate_Tpr } = useQuery({
    queryKey: ['calculateTpr'],
    queryFn: () => calculateTprPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Zfactor, isLoading: loadDataCalculate_Zfactor, isError: ErrorDataCalculate_Zfactor, refetch: refetchDataCalculate_Zfactor } = useQuery({
    queryKey: ['calculateZfactor'],
    queryFn: () => calculateZfactorPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Bg, isLoading: loadDataCalculate_Bg, isError: ErrorDataCalculate_Bg, refetch: refetchDataCalculate_Bg } = useQuery({
    queryKey: ['calculateBg'],
    queryFn: () => calculateBgPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.bg[0]
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Bg": decimalRound(dataCalculate, 4),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Deng, isLoading: loadDataCalculate_Deng, isError: ErrorDataCalculate_Deng, refetch: refetchDataCalculate_Deng } = useQuery({
    queryKey: ['calculateDeng'],
    queryFn: () => calculateDengPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.deng[0]
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Deng": decimalRound(dataCalculate, 4),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })


  const { data: dataCalculate_Visg, isLoading: loadDataCalculate_Visg, isError: ErrorDataCalculate_Visg, refetch: refetchDataCalculate_Visg } = useQuery({
    queryKey: ['calculateVisg'],
    queryFn: () => calculateVisgPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.visg[0];
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Ug": decimalRound(dataCalculate, 6),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })


  const { data: dataCalculate_Stwg, isLoading: loadDataCalculate_Stwg, isError: ErrorDataCalculate_Stwg, refetch: refetchDataCalculate_Stwg } = useQuery({
    queryKey: ['calculateStwg'],
    queryFn: () => calculateStwgPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.stwg[0]
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "SigmaW": decimalRound(dataCalculate, 2),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Bw, isLoading: loadDataCalculate_Bw, isError: ErrorDataCalculate_Bw, refetch: refetchDataCalculate_Bw } = useQuery({
    queryKey: ['calculateBw'],
    queryFn: () => calculateBwPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.bw
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Bw": decimalRound(dataCalculate, 4),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Denw, isLoading: loadDataCalculate_Denw, isError: ErrorDataCalculate_Denw, refetch: refetchDataCalculate_Denw } = useQuery({
    queryKey: ['calculateDenw'],
    queryFn: () => calculateDenwPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.denw
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Denw": decimalRound(dataCalculate, 2),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Visw, isLoading: loadDataCalculate_Visw, isError: ErrorDataCalculate_Visw, refetch: refetchDataCalculate_Visw } = useQuery({
    queryKey: ['calculateVisw'],
    queryFn: () => calculateViswPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
        const dataCalculate = data.data.visw;
        setInputPropertiesOut((prevState) => ({
          ...prevState,
          "Uw": decimalRound(dataCalculate, 4),
        }));
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Visl, isLoading: loadDataCalculate_Visl, isError: ErrorDataCalculate_Visl, refetch: refetchDataCalculate_Visl } = useQuery({
    queryKey: ['calculateVisl'],
    queryFn: () => calculateVislPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const { data: dataCalculate_Sigmal, isLoading: loadDataCalculate_Sigmal, isError: ErrorDataCalculate_Sigmal, refetch: refetchDataCalculate_Sigmal } = useQuery({
    queryKey: ['calculateSigmal'],
    queryFn: () => calculateSigmalPVT(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 2,
    enabled: false,
    onSuccess: (data) => {
      if (data.statusType === STATUS_TYPE_CALCULATE_PVT.SUCCESS) {
      } else if ((data.statusType === STATUS_TYPE_CALCULATE_PVT.ERROR)) {
        message.info("An error has occurred in the calculation made")
      }
    },
    onError: (error) => {
    }
  })

  const generateRadomId = () => {
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLettersAndNumbers = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';

    // Generar los primeros tres caracteres con letras mayúsculas
    for (let i = 0; i < 3; i++) {
      const randomUppercaseLetter = uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length));
      id += randomUppercaseLetter;
    }


    // Generar el resto de caracteres con letras minúsculas y números
    const remainingLength = 10 - id.length; // Asegurarse de que la longitud total sea al menos 10
    for (let i = 0; i < remainingLength; i++) {
      const randomChar = lowercaseLettersAndNumbers.charAt(Math.floor(Math.random() * lowercaseLettersAndNumbers.length));
      id += randomChar;
    }

    return id;

  };

  const calculateDomain = (value, domain) => {
    const allValues = value.reduce((acc, obj) => {
      Object.values(obj).forEach(value => {
        acc.push(value);
      });
      return acc;
    }, []);

    switch (domain) {
      case 'max':
        const maxValue = Math.max(...allValues);
        const roundedMax = Math.ceil(Math.round(maxValue * 10) / 100) * 10;
        return roundedMax
      case 'min':
        const minValue = Math.min(...allValues);
        const roundedMin = Math.floor(Math.round(minValue * 10) / 100) * 10;
        return roundedMin
      default:
        return null;
    }
  };

  const { data: listDataTuning, isLoading: loadListDataTuning, isError: errorListDataTuning, refetch: refetchDataTuning } = useQuery({
    queryKey: ["ListDataTuning"],
    retry: 1,
    enabled: false,
    queryFn: () =>
      getListTuning(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        User.user_name
      ),
    onError: () => {

    },
    onSuccess: (data) => {

    },
  });

  const mutationDataTablePVT = useMutation({
    mutationFn: uploadJsonTablePVT,
    onSuccess: () => {
      //message.success(`Fluid lab data Saved Successfull`);
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the IPR Data`);
    }
  })

  const { data: dataParameters, isLoading: loadDataParameters, isError: ErrorDataParameters, refetch: refetchDataParameters } = useQuery({
    queryKey: ['calculationsParameters'],
    queryFn: () => getCalculationsParameters(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataDeviationPB, isLoading: loadDataDeviationPB, isError: ErrorDataDeviationPB, refetch: refetchDataDeviationPB } = useQuery({
    queryKey: ['calculationsDataDeviationPB'],
    queryFn: () => getCalculationsDeviatonPB(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataDeviation, isLoading: loadDataDeviation, isError: ErrorDataDeviation, refetch: refetchDataDeviation } = useQuery({
    queryKey: ['calculationsDataDeviation'],
    queryFn: () => getCalculationsDeviaton(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: valuePb, isLoading: loadValuePb, isError: ErrorValuePb, refetch: refetchValuePb } = useQuery({
    queryKey: ['calculationsPb'],
    queryFn: () => getCalculationPb(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, enableCheckLab ? "_tuned_calc" : ""),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  })

  const { data: dataPBTuned, isLoading: loadDataPBTuned, isError: ErrorDataPBTuned, refetch: refetchDataPBTuned } = useQuery({
    queryKey: ['dataPBTuned'],
    queryFn: () => getPBTuned(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataRSTuned, isLoading: loadDataRSTuned, isError: ErrorDataRSTuned, refetch: refetchDataRSTuned } = useQuery({
    queryKey: ['dataRSTuned'],
    queryFn: () => getRSTuned(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataBOTuned, isLoading: loadDataBOTuned, isError: ErrorDataBOTuned, refetch: refetchDataBOTuned } = useQuery({
    queryKey: ['dataBOTuned'],
    queryFn: () => getBOTuned(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataVisoTuned, isLoading: loadDataVisoTuned, isError: ErrorDataVisoTuned, refetch: refetchDataVisoTuned } = useQuery({
    queryKey: ['dataVisoTuned'],
    queryFn: () => getVisoTuned(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataSepPress, isLoading: loadDataSepPress, isError: ErrorDataSepPress, refetch: refetchDataSepPress } = useQuery({
    queryKey: ['dataSepPress'],
    queryFn: () => getSepPress(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  const { data: dataSepCalculate, isLoading: loadDataSepCalculate, isError: ErrorDataSepCalculate, refetch: refetchDataSepCalculate } = useQuery({
    queryKey: ['dataSepCalculate'],
    queryFn: () => getSepCalculate(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, !enableCheckLab ? "_calc" : ""),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {

    },
    onError: (error) => {
    }
  })

  //DATA HISTORY REQUEST
  const {
    data: queryDataHistory,
    isLoading: loadDataHistory,
    isError: errorDataHistory,
    refetch: refetchDataHistory,
  } = useQuery({
    queryKey: ["DataHistory"],
    retry: 2,
    enabled: false,
    queryFn: () =>
      getDataHistory(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        dataFilterUserGasLift.county,
        dataFilterUserGasLift.field,
        dataFilterUserGasLift.well_id,
        User.user_name
      ),
    onError: (error) => {
      // setEnabledDataHistory(false);
    },
    onSuccess: (data) => {
      // setEnabledDataHistory(false);
    },
  });

  const mutationCreateDataHistory = useMutation({
    mutationFn: createDataHistory,
    onSuccess: () => {
      setTimeout(() => {
        refetchDataHistory();
        refetchListWellsGasLift();
      }, 200);
      message.success(`Data History Upload Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when Upload`
      );
    },
  });

  const mutationDeleteDataHistory = useMutation({
    mutationFn: deleteDataHistory,
    onSuccess: () => {
      setTimeout(() => {
        refetchDataHistory();
        refetchListWellsGasLift();
      }, 200);
      message.success(`Data History Delete Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when delete`
      );
    },
  });

  const mutationUpdateDataHistory = useMutation({
    mutationFn: updateDataHistory,
    onSuccess: () => {
      setTimeout(() => {
        refetchDataHistory();
        refetchListWellsGasLift();
      }, 200);
      message.success(`Data History Update Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when Upload`
      );
    },
  });

  const {
    data: queryListDataHistory,
    isLoading: loadListDataHistory,
    isError: errorListDataHistory,
    refetch: refetchListDataHistory,
  } = useQuery({
    queryKey: ["listDataHistory"],
    retry: 2,
    enabled: false,
    queryFn: () =>
      getListDataHistory(
        userDataInfo.operator,
        userDataInfo.lease,
        userDataInfo.region,
        User.user_name
      ),
    onSuccess: (data) => {
    },
    onError: (error) => {
      // setEnabledDataHistory(false);
    },
  });

  const { data: dataVolumeTracker, isLoading: loadVolumeTracker, isError: ErrorloadVolumeTracker, refetch: refetchVolumeTracker } = useQuery({
    queryKey: ['get_VolumTracker'],
    queryFn: () => getJsonVolumeTracker(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    }
  });

  const { data: dataVolumeTrackerRT, isLoading: loadDataVolumeTrackerRT, isError: ErrorDataVolumeTrackerRT, refetch: refetchVolumeTrackerRT} = useQuery({
    queryKey: ['get_VolumTracker_RT'],
    queryFn: () => getJsonIOTVolumeTracker(userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataWellGL.county, dataWellGL.field, dataWellGL.well_id, User.user_name),
    retry: 1,
    enabled: false,
    onSuccess: (data) => {
    },
    onError: (error) => {
    },
  })

  const mutationUploadJsonVolumeTracker = useMutation({
    mutationFn: uploadJsonVolumeTracker,
    onSuccess: () => {
      message.success(`Uploaded Data Successfull`);
      setTimeout(() => {
        refetchVolumeTracker();
        refetchVolumeTrackerRT()
      }, 500);
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the PVT data`)
    }
  })

  //saved, listing and delete of the data saved of the graphic of IPR

  const { data: queryListDataGraphic, isLoading: loadListDataGraphic, isError: errorListDataGraphic, refetch: refetchListDataGraphic } = useQuery({
    queryKey: ["ListDataGraphicsIPR"],
    retry: 1,
    enabled:false,
    queryFn: () =>
      listDataGraphic( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name ),
      onSuccess: (data) => {
          // message.success(`Data query Successfull`);
      },
      onError: (error) => {
      },
  });

  const { data: queryDataGraphic, isLoading: loadDataGraphic, isError: errorDataGraphic, refetch: refetchDataGraphic } = useQuery({
    queryKey: ["dataGraphicsIPR"],
    retry: 1,
    enabled:false,
    queryFn: () =>
      dataGraphic( userDataInfo.operator, userDataInfo.lease, userDataInfo.region, dataFilterUserGasLift.county, dataFilterUserGasLift.field, dataFilterUserGasLift.well_id, User.user_name, lastSelectDataIPR ),
      onSuccess: (data) => {
          // message.success(`Data query Successfull`);
      },
      onError: (error) => {
      },
  });

  const mutationUploadJsonDataGraph = useMutation({
    mutationFn: uploadaDataGraphic,
    onSuccess: () => {
      message.success(`Uploaded Data Successfull`);
      setTimeout(() => {
        refetchListDataGraphic();
      }, 500);
    },
    onError: (error) => {
      message.error(`An error has occurred when loading the data of the graphic`)
    }
  })

  const mutationDeleteDataGraphicIPR = useMutation({
    mutationFn: deleteDataGraphic,
    onSuccess: () => {
      setTimeout(() => {
        refetchListDataGraphic();
      }, 200);
      message.success(`Data of graphic Delete Successfull`);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when delete`
      );
    },
  });

  const mutationDeleteScenariosReservoir = useMutation({
    mutationFn: deleteScenariosReservoir,
    onSuccess: () => {
      setTimeout(() => {
        refetchListDataGraphic();
      }, 200);
    },
    onError: (error) => {
      message.error(
        `An error has occurred when delete`
      );
    },
  });

  // API`s IPR Dry Gas

  const mutationDataIPRDryGas = useMutation({
    mutationFn: uploadaDataReservoirDryGas,
    onSuccess: (data) => {
      if(data?.statusCode === 200044){
        // message.success(`Uploaded Data Successfull`);
      }else{
        message.error(`Uploaded Data Failed`);
      }
    },
    onError: (error) => {
      message.error(`error`)
    }
  })

  const { data: queryDataIPRDryGas, isLoading: loadDataIPRDryGas, isError: errorDataIPRDryGas, refetch: refetchDataIPRDryGas } = useQuery({
    queryKey: ["dataCalculateIPRDryGas"],
    retry: 1,
    enabled:false,
    queryFn: () =>
      dataReservoirDryGas( 
        userDataInfo.operator, 
        userDataInfo.lease, 
        userDataInfo.region, 
        dataFilterUserGasLift.county, 
        dataFilterUserGasLift.field, 
        dataFilterUserGasLift.well_id, 
        User.user_name 
      ),
      onSuccess: (data) => {
      },
      onError: (error) => {
      },
  });

  return {
    User,
    bodyAddWell,
    dataCreated,
    dataWell,
    dataWellTables,
    userDataInfo,
    selectedWell,
    getSelectedWell,
    dataDeviationApi,
    dataEquipmentApi,
    mutationUploadJsonPVT,
    mutationUploadPVT,
    mutationUploadJsonDataPVT,
    mutationUploadFoPVT,
    mutationUpdateFoPVT,
    mutationUpdateJsonPVT,
    mutationUpdateJsonDataPVT,
    getUserDataInfo,
    getDataWell,
    queryDataHistory,
    initiailInputPropiertiesOut,
    getDataWellTables,
    setDataDeviationApi,
    setDataEquipmentApi,
    setBodyAddWell,
    listWellsGasLift,
    refetchListWellsGasLift,
    dataCalculate_Pb,
    refetchDataCalculate_Pb,
    dataCalculate_Bo,
    refetchDataCalculate_Bo,
    dataCalculate_Viso,
    refetchDataCalculate_Viso,
    dataCalculate_Deno,
    refetchDataCalculate_Deno,
    dataCalculate_Stog,
    refetchDataCalculate_Stog,
    dataCalculate_Rs,
    refetchDataCalculate_Rs,
    dataCalculate_Bg,
    refetchDataCalculate_Bg,
    dataCalculate_Visg,
    refetchDataCalculate_Visg,
    dataCalculate_Deng,
    refetchDataCalculate_Deng,
    dataCalculate_Bw,
    refetchDataCalculate_Bw,
    dataCalculate_Visw,
    refetchDataCalculate_Visw,
    dataCalculate_Denw,
    refetchDataCalculate_Denw,
    dataCalculate_Stwg,
    refetchDataCalculate_Stwg,
    dataCalculate_Co,
    refetchDataCalculate_Co,
    dataCalculate_Ppc,
    refetchDataCalculate_Ppc,
    dataCalculate_Tcp,
    refetchDataCalculate_Tcp,
    dataCalculate_Ppr,
    refetchDataCalculate_Ppr,
    dataCalculate_Tpr,
    refetchDataCalculate_Tpr,
    dataCalculate_Zfactor,
    refetchDataCalculate_Zfactor,
    dataCalculate_Visl,
    refetchDataCalculate_Visl,
    dataCalculate_Sigmal,
    refetchDataCalculate_Sigmal,
    inputPropertiesOut,
    setInputPropertiesOut,
    dataWellGasLift,
    loadDataWellGasLift,
    loadDataHistory,
    refetchDataWellGasLift,
    refetchDataHistory,
    generateRadomId,
    getDataFilterUserGaslift,
    dataFilterUserGasLift,
    mutationSaveDataGasLift,
    mutationUpdateDataGasLift,
    mutationDeleteDataGasLift,
    mutationCreateDataHistory,
    mutationDeleteDataHistory,
    mutationUpdateDataHistory,
    mutationDataTablePVT,
    selectedWell,
    getSelectedWell,
    dataCreated,
    getDataCreated,
    listDataTuning,
    listqIPR,
    mutationSaveDataIPR,
    refetchListqIPR,
    refetchDataTuning,
    viewFluidLab,
    setViewFluidLab,
    inputCalculatedLab,
    dataTableLab,
    setDataTableLab,
    setExecuteTuned,
    executeTuned,
    setExecuteTunedCorrelation,
    executeTunedCorrelation,
    setIndexTableLab,
    indexTableLab,
    queryListDataHistory,
    refetchListDataHistory,
    errorListDataHistory,
    errorDataWellGasLift,
    calculateDomain,
    dataTableIPR,
    setDataTableIPR,
    dataParameters,
    refetchDataParameters,
    dataDeviationPB,
    refetchDataDeviationPB,
    dataDeviation,
    refetchDataDeviation,
    dataPBTuned,
    refetchDataPBTuned,
    dataRSTuned,
    refetchDataRSTuned,
    dataBOTuned,
    refetchDataBOTuned,
    dataVisoTuned,
    refetchDataVisoTuned,
    listRS,
    refetchloadListRS,
    refetchValuePb,
    valuePb,
    dataValuePb,
    dataSepPress,
    refetchDataSepPress,
    dataSepCalculate,
    refetchDataSepCalculate,
    refetchloadListBo,
    refetchloadListCo,
    refetchloadListViso,
    listCo,
    listBo,
    listViso,
    inputReservoir,
    setInputReservoir,
    setUserMultiselect,
    refetchWellsUserGasLift,
    wellsUserGasLift,
    dataVolumeTracker,
    refetchVolumeTracker,
    mutationUploadJsonVolumeTracker,
    mutationUploadJsonDataGraph,
    mutationDeleteDataGraphicIPR,
    mutationDeleteScenariosReservoir,
    queryDataGraphic,
    queryListDataGraphic,
    refetchDataGraphic,
    refetchListDataGraphic,
    lastSelectDataIPR,
    getSelectDataIPR,
    dataWellGL,
    setDataWellGL,
    dataIOTAllPlatforms,
    refetchDataIOTAllPlatforms,
    loadDataIOTAllPlatforms,
    dataVolumeTrackerRT,
    refetchVolumeTrackerRT,
    refetchDataIPRDryGas,
    mutationDataIPRDryGas,
    queryDataIPRDryGas,
    loadListWellsGasLift,
    statedataListIPR,
    setDataListIPR
  }
}