import { Select } from "antd";
import React, { useState } from "react";

export const ParametersDCA = ({
	dataListParameters,
	handleChangeSelect,
	selectedParameters,
}) => {
	const { Option } = Select;
	return (
		<div className="flex flex-row w-full h-full">
			<div className="flex flex-col w-1/2 h-full p-5">
				<h1 className="font-bold text-pa-purple text-[16px] mb-[10px]">
					Standar Paramters
				</h1>
				<div className="flex flex-col w-full h-full gap-5">
					<div className="flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							acumulativeGas
						</label>
					</div>
					<div className="flex  w-full h-[50px] rounded-sm border border-solid border-gray-500 p-3">
						<label
							htmlFor=""
							className="flex w-full h-full font-sans font-bold text-[14px] "
						>
							acumulativeOil
						</label>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-1/2 h-full p-4">
				<h1 className="font-bold text-pa-purple text-[16px] mb-[10px]">
					Parameters Real Time
				</h1>
				<div className="flex flex-col gap-5">
					<div className="w-full h-[50px] rounded-sm border border-solid border-gray-500 p-">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="acumulativeGas"
							value={selectedParameters.acumulativeGas || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("acumulativeGas", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
					<div className="w-full h-[50px] rounded-sm border border-solid border-gray-500 p-">
						<Select
							notFoundContent={"Loading..."} // Show loading message
							name="acumulativeOil"
							value={selectedParameters.acumulativeOil || ""}
							placeholder="Selected option"
							className="w-full min-h-[50px] text-left"
							onChange={(value) => handleChangeSelect("acumulativeOil", value)}
						>
							{dataListParameters
								.sort((a, b) => a.localeCompare(b)) // Sort items alphabetically
								.map((item, index) => (
									<Option key={index} value={item}>
										{item.toLowerCase() === "all" ? "All" : item}
									</Option>
								))}
						</Select>
					</div>
				</div>
			</div>
		</div>
	);
};
