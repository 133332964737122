//Eliminar filas vacias de las tablas
export function removeEmptyRows(table, columnsToIgnore = []) {
	if (!Array.isArray(columnsToIgnore)) {
		columnsToIgnore = [];
	}
	const ignoredColumns = Array.isArray(columnsToIgnore)
		? [...new Set([...columnsToIgnore, "id"])] // Evita duplicados
		: ["id"];
	return table.filter((row) => {
		//const values = Object.values(row).slice(1); //de esta forma se usaba antes
		const values = Object.entries(row)
			.filter(([key]) => !ignoredColumns.includes(key)) // Excluye columnas específicas
			.map(([, value]) => value);
		return values.some((value) => value !== null && value !== ""); // Verifica si hay algún valor no vacío
	});
}

export function mapDataAverage(data, type) {
	if (data) {
		const oilRates = [];
		const waterRates = [];
		const gasRates = [];
		if (type === "average") {
			Object.keys(data).forEach((keys) => {
				const { oil_rate_avg, water_rate_avg, gas_rate_avg } = data[keys];
				oilRates.push(oil_rate_avg ? oil_rate_avg : 0);
				waterRates.push(water_rate_avg ? water_rate_avg : 0);
				gasRates.push(gas_rate_avg ? gas_rate_avg : 0);
			});
		} else if (type === "averageRT") {
			Object.keys(data).forEach((keys) => {
				const { oil_rate, water_rate, gas_rate } = data[keys];
				oilRates.push(oil_rate ? oil_rate : 0);
				waterRates.push(water_rate ? water_rate : 0);
				gasRates.push(gas_rate ? gas_rate : 0);
			});
		}

		const result = {
			oil_rate: oilRates,
			water_rate: waterRates,
			gas_rate: gasRates,
		};

		return result;
	} else {
		return [];
	}
}
