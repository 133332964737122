import { serviceEndPoints } from "../../config/config";

const BASE_URL_IPR = serviceEndPoints.IPR.BASE_URL_IPR;
const BASE_URL_PLUNGER_LIFT = serviceEndPoints.PLUNGER_LIFT.URL_PLUNGER_LIFT;
const BASE_URL_WELL = serviceEndPoints.GASLIFT.BASE_URL_WELL;
console.log("BASE_URL_PLUNGER_LIFT", BASE_URL_PLUNGER_LIFT);

export const uploadJsonIPRPL = async (dataJson) => {
  const operator = dataJson.operator;
  const lease = dataJson.lease;
  const region = dataJson.region;
  const county = dataJson.county;
  const field = dataJson.field;
  const well = dataJson.well;
  const username = dataJson.username;
  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculateQIPRPL = async (
  operator,
  lease,
  region,
  country,
  field,
  well,
  username
) => {
  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/calculate_q/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

export const calculatePwfIPR = async (
  operator,
  lease,
  region,
  country,
  field,
  well,
  dataFile
) => {
  try {
    const response = await fetch(
      `${BASE_URL_IPR}/plunger_lift/calculate_pwf/${operator}/${lease}/${region}/${country}/${field}/${well}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};

// CASING PRESSURE

export const calculateCasing = async (
  operator,
  lease,
  region,
  country,
  field,
  well,
  username
) => {
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/calculate_table/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const uploadJsonCasing = async (dataJson) => {
  const operator = dataJson.operator;
  const lease = dataJson.lease;
  const region = dataJson.region;
  const county = dataJson.county;
  const field = dataJson.field;
  const well = dataJson.well;
  const username = dataJson.username;
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/upload_json/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem upload data");
  }
};

export const calculatePressure = async (
  operator,
  lease,
  region,
  county,
  field,
  well,
  username
) => {
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/calculate_press/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const uploadJsonPressure = async (dataJson) => {
  const operator = dataJson.operator;
  const lease = dataJson.lease;
  const region = dataJson.region;
  const county = dataJson.county;
  const field = dataJson.field;
  const well = dataJson.well;
  const username = dataJson.username;
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/upload_json_calc_press/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem upload data");
  }
};

export const calculateInterpolation = async (
  operator,
  lease,
  region,
  county,
  field,
  well,
  username
) => {
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift_casing/calculate_interpolation/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

// PAREMETERS WELL PLUNGER LIFT

export const dataWellPlungerLift = async (
  operator,
  lease,
  region,
  country,
  field,
  well,
  username,
  type
) => {
  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const uploadJsonWellPL = async (dataJson) => {
  const operator = dataJson.operator;
  const lease = dataJson.lease;
  const region = dataJson.region;
  const county = dataJson.county;
  const field = dataJson.field;
  const well = dataJson.well;
  const username = dataJson.username;
  const type = dataJson.type;
  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${type}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem upload data");
  }
};

export const updateJsonWellPL = async (dataJson) => {
  const operator = dataJson.operator;
  const lease = dataJson.lease;
  const region = dataJson.region;
  const county = dataJson.county;
  const field = dataJson.field;
  const well = dataJson.well;
  const username = dataJson.username;
  const type = dataJson.type;
  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${type}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem update data");
  }
};

export const deleteWellPL = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const type = dataJson.type;
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/${type}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem delete data well");
  }
};

export const listWellPlungerLift = async (
  operator,
  lease,
  region,
  username,
  type
) => {
  try {
    const response = await fetch(
      `${BASE_URL_WELL}/parameterslist/${operator}/${lease}/${region}/${username}/${type}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

// PVT PLUNGER LIFT

export const calculatePVT = async (
  operator,
  lease,
  region,
  country,
  field,
  well,
  username
) => {
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_pvt/calculate_pvt/${operator}/${lease}/${region}/${country}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const uploadJsonPVT = async (dataJson) => {
  const operator = dataJson.operator;
  const lease = dataJson.lease;
  const region = dataJson.region;
  const county = dataJson.county;
  const field = dataJson.field;
  const well = dataJson.well;
  const username = dataJson.username;
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_pvt/upload_json_pvt/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem upload data");
  }
};

export const updateParametersPlungerLift = async (dataJson) => {
  try {
    const operator = dataJson.operator;
    const lease = dataJson.lease;
    const region = dataJson.region;
    const county = dataJson.county;
    const field = dataJson.field;
    const well = dataJson.well;
    const username = dataJson.username;
    const response = await fetch(
      `${BASE_URL_WELL}/parameters/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}/parametersplungerlift`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token TroT6HpzeXw78LnkgPrPO772lSBAYELfpFWzpC1S3",
        },
        body: JSON.stringify(dataJson.data),
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting data");
  }
};

export const uploadExcelFile = async (fileData, dataParams) => {
  const { operator, lease, region, county, field, well, username } = dataParams;
  console.log("dataParams", dataParams);

  const formData = new FormData();
  formData.append("file", fileData);
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/upload_excel/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "POST",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
        body: formData,
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem uploading the Excel file");
  }
};
export const getProcessedData = async (
  operator,
  lease,
  region,
  county,
  field,
  well,
  username
) => {
  try {
    const response = await fetch(
      `${BASE_URL_PLUNGER_LIFT}/plunger_lift/get_excel/${operator}/${lease}/${region}/${county}/${field}/${well}/${username}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token ifYspn2z9jnyoNlQ0Zo5nUYSjnLYQgEhOtgKAtMXm",
        },
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem fetching processed data");
  }
};
