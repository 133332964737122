import React, { useEffect, useState } from "react";
import {
	DATA_END_PARAMETERS,
	INITIAL_STATE_PROCESS_PL,
	SELECT_DASHBOARD_PL,
} from "../components/elements/types/type_plunger_Lift";
import { WELL_FILTER } from "../components/elements/types/type_dashboard";

const initialViewLoadingPlungerLift = {
	loadSaveParameters: false,
};

const initialValidateAlertPlungerLift = {
	alertCard: false,
	alertDomain: false,
};

export const useDashboardPL = () => {
	const [dataFilterUserPL, setDataFilterUserPL] = useState(WELL_FILTER);
	const [selectDashboardPL, setSelectDashboardPL] = useState(
		DATA_END_PARAMETERS.initialMandatoryData.type_graph_dashboard[0],
	);
	const [lastSelectDataIPRPL, setLastSelectDataIPRPL] = useState();
	const [viewLoadingPlungerLift, setViewLoadingPlungerLift] = useState(
		initialViewLoadingPlungerLift,
	);
	const [validateAlertPlungerLift, setValidateAlertPlungerLift] = useState(
		initialValidateAlertPlungerLift,
	);
	const [stateMessageApisPL, setStateMessageApiPL] = useState(
		INITIAL_STATE_PROCESS_PL,
	);
	const [dataChartReservoir, setDataChartReservoir] = useState(null);
	const [dataChartVLP, setDataChartVLP] = useState(null);

	useEffect(() => {
		const getDataFilterPL = JSON.parse(
			sessionStorage.getItem("dataFilterUserPL"),
		);
		const getSelectDashboardPL = JSON.parse(
			sessionStorage.getItem("selectDashboardPL"),
		);
		const getSelectDataIPRPL = sessionStorage.getItem("lastSelectDataIPRPL");

		if (getDataFilterPL) {
			setDataFilterUserPL(getDataFilterPL);
		}
		if (getSelectDashboardPL) {
			setSelectDashboardPL(getSelectDashboardPL);
		}
		if (getSelectDataIPRPL) {
			setLastSelectDataIPRPL(getSelectDataIPRPL);
		}
		if (getStateMessageApisPL) {
			setStateMessageApiPL(getStateMessageApisPL);
		}
	}, []);

	const getDataFilterPL = (options) => {
		setDataFilterUserPL(options);
		sessionStorage.setItem("dataFilterUserPL", JSON.stringify(options));
	};

	const handleSelectWellGaslift = (wellId) => {
		setDataFilterUserPL(wellId);
		sessionStorage.setItem("dataFilterUserGaslift", wellId);
	};

	const getSelectDashboardPL = (options) => {
		setSelectDashboardPL(options);
		sessionStorage.setItem("selectDashboardPL", JSON.stringify(options));
	};

	const getSelectDataIPRPL = (options) => {
		setLastSelectDataIPRPL(options);
		sessionStorage.setItem("lastSelectDataIPRPL", JSON.stringify(options));
	};

	const getViewLoadingPlungerLift = (options) => {
		setViewLoadingPlungerLift(options);
	};

	const getValidateAlertPlungerLift = (options) => {
		setValidateAlertPlungerLift(options);
	};

	const getStateMessageApisPL = (options) => {
		setStateMessageApiPL(options);
	};
	const getDataChartReservoirPL = (options) => {
		setDataChartReservoir(options);
	};
	const getDataChartVLP = (options) => {
		setDataChartVLP(options);
	};
	return {
		dataFilterUserPL,
		lastSelectDataIPRPL,
		validateAlertPlungerLift,
		viewLoadingPlungerLift,
		stateMessageApisPL,
		selectDashboardPL,
		dataChartReservoir,
		dataChartVLP,
		getDataFilterPL,
		handleSelectWellGaslift,
		getSelectDashboardPL,
		getSelectDataIPRPL,
		getValidateAlertPlungerLift,
		getViewLoadingPlungerLift,
		getStateMessageApisPL,
		getDataChartReservoirPL,
		getDataChartVLP,
	};
};
